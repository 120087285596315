import isSignNow from '../isSignNow';
import TODO_LIST_STATES from './todoListStates';

const TIME_WAIT_AUTH = 60000;

const GHOST_ID = 'GHOST_ID';
const TIME_FOR_REPEATED_REQUEST = 10000;
const DENIED = 'denied';
const API_HASH = 'api_js';
const API_AIRSLATE_HASH = 'airSlate.session.token';
const WS_EDITOR_REST_API = 'WsEditorLib';
const TIME_FOR_REPEATED_REQUEST_CONSTANTS_HELP_STRUCTURE = 5000;
const TIME_FOR_RECONNECT = 2500;
const DEFAULT_EDITOR_MODE = 'jseditor';
const DEFAULT_EDITOR_MODE_ID = 'A1';
const DEFAULT_HELP_TYPE = 'core';
const HELP_MODAL_ID = 'HelpModal';
const AUTH_ERROR = `Authentication error. Wait ${TIME_WAIT_AUTH / 1000} sec.`;
const MAX_COUNT_BUSY = 60;
const MAX_ERROR_POINT_INFO_SIZE = 131000;
const MAX_RECONNECT_IN_A_ROW_COUNT = 10;
const MAX_RECONNECT_DURING_SESSION_COUNT = 30;
const CONDITION_STATUS_NONE = 'none';
const DESTROY_FAIL_ERROR = 'DESTROY_FAIL';

export const POST_MESSAGES = {
  editorDone: 'editorDone',
  unexpectedDestroy: 'unexpectedDestroy',
};
// JSF-5225: in case of both creating new dropDown element and
// removing all items from dropDown list, DEFAULT_DROPDOWN_ELEMENT will be in list
const DEFAULT_DROPDOWN_LIST = isSignNow()
  ? []
  : [{
    data: ' ',
    label: ' ',
    addedByDefault: true,
  }];

const COPY_BY_CLICKING_NAME = '<BY_LABEL>';

const GROUPS = {
  tools: 'tools',
  document: 'document',
  images: 'images',
  signatures: 'signatures',
  editor: 'editor',
  users: 'users',
  operations: 'operations',
  versions: 'versions',
  validators: 'validators',
  integrations: 'integrations',
};

const TYPES = {
  delete: 'delete',
  add: 'add',
  autofill: 'autofill',
  list: 'list',
  update: 'update',
  create: 'create',
  track: 'track',
  pages: 'pages',
  source: 'source',
  resolution: 'resolution',
  elements: 'elements',
  access: 'access',
  defaults: 'defaults',
  mode: 'mode',
  cancel: 'cancel',
  preview: 'preview',
  restore: 'restore',
  save: 'save',
  external: 'external',
  trigger: 'trigger',
};

const TOOL_TYPES = {
  // FC tool types
  text: 'text',
  number: 'number',
  checkmark: 'checkmark',
  signature: 'signature',
  initials: 'initials',
  date: 'date',
  image: 'image',
  dropdown: 'dropdown',
  radio: 'radio',
  attachment: 'attachment',
  formula: 'formula',
  salesforce: 'salesforce',
  smartfield: 'smartfield',
  // toolbar elements tool types
  erase: 'erase',
  highlight: 'highlight',
  blackout: 'blackout',
  textbox: 'textbox',
  arrow: 'arrow',
  line: 'line',
  sticky: 'sticky',
  pen: 'pen',
  comment: 'comment',
  curve: 'curve',
  // other
  overlying: 'overlying',
  selection: 'selection',
  none: 'none',
  fctool: 'fctool',
};

const ATTRIBUTE_NAMES = {
  watermark: 'watermark',
  date: 'date',
  numbering: 'numbering',
  bates: 'bates',
  attributes: 'attributes',
};

const TOOL_SUB_TYPES = {
  none: 'none',
  [TOOL_TYPES.signature]: {
    initials: TOOL_TYPES.initials,
    curve: TOOL_TYPES.curve,
    text: TOOL_TYPES.text,
    image: TOOL_TYPES.image,
  },
  [TOOL_TYPES.text]: {
    number: TOOL_TYPES.number,
    date: TOOL_TYPES.date,
    formula: TOOL_TYPES.formula,
    dropdown: TOOL_TYPES.dropdown,
    overlying: TOOL_TYPES.overlying,
  },
  [TOOL_TYPES.checkmark]: {
    v: 'v',
    x: 'x',
    o: 'o',
  },
  [TOOL_TYPES.radio]: {
    v: 'v',
    x: 'x',
    o: 'o',
  },
};

const QUERY_PARAMETERS = {
  token: 'token',
  viewerId: 'viewerId',
};

const OPERATION_SUB_TYPES = {
  request: 'request',
  change: 'change',
};

const WIZARD_STATES = {
  FULL: 'full',
  LITE: 'lite',
};

const VERSIONS_SUB_TYPES = {
  current: 'current',
  previous: 'previous',
  version: 'version',
};

const EDIT_TYPES = {
  standard: 'standard',
  l2f: 'l2f',
  s2s: 's2s',
  as: 'as',
};

const TRIGGER_TYPES = {
  focusOut: 'focusOut',
};

const ERROR_MESSAGES = {
  validatorsCollectionRequestError: 'validatorsCollection request error',
  editorLocaleFailRequestError: 'editorLocale request error',
};

export {
  WS_EDITOR_REST_API,
  GROUPS,
  TYPES,
  TOOL_TYPES,
  ATTRIBUTE_NAMES,
  TOOL_SUB_TYPES,
  QUERY_PARAMETERS,
  OPERATION_SUB_TYPES,
  CONDITION_STATUS_NONE,
  TODO_LIST_STATES,
  TIME_WAIT_AUTH,
  GHOST_ID,
  TIME_FOR_REPEATED_REQUEST,
  DENIED,
  API_HASH,
  API_AIRSLATE_HASH,
  TIME_FOR_REPEATED_REQUEST_CONSTANTS_HELP_STRUCTURE,
  TIME_FOR_RECONNECT,
  DEFAULT_EDITOR_MODE,
  DEFAULT_EDITOR_MODE_ID,
  DEFAULT_HELP_TYPE,
  HELP_MODAL_ID,
  AUTH_ERROR,
  DESTROY_FAIL_ERROR,
  MAX_COUNT_BUSY,
  MAX_ERROR_POINT_INFO_SIZE,
  MAX_RECONNECT_IN_A_ROW_COUNT,
  MAX_RECONNECT_DURING_SESSION_COUNT,
  DEFAULT_DROPDOWN_LIST,
  COPY_BY_CLICKING_NAME,
  WIZARD_STATES,
  VERSIONS_SUB_TYPES,
  EDIT_TYPES,
  TRIGGER_TYPES,
  ERROR_MESSAGES,
};
