import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

const List = ({
  children,
  size,
  offset,
  theme,
  type,
  isSortable,
  isEmpty,
  storeRef,
  onClick,
}) => {
  return (
    <ul
      className={classnames('list', {
        [`list--size--${size}`]: size,
        [`list--type--${type}`]: type,
        [`list--offset--${offset}`]: offset,
        [`list--theme--${theme}`]: theme,
        'is-sortable': isSortable,
        'is-empty': isEmpty,
      })}
      ref={storeRef}
      onClick={onClick}
    >
      {children}
    </ul>
  );
};

const listTypes = {
  null: null,
  listConfigurator: 'list-configurator',
};

const listSizes = {
  null: null,
  medium: 'medium',
};

const listOffsets = {
  null: null,
  verticalExtraSmall: 'vertical-extra-small',
};

const listThemes = {
  null: null,
  default: 'default',
};

List.propTypes = {
  children: PropTypes.node.isRequired,

  type: PropTypes.oneOf(Object.values(listTypes)),
  size: PropTypes.oneOf(Object.values(listSizes)),
  offset: PropTypes.oneOf(Object.values(listOffsets)),
  theme: PropTypes.oneOf(Object.values(listThemes)),
  isSortable: PropTypes.bool,
  isEmpty: PropTypes.bool,

  storeRef: PropTypes.func,
  onClick: PropTypes.func,
};

List.defaultProps = {
  size: listSizes.null,
  type: listTypes.null,
  offset: listOffsets.null,
  theme: listThemes.null,
  isSortable: false,
  isEmpty: false,
  storeRef: null,
  onClick: null,
};

export default List;
export { listSizes, listOffsets, listTypes, listThemes };
