/**
 * Эта HOF нужна для предотвращения бага:
 *
 * В SNFiller при переносе элемента со страницы на страницу происходит следующее:
 * 1. Сперва элемент маунтится на странице на которую его перенесли
 *    storeRef(id)(DOMElement)
 * 2. После этого элемент анмаунтится со страницы с которой его перенесли
 *    storeRef(id)(null)
 *
 * Итог: элемент на странице, а в refsStorageDecorator'e хранится null вместо
 * ref'a
 *
 * Повлиять на React мы не можем, поэтому пробуем детектить такую ситуацию.
 * Первый признак этого события:
 * 🐦 Ref меняется с DOMElement на DOMElement которые != друг другу
 * 🦉 После этого происходит вызов функци с ref === null который нужно запревентить
 *
 * @param {object} storage - хранилище рефов из refsStorageDecorator
 * @param {function} storeRef - оригинальная функция которую мы оборачиваем
 * @return {function} storeRefWithHOF
 */
function mountUnmountFixForRefs(storage, storeRef) {
  let lastChangedRefId;

  return id => (ref) => {
    const prefRef = storage.current[id];

    if (ref && prefRef && ref !== prefRef) { // 🐦
      lastChangedRefId = id;

      setTimeout(() => {
        lastChangedRefId = undefined;
      }, 100);
    }

    if (lastChangedRefId === id && ref === null) { // 🦉
      return;
    }

    storeRef(id)(ref);
  };
}

export default mountUnmountFixForRefs;
