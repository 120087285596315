export const valid = { isValid: true, errors: [] };

export const DEFAULT_NUMBER_VALIDATOR_ID = '4cf9dfe7e65eb0a2c0c93e199f1ec1cdc23a267d';

export const MAX_LENGTH_FOR_FORMULA_FIELD = 14;

export const emptyValidatorValue = 'none';

export const isEmptyValidator = (validatorId) => {
  return !validatorId || validatorId === emptyValidatorValue;
};

// eslint-disable-next-line
export const VALID_CHARS_REGEXP = /[^a-zA-Z0-9 #\(\)&'_\-:?",./\[\]]/g;

export const VALID_FIRST_CHAR_REGEXP = /[^a-zA-Z0-9]/g;
