import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

export const inputFieldBehaviors = {
  oneline: 'oneline',
  null: null,
};

export const inputFieldOffsets = {
  medium: 'medium',
  mediumHorizontal: 'medium-horizontal',
  smallHorizontal: 'small-horizontal',
  null: null,
};

export const inputFieldSizes = {
  mediumStretched: 'medium-stretched',
  extraLargeStretched: 'extra-large-stretched',
  parent: 'parent',
  null: null,
};

export const inputFieldTags = {
  input: 'input',
  textarea: 'textarea',
};

export const inputFieldThemes = {
  default: 'default',
  transparent: 'transparent',
  lightgray: 'lightgray',
  whiteBordered: 'white-bordered',
  null: null,
};

export const inputFieldWraps = {
  off: 'off',
  null: null,
};

export const inputFieldAligns = {
  center: 'center',
  null: null,
};

const InputField = ({
  attributes,
  behavior,
  isReadOnly,
  maxLength,
  offset,
  onBlur,
  onChange,
  onFocus,
  onPaste,
  placeholder,
  storeRef,
  size,
  tag: Tag,
  theme,
  value,
  defaultValue,
  onClick,
  wrap,
  align,
  ...rest
}) => {
  return (
    <Tag
      className={classnames('input-field', {
        [`input-field--behavior--${behavior}`]: behavior,
        [`input-field--offset--${offset}`]: offset,
        [`input-field--size--${size}`]: size,
        [`input-field--theme--${theme}`]: theme,
        [`input-field--align--${align}`]: align,
      })}
      maxLength={maxLength}
      onClick={onClick}
      onBlur={onBlur}
      onChange={onChange}
      onFocus={onFocus}
      onPaste={onPaste}
      placeholder={placeholder}
      readOnly={isReadOnly}
      ref={storeRef}
      value={value}
      defaultValue={defaultValue}
      wrap={wrap}
      {...attributes}
      {...rest}
    />
  );
};

InputField.propTypes = {
  attributes: PropTypes.shape({}),
  behavior: PropTypes.oneOf(Object.values(inputFieldBehaviors)),
  isReadOnly: PropTypes.bool,
  maxLength: PropTypes.number,
  offset: PropTypes.oneOf(Object.values(inputFieldOffsets)),
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  onClick: PropTypes.func,
  onFocus: PropTypes.func,
  onPaste: PropTypes.func,
  placeholder: PropTypes.string,
  size: PropTypes.oneOf(Object.values(inputFieldSizes)),
  storeRef: PropTypes.func,
  tag: PropTypes.oneOf(Object.values(inputFieldTags)),
  theme: PropTypes.oneOf(Object.values(inputFieldThemes)),
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  defaultValue: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  wrap: PropTypes.oneOf(Object.values(inputFieldWraps)),
  align: PropTypes.oneOf(Object.values(inputFieldAligns)),
};

InputField.defaultProps = {
  attributes: {},
  behavior: null,
  isReadOnly: false,
  maxLength: null,
  offset: null,
  onBlur: null,
  onChange: null,
  onClick: null,
  onFocus: null,
  onPaste: null,
  placeholder: '',
  size: null,
  storeRef: null,
  tag: inputFieldTags.input,
  theme: null,
  wrap: null,
  align: null,
  value: undefined,
  defaultValue: undefined,
};

export default InputField;
