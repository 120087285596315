import PropTypes from 'prop-types';
import { Component } from 'react';
import { promisifyGetRef } from '../../helpers/getRefHelpers';

export default class DetectWindowClickProvider extends Component {
  static propTypes = {
    getRef: PropTypes.func,
    getRefPromise: PropTypes.func,
    getTriggerRef: PropTypes.func,
    getTriggerRefPromise: PropTypes.func,
    onClick: PropTypes.func.isRequired,
    needStopPropagation: PropTypes.bool.isRequired,
  };

  static defaultProps = {
    getRef: null,
    getRefPromise: null,
    getTriggerRef: null,
    getTriggerRefPromise: null,
  };

  componentDidMount() {
    document.addEventListener('click', this.clickProxy, true);
    document.addEventListener('touchstart', this.clickProxy, true);
  }

  componentWillUnmount() {
    document.removeEventListener('click', this.clickProxy, true);
    document.removeEventListener('touchstart', this.clickProxy, true);
  }

  clickProxy = async (event) => {
    const element = await promisifyGetRef(
      this.props.getRef,
      this.props.getRefPromise,
    );

    const triggerElement = await promisifyGetRef(
      this.props.getTriggerRef,
      this.props.getTriggerRefPromise,
    );

    const clickOnPortalContent = element.contains(event.target);
    const clickOnTriggerElement = triggerElement.contains(event.target);
    // select рендерится в отдельном портале и клик по нему расценивается как клик вне
    // нужной нам области
    // TODO: это костыльное решение. Необходимо продумать более универсальное
    const isClickOnSelectOption = event.target.classList.contains('Select-option');

    if (
      event.target !== element &&
      !clickOnPortalContent &&
      !clickOnTriggerElement &&
      !isClickOnSelectOption
    ) {
      this.props.onClick(event);

      if (this.props.needStopPropagation) {
        event.stopPropagation();
      }
    }
  };

  render() {
    return null;
  }
}
