import trim from 'lodash/trim';
import uniq from 'lodash/uniq';
import get from 'lodash/get';

import { isFillable, isRadioElement } from '../../../store/helpers/functions';
import { isIntegrationField } from '../../../helpers/utils';
import { elementSettingsTypes } from '../../../helpers/elemTypes';
import * as consts from '../../const';

export const fieldNameValidators = {
  emptyName: {
    isValid: (value) => {
      // salesforce/smart field has null name after creation
      if (value === null) {
        return false;
      }

      return value.trim() !== '';
    },
    message: (value, formatMessage) => {
      return formatMessage('', 'FIELD_NAME.UPDATE_FIELD_NAME_ERROR', 'Empty field name is not allowed');
    },
  },
  validFirstCharacter: {
    isValid: (value) => {
      if (value === 'undefined') {
        return true;
      }
      return !consts.VALID_FIRST_CHAR_REGEXP.test(trim(value).substring(0, 1));
    },
    message: (value, formatMessage) => {
      const invalidCharacters = uniq(
        trim(value).substring(0, 1).match(consts.VALID_FIRST_CHAR_REGEXP),
      );
      return formatMessage('', 'FIELD_NAME.INVALID_CHARACTERS', invalidCharacters.join(' '));
    },
  },
  validCharacters: {
    isValid: (value) => {
      if (value === 'undefined') {
        return true;
      }
      return !consts.VALID_CHARS_REGEXP.test(trim(value));
    },
    message: (value, formatMessage) => {
      if (value === 'undefined') {
        return true;
      }
      const invalidCharacters = uniq(value.match(consts.VALID_CHARS_REGEXP));
      return formatMessage('', 'FIELD_NAME.INVALID_CHARACTERS', invalidCharacters.join(' '));
    },
  },
  duplicate: {
    isValid: (value, element, elements) => {
      // salesforce and smart fields may have not unique names
      if (isIntegrationField(element)) {
        return true;
      }

      // ищем дубликаты имен:
      // - в случае, если не радио элементы содержат одинаковое имя
      // - в случае, если не радио элемент имеет такое же имя как радио группа
      // - в случае, если две разных радиогруппы содержат одинковое имя радиокнопок
      const sameNameFillableElements = elements.filter((elem) => {
        return (
          isFillable(elem) &&
          !isIntegrationField(elem) &&
          elem.id !== element.id &&
          elem.template.name === value
        );
      });
      if (!sameNameFillableElements.length) {
        return true;
      }

      if (!isRadioElement(element)) {
        return false;
      }

      const radioGroupId = get(element, 'template.radioGroupId', '');

      return sameNameFillableElements.every((elem) => {
        return elem.template.radioGroupId === radioGroupId;
      });
    },
    message: (value, formatMessage) => {
      return formatMessage('', 'FIELD_NAME.DUPLICATE_NAME_ERROR', value);
    },
  },
};

export const validateFieldName = (element, validateField) => {
  const value = get(element, 'template.name', '');
  const field = elementSettingsTypes.uniqueFieldName;

  return validateField(value, field, fieldNameValidators);
};
