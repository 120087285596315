/* <JSF selectors /> */
const getIsCommentsLoaded = (state) => {
  return state.lazyLoad.comments;
};
const getIsFakeEditLoaded = (state) => {
  return state.lazyLoad.fakeEdit;
};
const getIsRearrangeLoaded = (state) => {
  return state.lazyLoad.rearrange;
};
const getIsManagersLoaded = (state) => {
  return state.lazyLoad.managers;
};
const getIsFeedbackLoaded = (state) => {
  return state.lazyLoad.feedback;
};
/* </JSF selectors> */

/* <SNF selectors> */
// ...
/* <SNF selectors /> */

/* <Common selectors> */
const getIsConstructorLoaded = (state) => {
  return state.lazyLoad.fConstructor;
};
const getIsWizardLoaded = (state) => {
  return state.lazyLoad.wizard;
};
const getIsFormulaLoaded = (state) => {
  return state.lazyLoad.formula;
};
const getIsVersionsLoaded = (state) => {
  return state.lazyLoad.versions;
};
/* <Common selectors /> */

export const jsf = {
  getIsManagersLoaded,
  getIsRearrangeLoaded,
  getIsCommentsLoaded,
  getIsFakeEditLoaded,
  getIsFeedbackLoaded,
};

export const snf = {};

export const common = {
  getIsConstructorLoaded,
  getIsWizardLoaded,
  getIsFormulaLoaded,
  getIsVersionsLoaded,
};
