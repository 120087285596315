import PropTypes from 'prop-types';
import React, { Component } from 'react';

export default class EditorInfoItem extends Component {
  static propTypes = {
    children: PropTypes.node,

    onMouseDown: PropTypes.func,
    onClick: PropTypes.func,
    onMouseEnter: PropTypes.func,
    onMouseLeave: PropTypes.func,
    onTouchStart: PropTypes.func,
    storeRef: PropTypes.func,
  };

  static defaultProps = {
    children: null,
    onMouseDown: null,
    onClick: null,
    onMouseEnter: null,
    onMouseLeave: null,
    onTouchStart: null,
    storeRef: null,
  };

  render() {
    return (
      <div
        ref={this.props.storeRef}
        className="editor-info__item"
        onMouseDown={this.props.onMouseDown}
        onClick={this.props.onClick}
        onMouseEnter={this.props.onMouseEnter}
        onMouseLeave={this.props.onMouseLeave}
        onTouchStart={this.props.onTouchStart}
      >
        {this.props.children}
      </div>
    );
  }
}
