import rootSaga from './index';

const run = (sagaMiddleware) => {
  let sagaTask = sagaMiddleware.run(rootSaga);

  if (__CLIENT__) {
    window.sagaMiddleware = sagaMiddleware;
  }

  if (module.hot) {
    module.hot.accept('./index', () => {
      sagaTask.cancel();
      sagaTask.done.then(() => {
        sagaTask = sagaMiddleware.run(rootSaga);
      });
    });
  }
};

export default run;
