import { stopEvent } from '../../helpers/utils';

export const RESET_ALL_REDUCERS = 'all/RESET_ALL_REDUCERS';
export const SET_PAGE_PANNING = 'events/SET_PAGE_PANNING';
export const SET_DRAG = 'events/SET_DRAG';
export const SET_PAGE_PINCHING = 'events/SET_PAGE_PINCHING';
export const SET_PAGE_CHANGING = 'events/SET_PAGE_CHANGING';
export const SET_PAGE_SCALING = 'events/SET_PAGE_SCALING';
export const SET_GHOST = 'events/SET_GHOST';
export const SET_SCROLL_DISABLED = 'events/SET_SCROLL_DISABLED';
export const FORCE_FOCUS_TEXT_ELEMENT = 'events/FORCE_FOCUS_TEXT_ELEMENT';
export const SET_IS_DRAWING_NEW_GRAPHIC_ELEMENT = 'events/SET_IS_DRAWING_NEW_GRAPHIC_ELEMENT';
export const SET_STRETCHING_ELEMENT_ID =
  'events/SET_STRETCHING_ELEMENT_ID';
export const TRIGGER_FIND_ANOTHER_FORM_CLICK = 'events/TRIGGER_FIND_ANOTHER_FORM_CLICK';
export const TRIGGER_MESSAGE_BUTTON_CLICK = 'events/TRIGGER_MESSAGE_BUTTON_CLICK';
export const TRIGGER_DECLINE_BUTTON_CLICK = 'events/TRIGGER_DECLINE_BUTTON_CLICK';
export const TRIGGER_DRAFT_BUTTON_CLICK = 'events/TRIGGER_DRAFT_BUTTON_CLICK';
export const TRIGGER_ON_DONE_SCENARIO = 'events/TRIGGER_ON_DONE_SCENARIO';
export const TRIGGER_DROPDOWN_ITEMS_LIST_FOCUS = 'events/TRIGGER_DROPDOWN_ITEMS_LIST_FOCUS';

export const SET_FILLABLE_PICTURE_SELECTING_ID = 'events/SET_FILLABLE_PICTURE_SELECTING_ID';
export const SET_GHOST_POS = 'events/SET_GHOST_POS';

export const SET_APP_STARTED = 'events/SET_APP_STARTED';
export const SET_FATAL_ERROR = 'events/SET_FATAL_ERROR';

export const SET_LAST_SIGN = 'events/SET_LAST_SIGN';
export const SET_LAST_INIT = 'events/SET_LAST_INIT';

export const ZOOM_IN_BY_KEYBOARD = 'events/ZOOM_IN_BY_KEYBOARD';
export const ZOOM_OUT_BY_KEYBOARD = 'events/ZOOM_OUT_BY_KEYBOARD';

export const TOOL_DRAGGING_STARTED = 'events/TOOL_DRAGGING_STARTED';
export const TOOL_DRAGGING_STOPED = 'events/TOOL_DRAGGING_STOPED';

export const ON_OK_CLICKED = 'events/ON_OK_CLICKED';

export const ON_DROPDOWN_TOOL_OPEN = 'events/ON_DROPDOWN_TOOL_OPEN';
export const ON_DROPDOWN_TOOL_CLOSE = 'events/ON_DROPDOWN_TOOL_CLOSE';

export const ON_DB_FIELD_NAME_FOCUS = 'events/ON_DB_FIELD_NAME_FOCUS';
export const ON_DB_FIELD_NAME_BLUR = 'events/ON_DB_FIELD_NAME_BLUR';

const initialState = {
  appStarted: false,
  triggerFindAnotherFormClick: false,
  triggerMessageButtonClick: false,
  triggerDeclineButtonClick: false,
  triggerDraftButtonClick: false,
  triggerDropdownItemsListFocus: false,

  pagePanning: false,
  pagePinching: false,
  pageChanging: false,
  pageScaling: false,

  dragElementId: false, // here is ID of dragging item, false is no dragging item
  ok: false,
  isScrollDisabled: false,
  forceFocusElementObject: false,

  isDrawingNewGraphicElement: false,

  // here is ID of graphic item (line, arrow)
  // that is dragging the EndPoints now, false is no dragging item
  stretchingElementId: false,
  triggerOnDoneScenario: {},

  // ID of fillable picture/sign, waiting for dialog response
  fillablePictureSelectingId: false,

  // save mouse pos when choose picture/signature to draw ghost immediately
  ghostPos: false,

  fatalError: false,

  isToolDragging: false,

  // save DropdownTool state
  isDropdownToolOpen: false,

  // save DbFieldName input state,
  isDbFieldNameFocused: false,
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case RESET_ALL_REDUCERS:
      return initialState;
    case TRIGGER_FIND_ANOTHER_FORM_CLICK:
      return {
        ...state,
        triggerFindAnotherFormClick: {},
      };
    case SET_PAGE_PANNING:
      return {
        ...state,
        pagePanning: action.value,
      };
    case SET_DRAG:
      return {
        ...state,
        dragElementId: action.value,
      };
    case SET_PAGE_PINCHING:
      return {
        ...state,
        pagePinching: action.value,
      };
    case SET_PAGE_CHANGING:
      return {
        ...state,
        pageChanging: action.value,
      };
    case SET_PAGE_SCALING:
      return {
        ...state,
        pageScaling: action.value,
      };
    case SET_SCROLL_DISABLED:
      return {
        ...state,
        isScrollDisabled: action.value,
      };
    case FORCE_FOCUS_TEXT_ELEMENT:
      return {
        ...state,
        forceFocusElementObject: { id: action.id },
      };
    case SET_IS_DRAWING_NEW_GRAPHIC_ELEMENT:
      return {
        ...state,
        isDrawingNewGraphicElement: action.value,
      };
    case SET_STRETCHING_ELEMENT_ID:
      return {
        ...state,
        stretchingElementId: action.value,
      };
    case SET_FILLABLE_PICTURE_SELECTING_ID:
      return {
        ...state,
        fillablePictureSelectingId: action.value,
      };
    case SET_GHOST_POS:
      return {
        ...state,
        ghostPos: action.pos,
      };
    case SET_FATAL_ERROR:
      return {
        ...state,
        fatalError: action.error,
      };
    case SET_APP_STARTED:
      return {
        ...state,
        appStarted: true,
      };
    case TRIGGER_MESSAGE_BUTTON_CLICK:
      return {
        ...state,
        triggerMessageButtonClick: {},
      };
    case TRIGGER_DECLINE_BUTTON_CLICK:
      return {
        ...state,
        triggerDeclineButtonClick: {},
      };
    case TRIGGER_DRAFT_BUTTON_CLICK:
      return {
        ...state,
        triggerDraftButtonClick: {},
      };
    case TRIGGER_DROPDOWN_ITEMS_LIST_FOCUS:
      return {
        ...state,
        triggerDropdownItemsListFocus: action.value,
      };
    case TRIGGER_ON_DONE_SCENARIO:
      return {
        ...state,
        triggerOnDoneScenario: {
          callback: action.callback,
        },
      };

    case TOOL_DRAGGING_STARTED:
      return {
        ...state,
        isToolDragging: true,
      };
    case TOOL_DRAGGING_STOPED:
      return {
        ...state,
        isToolDragging: false,
      };

    case ON_DROPDOWN_TOOL_OPEN:
      return {
        ...state,
        isDropdownToolOpen: true,
      };

    case ON_DROPDOWN_TOOL_CLOSE:
      return {
        ...state,
        isDropdownToolOpen: false,
      };

    case ON_DB_FIELD_NAME_FOCUS:
      return {
        ...state,
        isDbFieldNameFocused: true,
      };

    case ON_DB_FIELD_NAME_BLUR:
      return {
        ...state,
        isDbFieldNameFocused: false,
      };

    default:
      return state;
  }
}

export function triggerFindAnotherFormClick() {
  return { type: TRIGGER_FIND_ANOTHER_FORM_CLICK };
}

export function setPagePanning(value) {
  return { type: SET_PAGE_PANNING, value };
}

export function setDrag(value) {
  return { type: SET_DRAG, value };
}

export function setPagePinching(value) {
  return { type: SET_PAGE_PINCHING, value };
}

export function setPageChanging(value) {
  return { type: SET_PAGE_CHANGING, value };
}

export function setPageScaling(value) {
  return { type: SET_PAGE_SCALING, value };
}

export function setScrollDisabled(value) {
  return { type: SET_SCROLL_DISABLED, value };
}

export function setForceFocusElement(id) {
  return { type: FORCE_FOCUS_TEXT_ELEMENT, id };
}

export function zoomInByKeyboard() {
  return { type: ZOOM_IN_BY_KEYBOARD };
}

export function zoomOutByKeyboard() {
  return { type: ZOOM_OUT_BY_KEYBOARD };
}

export function setIsDrawingNewGraphicElement(value) {
  return { type: SET_IS_DRAWING_NEW_GRAPHIC_ELEMENT, value };
}

export function setStretchingElementId(value) {
  return { type: SET_STRETCHING_ELEMENT_ID, value };
}

export function setFillablePictureSelectingId(value) {
  return { type: SET_FILLABLE_PICTURE_SELECTING_ID, value };
}

export function setGhostPos(pos) {
  return { type: SET_GHOST_POS, pos };
}

export function setFatalError(error) {
  return { type: SET_FATAL_ERROR, error };
}

export function setAppStarted() {
  return { type: SET_APP_STARTED };
}

export function triggerMessageButtonClick() {
  return { type: TRIGGER_MESSAGE_BUTTON_CLICK };
}

export function triggerDeclineButtonClick() {
  return { type: TRIGGER_DECLINE_BUTTON_CLICK };
}

export function triggerDraftButtonClick() {
  return { type: TRIGGER_DRAFT_BUTTON_CLICK };
}

export function triggerOnDoneScenario(callback) {
  return { type: TRIGGER_ON_DONE_SCENARIO, callback };
}

export function triggerDropdownItemsListFocus(value) {
  return { type: TRIGGER_DROPDOWN_ITEMS_LIST_FOCUS, value };
}

export function resetAllReducers(restore) {
  return { type: RESET_ALL_REDUCERS, restore };
}

export function toolDraggingStarted() {
  return { type: TOOL_DRAGGING_STARTED };
}

export function toolDraggingStopped() {
  return { type: TOOL_DRAGGING_STOPED };
}

export function onOkClicked(event) {
  if (event) {
    stopEvent(event);
  }
  return { type: ON_OK_CLICKED };
}

export function onDropdownToolOpen() {
  return {
    type: ON_DROPDOWN_TOOL_OPEN,
  };
}

export function onDropdownToolClose() {
  return {
    type: ON_DROPDOWN_TOOL_CLOSE,
  };
}

export function onDbFieldNameFocus() {
  return {
    type: ON_DB_FIELD_NAME_FOCUS,
  };
}

export function onDbFieldNameBlur() {
  return {
    type: ON_DB_FIELD_NAME_BLUR,
  };
}
