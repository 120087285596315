import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

const SideBar = ({
  children,
  customClassnames,
  dataPagination,
  size,
  theme,
}) => {
  return (
    <div
      className={classnames(
        customClassnames,
        'sidebar',
        {
          [`sidebar--size--${size}`]: size,
          [`sidebar--theme--${theme}`]: theme,
        },
      )}
      data-pagination={dataPagination}
    >
      <div className="sidebar__body">
        {children}
      </div>
    </div>
  );
};

const sideBarSizes = {
  null: null,
  large: 'large',
  extraLarge: 'extra-large',
  medium: 'medium',
};

const sideBarThemes = {
  lightgray: 'lightgray',
  null: null,
};

SideBar.propTypes = {
  children: PropTypes.node.isRequired,
  customClassnames: PropTypes.arrayOf(
    PropTypes.string,
  ),
  size: PropTypes.oneOf(Object.values(sideBarSizes)),
  theme: PropTypes.oneOf(Object.values(sideBarThemes)),
  dataPagination: PropTypes.bool,
};

SideBar.defaultProps = {
  customClassnames: [],
  size: sideBarSizes.null,
  theme: sideBarThemes.null,
  dataPagination: false,
};

export default SideBar;
export { sideBarSizes, sideBarThemes };
