import { eventChannel, delay } from 'redux-saga';
import {
  all,
  call,
  takeEvery,
  select,
  put,
  fork,
  take,
} from 'redux-saga/effects';
import superagent from 'superagent';
import websocketClient from 'websocket-client';
import * as operationsMapper from 'ws-editor-lib/controllers/NetController/mapper';
import {
  destroy,
  setOrganization,
  operationReceive,
  authReceive,
  authInit,
  saveVersion,
  authRestore,
} from 'ws-editor-lib/actions';
import * as combineModalsActions from 'combine-modals/lib/actions/actionCreator';
import getLoaderApi from '@pdffiller/jsf-loader/api';
import { sessionsManager } from '@pdffiller/jsf-loader/JSFillerStores';

import {
  triggerOnDoneScenario,
  resetAllReducers,
  SET_APP_STARTED,
} from 'jsfcore/store/modules/events';
import { selectors, thunks } from 'jsfcore';
import pdfDocumentStore from 'jsfcore/store/helpers/pdfDocumentStore';
import { toggleProcessingModalOnAppStarted } from 'jsfcore/store/sagas/appStartedSaga';
import {
  EMBEDDED,
  cbsTypes,
} from 'jsfcore/helpers/const/interface';
import { onDoneScenarioString } from 'jsfcore/helpers/const';
import authSaga from './authSaga';

// Ready state
let alreadyInitialized = false;

// Callbacks
const cbs = {};

const emptyCb = () => {};

const setCallback = (type) => {
  return (fn) => {
    if (!__CLIENT__ || !fn) {
      return;
    }
    cbs[type] = fn;
  };
};

export const getCallback = (type, defaultCb = emptyCb) => {
  if (!__CLIENT__) {
    return emptyCb;
  }
  return cbs[type] || defaultCb;
};

if (__CLIENT__) {
  window.JSFiller = {
    ready: (callback) => {
      setCallback(cbsTypes.ready)(callback);
      if (alreadyInitialized) {
        getCallback(cbsTypes.ready, emptyCb)();
      }
    },
  };
}

export function* deactivateActiveElement() {
  const activeElementId = yield select(selectors.base.getActiveElementId);

  if (activeElementId !== false) {
    yield put(thunks.setActiveElement(activeElementId, false, { withoutHint: true }));
  }
}

export function* onDestroyReceived(redirectUrl, destroyCallback, saveChanges) {
  setCallback(cbsTypes.destroy)(destroyCallback);

  yield call(deactivateActiveElement);
  yield delay(300);

  const restDestroyArgs = [true, undefined, undefined, saveChanges];
  yield put(destroy(redirectUrl || EMBEDDED, ...restDestroyArgs));
}

// https://pdffiller.atlassian.net/browse/JSF-5271
// need for sending organization name if it's not received on auth
export function getOrganization() {
  const { host } = window.location;
  const [organization] = host.split('.');

  return organization;
}

/**
 * @param {Object} param params of document
 * @param {string} param.projectId unique identificator of project
 * @param {string} param.viewerId unique identificator of viewer
 * @param {Function} param.openCallback
 * @param {string} [param.organization=getOrganization()]
 */
export function* onOpenReceived({
  projectId,
  viewerId,
  openCallback,
  organization = getOrganization(),
}) {
  setCallback(cbsTypes.open)(openCallback);

  const project = { projectId, viewerId, organization };

  // before auth
  yield put(combineModalsActions.closeModal('DialogModal'));
  yield put(combineModalsActions.closeModal('FeedbackModal'));

  getLoaderApi().start();

  const ws = websocketClient.newInstance();
  const sessionId = sessionsManager.createSession(ws, project);
  yield put(resetAllReducers({ store: true }));

  yield put(setOrganization(organization));
  yield fork(authSaga, project);
  // after auth
  yield call(toggleProcessingModalOnAppStarted);
  const appStarted = yield select(selectors.base.getAppStarted);
  if (!appStarted) {
    yield take(SET_APP_STARTED);
  }
  const scenarios = yield select(selectors.base.getWsScenarios);
  if (!scenarios) {
    yield take('@@ws-editor-lib/ON_OPERATIONS_EDITOR_SCENARIOS');
  }

  getCallback(cbsTypes.open)(sessionId);
}

/**
 * @param {string} sessionId unique identificator of session
 * @param {Function} callback
 */
export function* onOpenBySessionIdReceived(
  sessionId,
  callback,
) {
  let error = null;

  setCallback(cbsTypes.open)(callback);

  yield put(combineModalsActions.closeAllModals());

  const session = sessionsManager.getSessionById(sessionId);

  if (!session) {
    error = new Error(`SessionManager: can't find session with id: ${sessionId}`);
  } else {
    yield put(combineModalsActions.closeModal('DialogModal'));
    getLoaderApi().start();

    yield put(resetAllReducers(session.state));

    sessionsManager.setSessionById(sessionId);

    yield put(authReceive(session.auth));

    yield put(authRestore({ ...session.project, sessionHash: sessionId, uid: session.uid }));

    const appStarted = yield select(selectors.base.getAppStarted);
    if (!appStarted) {
      yield take(SET_APP_STARTED);
    }
    const scenarios = yield select(selectors.base.getWsScenarios);
    if (!scenarios) {
      yield take('@@ws-editor-lib/ON_OPERATIONS_EDITOR_SCENARIOS');
    }
  }

  getCallback(cbsTypes.open)(sessionId, error);
}

export function* onDoneReceived(redirectUrl, callback, saveChanges) {
  const emptyDropDownElements = yield select(selectors.getEmptyDropDownElements);
  const isConstructorIncludingPreview = yield select(selectors.mode.isConstructor.includingPreview);

  // JSF-5225 need to show lock modal only in constructor mode
  if (emptyDropDownElements.length > 0 && isConstructorIncludingPreview) {
    yield put(thunks.showDropdownIsEmptyModal(emptyDropDownElements[0], callback));
  } else {
    setCallback(cbsTypes.destroy)(callback);

    yield call(deactivateActiveElement);
    yield delay(300);

    const restDestroyArgs = [true, undefined, undefined, saveChanges];

    if (redirectUrl) {
      yield put(destroy(redirectUrl, ...restDestroyArgs));
      return;
    }

    const doneButton = yield select(selectors.base.getDoneButton);
    if (doneButton && doneButton.main.location === onDoneScenarioString) {
      yield put(triggerOnDoneScenario(callback));
    } else {
      yield put(destroy(doneButton && doneButton.main.location, ...restDestroyArgs));
    }
  }
}

export function* onSaveReceived({ saveCallback }) {
  setCallback(cbsTypes.save)(saveCallback);

  yield call(deactivateActiveElement);
  yield delay(300);

  yield put(saveVersion());
}

export function saved() {
  return getCallback(cbsTypes.save, emptyCb)();
}

const getReceivedObjOrStr = (received) => {
  const result = received.map(async (elem) => {
    if (typeof elem === 'string') {
      const response = await superagent.get(elem);
      return response.body;
    }

    return elem;
  });

  return result;
};

// Функция которая кладет arrayBuffer в хранилище для пдф
// Получает конфиги для авторизации и всех операций из сокетов
function* receiveActions({ arrayBuffer, authParams, receivedOperations }) {
  pdfDocumentStore.setLocalPdf(arrayBuffer);

  // Входной формат либо аррей из строка-ссылок на json
  // либо сам аррей у которой состоит из объектов у которых в ключах либо
  // auth, либо operations

  // yield all - из-за деприкейшн ворнинга:
  // `[...effects] has been deprecated in favor of all([...effects])`
  const operations = yield all(getReceivedObjOrStr(receivedOperations));

  yield put(authInit(authParams));

  const allOperations = operations.reduce((acc, operation) => {
    if (operation.auth) {
      acc.push(
        put(authReceive(operation.auth)),
      );
    }

    if (operation.operations) {
      // TODO: Удалить group: document, type: source,
      // если он есть
      acc.push(put(
        operationReceive(operationsMapper.mapFromBackend(operation.operations)),
      ));
    }

    return acc;
  }, []);

  yield all(allOperations);
}

// Документация по использованию оффлайн интерфейса:
// https://github.com/pdffiller/jsfiller/blob/develop/docs/offlineMode.md
export function* onOpenPdfOfflineReceived({
  arrayBuffer,
  openCallback,
  receivedOperations,
  authParams,
}) {
  setCallback(cbsTypes.open)(openCallback);
  getLoaderApi().start();
  yield call(receiveActions, { arrayBuffer, authParams, receivedOperations });
  getCallback(cbsTypes.open)();
}

export function* destroyed() {
  const statePos = yield select((state) => {
    return state;
  });

  const stateFunc = () => {
    return statePos;
  };

  return getCallback(cbsTypes.destroy, stateFunc)();
}

export function* openPdfOfflineLocally() {
  const resp = yield superagent
    .get('w9/w9.pdf')
    .responseType('arraybuffer');

  yield call(onOpenPdfOfflineReceived, {
    arrayBuffer: resp.body,
    authParams: { viewerId: null, config: {} },
    receivedOperations: [
      'w9/operations.json',
      'w9/operations.1.json',
      'w9/operations.2.json',
      'w9/operations.3.json',
    ],
    openCallback: () => {},
  });
}

export const actions = {
  open: onOpenReceived,
  openPdfOffline: onOpenPdfOfflineReceived,
  openBySessionId: onOpenBySessionIdReceived,
  setMaxSessionsCount: sessionsManager.setMaxSessionsCount,
  getIsSessionExistByProjectId: sessionsManager.getIsSessionExistByProjectId,
  done: onDoneReceived,
  save: onSaveReceived,
  destroy: onDestroyReceived,
};

export const createInterfaceChannel = () => {
  return eventChannel((emitter) => {
    Object.keys(actions).forEach((action) => {
      window.JSFiller[action] = (...args) => {
        // NOTE: Не удаляйте этот лог, он нужен для того,
        // чтобы saga реагировала нормально на вызовы window.JSFiller[action] из
        // консоли. Workaround

        // eslint-disable-next-line no-console
        console.log(`JSFiller interface function "${action}" called with args`, args);
        emitter({ action, args: args || [] });
      };
    });
    getCallback(cbsTypes.ready, emptyCb)();
    alreadyInitialized = true;

    return () => {};
  });
};

export function* onChannelMessage({ action, args = [] }) {
  if (actions[action]) {
    yield call(actions[action], ...args);
  } else {
    // eslint-disable-next-line no-console
    console.warn('JSInterface unknown action', { action });
  }
}

export default function* interfaceRootSaga() {
  const interfaceChannel = yield call(createInterfaceChannel);
  yield takeEvery(interfaceChannel, onChannelMessage);
}
