import { closeModal } from 'combine-modals/lib/actions/actionCreator';
import { toggleSignatureManager } from '../modules/viewport';
import { SIGNATURE_MANAGER_MODAL_ID } from '../../helpers/const';

const closeSignatureManager = () => {
  return (dispatch) => {
    dispatch(closeModal(SIGNATURE_MANAGER_MODAL_ID));
    dispatch(toggleSignatureManager());
  };
};

export default closeSignatureManager;
