import { modulesJsf as modules } from './const';

export function generateModulePromise(moduleName) {
  // has to be static
  switch (moduleName) {
    case modules.wizard:
      return import(
        /* webpackChunkName: "wizard" */
        // eslint-disable-next-line comma-dangle
        'modules/wizard'
      );
    case modules.managers:
      return import(
        /* webpackChunkName: "managers" */
        // eslint-disable-next-line comma-dangle
        'modules/managers'
      );
    case modules.fConstructor:
      return import(
        /* webpackChunkName: "fConstructor" */
        // eslint-disable-next-line comma-dangle
        'modules/fConstructor'
      );
    case modules.rearrange:
      return import(
        /* webpackChunkName: "rearrange" */
        // eslint-disable-next-line comma-dangle
        'modules/rearrange'
      );
    case modules.comments:
      return import(
        /* webpackChunkName: "comments" */
        // eslint-disable-next-line comma-dangle
        'modules/comments'
      );
    case modules.fakeEdit:
      return import(
        /* webpackChunkName: "fakeEdit" */
        // eslint-disable-next-line comma-dangle
        'modules/fakeEdit'
      );
    case modules.formula:
      return import(
        /* webpackChunkName: "formula" */
        // eslint-disable-next-line comma-dangle
        '@pdffiller/jsf-formula'
      );
    case modules.versions:
      return import(
        /* webpackChunkName: "versions" */
        // eslint-disable-next-line comma-dangle
        '@pdffiller/jsf-versions'
      );
    case modules.feedback:
      return import(
        /* webpackChunkName: "feedback" */
        // eslint-disable-next-line comma-dangle
        '@pdffiller/jsf-feedback'
      );
    default:
      return Promise.reject(
        new Error(`${moduleName} is not defined in modulesJsf.js`),
      );
  }
}

export function runAcceptFunc(moduleName, callback) {
  // has to be static
  switch (moduleName) {
    case modules.wizard:
      return module.hot.accept('modules/wizard', callback);
    case modules.managers:
      return module.hot.accept('modules/managers', callback);
    case modules.fConstructor:
      return module.hot.accept('modules/fConstructor', callback);
    case modules.rearrange:
      return module.hot.accept('modules/rearrange', callback);
    case modules.comments:
      return module.hot.accept('modules/comments', callback);
    case modules.fakeEdit:
      return module.hot.accept('modules/fakeEdit', callback);
    case modules.formula:
      return module.hot.accept('@pdffiller/jsf-formula', callback);
    case modules.versions:
      return module.hot.accept('@pdffiller/jsf-versions', callback);
    case modules.feedback:
      return module.hot.accept('@pdffiller/jsf-feedback', callback);
    default:
      return null;
  }
}
