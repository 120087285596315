import { reducer as jsfcoreModalsReducer } from '../../jsf-modals';

const initialState = {
  modalData: {},
  ...jsfcoreModalsReducer.confirmationModalInitialState,
  ...jsfcoreModalsReducer.warningModalInitilState,
  ...jsfcoreModalsReducer.radioModalInitialState,
  ...jsfcoreModalsReducer.radioRemoveModalInitialState,
};

export default function modalsReducer(state = initialState, action = {}) {
  switch (action.type) {
    case jsfcoreModalsReducer.SHOW_CONFIRMATION_MODAL:
      return jsfcoreModalsReducer.handleShowConfirmationModal(state, action);

    case jsfcoreModalsReducer.HIDE_CONFIRMATION_MODAL:
      return jsfcoreModalsReducer.handleHideConfirmationModal(state, action);

    case jsfcoreModalsReducer.SHOW_WARNING_MODAL:
      return jsfcoreModalsReducer.handleShowWarningModal(state, action);

    case jsfcoreModalsReducer.HIDE_WARNING_MODAL:
      return jsfcoreModalsReducer.handleHideWarningModal(state, action);

    case jsfcoreModalsReducer.SHOW_RADIO_MODAL:
      return jsfcoreModalsReducer.handleShowRadioModal(state, action);

    case jsfcoreModalsReducer.HIDE_RADIO_MODAL:
      return jsfcoreModalsReducer.handleHideRadioModal(state, action);

    case jsfcoreModalsReducer.SHOW_RADIO_REMOVE_MODAL:
      return jsfcoreModalsReducer.handleShowRadioRemoveModal(state, action);

    case jsfcoreModalsReducer.HIDE_RADIO_REMOVE_MODAL:
      return jsfcoreModalsReducer.handleHideRadioRemoveModal(state, action);

    default:
      return state;
  }
}
