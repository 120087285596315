import isArray from 'lodash/isArray';

const findPathsInElement = (name, value, dependency, path = '0') => {
  const paths = [];
  if (dependency.clauses) {
    for (let i = 0; i < dependency.clauses.length; i++) {
      const pathsInElement = findPathsInElement(
        name, value, dependency.clauses[i], `${path}.${i}`,
      );
      if (isArray(pathsInElement)) {
        pathsInElement.forEach((p) => {
          paths.push(p);
        });
      } else if (pathsInElement) {
        paths.push(pathsInElement);
      }
    }
  } else if (
    dependency.name === name &&
    (
      !value ||
      dependency.value === value
    )
  ) {
    return path;
  }

  return paths;
};

const findDependentElements = (name, elements, value) => {
  const result = [];
  for (let i = 0; i < elements.length; i++) {
    const el = elements[i];
    const pathsInElement = findPathsInElement(name, value, el.template.dependency);
    if (isArray(pathsInElement)) {
      pathsInElement.forEach((path) => {
        result.push({
          id: el.id,
          path,
        });
      });
    } else if (pathsInElement) {
      result.push({
        id: el.id,
        path: pathsInElement,
      });
    }
  }
  return result;
};

export default findDependentElements;
