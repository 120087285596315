import {
  showConfirmationModal,
  hideConfirmationModal,

  showWarningModal,
  hideWarningModal,

  showRadioModal,
  hideRadioModal,

  showRadioRemoveModal,
  hideRadioRemoveModal,
} from './modalsReducer';

export const modalTypes = {
  confirmation: 'confirmation',
  warning: 'warning',

  radio: 'radio',
  radioRemove: 'radioRemove',
};

export const confirmationTypes = {
  removeConditions: 'removeConditions',
  updateConditions: 'updateConditions',
  updateRadio: 'updateRadio',
  removeRequestPayment: 'removeRequestPayment',
  changeSignerCrossRolesWarning: 'changeSignerCrossRolesWarning',
};

export const warningTypes = {
  removeWarning: 'removeWarning',
  circularWarning: 'circularWarning',
  removePaymentWarning: 'removePaymentWarning',
  removeFormulaFieldWarning: 'removeFormulaFieldWarning',
  setConditionalOrChangeDBName: 'setConditionalOrChangeDBName',
  changeSignerCrossRolesWarning: 'changeSignerCrossRolesWarning',
  restrictedOptions: 'restrictedOptions',
  preAssignedSignatureError: 'preAssignedSignatureError',
};

export const modalsMap = {
  [modalTypes.confirmation]: {
    type: modalTypes.confirmation,
    open: showConfirmationModal,
    close: hideConfirmationModal,
  },
  [modalTypes.warning]: {
    type: modalTypes.warning,
    open: showWarningModal,
    close: hideWarningModal,
  },
  [modalTypes.radio]: {
    type: modalTypes.radio,
    open: showRadioModal,
    close: hideRadioModal,
  },
  [modalTypes.radioRemove]: {
    type: modalTypes.confirmation,
    open: showRadioRemoveModal,
    close: hideRadioRemoveModal,
  },
};
