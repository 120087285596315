import includes from 'lodash/includes';
import findIndex from 'lodash/findIndex';
import zipWith from 'lodash/zipWith';

import logger from '@pdffiller/jsf-logger/clientLogger';
import { actionTypes } from 'ws-editor-lib/actions';
import { getArrayOfNull, isSignNow } from '../../helpers/utils';
import { RESET_ALL_REDUCERS } from './events';
import { RESET_FOR_RELOAD_PDF } from './actions';
import {
  outputScale,
  maxCanvasSize,
  thumbnailMaxWidth,
  initialCanvasContainerWidth,
  initialCanvasContainerHeight,
  initialCanvasBackgroundColor,
} from '../../helpers/const';

import canvasStore from '../helpers/canvasStore';
import pdfPagesStore from '../helpers/pdfPagesStore';
import pdfDocumentStore from '../helpers/pdfDocumentStore';
import pdfTextStore from '../helpers/pdfTextStore';
import profiler from '../../helpers/profiler';
import * as selectors from '../selectors/selectors';

const log = logger.getLogger('middleware');

export const GET_DOCUMENT = 'pdf/GET_DOCUMENT';
export const GET_DOCUMENT_SUCCESS = 'pdf/GET_DOCUMENT_SUCCESS';
export const GET_DOCUMENT_FAILED = 'pdf/GET_DOCUMENT_FAILED';

export const SET_NEED_GET = 'pdf/SET_NEED_GET';
export const SET_NEED_RENDER = 'pdf/SET_NEED_RENDER';
export const SET_NEED_RENDER_THUMBNAIL = 'pdf/SET_NEED_RENDER_THUMBNAIL';
export const SET_CANVAS_CONTAINER_SIZE = 'pdf/SET_CANVAS_CONTAINER_SIZE';

export const SET_VISIBLE_THUMBNAILS = 'pdf/SET_VISIBLE_THUMBNAILS';

export const GET_PAGE = 'pdf/GET_PAGE';
export const GET_PAGE_SUCCESS = 'pdf/GET_PAGE_SUCCESS';
export const GET_PAGE_FAILED = 'pdf/GET_PAGE_FAILED';

export const RENDER_CANVAS = 'pdf/RENDER_CANVAS';
export const RENDER_CANVAS_SUCCESS = 'pdf/RENDER_CANVAS_SUCCESS';
export const RENDER_CANVAS_FAILED = 'pdf/RENDER_CANVAS_FAILED';
export const REMOVE_CANVASES = 'pdf/REMOVE_CANVASES';

export const RENDER_THUMBNAIL = 'pdf/RENDER_THUMBNAIL';
export const RENDER_THUMBNAIL_SUCCESS = 'pdf/RENDER_THUMBNAIL_SUCCESS';
export const RENDER_THUMBNAIL_FAILED = 'pdf/RENDER_THUMBNAIL_FAILED';

export const SET_LOADING_PROGRESS = 'pdf/SET_LOADING_PROGRESS';

export const SET_PDF_COMPLETELY_LOADED = 'pdf/SET_PDF_COMPLETELY_LOADED';

export const SET_PDF_TEXT_CONTENT = 'pdf/SET_PDF_TEXT_CONTENT';
export const SET_ALL_PDF_TEXT_CONTENT = 'pdf/SET_ALL_PDF_TEXT_CONTENT';

export const INIT_DOCUMENT = 'pdf/INIT_DOCUMENT';

export const initialState = {
  loadingProgress: false,

  hasPdfDocument: false,
  pdfDocumentLoading: false,

  count: false,

  needGet: false,
  needRender: false,
  needRenderThumbnails: false,
  visibleThumbnails: false,

  pagesSettings: null,
  canvasContainerSize: {
    width: initialCanvasContainerWidth,
    height: initialCanvasContainerHeight,
  },
  pdfPages: false,
  pdfPageLoading: false,

  canvases: false,
  canvasLoading: false,
  canvasesTimestamp: false,

  thumbnails: false,
  thumbnailLoading: false,

  pdfTextContent: false,
};

const addTo = (() => {
  return {
    needRender: ({ needRender, canvasLoading }, array) => {
      const get = Array.isArray(needRender)
        ? [...needRender]
        : [];
      const filterStatement = (page) => {
        const notAlreadyIn = !needRender[page];
        const notLoadingNow = canvasLoading !== page;
        return notLoadingNow && notAlreadyIn;
      };

      array
        .filter(filterStatement)
        .forEach((page) => {
          get[page] = true;
        });
      return get;
    },

    needRenderThumbnails: (state, index) => {
      const { needRenderThumbnails, thumbnails, thumbnailLoading } = state;
      const get = Array.isArray(needRenderThumbnails)
        ? [...needRenderThumbnails]
        : [];

      const indexes = Array.isArray(index)
        ? index
        : [index];

      indexes.forEach((item) => {
        const notLoadingNow = item !== thumbnailLoading;
        const notAlreadyIn = !needRenderThumbnails[item];
        const notAlreadyGot = !thumbnails[item];

        if (notLoadingNow && notAlreadyIn && notAlreadyGot) {
          get[item] = true;
        }
      });

      return get;
    },

    needGet: ({ needGet, pdfPages, pdfPageLoading }, array) => {
      const get = Array.isArray(needGet)
        ? [...needGet]
        : [];
      const filterStatement = (page) => {
        const notAlreadyIn = needGet.indexOf(page) === -1;
        const notLoadingNow = pdfPageLoading !== page;
        const notAlreadyGot = !pdfPages[page];
        return notAlreadyGot && notAlreadyIn && notLoadingNow;
      };

      array.filter((page) => {
        return filterStatement(page);
      }).forEach((page) => {
        get[page] = true;
      });
      return get;
    },
  };
})();

const getNeedRender = (needRender, canvases, pdfCount) => {
  return needRender
    ? needRender.map((needRenderItem, index) => {
      if (needRenderItem !== null) {
        return needRenderItem;
      }
      return canvases[index] === null
        ? null
        : true;
    })
    : getArrayOfNull(pdfCount);
};

const getNewNeedOnGetDocumentSuccess = (state, pdfCount) => {
  const needRender = getNeedRender(
    state.needRender,
    state.canvases,
    pdfCount,
  );
  const needRenderThumbnails = getNeedRender(
    state.needRenderThumbnails,
    state.thumbnails,
    pdfCount,
  );
  const needGet = zipWith(
    needRender,
    needRenderThumbnails,
    (newRenderItem, newThumbnailsItem) => {
      if (newRenderItem !== null && newRenderItem !== undefined) {
        return newRenderItem;
      }
      if (newThumbnailsItem !== null && newThumbnailsItem !== undefined) {
        return newThumbnailsItem;
      }
      return null;
    },
  );

  return {
    needRender,
    needRenderThumbnails,
    needGet,
  };
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case actionTypes.RESET_WS_PAGES: {
      canvasStore.clear();
      pdfPagesStore.clear();
      pdfDocumentStore.clear();
      pdfTextStore.clear();
      return initialState;
    }

    case RESET_FOR_RELOAD_PDF: {
      canvasStore.clear();
      if (action.resetPdf) {
        pdfPagesStore.clear();
        pdfDocumentStore.clear();
        pdfTextStore.clear();
        return initialState;
      }
      return {
        ...initialState,
        pdfPages: state.pdfPages,
        pdfTextContent: getArrayOfNull(state.pdfPages.length),
      };
    }

    case RESET_ALL_REDUCERS: {
      if (action.restore && action.restore.pdf) {
        return {
          ...initialState,
          // ...action.restore.pdf, //AB: пока не используем
        };
      }
      if (!(action.restore && action.restore.store)) {
        pdfPagesStore.clear();
        pdfDocumentStore.clear();
        canvasStore.clear();
        pdfTextStore.clear();
      }
      return initialState;
    }

    case SET_NEED_GET:
      return {
        ...state,
        needGet: addTo.needGet(state, action.array),
      };

    case SET_NEED_RENDER_THUMBNAIL:
      return {
        ...state,
        needRenderThumbnails: addTo.needRenderThumbnails(state, action.index),
      };

    case SET_VISIBLE_THUMBNAILS:
      return {
        ...state,
        visibleThumbnails: action.indexes,
      };
    case SET_CANVAS_CONTAINER_SIZE:
      return {
        ...state,
        canvasContainerSize: action.canvasContainerSize,
      };
    case SET_NEED_RENDER:
      return {
        ...state,
        needRender: addTo.needRender(state, action.array),
      };

    case GET_DOCUMENT:
      return {
        ...state,
        pdfDocumentLoading: true,
      };

    case GET_DOCUMENT_SUCCESS: {
      const pdfCount = action.result.numPages;
      pdfDocumentStore.setPdfDocument(action.result);
      const hasImages = !!pdfDocumentStore.getImagePageLoader();
      const hasPdfTextContent = !!state.pdfTextContent;

      const {
        needGet,
        needRender,
        needRenderThumbnails,
      } = getNewNeedOnGetDocumentSuccess(state, pdfCount);

      return {
        ...state,
        pdfDocumentLoading: false,
        hasPdfDocument: true,
        count: action.pagesSettingsLength,
        thumbnails: hasImages
          ? state.thumbnails
          : getArrayOfNull(pdfCount),
        canvases: hasImages
          ? state.canvases
          : getArrayOfNull(pdfCount),
        canvasesTimestamp: hasImages
          ? state.canvasesTimestamp
          : getArrayOfNull(pdfCount),
        pdfPages: getArrayOfNull(pdfCount),
        needGet,
        needRender,
        needRenderThumbnails,
        pdfTextContent: hasPdfTextContent
          ? state.pdfTextContent
          : getArrayOfNull(pdfCount),
      };
    }

    case INIT_DOCUMENT: {
      const pdfCount = action.result.numPages;
      return {
        ...state,
        hasPdfDocument: true,
        count: action.pagesSettingsLength,
        thumbnails: getArrayOfNull(pdfCount),
        canvases: getArrayOfNull(pdfCount),
        canvasesTimestamp: getArrayOfNull(pdfCount),
        pdfPages: getArrayOfNull(pdfCount),
        needGet: getArrayOfNull(pdfCount),
        needRender: getArrayOfNull(pdfCount),
        needRenderThumbnails: getArrayOfNull(pdfCount),
      };
    }

    case GET_DOCUMENT_FAILED:
      return {
        ...state,
        pdfDocumentLoading: false,
        pdfDocumentError: action.error,
      };

    case GET_PAGE:
      return {
        ...state,
        pdfPageLoading: action.pageId,
      };

    case GET_PAGE_SUCCESS: {
      // save to outer state
      const pdfPagesStoreId = pdfPagesStore.putPdfPage(action.result);

      const toGet = Array.isArray(state.needGet)
        ? [
          ...state.needGet.slice(0, action.pageId),
          null,
          ...state.needGet.slice(action.pageId + 1),
        ]
        : [];

      return {
        ...state,
        needGet: toGet,
        pdfPages: [
          ...state.pdfPages.slice(0, action.pageId),
          pdfPagesStoreId,
          ...state.pdfPages.slice(action.pageId + 1),
        ],
        pdfPageLoading: false,
      };
    }

    case GET_PAGE_FAILED:
      return {
        ...state,
        pdfPageError: action.error,
        pdfPageLoading: false,
      };

    case RENDER_CANVAS:
      return {
        ...state,
        canvasLoading: action.pageId,
      };

    case RENDER_CANVAS_SUCCESS: {
      const { pageId, result } = action;

      if (!state.needRender) {
        return state;
      }

      // Если для этой страницы уже был канвас - удаляем его
      // из canvasStore
      const canvasOfThisPage = state.canvases[pageId];
      if (canvasOfThisPage !== null && canvasOfThisPage !== result) {
        canvasStore.deleteCanvas(canvasOfThisPage);
      }
      const newCanvases = Array.isArray(state.canvases)
        ? [
          ...state.canvases.slice(0, pageId),
          result,
          ...state.canvases.slice(pageId + 1),
        ]
        : [];

      const timeStamps = Array.isArray(state.canvasesTimestamp)
        ? [
          ...state.canvasesTimestamp.slice(0, pageId),
          Date.now(),
          ...state.canvasesTimestamp.slice(pageId + 1),
        ]
        : [];

      return {
        ...state,
        needRender: [
          ...state.needRender.slice(0, pageId),
          null,
          ...state.needRender.slice(pageId + 1),
        ],
        canvases: newCanvases,
        canvasesTimestamp: timeStamps,
        canvasLoading: false,
      };
    }

    case RENDER_CANVAS_FAILED:
      return {
        ...state,
        canvasError: action.error,
        canvasLoading: false,
      };

    case RENDER_THUMBNAIL:
      return {
        ...state,
        thumbnailLoading: action.pageId,
      };

    case RENDER_THUMBNAIL_SUCCESS: {
      const { result, pageId } = action;

      // Если для этой страницы уже был канвас - удаляем его
      // из canvasStore
      const canvasOfThisPage = state.thumbnails[pageId];
      if (canvasOfThisPage !== null && canvasOfThisPage !== action.result) {
        canvasStore.deleteCanvas(canvasOfThisPage);
      }

      return {
        ...state,
        needRenderThumbnails: [
          ...state.needRenderThumbnails.slice(0, pageId),
          null,
          ...state.needRenderThumbnails.slice(pageId + 1),
        ],
        thumbnails: [
          ...state.thumbnails.slice(0, pageId),
          result,
          ...state.thumbnails.slice(pageId + 1),
        ],
        thumbnailLoading: false,
      };
    }

    case RENDER_THUMBNAIL_FAILED:
      return {
        ...state,
        thumbnailError: action.error,
        thumbnailLoading: false,
      };

    case REMOVE_CANVASES: {
      return {
        ...state,
        canvases: state.canvases.map(
          (canvasId, canvasPageId) => {
            if (includes(action.array, canvasPageId)) {
              canvasStore.deleteCanvas(canvasId);
              return null;
            }

            return canvasId;
          },
        ),
      };
    }

    case SET_LOADING_PROGRESS: {
      return {
        ...state,
        loadingProgress: action.progress,
      };
    }

    case SET_PDF_TEXT_CONTENT: {
      return {
        ...state,
        pdfTextContent: [
          ...state.pdfTextContent.slice(0, action.index),
          action.index,
          ...state.pdfTextContent.slice(action.index + 1),
        ],
      };
    }

    case SET_ALL_PDF_TEXT_CONTENT: {
      const pdfTextContent = [...state.pdfTextContent];
      (action.indexes || []).forEach((index) => {
        pdfTextContent[index] = index;
      });
      return {
        ...state,
        pdfTextContent,
      };
    }

    default:
      return state;
  }
}

let needGetPagesPromiseStore = [];

export function setNeedGet(array) {
  return (dispatch, getState) => {
    const state = getState();
    /* Filter already loaded pages */
    const filteredArray = array.filter((pageId) => {
      return !state.pdf.pdfPages[pageId];
    });

    if (filteredArray.length > 0) {
      dispatch({ type: SET_NEED_GET, array: filteredArray });
    }

    return new Promise((resolve) => {
      return needGetPagesPromiseStore.push({ resolve, array: filteredArray });
    });
  };
}

export function* onGetPageSuccess({ pageId }) {
  for (let i = 0; i < needGetPagesPromiseStore.length; i++) {
    needGetPagesPromiseStore[i].array = needGetPagesPromiseStore[i].array.filter((id) => {
      return id !== pageId;
    });
    if (needGetPagesPromiseStore[i].array.length === 0) {
      needGetPagesPromiseStore[i].resolve();
    }
  }

  needGetPagesPromiseStore = needGetPagesPromiseStore.filter((_) => {
    return _.array.length !== 0;
  });

  yield null;
}

export function setNeedRenderThumbnail(index) {
  return { type: SET_NEED_RENDER_THUMBNAIL, index };
}

export function setVisibleThumbnails(indexes) {
  return { type: SET_VISIBLE_THUMBNAILS, indexes };
}

export function setNeedRender(array) {
  return { type: SET_NEED_RENDER, array };
}

export const getDocumentActionCreator = () => {
  return { type: GET_DOCUMENT };
};

export const getDocumentSuccessActionCreator = ({ result, pagesSettingsLength }) => {
  return {
    type: GET_DOCUMENT_SUCCESS,
    result,
    _resultNoSerialize: true,
    pagesSettingsLength,
  };
};

export const getDocumentFailedActionCreator = ({ error }) => {
  return { type: GET_DOCUMENT_FAILED, error };
};

const getPageActionCreator = (pageId) => {
  return { type: GET_PAGE, pageId };
};
const getPageSuccessActionCreator = ({ pageId, pagesSettings, result, pdfScale }) => {
  return { type: GET_PAGE_SUCCESS, pageId, pagesSettings, result, pdfScale };
};
const getPageFailedActionCreator = (pageId) => {
  return { type: GET_PAGE_FAILED, pageId };
};

export function initDocument(numPages) {
  return { type: INIT_DOCUMENT, numPages };
}

export function getPage(pageId, pagesSettings, pdfScale) {
  return (dispatch) => {
    profiler('BeginGetPage', { pageId });
    dispatch(getPageActionCreator(pageId));

    let promise;
    if (pdfDocumentStore.getPdfDocument()) {
      promise = pdfDocumentStore.getPdfDocument().getPage(pageId + 1);
    } else if (pdfDocumentStore.getImagePageLoader() && pagesSettings[pageId].sourceUrl) {
      promise = Promise.resolve(pdfDocumentStore.getImagePageLoader().getPage(pageId));
    } else {
      throw new Error('getPage called with no pdf document and no page sourceUrl');
    }

    promise
      .then((result) => {
        dispatch(getPageSuccessActionCreator({
          pageId,
          result,
          pagesSettings,
          pdfScale,
        }));
      })
      .catch((error) => {
        log.error('Error in getDocument thunk', error);

        dispatch(getPageFailedActionCreator(pageId));
      })
      .then(() => {
        profiler('EndGetPage', { pageId });
      });
  };
}

export function renderCanvas(pageId) {
  return async (dispatch, getState) => {
    // start render
    profiler('BeginRenderCanvas', { pageId });
    dispatch({ type: RENDER_CANVAS, pageId });

    // run canvas logic
    const state = getState();
    const pdfPage = (
      pdfPagesStore.getPdfPage(state.pdf.pdfPages[pageId]) ||
      await (
        pdfDocumentStore.getImagePageLoader() &&
        pdfDocumentStore.getImagePageLoader().getPage(pageId)
      )
    );
    const pagesSettings = selectors.navigation.getPagesSettings(state);
    const pageSetting = pagesSettings[
      findIndex(pagesSettings, ({ source }) => {
        return source === pageId;
      })
    ];
    const pdfScale = selectors.base.getPdfScale(state);
    let scale = state.navigation.scales[pageId] * outputScale * pdfScale;
    let viewport = pdfPage.getViewport(scale, pageSetting.rotation + pdfPage.rotate);
    if (viewport.width * viewport.height > maxCanvasSize) {
      scale /= Math.sqrt(viewport.width * (viewport.height / maxCanvasSize));
      viewport = pdfPage.getViewport(scale, pageSetting.rotation + pdfPage.rotate);
    }

    const canvasEl = document.createElement('canvas');
    if (isSignNow()) {
      canvasEl.className = 'page__object';
    }
    canvasEl.width = viewport.width;
    canvasEl.height = viewport.height;
    const canvasContext = canvasEl.getContext('2d');
    const canvasId = canvasStore.putCanvas(canvasEl);

    const canvasPromise = pdfPage.render({ canvasContext, viewport }).promise.then((_) => {
      profiler('EndRenderCanvas', { pageId });
      return _;
    });

    canvasPromise.then(
      () => {
        return dispatch({ result: canvasId, type: RENDER_CANVAS_SUCCESS, pageId });
      },
      (error) => {
        log.error('Canvas middleware error', error);
        dispatch({ error, type: RENDER_CANVAS_FAILED, pageId });
      },
    ).catch((error) => {
      log.error('Canvas middleware error', error);
      dispatch({ error, type: RENDER_CANVAS_FAILED, pageId });
    });

    return canvasPromise;
  };
}

export function renderThumbnail(pageId) {
  return async (dispatch, getState) => {
    profiler('BeginRenderThumbnail', { pageId });
    dispatch({ type: RENDER_THUMBNAIL, pageId });

    const state = getState();
    const pdfPage = (
      pdfPagesStore.getPdfPage(state.pdf.pdfPages[pageId]) ||
      await (
        pdfDocumentStore.getImagePageLoader() &&
        pdfDocumentStore.getImagePageLoader().getPage(pageId)
      )
    );

    const pagesSettings = selectors.navigation.getPagesSettings(state);

    const pageSetting = pagesSettings[
      findIndex(pagesSettings, ({ source }) => {
        return source === pageId;
      })
    ];

    const scale = thumbnailMaxWidth / pdfPage.getViewport(1.0,
      pageSetting.rotation + pdfPage.rotate).width;
    const viewport = pdfPage.getViewport(scale * outputScale,
      pageSetting.rotation + pdfPage.rotate);

    const { width, height } = viewport;
    const canvasContainerSize = { width, height };
    dispatch({ type: SET_CANVAS_CONTAINER_SIZE, canvasContainerSize });

    const canvasEl = document.createElement('canvas');
    canvasEl.width = width;
    canvasEl.height = height;
    if (!isSignNow()) {
      canvasEl.style.backgroundColor = initialCanvasBackgroundColor;
      canvasEl.className = 'media media--type--canvas';
    }
    const canvasContext = canvasEl.getContext('2d');
    const canvasId = canvasStore.putCanvas(canvasEl);

    const canvasPromise = pdfPage.render({ canvasContext, viewport }).promise.then(() => {
      profiler('EndRenderThumbnail', { pageId });
    });

    dispatch({ result: canvasId, type: RENDER_THUMBNAIL_SUCCESS, pageId });

    canvasPromise.then(
      () => {
        return dispatch({ result: canvasId, type: RENDER_THUMBNAIL_SUCCESS, pageId });
      },
      (error) => {
        log.error('Thumbnails middleware error', error);
        dispatch({ error, type: RENDER_THUMBNAIL_FAILED, pageId });
      },
    ).catch((error) => {
      log.error('Thumbnails middleware error', error);
      dispatch({ error, type: RENDER_THUMBNAIL_FAILED, pageId });
    });

    return canvasPromise;
  };
}

export function removeCanvases(array) {
  return { type: REMOVE_CANVASES, array };
}

export function setLoadingProgress(progress) {
  return { type: SET_LOADING_PROGRESS, progress };
}

export function setPdfCompletelyLoaded() {
  return { type: SET_PDF_COMPLETELY_LOADED };
}

export function setPdfTextContent(index) {
  return { type: SET_PDF_TEXT_CONTENT, index };
}

export function setAllPdfTextContent(indexes) {
  return { type: SET_ALL_PDF_TEXT_CONTENT, indexes };
}
