import without from 'lodash/without';
import pick from 'lodash/pick';
import isEmpty from 'lodash/isEmpty';
import isSignNow from '../../../../isSignNow';

import {
  createFlatId,
  isVisible,
  isVisibleTemplate,
  createMapFromElements,
  needHandleOperationFromServer,
  createGroupsUsingElements,
} from '../Utils';

import {
  applyElementUpdateOperation,
  createElementFromOperation,
} from './helpers';

import { TYPES, TOOL_TYPES } from '../../../../constants';

export const deleteElementsByOps = (batch, state) => {
  if (batch === undefined) {
    return state;
  }

  const elementsToDelete = Object.keys(batch);
  if (elementsToDelete.length === 0) {
    return state;
  }

  const elementsWithoutDeletedElements = without(
    state.elements,
    ...elementsToDelete.map((id) => {
      return state.elementsMap[id];
    }),
  );

  return {
    ...state,
    elements: elementsWithoutDeletedElements,
    elementsMap: createMapFromElements(elementsWithoutDeletedElements),
  };
};

export const updateElementsByOps = (batch, state) => {
  if (isEmpty(batch)) {
    return state;
  }

  const { elements, elementsMap } = state;

  const newElements = elements
    .map((el) => {
      const ops = batch[el.id];
      if (ops === undefined) {
        return el;
      }

      return ops.reduce((element, op) => {
        return applyElementUpdateOperation(op, element);
      }, elementsMap[el.id]);
    });

  return {
    ...state,
    elements: newElements,
    elementsMap: createMapFromElements(newElements),
  };
};

export const createElementsByOps = (batch, state) => {
  const ops = Object.values(batch);
  if (ops === undefined || ops.length === 0) {
    return state;
  }

  const els = ops.reduce((acc, opsArray) => {
    // create element contains only operation for only elements
    // and thats why we read only first element
    const { sendOperationsCount, element } = createElementFromOperation(opsArray[0], acc);

    if (element.type === TOOL_TYPES.signature && isSignNow()) {
      acc.elements.unshift(element);
    } else {
      acc.elements.push(element);
    }

    return {
      elements: acc.elements,
      sendOperationsCount,
    };
  }, {
    elements: [],
    sendOperationsCount: state.sendOperationsCount,
  });

  const mergedElements = [...state.elements, ...els.elements];

  return {
    ...state,
    elements: mergedElements,
    elementsMap: createMapFromElements(mergedElements),
    sendOperationsCount: els.sendOperationsCount,
  };
};

const getAction = (acc, id, op) => {
  const { batch, state } = acc;

  // deleting fillable elements
  // deleting simple elements
  if ((!isVisible(op.properties) && !op.properties.template) || !isVisibleTemplate(op.properties)) {
    return TYPES.delete;
  }

  // updating simple or fillable elements, clear fillable elements
  if (state.elementsMap[id] !== undefined || batch[id] !== undefined) {
    return TYPES.update;
  }

  return TYPES.create;
};

const applyBatchToState = (acc) => {
  const { action, state, batch } = acc;
  switch (action) {
    case TYPES.update:
      return updateElementsByOps(batch, state);
    case TYPES.delete:
      return deleteElementsByOps(batch, state);
    case TYPES.create:
      return createElementsByOps(batch, state);
    default:
      return state;
  }
};

export const doElements = (transport, state, { operations }) => {
  const result = operations.reduce((acc, op, index) => {
    if (!needHandleOperationFromServer({
      operation: op,
      operations,
      operationIndex: index,
      // JSF-6671 JSF-7238
      // elements map need for check that element was deleted and added after undo/redo
      // in this case delete operation will be skipped
      elementsMap: state.elementsMap,
    })) {
      return acc;
    }

    const id = createFlatId(op.properties.element);
    const { action: prevAction } = acc;
    const action = getAction(acc, id, op);

    if (action !== prevAction) {
      return {
        action,
        state: applyBatchToState(acc),
        batch: { [id]: [op] },
      };
    }

    acc.batch[id] = acc.batch[id]
      ? [...acc.batch[id], op]
      : [op];

    return acc;
  }, {
    state: pick(state, ['elements', 'elementsMap', 'sendOperationsCount']),
    action: '',
    batch: {},
  });

  // ensure to apply last batch
  const lastBatchState = applyBatchToState(result);
  const { elements, fillableGroups } = createGroupsUsingElements(lastBatchState.elements, state);

  return {
    ...state,
    elements,
    fillableGroups,
    elementsMap: createMapFromElements(elements),
    sendOperationsCount: lastBatchState.sendOperationsCount,
  };
};

export const doPages = (operations) => {
  return ([
    ...operations[operations.length - 1].properties.pages,
  ]);
};

export const doAttributes = (operations, attributes) => {
  return operations.reduce((acc, operation) => {
    const attName = operation.properties.subType;
    const oldAttribute = acc && acc[attName]
      ? acc[attName]
      : { content: {} };

    return ({
      ...acc,
      [attName]: {
        ...oldAttribute,
        ...operation.properties,
        content: {
          ...oldAttribute.content,
          ...operation.properties.content,
        },
      },
    });
  }, attributes);
};
