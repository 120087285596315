import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

export const separatorThemes = {
  default: 'default',
  white: 'white',
  null: null,
};

export const separatorVerticalOffsets = {
  small: 'small',
  null: null,
};

export const separatorHorizontalOffsets = {
  large: 'large',
  medium: 'medium',
  null: null,
};

export const separatorTypes = {
  vertical: 'vertical',
  horizontal: 'horizontal',
  null: null,
};

export default class Separator extends Component {
  static propTypes = {
    theme: PropTypes.oneOf(
      Object.values(separatorThemes),
    ),
    verticalOffset: PropTypes.oneOf(
      Object.values(separatorVerticalOffsets),
    ),
    horizontalOffset: PropTypes.oneOf(
      Object.values(separatorHorizontalOffsets),
    ),
    type: PropTypes.oneOf(
      Object.values(separatorTypes),
    ),
  };

  static defaultProps = {
    theme: separatorThemes.null,
    verticalOffset: separatorVerticalOffsets.null,
    horizontalOffset: separatorHorizontalOffsets.null,
    type: separatorTypes.null,
  };

  render() {
    const { theme, verticalOffset, horizontalOffset, type } = this.props;

    return (
      <div
        className={classnames(
          'separator', {
            [`separator--theme--${theme}`]: theme,
            [`separator--offset-vertical--${verticalOffset}`]: verticalOffset,
            [`separator--offset-horizontal--${horizontalOffset}`]: horizontalOffset,
            [`separator--type--${type}`]: type,
          },
        )}
      />
    );
  }
}
