import PropTypes from 'prop-types';
import React from 'react';

const TodoListCell = ({ children }) => {
  return (
    <div className="todo__cell">
      <div className="todo__entitled-box">
        {children}
      </div>
    </div>
  );
};

TodoListCell.propTypes = {
  children: PropTypes.node,
};

TodoListCell.defaultProps = {
  children: null,
};

export default TodoListCell;
