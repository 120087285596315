import {
  sendToolOperation,
  getNextLocalId,
  createId,
} from '../Operations/Utils';
import { TYPES } from '../../../constants';

export default function doUpdatePages(transport, state, { pages, opts }) {
  // В некоторых случаях в режиме Versions в ответе на превью версии не приходит операция pages
  // По этому нужна очистка pages, но не нужно их отправлять
  if (opts.isOnlyStateUpdate && opts.isInitialize) {
    return {
      ...state,
      pages,
    };
  }

  const sendOperationsCount = getNextLocalId(state.sendOperationsCount);
  const id = createId(sendOperationsCount);

  sendToolOperation(transport, id, {
    type: TYPES.pages,
    pages,
    ...(
      opts.isInitialize
        ? { initial: true }
        : {}
    ),
  });

  return {
    ...state,
    sendOperationsCount,
    ...(
      opts.isInitialize
        ? { pages }
        : {}
    ),
  };
}
