const closeBtnWidth = 13;

// Штамп позиционируется с отступом 3px вправо от элемента
const stampMarginLeft = 3;
const stampMarginRight = 3;

// Эти отступы добавлены для корректного позиционирования штампа через портал
// при перемещении из правого верхнего угла, от правого или от верхнего краев документа
const elementMarginRight = 5;
const elementMarginTop = 5;

// У штампа есть крестик, который его скрывает, виден только на ховере.
// (*1) Когда элемент посередине или у нижне, левых границ -
//      top: -50% от высоты штампа
//      Правый край элемента совпадает с левым краем штампа
//
// (*2) Когда элемент у верхнего края страницы -
//      штамп прижимается к потолку.
//
// (*3) Когда элемент у нижнего края, при этом он очень маленький в высоту - то прижимаем
//      штамп к полу, чтобы он не выходил за границы документа. Во флеше этого нет, но есть баг.
//
// (*4) Когда элемент у правого края страницы -
//      штамп переезжает наверх, находится в 50% высоты штампа от верхнего края элемента.
//      Правый край элемента совпадает с правым краем крестика
//
// (*5) Когда элемент у верхнеправого угла - штамп переезжает вниз,
//      находится в 50% высоты штампа от нижнего края элемента.
//      Правый край элемента совпадает с правым краем крестика

export function calculateSignStampAnchorPosition(args) {
  const {
    anchorRect,
    viewportRect,
    elementRect,
    otherArgs: {
      elementTopOffset,
      elementLeftOffset,
      anchorOutline,
      scale,
    },
  } = args;

  const defaultLeft = anchorRect.left + stampMarginLeft + anchorOutline;
  const defaultRight = anchorRect.right + stampMarginRight + anchorOutline;
  const elementWidth = elementRect.width + closeBtnWidth;

  // Т.к. штамп позиционируется top: -50% или 50% от высоты штампа
  const halfElementHeight = elementRect.height / 2;

  const isAtRightBorder =
    (viewportRect.right - anchorRect.left - elementLeftOffset) <
    (elementWidth + elementMarginRight);

  const isAtTopRightCorner = isAtRightBorder &&
    (anchorRect.top + elementTopOffset - viewportRect.top) <
    (elementRect.height + halfElementHeight + elementMarginTop);

  if (isAtTopRightCorner) {
    return {
      // (*5)
      top: anchorRect.bottom - anchorOutline + (halfElementHeight * scale),
      right: defaultRight,
      bottom: anchorRect.bottom - anchorOutline + elementRect.height + halfElementHeight,
      left: defaultLeft + anchorRect.width - elementWidth,
    };
  }

  if (isAtRightBorder) {
    return {
      // (*4)
      top: anchorRect.top - anchorOutline -
        elementRect.height - (halfElementHeight * scale) + elementTopOffset,
      right: defaultRight,
      bottom: anchorRect.bottom - anchorOutline -
        elementRect.height - halfElementHeight + elementTopOffset,
      left: defaultLeft + anchorRect.width - elementWidth,
    };
  }

  const isAtTopBorder =
    (anchorRect.top + elementTopOffset - viewportRect.top) <
    (halfElementHeight + elementMarginTop);

  if (isAtTopBorder) {
    return {
      // (*2)
      top: viewportRect.top,
      right: defaultRight + elementLeftOffset,
      bottom: viewportRect.top + anchorRect.height,
      left: defaultLeft + elementLeftOffset,
    };
  }

  const isAtBottomBorder = anchorRect.top + halfElementHeight > viewportRect.bottom;

  if (isAtBottomBorder) {
    return {
      // (*3)
      top: viewportRect.bottom - elementRect.height,
      right: defaultRight + elementLeftOffset,
      bottom: viewportRect.bottom,
      left: defaultLeft + elementLeftOffset,
    };
  }

  return {
    // (*1)
    top: anchorRect.top - anchorOutline - halfElementHeight + elementTopOffset,
    right: defaultRight + elementLeftOffset,
    bottom: anchorRect.bottom - anchorOutline - halfElementHeight + elementTopOffset,
    left: defaultLeft + elementLeftOffset,
  };
}

export default {
  postMeasure(args) {
    const { elementRect, anchorRect, otherArgs: { elementLeftOffset, anchorOutline } } = args;

    const width = anchorRect.width + anchorOutline * 2 - elementLeftOffset - stampMarginLeft;
    const height = anchorRect.height + anchorOutline * 2;

    return {
      ...args,
      anchorRect: {
        ...anchorRect,
        width,
        height,
        ...calculateSignStampAnchorPosition(args),
      },

      elementRect: {
        ...elementRect,
        right: elementRect.right + closeBtnWidth,
        width: elementRect.width + closeBtnWidth,
      },
    };
  },
};
