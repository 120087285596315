import React from 'react';
import PropTypes from 'prop-types';

const Body = ({ children }) => {
  return (
    <div className="breadcrumbs">
      <div className="breadcrumbs__body">
        {children}
      </div>
    </div>
  );
};

Body.propTypes = {
  children: PropTypes.arrayOf(
    PropTypes.oneOfType([
      PropTypes.element,
      PropTypes.bool,
    ]),
  ).isRequired,
};

export default Body;
