import React from 'react';
import PropTypes from 'prop-types';

const FillableWizardBody = ({ children }) => {
  return (
    <div className="fillable-wizard__body">
      {children}
    </div>
  );
};

FillableWizardBody.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.arrayOf(
      PropTypes.oneOfType([
        PropTypes.element,
        PropTypes.bool,
      ]),
    ),
  ]).isRequired,
};

export default FillableWizardBody;
