import { createContext } from 'react';

const FocusControllerContext = createContext({
  storeRef: null,
  onBlur: null,
  onFocus: null,
  restoreFocus: null,
  disableBlur: null,
  enableBlur: null,
  disableBlurGlobally: null,
  enableBlurGlobally: null,
});

export default FocusControllerContext;
