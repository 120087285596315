import once from 'lodash/once';

function deprecate(message, fn) {
  // we need "bind" for "once" in IE11/Edge when the DevTools F12 are not open
  const printWarn = once(console.warn.bind(console)); // eslint-disable-line no-console
  return (...args) => {
    printWarn(message || `${fn.name} is deprecated`);
    return fn(...args);
  };
}

export default deprecate;
