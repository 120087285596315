import { take, put, select, all } from 'redux-saga/effects';
import { actionTypes } from 'ws-editor-lib/actions';
import { selectors } from 'jsfcore';
import { miscIds } from 'jsfcore/helpers/const';
import { showComments } from 'jsfcore/store/modules/viewport';
import { thisDevice } from '@pdffiller/jsf-useragent';

export default function* openCommentsOnCommentElementsReceive() {
  while (true) {
    yield all([
      take(actionTypes.ON_OPERATIONS_TOOLS_COMMENT),
      take(actionTypes.ON_OPERATIONS_EDITOR_FEATURES),
    ]);

    const comments = yield select(selectors.comments.getComments);
    const isVisible = yield select(selectors.getMiscFeatureVisible, miscIds.comments.id);

    if (comments.length !== 0 && isVisible && !thisDevice.isTablet) {
      yield put(showComments());
    }
  }
}
