import PropTypes from 'prop-types';
import React, { Component } from 'react';
import cls from 'classnames';

const controlTags = {
  button: 'button',
  div: 'div',
};

export default class Button extends Component {
  static propTypes = {
    text: PropTypes.element,
    icon: PropTypes.element,
    tag: PropTypes.oneOf(
      Object.values(controlTags),
    ),
    isActive: PropTypes.bool,

    onClick: PropTypes.func,
  };

  static defaultProps = {
    tag: controlTags.div,
    isActive: false,
    text: null,
    icon: null,
    onClick: null,
  };

  render() {
    const { icon, text, tag } = this.props;
    const Tag = tag;

    return (
      <Tag
        className={
          cls('crumb crumb--theme--default', {
            'is-active': this.props.isActive,
          })
        }
        type="button"
        onClick={this.props.onClick}
      >
        {icon &&
          <span className="crumb__icon">
            {icon}
          </span>
        }
        {text &&
          <span className="crumb__text">
            {this.props.text}
          </span>
        }
      </Tag>
    );
  }
}
