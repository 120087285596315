import React from 'react';
import PropTypes from 'prop-types';

const ListConfiguratorMain = ({ children, storeRef }) => {
  return (
    <div
      className="list-configurator__main"
      ref={storeRef}
    >
      {children}
    </div>
  );
};

ListConfiguratorMain.propTypes = {
  children: PropTypes.node.isRequired,
  storeRef: PropTypes.func,
};

ListConfiguratorMain.defaultProps = {
  storeRef: null,
};

export default ListConfiguratorMain;
