import { combineReducers } from 'redux';
import { ws } from 'ws-editor-lib';
import modal from 'combine-modals/lib/store/reducers';
import locale from 'global-locale/lib/store/reducer';
import conditions from '@pdffiller/jsf-conditions/store/reducers/conditionsReducer';
import lazyLoad from '@pdffiller/jsf-lazyload/store/reducer';

import { reducers as validationReducers } from '../../jsf-validations';
import navigation from './navigation';
import viewport from './viewport';
import pdf from './pdf';
import events from './events';
import query from './query';
import search from './search';
import undoRedo from './undoRedo';
import modals from './modals';

const createReducer = (wsTransport, lazyReducers) => {
  let reducers = {};
  if (lazyReducers) {
    Object.keys(lazyReducers).forEach((el) => {
      reducers = {
        ...reducers,
        ...lazyReducers[el],
      };
    });
  }

  return combineReducers({
    ws: ws(wsTransport),
    modal,
    viewport,
    navigation,
    pdf,
    events,
    locale,
    lazyLoad,
    query,
    search,
    undoRedo,
    errors: validationReducers.errors,
    conditions,
    modals,
    ...reducers,
  });
};

export default createReducer;
