import { selectors } from 'jsfcore';

import { formatMessage as fm } from '../../jsf-localization';
import { elemTypes, elemSubTypes } from '../../helpers/elemTypes';
import { validateFieldName } from './typeValidators/validateFieldName';
import validateSmart from './typeValidators/validateSmart';
import validateDropdown from './typeValidators/validateDropdown';
import validateRadio from './typeValidators/validateRadio';
import validateText from './typeValidators/validateText';
import * as validationSelectors from '../selectors';
import * as consts from '../const';
import { setConstructorError } from './errorsActions';

const validateFieldFactory = (element, elements, formatMessage) => {
  return (value, field, validators, options) => {
    const validatorNames = Object.keys(validators);

    for (let i = 0; i < validatorNames.length; i++) {
      const validator = validators[validatorNames[i]];
      const { disabled, isValid, message } = validator;

      if (!disabled) {
        if (!isValid(value, element, elements, options)) {
          return {
            isValid: false,
            errors: [{
              field,
              errorText: message(value, formatMessage),
              isGlobal: true,
            }],
          };
        }
      }
    }

    return consts.valid;
  };
};

const validate = (element, validators, elements, formatMessage, dispatch, getState) => {
  const validateField = validateFieldFactory(element, elements, formatMessage);
  const validFieldName = validateFieldName(element, validateField);
  if (!validFieldName.isValid) {
    return validFieldName;
  }

  switch (element.type) {
    case elemTypes.salesforce:
    case elemTypes.smart:
      return validateSmart(element);
    case elemTypes.radio:
      return validateRadio(element, validateField);
    case elemTypes.text:
      switch (element.subType) {
        case elemSubTypes.text.dropdown:
          return validateDropdown(element);
        case elemSubTypes.none:
          return validateText(element, validators, dispatch, getState, formatMessage);
        case elemSubTypes.text.date:
          return validateText(element, validators, dispatch, getState, formatMessage);
        default:
          return consts.valid;
      }

    default:
      return consts.valid;
  }
};

const validateAllElementsThunk = () => {
  return (dispatch, getState) => {
    const state = getState();
    const elements = selectors.elements.getFillableEnabledElements(state);
    const validators = validationSelectors.validators.getMergedValidators(state);
    const locale = selectors.locale.getSNFillerLocale(state);
    const formatMessage = fm(locale);

    for (let i = 0; i < elements.length; i++) {
      const element = elements[i];
      const { isValid, errors } = validate(
        element,
        validators,
        elements,
        formatMessage,
        dispatch,
        getState,
      );
      if (!isValid) {
        dispatch(
          setConstructorError({
            id: element.id,
            isValid,
            errors,
          }),
        );
      }
    }
  };
};

export {
  validate,
  validateFieldFactory,
  validateAllElementsThunk,
};
