import logger, { MARKERS, LoggingContext } from './index';

const repeat = (str, times) => {
  return (new Array(times + 1)).join(str);
};
const pad = (num, maxLength) => {
  return repeat('0', maxLength - num.toString().length) + num;
};
const formatTime = (time) => {
  return `@ ${pad(time.getHours(), 2)}:${pad(time.getMinutes(), 2)}:` +
  `${pad(time.getSeconds(), 2)}.${pad(time.getMilliseconds(), 3)}`;
};

// Use the new performance api to get better precision if available
const timer = typeof performance !== 'undefined' && typeof performance.now === 'function'
  ? performance
  : Date;

function createLogger(options = {}) {
  const {
    log = logger.getLogger('redux'),
    logErrors = true,
    predicate,
    duration = true,
    timestamp = true,
    actionTransformer = (action) => {
      return action;
    },
    errorTransformer = (error) => {
      return error;
    },
  } = options;

  // exit if console undefined
  if (typeof log === 'undefined') {
    return () => {
      return (next) => {
        return (action) => {
          return next(action);
        };
      };
    };
  }

  const logBuffer = [];
  function printBuffer() {
    while (logBuffer.length > 0) {
      // Can be recursion!!!
      // logBuffer.forEach((logEntry, key) =>
      const logEntry = logBuffer.shift();
      const key = -1;
      const { started, startedTime, action, error } = logEntry;
      let { took, nextState } = logEntry;
      const nextEntry = logBuffer[key + 1];

      if (nextEntry) {
        nextState = nextEntry.prevState;
        took = nextEntry.started - started;
      }

      // message
      const formattedAction = actionTransformer(action);
      const formattedTime = formatTime(startedTime);

      const actionTime = timestamp
        ? formattedTime
        : '';

      const actionType = formattedAction.types
        ? formattedAction.types[0]
        : formattedAction.type;

      const actionDuration = duration
        ? `(in ${took.toFixed(2)} ms)`
        : '';

      const title = `action ${actionTime} ${actionType} ${actionDuration}`;

      logger.addAction(formattedAction, formattedTime, took);

      // render
      if (error) {
        log.error(`error in ${title}`, error);
      } else if (__TEST__) {
        log.debug(MARKERS.REDUX_ACTION,
          title, {
            action: formattedAction,
            duration: took,
          },
        );
      } else {
        log.debug(MARKERS.REDUX_ACTION,
          title,
          new LoggingContext({
            action: formattedAction,
            state: nextState,
            duration: took,
          }),
        );
      }
    }

    logBuffer.length = 0;
  }

  return ({ getState }) => {
    return (next) => {
      return (action) => {
        // exit early if predicate function returns false
        if (typeof predicate === 'function' && !predicate(getState, action)) {
          return next(action);
        }

        const logEntry = {};
        logBuffer.push(logEntry);

        logEntry.started = timer.now();
        logEntry.startedTime = new Date();
        logEntry.prevState = getState();
        logEntry.action = action;

        let returnedValue;

        if (logErrors) {
          try {
            returnedValue = next(action);
          } catch (error) {
            if (action === 'events/SET_FATAL_ERROR') {
              // eslint-disable-next-line no-console
              console.log('LoggerMiddleware: error in SET_FATAL_ERROR action.', logEntry.error);
            } else {
              logEntry.error = errorTransformer(error);
            }
          }
        } else {
          returnedValue = next(action);
        }

        logEntry.nextState = getState();
        logEntry.took = timer.now() - logEntry.started;

        printBuffer();

        if (logEntry.error) {
          throw logEntry.error;
        }

        return returnedValue;
      };
    };
  };
}

export default createLogger();
