import PropTypes from 'prop-types';
import { Component } from 'react';

export default class ClosePortalFunctionComposer extends Component {
  static propTypes = {
    children: PropTypes.func.isRequired,
    closePortal: PropTypes.func.isRequired,
    func: PropTypes.func.isRequired,
  };

  functionProxy = (...args) => {
    this.props.func(...args);
    this.props.closePortal();
  };

  render() {
    return this.props.children({
      composedFunc: this.functionProxy,
    });
  }
}
