import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import get from 'lodash/get';

const nullFunc = () => {};

const getClassModificators = ({ type, subType }) => {
  const classes = {
    text: {
      cls: 'text-field',
      subCls: {
        none: 'text',
        dropdown: 'select',
        number: 'number',
        picture: 'picture',
        date: 'date',
        formula: 'formula',
      },
    },
    checkmark: {
      cls: 'checkbox',
    },
    radio: {
      cls: 'checkbox',
    },
    image: {
      cls: 'image',
      subCls: {
        none: 'picture',
      },
    },
    signature: {
      cls: 'image',
      subCls: {
        none: 'signature',
        initials: 'initials',
      },
    },
  };
  return {
    cls: get(classes, `${type}.cls`, null),
    subCls: get(classes, `${type}.subCls.${subType}`, null),
  };
};

const FillableFieldBody = ({
  className,
  children,
  style,
  onMouseDown,
  onTouchStart,
  onMouseUp,
  onTouchEnd,
  isFocused,
  isFilled,
  isRequired,
  isInvalid,
  isDisabled,
  isChecked,
  isPlaceholder,
  isEllipsis,
  type,
  subType,
  isEnabled,
  isDraggable,
  isActive,
  storeRef,
}) => {
  const modificators = getClassModificators({ type, subType });
  const classes = classnames(
    'element-Element',
    'fillable-field fillable-field--theme--primary',
    {
      [`fillable-field--type--${modificators.cls}`]: modificators.cls,
      [`fillable-field--type--${modificators.subCls}`]: modificators.subCls,
      'is-focused': isEnabled && isFocused,
      'is-active': isActive,
      'is-disabled': isDisabled,
      'is-required': isRequired,
      'is-invalid': isInvalid,
      'is-filled': isFilled,
      'is-checked': isChecked,
      'is-draggable': isDraggable,
      'is-placeholder': isPlaceholder,
      'is-ellipsis': isEllipsis,
      [className]: className,
    },
  );

  return (
    <div
      style={style}
      className={classes}
      onMouseDown={onMouseDown}
      onTouchStart={onTouchStart}
      onMouseUp={onMouseUp}
      onTouchEnd={onTouchEnd}
      ref={storeRef}
    >
      <div className="fillable-field__body">
        {children}
        <div className="fillable-field__required-indicator" />
      </div>
    </div>
  );
};

FillableFieldBody.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.element.isRequired,
    PropTypes.array.isRequired,
  ]).isRequired,
  className: PropTypes.string,
  style: PropTypes.shape({}),
  onMouseDown: PropTypes.func,
  onTouchStart: PropTypes.func,
  onMouseUp: PropTypes.func,
  onTouchEnd: PropTypes.func,
  isFocused: PropTypes.bool,
  isFilled: PropTypes.bool,
  isRequired: PropTypes.bool,
  isInvalid: PropTypes.bool,
  isDisabled: PropTypes.bool,
  isChecked: PropTypes.bool,
  type: PropTypes.string,
  subType: PropTypes.string,
  isEnabled: PropTypes.bool,
  isDraggable: PropTypes.bool,
  isActive: PropTypes.bool,
  isPlaceholder: PropTypes.bool,
  isEllipsis: PropTypes.bool,
  storeRef: PropTypes.func,
};

FillableFieldBody.defaultProps = {
  className: null,
  type: null,
  subType: null,
  isFocused: false,
  isFilled: false,
  isRequired: false,
  isInvalid: false,
  isDisabled: false,
  isChecked: false,
  isEnabled: false,
  isDraggable: false,
  isActive: false,
  isPlaceholder: false,
  isEllipsis: false,
  style: {},
  onMouseDown: nullFunc,
  onTouchStart: nullFunc,
  onMouseUp: nullFunc,
  onTouchEnd: nullFunc,
  storeRef: null,
};

export default FillableFieldBody;
