import React from 'react';
import PropTypes from 'prop-types';

const SortableElementDragHandler = ({ children, ...props }) => {
  return (
    <div
      {...props}
      className="sortable-element__drag-handler"
    >
      {children}
    </div>
  );
};

SortableElementDragHandler.propTypes = {
  children: PropTypes.node.isRequired,
};

export default SortableElementDragHandler;
