import React from 'react';
import PropTypes from 'prop-types';

const FillableWizardCell = ({
  attributes,
  children,
}) => {
  return (
    <div
      className="fillable-wizard__cell"
      {...attributes}
    >
      {children}
    </div>
  );
};

FillableWizardCell.propTypes = {
  attributes: PropTypes.shape({}),
  children: PropTypes.element.isRequired,
};

FillableWizardCell.defaultProps = {
  attributes: {},
};

export default FillableWizardCell;
