import { createSelector } from 'reselect';
import get from 'lodash/get';
import find from 'lodash/find';
import { thisDevice } from '@pdffiller/jsf-useragent';
import * as base from './baseSelectors';
import {
  miscIds,
  extrasbarIds,
  constructorProperties,
  editType,
} from '../../helpers/const';
import { elemTypes, elemSubTypes } from '../../helpers/elemTypes';
// need some initial state to show before data comes from ws
const emptyObj = {};
const DEFAULT_DONE_BUTTON = {
  main: {
    id: '',
    location: '',
  },
  list: [],
  visible: true,
};

const defaultFeatureObject = {
  locale: { label: '' },
  isVisible: false,
};

export const getFeatures = (state) => {
  return state.ws.features || emptyObj;
};

export const getFeaturesMisc = (state) => {
  return getFeatures(state).misc || null;
};

export const getFeaturesToolbar = (state) => {
  return getFeatures(state).toolbar || null;
};

export const getFeaturesLogo = (state) => {
  return getFeatures(state).logo || null;
};

export const getModeBarFeature = (state) => {
  return getFeatures(state).modebar || null;
};

export const getConstructorFeature = (state) => {
  return getFeatures(state).constructor || null;
};

export const getDoneButton = (state) => {
  return getFeatures(state).doneButton || DEFAULT_DONE_BUTTON;
};

export const getIsDoneButtonVisible = (state) => {
  const doneButton = getDoneButton(state);
  const isAppStarted = base.getAppStarted(state);
  const currentEditType = base.getEditType(state);
  const hideByType = currentEditType === editType.as && !isAppStarted;

  return (doneButton.visible === undefined || doneButton.visible === null)
    ? true
    : doneButton.visible && !hideByType;
};

export const getMiscFeatures = createSelector(
  [getFeatures],
  (features) => {
    const { misc = [] } = features;

    return misc.reduce((acc, el) => {
      acc[el.id] = {
        locale: { label: get(el, 'label', '') },
        isVisible: get(el, 'visible', false),
      };
      return acc;
    }, {});
  },
);

export const getExtrasBarFeatures = createSelector(
  [getFeatures], (features) => {
    const { extrasbar = [] } = features;

    return extrasbar.reduce((acc, el) => {
      acc[el.id] = {
        isVisible: get(el, 'visible', false),
      };
      return acc;
    }, {});
  },
);

export const getMiscFeature = (state, miscId) => {
  return getMiscFeatures(state)[miscId] || defaultFeatureObject;
};

export const getMiscFeatureVisible = (state, miscId) => {
  return getMiscFeature(state, miscId).isVisible;
};

export const getExtrasBarFeature = (state, id) => {
  return get(getExtrasBarFeatures(state), id, defaultFeatureObject);
};

export const getExtrasBarVisible = (state, id) => {
  return getExtrasBarFeature(state, id).isVisible;
};

export const getIsModesPanelVisible = (state) => {
  const modesBar = getModeBarFeature(state);

  return find(modesBar, { visible: true }) !== undefined;
};

export const isConstructorButtonVisible = (state, buttonId) => {
  const constructor = getConstructorFeature(state);
  const button = find(constructor, { id: buttonId });

  return get(button, 'visible', false);
};

export const getFieldNamePropertyVisibility = (state) => {
  const constructorFeatures = getConstructorFeature(state);
  const fieldNameProperties = find(
    constructorFeatures,
    { id: constructorProperties.fieldName },
  );

  return get(fieldNameProperties, 'visible', false);
};

export const isImageAvailable = createSelector(
  [getFeaturesToolbar],
  (toolbar) => {
    const imageToolbarItem = find(toolbar, (toolbarItem) => {
      return toolbarItem.id === `tools.${elemTypes.image}.${elemSubTypes.none}`;
    });

    if (!imageToolbarItem) {
      return false;
    }

    return imageToolbarItem.visible;
  },
);

export const isSignatureAvailable = createSelector(
  [getFeaturesToolbar],
  (toolbar) => {
    const signToolbarItem = find(toolbar, (toolbarItem) => {
      return toolbarItem.id === `tools.${elemTypes.signature}.*`;
    });

    if (!signToolbarItem) {
      return false;
    }

    return signToolbarItem.visible;
  },
);

export const isRearrangeAvailable = (state) => {
  return getMiscFeatureVisible(state, miscIds.rearrange.id);
};

export const isFeedbackAvailable = (state) => {
  return getMiscFeatureVisible(state, miscIds.feedback.id);
};

export const isHelpAvailable = (state) => {
  return getMiscFeatureVisible(state, miscIds.help.id);
};

export const isThumbnailsAvailable = (state) => {
  return getMiscFeatureVisible(state, miscIds.thumbnails.id);
};

/**
  * desktopOnlyFeatures:
  * - constructor
  * - watermark
  * - versions
  * - search
  * - fakePdfEdit
  * - comments
*/

export const isCommentsAvailable = (state) => {
  return thisDevice.isDesktop &&
    getMiscFeatureVisible(state, miscIds.comments.id);
};

export const isSearchAvailable = (state) => {
  return thisDevice.isDesktop &&
    getMiscFeatureVisible(state, miscIds.search.id);
};

export const isFakeEditAvailable = (state) => {
  return thisDevice.isDesktop &&
    getMiscFeatureVisible(state, miscIds.fakePdfEdit.id);
};

export const isFConstructorAvailable = (state) => {
  return thisDevice.isDesktop &&
    getExtrasBarVisible(state, extrasbarIds.fConstructor.id);
};

export const isWatermarkBarAvailable = (state) => {
  return thisDevice.isDesktop &&
    getExtrasBarVisible(state, extrasbarIds.watermark.id);
};

export const isVersionsBarAvailable = (state) => {
  return thisDevice.isDesktop &&
    getExtrasBarVisible(state, extrasbarIds.versions.id);
};

export const getUseNewFeedbackModal = (state) => {
  return getFeatures(state).useNewFeedbackModal || false;
};

export const getShowSupportChatButton = (state) => {
  return getFeatures(state).showSupportChatButton || false;
};
