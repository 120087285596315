import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

export const textareaAutocompleteSizes = {
  large: 'large',
  extraLarge: 'extra-large',
  null: null,
};

export const textareaAutocompleteThemes = {
  default: 'default',
  null: null,
};

const TextareaAutocomplete = ({
  theme,
  size,
  children,
  isValid,
  storeRef,
}) => {
  return (
    <div
      className={classnames('rta-item', {
        [`rta-item--size--${size}`]: size,
        [`rta-item--theme--${theme}`]: theme,
        'is-invalid': !isValid,
      })}
      ref={storeRef}
    >
      {children}
    </div>
  );
};

TextareaAutocomplete.propTypes = {
  size: PropTypes.oneOf(Object.values(textareaAutocompleteSizes)),
  theme: PropTypes.oneOf(Object.values(textareaAutocompleteThemes)),
  children: PropTypes.node,
  storeRef: PropTypes.func,
  isValid: PropTypes.bool,
};

TextareaAutocomplete.defaultProps = {
  theme: textareaAutocompleteThemes.null,
  size: textareaAutocompleteSizes.null,
  isValid: true,
  children: null,
  storeRef: null,
};

export default TextareaAutocomplete;
