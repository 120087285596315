import { isAnyFilledDependencyClause } from '../../helpers/conditionsUtils';
import { getDependency, dependenciesMap } from '../../di';


const confirmationRemoveDependencies = (element) => {
  return async (dispatch, getState) => {
    const jsfcoreModalsActions = getDependency(dependenciesMap.jsfcoreModalsActions);
    const jsfcoreModalsTypes = getDependency(dependenciesMap.jsfcoreModalsTypes);
    const jsfcoreSelectors = getDependency(dependenciesMap.jsfcoreSelectors);
    const jsfcoreModalsReducer = getDependency(dependenciesMap.jsfcoreModalsReducer);
    const isSignNow = getDependency(dependenciesMap.isSignNow);

    const { conditional } = element.template;
    const activeElement = jsfcoreSelectors.elements.getActiveElement(
      getState(),
    );
    if (conditional === false && activeElement.template.dependency) {
      const { dependency } = activeElement.template;
      const isNecessaryToOpenModal = isAnyFilledDependencyClause(dependency);

      const isConfirmed =
        isNecessaryToOpenModal
          ? await dispatch(
            jsfcoreModalsActions.openModal(
              {
                type: jsfcoreModalsTypes.modalTypes.confirmation,
                open: jsfcoreModalsReducer.showConfirmationModal,
              },
              jsfcoreModalsTypes.confirmationTypes.removeConditions,
            ),
          )
          : true;
      if (isConfirmed) {
        // SignNow прекрасно работает, если отправить undefined.
        // Для JSFiller такое не катит: undefined-апдейты обрезаются на уровне
        // либы, и бекенд такое не обрабатывает
        return {
          id: element.id,
          template: {
            dependency: isSignNow()
              ? undefined
              : false,
            conditional,
          },
        };
      }

      return {
        id: element.id,
        template: { conditional: true },
      };
    }

    return element;
  };
};

export default confirmationRemoveDependencies;
