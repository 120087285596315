import React from 'react';
import PropTypes from 'prop-types';

const RightSidebarItemList = ({ children, onClick, ...props }) => {
  return (
    <div
      onClick={onClick}
      className="collapsed-right-sidebar__list-item"
      {...props}
    >
      {children}
    </div>
  );
};

RightSidebarItemList.propTypes = {
  onClick: PropTypes.func,
  children: PropTypes.node.isRequired,
};

RightSidebarItemList.defaultProps = {
  onClick: null,
};

export default RightSidebarItemList;
