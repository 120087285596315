const fieldSizes = {
  top: 2,
  right: 2,
  bottom: 2,
  left: 2,

};
const datePickerShadow = {
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
};

export default {
  postMeasure(args) {
    const { anchorRect, elementRect } = args;
    return {
      ...args,
      anchorRect: {
        ...anchorRect,
        top: anchorRect.top - fieldSizes.top,
        right: anchorRect.right + fieldSizes.right,
        bottom: anchorRect.bottom + fieldSizes.bottom,
        left: anchorRect.left - fieldSizes.left,

        width: anchorRect.width + fieldSizes.left + fieldSizes.right,
        height: anchorRect.height + fieldSizes.top + fieldSizes.bottom,
      },

      elementRect: {
        ...elementRect,
        top: elementRect.top + datePickerShadow.top,
        right: elementRect.right + datePickerShadow.right,
        bottom: elementRect.bottom + datePickerShadow.bottom,
        left: elementRect.left - datePickerShadow.left,

        width: elementRect.width + datePickerShadow.left + datePickerShadow.left,
        height: elementRect.height + datePickerShadow.top + datePickerShadow.bottom,
      },
    };
  },
};
