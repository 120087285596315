import React from 'react';
import PropTypes from 'prop-types';

const TextBoxListItem = ({ children }) => {
  return (
    <li className="text-box__list-item">
      {children}
    </li>
  );
};

TextBoxListItem.propTypes = {
  children: PropTypes.node.isRequired,
};

export default TextBoxListItem;
