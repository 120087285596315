import React from 'react';
import PropTypes from 'prop-types';

const TabsContent = ({ children }) => {
  return (
    <div className="tabs__content">
      {children}
    </div>
  );
};

TabsContent.propTypes = {
  children: PropTypes.node.isRequired,
};

export default TabsContent;
