import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

export const customFieldSizes = {
  large: 'large',
  medium: 'medium',
  small: 'small',
  parent: 'parent',
  null: null,
};

export const customFieldThemes = {
  default: 'default',
  lightgray: 'lightgray',
  null: null,
};

export const customFieldTypes = {
  compact: 'compact',
  null: null,
};

export const customFieldBottomOffsets = {
  small: 'small',
  null: null,
};

export const customFieldArrows = {
  default: 'default',
};

const CustomField = ({
  children,
  size,
  style,
  theme,
  isInvalid,
  isFocused,
  width,
  type,
  arrows,
  storeRef,
  bottomOffset,
  attributes,
  onFocus,
  onBlur,
}) => {
  return (
    <div
      className={classnames('custom-field', {
        [`custom-field--size--${size}`]: size,
        [`custom-field--theme--${theme}`]: theme,
        [`custom-field--type--${type}`]: type,
        [`custom-field--offset-bottom--${bottomOffset}`]: bottomOffset,
        [`custom-field--arrows--${arrows}`]: arrows,
        'is-invalid': isInvalid,
        'is-focused': isFocused,
      })}
      style={{ width, ...style }}
      ref={storeRef}
      onFocus={onFocus}
      onBlur={onBlur}
      {...attributes}
    >
      {children}
    </div>
  );
};

CustomField.propTypes = {
  children: PropTypes.node.isRequired,
  size: PropTypes.oneOf(Object.values(customFieldSizes)),
  style: PropTypes.shape({}),
  theme: PropTypes.oneOf(Object.values(customFieldThemes)),
  isInvalid: PropTypes.bool,
  isFocused: PropTypes.bool,
  width: PropTypes.number,
  type: PropTypes.oneOf(Object.values(customFieldTypes)),
  arrows: PropTypes.oneOf(Object.values(customFieldArrows)),
  storeRef: PropTypes.func,
  bottomOffset: PropTypes.oneOf(
    Object.values(customFieldBottomOffsets),
  ),
  attributes: PropTypes.shape({}),
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
};

CustomField.defaultProps = {
  isInvalid: false,
  size: null,
  style: null,
  theme: null,
  isFocused: false,
  width: null,
  type: customFieldTypes.null,
  arrows: null,
  storeRef: null,
  bottomOffset: null,
  attributes: null,
  onFocus: null,
  onBlur: null,
};

export default CustomField;
