import React from 'react';
import FocusControllerContext from './FocusControllerContext';

function withFocusControllerContext(Component) {
  return function FocusControllerContextConsumer(props) {
    return (
      <FocusControllerContext.Consumer>
        {context => (
          <Component
            {...props}
            {...context}
          />
        )}
      </FocusControllerContext.Consumer>
    );
  };
}

export default withFocusControllerContext;
