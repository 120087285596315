export const EMBEDDED = 'embedded';

/**
 * Callbacks types
 *
 * - readyCallback нужен для уведомления пользователей интерфейса
 *   о том, что можно вызывать методы интерфейса
 *
 *   Example bad:
 *   // Interface может быть как готов, так и не готов
 *   // такое использование опасно
 *   window.JSFiller.open()
 *
 *   Example good:
 *   window.JSFiller.ready(() => {
 *     // Inretface точно готов
 *     window.JSFiller.open()
 *   })
 *
 *
 * - destroyCallback нужен для уведомления пользователей интерфейса
 *   о том, что работа с редактором завершена, чтобы пользователь
 *   интерфейса мог редикектнуть пользователя на нужную страницу
 *   (при необходимости)
 *
 *   Если destroy() === true - мы изменим location.href внутри JSFiller'a
 *   Если destroy() === false - мы ничего не будем делать
 *
 *
 * - openCallback нужен для уведомления пользователей интерфейса
 *   о том, что пользователь начал работу с эдитором (этим моментом мы считаем
 *   загрузку и вставку первого canvas)
 */
export const cbsTypes = {
  ready: 'ready',
  destroy: 'destroy',
  open: 'open',
  save: 'save',
};


// Если сценарий прервался по какой-либо причине и не дошел до destroy, в callback должно быть
// передано значение "skipped" в качестве первого аргумента.
// (c) https://pdffiller.atlassian.net/wiki/spaces/PD/pages/659456164/JS+API
export const SKIPPED = 'skipped';
