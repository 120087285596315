import React from 'react';
import PropTypes from 'prop-types';

const CounterSeparator = ({ children }) => {
  return (
    <div className="counter__separator">
      {children}
    </div>
  );
};

CounterSeparator.propTypes = {
  children: PropTypes.string,
};

CounterSeparator.defaultProps = {
  children: '',
};

export default CounterSeparator;
