import PropTypes from 'prop-types';
import classnames from 'classnames';
import React from 'react';

export const selectThemes = {
  secondary: 'secondary',
  transparentGray: 'transparent-gray',
  multicolored: 'multicolored',
  light: 'light',
  whiteBordered: 'white-bordered',
  default: 'default',
  white: 'white',
  flatWhite: 'flat-white',
  primary: 'primary',
};

export const selectSizes = {
  extraSmall: 'extra-small',
  small: 'small',
  parentHeight: 'parent-height',
  null: null,
};

export const selectTypes = {
  fillableField: 'fillable-field',
  null: null,
};

export const selectMenuHorizontalAlign = {
  left: 'left',
  null: null,
};

const selectWrapperFactory = (Component) => {
  const SelectWrapper = (props) => {
    const {
      width,
      style,
      valueRend,
      theme,
      size,
      type,
      className,
      attributes,
      ...restProps
    } = props;

    return (
      <Component
        {...restProps}
        selectAttributes={attributes}
        className={classnames(
          {
            [`Select--theme--${theme}`]: theme,
            [`Select--size--${size}`]: size,
            [`Select--types--${type}`]: type,
          },
          className,
        )}
        // generates Warning: Function components cannot be given refs.
        // but this prop is needed by Creatable
        // https://github.com/pdffiller/react-select/blob/master/src/Creatable.js#L293
        ref={props.storeRef}
        autosize={false}
        valueRenderer={valueRend}
        style={{ width, ...style }}
      />
    );
  };

  SelectWrapper.defaultProps = {
    width: null,
    style: null,
    selectMenuHorizontalAlign: selectMenuHorizontalAlign.null,
    valueRend: null,
    storeRef: null,
    theme: selectThemes.null,
    size: selectSizes.null,
    type: selectTypes.null,
    className: null,
    attributes: null,
  };

  SelectWrapper.propTypes = {
    width: PropTypes.number,
    style: PropTypes.shape({}),
    selectMenuHorizontalAlign: PropTypes.oneOf(
      Object.values(selectMenuHorizontalAlign),
    ),
    valueRend: PropTypes.func,
    storeRef: PropTypes.func,

    theme: PropTypes.oneOf(Object.values(selectThemes)),
    size: PropTypes.oneOf(Object.values(selectSizes)),
    type: PropTypes.oneOf(Object.values(selectTypes)),
    className: PropTypes.string,
    attributes: PropTypes.shape({}),
  };

  SelectWrapper.themes = selectThemes;
  SelectWrapper.sizes = selectSizes;
  SelectWrapper.types = selectTypes;

  return SelectWrapper;
};

export default selectWrapperFactory;
