import { CONDITION_STATUS_NONE } from '../../../../constants';

export const CONDITION_SYSTEM_DESTROY_URL = '/en/forms.htm?method=system';
export const CONDITION_SYSTEM_SEND_STATUS = 'send auth';
export const CONDITION_SYSTEM_AUTH_RECEIVE_STATUS = 'auth receive';
export const CONDITION_SYSTEM_OPERATIONS_RECEIVE_STATUS = 'operations receive';
export const CONDITION_SYSTEM_SEND_DESTROY = 'destroy';
export const CONDITION_SYSTEM_RECEIVE_DESTROY = 'destroyed';

const getNewSystemStatus = (prevStatus, nextStatus) => {
  return prevStatus === CONDITION_SYSTEM_RECEIVE_DESTROY && nextStatus !== CONDITION_STATUS_NONE
    ? prevStatus
    : nextStatus;
};

// "changes" indicate that was send one or more tools group operations
// from client in current session
// https://pdffiller.atlassian.net/browse/JSF-3963
// https://pdffiller.atlassian.net/browse/JSF-4054
export const SystemCondition = {
  getInitialState: () => {
    return ({
      status: CONDITION_STATUS_NONE,
      destroyUrl: CONDITION_SYSTEM_DESTROY_URL,
      doneProgress: false,
      changes: false,
    });
  },

  reconnect: (state) => {
    return ({
      ...state,
      status: getNewSystemStatus(state.status, CONDITION_STATUS_NONE),
    });
  },

  sendAuth: (state) => {
    return ({
      ...state,
      status: getNewSystemStatus(state.status, CONDITION_SYSTEM_SEND_STATUS),
    });
  },

  receiveAuth: (state) => {
    return ({
      ...state,
      status: getNewSystemStatus(state.status, CONDITION_SYSTEM_AUTH_RECEIVE_STATUS),
    });
  },

  receiveOperations: (state) => {
    return ({
      ...state,
      status: getNewSystemStatus(state.status, CONDITION_SYSTEM_OPERATIONS_RECEIVE_STATUS),
    });
  },

  destroy: (state, destroyUrl, doneProgress) => {
    return ({
      ...state,
      status: getNewSystemStatus(state.status, CONDITION_SYSTEM_SEND_DESTROY),
      ...(destroyUrl && { destroyUrl }),
      doneProgress,
    });
  },

  destroyed: (state, destroyUrl, changes) => {
    return ({
      ...state,
      ...(destroyUrl && { destroyUrl }),
      changes,
      status: CONDITION_SYSTEM_RECEIVE_DESTROY,
    });
  },
};
