import * as baseSelectors from './baseSelectors';

const getIsSignnowModalVisible = (state, modalType) => {
  const signnowModals = baseSelectors.getSignnowModals(state);
  return signnowModals && (
    modalType
      ? signnowModals[modalType]
      : Object.values(signnowModals).indexOf(true) !== -1
  );
};

export default getIsSignnowModalVisible;
