import isEmpty from 'lodash/isEmpty';
import {
  fillComment,
  updateCommentInComments,
  applyUpdateToComment,
  deleteCommentInComments,
  addCommentInComments,
  moveCommentToNewIndex,
} from './utils';
import { sendComment, createCommentsMapFromArray } from '../Operations/Utils';

export const addComment = (transport, state, action) => {
  const { comments, commentsMap } = state;
  const { parent } = action.comment;
  const comment = fillComment(action.comment, state, parent);

  if (parent) {
    const parentObj = commentsMap[parent];
    if (parentObj === undefined) {
      // eslint-disable-next-line no-console
      console.error('Attempt to create comment in the parent that doesn\'t exist');
      return state;
    }

    const updatedComment = applyUpdateToComment({
      content: {
        replies: [
          ...(parentObj.content.replies || []),
          comment,
        ],
      },
    }, parentObj);

    const updatedComments = updateCommentInComments(updatedComment, comments);
    sendComment(transport, updatedComment);

    return {
      comments: updatedComments,
      commentsMap: createCommentsMapFromArray(updatedComments),
    };
  }

  if (state.commentsMap[action.comment.id]) {
    // eslint-disable-next-line no-console
    console.error('Attempt to create element that already exists');
    return state;
  }

  // sendComment(transport, comment);
  const updatedComments = addCommentInComments(comment, comments);
  return {
    comments: updatedComments,
    commentsMap: createCommentsMapFromArray(updatedComments),
  };
};

export const updateComment = (transport, state, action) => {
  const { comment } = action;
  const { comments, commentsMap } = state;
  const { parent } = comment;
  if (!comments.length) {
    return state;
  }

  if (comment.id === undefined) {
    return state;
  }

  if (parent) {
    const parentObj = commentsMap[parent];
    if (parentObj === undefined) {
      // eslint-disable-next-line no-console
      console.error('Attempt to update comment in the parent that doesn\'t exist');
      return state;
    }

    const { replies } = parentObj.content;
    const commentToUpdate = replies.find((el) => {
      return el.id === comment.id;
    });
    if (commentToUpdate === undefined) {
      // eslint-disable-next-line no-console
      console.error('Attempt to update comment that doesn\'t exist');
      return state;
    }

    // TODO: get rid of maps
    const updatedComment = applyUpdateToComment(comment, commentToUpdate);
    const updatedParent = applyUpdateToComment({
      content: {
        replies: parentObj.content.replies.map((el) => {
          if (el.id !== updatedComment.id) {
            return el;
          }
          return updatedComment;
        }),
      },
    }, parentObj);
    sendComment(transport, updatedParent);

    const updatedComments = comments.map((el) => {
      if (el.id !== parent) {
        return el;
      }
      return updatedParent;
    });

    return {
      comments: updatedComments,
      commentsMap: createCommentsMapFromArray(updatedComments),
    };
  }

  const commentToUpdate = commentsMap[comment.id];
  if (commentToUpdate === undefined) {
    // eslint-disable-next-line no-console
    console.error('Attempt to update comment that doesn\'t exist');
    return state;
  }

  const updatedComment = applyUpdateToComment(comment, commentToUpdate);
  const updatedComments = moveCommentToNewIndex(updatedComment, comments);

  if (!isEmpty(updatedComment.content.message)) {
    sendComment(transport, updatedComment);
  }

  return {
    comments: updatedComments,
    commentsMap: createCommentsMapFromArray(updatedComments),
  };
};

export const deleteComment = (transport, state, action) => {
  const { comment: commentToDelete } = action;
  const { parent } = commentToDelete;
  const { comments, commentsMap } = state;
  if (!comments.length) {
    return comments;
  }

  if (parent) {
    const parentObj = commentsMap[parent];
    if (parentObj === undefined) {
      // eslint-disable-next-line no-console
      console.error('Attempt to delete comment in the parent that doesn\'t exist');
      return state;
    }

    const { replies } = parentObj.content;
    const newReplies = deleteCommentInComments(commentToDelete, replies);
    const updatedComment = {
      ...parentObj,
      content: {
        ...parentObj.content,
        replies: newReplies,
      },
    };

    const updatedComments = updateCommentInComments(updatedComment, comments);
    sendComment(transport, updatedComment);
    return {
      comments: updatedComments,
      commentsMap: createCommentsMapFromArray(updatedComments),
    };
  }

  if (commentsMap[commentToDelete.id] === undefined) {
    // eslint-disable-next-line no-console
    console.error('Attempt to delete comment that doesn\'t exist');
    return state;
  }
  const updatedComments = deleteCommentInComments(commentToDelete, comments);

  sendComment(transport, {
    ...commentToDelete,
    content: {
      ...commentToDelete.content,
      visible: false,
    },
  });

  return {
    comments: updatedComments,
    commentsMap: createCommentsMapFromArray(updatedComments),
  };
};
