import { MAX_COUNT_BUSY, CONDITION_STATUS_NONE } from '../../../../constants';

export const CONDITION_SYSTEM_CANCEL_URL = '/en/forms.htm?method=access';
export const CONDITION_SYSTEM_LOGIN_URL = '/en/login.htm';
export const CONDITION_SYSTEM_ACCESS_TIMER = 60;

export const AccessCondition = {

  getInitialState: () => {
    return {
      status: CONDITION_STATUS_NONE,
      cancelUrl: CONDITION_SYSTEM_CANCEL_URL,
      loginUrl: CONDITION_SYSTEM_LOGIN_URL,
      accessTimer: CONDITION_SYSTEM_ACCESS_TIMER,
      autoCheck: true,
      counterBusy: 0,
    };
  },

  setAccessTimer: ({ access }, { accessTimer }) => {
    return {
      ...access,
      accessTimer,
    };
  },

  handleBusyCounter: (access, setToZero = false) => {
    if (setToZero) {
      return {
        ...access,
        counterBusy: 0,
        autoCheck: true,
      };
    }
    const newBusyCounter = access.counterBusy + 1;
    return {
      ...access,
      counterBusy: newBusyCounter,
      autoCheck: newBusyCounter <= MAX_COUNT_BUSY,
    };
  },

};
