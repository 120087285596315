import React from 'react';
import PropTypes from 'prop-types';

const RightSidebarList = ({ children }) => {
  return (
    <ul className="collapsed-right-sidebar__list">
      {children}
    </ul>
  );
};

RightSidebarList.propTypes = {
  children: PropTypes.node.isRequired,
};

export default RightSidebarList;
