import PropTypes from 'prop-types';
import React, { Component } from 'react';

export default class MenuList extends Component {
  static propTypes = {
    children: PropTypes.node,

    onTouchStart: PropTypes.func,
    storeRef: PropTypes.func,
    style: PropTypes.shape({}),
  };

  static defaultProps = {
    children: null,

    onTouchStart: null,
    storeRef: null,
    style: null,
  };

  render() {
    return (
      <ul
        className="menu__list"
        onTouchStart={this.props.onTouchStart}
        ref={this.props.storeRef}
        style={this.props.style}
      >
        {this.props.children}
      </ul>
    );
  }
}
