import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

export const settingsBoxSizes = {
  medium: 'medium',
  null: null,
};

export const settingsBoxTypes = {
  compact: 'compact',
  null: null,
};

export const settingsBoxOffsets = {
  small: 'small',
  null: null,
};

const SettingsBoxBody = ({
  children,
  offset,
  size,
  storeRef,
  type,
}) => {
  return (
    <div
      className={classnames('settings-box', {
        [`settings-box--offset--${offset}`]: offset,
        [`settings-box--size--${size}`]: size,
        [`settings-box--type--${type}`]: type,
      })}
      ref={storeRef}
    >
      <div className="settings-box__body">
        {children}
      </div>
    </div>
  );
};

SettingsBoxBody.propTypes = {
  children: PropTypes.node.isRequired,
  offset: PropTypes.string,
  size: PropTypes.string,
  storeRef: PropTypes.func,
  type: PropTypes.string,
};

SettingsBoxBody.defaultProps = {
  offset: settingsBoxOffsets.null,
  size: settingsBoxSizes.null,
  storeRef: null,
  type: settingsBoxTypes.null,
};

export default SettingsBoxBody;
