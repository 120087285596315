import {
  getWithoutDuplicates,
  getSquashedItems,
  getItemsWithText,
  getJsfStyleItems,
  getRotatedItems,
  getFixedGeometryItems,
} from './pdfTextHelpers';

const getPageTextItems = ({ items, styles }, pdfPage, pageSetting) => {
  const pageSize = {
    width: pdfPage.view[2],
    height: pdfPage.view[3],
  };

  const filteredDuplicateItems = getWithoutDuplicates(items);
  const squashedItems = getSquashedItems(filteredDuplicateItems, styles, pageSize.height);
  const itemsWithText = getItemsWithText(squashedItems);
  const jsfItems = getJsfStyleItems(itemsWithText, styles, pageSize.height);
  const rotatedItems = getRotatedItems(jsfItems, pageSetting, pageSize);
  return getFixedGeometryItems(rotatedItems);
};

export default getPageTextItems;
