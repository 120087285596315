import { saveCaretPosition as saveCaretPositionAction } from '../modules/navigation';

const getCaretPosition = ({
  clickPositionFromStart,
  id,
  itemWidth,
  text,
}) => {
  const clickPositionInPercent = clickPositionFromStart / itemWidth;
  const textLength = text && text.length;

  const caretPosition = Math.ceil(textLength * clickPositionInPercent);

  return {
    id,
    position: caretPosition,
  };
};

const saveCaretPosition = ({ clickPositionFromStart, id, itemWidth, text }) => {
  return (dispatch) => {
    const caretPosition = getCaretPosition({
      clickPositionFromStart,
      id,
      itemWidth,
      text,
    });

    dispatch(saveCaretPositionAction(caretPosition));
  };
};

export default saveCaretPosition;
