const modulesJsf = {
  wizard: 'wizard',
  managers: 'managers',
  rearrange: 'rearrange',
  fConstructor: 'fConstructor',
  comments: 'comments',
  fakeEdit: 'fakeEdit',
  formula: 'formula',
  versions: 'versions',
  feedback: 'feedback',
};

/*
 * Конструктор и визард на самом деле не ленивые в snfiller,
 * и мы эмулируем эту ленивость, добавляя эти ключи в reducer lazyLoad'а
 */
const modulesSnf = {
  fConstructor: 'fConstructor',
  wizard: 'wizard',
  formula: 'formula',
};

const allModules = {
  ...modulesSnf,
  ...modulesJsf,
};

const loadModuleAttemptTimeout = 1000; // ms
const loadModuleMaxAttempts = 5;

const mockObject = {};
const mockArray = [];
const fns = {
  true() {
    return true;
  },
  false() {
    return false;
  },
  null() {
    return null;
  },
  array() {
    return mockArray;
  },
  object() {
    return mockObject;
  },
  val(value) {
    return () => {
      return value;
    };
  },
  empty() {},
};

const mocks = {
  fns,
  null: null,
  object: mockObject,
  array: mockArray,
  component: function NullComponent() {
    return null;
  },
};

export {
  allModules,
  modulesJsf,
  modulesSnf,
  mocks,
  loadModuleAttemptTimeout,
  loadModuleMaxAttempts,
};
