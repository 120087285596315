/* eslint-disable jsx-a11y/no-redundant-roles */
import React from 'react';

const CustomSettingsSeparator = () => {
  return (
    <hr
      className="custom-settings__separator"
      role="separator"
    />
  );
};

export default CustomSettingsSeparator;
