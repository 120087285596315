import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

export const entitledBoxBodyThemes = {
  default: 'default',
  black: 'black',
  null: null,
};

export const entitledBoxBodyOffsets = {
  large: 'large',
};

const EntitledBoxBody = ({ theme, offset, children, storeRef }) => {
  return (
    <div
      className={classnames('entitled-box', {
        [`entitled-box--theme--${theme}`]: theme,
        [`entitled-box--offset--${offset}`]: offset,
      })}
      ref={storeRef}
    >
      <div className="entitled-box__body">
        {children}
      </div>
    </div>
  );
};

EntitledBoxBody.propTypes = {
  theme: PropTypes.oneOf(Object.values(entitledBoxBodyThemes)),
  offset: PropTypes.oneOf(Object.values(entitledBoxBodyOffsets)),
  children: PropTypes.node.isRequired,
  storeRef: PropTypes.func,
};

EntitledBoxBody.defaultProps = {
  theme: entitledBoxBodyThemes.null,
  offset: entitledBoxBodyOffsets.null,
  storeRef: null,
};

export default EntitledBoxBody;
