import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

export const counterThemes = {
  default: 'default',
  null: null,
};
export const counterBehaviors = {
  overflowText: 'overflow-text',
  null: null,
};
export const counterOffsets = {
  medium: 'medium',
  null: null,
};

const CounterBody = ({
  children,
  theme,
  behavior,
  offset,
  attributes,
  innerHTML,
  onClick,
}) => {
  return (
    <div
      className={classnames(
        'counter',
        {
          [`counter--theme--${theme}`]: theme,
          [`counter--behavior--${behavior}`]: behavior,
          [`counter--offset--${offset}`]: offset,
        },
      )}
      // eslint-disable-next-line
      dangerouslySetInnerHTML={innerHTML}
      onClick={onClick}
      {...attributes}
    >
      {children}
    </div>
  );
};

CounterBody.propTypes = {
  children: PropTypes.node,
  theme: PropTypes.oneOf(
    Object.values(counterThemes),
  ),
  behavior: PropTypes.oneOf(
    Object.values(counterBehaviors),
  ),
  offset: PropTypes.oneOf(
    Object.values(counterOffsets),
  ),
  innerHTML: PropTypes.shape({
    __html: PropTypes.string,
  }),
  onClick: PropTypes.func,
  attributes: PropTypes.shape({}),
};

CounterBody.defaultProps = {
  children: null,
  theme: counterThemes.null,
  behavior: counterBehaviors.null,
  offset: counterOffsets.null,
  innerHTML: null,
  onClick: null,
  attributes: {},
};

export default CounterBody;
