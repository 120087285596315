import isEmpty from 'lodash/isEmpty';
import get from 'lodash/get';

import {
  DEFAULT_SUBTYPE,
  createFlatId,
  isVisible,
} from '../Utils';

export function createElementFromOperation(operation, state) {
  const { properties, id } = operation;

  const { sendOperationsCount } = state;
  const {
    content, element, enabled, pageId,
    subType = DEFAULT_SUBTYPE, template, type,
  } = properties;

  return {
    element: {
      id: createFlatId(element),
      content,
      element,
      enabled,
      pageId,
      subType,
      template,
      type,
    },
    sendOperationsCount: {
      ...sendOperationsCount,
      local: id.clientId === 0 && element.localId > sendOperationsCount.local
        ? element.localId
        : sendOperationsCount.local,
    },
  };
}

export function applyElementUpdateOperation(operation, elementToUpdate) {
  const content = {
    ...elementToUpdate.content,
    ...operation.properties.content,
  };

  return {
    ...elementToUpdate,

    // may be changed, e.g. fillable checkmark
    subType: operation.properties.subType,

    // content mustn't be an empty object
    content: isVisible(operation.properties) && !isEmpty(content)
      ? content
      : undefined,

    ...(get(elementToUpdate, 'template', false) && {
      template: {
        ...elementToUpdate.template,
        ...operation.properties.template,
      },
    }),
  };
}
