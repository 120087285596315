import * as selectors from '../selectors';
import activateDefaultTool from './activateDefaultTool';
import {
  changePage as changePageWs,
} from '../modules/navigation';
import isSignNow from '../../helpers/const/isSignNow';
import setActiveElement from './setActiveElement';

const setActiveElementFalse = () => {
  return (dispatch, getState) => {
    const state = getState();
    const activeElementId = selectors.base.getActiveElementId(state);
    if (activeElementId) {
      dispatch(setActiveElement(activeElementId, false));
    }
  };
};

// eslint-disable-next-line import/prefer-default-export
export const changePage = (...args) => {
  return (dispatch) => {
    if (isSignNow()) {
      dispatch(activateDefaultTool());
    }
    dispatch(setActiveElementFalse());
    dispatch(changePageWs(...args));
  };
};
