import { getFormulasDependentOnElement } from '../store/selectors';
import { jsfcoreModalsTypes, jsfcoreModalsActions } from '../di';

const checkIsElementInFormulas = (dispatch, getState, element, isModalNeeded = true) => {
  const state = getState();
  const dependentFormulas = getFormulasDependentOnElement(element)(state);

  if (dependentFormulas.length === 0) {
    return false;
  }

  // TODO: this block fires onRemove modal and should be moved in separate or related file
  if (isModalNeeded) {
    const { modalsMap, modalTypes, warningTypes } = jsfcoreModalsTypes;
    dispatch(
      jsfcoreModalsActions.openModal(
        modalsMap[modalTypes.warning],
        warningTypes.removeFormulaFieldWarning,
      ),
    );
  }

  return true;
};

export default checkIsElementInFormulas;
