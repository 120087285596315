import PropTypes from 'prop-types';
import React, { Component } from 'react';
import cls from 'classnames';

export const fillableActionsThemes = {
  default: 'default',
  null: null,
};

export const fillableActionsOffsets = {
  large: 'large',
  null: null,
};

export default class FillableActions extends Component {
  static propTypes = {
    children: PropTypes.node,

    theme: PropTypes.oneOf(
      Object.values(fillableActionsThemes),
    ),
    offset: PropTypes.oneOf(
      Object.values(fillableActionsOffsets),
    ),
  };

  static defaultProps = {
    children: null,

    theme: fillableActionsThemes.null,
    offset: fillableActionsOffsets.null,
  };

  render() {
    const { theme, offset } = this.props;

    return (
      <div
        className={
          cls(
            'fillable-actions', {
              [`fillable-actions--theme--${theme}`]: theme,
              [`fillable-actions--offset--${offset}`]: offset,
            },
          )
        }
      >
        {this.props.children}
      </div>
    );
  }
}
