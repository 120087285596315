import { useContext } from 'react';
import FocusControllerContext from './FocusControllerContext';

const useDisableBlur = (onStart, onEnd) => {
  const {
    restoreFocus,
    disableBlurGlobally,
    enableBlurGlobally,
  } = useContext(FocusControllerContext);

  const onStartWithDisableBlur = (...args) => {
    disableBlurGlobally();
    if (onStart) onStart(...args);
  };

  const onEndWithDisableBlur = (...args) => {
    if (onEnd) onEnd(...args);
    restoreFocus();
    enableBlurGlobally();
  };

  return [onStartWithDisableBlur, onEndWithDisableBlur];
};

export default useDisableBlur;
