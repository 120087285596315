import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import cls from 'classnames';

export const mediaSizes = {
  parentHeight: 'parent-height',
  parent: 'parent',
  null: null,
};

export const mediaTypes = {
  image: 'image',
  null: null,
};

export const mediaOffsetsTop = {
  huge: 'huge',
  null: null,
};

export const mediaOffsetsBottom = {
  large: 'large',
  null: null,
};

export const mediaThemes = {
  halfTransparent: 'half-transparent',
  null: null,
};

export default class Media extends Component {
  static propTypes = {
    logoUrl: PropTypes.string,
    alt: PropTypes.string,

    size: PropTypes.oneOf(
      Object.values(mediaSizes),
    ),
    type: PropTypes.oneOf(
      Object.values(mediaTypes),
    ),
    offsetTop: PropTypes.oneOf(
      Object.values(mediaOffsetsTop),
    ),
    offsetBottom: PropTypes.oneOf(
      Object.values(mediaOffsetsBottom),
    ),
    theme: PropTypes.oneOf(Object.values(mediaThemes)),
    className: PropTypes.string,
  };

  static defaultProps = {
    logoUrl: null,
    alt: '',
    size: mediaSizes.null,
    type: mediaTypes.null,
    offsetTop: null,
    offsetBottom: null,
    theme: mediaThemes.null,
    className: '',
  };

  render() {
    const { size, type, offsetTop, offsetBottom, alt, logoUrl, theme, className } = this.props;

    return (
      <Fragment>
        {logoUrl && (
          <img
            className={cls('media', className, {
              [`media--size--${size}`]: size,
              [`media--type--${type}`]: type,
              [`media--offset-top--${offsetTop}`]: offsetTop,
              [`media--offset-bottom--${offsetBottom}`]: offsetBottom,
              [`media--theme--${theme}`]: theme,
            })}
            src={logoUrl}
            alt={alt}
            title={alt}
          />
        )}
      </Fragment>
    );
  }
}
