import React from 'react';

/**
 * Здесь до этого была обертка в виде FocusControllerRestoreFocusArea
 * сейчас необходимости в нем уже не видно. Он нужен был чтобы элемент не блюрился
 * после повторного клика на него. Но сейчас это не воспроизвелось (textTool, checkmark)
 *
 * Задумывалось, что FocusControllerRestoreFocusArea обрабатывает только клик
 * но сейчас он оброс функционалом и по mouseDown делал disableBlur
 *
 * Если это не пройдет тестирование или будут замечены баги, то нужно будет
 * отделить от FocusControllerRestoreFocusArea часть про handleClickCapture и использовать
 * здесь только ее
 */
function focusControllerDraggableDecoratorMobile(DraggableCore) {
  function CancelBlurForDraggableCore(props) {
    return (
      <DraggableCore
        {...props}

        // Prevent blur after touchend
        enableUserSelectHack={false}
      />
    );
  }

  return CancelBlurForDraggableCore;
}

export default focusControllerDraggableDecoratorMobile;
