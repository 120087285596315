import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { positions } from '../../helpers/const';
import EndPoint from '../Tools/LineTool/EndPoint/EndPoint';

const endPointPositions = [
  { resizeIndex: positions.topleft, cursor: 'nwse-resize', classMod: 'top-left' },
  { resizeIndex: positions.topright, cursor: 'nesw-resize', classMod: 'top-right' },
  { resizeIndex: positions.bottomright, cursor: 'nwse-resize', classMod: 'bottom-right' },
  { resizeIndex: positions.bottomleft, cursor: 'nesw-resize', classMod: 'bottom-left' },
];

export default class ResizeBox extends Component {
  static propTypes = {
    onResizeDragStart: PropTypes.func.isRequired,
    onResizeDragMove: PropTypes.func.isRequired,
    onResizeDragStop: PropTypes.func.isRequired,
    isDragging: PropTypes.bool,
    resizeIndex: PropTypes.number,
    // for constructor style
    isFConstructorShown: PropTypes.bool,
  };

  static defaultProps = {
    isDragging: false,
    resizeIndex: positions.none,
    isFConstructorShown: false,
  };

  render() {
    return (
      <div className="box__resize-container resizeBox-ResizeBox">
        {endPointPositions
          .map(({ resizeIndex, cursor, classMod }) => {
            return (
              <EndPoint
                key={`${resizeIndex}_${cursor}`}
                onDragStart={this.props.onResizeDragStart}
                onDragMove={this.props.onResizeDragMove}
                onDragStop={this.props.onResizeDragStop}
                disabled={
                  (this.props.resizeIndex && this.props.resizeIndex !== resizeIndex) ||
                  this.props.isDragging
                }
                resizeIndex={resizeIndex}
                cursor={cursor}
                zIndex={10000}
                isFConstructorShown={!!this.props.isFConstructorShown}
                classMod={classMod}
              />
            );
          })
        }
      </div>
    );
  }
}
