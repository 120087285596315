import findIndex from 'lodash/findIndex';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';

import {
  createId,
  flatIdToId,
  updateElement,
  deleteElement,
  getNewDefaults,
  getNextLocalId,
  isFillableElement,
  sendFillableElement,
  fillableActionsTypes,
  applyNextDbNameByType,
  createMapFromElements,
  createGroupsUsingElements,
  sendOrderFillableElements,
  reCreateFillableGhostElement,
  addFillableElementAndSortElements,
  getElementsToUpdateAccordingToGroup,
  alignIfRequiresContentPos,
  sendToolElement,
  applyToDefaultsAndUpdateGhost,
} from '../Operations/Utils';

import isSignNow from '../../../isSignNow';

import { TOOL_TYPES, TOOL_SUB_TYPES } from '../../../constants';
import { getUpdatedContentOnTemplateUpdating } from '../../helpers';
import { updateGhostAfterUpdateFillableElement } from '../Elements';

export const doChangeAllowEditing = (state, roleId) => {
  const { elements } = state;
  const elementsMap = {};
  const newElements = elements.map((el) => {
    if (!el.template) {
      return el;
    }

    return {
      ...el,
      template: {
        ...el.template,
        allowEditing: roleId === el.template.roleId
          ? null
          : [],
      },
    };
  });
  newElements.forEach((el) => {
    elementsMap[el.id] = el;
  });
  return {
    elements: newElements,
    elementsMap,
  };
};

export const doDeleteContentForPreviewMode = (state) => {
  const { elements } = state;
  const elementsMap = {};
  const newElements = elements.map((el) => {
    if (!el.content || !el.template) {
      return el;
    }

    return {
      ...el,
      content: undefined,
    };
  });
  newElements.forEach((el) => {
    elementsMap[el.id] = el;
  });
  return {
    elements: newElements,
    elementsMap,
  };
};

export const doActivateFillableTool = (transport, state, action) => {
  const { tool } = action;
  const { sendOperationsCount, ghostElement } = reCreateFillableGhostElement(tool, state);
  return {
    activeTool: tool,
    ghostElement,
    sendOperationsCount,
  };
};

const {
  text, checkmark, signature, image, radio,
  attachment, smartfield, salesforce,
} = TOOL_TYPES;
const {
  none,
  text: { number, dropdown, date, formula },
  signature: { initials },
  checkmark: { x },
} = TOOL_SUB_TYPES;

export const fConstructorTypesToElementTypes = {
  text: {
    type: text,
    subType: none,
  },
  number: {
    type: text,
    subType: number,
  },
  dropdown: {
    type: text,
    subType: dropdown,
  },
  checkmark: {
    type: checkmark,
    subType: x,
  },
  signature: {
    type: signature,
    subType: none,
  },
  initials: {
    type: signature,
    subType: initials,
  },
  date: {
    type: text,
    subType: date,
  },
  image: {
    type: image,
    subType: none,
  },
  radio: {
    type: radio,
    subType: none,
  },
  attachment: {
    type: attachment,
    subType: none,
  },
  formula: {
    type: text,
    subType: formula,
  },
  smartfield: {
    type: smartfield,
    subType: none,
  },
  salesforce: {
    type: salesforce,
    subType: none,
  },
};

const getElemTypeFromFCType = (fConstructorType, state) => {
  if (fConstructorType === TOOL_TYPES.checkmark) {
    return {
      type: TOOL_TYPES.checkmark,
      subType: state.defaultFCCheckmarkSubtype,
    };
  }

  return fConstructorTypesToElementTypes[fConstructorType];
};

const getNewElementJSF = (newElementTemplate, state) => {
  return applyNextDbNameByType(newElementTemplate, state);
};

// we shouldn't call applyNextDbNameByType() for imported fields in snfiller editor
const getNewElementSNF = (newElementTemplate, state, opts = {}) => {
  const isImportingElement = get(opts, 'isImporting');

  return isImportingElement
    ? newElementTemplate
    : applyNextDbNameByType(newElementTemplate, state);
};

const getNewElement = isSignNow()
  ? getNewElementSNF
  : getNewElementJSF;

export const doAddFillableElement = (transport, state, action) => {
  const { element, opts } = action;
  const newElementTemplate = {
    ...element,
    ...getElemTypeFromFCType(element.subType, state),
    element: flatIdToId(element.id),
    enabled: true,
  };

  const newElement = getNewElement(newElementTemplate, state, opts);

  // send to ws
  sendFillableElement(transport, {
    id: newElement.element, element: newElement, type: fillableActionsTypes.add,
  });

  const {
    elements,
    elementsMap,
  } = addFillableElementAndSortElements(newElement, state.elements, state.pages);

  const id = flatIdToId(action.element.id);

  const updatedElement = alignIfRequiresContentPos({
    ...state.ghostElement,
    ...newElement,
    element: id,
  });

  sendToolElement(transport, id, updatedElement);

  const { dontUpdateDefaults } = action.opts;

  const { ghostElement, defaults, sendOperationsCount } = applyToDefaultsAndUpdateGhost(
    transport,
    updatedElement,
    state,
    dontUpdateDefaults,
    true,
  );

  if (ghostElement) {
    return {
      defaults,
      ghostElement,
      elements,
      elementsMap,
      sendOperationsCount,
    };
  }

  const {
    ghostElement: recreatedGhostElement,
    sendOperationsCount: recreatedSendOperationsCount,
  } = reCreateFillableGhostElement(state.activeTool, state);

  return {
    defaults,
    ghostElement: recreatedGhostElement,
    elements,
    elementsMap,
    sendOperationsCount: recreatedSendOperationsCount,
  };
};

// same logic as in doAddFillableElement but doesn't generate any ids
export const doAddFillableElementAsIs = (transport, state, action) => {
  const { element } = action;

  // send to ws
  sendFillableElement(transport, {
    id: element.element, element, type: fillableActionsTypes.add,
  });

  const {
    ghostElement,
    sendOperationsCount,
  } = reCreateFillableGhostElement(state.activeTool, state);

  const {
    elements,
    elementsMap,
  } = addFillableElementAndSortElements(element, state.elements, state.pages);

  return {
    ghostElement,
    elements,
    elementsMap,
    sendOperationsCount,
  };
};

export const doUpdateFillableElement = (transport, state, action) => {
  const { id, template, subType } = action;

  if (state.ghostElement && action.id === state.ghostElement.id) {
    return {
      ghostElement: {
        ...state.ghostElement,
        template: {
          ...state.ghostElement.template,
          ...template,
        },
      },
    };
  }

  const { elementsMap } = state;
  const elementToUpdate = elementsMap[id];

  if (elementToUpdate === undefined) {
    return {};
  }

  const visiblePageIds = state.pages.filter((page) => {
    return page.visible;
  }).map((page) => {
    return page.source;
  });

  const fillableElements = state.elements.filter((element) => {
    return isFillableElement(element) && visiblePageIds.includes(element.pageId);
  });

  const updatedContent = getUpdatedContentOnTemplateUpdating({
    template,
    subType,
    content: elementToUpdate.content,
    type: elementToUpdate.type,
    fillableElements,
  });

  const newElementState = {
    ...elementToUpdate,

    ...template.readonly || Array.isArray(template.allowEditing)
      ? { enabled: false }
      : { enabled: true },

    subType: !subType
      ? elementToUpdate.subType
      : subType,

    template: {
      ...elementToUpdate.template,
      ...template,
    },
    ...(!isEmpty(updatedContent) && { content: updatedContent }),
  };

  const stateAfterUpdateElement = {
    ...state,
    sendOperationsCount: getNextLocalId(state.sendOperationsCount),
  };

  sendFillableElement(transport, {
    element: newElementState,
    id: createId(stateAfterUpdateElement.sendOperationsCount),
    type: fillableActionsTypes.edit,
  });

  const elementsWithinUpdatedElement = updateElement(newElementState, stateAfterUpdateElement);

  const sendUpdatedElementGroup = ({ updatedElement }) => {
    stateAfterUpdateElement.sendOperationsCount.local += 1;

    sendFillableElement(transport, {
      element: updatedElement,
      id: createId(stateAfterUpdateElement.sendOperationsCount),
      type: fillableActionsTypes.edit,
    });
  };

  const newElementsState =
    getElementsToUpdateAccordingToGroup(
      newElementState,
      state,
      { sendUpdatedElementGroup },
    ).reduce(
      (elsState, el) => {
        return updateElement(el, elsState);
      },
      elementsWithinUpdatedElement,
    );

  const { defaults, elementDiff } =
    getNewDefaults(stateAfterUpdateElement.defaults, newElementState);

  const stateAfterApplyDiff = {
    ...stateAfterUpdateElement,
    ...newElementsState,
    ...(elementDiff && {
      sendOperationsCount: getNextLocalId(stateAfterUpdateElement.sendOperationsCount),
    }),
  };

  const { elements, fillableGroups } = createGroupsUsingElements(stateAfterApplyDiff.elements);
  const newGhostElement = updateGhostAfterUpdateFillableElement(state, action);

  return {
    ...stateAfterApplyDiff,
    ...newGhostElement && {
      ghostElement: newGhostElement,
    },
    defaults,
    elements,
    fillableGroups,
  };
};

export function doUpdateFillableElements(transport, state, action) {
  const { elements } = action;
  return elements.reduce((curState, element) => {
    return {
      ...curState,
      ...doUpdateFillableElement(transport, curState, element),
    };
  }, state);
}

export const doRemoveFillableElement = (transport, state, action) => {
  const elementToRemove = state.elementsMap[action.id];

  if (!elementToRemove) {
    // eslint-disable-next-line no-console
    console.warn(`elementToRemove is ${elementToRemove}. Id ${action.id}`);
    return state;
  }

  const removeElement = {
    ...elementToRemove,
    content: {
      ...elementToRemove.content,
      visible: false,
    },
  };

  const finalSendOperationsCount = getNextLocalId(state.sendOperationsCount);

  // send to ws
  sendFillableElement(transport, {
    id: createId(finalSendOperationsCount),
    element: removeElement,
    type: fillableActionsTypes.remove,
  });

  const { elements, elementsMap } = deleteElement(elementToRemove, state);

  return {
    elements,
    elementsMap,
    sendOperationsCount: finalSendOperationsCount,
  };
};

export const doDisableNonFillableElement = (transport, state) => {
  const { elements } = state;
  const newElements = elements.map((el) => {
    if (isFillableElement(el)) {
      return el;
    }
    return { ...el, enabled: false };
  });

  return {
    elements: newElements,
    elementsMap: createMapFromElements(newElements),
  };
};

const getNewPageByItemId = (id, pages) => {
  return Number(
    Object.entries(pages).map(
      (elems) => {
        return findIndex(elems[1], (i) => {
          return i === id;
        }) !== -1 && elems[0];
      },
    ).filter((el) => {
      return el;
    })[0],
  );
};

export const patchElementsOrder = ({ elements, order }) => {
  return elements.map((elem) => {
    if (order[elem.pageId] && isFillableElement(elem)) {
      const orderPosition = order[elem.pageId].findIndex(
        (id) => {
          return id === elem.id;
        },
      );

      return {
        ...elem,
        template: {
          ...elem.template,
          ...(orderPosition !== -1 && { order: orderPosition }),
        },
      };
    }

    return elem;
  });
};

export const doUpdateOrderFillableElements = (transport, state, action) => {
  const { elements, elementsMap } = state;
  const { id, order, hasBeenMoved = false } = action;

  let sortedPageElementsHasBeenAdded = false;
  // order структура вида: { pageId: [el1, el2...], ... }
  // номер страницы на которую мы переносим элемент
  const pageId = getNewPageByItemId(id, order);

  sendOrderFillableElements(transport, state, order);

  // если мы перенесли элемент с одной страницы на другую
  if (hasBeenMoved) {
    const newElements = elements.reduce((accum, elem) => {
      if (!isFillableElement(elem)) {
        return [...accum, elem];
      }

      if (!sortedPageElementsHasBeenAdded && elem.pageId === pageId) {
        sortedPageElementsHasBeenAdded = true;
        return [...accum, ...order[pageId].map((elId) => {
          const currentElement = elementsMap[elId];

          if (elId === id) {
            return {
              ...currentElement,
              pageId,
            };
          }

          return { ...currentElement };
        })];
      }

      if (elem.pageId === pageId || elem.id === id) {
        // if we have empty page and we're moving element to this page -
        // need to change pageId for this element
        if (order[pageId].length === 1 && order[pageId][0] === id) {
          return [
            ...accum,
            {
              ...elem,
              pageId,
            },
          ];
        }

        return accum;
      }

      return [...accum, elem];
    }, []);

    const newElementsWithCorrectOrder = patchElementsOrder({
      elements: newElements,
      order,
    });

    return {
      elements: newElementsWithCorrectOrder,
      elementsMap: createMapFromElements(newElementsWithCorrectOrder),
    };
  }

  const newElements = elements.reduce((accum, elem) => {
    if (!isFillableElement(elem)) {
      return [...accum, elem];
    }
    if (!sortedPageElementsHasBeenAdded && elem.pageId === pageId) {
      sortedPageElementsHasBeenAdded = true;
      return [...accum, ...order[pageId].map((elId) => {
        return ({ ...elementsMap[elId] });
      })];
    }

    if (elem.pageId === pageId) {
      return accum;
    }

    return [...accum, elem];
  }, []);

  const newElementsWithCorrectOrder = patchElementsOrder({
    elements: newElements,
    order,
  });

  return {
    elements: newElementsWithCorrectOrder,
    elementsMap: createMapFromElements(newElementsWithCorrectOrder),
  };
};

export function doAddFillableElements(transport, state, action) {
  let newState = state;
  const { elements, ...opts } = action;
  for (let i = 0; i < elements.length; i++) {
    const sendOperationsCount = getNextLocalId(newState.sendOperationsCount);
    newState = { ...newState, sendOperationsCount };

    const elementAction = {
      element: { ...elements[i] },
      ...opts,
    };
    const partOfNewState = doAddFillableElement(transport, newState, elementAction);
    newState = { ...newState, ...partOfNewState };
  }

  return createGroupsUsingElements(newState.elements, newState);
}
