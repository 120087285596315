/* eslint-disable jsx-a11y/no-redundant-roles */
import React from 'react';

const SettingsBoxSeparator = () => {
  return (
    <hr
      className="settings-box__separator"
      role="separator"
    />
  );
};


export default SettingsBoxSeparator;
