import * as jsfConditionsDI from '@pdffiller/jsf-conditions/di';

// jsf-conditions dynamic dependency injection
import { elemSubTypes, elemFCTypes } from './helpers/elemTypes';
import {
  isVisibleElement,
  isDropdownElement,
  isElementFromDeletedPage,
  isRadioElement,
  isFillableElement,
  isElementWithContent,
} from './store/helpers/functions';
import * as jsfcoreSelectors from './store/selectors';
import { cancellableOpts } from './store/modules/undoRedo';
import updateFillableElementThunk from './store/thunks/updateFillableElement';
import updateFillableElementWithPreprocessing from './store/thunks/updateFillableElementWithPreprocessing';
import FormattedMessage from './jsf-localization/IntlProvider/FormattedMessage';
import FocusControlledSelect from './components/Select';
import { nodeIsElement } from './components/Ghost/ghostUtils';
import elementsSelectors from './store/selectors/elementsSelectors';
import { blurNativeActiveElement } from './helpers/utils';
import {
  types as jsfcoreModalsTypes,
  actions as jsfcoreModalsActions,
  reducer as jsfcoreModalsReducer,
} from './jsf-modals';
import { getDeletedPages } from './store/selectors/wsPagesSelectors';

jsfConditionsDI.injectDependencies({
  jsfcoreSelectors,
  cancellableOpts,
  elemSubTypes,
  elemFCTypes,
  isDropdownElement,
  FormattedMessage,
  Select: FocusControlledSelect,
  updateFillableElementThunk,
  updateFillableElementWithPreprocessing,
  jsfcoreModalsActions,
  jsfcoreModalsTypes,
  jsfcoreModalsReducer,
  nodeIsElement,
  isRadioElement,
  isElementFromDeletedPage,
  getDeletedPages,
  elementsSelectors,
  isFillableElement,
  isElementWithContent,
  isVisibleElement,
  blurNativeActiveElement,
});
