import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

export const panelHeaderSizes = {
  small: 'small',
  medium: 'medium',
  null: null,
};

export const panelHeaderThemes = {
  default: 'default',
  lightgray: 'lightgray',
  null: null,
};

export const panelHeaderTypes = {
  collapsible: 'collapsible',
  null: null,
};

export const panelHeaderOffsets = {
  medium: 'medium',
  mediumHorizontal: 'medium-horizontal',
  null: null,
};

const PanelHeader = ({
  attributes,
  children,
  isActive,
  offset,
  onClick,
  size,
  theme,
  type,
}) => {
  return (
    <div
      className={classnames('panel-header', {
        'is-active': isActive,
        [`panel-header--offset--${offset}`]: offset,
        [`panel-header--size--${size}`]: size,
        [`panel-header--theme--${theme}`]: theme,
        [`panel-header--type--${type}`]: type,
      })}
      onClick={onClick}
      {...attributes}
    >
      {children}
    </div>
  );
};

PanelHeader.propTypes = {
  attributes: PropTypes.shape({}),
  children: PropTypes.node.isRequired,
  isActive: PropTypes.bool,
  offset: PropTypes.string,
  onClick: PropTypes.func,
  size: PropTypes.string,
  theme: PropTypes.string,
  type: PropTypes.string,
};

PanelHeader.defaultProps = {
  attributes: {},
  isActive: false,
  offset: panelHeaderOffsets.null,
  size: panelHeaderSizes.null,
  theme: panelHeaderThemes.null,
  type: panelHeaderTypes.null,
  onClick: null,
};

export default PanelHeader;
