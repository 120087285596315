import React from 'react';
import PropTypes from 'prop-types';
import useTemporaryDisableBlur from './useTemporaryDisableBlur';

function focusControllerSortableDecorator(Sortable) {
  function CancelBlurForSortable({ options, ...props }) {
    const { onStart, onEnd } = options;
    const [
      onStartWithDisableBlur,
      onEndWithDisableBlur,
    ] = useTemporaryDisableBlur(onStart, onEnd);

    return (
      <Sortable
        {...props}
        options={{
          ...options,
          onStart: onStartWithDisableBlur,
          onEnd: onEndWithDisableBlur,
        }}
      />
    );
  }

  CancelBlurForSortable.propTypes = {
    options: PropTypes.shape({
      onStart: PropTypes.func.isRequired,
      onEnd: PropTypes.func.isRequired,
    }).isRequired,
  };

  return CancelBlurForSortable;
}

export default focusControllerSortableDecorator;
