import React from 'react';
import PropTypes from 'prop-types';

const CustomSettingsItem = ({ children }) => {
  return (
    <div className="custom-settings__item">
      {children}
    </div>
  );
};

CustomSettingsItem.propTypes = {
  children: PropTypes.node.isRequired,
};

export default CustomSettingsItem;
