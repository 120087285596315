import { sessionsManager } from '@pdffiller/jsf-loader/JSFillerStores';
import { getQSParams } from '@pdffiller/jsf-loader/auth';

const store = {};
let counter = 0;

if (__CLIENT__) {
  window.JSFillerStores = { ...window.JSFillerStores, pdfPagesStore: store };
}

const getStore = () => {
  const sessionId = sessionsManager.getCurrentSessionId();

  if (!store[sessionId]) {
    store[sessionId] = {};
  }

  return store[sessionId];
};

const deletePdfPagesBySessionId = (id) => {
  const { embedded } = getQSParams();

  // TODO: избавиться от этой логике в JSF-8041
  // после нажатия на Search в FindAnotherForm
  // посылается destroy, на который уничтожается сессия
  // но приложение работает еще какое-то время, и ему нужны pdfPages и pdfDocuments
  if (embedded) {
    delete store[id];
  }
};

export default {
  getPdfPage: (id) => {
    return getStore()[id];
  },

  putPdfPage: (pdfPage) => {
    counter += 1;
    getStore()[counter] = pdfPage;
    return counter;
  },

  clear: () => {
    const str = getStore();

    Object.keys(store)
      .forEach((id) => {
        delete str[id];
      });
  },

  getAllPdfPage: () => {
    return getStore();
  },

};

sessionsManager.onSessionRemoved(deletePdfPagesBySessionId);
