import PropTypes from 'prop-types';
import React, { Component } from 'react';
import cls from 'classnames';

const nullFunc = () => {};

export const boxIconTypes = {
  thin: 'thin',
  wide: 'wide',
  parentMedium: 'parent-medium',
  null: null,
};

export const boxIconSizes = {
  xsmall: 'xsmall',
  xsmallVertical: 'xsmall-vertical',
  mediumRectangle: 'medium-rectangle',
  large: 'large',
  null: null,
};

export const controlSizes = {
  parent: 'parent',
  medium: 'medium',
  small: 'small',
  xxsmall: 'xxsmall',
  xsmallStretched: 'xsmall-stretched',
  mediumStretched: 'medium-stretched',
  parentHeight: 'parent-height',
  smallStretched: 'small-stretched',
  touch: 'touch',
  touchExtraLargeStretched: 'touch-extra-large-stretched',
  extraLargeRectangular: 'extra-large-rectangular',
  extraSmallRectangular: 'extra-small-rectangular',
  extraSmall: 'extra-small',
  smallRectangular: 'small-rectangular',
  large: 'large',
  largeStretched: 'large-stretched',
  largeRectangular: 'large-rectangular',
  null: null,
};

export const controlOffsets = {
  large: 'large',
  medium: 'medium',
  small: 'small',
  extraLarge: 'extra-large',
  extraSmall: 'extra-small',
  horizontalLarge: 'horizontal-large',
  verticalSmall: 'vertical-small',
  mediumReversed: 'medium-reversed',
  null: null,
};

export const controlThemes = {
  simpleDashed: 'simple-dashed',
  transparentGray: 'transparent-gray',
  transparentPrimary: 'transparent-primary',
  transparentPrimaryActive: 'transparent-primary-active',
  secondary: 'secondary',
  primary: 'primary',
  primaryBordered: 'primary-bordered',
  primaryHighlighted: 'primary-highlighted',
  primaryUnderlined: 'primary-underlined',
  multicolored: 'multicolored',
  gray: 'gray',
  lite: 'lite',
  light: 'light',
  lightgray: 'lightgray',
  spark: 'spark',
  lackluster: 'lackluster',
  highlighted: 'highlighted',
  highlightedBlue: 'highlighted-blue',
  silver: 'silver',
  darkgray: 'darkgray',
  transparent: 'transparent',
  solidPrimary: 'solid-primary',
  transparentBlue: 'transparent-blue',
  transparentBordered: 'transparent-bordered',
  transparentGreen: 'transparent-green',
  toggleTransparentPrimary: 'toggle-transparent-primary',
  toggleSecondary: 'toggle-secondary',
  toggle: 'toggle',
  spatial: 'spatial',
  solidSuccess: 'solid-success',
  solidDanger: 'solid-danger',
  solidBlur: 'solid-blur',
  success: 'success',
  flatWhite: 'flat-white',
  datepickerPrimary: 'datepicker-primary',
  flatLightgray: 'flat-lightgray',
  solidBlue: 'solid-blue',
  secondaryTransparent: 'secondary-transparent',
  silverTransparent: 'silver-transparent',
  null: null,
};

export const controlBehaviors = {
  overflowText: 'overflow-text',
  wrapText: 'wrap-text',
  rowRecursive: 'row-recursive',
  stretchedContent: 'stretched-content',
  column: 'column',
  rotatedText: 'rotated-text',
  null: null,
};

export const controlAligns = {
  center: 'center',
  null: null,
};

export const controlVerticalAligns = {
  top: 'top',
  baseline: 'baseline',
  null: null,
};

export const controlJustifies = {
  center: 'center',
  flexStart: 'flex-start',
  null: null,
};

export const controlRoles = {
  switch: 'switch',
  tab: 'tab',
  button: 'button',
  null: null,
};

export const controlTags = {
  button: 'button',
  label: 'label',
  div: 'div',
};

export const controlTypes = {
  dropdown: 'dropdown',
  null: null,
};

export const controlDisplays = {
  block: 'block',
  null: null,
};

export const dataPointerTypes = {
  arrow: 'arrow',
  arrowRight: 'arrow-right',
  null: null,
};

export const getBoxIcon = ({ boxColor, type, size }) => {
  return (
    <span
      className={cls('color-example', {
        [`color-example--type--${type}`]: type,
        [`color-example--size--${size}`]: size,
      })}
      style={{ backgroundColor: `#${boxColor}` }}
    />
  );
};

getBoxIcon.propTypes = {
  boxColor: PropTypes.string.isRequired,
  type: PropTypes.oneOf(
    Object.values(boxIconTypes),
  ),
  size: PropTypes.oneOf(
    Object.values(boxIconSizes),
  ),
};

getBoxIcon.defaultProps = {
  type: boxIconTypes.null,
  size: boxIconSizes.null,
};

export default class Control extends Component {
  static propTypes = {
    attributes: PropTypes.shape({}),
    input: PropTypes.oneOfType([
      PropTypes.element,
      PropTypes.arrayOf(PropTypes.oneOfType([
        PropTypes.element,
        PropTypes.bool,
      ])),
    ]),
    icon: PropTypes.element,
    boxIcon: PropTypes.element,
    children: PropTypes.element,
    size: PropTypes.oneOf(
      Object.values(controlSizes),
    ),
    offset: PropTypes.oneOf(
      Object.values(controlOffsets),
    ),
    theme: PropTypes.oneOf(
      Object.values(controlThemes),
    ),
    behavior: PropTypes.oneOf(
      Object.values(controlBehaviors),
    ),
    justify: PropTypes.oneOf(
      Object.values(controlJustifies),
    ),
    role: PropTypes.oneOf(
      Object.values(controlRoles),
    ),
    tag: PropTypes.oneOf(
      Object.values(controlTags),
    ),
    type: PropTypes.oneOf(
      Object.values(controlTypes),
    ),
    dataPointer: PropTypes.oneOf(
      Object.values(dataPointerTypes),
    ),
    align: PropTypes.oneOf(
      Object.values(controlAligns),
    ),
    verticalAlign: PropTypes.oneOf(
      Object.values(controlVerticalAligns),
    ),
    className: PropTypes.string,
    isBusy: PropTypes.bool,
    dataRedesign: PropTypes.bool,
    disabled: PropTypes.bool,
    ariaLabel: PropTypes.string,
    inheritRadius: PropTypes.bool,
    title: PropTypes.string,
    tabIndex: PropTypes.number,

    onMouseDown: PropTypes.func,
    onMouseUp: PropTypes.func,
    onClick: PropTypes.func,
    onMouseEnter: PropTypes.func,
    onMouseLeave: PropTypes.func,
    onTouchStart: PropTypes.func,
    onFocus: PropTypes.func,
    isChecked: PropTypes.bool,
    isSelected: PropTypes.bool,
    isPressed: PropTypes.bool,
    isHighlighted: PropTypes.bool,
    storeRef: PropTypes.oneOfType([
      PropTypes.func,
      PropTypes.shape({}),
    ]),
    roundedCorners: PropTypes.bool,
    titleId: PropTypes.string,
    display: PropTypes.oneOf(
      Object.values(controlDisplays),
    ),
  };

  static contextTypes = {
    formatMessage: PropTypes.func.isRequired,
  };

  static defaultProps = {
    attributes: {},
    align: controlAligns.null,
    verticalAlign: controlVerticalAligns.null,
    behavior: controlBehaviors.null,
    size: controlSizes.parent,
    offset: controlOffsets.null,
    justify: controlJustifies.null,
    theme: controlThemes.transparentGray,
    role: controlRoles.null,
    tag: controlTags.button,
    type: controlTypes.null,
    input: null,
    icon: null,
    boxIcon: null,
    className: null,
    disabled: false,
    ariaLabel: null,
    inheritRadius: false,
    dataRedesign: false,
    isBusy: null,
    isChecked: null,
    isSelected: null,
    isPressed: null,
    isHighlighted: null,
    title: null,
    dataPointer: null,
    tabIndex: null,

    onMouseDown: nullFunc,
    onMouseUp: nullFunc,
    onClick: nullFunc,
    onMouseEnter: nullFunc,
    onMouseLeave: nullFunc,
    onTouchStart: nullFunc,
    onFocus: nullFunc,
    storeRef: nullFunc,
    children: null,
    roundedCorners: false,
    titleId: null,
    display: controlDisplays.null,
  };

  render() {
    const {
      attributes,
      icon,
      boxIcon,
      children,
      input,
      tag,
      size,
      offset,
      theme,
      inheritRadius,
      justify,
      behavior,
      align,
      verticalAlign,
      className,
      role,
      dataRedesign,
      isBusy,
      isChecked,
      isSelected,
      isPressed,
      title: titleText,
      disabled,
      ariaLabel,
      type,
      dataPointer,
      isHighlighted,
      roundedCorners,
      tabIndex,
      titleId,
      display,
    } = this.props;
    const Tag = tag;

    const title = titleId
      ? this.context.formatMessage(children, titleId)
      : titleText;

    return (
      <Tag
        className={
          cls('control', `control--size--${size}`, className, {
            [`control--offset--${offset}`]: offset,
            [`control--theme--${theme}`]: theme,
            [`control--behavior--${behavior}`]: behavior,
            [`control--justify--${justify}`]: justify,
            [`control--type--${type}`]: type,
            [`control--align--${align}`]: align,
            [`control--vertical-align--${verticalAlign}`]: verticalAlign,
            [`control--display--${display}`]: display,
            '_inherit-radius': inheritRadius,
            '_rounded-corners': roundedCorners,
            'is-checked': isChecked,
            'is-disabled': disabled,
            'is-selected': isSelected,
            'is-pressed': isPressed,
            'is-highlighted': isHighlighted,
          })
        }
        role={role}
        aria-checked={isChecked}
        aria-label={ariaLabel}
        aria-selected={isSelected}
        aria-busy={isBusy}
        aria-pressed={isPressed}
        data-pointer={dataPointer}
        data-redesign={dataRedesign}
        type="button"
        disabled={disabled}
        title={title}
        tabIndex={tabIndex}
        onMouseDown={this.props.onMouseDown}
        onMouseUp={this.props.onMouseUp}
        onClick={this.props.onClick}
        onMouseEnter={this.props.onMouseEnter}
        onMouseLeave={this.props.onMouseLeave}
        onTouchStart={this.props.onTouchStart}
        onFocus={this.props.onFocus}

        ref={this.props.storeRef}
        {...attributes}
      >
        <span className="control__body">
          {input}
          {icon &&
            <span className="control__icon">
              {icon}
            </span>
          }
          {boxIcon &&
            <div className="control__box">
              {boxIcon}
            </div>
          }
          {children &&
            <span className="control__text">
              {children}
            </span>
          }
        </span>
      </Tag>
    );
  }
}
