import { takeEvery, select, put, all, call } from 'redux-saga/effects';
import get from 'lodash/get';
import has from 'lodash/has';
import find from 'lodash/find';
import isEmpty from 'lodash/isEmpty';
import { actionTypes } from 'ws-editor-lib/actions';
import { utils } from 'ws-editor-lib';
import { errors as errorsActions } from '../actions';
import { MAX_LENGTH_FOR_FORMULA_FIELD } from '../const';
import {
  getElementsWithWValidationToCurrentPage,
  getConstructorErrors,
  defaultValidatorState,
} from '../selectors/errorsSelectors';
import { getMergedValidators, needNumberLengthValidation } from '../selectors/validatorsSelectors';
import * as selectors from '../../store/selectors';
import { SET_ACTIVE_PAGE } from '../../store/modules/navigationActionTypes';
import validateElement from '../utils/validateElement';
import { format, unformat } from '../utils/formatUnformat';
import { CONSTRUCTOR_HIDE, CONSTRUCTOR_SHOW } from '../../store/modules/viewport';
import { isDate } from '../../store/helpers/functions';
import { getNumberLengthWithoutDotsOrCommas } from '../../helpers/numbers';

const getIsValidChanged = (error = defaultValidatorState, isValid) => {
  return error.isValid !== isValid;
};

const getIsValid = (value, validator) => {
  const isValidatorWithLength = needNumberLengthValidation(validator);
  return (
    validateElement({ value, validator }) &&
    !(
      isValidatorWithLength &&
      getNumberLengthWithoutDotsOrCommas(value) > MAX_LENGTH_FOR_FORMULA_FIELD
    )
  );
};

// export for tests only
export function* validateElements() {
  const elements = yield select(getElementsWithWValidationToCurrentPage);
  const constructorErrors = yield select(getConstructorErrors);

  const errors = elements.reduce((acc, element) => {
    // element здесь вот такого шейпа
    // { value - значение, validator - его валидатор, id - его id }

    // TODO: Вынести отдельно логику simpleErrors и constructorErrors
    // после этого убрать анформат отсюда
    // https://pdffiller.atlassian.net/browse/JSF-5358 (п.2)

    const elementContent = get(element, 'value', '');
    const validator = get(element, 'validator', '');

    // elementFakeForOldFormat - объект чтоб взять из него для getOldFormat для анформата
    const elementFakeForOldFormat = {
      template: {
        validator,
      },
    };
    const formattedValue = format(
      unformat(elementContent, elementFakeForOldFormat),
      elementFakeForOldFormat,
      elementContent,
    );

    const isValid = getIsValid(formattedValue, validator);
    const constructorError = constructorErrors[element.id];

    if (!getIsValidChanged(constructorError, isValid)) {
      return acc;
    }

    return {
      ...acc,
      [element.id]: {
        isValid,
      },
    };
  }, {});

  if (isEmpty(errors)) {
    return;
  }

  yield put(errorsActions.setConstructorErrors(errors));
}

function* propagateValidationErrorsToFlatGroup(element, isValid) {
  if (element.isMemberOfFlatGroup) {
    const flatGroups = yield select(selectors.base.getFlatGroups);
    const flatGroupIds = Object.values(flatGroups[utils.createFlatName(element)]).filter((id) => {
      return element.id !== id;
    });
    for (let i = 0; i < flatGroupIds.length; i += 1) {
      const id = flatGroupIds[i];
      yield put(errorsActions.setConstructorError({ id, isValid }));
    }
  }
}

export function* validateActiveElement(action) {
  const pathToCheckedValue = {
    [actionTypes.UPDATE_ELEMENT]: 'content.text',
    [actionTypes.UPDATE_FILLABLE_ELEMENT]: 'template.initial',
  };
  const isCorrectValueToCheck = has(action, pathToCheckedValue[action.type]) ||
    has(action, 'template.validatorId');

  if (!isCorrectValueToCheck) {
    return;
  }

  const activeElement = yield select(selectors.elements.getActiveElement);
  const validatorId = get(activeElement, 'template.validatorId');

  if (!validatorId) {
    if (validatorId === '') {
      yield put(errorsActions.setConstructorError({ id: activeElement.id, isValid: true }));
      yield* propagateValidationErrorsToFlatGroup(activeElement, true);
    }

    return;
  }

  const validators = yield select(getMergedValidators);
  const errors = yield select(getConstructorErrors);

  const elementContent = get(activeElement, pathToCheckedValue[action.type], '');
  const formattedValue = format(
    unformat(elementContent, activeElement),
    activeElement,
    elementContent,
  );

  const validator = find(validators, { id: validatorId });

  // https://pdffiller.atlassian.net/browse/JSF-5140
  // removed validation for Date field with No Date
  // for selected No Date option
  // initial stores date format
  // and it equal validator.momentFormat value
  if (
    validator &&
    !(
      isDate(activeElement) &&
      elementContent === validator.momentFormat
    )
  ) {
    const isValid = getIsValid(formattedValue, validator);
    const error = errors[activeElement.id];

    if (getIsValidChanged(error, isValid)) {
      yield put(
        errorsActions.setConstructorError({ id: activeElement.id, isValid }),
      );
      yield* propagateValidationErrorsToFlatGroup(activeElement, isValid);
    }
  }
}

function* validateActiveElementAction(action) {
  const isConstructorMode = yield select(selectors.mode.isConstructor);
  const isUpdateFillableInConstructorMode = isConstructorMode &&
    action.type === actionTypes.UPDATE_FILLABLE_ELEMENT;
  const isUpdateInEditorMode = !isConstructorMode &&
    action.type === actionTypes.UPDATE_ELEMENT;

  if (isUpdateFillableInConstructorMode || isUpdateInEditorMode) {
    yield call(validateActiveElement, action);
  }
}

function* validateElementsSaga() {
  yield takeEvery([
    SET_ACTIVE_PAGE,
    actionTypes.ON_OPERATIONS_TOOLS_ELEMENTS,
    CONSTRUCTOR_HIDE,
    CONSTRUCTOR_SHOW,
  ], validateElements);
}

function* validateActiveElementSaga() {
  yield takeEvery(
    [
      actionTypes.UPDATE_ELEMENT,
      actionTypes.UPDATE_FILLABLE_ELEMENT,
    ],
    validateActiveElementAction,
  );
}

export default function* validateElementWatcher() {
  yield all([
    validateElementsSaga(),
    validateActiveElementSaga(),
  ]);
}
