import uniqWith from 'lodash/uniqWith';
import { pdfTextItemMargin } from '../../../../helpers/const';
import {
  modulus,
  toRadians,
  roundTo2,
} from '../../../../helpers/utils';
import getItemRotate from './getItemRotate';

export const getFixedGeometryItems = (items) => {
  return items.map((item) => {
    const itemMargin = item.height * pdfTextItemMargin;
    return {
      ...item,
      x: item.x - (itemMargin / 2),
      width: item.width + itemMargin,
      height: item.height + itemMargin,
    };
  });
};

/*
 * Некоторые PDF-ки прилетают поломанные: один и тот же текстовый чанк там
 * встречается несколько раз.
 * В этом блоке мы отфильтровываем те элементы, которые повторяются
 */
export const getWithoutDuplicates = (items) => {
  return uniqWith(items, (item, another) => {
    return (
      item.str === another.str &&
      item.height === another.height &&
      item.transform[4] === another.transform[4] &&
      item.transform[5] === another.transform[5]
    );
  });
};

/*
 * PDFJS возвращает довольно неудобные для работы данные:
 * fontFamily задается в отдельном объекте стилей,
 * transform вместо x, y
 * Здесь мы преобразуем pdfItem'ы в объекты, с которыми будем работать
 */
export const getJsfStyleItems = (pdfItems, styles, pageHeight) => {
  return pdfItems.map((item) => {
    return {
      text: item.str,
      width: item.width,
      height: item.height,
      x: item.transform[4],
      y: pageHeight - item.transform[5],
      fontFamily: styles[item.fontName].fontFamily,
      pdfFontName: item.fontName,
      rotate: getItemRotate(item.transform),
    };
  });
};

/**
 * PDFJS возвращает довольно неудобные для работы данные:
 * сам текст может быть разделен на несколько чанкрв
 * внутри одной строки (и даже одного слова)
 * Поэтому такие "слипшиеся" чанки мы сохраняем как один
 * (конкатенировав строки и сложив ширину).
 */
export const getSquashedItems = (items) => {
  return items
    .reduce((result, item, index) => {
      if (index === 0) {
        return [item];
      }

      const prev = result[result.length - 1];
      const prevRight = prev.transform[4] + prev.width;
      const thisLeft = item.transform[4];

      const xDiff = Math.abs(prevRight - thisLeft);
      const yDiff = Math.abs(prev.transform[5] - item.transform[5]);
      const heightDiff = Math.abs(prev.height - item.height);
      if (xDiff < 1 && yDiff < 1 && heightDiff < 1 && item.fontName === prev.fontName) {
        return [
          ...result.slice(0, -1),
          {
            ...prev,
            str: prev.str + item.str,
            width: prev.width + item.width,
          },
        ];
      }

      return [...result, item];
    }, []);
};

/*
 * Айтемы с пустым текстом нам для поиска не нужны
 */
export const getItemsWithText = (items) => {
  return items
    .filter((item) => {
      return item.str.match(/^\s*$/) === null;
    });
};

// Здесь мы также делаем поправку на height:
// в PDEF отсчет координат с нижнелевого угла, у нас - из верхнелевого
// Для упрощения подсчетов поправку мы делаем только здесь, хотя по логике
// надо бы в getJsfStyleItems
export const getRotatedItems = (items, pageSetting, pageSize) => {
  const pageRotateRadians = toRadians(pageSetting.rotation);
  return items.map((item) => {
    const rotate = modulus(item.rotate + pageSetting.rotation, 360);
    const rotateRadians = toRadians(rotate);
    const pageRotateCos = roundTo2(Math.cos(pageRotateRadians));
    const pageRotateSin = roundTo2(Math.sin(pageRotateRadians));
    return {
      ...item,
      rotate,
      x: modulus(item.x * pageRotateCos, pageSize.width) +
        modulus(item.y * pageRotateSin * -1, pageSize.height) +
        (item.height * roundTo2(Math.sin(rotateRadians))),
      y: modulus(item.x * pageRotateSin, pageSize.width) +
        modulus(item.y * pageRotateCos, pageSize.height) +
        (item.height * roundTo2(Math.cos(rotateRadians)) * -1),
    };
  });
};
