import * as selectors from '../selectors';
import updateElement from './updateElement';
import setFontSize from './setFontSize';
import { isTextToolBasedElement, getSizeDelta } from '../../helpers/elemTypes';
import { getFontSize, getSize } from '../../decorators/element/elementDecorator';
import { elementMinSize } from '../../helpers/const';
import { areAlmostEqual } from '../../helpers/utils';


export const sizeChange = (more) => {
  return (dispatch, getState) => {
    const state = getState();
    const activeElement = selectors.elements.getActiveElement(state);

    if (activeElement) {
      if (isTextToolBasedElement(activeElement)) {
        const { fontSize } = activeElement.content;
        setFontSize(getFontSize(fontSize, more))(dispatch, getState);
      } else {
        const { id, type, pageId } = activeElement;
        const { width, height, x, y } = activeElement.content;
        const originalSize = selectors.getOriginalSize(state, pageId);
        const maxSize = {
          width: originalSize.width - x,
          height: originalSize.height - y,
        };

        const newSize = getSize(
          { width, height }, more, elementMinSize, maxSize, getSizeDelta(type),
        );

        if (!areAlmostEqual(newSize, { width, height })) {
          updateElement(id, newSize)(dispatch, getState);
        }
      }
    }
  };
};

export const sizeUp = () => {
  return (dispatch, getState) => {
    sizeChange(true)(dispatch, getState);
  };
};

export const sizeDown = () => {
  return (dispatch, getState) => {
    sizeChange(false)(dispatch, getState);
  };
};
