import moment from 'moment';
import { updateDefaultSettings } from 'ws-editor-lib/actions';
import updateElement from './updateElement';
import * as selectors from '../selectors';
import { defaultDateFormat } from '../../helpers/const';
import { getElementProps } from '../helpers/functions';

const toggleStamp = (args = { onComponentDidMount: false, id: null }) => {
  return (dispatch, getState) => {
    const state = getState();
    const { id, dateStamp } = selectors.toolbar.getElementPropsForToolbar(state);
    const useSigDateStamp = selectors.base.getUseSigDateStamp(state);

    if (!args.onComponentDidMount) {
      dispatch(
        updateDefaultSettings('useSigDateStamp', !useSigDateStamp),
      );
    }

    let updateElementId = id;
    let updateDateStamp = dateStamp;

    if (args.id) {
      const element = selectors.getElementById(args.id)(state);
      updateElementId = args.id;
      updateDateStamp = getElementProps(element).dateStamp;
    }

    return dispatch(updateElement(
      updateElementId,
      {
        dateStamp: (!!updateDateStamp && !args.onComponentDidMount)
          ? ''
          : moment(new Date()).format(defaultDateFormat),
      },
    ));
  };
};

export default toggleStamp;
