import { createStore as _createStore } from 'redux';
import createSagaMiddleware from 'redux-saga';
import thunk from 'redux-thunk';
import logger from '@pdffiller/jsf-logger/clientLogger';
import lazyMiddleware from '@pdffiller/jsf-lazyload/store/middleware';

import {
  wsMiddleware,
  combineModalsMiddleware,
  loggerMiddleware,
  localeMiddleware,
  controllerMiddleware,
  createUndoRedoMiddleware,
} from 'jsfcore/store/middleware';
import applyMiddleware from 'jsfcore/store/applyMiddleware';
import createReducer from 'jsfcore/store/modules/reducer';
import { watchFactory } from 'jsfcore/store/helpers/dev';

import { initialStateLocale } from 'jsfcore/store/modules/locale';

import Interface from 'websocket-client/lib/interface';
import { getQSParams } from 'jsfcore/helpers/utils';
import { getWsTransport } from '@pdffiller/jsf-loader/JSFillerStores';

import runSaga from './sagas/run';

const createMiddlewares = (sagaMiddleware, wsTransport) => {
  const undoRedoMiddleware = createUndoRedoMiddleware();

  return [
    thunk,
    undoRedoMiddleware,
    sagaMiddleware,
    ...(
      __CLIENT__ && __ENABLE_SAGAS__ && wsTransport
        ? [wsMiddleware(wsTransport)]
        : []
    ),
    ...(
      __CLIENT__
        ? [
          combineModalsMiddleware,
          lazyMiddleware,
          loggerMiddleware,
          controllerMiddleware,
          localeMiddleware,
        ]
        : []
    ),
  ];
};

function coreCreateStore(data, wsTransport) {
  const sagaMiddleware = createSagaMiddleware({
    onError: logger.sagaErrorHandler,
    logger: logger.sagaLogger,
  });

  const ws = (wsTransport && wsTransport.getWebSocketClient)
    ? wsTransport.getWebSocketClient
    : null;
  const middlewares = createMiddlewares(sagaMiddleware, wsTransport);
  const finalCreateStore = applyMiddleware(...middlewares)(_createStore);
  const initialState = { locale: initialStateLocale, ...data };
  const store = finalCreateStore(createReducer(ws), initialState);

  store.wsTransport = ws;
  store.lazyReducers = {};
  store.lazyMiddlewares = {};
  store.lazyMiddlewaresChain = null;

  if (module.hot) {
    module.hot.accept('jsfcore/store/modules/reducer', () => {
      store.replaceReducer(createReducer(wsTransport, store.lazyReducers));
    });

    module.hot.accept('jsfcore/store/middleware', () => {
      store.replaceMiddlewares(...createMiddlewares(sagaMiddleware, wsTransport));
    });
  }

  runSaga(sagaMiddleware);

  if (__CLIENT__) {
    window.sagaMiddleware = sagaMiddleware;
    window.watch = watchFactory(store);
  }

  return store;
}

class DummyWebSocketClient extends Interface {
  connect = () => {
    return false;
  }

  send = () => {
    return false;
  }
}

export default function createStore(data) {
  const { isOfflineMode } = getQSParams();

  if (isOfflineMode) {
    const dummyWebSocketClient = {
      onWebSocketClientChanged: () => {},
      getWebSocketClient: () => {
        return new DummyWebSocketClient();
      },
    };

    return coreCreateStore(data, dummyWebSocketClient);
  }

  const wsTransport = getWsTransport();
  return coreCreateStore(data, wsTransport);
}
