import { RESET_ALL_REDUCERS } from './events';

const prefix = 'search';

export const SET_SEARCH_MODE_ACTIVE = `${prefix}/SET_SEARCH_MODE_ACTIVE`;
export const SET_SEARCH_TERM = `${prefix}/SET_SEARCH_TERM`;
export const SET_OCCURENCES = `${prefix}/SET_OCCURENCES`;
export const TOGGLE_SEARCH_NEXT = `${prefix}/TOGGLE_SEARCH_NEXT`;
export const TOGGLE_SEARCH_PREV = `${prefix}/TOGGLE_SEARCH_PREV`;
export const SET_SEARCH_AVAILABLE = `${prefix}/SET_SEARCH_AVAILABLE`;
export const SET_CURRENT_HIGHLIGHT_TYPE = `${prefix}/SET_CURRENT_HIGHLIGHT_TYPE`;
export const SET_NEW_HIGHLIGHT_TYPE = `${prefix}/SET_NEW_HIGHLIGHT_TYPE`;
export const SET_OCCURENCE_RECTS = `${prefix}/SET_OCCURENCE_RECTS`;
export const APPEND_OCCURENCE_RECTS = `${prefix}/APPEND_OCCURENCE_RECTS`;

export const initialState = {
  isSearchModeActive: false,
  searchTerm: '',
  occurences: false,
  searchIndex: 0,
  isSearchAvailable: false,
  currentHighlightType: false,
  newHighlightType: false,
  occurenceRects: [],
  isProcessingSearch: false,
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case RESET_ALL_REDUCERS:
      return initialState;

    case SET_SEARCH_MODE_ACTIVE:
      return {
        ...state,
        isSearchModeActive: action.isActive,
      };

    case SET_SEARCH_TERM:
      return {
        ...state,
        searchTerm: action.text,
        occurenceRects: [],
        isProcessingSearch: true,
      };

    case SET_OCCURENCES:
      return {
        ...state,
        occurences: action.occurences,
        searchIndex: action.searchIndex || 0,
        currentHighlightType: false,
        newHighlightType: false,
        occurenceRects: [],
        isProcessingSearch: false,
      };

    case TOGGLE_SEARCH_NEXT: {
      const length = state.occurences.length || 1;
      return {
        ...state,
        searchIndex: (state.searchIndex + 1) % length,
        currentHighlightType: false,
        newHighlightType: false,
        occurenceRects: [],
      };
    }

    case TOGGLE_SEARCH_PREV: {
      const length = state.occurences.length || 1;
      return {
        ...state,
        searchIndex: ((state.searchIndex - 1) + length) % length,
        currentHighlightType: false,
        newHighlightType: false,
        occurenceRects: [],
      };
    }

    case SET_SEARCH_AVAILABLE:
      return {
        ...state,
        isSearchAvailable: true,
      };

    case SET_CURRENT_HIGHLIGHT_TYPE:
      return {
        ...state,
        currentHighlightType: action.highlightType,
      };

    case SET_NEW_HIGHLIGHT_TYPE:
      return {
        ...state,
        newHighlightType: action.highlightType,
      };

    case SET_OCCURENCE_RECTS:
      return {
        ...state,
        occurenceRects: action.rects,
      };

    case APPEND_OCCURENCE_RECTS:
      return {
        ...state,
        occurenceRects: [
          ...state.occurenceRects,
          ...action.rects,
        ],
      };

    default:
      return state;
  }
}

export function setIsSearchModeActive(isActive) {
  return { type: SET_SEARCH_MODE_ACTIVE, isActive };
}

export function setSearchTerm(text) {
  return { type: SET_SEARCH_TERM, text };
}

export function setOccurences(occurences, searchIndex) {
  return { type: SET_OCCURENCES, occurences, searchIndex };
}

export function toggleSearchNext() {
  return { type: TOGGLE_SEARCH_NEXT };
}

export function toggleSearchPrev() {
  return { type: TOGGLE_SEARCH_PREV };
}

export function setSearchAvailable() {
  return { type: SET_SEARCH_AVAILABLE };
}

export function setCurrentHighlightType(highlightType) {
  return { type: SET_CURRENT_HIGHLIGHT_TYPE, highlightType };
}

export function setNewHighlightType(highlightType) {
  return { type: SET_NEW_HIGHLIGHT_TYPE, highlightType };
}

export function setOccurenceRects(rects) {
  return { type: SET_OCCURENCE_RECTS, rects };
}

export function appendOccurenceRects(rects) {
  return { type: APPEND_OCCURENCE_RECTS, rects };
}
