import React, { useRef, useCallback } from 'react';
import getPromise from './getPromise';
import mountUnmountFixForRefs from './mountUnmountFixForRefs';

/**
 * Why we need promises here?
 *
 * Sometimes don't know then ref will be set
 *
 * Example:
 * > addNewElement({ id: 10 }) + setActiveElement(10)
 * We should set caret to element 10 immediately after adding it to DOM
 */
const refsStorageDecorator = WrappedComponent => (props) => {
  const storage = useRef({});
  const promisesStorage = useRef({});

  const storeRef = useCallback(
    mountUnmountFixForRefs(
      storage,
      id => (ref) => {
        storage.current[id] = ref;

        // Resolve promise and remove it
        const promiseObj = promisesStorage.current[id];
        if (promiseObj) {
          clearTimeout(promiseObj.rejectTimeout);
          promiseObj.resolve(ref);
          delete promisesStorage.current[id];
        }
      },
    ),
    [],
  );

  const getRef = useCallback(
    (id) => {
      // Already have ref - return resolved promise with ref
      if (storage.current[id]) return Promise.resolve(storage.current[id]);

      // If promise not exist - create it, and start catch timer
      if (!promisesStorage.current[id]) {
        const { resolve, reject, promise } = getPromise();

        const rejectTimeout = setTimeout(() => {
          reject(`Ref ${id} not set in 300ms`);
          delete promisesStorage.current[id];
        }, 300);

        promisesStorage.current[id] = { resolve, promise, rejectTimeout };
      }

      // Return an existing or just created promise
      return promisesStorage.current[id].promise;
    },
    [],
  );

  return (
    <WrappedComponent
      {...props}
      storeRef={storeRef}
      getRef={getRef}
    />
  );
};

export default refsStorageDecorator;
