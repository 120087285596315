import has from 'lodash/has';
import { takeEvery, select, put, all } from 'redux-saga/effects';
import get from 'lodash/get';
import { updateElement } from 'ws-editor-lib/actions';
import { CONSTRUCTOR_HIDE } from 'jsfcore/store/modules/viewport';
import { cancellableOpts } from 'jsfcore/store/modules/undoRedo';
import findDependentElements from '../helpers/findDependentElements';
import { getDependency, dependenciesMap } from '../di';

export function* getIdsToRemove(element, elements) {
  const jsfcoreSelectors = getDependency(dependenciesMap.jsfcoreSelectors);

  const dependentElements = findDependentElements(
    element.template.name,
    elements,
  );
  const result = element.content
    ? [element.id]
    : [];

  for (let index = 0; index < dependentElements.length; index++) {
    const { id } = dependentElements[index];
    const dependentElement = yield select(jsfcoreSelectors.elements.getElementByIdFactory(id));
    const idsToRemove = yield getIdsToRemove(dependentElement, elements);
    result.push(...idsToRemove);
  }

  return result;
}

export function* conditionsRemoveContentSaga(action) {
  // Кейс для signnow, вызывается из setActiveElementIfNotActive
  if (get(action, 'opts.shouldNotRemoveContent', false)) {
    return;
  }

  const jsfcoreSelectors = getDependency(dependenciesMap.jsfcoreSelectors);
  const removeElements = getDependency(dependenciesMap.removeElements);
  const isVisibleElement = getDependency(dependenciesMap.isVisibleElement);

  const elements = yield select(jsfcoreSelectors.elements.getElements);
  const disabledElementByDepsWithContent = elements.filter((elem) => {
    return (
      elem.isEnabledByDependencies === false &&
      elem.content &&
      elem.template &&
      isVisibleElement(elem)
    );
  });

  if (disabledElementByDepsWithContent.length === 0) {
    return;
  }

  const elementsWithDependency = elements.filter((elem) => {
    return has(elem, 'template.dependency');
  });

  const allIdsToRemove = [];
  for (let index = 0; index < disabledElementByDepsWithContent.length; index++) {
    const element = disabledElementByDepsWithContent[index];
    const idsToRemove = yield getIdsToRemove(element, elementsWithDependency);
    allIdsToRemove.push(...idsToRemove);
  }

  if (allIdsToRemove.length > 0 && !get(action, 'opts.fromUndoRedo', false)) {
    yield put(removeElements(allIdsToRemove, { ...cancellableOpts }));
    const activeElement = yield select(jsfcoreSelectors.elements.getActiveElement);

    if (activeElement && allIdsToRemove.includes(activeElement.id) && activeElement.content) {
      yield put(updateElement(activeElement.id, null, { ...cancellableOpts }));
    }
  }
}


function* watchActionsToRemoveElementContent() {
  const actionTypes = getDependency(dependenciesMap.actionTypes);
  const { REMOVE_ELEMENT, UPDATE_ELEMENT } = actionTypes;
  yield takeEvery(
    [CONSTRUCTOR_HIDE, REMOVE_ELEMENT, UPDATE_ELEMENT],
    conditionsRemoveContentSaga,
  );
}

export default function* conditionsRemoveContentRootSaga() {
  yield all([
    watchActionsToRemoveElementContent(),
  ]);
}
