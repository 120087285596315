import {
  send,
  createFlatId,
  createId,
  getNextLocalId,
} from '../Utils';
import {
  GROUPS,
  TYPES,
  WIZARD_STATES,
} from '../../../../constants';

const updateDefaultWizardState = (transport, state, fillableWizardState) => {
  const { defaults } = state;

  const sendOperationsCount = getNextLocalId(state.sendOperationsCount);
  const id = createFlatId(createId(sendOperationsCount));

  send(transport, id, {
    fillableWizardState,
    group: GROUPS.editor,
    type: TYPES.defaults,
  });

  return {
    defaults: {
      ...defaults,
      fillableWizardState,
    },
    sendOperationsCount,
  };
};

export const turnDefaultWizardFull = (transport, state) => {
  return updateDefaultWizardState(transport, state, WIZARD_STATES.FULL);
};

export const turnDefaultWizardLite = (transport, state) => {
  return updateDefaultWizardState(transport, state, WIZARD_STATES.LITE);
};
