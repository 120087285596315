import * as wsActions from 'ws-editor-lib/actions';
import { utils } from 'ws-editor-lib';
import { conditionsRemoveElementCheck } from '@pdffiller/jsf-conditions/store/actions';
import { formula, getLazy, mocks } from '@pdffiller/jsf-lazyload';
import * as selectors from '../selectors';
import setActiveElement from './setActiveElement';
import * as functions from '../helpers/functions';
import { isSignNow } from '../../helpers/utils';
import { cancellableOpts } from '../modules/undoRedo';

const removeElementWithGroup = (elementToRemove, opts) => {
  return (dispatch, getState) => {
    const newState = utils.deleteElementFromGroups(elementToRemove, getState().ws);
    dispatch(wsActions.updateFillableGroups(newState.fillableGroups, opts));
    dispatch(wsActions.removeFillableElement(elementToRemove.id, opts));
  };
};

const remove = (elementToRemoveId, opts = cancellableOpts) => {
  return (dispatch, getState) => {
    const state = getState();
    const activeElementId = selectors.base.getActiveElementId(state);
    const elementToRemove = selectors.base.getElement(state, elementToRemoveId);
    const elementToRemoveIsFillable = functions.isFillable(elementToRemove);

    // Fixed bug with click on OK button on phone.
    // Element has been deleted from elementsMap (removed on blur for empty text element)
    // and we're calling to get this one from array and got undefined
    if (!elementToRemove) {
      return;
    }

    const isConstructorMode = selectors.mode.isConstructor(state);
    const elementToRemoveIsActive = elementToRemoveId === activeElementId;
    const isRadioElement = functions.isRadioElement(elementToRemove);
    const willClearElement = isSignNow() && elementToRemoveIsFillable &&
      !isConstructorMode && !isRadioElement;

    // for some cases we just clear element's content instead of removing it entirely
    // so we don't need to check if it's conditional or used in formula
    if (!willClearElement) {
      if (!conditionsRemoveElementCheck(dispatch, getState, elementToRemoveId)) {
        return;
      }

      const checkIsElementInFormulas = getLazy(
        formula.functions.checkIsElementInFormulas,
        mocks.fns.false,
      );

      if (
        elementToRemoveIsFillable &&
        checkIsElementInFormulas(dispatch, getState, elementToRemove)
      ) {
        return;
      }
    }

    if (elementToRemoveIsActive) {
      /**
       * fromRemove нужен т.к. возможно зацикливание кода
       * thunks.setActiveElement ->
       * removeSimpleEmptyTextElements ->
       * (1) thunks.remove ->
       * thunks.setActiveElement ->
       * removeSimpleEmptyTextElements ->
       * (2) thunks.remove
       *
       * А элемент был удален еще при первом вызове
       */
      dispatch(setActiveElement(
        elementToRemoveId,
        false,
        { fromRemove: true },
      ));
    }

    if (elementToRemoveIsFillable && isConstructorMode) {
      // TODO: research how to do it in a better way (JSF-8060)
      // if element has group (flat or radio) - update it and delete, if no - only delete
      removeElementWithGroup(elementToRemove, opts)(dispatch, getState);
    } else if (isSignNow() && elementToRemoveIsFillable) {
      // Deactivate element since deleting may leads to clearing currently active element
      // and we will have different state in store.activeElement and store.ws.elements.
      // As an option we may check if activeElementId conditionally depends on elementToRemove
      if (!elementToRemoveIsActive && activeElementId) {
        dispatch(setActiveElement(activeElementId, false));
      }
      dispatch(wsActions.removeElement(
        selectors.elements.getElementToRemove(state, elementToRemove).id,
        opts,
      ));
    } else if (utils.isElementOverlyingText(elementToRemove)) {
      // JSF-7578 [FakeEdit improvement] set space on focus out if chars = 0
      dispatch(wsActions.updateElement(elementToRemoveId, { text: ' ' }, opts));
    } else {
      dispatch(wsActions.removeElement(elementToRemoveId, opts));
    }
  };
};

export const removeFillableElements = (elementIds, opts = {}) => {
  return (dispatch) => {
    elementIds.forEach((elementId) => {
      dispatch(remove(elementId, opts));
    });
  };
};

export default remove;
