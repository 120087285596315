import { createSelector } from 'reselect';
import deprecate from 'jsfcore/helpers/deprecate';
import get from 'lodash/get';
import without from 'lodash/without';
import pick from 'lodash/pick';
import pickBy from 'lodash/pickBy';
import findIndex from 'lodash/findIndex';
import difference from 'lodash/difference';
import find from 'lodash/find';
import flow from 'lodash/flow';

import * as conditions from '@pdffiller/jsf-conditions/selectors';
import * as lazySelectors from '@pdffiller/jsf-lazyload/store/selectors';

import * as modals from '../../jsf-modals/selectors';
import elementsSelectors from './elementsSelectors';
import navigationSelectors from './navigationSelectors';
import * as base from './baseSelectors';
import * as locale from './localesSelectors';
import * as fakeEdit from './fakeEditSelectors';
import * as comments from './commentsSelectors';
import * as modeSelectors from './modeSelectors';
import * as helpers from './helpers';
import * as pages from './wsPagesSelectors';
import * as toolbarSelectors from './toolbarSelectors';
import * as feature from './featureSelectors';
import {
  SCALE_FIT_PAGE,
  SCALE_FIT_WIDTH,
  SCALE_890,
} from '../modules/navigationActionTypes';
import {
  getFitPageScale,
  getFitWidthScale,
  isContiniousPagination,
  getIndexByPageId,
  formatDate,
  getFrameSizesOrDefault,
  getIsReadOnlyOverlayEnabled,
  getIsSigningSessionOverlayEnabled,
  getScrollbarWidth,
} from '../../helpers/utils';
import {
  constructorProperties,
  defaultDateFormat,
  launchTypes,
  occurenceTypes,
  wizardTraverseModes,
  paSections,
  defaultUser,
  editType,
  miscIds,
  accessStatuses,
  bigPdfPagesCount,
} from '../../helpers/const';
import * as contentUtils from '../../components/Content/contentUtils';
import * as stateToProps from '../helpers/stateToProps';
import {
  getElementProps,
  isSignatureCurve,
  isDropdownElement,
  isFillable,
  isFilledElement,
  isDisabledElement,
  getIsSignatureSingleUse,
} from '../helpers/functions';
import {
  isFocusTool,
  elemTypes,
  elemSubTypes,
  isDrawType,
  isSearchHighlightType,
  searchHighlightTypes,
} from '../../helpers/elemTypes';
import { getArrowPropsForGhost } from '../../helpers/graphicUtils';
import isSignNow from '../../helpers/const/isSignNow';

import searchExistingHighlightElements from '../../helpers/search/searchExistingHighlightElements';
import {
  getIsPageAttributeVisible,
} from '../../components/Content/PageAttributesLayer/pageAttributesUtils';

import { getConstructorErrors } from '../../jsf-validations/selectors/errorsSelectors';
import getIsSignnowModalVisible from './getIsSignnowModalVisible';

export {
  locale,
  elementsSelectors as elements,
  navigationSelectors as navigation,
  base,
  fakeEdit,
  comments,
  modeSelectors as mode,
  conditions,
  modals,
  pages,
  helpers,
  toolbarSelectors as toolbar,
  feature,
};

const emptyObject = {};
const getElementWithEnabledProp = (element, roleId) => {
  if (!element) {
    return element;
  }

  return isDisabledElement(element, roleId)
    ? { ...element, enabled: false }
    : { ...element, enabled: true };
};

const getElementsWithEnabledProp = (roleId) => {
  return (elements) => {
    return elements.map((el) => {
      return getElementWithEnabledProp(el, roleId);
    });
  };
};

/**
 * Navigation:
 */
export const getOriginalSize = (state, pageId) => {
  return base.getOriginalSizes(state)[pageId];
};
export const getScale = (state, pageIndex) => {
  return base.getScales(state)[pageIndex];
};
export const getActivePageScale = (state) => {
  return base.getScales(state)[base.getActivePageId(state)];
};
export const getCaretPositionById = (state, elementId) => {
  const caretPosition = base.getCaretPosition(state) || emptyObject;
  const { id, position } = caretPosition;

  return id === elementId
    ? position
    : null;
};

export const isAttributesInitialized = (state) => {
  return !!base.getAttributes(state);
};

export const getOwnerEmail = (state) => {
  const external = base.getExternal(state);

  if (!external || !external.document) {
    return null;
  }

  return external.document.owner;
};

export const getUser = (state, userId) => {
  return base.getUsers(state)[userId] || defaultUser;
};

export const getIsLaunchEqualConstructor = createSelector(
  [base.getLaunchFromAuth],
  (launch) => {
    return launch === launchTypes.constructor;
  },
);

export const getIsRearrangeAvailable = createSelector(
  [base.getFeaturesMisc],
  (misc) => {
    if (!misc) {
      return false;
    }

    const rearrangeFeature = find(misc, (item) => {
      return item.id === miscIds.rearrange.id &&
        item.label === miscIds.rearrange.label;
    });

    return get(rearrangeFeature, 'visible', false);
  },
);

/**
 * -=-=-=- Calculatable -=-=-=-
 *
 * FrameSizes:
 */
export const getFrameSizes = createSelector(
  [base.getOriginalSizes, base.getScales],
  (originalSizes, scales) => {
    if (!originalSizes) {
      return false;
    }
    return originalSizes.map((originalSize, pageIndex) => {
      if (!originalSize) {
        return false;
      }
      return {
        width: originalSize.width * scales[pageIndex],
        height: originalSize.height * scales[pageIndex],
      };
    });
  },
);

export const getFrameSize = (state, pageIndex) => {
  return getFrameSizes(state)[pageIndex];
};
export const getFrameSizesPadded = createSelector(
  [getFrameSizes, base.getFramePadding],
  (frameSizes, framePadding) => {
    return frameSizes.map(
      (frameSize) => {
        return {
          width: frameSize.width + framePadding.left + framePadding.right,
          height: frameSize.height + framePadding.top + framePadding.bottom,
        };
      },
    );
  },
);

export const getFrameSizePadded = (state, pageIndex) => {
  return getFrameSizesPadded(state)[pageIndex];
};

export const getFrameSizeOfActivePage = (state) => {
  return getFrameSize(state, base.getActivePageId(state));
};

/**
 * FrameOffsets:
 */
const getTopAndBottomPadding = ({ framePadding }) => {
  return framePadding.top + framePadding.bottom;
};

const getLeftAndRightPadding = ({ framePadding }) => {
  return framePadding.left + framePadding.right;
};

const getFrameOffsetsWithoutReselectSimple = (frameSizes, workspace, frameScrolls) => {
  return frameSizes.map(({ width, height }, pageIndex) => {
    const offset = {
      top: (workspace.height - height - getTopAndBottomPadding(workspace)) / 2,
      left: (workspace.width - width - getLeftAndRightPadding(workspace) - getScrollbarWidth()) / 2,
    };
    return {
      top: (offset.top && offset.top > 0)
        ? offset.top
        : 0,

      left: (offset.left && offset.left > 0)
        ? offset.left
        : 0,

      ...frameScrolls[pageIndex],
    };
  });
};

export const getFrameOffsetsWithoutReselectContinious =
  (frameSizes, workspace, frameScrolls, pagesSettings, scales = [1]) => {
    const scale = scales[0];
    const frameOffsets = [];
    const { framePadding } = workspace;
    const frameSizesOrDefault = getFrameSizesOrDefault({
      frameSizes,
      workspace,
      pagesSettings,
      scale,
    });
    for (let index = 0; index < frameSizesOrDefault.length; index++) {
      const { width } = frameSizesOrDefault[index];
      const top = [...Array(index)].reduce((acc, _, i) => {
        const pageHeight = frameSizesOrDefault[i].height;
        return acc + pageHeight + framePadding.top;
      }, 0);
      const offsetLeft = (workspace.width - width - getLeftAndRightPadding(workspace)) / 2;
      frameOffsets.push({
        left: offsetLeft > 0
          ? offsetLeft
          : 0,
        top,
        ...frameScrolls[index],
      });
    }

    return frameOffsets;
  };

export const getFrameOffsetsWithoutReselect = (...args) => {
  return isContiniousPagination()
    ? getFrameOffsetsWithoutReselectContinious(...args)
    : getFrameOffsetsWithoutReselectSimple(...args);
};

export const getFrameOffsets = isSignNow()
  ? createSelector(
    [
      getFrameSizes,
      base.getWorkspace,
      base.getFrameScrolls,
      navigationSelectors.getPagesSettings,
      base.getScales,
    ],
    getFrameOffsetsWithoutReselect,
  )
  : createSelector(
    [getFrameSizes, base.getWorkspace, base.getFrameScrolls],
    getFrameOffsetsWithoutReselect,
  );

export const getFrameOffset = (state, pageIndex) => {
  return getFrameOffsets(state)[pageIndex];
};

export const getFrameOffsetOfActivePage = (state) => {
  return getFrameOffset(state, base.getActivePageId(state));
};

/**
 * DefaultScales:
 */
export const getDefaultScales = createSelector(
  [base.getWorkspace, base.getOriginalSizes],
  (workspace, originalSizes) => {
    if (!workspace) {
      return false;
    }
    if (!originalSizes) {
      return false;
    }
    return originalSizes.map((originalSize) => {
      if (!originalSize) {
        return undefined;
      }
      return {
        [SCALE_FIT_PAGE]: getFitPageScale(workspace, originalSize),
        [SCALE_FIT_WIDTH]: getFitWidthScale(workspace, originalSize),
        [SCALE_890]: getFitWidthScale(
          {
            ...workspace,
            width: 890 + (
              workspace.framePadding.left + workspace.framePadding.right
            ),
          },
          originalSize,
        ),
      };
    });
  },
);

export const getDefaultScale = (state, pageIndex) => {
  const defaultScales = getDefaultScales(state);
  if (!defaultScales) {
    return undefined;
  }
  return defaultScales[pageIndex];
};

export const getElementById = (id) => {
  return createSelector(
    [elementsSelectors.getActiveElement, base.getElementsMap],
    (activeElement, elements) => {
      if (activeElement && activeElement.id === id) {
        return activeElement;
      }
      return elements[id];
    });
};

const getElementsMergedWithActiveElement = ({ elements, activeElement }) => {
  return activeElement
    ? elements.map((elem) => {
      if (elem.id === activeElement.id) {
        return activeElement;
      }
      return elem;
    })
    : elements;
};

const getPage = ({ pagesSettings, el }) => {
  return get(
    pagesSettings.filter((setting) => {
      return setting.visible && setting.source === el.pageId;
    })[0],
    'source',
    false,
  );
};

// case when element page is absent in document (after rearrange)
const getVisibleElementsMergedWithActiveElement = (elements, pagesSettings, activeElement) => {
  return getElementsMergedWithActiveElement({
    elements: elements.filter((el) => {
      return getPage({ pagesSettings, el }) !== false;
    }),
    activeElement,
  });
};

const getOnlyDropDownElements = (elements) => {
  return elements.filter((el) => {
    return isDropdownElement(el);
  });
};
const getOnlyFillableElements = (elements) => {
  return elements.filter((el) => {
    return isFillable(el);
  });
};

export const getDropDownElements = createSelector(
  [
    base.getElements,
    navigationSelectors.getPagesSettings,
    elementsSelectors.getActiveElement,
    base.getRoleId,
  ],
  (elements, pagesSettings, activeElement, roleId) => {
    if (!pagesSettings || !elements) {
      return [];
    }

    return flow(
      getVisibleElementsMergedWithActiveElement,
      getOnlyDropDownElements,
      getElementsWithEnabledProp(roleId),
    )(elements, pagesSettings, activeElement, roleId);
  },
);

export const getEmptyDropDownElements = createSelector(
  [getDropDownElements],
  (dropDownElements) => {
    return dropDownElements.filter((el) => {
      return (
        get(el, 'template.list.length', 0) === 0 ||
        get(el, 'template.list[0].addedByDefault', false)
      );
    });
  },
);

export const getRadioElementsByRadioGroupName = (state, radioGroupName) => {
  if (!radioGroupName) {
    return [];
  }

  const elements = elementsSelectors.getElements(state);

  return elements.filter((element) => {
    return get(element, 'template.name', '') === radioGroupName;
  });
};

export const getFillableElements = createSelector(
  [
    base.getElements,
    navigationSelectors.getPagesSettings,
    elementsSelectors.getActiveElement,
    base.getRoleId,
  ],
  (elements, pagesSettings, activeElement, roleId) => {
    if (!pagesSettings || !elements) {
      return [];
    }
    return flow(
      getVisibleElementsMergedWithActiveElement,
      getOnlyFillableElements,
      getElementsWithEnabledProp(roleId),
    )(elements, pagesSettings, activeElement, roleId);
  },
);

export const getElementsWithValidationErrors = createSelector([
  base.getElements,
  getConstructorErrors,
], (elements, constructorErrors) => {
  if (!elements) {
    return [];
  }

  return elements.filter((element) => {
    return constructorErrors[element.id] && !constructorErrors[element.id].isValid;
  });
});

export const doFillableElementsExist = createSelector(
  [getFillableElements],
  (elements) => {
    return elements.length > 0;
  },
);

const filterDisabled = (elements) => {
  return elements.filter((el) => {
    return el.enabled;
  });
};

export const getFillableEnabledElements = createSelector(
  [
    base.getElements,
    navigationSelectors.getPagesSettings,
    elementsSelectors.getActiveElement,
    base.getRoleId,
  ],
  (elements, pagesSettings, activeElement, roleId) => {
    if (!pagesSettings || !elements) {
      return [];
    }
    return flow(
      getVisibleElementsMergedWithActiveElement,
      getOnlyFillableElements,
      getElementsWithEnabledProp(roleId),
      filterDisabled,
    )(elements, pagesSettings, activeElement, roleId);
  },
);

export const getActivePageIndex = createSelector(
  [base.getActivePageId, navigationSelectors.getPagesSettings],
  (activePageId, pagesSettigns) => {
    return getIndexByPageId(activePageId, pagesSettigns);
  },
);

/**
 * getIsActivePage:
 */
export const getIsActivePage = (state, pageId) => {
  return base.getActivePageId(state) === pageId;
};

/**
 * getIsContentLocked:
 */
export const getIsContentLocked = (state, pageId) => {
  return getIsActivePage(state, pageId) && (
    !!base.getStretchingElementId(state) ||
    !!base.getIsDrawingNewGraphicElement(state)
  );
};

/**
 * getDrawingLayerType:
 */
export const { getDrawingLayerType } = contentUtils;

/**
 * getIsHoverTool
 */
export const getIsHoverTool = (state) => {
  const activeTool = base.getActiveTool(state);

  if (isSignNow()) {
    return activeTool && isFocusTool(activeTool);
  }

  const isConstructor = modeSelectors.isConstructor(state);
  /**
   * В FillingMode hover тулы описаны в isFocusTool.
   * В ConstructorMode всегда false, чтобы запретить фокусироваться с simple элементы
   */
  return activeTool && isFocusTool(activeTool) && !isConstructor;
};

/**
 * getIsWizardContentVisible
 */
export const getIsWizardContentVisible = (state) => {
  return (
    lazySelectors.common.getIsWizardLoaded(state) && modeSelectors.isFilling(state)
  );
};

/**
 * getIsModalChildsSet
 */
export const getIsModalChildsSet = (state) => {
  return !!get(state, 'modal.chlds.length');
};

/**
 * getGhostElementForRender
 */
export const getGhostElementForRender = createSelector(
  [base.getGhostElement, base.getActivePageId],
  (ghost, activePageId) => {
    if (!ghost) {
      return null;
    }

    const { type, subType } = ghost;
    const ghostProps = getElementProps(ghost);
    const { width: arrowWidth, height: arrowHeight } = getArrowPropsForGhost(ghost.content || {});

    return ({
      ...ghost,
      pageId: activePageId,
      content: {
        x: 0,
        y: 0,
        ...ghost.content,
        ...(
          type === 'highlight' ||
          type === 'erase' ||
          type === 'blackout' ||
          type === 'pen' ||
          type === 'line'
            ? {
              width: ghost.content.lineWidth,
              height: ghost.content.lineWidth,
            }
            : {}
        ),
        ...(
          type === 'arrow'
            ? {
              width: arrowWidth,
              height: arrowHeight,
            }
            : {}
        ),

        ...(type === 'text' && (subType === 'none' || !subType)
          ? {
            subType: 'none',
            width: 10,
            height: 17,
            text: 'T',
          }
          : {}),

        ...(type === 'text' && subType === 'date'
          ? {
            width: 72,
            text: formatDate(new Date(), defaultDateFormat),
            height: 17,
          }
          : {}),
        // For Constructor Ghosts
        ...(
          (type === elemTypes.fctool && subType !== elemTypes.none)
            ? {
              width: ghostProps.width,
              height: ghostProps.height,
            }
            : {}
        ),
        ...(
          isSignatureCurve(ghost)
            ? {
              width: ghost.content.width,
              height: ghost.content.height,
            }
            : {}
        ),
        ...(ghostProps.width
          ? { width: ghostProps.width }
          : {}),

        ...(ghostProps.height
          ? { height: ghostProps.height }
          : {}),
      },
    });
  },
);

/**
 * getIsModalVisible
 */
export const getIsModalVisible = (...args) => {
  return stateToProps.isModalVisible(...args);
};

/**
 * getIsGhostHidden
 */
export const getIsGhostHidden = createSelector(
  [
    getIsModalVisible,
    base.getIsWizardActive,
    base.getIsDrawingNewGraphicElement,
    base.getStretchingElementId,
    base.getIsFConstructorPreviewShown,
    base.getIsPAConstructorShown,
    base.getIsVersionsShown,
  ],
  (
    isModalVisible,
    isWizardActive,
    isDrawingNewGraphicElement,
    stretchingElementId,
    isFConstructorPreviewShown,
    isPAConstructorShown,
    isVersionsShown,
  ) => {
    return (
      isModalVisible ||
      isWizardActive ||
      isDrawingNewGraphicElement ||
      stretchingElementId !== false ||
      isFConstructorPreviewShown ||
      isPAConstructorShown ||
      isVersionsShown
    );
  },
);

/**
 * getIsElementCreationAvailable
 */
export const getIsElementCreationAvailable = createSelector(
  [
    base.getStretchingElementId,
    base.getActiveTool,
    getIsModalVisible,
    base.getGhostElement,
    modeSelectors.isPreview,
    modeSelectors.isPageAttributes,
  ],
  (
    stretchingElementId,
    activeTool,
    isModalVisible,
    ghostElement,
    isPreview,
    isPageAttributes,
  ) => {
    if (!activeTool) {
      return false;
    }
    if (stretchingElementId) {
      return false;
    }
    if (activeTool.type === elemTypes.selection) {
      return false;
    }
    if (isModalVisible) {
      return false;
    }
    if (!ghostElement) {
      return false;
    }
    if (isDrawType(ghostElement.type)) {
      return false;
    }
    if (isPreview) {
      return false;
    }
    if (isPageAttributes) {
      return false;
    }
    return true;
  },
);

/**
 * -=-=-=- SubSelectors -=-=-=-
 */
export const subSelectors = {
  getEraseSeparately: contentUtils.getEraseSeparately,
};

/**
 * getIsContentShown:
 */
export const getIsNextPageId = (state, pageId) => {
  return base.getNextPageId(state) === pageId;
};

export const getIsCanvasRendered = (state, pageId) => {
  return !!base.getCanvases(state)[pageId];
};

export const getIsContentShown = createSelector(
  [getIsActivePage, getIsNextPageId, getIsCanvasRendered],
  (isActivePage, isNextPageId, isCanvasRendered) => {
    return isActivePage || isNextPageId || isCanvasRendered;
  },
);

/**
 * getDefaultTool:
 */
export const getDefaultTool = isSignNow()
  ? (state) => {
    const viewMode = base.getViewMode(state);
    return (
      viewMode === 'freeform' ||
      viewMode === 'limited-edit'
        ? { type: elemTypes.text, subType: elemSubTypes.none }
        : { type: elemTypes.selection, subType: elemSubTypes.none }
    );
  }
  : createSelector(
    [base.getFeatures],
    ({ toolbar }) => {
      const textId = `tools.${elemTypes.text}.${elemSubTypes.none}`;
      const hasText = findIndex(toolbar, ({ id, visible }) => {
        return id === textId && visible;
      }) > -1;
      if (hasText) {
        return { type: elemTypes.text, subType: elemSubTypes.none };
      }

      return { type: elemTypes.selection, subType: elemSubTypes.none };
    },
  );

/**
 * getConstructorDefaultTool:
 */
export const getConstructorDefaultTool = () => {
  return { type: elemTypes.fctool, subType: elemSubTypes.none };
};

/**
 * Funcs for getIsHighlighted:
 */
const getElementGroups = createSelector(
  elementsSelectors.getElements,
  (elements) => {
    return stateToProps.groupByRadioGroup(elements);
  },
);

export const makeGetElementGroup = (elementId) => {
  return createSelector(
    [getElementGroups],
    (groups) => {
      const elementGroupIndex = findIndex(groups, (group) => {
        if (group.id && group.id === elementId) {
          return true;
        }
        if (group.length) {
          return findIndex(group, (el) => {
            return el.id === elementId;
          }) > -1;
        }
        return false;
      });
      if (elementGroupIndex === -1) {
        return false;
      }
      return groups[elementGroupIndex];
    },
  );
};

// Этот селектор получает значение filled.
// Для обычных элементов - в обычном порядке
// Для элементов в радиогруппе - true если хотя бы один из радиогруппы checked
const makeIsFilledSelector = (elementId, isFillableElement) => {
  if (!isFillableElement) {
    return () => {
      return false;
    };
  }

  const getCurrentElementGroup = makeGetElementGroup(elementId);
  return createSelector(
    [getCurrentElementGroup],
    (elementGroup) => {
      if (elementGroup.length) {
        return elementGroup.reduce((filled, el) => {
          return filled || get(el, 'content.checked', false);
        }, false);
      }

      return isFilledElement(elementGroup);
    },
  );
};


/**
 * Return true or false -
 * Need show 'required*' near field or not
 */
export const getIsHighlighted = (state, elementId) => {
  const element = base.getElement(state, elementId);
  const wtm = base.getWizardTraverseMode(state);

  if (!element) {
    return false;
  }
  if (!isFillable(element)) {
    return false;
  }

  if (
    (
      wtm === wizardTraverseModes.requiredUnfilled &&
      get(element, 'template.required')
    ) ||
    wtm === wizardTraverseModes.allUnfilled
  ) {
    return !makeIsFilledSelector(element.id, isFillable(element));
  }

  return false;
};

export const getUnloadedTextContent = createSelector(
  [base.getTextContentPages, navigationSelectors.getPagesSettings],
  (textContentPages, pagesSettings) => {
    return textContentPages
      .map((page, index) => {
        return page === null
          ? index
          : null;
      })
      .filter((pageId) => {
        if (pageId === null) {
          return false;
        }

        const setting = find(pagesSettings, (settingsItem) => {
          return settingsItem.source === pageId;
        });
        return setting && setting.visible;
      });
  },
);

export const getUnloadedPages = createSelector(
  [base.getPdfPages, navigationSelectors.getPagesSettings],
  (pdfPages, pagesSettings) => {
    return pdfPages
      .map((page, index) => {
        return page === null
          ? index
          : null;
      })
      .filter((pageId) => {
        if (pageId === null) {
          return false;
        }

        const setting = find(pagesSettings, (settingsItem) => {
          return settingsItem.source === pageId;
        });
        return setting && setting.visible;
      });
  },
);

export const getIsAllTextContentLoaded = createSelector(
  [getUnloadedTextContent],
  (unloadedPages) => {
    return unloadedPages.length === 0;
  },
);

export const getIsAllPagesLoaded = createSelector(
  [getUnloadedPages],
  (unloadedPages) => {
    return unloadedPages.length === 0;
  },
);

export const getActivePAConstructorSection = createSelector(
  [base.getIsPAConstructorShown, base.getAttributes],
  (isPACShown, attributes) => {
    return (isPACShown && attributes.activeSection) || false;
  },
);

const getHighlightElements = createSelector(
  [elementsSelectors.getElements],
  (elements) => {
    return elements.filter((element) => {
      return isSearchHighlightType(element.type);
    });
  },
);

export const makeGetPageOccurences = (pageId) => {
  return createSelector(
    [base.getOccurences],
    (occurences) => {
      if (!occurences) {
        return false;
      }
      return (
        occurences.filter(
          (occurence) => {
            return (
              occurence.pageId === pageId &&
              occurence.type === occurenceTypes.pdfText
            );
          },
        )
      );
    },
  );
};

export const makeGetSearchOccurences = (elementId) => {
  return createSelector(
    [modeSelectors.isSearch, base.getOccurences],
    (isSearchModeActive, occurences) => {
      if (!isSearchModeActive || !occurences) {
        return false;
      }

      const elementOccurences = occurences.filter((occurence) => {
        return occurence.elementId === elementId;
      });
      if (elementOccurences.length > 0) {
        return elementOccurences;
      }

      return false;
    },
  );
};

export const getHighlightDefaults = createSelector(
  [base.getDefaultContents],
  (defaultContents) => {
    return (
      defaultContents
        .map((content) => {
          return { ...content, type: content.id.split('.')[1] };
        })
        .filter((content) => {
          return searchHighlightTypes.indexOf(content.type) > -1;
        })
        .reduce(
          (result, content) => {
            return {
              ...result,
              [content.type]: pickBy(content, (__, key) => {
                return key !== 'id';
              }),
            };
          },
          {},
        )
    );
  },
);

export const getExistingHighlights = (state) => {
  const rects = base.getRects(state);
  const occurence = base.getActiveOccurence(state);
  const highlightElements = getHighlightElements(state).filter((element) => {
    return element.pageId === occurence.pageId;
  });

  return searchExistingHighlightElements(rects, highlightElements);
};

const getElementsForSearch = createSelector(
  [base.getElements, elementsSelectors.getActiveElement, base.getIsFConstructorShown],
  (elements, activeElement, isFConstructorShown) => {
    const mergedElements = getElementsMergedWithActiveElement({ elements, activeElement });

    if (isFConstructorShown) {
      return mergedElements.filter((element) => {
        return !isFillable(element);
      });
    }

    return mergedElements;
  },
);

export const getArgsForSearch = (state) => {
  return {
    searchTerm: base.getSearchTerm(state),
    elements: getElementsForSearch(state),
    textContentPages: base.getTextContentPages(state),
    pagesSettings: navigationSelectors.getPagesSettings(state),
  };
};

/*
 * pageAttributes
 */
const getAttrName = (__, attrName) => {
  return attrName;
};

export const getIsPACShownWithSection = createSelector(
  [base.getIsPAConstructorShown, base.getPAConstructorActiveSection],
  (isPACShown, activeSection) => {
    return (isPACShown && activeSection) || false;
  },
);

const findDefaultAttrContent = (defaults, attrName) => {
  return find(defaults, (contentItem) => {
    return contentItem.id === `tools.attributes.${attrName}`;
  });
};
export const getDefaultAttrContent = createSelector(
  [base.getDefaultContents, getAttrName],
  findDefaultAttrContent,
);

const findAttrContent = (attributes, attrName) => {
  return get(attributes, `[${attrName}].content`, false);
};
export const getAttrContent = createSelector(
  [base.getAttributes, getAttrName],
  findAttrContent,
);

export const getAttrContentWithDefaults = createSelector(
  [getAttrContent, getDefaultAttrContent],
  (attrContent, defaultAttrContent) => {
    const { visible, ...defaultWithoutVisible } = defaultAttrContent;
    return {
      ...defaultWithoutVisible,
      ...attrContent,
    };
  },
);

export const getActiveAttrContentWithDefaults = createSelector(
  [base.getAttributes, base.getDefaults, getIsPACShownWithSection],
  (attributes, defaults, activeSection) => {
    if (!activeSection) {
      return false;
    }
    return {
      ...findDefaultAttrContent(defaults, activeSection),
      ...findAttrContent(attributes, activeSection),
    };
  },
);

const getPageIndex = (state, attrName, pageIndex) => {
  return pageIndex;
};

/*
 * radio groups
 */
export const getElementsIdByRadioGroup = (state, groupName) => {
  return base.getRadioGroups(state)[groupName];
};

export const getIsAttributeInited = (state, attrName) => {
  const attrContent = getAttrContent(state, attrName);
  if (!attrContent) {
    return false;
  }

  const defaultContent = getDefaultAttrContent(state, attrName);
  return difference(
    without(Object.keys(defaultContent), 'id'),
    Object.keys(attrContent),
  ).length === 0;
};

export const getNeededAttrContent = (state, attrName) => {
  const attrContent = getAttrContent(state, attrName);
  const defaultContent = getDefaultAttrContent(state, attrName);

  if (!attrContent) {
    return defaultContent;
  }
  const uninitedKeys = difference(Object.keys(defaultContent), Object.keys(attrContent));
  return pick(defaultContent, uninitedKeys);
};

export const getCheckedElementInsideRadioGroup = (state, groupName) => {
  const elementIds = getElementsIdByRadioGroup(state, groupName);
  if (elementIds === undefined) {
    return false;
  }

  const elementsMap = base.getElementsMap(state);
  const checkedElement = elementIds
    .map((elementId) => {
      return elementsMap[elementId];
    })
    .find((element) => {
      return element.content && element.content.checked;
    });

  return checkedElement;
};

export const isUndoAvailable = createSelector(
  [base.getUndoRedoHistory, base.getUndoRedoCursor],
  (history, cursor) => {
    return history.length - cursor > 0;
  },
);

export const isRedoAvailable = createSelector(
  base.getUndoRedoCursor,
  (cursor) => {
    return cursor !== 0;
  },
);

export const getUndoRedo = createSelector(
  [
    base.getUndoRedoHistory,
    base.getUndoRedoCursor,
    elementsSelectors.getActiveElement,
    base.getActivePageId,
  ],
  (history, cursor, activeElementId, activePageId) => {
    return {
      history,
      cursor,
      activeElementId,
      activePageId,
    };
  },
);

export const getIsAttrVisibleOnPage = createSelector(
  [getAttrContent, getAttrContentWithDefaults, getPageIndex],
  (content, contentWithDefaults, pageIndex) => {
    if (!content || !content.visible) {
      return false;
    }
    return getIsPageAttributeVisible(contentWithDefaults, pageIndex);
  },
);

export const getRestrictedAligns = createSelector(
  [base.getAttributes, base.getDefaultContents, getAttrName],
  (attributes, defaultContents, attrName) => {
    return Object.values(paSections)
      .filter((sectionKey) => {
        return attrName !== sectionKey;
      })
      .reduce((result, sectionKey) => {
        const content = get(attributes[sectionKey], 'content', false);
        if (!content || !content.visible) {
          return result;
        }
        const defaultContent = findDefaultAttrContent(defaultContents, sectionKey);
        const align = content.align || defaultContent.align;
        if (!align) {
          return result;
        }
        return [
          ...result,
          align,
        ];
      }, []);
  },
);

/**
 * Content utils
 */
export const makeGetElementsForPage = (pageId) => {
  return createSelector(
    [base.getElements],
    (elements) => {
      return elements.filter((elem) => {
        return elem.pageId === pageId;
      });
    },
  );
};

const makeGetElementsWithActiveForPage = (pageId) => {
  const getElementsForPage = makeGetElementsForPage(pageId);
  return createSelector(
    [getElementsForPage, elementsSelectors.getActiveElement],
    (elements, activeElement) => {
      if (activeElement.pageId !== pageId) {
        return elements;
      }
      return getElementsMergedWithActiveElement({ elements, activeElement });
    },
  );
};

export const makeGetPageElements = (pageId) => {
  const getElementsWithActiveForPage = makeGetElementsWithActiveForPage(pageId);
  return createSelector(
    [getElementsWithActiveForPage, base.getStretchingElementId],
    (elements, stretchingElementId) => {
      if (!elements) {
        return [];
      }
      return elements.filter((elem) => {
        if (!elem) {
          return false;
        }

        if (!elemTypes[elem.type]) {
          // console.log('Element skiped (!elemTypes[elem.type]): ', elem);
          return false;
        }

        // stretching|Element will be renderede at DrawingLayer
        if (elem.id === stretchingElementId) {
          return false;
        }

        /**
        * https://pdffiller.atlassian.net/browse/JSF-1605
        * get rid of trash elements either with negative coords or without content
        * that leads to .content transform: translate issue
        */
        if (elem.template && 'x' in elem.template && 'y' in elem.template) {
          if (elem.template.x < 0 || elem.template.y < 0) {
            return false;
          }
        } else if (!(elem.content && 'x' in elem.content && 'y' in elem.content)) {
          return false;
        }

        return (elem.pageId === pageId);
      });
    },
  );
};

/*
 * Fake edit
 */

export { getIsSignnowModalVisible };

export const isFConstructorOrderShown = deprecate(
  `plz stop using selectors.isFConstructorOrderShown,
   use selectors.base.getIsFConstructorOrderShown or selectors.mode.isFieldsOrder`,
  base.getIsFConstructorOrderShown,
);

export const getMiscFeatures = createSelector(
  [base.getFeatures],
  (features) => {
    const { misc = [] } = features;

    return misc.reduce((acc, el) => {
      acc[el.id] = {
        locale: { label: get(el, 'label', '') },
        isVisible: get(el, 'visible', false),
      };
      return acc;
    }, {});
  },
);

export const getExtrasBarFeatures = createSelector(
  [base.getFeatures], (features) => {
    const { extrasbar = [] } = features;

    return extrasbar.reduce((acc, el) => {
      acc[el.id] = {
        isVisible: get(el, 'visible', false),
      };
      return acc;
    }, {});
  },
);

const defaultFeatureObject = {
  locale: { label: '' },
  isVisible: false,
};

export const getMiscFeature = (state, miscId) => {
  return getMiscFeatures(state)[miscId] || defaultFeatureObject;
};

export const getExtrasBarFeature = (state, id) => {
  return get(getExtrasBarFeatures(state), id, defaultFeatureObject);
};

export const getMiscFeatureVisible = (state, miscId) => {
  return get(getMiscFeature(state, miscId), 'isVisible', false);
};

export const getExtrasBarFeatureVisible = (state, id) => {
  return get(getExtrasBarFeature(state, id), 'isVisible', false);
};

export const getIsModesPanelVisible = (state) => {
  const modesBar = base.getModeBarFeature(state);

  return typeof find(modesBar, { visible: true }) !== 'undefined';
};

export const getIsButtonVisible = (state, buttonId) => {
  const constructor = base.getConstructorFeature(state);
  const button = find(constructor, { id: buttonId });

  return get(button, 'visible', false);
};

export const getFieldNamePropertyVisibility = (state) => {
  const constructorFeatures = base.getConstructorFeature(state);
  const fieldNameProperties = find(
    constructorFeatures,
    { id: constructorProperties.fieldName },
  );

  return get(fieldNameProperties, 'visible', false);
};

export const getIsDoneButtonVisible = (state) => {
  const doneButton = base.getDoneButton(state);
  const isAppStarted = base.getAppStarted(state);
  const currentEditType = base.getEditType(state);
  const hideByType = currentEditType === editType.as && !isAppStarted;

  return (doneButton.visible === undefined || doneButton.visible === null)
    ? true
    : doneButton.visible && !hideByType;
};

export const getIsImageAvailable = createSelector(
  [base.getFeaturesToolbar],
  (toolbar) => {
    const imageToolbarItem = find(toolbar, (toolbarItem) => {
      return toolbarItem.id === `tools.${elemTypes.image}.${elemSubTypes.none}`;
    });

    if (!imageToolbarItem) {
      return false;
    }

    return imageToolbarItem.visible === true;
  },
);

export const getIsSignatureAvailable = createSelector(
  [base.getFeaturesToolbar],
  (toolbar) => {
    const signToolbarItem = find(toolbar, (toolbarItem) => {
      return toolbarItem.id === `tools.${elemTypes.signature}.*`;
    });

    if (!signToolbarItem) {
      return false;
    }

    return signToolbarItem.visible === true;
  },
);

export const getIsReadOnlyMode = createSelector(
  [base.getToolbarOverlays],
  (toolbarOverlays) => {
    return getIsReadOnlyOverlayEnabled(toolbarOverlays);
  },
);

export const getIsSigningSessionMode = createSelector(
  [base.getToolbarOverlays, elementsSelectors.getFillableEnabledElements],
  (toolbarOverlays, fillableElements) => {
    return (
      getIsSigningSessionOverlayEnabled(toolbarOverlays) &&
      fillableElements.length > 0
    );
  },
);

export const getIsBusyAccessStatus = (state) => {
  const accessStatus = base.getAccessStatus(state);

  return accessStatus === accessStatuses.busy;
};

export const getUseSignNowTheme = (state) => {
  return get(state, 'ws.useSignNowTheme', false);
};

export const getIsAirSlate = (state) => {
  const currentEditType = base.getEditType(state);

  return currentEditType === editType.as;
};

export const getIsActiveSignatureSingleUse = createSelector(
  [elementsSelectors.getActiveElement],
  (activeElement) => {
    return getIsSignatureSingleUse(activeElement);
  },
);

export const getIsPdfBig = (state) => {
  return base.getPdfCount(state) > bigPdfPagesCount;
};

export const getShouldHidePointerInFullWizard = (state) => {
  return base.getIsWizardFull(state) && base.getHidePointerInFullWizard(state);
};

export const getViewerEmail = createSelector(
  [base.getViewerId, base.getUsers],
  (viewerId, users) => {
    return get(users, `[${viewerId}].email`);
  },
);

const getElementsGroupedByRadioGroupFactory = (elementsSelector) => {
  return createSelector(
    [elementsSelector],
    (fillableElements) => {
      return stateToProps.groupByRadioGroup(fillableElements);
    },
  );
};

const getFilledElementsLengthGroupedByRadioGroupFactory = (elementsSelector) => {
  return createSelector(
    [elementsSelector],
    (fillableElements) => {
      const filledElements = fillableElements.filter((fillableElement) => {
        return Array.isArray(fillableElement)
          ? fillableElement.some(isFilledElement)
          : isFilledElement(fillableElement);
      });

      return filledElements.length;
    },
  );
};

export const getFillableRequiredEnabledElementsGroupedByRadioGroup =
  getElementsGroupedByRadioGroupFactory(elementsSelectors.getFillableRequiredEnabledElements);

export const getFillableRequiredEnabledElementsLengthGroupedByRadioGroup = (state) => {
  return getFillableRequiredEnabledElementsGroupedByRadioGroup(state).length;
};

export const getFilledFillableRequiredElementsLengthGroupedByRadioGroup =
  getFilledElementsLengthGroupedByRadioGroupFactory(
    getFillableRequiredEnabledElementsGroupedByRadioGroup,
  );

export const getFillableOptionalEnabledElementsGroupedByRadioGroup =
  getElementsGroupedByRadioGroupFactory(elementsSelectors.getFillableOptionalEnabledElements);

export const getFillableOptionalEnabledElementsLengthGroupedByRadioGroup = (state) => {
  return getFillableOptionalEnabledElementsGroupedByRadioGroup(state).length;
};

export const getFilledFillableOptionalElementsLengthGroupedByRadioGroup =
  getFilledElementsLengthGroupedByRadioGroupFactory(
    getFillableOptionalEnabledElementsGroupedByRadioGroup,
  );

export const getLoggedInViewerId = (state) => {
  const isLoggedInViewer = base.getIsLoggedInViewer(state);
  const viewerId = base.getViewerId(state);

  return isLoggedInViewer
    ? viewerId
    : null;
};
