import * as Box from './Box';
import * as Grid from './Grid';
import * as ToolBar from './ToolBar';
import * as Logo from './Logo';
import * as SettingBar from './SettingBar';
import * as Slider from './Slider';
import * as Menu from './Menu';
import * as ColorPicker from './ColorPicker';
import * as Group from './Group';
import * as Popover from './Popover';
import * as PopoverItem from './PopoverItem';
import * as EditorAction from './EditorAction';
import * as FillableActions from './FillableActions';
import * as EditorInfo from './EditorInfo';
import * as BottomSheet from './BottomSheet';
import * as Tools from './Tools';
import * as SettingsBox from './SettingsBox';
import * as Dialog from './Dialog';
import * as FlexibleBar from './FlexibleBar';
import * as ContentConfigurator from './ContentConfigurator';
import * as FlexibleList from './FlexibleList';
import * as PageThumbnail from './PageThumbnail';
import * as PageNavigation from './PageNavigation';
import * as Panel from './Panel';
import * as PanelHeader from './PanelHeader';
import * as EntitledBox from './EntitledBox';
import * as CustomField from './CustomField';
import * as CustomSettings from './CustomSettings';
import * as RadioGroup from './RadioGroup';
import * as Tabs from './Tabs';
import * as SideBar from './SideBar';
import * as SortableElement from './SortableElement';
import * as List from './List';
import * as FillableWizard from './FillableWizard';
import * as ListConfigurator from './ListConfigurator';
import * as TextBox from './TextBox';
import * as RightSidebar from './RightSidebar';
import * as FillableField from './FillableField';
import * as TodoList from './TodoList';
import * as Counter from './Counter';

import locales from './locales';

import ControlBar from './ControlBar';

import {
  bottomSheetThemes,
} from './BottomSheet/BottomSheet';

import {
  boxThemes,
} from './Box/BoxIndex';

import {
  fillableActionsThemes,
  fillableActionsOffsets,
} from './FillableActions/FillableActions';

import {
  colorPickerTypes,
  colorPickerOffsets,
} from './ColorPicker/ColorPicker';

import {
  colorPickerItemTypes,
  colorPickerItemTags,
} from './ColorPicker/ColorPickerItemByType';

import {
  menuItemsRoles,
} from './Menu/MenuItem';

import {
  menuItemsTypes,
} from './Menu/MenuItemByType';

import {
  menuBodyTypes,
  menuBodyThemes,
  menuBodyJustifies,
  menuBodySizes,
} from './Menu/MenuBody';

import {
  gridSizes,
  gridOffsets,
  gridTypes,
  gridJustifies,
} from './Grid/GridCell';

import {
  gridSeparatorSizes,
  gridSeparatorDirections,
} from './Grid/GridSeparator';

import Separator, {
  separatorThemes,
  separatorVerticalOffsets,
  separatorHorizontalOffsets,
  separatorTypes,
} from './Separator';

import Switcher, {
  switcherSizes,
  switcherThemes,
} from './Switcher';

import { todoListSizes } from './TodoList/TodoListIndex';

import {
  gridCellGroupSizes,
  gridCellGroupTypes,
} from './Grid/GridCellGroup';

import {
  toolbarItemTypes,
} from './ToolBar/ToolBarItem';

import {
  toolbarThemes,
  toolbarSizes,
  toolbarTypes,
  toolbarBehaviors,
  toolbarOffsets,
} from './ToolBar/ToolBarBody';

import {
  popoverArrowPositions,
  popoverSizes,
  popoverThemes,
  popoverOffsets,
  popoverOffsetsHorizontal,
  popoverOffsetsVertical,
} from './Popover/Popover';

import { popoverItemOffsets, popoverItemThemes } from './PopoverItem/PopoverItem';


import Control, {
  controlThemes,
  controlSizes,
  controlOffsets,
  controlBehaviors,
  controlTypes,
  boxIconTypes,
  boxIconSizes,
  dataPointerTypes,
  controlJustifies,
  controlAligns,
  controlVerticalAligns,
  controlRoles,
  controlTags,
  controlDisplays,
} from './Control';

import Icon, {
  iconTypes,
  iconSizes,
  iconThemes,
} from './Icon';

import Text, {
  textFamilies,
  textOffsets,
  textTopOffsets,
  textRightOffsets,
  textBottomOffsets,
  textAligns,
  textWeights,
  textTransforms,
  textLineHeights,
  textThemes,
  textTags,
  textWritings,
  textSizes,
  textDisplays,
} from './Text';

import TextareaAutocomplete, {
  textareaAutocompleteSizes,
  textareaAutocompleteThemes,
} from './TextareaAutocomplete';

import Input, {
  inputThemes,
  inputSizes,
  inputOffsets,
  inputTypes,
} from './Input';


import Label, {
  labelTypes,
  labelThemes,
  labelSizes,
} from './Label';

import {
  editorActionSizes,
  editorActionTypes,
  editorActionOffsets,
} from './EditorAction/EditorAction';

import {
  groupTypes,
  groupSizes,
  groupOffsets,
  groupOffsetsHorizontal,
  groupBehaviors,
  groupAligns,
  groupJustifies,
} from './Group/Group';

import {
  logoSizes,
  logoOffsets,
  logoBehaviors,
} from './Logo/Logo';

import {
  mediaSizes,
  mediaTypes,
  mediaThemes,
} from './Logo/Media';

import {
  editorInfoSizes,
} from './EditorInfo/EditorInfo';

import SimpleTool from './SimpleTool/SimpleTool';

import {
  toolsDropdownSizes,
  toolsDropdownPositions,
  toolsDropdownOffsets,
} from './Tools/Dropdown';

import SettingButton from './SettingButton';
import GridCellAndWrapper from './GridCellAndWrapper';

import {
  settingsBoxSizes,
  settingsBoxTypes,
  settingsBoxOffsets,
} from './SettingsBox/SettingsBoxBody';

import { thumbnailBodyThemes } from './PageThumbnail/PageThumbnailBody';
import { thumbnailContentTypes } from './PageThumbnail/PageThumbnailContent';
import { pageNavigationOffsets } from './PageNavigation/PageNavigationBody';
import { pageThumbnailLabelThemes, pageThumbnailLabelTypes } from './PageThumbnail/PageThumbnailLabel';
import { flexibleBarBodyOffsets, flexibleBarBodyJustifies } from './FlexibleBar/FlexibleBarBody';
import { flexibleListBodySizes, flexibleListBodyOffsets } from './FlexibleList/FlexibleListBody';
import {
  dialogBodyThemes,
  dialogBodyBehaviors,
  dialogBodySizes,
  dialogBodyOffsets,
} from './Dialog/DialogBody';
import { contentConfiguratorBodyThemes } from './ContentConfigurator/ContentConfiguratorBody';
import { entitledBoxBodyThemes, entitledBoxBodyOffsets } from './EntitledBox/EntitledBoxBody';
import {
  customFieldSizes,
  customFieldThemes,
  customFieldTypes,
  customFieldBottomOffsets,
  customFieldArrows,
} from './CustomField/CustomField';
import { sliderThemes } from './Slider/RangeSlider';

import {
  panelOffsetsBottom,
  panelOffsetsHorizontal,
  panelOffsetsTop,
  panelOffsetsVertical,
  panelThemes,
  panelTypes,
} from './Panel/PanelBody';

import {
  panelHeaderOffsets,
  panelHeaderSizes,
  panelHeaderThemes,
  panelHeaderTypes,
} from './PanelHeader/PanelHeader';

import Toggler, {
  togglerThemes,
  togglerOffsets,
  togglerSizes,
} from './Toggler';

import { customSettingsOffsets } from './CustomSettings/CustomSettingsBody';

import InputField, {
  inputFieldBehaviors,
  inputFieldOffsets,
  inputFieldSizes,
  inputFieldTags,
  inputFieldThemes,
  inputFieldWraps,
  inputFieldAligns,
} from './InputField';

import Checkbox, {
  checkboxDataAsteriskPositions,
  checkboxDataIndicators,
  checkboxSizes,
  checkboxThemes,
  checkboxTypes,
  checkboxOffsets,
} from './Checkbox';

import {
  radioGroupBehaviors,
  radioGroupOffsets,
  radioGroupTags,
  radioGroupTypes,
} from './RadioGroup/RadioGroup';

import RadioButton, {
  radioButtonDataIndicators,
  radioButtonThemes,
} from './RadioButton';

import {
  tabsThemes,
  tabsBehaviors,
} from './Tabs/Tabs';

import {
  sortableElementThemes,
  sortableElementSizes,
  sortableElementOffsets,
} from './SortableElement/SortableElement';

import {
  listSizes,
  listTypes,
  listOffsets,
  listThemes,
} from './List/List';

import {
  listConfiguratorSizes,
  listConfiguratorThemes,
} from './ListConfigurator/ListConfigurator';

import {
  fillableWizardHorizontalOffsets,
  fillableWizardVerticalOffsets,
} from './FillableWizard/FillableWizard';

import {
  sideBarSizes,
  sideBarThemes,
} from './SideBar/SideBarBody';

import FakeTextArea, {
  fakeTextAreaThemes,
  fakeTextAreaSizes,
  fakeTextAreaOffsets,
} from './FakeTextArea';

import ScrollBox, {
  scrollBoxSizes,
} from './ScrollBox';

import Pointer, {
  pointerSizes,
  pointerThemes,
  dataPointerDirections,
} from './Pointer';

import {
  counterThemes,
  counterBehaviors,
  counterOffsets,
} from './Counter/CounterBody';

import RequiredIndicator from './RequiredIndicator/RequiredIndicator';
import ImagePlaceholder from './ImagePlaceholder/ImagePlaceholder';

import attributes from './attributes';

import {
  textBoxOffsets,
  textBoxHorizontalOffsets,
  textBoxAligns,
  textBoxJustifies,
} from './TextBox/TextBoxBody';

const scrollBox = {
  sizes: scrollBoxSizes,
};

const pointer = {
  sizes: pointerSizes,
  themes: pointerThemes,
  dataPointerDirections,
};

const box = {
  themes: boxThemes,
};

const control = {
  themes: controlThemes,
  sizes: controlSizes,
  offsets: controlOffsets,
  behaviors: controlBehaviors,
  types: controlTypes,
  dataPointers: dataPointerTypes,
  boxIconTypes,
  boxIconSizes,
  justifies: controlJustifies,
  aligns: controlAligns,
  verticalAligns: controlVerticalAligns,
  roles: controlRoles,
  tags: controlTags,
  displays: controlDisplays,
};

const icon = {
  types: iconTypes,
  sizes: iconSizes,
  themes: iconThemes,
};

const text = {
  families: textFamilies,
  offsets: textOffsets,
  topOffsets: textTopOffsets,
  rightOffsets: textRightOffsets,
  bottomOffsets: textBottomOffsets,
  aligns: textAligns,
  weights: textWeights,
  transforms: textTransforms,
  lineHeights: textLineHeights,
  themes: textThemes,
  tags: textTags,
  writings: textWritings,
  sizes: textSizes,
  displays: textDisplays,
};

const label = {
  types: labelTypes,
  themes: labelThemes,
  sizes: labelSizes,
};

const switcher = {
  sizes: switcherSizes,
  themes: switcherThemes,
};

const editorAction = {
  offsets: editorActionOffsets,
  sizes: editorActionSizes,
  types: editorActionTypes,
};

const fillableActions = {
  themes: fillableActionsThemes,
  offsets: fillableActionsOffsets,
};

const toolBar = {
  sizes: toolbarSizes,
  themes: toolbarThemes,
  types: toolbarTypes,
  behaviors: toolbarBehaviors,
  itemTypes: toolbarItemTypes,
  offsets: toolbarOffsets,
};

const editorInfo = {
  sizes: editorInfoSizes,
};

const separator = {
  themes: separatorThemes,
  verticalOffsets: separatorVerticalOffsets,
  horizontalOffsets: separatorHorizontalOffsets,
  types: separatorTypes,
};

const grid = {
  sizes: gridSizes,
  offsets: gridOffsets,
  types: gridTypes,
  justifies: gridJustifies,
  separator: {
    sizes: gridSeparatorSizes,
    directions: gridSeparatorDirections,
  },
  cellGroup: {
    sizes: gridCellGroupSizes,
    types: gridCellGroupTypes,
  },
};

const menu = {
  itemsTypes: menuItemsTypes,
  itemsRoles: menuItemsRoles,
  bodyJustifies: menuBodyJustifies,
  bodyTypes: menuBodyTypes,
  bodyThemes: menuBodyThemes,
  bodySizes: menuBodySizes,
};

const colorPicker = {
  types: colorPickerTypes,
  offsets: colorPickerOffsets,
  itemTypes: colorPickerItemTypes,
  itemTags: colorPickerItemTags,
};

const group = {
  types: groupTypes,
  sizes: groupSizes,
  offsets: groupOffsets,
  offsetsHorizontal: groupOffsetsHorizontal,
  behaviors: groupBehaviors,
  aligns: groupAligns,
  justifies: groupJustifies,
};

const logo = {
  sizes: logoSizes,
  offsets: logoOffsets,
  behaviors: logoBehaviors,
};

const media = {
  sizes: mediaSizes,
  types: mediaTypes,
  themes: mediaThemes,
};

const counterBody = {
  themes: counterThemes,
  behaviors: counterBehaviors,
  offsets: counterOffsets,
};

const bottomSheet = {
  themes: bottomSheetThemes,
};

const tools = {
  dropdown: {
    sizes: toolsDropdownSizes,
    positions: toolsDropdownPositions,
    offsets: toolsDropdownOffsets,
  },
};

const settingsBox = {
  offsets: settingsBoxOffsets,
  sizes: settingsBoxSizes,
  types: settingsBoxTypes,
};

const pageThumbnail = {
  themes: thumbnailBodyThemes,
  contentTypes: thumbnailContentTypes,
};

const pageNavigation = {
  offsets: pageNavigationOffsets,
};

const flexibleBar = {
  offsets: flexibleBarBodyOffsets,
  justifies: flexibleBarBodyJustifies,
};

const pageThumbnailLabel = {
  themes: pageThumbnailLabelThemes,
  types: pageThumbnailLabelTypes,
};

const flexibleList = {
  sizes: flexibleListBodySizes,
  offsets: flexibleListBodyOffsets,
};

const dialog = {
  sizes: dialogBodySizes,
  themes: dialogBodyThemes,
  behaviors: dialogBodyBehaviors,
  offsets: dialogBodyOffsets,
};

const todoList = {
  sizes: todoListSizes,
};

const contentConfigurator = {
  themes: contentConfiguratorBodyThemes,
};

const popover = {
  sizes: popoverSizes,
  themes: popoverThemes,
  arrowPositions: popoverArrowPositions,
  offsets: popoverOffsets,
  offsetsHorizontal: popoverOffsetsHorizontal,
  offsetsVertical: popoverOffsetsVertical,
};

const popoverItem = {
  offsets: popoverItemOffsets,
  themes: popoverItemThemes,
};

const entitledBox = {
  themes: entitledBoxBodyThemes,
  offsets: entitledBoxBodyOffsets,
};

const customField = {
  sizes: customFieldSizes,
  themes: customFieldThemes,
  types: customFieldTypes,
  bottomOffsets: customFieldBottomOffsets,
  arrows: customFieldArrows,
};

const input = {
  themes: inputThemes,
  sizes: inputSizes,
  offsets: inputOffsets,
  types: inputTypes,
};

const panel = {
  offsetsBottom: panelOffsetsBottom,
  offsetsHorizontal: panelOffsetsHorizontal,
  offsetsTop: panelOffsetsTop,
  offsetsVertical: panelOffsetsVertical,
  themes: panelThemes,
  types: panelTypes,
};

const panelHeader = {
  offsets: panelHeaderOffsets,
  sizes: panelHeaderSizes,
  themes: panelHeaderThemes,
  types: panelHeaderTypes,
};

const toggler = {
  themes: togglerThemes,
  offsets: togglerOffsets,
  sizes: togglerSizes,
};

const customSettings = {
  offsets: customSettingsOffsets,
};

const inputField = {
  behaviors: inputFieldBehaviors,
  offsets: inputFieldOffsets,
  sizes: inputFieldSizes,
  tags: inputFieldTags,
  themes: inputFieldThemes,
  wraps: inputFieldWraps,
  aligns: inputFieldAligns,
};

const checkbox = {
  dataAsteriskPositions: checkboxDataAsteriskPositions,
  dataIndicators: checkboxDataIndicators,
  sizes: checkboxSizes,
  themes: checkboxThemes,
  types: checkboxTypes,
  offsets: checkboxOffsets,
};

const textareaAutocomplete = {
  themes: textareaAutocompleteThemes,
  sizes: textareaAutocompleteSizes,
};

const radioGroup = {
  behaviors: radioGroupBehaviors,
  offsets: radioGroupOffsets,
  tags: radioGroupTags,
  types: radioGroupTypes,
};

const radioButton = {
  dataIndicators: radioButtonDataIndicators,
  themes: radioButtonThemes,
};

const tabs = {
  themes: tabsThemes,
  behaviors: tabsBehaviors,
};

const sideBar = {
  sizes: sideBarSizes,
  themes: sideBarThemes,
};

const sortableElement = {
  sizes: sortableElementSizes,
  offsets: sortableElementOffsets,
  themes: sortableElementThemes,
};

const list = {
  sizes: listSizes,
  types: listTypes,
  offsets: listOffsets,
  themes: listThemes,
};

const listConfigurator = {
  sizes: listConfiguratorSizes,
  themes: listConfiguratorThemes,
};

const fillableWizard = {
  horizontalOffsets: fillableWizardHorizontalOffsets,
  verticalOffsets: fillableWizardVerticalOffsets,
};

const fakeTextArea = {
  themes: fakeTextAreaThemes,
  sizes: fakeTextAreaSizes,
  offsets: fakeTextAreaOffsets,
};

const slider = {
  themes: sliderThemes,
};

const Container = {
  GridCellAndWrapper,
  SettingButton,
};

const textBox = {
  offsets: textBoxOffsets,
  horizontalOffsets: textBoxHorizontalOffsets,
  aligns: textBoxAligns,
  justifies: textBoxJustifies,
};

export {
  box,
  icon,
  control,
  editorAction,
  toolBar,
  grid,
  text,
  label,
  switcher,
  menu,
  colorPicker,
  fillableActions,
  attributes,
  group,
  logo,
  media,
  editorInfo,
  bottomSheet,
  tools,
  pageThumbnail,
  pageNavigation,
  flexibleBar,
  pageThumbnailLabel,
  flexibleList,
  dialog,
  contentConfigurator,
  popover,
  popoverItem,
  entitledBox,
  customField,
  input,
  settingsBox,
  panel,
  panelHeader,
  toggler,
  customSettings,
  inputField,
  checkbox,
  radioGroup,
  radioButton,
  tabs,
  sideBar,
  todoList,
  sortableElement,
  separator,
  list,
  listConfigurator,
  fillableWizard,
  fakeTextArea,
  slider,
  textareaAutocomplete,
  scrollBox,
  pointer,
  textBox,
  counterBody,

  locales,

  Box,
  FillableActions,
  FillableField,
  ColorPicker,
  Popover,
  PopoverItem,
  Menu,
  Slider,
  Grid,
  Group,
  ToolBar,
  Logo,
  Icon,
  Text,
  Label,
  SettingBar,
  Separator,
  Switcher,
  Control,
  ControlBar,
  EditorAction,
  Container,
  EditorInfo,
  BottomSheet,
  Tools,
  Dialog,
  FlexibleBar,
  ContentConfigurator,
  FlexibleList,
  PageThumbnail,
  PageNavigation,
  EntitledBox,
  CustomField,
  Input,
  SettingsBox,
  SimpleTool,
  Panel,
  PanelHeader,
  Toggler,
  CustomSettings,
  InputField,
  TextareaAutocomplete,
  Checkbox,
  RadioGroup,
  RadioButton,
  Tabs,
  SideBar,
  ScrollBox,
  SortableElement,
  List,
  TodoList,
  ListConfigurator,
  FillableWizard,
  TextBox,
  FakeTextArea,
  RightSidebar,
  RequiredIndicator,
  ImagePlaceholder,
  Pointer,
  Counter,
};
