import includes from 'lodash/includes';
import get from 'lodash/get';
import findIndex from 'lodash/findIndex';
import some from 'lodash/some';
import moment from 'moment';

import {
  elemTypes,
  elemSubTypes,
  dateTypes,
  isTextToolBasedElement,
  getSignatureSubtype,
} from '../../helpers/elemTypes';
import {
  textToolArrangements,
  textFieldViewModes,
  defaultDateFormat,
} from '../../helpers/const';
import { formatDate } from '../../helpers/utils';
import { getContentXY } from '../../helpers/getContentXY';

export const isEnabledElement = (element) => {
  return element.enabled === undefined
    ? true
    : element.enabled;
};

export const isFillable = (elem) => {
  return !!elem.template;
};

export const isFormulaElement = (elem) => {
  return isFillable(elem) && elem.hasOwnProperty('subType') && (
    elem.subType === elemSubTypes[elemTypes.text].formula ||
    Boolean(elem.template.formula)
  );
};

export const isElement = (elem) => {
  return elem.type && elem.subType && elem.id &&
  elem.pageId !== null && elem.pageId !== undefined;
};

export const isDate = (elem) => {
  return (elem.hasOwnProperty('type') && elem.hasOwnProperty('subType') &&
  elem.type === elemTypes.text &&
  includes(dateTypes, elem.subType)) || false;
};

export const isCells = (elem) => {
  return (elem.hasOwnProperty('type') && elem.hasOwnProperty('subType') &&
  elem.type === elemTypes.text &&
  elem.template.arrangement === textToolArrangements.cells) ||
  false;
};

export const isNumber = (elem) => {
  return (elem.hasOwnProperty('type') && elem.hasOwnProperty('subType') &&
  elem.type === elemTypes.text &&
  elem.subType === elemSubTypes.text.number) || false;
};

export const isSignatureInitials = (elem) => {
  return (elem.hasOwnProperty('type') && elem.hasOwnProperty('subType') &&
  elem.type === elemTypes.signature &&
  getSignatureSubtype(elem) === elemSubTypes.signature.initials) || false;
};

export const isSignatureCurve = (elem) => {
  return (elem.hasOwnProperty('type') && elem.hasOwnProperty('subType') &&
  elem.type === elemTypes.signature &&
  getSignatureSubtype(elem) === elemSubTypes.signature.curve) || false;
};

export const isSignatureText = (elem) => {
  return elem.hasOwnProperty('type') && elem.hasOwnProperty('subType') &&
  elem.type === elemTypes.signature &&
  getSignatureSubtype(elem) === elemSubTypes.signature.text;
};

export const isSticky = (elem) => {
  return elem.hasOwnProperty('type') && elem.hasOwnProperty('subType') &&
  elem.type === elemTypes.sticky && elem.subType === elemSubTypes.none;
};

export const isTextBox = (elem) => {
  return elem.hasOwnProperty('type') && elem.hasOwnProperty('subType') &&
  elem.type === elemTypes.textbox && elem.subType === elemSubTypes.none;
};

export const isElementWithContent = (elem) => {
  return Boolean(elem && elem.content);
};

export const byType = (type) => {
  return (elem) => {
    return elem.type === type;
  };
};

export const isErase = (elem) => {
  return byType(elemTypes.erase)(elem);
};

export const isSignature = (elem) => {
  return byType(elemTypes.signature)(elem);
};

export const isText = (elem) => {
  return byType(elemTypes.text)(elem);
};

export const isSalesforce = (elem) => {
  return byType(elemTypes.salesforce)(elem);
};

export const isSmart = (elem) => {
  return byType(elemTypes.smart)(elem);
};

export const isIntergation = (elem) => {
  return isSalesforce(elem) || isSmart(elem);
};

export const isUnfilledFillableText = (element) => {
  return (
    isFillable(element) &&
    isText(element) &&
    !get(element, 'content.text', false)
  );
};

export const isPicture = (elem) => {
  return byType(elemTypes.image)(elem);
};

export const isCheckmarkElement = (elem) => {
  return byType(elemTypes.checkmark)(elem);
};
export const isFillableCheckmarkElement = (elem) => {
  return isCheckmarkElement(elem) && isFillable(elem);
};

export const isDropdownElement = (elem) => {
  return (elem.hasOwnProperty('type') && elem.hasOwnProperty('subType') &&
  elem.type === elemTypes.text &&
  elem.subType === elemSubTypes.text.dropdown) || false;
};

export const isDropdownElementWithCustomValueSelected = (element) => {
  return (
    isDropdownElement(element) &&
    get(element, 'content.customDropdownValueSelected', false)
  );
};

// TODO: write test
export const isRadioElement = (elem) => {
  return byType(elemTypes.radio)(elem);
};
export const isUnfilledFillableRadio = (element) => {
  return (
    isFillable(element) &&
    isRadioElement(element) &&
    !get(element, 'content.checked', false)
  );
};

export const isAttachmentElement = (elem) => {
  return byType(elemTypes.attachment)(elem);
};

export const isFilledTextElement = (elem) => {
  return (
    isElementWithContent(elem) &&
    isText(elem) &&
    elem.content.text !== null && elem.content.text !== undefined &&
    elem.content.text.trim && elem.content.text.trim() !== ''
  ) || false;
};

export const isFilledDropdownElement = (elem) => {
  return isDropdownElement(elem) && !!get(elem, 'content.text', false);
};

export const isFilledCheckmarkElement = (elem) => {
  return (
    isElementWithContent(elem) &&
    isCheckmarkElement(elem) &&
    elem.content.checked !== undefined
  ) || false;
};

export const isFilledSignatureElement = (elem) => {
  return (
    isElementWithContent(elem) &&
    isSignature(elem) &&
    elem.content.subType && !(
      elem.content.subType === elemSubTypes.none ||
      elem.content.subType === elemSubTypes.signature.initials
    ) &&
    true
  ) || false;
};

export const isFilledPictureElement = (elem) => {
  return (
    isElementWithContent(elem) &&
    isPicture(elem) &&
    elem.content.url &&
    true
  ) || false;
};

export const isFilledAttachmentElement = (elem) => {
  if (
    isElementWithContent(elem) &&
    isAttachmentElement(elem) &&
    elem.content.fileId
  ) {
    return true;
  }
  return false;
};

export const isFilledRadioElement = (elem) => {
  if (
    isElementWithContent(elem) &&
    isRadioElement(elem)
  ) {
    return true;
  }
  return false;
};

export const isAnyContentCheckedForRadioGroup = (elementsArray) => {
  return some(elementsArray, (element) => {
    return isFilledRadioElement(element) && element.content.checked;
  });
};

// single-line text fillable element might expand width on input
export const isExpandableFillable = (element) => {
  return (
    isFillable(element) &&
    (isText(element) || isNumber(element)) &&
    !isSignature(element)
  );
};

export const getElementProps = (element, textFieldViewMode) => {
  return {
    ...(element.content || {}),
    ...(element.template
      ? {
        ...element.template,
        ...(element.content && element.content.text && { text: element.content.text }),
        ...(isFilledSignatureElement(element) && { fontSize: element.content.fontSize }),
      }
      : {}),
    isDisabled: !isEnabledElement(element) || isFormulaElement(element),
    viewMode: get(element, 'template.viewMode', textFieldViewMode),
  };
};

export const getElementToolbarData = (element, textFieldViewMode) => {
  const isElementWithCells = element.template && isCells(element);

  return {
    isFillable: isFillable(element),
    isSticky: isSticky(element),
    isTextBox: isTextBox(element),
    isTextToolBasedElement: isTextToolBasedElement(element),
    isSignature: isSignature(element),
    pageId: element.pageId,
    type: element.type,
    viewMode: isSignature(element) || isElementWithCells
      ? textFieldViewModes.classic
      : get(element, 'template.viewMode', textFieldViewMode),
  };
};

// for elements which are isExpandableFillable
export const getExpandableElementProps = ({
  element,
  isConstructorMode,
  isActiveElement,
  textFieldViewMode,
}) => {
  const elementProps = getElementProps(element, textFieldViewMode);
  const isExpandableByViewMode = (
    (elementProps.viewMode === textFieldViewModes.combo && isActiveElement) ||
    elementProps.viewMode === textFieldViewModes.stretch
  );

  if (element.isMemberOfFlatGroup) {
    return {
      ...elementProps,
      viewMode: textFieldViewModes.classic,
    };
  }

  if (
    !isExpandableFillable(element) ||
    !isExpandableByViewMode ||
    isConstructorMode
  ) {
    return elementProps;
  }

  return {
    ...elementProps,
    width: get(element, 'content.width') || get(element, 'template.width'),
    height: get(element, 'content.height') || get(element, 'template.height'),
  };
};

export const byRadioGroupId = (radioGroupId) => {
  return (elem) => {
    return isRadioElement(elem) && elem.template.radioGroupId === radioGroupId;
  };
};

export const byPage = (pageId) => {
  return (elem) => {
    return elem.pageId !== null && elem.pageId !== undefined && elem.pageId === pageId;
  };
};

export const byId = (id) => {
  return (elem) => {
    return elem.id === id;
  };
};

export const byFilled = (filled) => {
  return (el) => {
    return (isDropdownElement(el) && isFilledDropdownElement(el) === filled) ||
    (isTextToolBasedElement(el) && isFilledTextElement(el) === filled) ||
    (isSignature(el) && isFilledSignatureElement(el) === filled) ||
    (isPicture(el) && isFilledPictureElement(el) === filled) ||
    (isCheckmarkElement(el) && isFilledCheckmarkElement(el) === filled) ||
    (isAttachmentElement(el) && isFilledAttachmentElement(el) === filled) ||
    (isRadioElement(el) && isFilledRadioElement(el) === filled) ||
    false;
  };
};

export const isFilledElement = (elem) => {
  return byFilled(true)(elem);
};

export const isUnfilledElement = (elem) => {
  return byFilled(false)(elem);
};

export const isUnfilledFillableDate = (element) => {
  return (
    isFillable(element) &&
    isDate(element) &&
    !isFilledElement(element)
  );
};

export const isUnfilledFillableCheckmark = (element) => {
  return (
    isFillable(element) &&
    isCheckmarkElement(element) &&
    !isFilledElement(element)
  );
};

export const isFilledFillableAttachment = (element) => {
  return (
    isFillable(element) &&
    isAttachmentElement(element) &&
    isFilledElement(element)
  );
};
export const isUnfilledFillableAttachment = (element) => {
  return (
    isFillable(element) &&
    isAttachmentElement(element) &&
    !isFilledElement(element)
  );
};

export const getElementXYRightBottom = (elem) => {
  return ((props) => {
    return {
      y: props.y || 0,
      x: props.x || 0,
      right: (props.x || 0) + (props.width || 0),
      bottom: (props.y || 0) + (props.height || 0),
    };
  })(getExpandableElementProps({ element: elem, isConstructorMode: false, isActiveElement: true }));
};

export const isValidDropdownElement = (elem) => {
  return (
    get(elem, 'template.list', []).length > 0
  );
};

export const isValidRadioElement = (elem) => {
  return (
    get(elem, 'template.radioValue', '').trim().length > 0
  );
};

export const isCustomDropdownValueSelected = (elem) => {
  const { template: { list, allowCustomText } } = elem;
  const text = get(elem, 'content.text');
  return Boolean(text) && allowCustomText && findIndex(list, ['label', text]) === -1;
};

export const getCroppedPositionByFrameSize = ({ element, croppedPosition }) => {
  return {
    template: {
      ...element.template,
      ...croppedPosition,
    },
    ...(element.content && {
      content: {
        ...element.content,
        ...croppedPosition,
      },
    }),
  };
};

export const getCellQuantity = (width) => {
  return Math.round(width / 20);
};

export const isNeedToPasteMaxChars = (element) => {
  return element.template.arrangement === 'cells' && element.template.maxChars === '';
};

export const pasteMaxCharsInCells = (elementTemplate) => {
  return {
    ...elementTemplate,
    maxChars: getCellQuantity(elementTemplate.width),
  };
};

export const isNeedToPasteName = (element) => {
  return get(element, 'template.name', '') === '';
};

export const isNeedToPastePrompt = (element) => {
  return get(element, 'template.prompt', '') === '';
};

export const pastePreviousName = (elementTemplate, previousElementTemplate) => {
  return {
    ...elementTemplate,
    name: previousElementTemplate.name,
  };
};

export const pastePreviousPrompt = (elementTemplate, previousElementTemplate) => {
  return {
    ...elementTemplate,
    prompt: previousElementTemplate.prompt,
  };
};

export const isRequiredElement = (element) => {
  return get(element, 'template.required', false);
};

export const isOptionalElement = (element) => {
  return !isRequiredElement(element);
};

export const momentConverter = ({ oldDate, oldFormat, newFormat }) => {
  const dateObj = moment(oldDate, oldFormat.toUpperCase());
  if (newFormat) {
    return moment(
      [dateObj.year(), dateObj.month(), dateObj.date()],
    ).format(newFormat.toUpperCase());
  }
  return dateObj;
};

export const isInitialPropContentDate = ({ date }) => {
  return Array.from(date).some((el) => {
    return !Number.isNaN(parseInt(el, 10));
  });
};

export const isFillableElement = (element) => {
  return element.hasOwnProperty('template');
};
export const isDisabledElement = (element, roleId) => {
  const allowEditing = get(element, 'template.allowEditing', false);
  const readonly = get(element, 'template.readonly', false);
  return (
    element.enabled === false ||
    readonly === true ||
    (Array.isArray(allowEditing) && allowEditing.indexOf(roleId) === -1)
  );
};

export const isLinkedElement = (element) => {
  return get(element, 'content.linkedEraseId', false);
};

export const isUnfilledPictureOrSign = (element) => {
  if (!isFillable(element)) {
    return false;
  }
  if (isPicture(element)) {
    return !isFilledPictureElement(element);
  }
  if (isSignature(element)) {
    return !isFilledSignatureElement(element);
  }
  return false;
};

export const isVisibleElement = (element) => {
  return get(element, 'content.visible') !== false;
};

export const isElementFromDeletedPage = (elementPageId, deletedPages) => {
  return deletedPages.some((deletedPageId) => {
    return deletedPageId === elementPageId;
  });
};

export const isElementHaveToolbar = (elem) => {
  if (!elem) {
    return false;
  }

  const isFillableElem = isFillable(elem);
  if (!isFillableElem || isFilledSignatureElement(elem)) {
    return true;
  }

  return false;
};

export const isFConstructorElementHaveToolbar = (elem) => {
  return elem && !isSignature(elem) && !isPicture(elem);
};

export const getDateStampObject = (useSigDateStamp) => {
  return useSigDateStamp
    ? { dateStamp: formatDate(new Date(), defaultDateFormat) }
    : {};
};

export const getSignToReuseContentToUpdate = (signToReuseContent, element, useSigDateStamp) => {
  if (signToReuseContent.subType === elemSubTypes.signature.text) {
    return {
      ...signToReuseContent,
      valign: 'bottom',
      width: element.template.width,
      height: element.template.height,
      ...getDateStampObject(useSigDateStamp),
    };
  }

  const elementWithSignToReuseContent = { ...element, content: signToReuseContent };

  return {
    ...signToReuseContent,
    ...getContentXY(elementWithSignToReuseContent),
    ...getDateStampObject(useSigDateStamp),
  };
};

export const getIsSignatureSingleUse = (element) => {
  return get(element, 'template.singleUse', false);
};
