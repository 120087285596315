import PropTypes from 'prop-types';
import React from 'react';
import classnames from 'classnames';

const TodoListItem = ({ children, onClick, required, done, active, tabIndex }) => {
  return (
    <li
      className="list__item"
      onClick={onClick}
    >
      <div
        className={classnames(
          'todo-item', {
            'is-required': required,
            'is-crossed': done,
            'is-highlighted': active,
          },
        )}
        role="button"
        tabIndex={tabIndex}
      >
        <div className="todo-item__body">
          {children}
        </div>
      </div>
    </li>
  );
};

TodoListItem.propTypes = {
  children: PropTypes.node,
  onClick: PropTypes.func,
  required: PropTypes.bool,
  done: PropTypes.bool,
  active: PropTypes.bool,
  tabIndex: PropTypes.number,
};

TodoListItem.defaultProps = {
  children: null,
  required: false,
  done: false,
  active: false,
  onClick: null,
  tabIndex: 0,
};

export default TodoListItem;
