import {
  isInputOrTextarea,
  isContentEditable,
} from '../focusUtils';
import {
  c2eForContentEditable,
  c2eForInputInIE11,
  c2eForInputDefault,
} from './c2eUtils';

const contentEditable = {
  check: isContentEditable,
  fn: c2eForContentEditable,
};

const inputTextareaForIE11 = {
  check: (node, { isInternetExplorer11 }) => {
    if (!isInternetExplorer11) return false;
    if (isInputOrTextarea(node)) return true;

    return false;
  },
  fn: c2eForInputInIE11,
};

const inputTextareaDefault = {
  check: (node, { isInternetExplorer11 }) => {
    if (isInternetExplorer11) return false;
    if (isInputOrTextarea(node)) return true;

    return false;
  },
  fn: c2eForInputDefault,
};

const caret2EndMap = [
  contentEditable,
  inputTextareaForIE11,
  inputTextareaDefault,
];

/**
 * Элемент может стать активен 2-я путями:
 * 1. Кликом по нему
 * 2. Какое-то стороннее событие
 *
 * При клике по элементу каретка встанет туда, куда ткнул пользователь
 * Но при наступлении какого-то стороннего события мы хотиим ставить каретку
 * в самый конец.
 *
 * Этот модуль решает эту проблему.
 *
 * На самом деле довольно сложно отличить 1 и 2
 * Поэтому мы используем следующий хак:
 * - Когда происходит событие onFocus вызываем фукнцию remember(id)
 * - Когда происходит событие onBlur вызываем фукнцию remember(null)
 *
 * Сам вызов "каретку в конец" нужно делать после установки фокуса ref.focus()
 * Поэтому до ref.focus() вызываем getCallback
 *
 * И тогда может быть 2 варианта развития событий, первый начинается с onFocus:
 * onFocus(id)
 * remember(id)
 * focusToRef(id)
 * cb = getCallback(id)
 * ref.focus()
 * cb() -> nothing так как elementWithFocus === activeElementId
 *
 * Второй не начинается с него:
 * componentDidUpdate(prevProps)
 * focus(id)
 * focusToRef(id)
 * cb = getCallback(id)
 * ref.focus()
 * cb() -> caret2end так как elementWithFocus !== activeElementId,
 * т.к. не было вызова remember
 */
const initializeCaret2End = (thisDevice) => {
  let elementWithFocus = null;

  const remember = (id) => {
    elementWithFocus = id;
  };

  const doCaret2End = (ref) => {
    caret2EndMap.forEach((c2e) => {
      if (c2e.check(ref, thisDevice)) {
        c2e.fn(ref);
      }
    });
  };

  const getCallback = (activeElementId, ref) => {
    if (elementWithFocus !== activeElementId) {
      return () => {
        doCaret2End(ref);
      };
    }

    return () => {
    };
  };

  return {
    remember,
    getCallback,
    doCaret2End,
  };
};

export default initializeCaret2End;
