import PropTypes from 'prop-types';
import React, { Component } from 'react';
import cls from 'classnames';

export const bottomSheetThemes = {
  flatWhite: 'flat-white',
  null: null,
};

export default class BottomSheet extends Component {
  static propTypes = {
    children: PropTypes.node,

    theme: PropTypes.oneOf(
      Object.values(bottomSheetThemes),
    ),
  };

  static defaultProps = {
    children: null,

    theme: bottomSheetThemes.null,
  };

  render() {
    const { theme } = this.props;

    return (
      <div
        className={
          cls(
            'bottom-sheet', {
              [`bottom-sheet--theme--${theme}`]: theme,
            },
          )
        }
      >
        {this.props.children}
      </div>
    );
  }
}
