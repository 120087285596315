import PropTypes from 'prop-types';
import React, { Component } from 'react';

export default class SliderContentCell extends Component {
  static propTypes = {
    children: PropTypes.node,

    onTouchStart: PropTypes.func,
    onTouchEnd: PropTypes.func,
  };

  static defaultProps = {
    children: null,

    onTouchStart: null,
    onTouchEnd: null,
  };

  render() {
    return (
      <div
        className="slide-content__cell"
        onTouchStart={this.props.onTouchStart}
        onTouchEnd={this.props.onTouchEnd}
      >
        {this.props.children}
      </div>
    );
  }
}
