import React, { Component } from 'react';
import PropTypes from 'prop-types';
import cls from 'classnames';

/*
Эти иконки хранятся на серверной локали (locale.editor.assistant.tools[toolId]),
поэтому их можно не найти в проекте, но они нужны для propTypes.
*/
const localeIconTypes = {
  // иконки для basic тулов
  checkboxNew: 'checkbox-new',
  dateNew: 'date-new',
  dropdownNew: 'dropdown-new',
  formulaBordered: 'formula-bordered',
  picture: 'picture',
  initialsBordered: 'initials-bordered',
  numberNew: 'number-new',
  redesignRadioButton: 'redesign--radio-button',
  signNew: 'sign-new',
  textNew: 'text-new',
  // иконки для additional тулов
  redesignCake: 'redesign--cake',
  redesignCard: 'redesign--card',
  redesignCompany: 'redesign--company',
  redesignEin: 'redesign--ein',
  redesignEmail24: 'redesign--email-24',
  redesignEur: 'redesign--eur',
  redesignGender: 'redesign--gender',
  redesignSquaredUser: 'redesign--squared-user',
  redesignPhone: 'redesign--phone',
  redesignBuild: 'redesign--build',
  redesignFlag: 'redesign--flag',
  redesignUserInfo: 'redesign--user-info',
  redesignUsd: 'redesign--usd',
  redesignPost: 'redesign--post',
};

const uiIconTypes = {
  redesignResend: 'redesign--resend',
  clear: 'redesign--clear',
  settings: 'settings',
  save: 'redesign--save',
  user: 'user',
  addUser: 'add-user',
  fountainPen: 'fountain-pen',
  pencil: 'pencil',
  clock: 'clock',
  check: 'check',
  record: 'record',
  navigateDown: 'navigate-down',
  paperclip: 'paperclip',
  help: 'help',
  move: 'redesign--move',
  moveLeft: 'move-left',
  moveRight: 'move-right',
  moveTo: 'move-to',
  delete: 'delete',
  checkWide: 'check-wide',
  upload: 'upload',
  todo: 'todo',
  coloredTodoSticky: 'colored--todo-sticky',
  coloredSignNow: 'colored--sign-now',
  coloredPointerLeft: 'colored--pointer-left',
  coloredPointerUp: 'colored--pointer-up',
  coloredSmiling: 'colored--smiling',
  copy: 'copy',
  menu: 'menu',
  plus: 'plus',
  trash: 'redesign--trash-can',
  trashBin: 'trash-bin',
  undo: 'undo-new',
  redo: 'redo-new',
  pageRearrange: 'page-rearrange-new',
  pages: 'pages',
  pageAdd: 'page-add',
  alertBubble: 'alert-bubble',
  feedback: 'feedback-new',
  circledQuestion: 'circled-question',
  circledQuestionNew: 'circled-question-new',
  sticky: 'redesign--sticky',
  draw: 'redesign--draw',
  line: 'redesign--line',
  arrowUpright: 'redesign--arrow-upright-24',
  arrowDownleft: 'redesign--arrow-downleft',
  arrowRightShort: 'arrow-right-short',
  arrowUprightDownleft: 'redesign--arrow-upright-downleft',
  textbox: 'redesign--textbox',
  blackout: 'redesign--blackout',
  highlight: 'redesign--highlight',
  erase: 'redesign--erase',
  doubleArrowRight: 'redesign--double-arrow-right',
  doubleArrowLeft: 'redesign--double-arrow-left',
  arrowRight: 'redesign--arrow-right',
  arrowRightOld: 'arrow-right',
  redesignPicture: 'redesign--picture',
  date: 'redesign--date',
  sign: 'redesign--sign',
  circle: 'redesign--circle',
  cross: 'redesign--cross',
  text: 'redesign--text',
  fillableFields: 'fillable-fields',
  redesignFillableFields24: 'redesign--fillable-fields-24',
  angleThinRight: 'angle-thin-right',
  documentPreview: 'redesign--document-preview',
  coloredPageSettings: 'colored--page-settings',
  checkMarkThin: 'check-mark-thin',
  checkMark: 'redesign--check-mark-24',
  checkMarkOld: 'check-mark',
  caretDown: 'caret-down',
  caretUp: 'caret-up',
  close: 'close',
  search: 'search-new',
  sms: 'redesign--sms',
  closeNew: 'close-new',
  smsThin: 'sms-thin',
  link: 'redesign--link-24',
  textAlignTop: 'redesign--text-align-top',
  textAlignCenter: 'redesign--text-align-center',
  textAlignRight: 'redesign--text-align-right',
  textAlignLeft: 'redesign--text-align-left',
  textAlignMiddle: 'redesign--text-align-middle',
  textAlignBottom: 'redesign--text-align-bottom',
  square: 'square',
  paint: 'redesign--paint',
  color: 'color',
  letterUnderline: 'redesign--letter-underline-24',
  letterItalic: 'redesign--letter-italic-24',
  letterBold: 'redesign--letter-bold-24',
  searchText: 'search-text',
  watermark: 'redesign--watermark-24',
  spellingActive: 'redesign--spelling-switch-active',
  spelling: 'redesign--spelling-switch',
  commentSwitch: 'redesign--comment-switch',
  commentSwitchActive: 'redesign--comment-switch-active',
  fakeEditSwitch: 'redesign--edit-source-switch',
  fakeEditSwitchActive: 'redesign--edit-source-switch-active',
  cogwheel: 'cogwheel',
  arrowLeft: 'redesign--arrow-left',
  arrowLeftOld: 'arrow-left',
  textReduce: 'redesign--text-reduce',
  textGrow: 'redesign--text-grow',
  stamp: 'stamp',
  angleUp: 'angle-up',
  angleDown: 'angle-down',
  skip: 'skip',
  checkbox: 'redesign--checkbox',
  print: 'redesign--print',
  share: 'redesign--share',
  email: 'email',
  fax: 'fax',
  s2s: 'redesign--sendtosign',
  l2f: 'redesign--linktofill',
  return: 'redesign--return',
  pageZoom: 'page-zoom',
  graphicGrow: 'graphic-grow',
  graphicReduce: 'graphic-reduce',
  prevSquare: 'prev-square',
  nextSquare: 'next-square',
  keyboard: 'keyboard',
  checklist: 'colored--checklist',
  eye: 'eye',
  magicWand: 'magic-wand',
  info: 'info',
  documentEmpty: 'document-empty',
  documentDecline: 'document-decline',
  signnow: 'signnow',
  rotateRight: 'rotate-right',
  irs: 'irs',
  notarize: 'notarize',
  field: 'field',
  order: 'order',
  sampleTextHalfQuarterTurn: 'sample-text-half-quarter-turn',
  sampleTextHalfTurn: 'sample-text-half-turn',
  sampleText: 'sample-text',
  dragHandler: 'drag-handler',
  positionTopLeft: 'position-top-left',
  positionTopCenter: 'position-top-center',
  positionTopRight: 'position-top-right',
  positionBottomLeft: 'position-bottom-left',
  positionBottomCenter: 'position-bottom-center',
  positionBottomRight: 'position-bottom-right',
  coloredLockOpened: 'colored--lock-opened',
  coloredLock: 'colored--lock',
  dropdown: 'dropdown',
  number: 'redesign--number',
  coloredWarningCircled: 'colored--warning-circled',
  historyClock: 'redesign--history-clock-24',
  radioButton: 'radio-button',
  circledExclamationThin: 'circled-exclamation-thin',
  coloredAutofill: 'colored--autofill',
  dots: 'dots',
  calculator: 'calculator',
  penEdit: 'pen-edit',
  calendar: 'calendar-new',
  hash: 'hash-new',
  arrowDown: 'arrow-down',
  formula: 'redesign--formula',
  sendToSign: 'sendtosign-new',
  image: 'redesign--image',
  initials: 'initials-new',
  wizardFull: 'redesign--wizard-switch-active',
  wizardLite: 'redesign--wizard-switch',
  shortArrowUp: 'short-arrow-up',
  shortArrowDown: 'short-arrow-down',
  redesignDate: 'redesign-date',
  redesignDropdown: 'redesign--dropbox',
  ok: 'redesign--ok',
  textColor: 'redesign--text-color',
  longArrowLeftNew: 'long-arrow-left-new',
  options: 'options',
  plusNew: 'plus-new',
  lockNew: 'lock-new',
  unlockNew: 'unlock-new',
  crossNew: 'cross-new',
  checkmarkNew: 'checkmark-new',
  circleNew: 'circle-new',
  annotate: 'annotate',
  imageNew: 'image-new',
  formulaNew: 'formula-new',
  redesignColoredChat: 'redesign--colored--chat',
  redesignClose: 'redesign--close',
};

export const iconTypes = {
  ...localeIconTypes,
  ...uiIconTypes,
};

export const iconSizes = {
  medium: 'medium',
  small: 'small',
  extraSmall: 'extra-small',
  extraLarge: 'extra-large',
  parent: 'parent',
  large: 'large',
  largeA4: 'large-a4',
  huge: 'huge',
  xsmall: 'xsmall',
};

export const iconThemes = {
  darkgray: 'darkgray',
  dovegray: 'dovegray',
  lightgray: 'lightgray',
  null: null,
};

export default class Icon extends Component {
  static propTypes = {
    type: PropTypes.oneOf(
      Object.values(iconTypes),
    ),
    size: PropTypes.oneOf(
      Object.values(iconSizes),
    ),
    theme: PropTypes.oneOf(
      Object.values(iconThemes),
    ),
    style: PropTypes.shape({}),
  };

  static defaultProps = {
    type: iconTypes.settings,
    size: iconSizes.large,
    theme: iconThemes.null,
    style: null,
  };

  render() {
    const { type, size, theme, style } = this.props;

    return (
      <span
        className={
          cls('icon', {
            [`icon--size--${size}`]: size,
            [`icon--theme--${theme}`]: theme,
          })
        }
        style={style}
        aria-hidden="true"
      >
        <svg className="icon__pic">
          <use xlinkHref={`#${type}`} />
        </svg>
      </span>
    );
  }
}
