import get from 'lodash/get';
import { GROUPS, EDIT_TYPES } from '../../constants';

/**
 * In fConstructor preview mode we don't send operations when user fill fillables
 */
// eslint-disable-next-line
export const getFilteredOperations = (operations, state) => {
  const wsSnapshot = get(state, 'ws.wsSnapshot', false);
  if (!wsSnapshot) {
    return operations;
  }

  return operations.filter((operation) => {
    return operation.properties.group !== GROUPS.tools;
  });
};

export const getIsAirSlate = (state) => {
  const { editType } = state.query;

  return editType === EDIT_TYPES.as;
};
