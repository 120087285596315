import PropTypes from 'prop-types';
import { Component } from 'react';
import throttle from 'lodash/throttle';

export default class DetectScrollProvider extends Component {
  static propTypes = {
    getRef: PropTypes.func.isRequired,
    onScroll: PropTypes.func.isRequired,
    timeout: PropTypes.number,
  };

  static defaultProps = {
    timeout: 200,
  };

  constructor(props) {
    super(props);
    this.lastRect = null;
  }

  componentDidMount() {
    document.addEventListener('scroll', this.proxyScroll, true);
    const element = this.props.getRef();
    if (element instanceof HTMLElement) {
      this.lastRect = element.getBoundingClientRect();
    }
  }

  componentWillUnmount() {
    document.removeEventListener('scroll', this.proxyScroll, true);
  }

  proxyScroll = throttle(
    (event) => {
      const element = this.props.getRef();
      if (event.target.contains(element)) {
        const newPos = element.getBoundingClientRect();
        if (!this.lastRect ||
          newPos.left !== this.lastRect.left ||
          newPos.top !== this.lastRect.top
        ) {
          this.props.onScroll(event);
        }
        this.lastRect = newPos;
      }
    },
    this.props.timeout,
  );

  render() {
    return null;
  }
}
