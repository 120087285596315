import { all, put, takeEvery } from 'redux-saga/effects';
import { activateFCMode } from 'ws-editor-lib/actions';
import { CONSTRUCTOR_SHOW } from 'jsfcore/store/modules/viewport';

function* onConstructorShow({ initial }) {
  yield put(activateFCMode(initial));
}

function* watchOnConstructorShow() {
  yield takeEvery(CONSTRUCTOR_SHOW, onConstructorShow);
}

export default function* changeFCModeSaga() {
  yield all([
    watchOnConstructorShow(),
  ]);
}
