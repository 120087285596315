import PropTypes from 'prop-types';
import React, { Component } from 'react';

export default class FlexRow extends Component {
  static propTypes = {
    children: PropTypes.node,
  };

  static defaultProps = {
    children: null,
  };

  render() {
    return (
      <div className="flex-grid__row">
        {this.props.children}
      </div>
    );
  }
}
