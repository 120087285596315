import { select } from 'redux-saga/effects';
import elementSelectors from '../selectors/elementsSelectors';

export default function getChangedElementsIsEnabledByDepFactory() {
  let cache = {};

  return function* getChangedElementsIsEnabledByDep() {
    const newCache = {};
    const elements = yield select(elementSelectors.getElements);
    const changedElementsIsEnabledByDep = [];

    for (let i = 0; i < elements.length; i++) {
      const element = elements[i];
      const { isEnabledByDependencies } = element;
      if (isEnabledByDependencies !== undefined) {
        newCache[element.id] = isEnabledByDependencies;
        if (
          cache[element.id] !== undefined &&
          cache[element.id] !== newCache[element.id]
        ) {
          changedElementsIsEnabledByDep.push(element);
        }
      }
    }
    cache = newCache;

    return changedElementsIsEnabledByDep;
  };
}
