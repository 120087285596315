import locales from './locales';

export { locales };
export const helpHref = 'https://www.signnow.com/features/add-calculated-fields';
export const startSymbol = '{';
export const endSymbol = '}';
export const splitter = '_splitter_';
export const snfPrefix = 'snfPrefix';
export const recursionFormulaSign = '__{-1}__';

const badSymb = [
  ' ',
  '#',
  '(',
  ')',
  '&',
  '\'',
  '-',
  ':',
  '?',
  '"',
  ',',
  '.',
  '/',
  '[',
  ']',
  '_',
];

export const splitters = badSymb.reduce((obj, el, index) => {
  const num = index === 0
    ? ''
    : String.fromCharCode(65 + index);

  // eslint-disable-next-line no-param-reassign
  obj[el] = `splitter${num}E`;
  return obj;
}, {});

export const COMMAS_REGEX = /,/g;
export const POSITIVE_NEGATIVE_NUMBER_REGEX = /^[+-]?[\d.,]+$/;
