import get from 'lodash/get';
import size from 'lodash/size';
import { newSessionInstanceId } from './auth';

export const DEFAULT_SESSION_ID = 0;
export const MAX_SESSIONS_COUNT = 3;

const initialStore = {
  currentSessionId: DEFAULT_SESSION_ID,
  sessions: {},
  sessionsIds: [],
};

/**
 * @interface SessionStore
 * @property {string} currentSessionId
 * @property {Object} sessions
 * @property {string[]} sessionsIds
 */

function SessionManager() {
  this.store = initialStore;
  this.maxSessionsCount = MAX_SESSIONS_COUNT;

  this.changedEvents = [];
  this.removedEvents = [];

  /**
   * @return {SessionStore}
   */
  this.getStore = () => {
    return this.store;
  };

  /**
   * Превышает ли текущее кол-во сессий максимально допустимого значения кол-ва сессий
   * @return {boolean}
   */
  this.getIsMaxLengthReached = () => {
    return size(this.store.sessions) === this.maxSessionsCount;
  };

  /**
   * @return {boolean}
   */
  this.getCurrentSessionId = () => {
    return this.store.currentSessionId;
  };

  /**
   * @param {string} id session id
   * @return {boolean}
   */
  this.getIsSessionExistById = (id) => {
    return !!this.store.sessions[id];
  };

  /**
   * @param {string} projectId session id
   * @return {boolean}
   */
  this.getIsSessionExistByProjectId = (id, callback) => {
    const res = Object.values(this.store.sessions)
      .some((session) => {
        const projectId = get(session, 'project.projectId');
        return projectId === id;
      });

    callback && callback(res);
  };

  /**
   * @param {string} id session id
   * @return {SessionStore}
   */
  this.getSessionById = (id) => {
    return this.store.sessions[id];
  };

  /**
   * @return {boolean}
   */
  this.getIsCurrentSession = () => {
    return !!this.store.sessions[this.store.currentSessionId];
  };

  /**
   * @return {SessionStore}
   */
  this.getCurrentSession = () => {
    return this.store.sessions[this.store.currentSessionId];
  };

  /**
   * @param {number} count max count of sessions
   * @return {boolean}
   */
  this.setMaxSessionsCount = (count) => {
    if (!Number.isInteger(count) || count < 1) {
      return false;
    }

    this.maxSessionsCount = count;
    return true;
  };

  /**
   * @return {SessionStore}
   */
  this.setSessionById = (id) => {
    // сохраняем id сессии в instanceId и подменяем хэш в URL
    newSessionInstanceId(id);
    this.changeCurrentSession(this.store.currentSessionId, id);

    return this.store.sessions[id];
  };

  /**
   * @param {function} cb callback, will be run on session change
   */
  this.onSessionChanged = (cb) => {
    this.changedEvents.push(cb);
  };

  /**
   * @param {function} cb callback, will be run on session remove
   */
  this.onSessionRemoved = (cb) => {
    this.removedEvents.push(cb);
  };

  /**
   * create new session
   * @param {WebSocket} websocketClient websocket instance
   * @param {Object} project (null for offline)
   * @param {string} project.projectId
   * @param {string} project.viewerId
   * @param {string} project.organization
   * @return {string} session id
   */
  this.createSession = (websocketClient, project) => {
    if (this.getIsMaxLengthReached()) {
      const [sessionId] = this.store.sessionsIds;
      this.destroySessionById(sessionId);
    }

    const id = newSessionInstanceId();

    this.store.sessions[id] = {
      instanceId: id,
      websocketClient,
      project,
    };
    this.store.sessionsIds.push(id);

    if (websocketClient && websocketClient.handle) {
      websocketClient.handle('AUTH', (auth, uid) => {
        if (!this.store.sessions[id]) {
          // FIXME: expirement JSF-7952
          // фикс нужен для того, что бы не тащить sessionManager в NetController т.к.
          // sendAuth может быть вызван в ws.runGetAccessTimer и ws.connect(only AS),
          // после destroy со стороны сервера
          // TODO: kpetrov: всегда инициализировать сессию во время получения AUTH(JSF-8041)
          this.store.sessions[id] = {
            instanceId: id,
            websocketClient,
            project,
          };
        }


        this.store.sessions[id].auth = auth;
        this.store.sessions[id].uid = uid;
      });
      websocketClient.handle('DESTROY', () => {
        this.destroySessionById(id);
      });
    }

    this.changeCurrentSession(this.store.currentSessionId, id);

    return id;
  };

  // private method
  this.destroySessionById = (id) => {
    const currentSessionId = this.getCurrentSessionId();

    // JSF-7940, JSF-8274
    if (currentSessionId === id) {
      return;
    }

    const session = this.getSessionById(id);

    if (session) {
      if (session.websocketClient) {
        session.websocketClient.disconnect();
      }

      delete this.store.sessions[id];

      this.removedEvents.forEach((cb) => {
        cb(id);
      });

      this.store.sessionsIds = this.store.sessionsIds.filter((sessionId) => {
        return sessionId !== id;
      });
    }
  };

  // private method
  this.changeCurrentSession = (prevId, nextId) => {
    const prevSession = this.getSessionById(prevId);

    if (prevSession) {
      const websocketClient = get(prevSession, 'websocketClient', null);
      const store = get(websocketClient, 'store', null);

      if (store) {
        websocketClient.standby();
        const state = store.getState();
        prevSession.state = {
          pdf: state.pdf,
        };
      }
    }

    this.store.currentSessionId = nextId;

    const nextSession = this.getSessionById(nextId);

    if (nextSession) {
      this.changedEvents.forEach((cb) => {
        cb(nextSession);
      });
      const websocketClient = get(nextSession, 'websocketClient', null);
      const store = get(websocketClient, 'store', null);

      if (store) {
        websocketClient.resume();
      }
    }
  };
}

export default SessionManager;
