import { takeEvery, select, put, all } from 'redux-saga/effects';
import findIndex from 'lodash/findIndex';

import {
  removeElements,
  addElements,
} from 'ws-editor-lib/actions';

import { cancellableOpts } from 'jsfcore/store/modules/undoRedo';

import {
  SET_SEARCH_MODE_ACTIVE,
  SET_SEARCH_TERM,
  TOGGLE_SEARCH_NEXT, TOGGLE_SEARCH_PREV,
  APPEND_OCCURENCE_RECTS,
  SET_NEW_HIGHLIGHT_TYPE,
  setOccurences,
  setSearchAvailable,
  setCurrentHighlightType, toggleSearchNext,
} from 'jsfcore/store/modules/search';
import {
  SET_PDF_TEXT_CONTENT,
  SET_ALL_PDF_TEXT_CONTENT,
} from 'jsfcore/store/modules/pdf';
import { changePage } from 'jsfcore/store/modules/navigation';
import { minSearchTermLength } from 'jsfcore/helpers/const';
import {
  getIndexByPageId,
} from 'jsfcore/helpers/utils';
import { getScaledEraseElementShapes } from 'jsfcore/helpers/eraseShapesForTextContent';
import { processSearchTerm } from 'jsfcore/helpers/searchUtils';
import { selectors } from 'jsfcore';

/**
 * При выполнении поиска мы ищем в списке результатов первый, у которого
 * страница равна текущей или больше.
 */
export const findSearchIndex = (occurences, activePageId, pagesSettings) => {
  const activePageIndex = getIndexByPageId(activePageId, pagesSettings);
  const index = findIndex(
    occurences,
    (occurence) => {
      const occurIndex = getIndexByPageId(occurence.pageId, pagesSettings);
      return occurIndex >= activePageIndex;
    },
  );
  if (index > -1) {
    return index;
  }
  return occurences.length > 0
    ? 0
    : -1;
};

/**
 * При изменении searchTerm - производим поиск текста (по элементам и пдф-контенту)
 * результаты сохраняем в store
 */
export function* onSetSearchTerm({ text }) {
  if (!text || text.length < minSearchTermLength) {
    yield put(setOccurences(false));
    return;
  }

  const isAllPagesLoaded = yield select(selectors.getIsAllTextContentLoaded);
  if (!isAllPagesLoaded) {
    // Пока у нас не загружена пдф-ка - будем ждать ее загрузки.
    // Но в search input пользователь пускай что-нибудь вводит.
    // Пока он вводит - как раз успеем подгрузить
    return;
  }

  const searchArgs = yield select(selectors.getArgsForSearch);
  const pagesSettings = yield select(selectors.navigation.getPagesSettings);
  const activePageId = yield select(selectors.base.getActivePageId);
  const eraseElementShapes = yield select(getScaledEraseElementShapes, activePageId);
  const occurences = processSearchTerm(searchArgs, eraseElementShapes);
  const searchIndex = findSearchIndex(occurences, activePageId, pagesSettings);
  yield put(setOccurences(occurences, searchIndex));

  if (searchIndex > -1 && activePageId !== occurences[searchIndex].pageId) {
    yield put(changePage(occurences[searchIndex].pageId));
  }
}

export function* onSetSearchModeActive({ isActive }) {
  if (isActive) {
    // Если какой-то поиск уже происходил - повторим поиск заново
    const searchTerm = yield select(selectors.base.getSearchTerm);
    yield onSetSearchTerm({ text: searchTerm });
  }
}

/**
 * Если необходимо перейти на другую страницу при переходе по результатам поиска
 */
export function* onToggleSearch() {
  const activePageId = yield select(selectors.base.getActivePageId);
  const nextOccurence = yield select(selectors.base.getActiveOccurence);
  if (nextOccurence.pageId !== activePageId) {
    yield put(changePage(nextOccurence.pageId));
  }
}

/**
 * Когда будет загружен весь textContent - то уведомляем приложку что
 * теперь можно искать
 */
export function* onSetPdfTextContent() {
  const isAllPagesLoaded = yield select(selectors.getIsAllTextContentLoaded);
  if (isAllPagesLoaded) {
    yield put(setSearchAvailable());
    // Если пользователь уже успел ввести что-то в search input пока грузился пдф
    // тогда выполним поиск
    const searchTerm = yield select(selectors.base.getSearchTerm);
    if (searchTerm.length > 2) {
      yield onSetSearchTerm({ text: searchTerm });
    }
  }
}

/**
 * Когда SearchHighlighter выставит свои позиции-размеры -
 * найдем, нет ли уже элементов с такими параметрами. Если есть -
 * то выставляем currentHighlightType
 */
export function* onSetOccurenceRects() {
  const existingHighlights = yield select(selectors.getExistingHighlights);
  if (existingHighlights) {
    yield put(setCurrentHighlightType(existingHighlights[0].type));
  }
}

const makeNewElementContent = (rect, defaults) => {
  const { width, height } = rect;
  const lineWidth = Math.min(width, height);
  const controlPoints = [0, 0, width - lineWidth, height - lineWidth];

  return {
    ...defaults,
    ...rect,
    lineWidth,
    controlPoints,
  };
};

/**
 * Когда пользователь жмет кнопку выделения результатов поиска - создаем соотв. элементы
 */
export function* onSetNewHighlightType(action) {
  const existingHighlights = yield select(selectors.getExistingHighlights);
  if (existingHighlights) {
    const existingHighlightsIds =
      existingHighlights.map((elem) => {
        return elem.id;
      });
    yield put(removeElements(existingHighlightsIds, { ...cancellableOpts }));
  }

  if (action.highlightType !== false) {
    const rects = yield select(selectors.base.getRects);
    const defaults = yield select(selectors.getHighlightDefaults);
    const occurence = yield select(selectors.base.getActiveOccurence);

    const newElements = rects.map((rect) => {
      return {
        pageId: occurence.pageId,
        type: action.highlightType,
        subType: 'none',
        content: makeNewElementContent(rect, defaults[action.highlightType]),
      };
    });

    const opts = cancellableOpts;
    yield put(addElements(newElements, opts));
    yield put(setCurrentHighlightType(action.highlightType));
  } else {
    yield put(setCurrentHighlightType(false));
  }

  const occurences = yield select(selectors.base.getOccurences);
  if (occurences.length > 1) {
    yield put(toggleSearchNext());
  }
}

export function* watchIsSearchModeActive() {
  yield takeEvery(SET_SEARCH_MODE_ACTIVE, onSetSearchModeActive);
}

function* watchSearchTerm() {
  yield takeEvery(SET_SEARCH_TERM, onSetSearchTerm);
}

function* watchToggleSearch() {
  yield takeEvery([TOGGLE_SEARCH_NEXT, TOGGLE_SEARCH_PREV], onToggleSearch);
}

export function* watchPdfTextContent() {
  yield takeEvery([SET_PDF_TEXT_CONTENT, SET_ALL_PDF_TEXT_CONTENT], onSetPdfTextContent);
}

function* watchOccurenceRects() {
  yield takeEvery(APPEND_OCCURENCE_RECTS, onSetOccurenceRects);
}

function* watchSetNewHighlightType() {
  yield takeEvery(SET_NEW_HIGHLIGHT_TYPE, onSetNewHighlightType);
}

export default function* searchRootSaga() {
  yield all([
    watchIsSearchModeActive(),
    watchSearchTerm(),
    watchToggleSearch(),
    watchPdfTextContent(),
    watchOccurenceRects(),
    watchSetNewHighlightType(),
  ]);
}
