import React from 'react';
import PropTypes from 'prop-types';

export const thumbnailContentTypes = {
  canvas: 'canvas',
  null: null,
};

const PageThumbnailContentMain = ({ storeRef }) => {
  return (
    <div
      className="page-thumbnail__content"
      ref={storeRef}
    />
  );
};

PageThumbnailContentMain.propTypes = {
  storeRef: PropTypes.func,
};

PageThumbnailContentMain.defaultProps = {
  storeRef: null,
};

export default PageThumbnailContentMain;
