import queryString from 'query-string';
import moment from 'moment';
import scroll from 'scroll';
import findIndex from 'lodash/findIndex';
import find from 'lodash/find';
import clamp from 'lodash/clamp';
import fill from 'lodash/fill';
import get from 'lodash/get';
import isElement from 'lodash/isElement';
import { thisDevice } from '@pdffiller/jsf-useragent';
import { ErrorContext } from '@pdffiller/jsf-logger/clientLogger';
import config from './clientConfig';

import { ghostPosition, maxScale, mobilePopupMinScreenWidthPx,
  mobilePopupHeightPx, orientations, sizeEpsilon, paginationTypes,
  keyCodesMap, styleOpacityZero, fontsUnsupportedBold, fontsUnsupportedItalic,
  snDefaultPageWidth, smoothScrollDefaultOptions, headerOverlayTypes,
} from './const';
import {
  isDrawType,
  elemTypes,
  elemSubTypes,
  elemFCTypes,
} from './elemTypes';
import * as Ui from '../ui';
import { SCALE_FIT_PAGE, SCALE_FIT_WIDTH, SCALE_890 } from '../store/modules/navigationActionTypes';
import isSignNow from './isSignNow';
import { directions, getArrowPropsForGhost } from './graphicUtils';

export const none = 'none';

export { isSignNow };

const defaultPageSize = {
  width: 890,
  height: 1152,
};

// TODO: Write docs about 890px and pagesSettings in SNF
const pageSettingsSignNowProperties = {
  // ordinary size typical pdf page
  originalSize: defaultPageSize,
  size: defaultPageSize,

  sourceUrl: config.app.offline
    ? 'DefaultPagesSettingsSourceUrl'
    : null,
};

export const isSimplePagination = () => {
  return config.app.paginationType === paginationTypes.simple;
};

export const isContiniousPagination = () => {
  return config.app.paginationType === paginationTypes.continious;
};

export const getLeftAndRightPadding = (workspace) => {
  return workspace.framePadding.left + workspace.framePadding.right;
};

export const getTopAndBottomPadding = (workspace) => {
  return workspace.framePadding.top + workspace.framePadding.bottom;
};

export const scuHelper = (nextProps, nextState, componentInstance, rerenderPropsKeysArray, opt) => {
  if (nextState !== componentInstance.state) {
    if (opt && opt.debug) {
      // eslint-disable-next-line no-console
      console.log('re-render by state change');
    }

    return true;
  }

  const changedPropsKeysArray = rerenderPropsKeysArray.filter((key) => {
    if (componentInstance.props[key] !== nextProps[key]) {
      return true;
    }
    return false;
  });

  if (changedPropsKeysArray.length > 0) {
    if (opt && opt.debug) {
      // eslint-disable-next-line no-console
      console.log('re-render by prop change', changedPropsKeysArray);
    }

    return true;
  }

  return false;
};

export const getQSParams = () => {
  const qs = queryString.parse(window.location.search);
  return {
    projectId: qs.projectId,
    viewerId: qs.viewerId,
    embedded: qs.embedded !== undefined,
    redirectUri: qs.redirect_uri || null,
    isOfflineMode: qs.isOfflineMode !== undefined,
    dontWaitForPdf: qs.dontWaitForPdf !== undefined,
    debugPanelVisible: qs.debug_panel === 'true' || qs.debug_panel === 'on',
    firstname: qs.firstname,
    lastname: qs.lastname,
    viewMode: qs['ws.external.document.viewMode'] || qs.viewMode,
    externalValidation: qs.externalValidation,
  };
};
export const hasClass = (el, className) => {
  if (el.classList) {
    return el.classList.contains(className);
  }

  // IE11 костыль: <svg> под IE11 имеет своеобразный className
  if (el.className) {
    const elClassName = typeof el.className !== 'string'
      ? el.className.baseVal
      : el.className;

    return new RegExp(`(^| )${className}( |$)`, 'gi').test(elClassName);
  }

  return false;
};

/**
 *
 */
export const stopEvent = (event) => {
  if (!event) {
    return;
  }
  event.stopPropagation();
  event.preventDefault();
};

export const stopEventPropagation = (event) => {
  if (!event) {
    return;
  }

  event.stopPropagation();
};

const isCursorInCenterGhost = (ghost) => {
  // disable centering for all fields in snfiller editor
  if (ghost && isSignNow()) {
    return false;
  }

  return (
    ghost && (
      ghost.template ||
      isDrawType(ghost.type)
    )
  );
};

/**
 * @param {number} scale
 * @param {object} ghost
 * @return {object} {top, left} - scaled ghost Position
 */
export const getGhostPosition = (scale, ghost) => {
  const centeredPosition = {
    top: -0.5 * ghost.content.height * scale,
    left: -0.5 * ghost.content.width * scale,
  };

  if (ghost.type === elemTypes.arrow) {
    const { x } = getArrowPropsForGhost(ghost.content, scale);

    const left = ghost.content.direction === directions.forward
      ? x
      : 0;
    return {
      top: centeredPosition.top,
      left,
    };
  }

  return isCursorInCenterGhost(ghost)
    ? {
      top: centeredPosition.top,
      left: centeredPosition.left,
    }
    : {
      top: ghostPosition.top * scale,
      left: ghostPosition.left * scale,
    };
};

// TODO: add comment
export const parentPropsImportant = (state, dispatch, own) => {
  return Object.assign({}, state, dispatch, own);
};

// TODO: add comment
export const getDiff = (left, right) => {
  const diffArray = Object.keys(right).filter((key) => {
    return left[key] === undefined || right[key] !== left[key];
  });
  return {
    diffArray,
    have: (key) => {
      return diffArray.indexOf(key) !== -1;
    },
  };
};

// TODO: add comment
export const getScrollToVisibleByElement = (options) => {
  const { x, y, width, height, workspace, frameOffset } = options;
  const elementBottom = y + height;
  const elementRight = x + width;
  const rightLimit = frameOffset.scrollLeft + workspace.width;
  const bottomLimit = frameOffset.scrollTop + workspace.height;

  // Calc invisible size of all directions
  const { top, left, right, bottom } = {
    top: y < frameOffset.scrollTop
      ? y - frameOffset.scrollTop
      : 0,

    left: x < frameOffset.scrollLeft
      ? x - frameOffset.scrollLeft
      : 0,

    right: elementRight > rightLimit
      ? rightLimit - elementRight
      : 0,

    bottom: elementBottom > bottomLimit
      ? bottomLimit - elementBottom
      : 0,
  };
  const result = frameOffset;

  if (top !== 0) {
    result.scrollTop += top;
  } else if (bottom !== 0) {
    result.scrollTop -= bottom;
  }

  if (left !== 0) {
    result.scrollLeft += left;
  } else if (right !== 0) {
    result.scrollLeft -= bottom;
  }

  return result;
};

/**
 * NOTE: unusable
 * Returns scroll of frame by elem (center)
 * @param {object} element - { width, height, top, left }
 * @param {object} workspace - { top, left, width, height }
 */
export const getCenterScrollByElement = (element, workspace) => {
  const { width, height, top, left } = element;
  return {
    scrollTop: (top + (height / 2)) - (workspace.height / 2),
    scrollLeft: (left + (width / 2)) - (workspace.width / 2),
  };
};

/*
 * Returns the new position of the frame offset
 * @param {object} {x, y} - coordinate on page
 * @param {number} scale - page scale
 * @param {object} screen - {x, y} coordinate on screen
 * @param {object} workspace - {top, left} workspace offset
 * @param {object} size - {width, height} page size
 */
export const getNewFrameOffset = ({ x, y }, scale, screen, workspace, size) => {
  const { framePadding } = workspace;
  const offset = {
    left: screen.x - (x * scale) - workspace.left - framePadding.left,
    top: screen.y - (y * scale) - workspace.top - framePadding.top,
  };


  if (size) {
    const width = (size.width * scale) + framePadding.left + framePadding.right;
    const height = (size.height * scale) + framePadding.top + framePadding.bottom;
    if ((workspace.width - width) / 2 >= 0) {
      offset.left = (workspace.width - width) / 2;
    } else {
      if (offset.left > 0) {
        offset.left = 0;
      }
      if (offset.left < workspace.width - width) {
        offset.left = workspace.width - width;
      }
    }

    if ((workspace.height - height) / 2 >= 0) {
      offset.top = (workspace.height - height) / 2;
    } else {
      if (offset.top > 0) {
        offset.top = 0;
      }
      if (offset.top < workspace.height - height) {
        offset.top = workspace.height - height;
      }
    }
  }

  return offset;
};

export const getFitPageScale = (workspace, page) => {
  const { framePadding } = workspace;
  const widthScale = (workspace.width - (framePadding.left + framePadding.right)) / page.width;
  const heightScale = (workspace.height - (framePadding.top + framePadding.bottom)) / page.height;
  return Math.min(widthScale, heightScale);
};

export const getScrollbarWidth = () => {
  if (thisDevice.isWindowsDesktop) {
    if (thisDevice.isChromeDesktop || thisDevice.isOperaDesktop) {
      return 6;
    }
    if (
      thisDevice.isFirefoxDesktop &&
      thisDevice.isInternetExplorer11 &&
      thisDevice.isEdgeDesktop
    ) {
      return 17;
    }
  }

  if (!thisDevice.isMacOSX && thisDevice.isDesktop) {
    return 17;
  }

  // mobile, tablet, macOSX etc
  return 0;
};

export const getFitScale = (workspace) => {
  return (page) => {
    return (headerScale) => {
      const scrollbarWidth = getScrollbarWidth();
      const paddings = workspace.framePadding.left + workspace.framePadding.right + scrollbarWidth;
      const widthScale = ((workspace.width - paddings) / page.width) * headerScale;
      return Math.min(widthScale, maxScale);
    };
  };
};

export const getFitWidthScale = (workspace, page) => {
  return getFitScale(workspace)(page)(1);
};

export const getDefaultScales = (scale, workspace, originalSize) => {
  if (scale === SCALE_FIT_PAGE) {
    return getFitPageScale(workspace, originalSize);
  }

  if (scale === SCALE_FIT_WIDTH) {
    return getFitWidthScale(workspace, originalSize);
  }

  return scale || 1;
};

export const screenToPage = (
  { x, y }, scale, { top, left, ...scrollElement },
  { left: workspaceLeft, top: workspaceTop, framePadding },
) => {
  return {
    x: (x - ((left - (scrollElement.scrollLeft || 0)) + framePadding.left) - workspaceLeft) / scale,
    y: (y - ((top - (scrollElement.scrollTop || 0)) + framePadding.top) - workspaceTop) / scale,
  };
};

export const screenToPageFreeGhost = (
  { x, y }, scale, { top, left, ...scrollElement },
  { framePadding },
) => {
  return {
    x: (x - ((left - (scrollElement.scrollLeft || 0)) + framePadding.left)) / scale,
    y: (y - ((top - (scrollElement.scrollTop || 0)) + framePadding.top)) / scale,
  };
};

export const screenToFrame = ({ x, y }, { left, scrollLeft, top, scrollTop }, workspace) => {
  return {
    x: (x - ((left - scrollLeft) + workspace.framePadding.left) - workspace.left),
    y: (y - ((top - scrollTop) + workspace.framePadding.top) - workspace.top),
  };
};

export const pageToScreen = ({ x, y }, scale, frameOffset, workspace) => {
  return {
    x: (x * scale) + ((frameOffset.left - frameOffset.scrollLeft) + workspace.framePadding.left) +
      workspace.left,
    y: (y * scale) + ((frameOffset.top - frameOffset.scrollTop) + workspace.framePadding.top) +
      workspace.top,
  };
};

const fixNegativePosition = ({ x, y }) => {
  return {
    x: x < 0
      ? 0
      : x,

    y: y < 0
      ? 0
      : y,
  };
};

/*
 * Returns the possible position of the element with a given
 * 'size' within a frame with the 'frameSize'
 * @param {object} position - position to fix {top, left}
 * @param {object} frameSize - {width, height}
 * @param {object} size - {width, height}
 */
export function limitByFrameSize(position, frameSize, size) {
  const { x, y } = fixNegativePosition(position);
  const { width: frameWidth, height: frameHeight } = frameSize;
  const { width: elemWidth, height: elemHeight } = size;

  if (Number.isNaN(elemWidth) || Number.isNaN(elemHeight)) {
    return { x, y };
  }
  return {
    x: clamp(x, 0, frameWidth - elemWidth),
    y: clamp(y, 0, frameHeight - elemHeight),
  };
}

export const limitByFrameSizeFreeGhost =
  (position, frameSize, size) => {
    const { x, y } = fixNegativePosition(position);
    const { width: frameWidth, height: frameHeight } = frameSize;
    const { width: elemWidth, height: elemHeight } = size;

    if (Number.isNaN(elemWidth) || Number.isNaN(elemHeight)) {
      return { x, y };
    }
    return {
      x: clamp(x, 0, frameWidth - elemWidth),
      y: clamp(y, 0, frameHeight - elemHeight),
    };
  };

/*
 * [Null, Null, ...] (count)
 */
export const getArrayOfNull = (count) => {
  return fill(Array(count), null);
};

/*
 * [0, 0, ...] (count)
 */
export const getArrayOfZero = (count) => {
  return fill(Array(count), 0);
};

/*
 * [0, 1, 2, ...] (count)
 */
export function getArrayOfIds(count) {
  let index = 0;
  const array = [];
  while (index < count) {
    array.push(index);
    index += 1;
  }

  return array;
}

/*
 * Returns an array of the parents of this element
 * @param {object} Event (.target) or DOMNode
 */
export function getEventPath(event) {
  const target = isElement(event.target) // target can be an attribute of <a/> tag
    ? event.target
    : event;
  const path = [];
  let node = target;
  while (node && node !== document.body) {
    path.push(node);
    node = node.parentNode;
  }

  return path;
}

// TODO: add comment
// legacy code to not break smth
export const ps = () => {
  return {
    initialize: () => {},
    destroy: () => {},
    update: () => {},
  };
};

/**
 * Add listener to clicks outside `node`, and process `handler`
 *
 * @param {object} node - react component to subscribe to clicks outside
 * @param {object} onClickOutside - click inside handler
 * @param {object} onClickInside - click outside handler
 * @returns {function} function that unsubscribes handler
 */
export const listenClickOutside = (node, onClickOutside, onClickInside) => {
  const maybeHandle = (event) => {
    const isClickInside = node.contains(event.target);

    if (isClickInside) {
      if (typeof onClickInside === 'function') {
        onClickInside();
      }
    } else if (typeof onClickOutside === 'function') {
      onClickOutside();
    }
  };

  window.addEventListener('click', maybeHandle);
  return () => {
    return window.removeEventListener('click', maybeHandle);
  };
};

/**
 * Get object to pass into 'togglePopup' method.
 * We compute it only once, because this code is actual for mobile devices (no window resize)
 * and save to 'popupSize' var
 *
 * @returns {object} { landscape: sizePx, portrait: sizePx}
 */
let popupSize = false;
export const getPopupSize = () => {
  if (popupSize) {
    return popupSize;
  }
  const { clientWidth, clientHeight } = document.body;

  // let's work like we are in portrait orientation: bigger value will be height, less - width
  const screenWidth = Math.min(clientWidth, clientHeight);
  const screenHeight = Math.max(clientWidth, clientHeight);

  if (screenHeight < mobilePopupMinScreenWidthPx) {
    // popup will be a fullwidth_menu_at_bottom in landscape and portrait
    popupSize = { portrait: mobilePopupHeightPx, landscape: mobilePopupHeightPx };
  } else if (screenWidth < mobilePopupMinScreenWidthPx) {
    // if popup will be a fullwidth_menu_at_bottom in portrait and popup_at_top in landscape
    popupSize = { portrait: mobilePopupHeightPx, landscape: 0 };
  } else {
    // popup will be popup_at_top in landscape and portrait
    popupSize = { portrait: 0, landscape: 0 };
  }

  return popupSize;
};

/**
 * Returns current orientation
 *
 * @param {object} body - anything that has 'width' and 'height' properties
 * @returns {string} helpers/const::orientations instance
 */
export const getOrientation = (body) => {
  if (!body || body.width < body.height) {
    return orientations.portrait;
  }
  return orientations.landscape;
};

/**
 * Move value at indexFrom to indexTo
 *
 * @param {array} array - incoming array
 * @param {number} indexFrom - index of moving value
 * @param {number} indexTo - index where we are moving value
 * @returns {array} new array
 */
export const moveTo = (array, indexFrom, indexTo) => {
  if (
    indexFrom < 0 ||
    indexTo < 0 ||
    indexFrom > array.length ||
    indexTo > array.length
  ) {
    return array;
  }

  if (indexFrom < indexTo) {
    return [
      ...array.slice(0, indexFrom),
      ...array.slice(indexFrom + 1, indexTo + 1),
      array[indexFrom],
      ...array.slice(indexTo + 1),
    ];
  }

  return [
    ...array.slice(0, indexTo),
    array[indexFrom],
    ...array.slice(indexTo, indexFrom),
    ...array.slice(indexFrom + 1),
  ];
};

export const getDefaultPagesSettings = (count) => {
  return getArrayOfNull(count).map((_, index) => {
    return {
      rotation: 0,
      visible: true,
      source: index,
      ...(isSignNow() && pageSettingsSignNowProperties),
    };
  });
};

export const getPageIdByIndex = (index, pagesSettings) => {
  return pagesSettings[index].source;
};

export const getIndexByPageId = (sourceId, pagesSettings) => {
  return findIndex(pagesSettings, ({ source }) => {
    return source === sourceId;
  });
};

export const getAdjacentPageId = (activePageId, pagesSettings, count) => {
  const index = getIndexByPageId(activePageId, pagesSettings);
  const nextIndex = index + count;
  if (nextIndex < 0) {
    return getPageIdByIndex(0, pagesSettings);
  }
  if (nextIndex >= pagesSettings.length) {
    return getPageIdByIndex(pagesSettings.length - 1, pagesSettings);
  }

  return getPageIdByIndex(nextIndex, pagesSettings);
};

/**
 * return true, if all non-numercic in objects are equal
 * and all numeric values are _almost_ equal (diff less than sizeEpsilon conwt)
 *
 * @param {object} firstObj - first object to diff
 * @param {object} anotherObj - second object to diff
 * @returns {bool} true if all values are almost equal
 */
export const areAlmostEqual = (firstObj, anotherObj) => {
  const keys = Object.keys(firstObj);
  for (let keyI = 0; keyI < keys.length; ++keyI) {
    const firstVal = firstObj[keys[keyI]];
    const anotherVal = anotherObj[keys[keyI]];
    if (typeof firstVal === 'number' && typeof anotherVal === 'number') {
      if (Math.abs(firstVal - anotherVal) > sizeEpsilon) {
        return false;
      }
    } else if (firstVal !== anotherVal) {
      return false;
    }
  }

  return true;
};

// TODO: test
// Если свойство enabled отсутствует - считаем по умолчанию, что оно true
export const isEnabledElement = (element) => {
  return element.enabled === undefined
    ? true
    : element.enabled;
};

export const isConditionalElement = (element) => {
  return get(element, 'template.conditional', false);
};

// true if key code +
export const hasPlusButton = (code) => {
  return keyCodesMap.plus.some((el) => {
    return el === code;
  });
};

// true if key code -
export const hasMinusButton = (code) => {
  return keyCodesMap.minus.some((el) => {
    return el === code;
  });
};

export const hasPlusMinusKeyCodes = (code) => {
  return hasPlusButton(code) || hasMinusButton(code);
};

// NOTE: this function have test
export const generateFuncDateToElement = (updateElementFunc) => {
  return (elem) => {
    return (date) => {
      return updateElementFunc(elem.id, {
        text: date,
      });
    };
  };
};

export const setMoveCursor = () => {
  document.body.style.cursor = 'move';
};

export const deleteMoveCursor = () => {
  document.body.style.cursor = '';
};

export const getElementCursor = (isActiveElement) => {
  return isActiveElement
    ? 'auto'
    : 'pointer';
};

export { elemFCTypes };

export const hasIconCenter = [
  elemFCTypes.signature,
  elemFCTypes.initials,
  elemFCTypes.image,
];

export const hasGhostIconCenter = (type) => {
  return hasIconCenter.some((el) => {
    return el === type;
  });
};

// Round to 2 decimals
export const roundTo2 = (value) => {
  return Math.round(value * 100) / 100;
};

export const findPageSettingsByPageId = (pagesSettings, pageId) => {
  return find(pagesSettings, { source: pageId });
};

export const isNumeric = (value) => {
  return typeof value === 'number' && Number.isFinite(value);
};

export const padZeros = (value, length) => {
  return (Array(length + 1).join('0') + value).slice(-length);
};

export const formatDate = (date, format) => {
  // NOTE: now we use uppercased format always.
  // fix it if we want to display minutes (or other lower-cased moment format items)
  return moment(date).format(format.toUpperCase());
};

export const mergeInputStyles = (styles) => {
  return { ...styles, ...styleOpacityZero };
};

export const isTabletWithActiveElement = (activeElement) => {
  return thisDevice.isTablet && activeElement !== false;
};

export const getMouseEventHandler = (callback) => {
  if (thisDevice.isSafariPhone) {
    return { onTouchEnd: callback };
  }

  return thisDevice.isSafariDesktop
    ? { onMouseDown: callback }
    : { onClick: callback };
};

export const getIsReadOnlyOverlayEnabled = (overlays = []) => {
  return overlays.indexOf(headerOverlayTypes.readOnly) !== -1;
};

export const getIsWizardOverlayEnabled = (overlays = []) => {
  return overlays.indexOf(headerOverlayTypes.wizard) !== -1;
};

export const getIsSigningSessionOverlayEnabled = (overlays = []) => {
  return overlays.indexOf(headerOverlayTypes.signingSession) !== -1;
};

export const getTypeSubType = (id = '') => {
  const idSplit = id.split('.');

  return {
    type: idSplit[1],
    subType: idSplit[2],
  };
};

export const fireFakeEvent = (eventName) => {
  let event; // The custom event that will be created

  if (document.createEvent) {
    event = document.createEvent('HTMLEvents');
    event.initEvent(eventName, true, true);
  } else {
    event = document.createEventObject();
    event.eventType = eventName;
  }

  event.eventName = eventName;

  if (document.createEvent) {
    window.dispatchEvent(event);
  } else {
    window.fireEvent(`on${event.eventType}`, event);
  }
};

// return number between 0 and 'denominator'
// e.g. modulus(15, 12) = 3
// e.g. modulus(-1, 12) = 11
export const modulus = (value, denominator) => {
  return ((value % denominator) + denominator) % denominator;
};

export const toRadians = (angleInDegrees) => {
  return angleInDegrees * (Math.PI / 180);
};

export const getUnsupportedInscriptionsByFont = (font) => {
  const isContentUnsuportedFont = (unsuportedFont) => {
    return unsuportedFont === font;
  };
  return {
    boldUnsupported: fontsUnsupportedBold.some(isContentUnsuportedFont),
    italicUnsupported: fontsUnsupportedItalic.some(isContentUnsuportedFont),
  };
};

export const getBase64 = (obj) => {
  // TODO: remove eslint-disable
  // eslint-disable-next-line no-buffer-constructor
  return new Buffer(JSON.stringify(obj)).toString('base64');
};

// Used for FeedBack and FeedBack mode Oops modal
export const getDataForFeedBackModal = (
  viewerId,
  viewerEmail,
  projectId,
  { baseAPI, feedbackLocale, appKey } = {},
) => {
  const { state, history } = new ErrorContext();
  const version = `JSFiller: ${(config.build || 'null')}`;
  return {
    userData: {
      user: {
        id: viewerId,
        email: viewerEmail,
      },
      info: {
        referrer: __CLIENT__ && document.referrer,
        location: __CLIENT__ && window.location.href,
        projectId,
        version,
      },
      attachments: {
        state: getBase64(state),
        actions: getBase64(history),
      },
    },
    baseUrl: baseAPI,
    constsJSONUrl: feedbackLocale,
    appKey,
  };
};

const getDefaultWidthByDevice = (workspace) => {
  return thisDevice.isMobile
    ? workspace.width - (workspace.framePadding.left + workspace.framePadding.right)
    : snDefaultPageWidth;
};

const getHeightByProps = (pagesSettings, index, workspace, scale) => {
  const height = get(pagesSettings, `[${index}].originalSize.height`, null);
  if (height !== null) {
    return height * scale;
  }

  return workspace.height;
};

export const getFrameSizesOrDefault = ({
  frameSizes,
  workspace,
  pagesSettings = [],
  scale = 1,
}) => {
  const defaultWidth = getDefaultWidthByDevice(workspace);
  return frameSizes.map((frameSize, i) => {
    return frameSize || {
      width: defaultWidth * scale,
      height: getHeightByProps(pagesSettings, i, workspace, scale),
    };
  });
};

/**
 * Получение значение scrollTop к странице с id (pageId)
 */
export const getScrollTopToPageId = ({
  workspace,
  frameSizes,
  pageId,
  pagesSettings = [],
  scale,
}) => {
  let id = pageId - 1;
  let scrollTop = 0;
  const { framePadding } = workspace;

  const frameSizesOrDefault = getFrameSizesOrDefault({
    frameSizes,
    workspace,
    pagesSettings,
    scale,
  });

  while (id !== -1) {
    const frameSize = frameSizesOrDefault[id];
    scrollTop += framePadding.top + frameSize.height;
    id -= 1;
  }

  return scrollTop;
};

export const getSnScaleFromHeaderScale = (headerScale) => {
  return (
    headerScale === SCALE_890
      ? 1
      : headerScale
  );
};

export const getIsInputOrTextareaElement = (targetNode) => {
  const { tagName, type, disabled } = targetNode;

  return (
    targetNode.getAttribute('contenteditable') === true ||
    tagName === 'textarea'.toUpperCase() ||
    (
      tagName === 'input'.toUpperCase() &&
      (type === 'text' || type === 'number') &&
      !disabled
    )
  );
};

export const getSnFillerWorkspace = (workspace) => {
  return {
    ...workspace,
    width: getDefaultWidthByDevice(workspace) + (
      workspace.framePadding.left + workspace.framePadding.right
    ),
  };
};

export const smoothScrollToElement = (
  targetNode,
  scrollNode,
  options = smoothScrollDefaultOptions,
) => {
  const { height: parentNodeHeight } = scrollNode.getBoundingClientRect();
  const {
    offsetTop: targetNodeOffsetTop,
    offsetParent: { offsetTop: targetNodeParentOffsetTop },
  } = targetNode;

  const scrollValue =
    (targetNodeOffsetTop + targetNodeParentOffsetTop) - Number(parentNodeHeight / 2);

  scroll.top(scrollNode, scrollValue, options);
};

export const blurNativeActiveElement = () => {
  if (document.activeElement && document.activeElement !== document.body) {
    document.activeElement.blur();
  }
};

export const getDaysInMonth = (month, year) => {
  return new Date(year, month + 1, 0).getDate();
};

// smart and salesforce can haven't unique names
export const isIntegrationField = (element) => {
  return (
    element.type === elemFCTypes.salesforce ||
    element.type === elemFCTypes.smart
  );
};

// Browsers can block access to window.top due to same origin policy.
export const isInIframe = () => {
  try {
    return window.self !== window.top;
  } catch (e) {
    return true;
  }
};

// Returns closest item of array to the given value
export const getClosestItemOfArray = (array, value) => {
  return array.reduce((prev, current) => {
    return (Math.abs(current - value) < Math.abs(prev - value)
      ? current
      : prev
    );
  });
};

export const getIconType = (el) => {
  if (Array.isArray(el)) {
    const isRadioGroup = el.every((elem) => {
      return elem.type === elemFCTypes.radio;
    });
    if (isRadioGroup) {
      return Ui.icon.types.radioButton;
    }
  }
  if (el && el.type) {
    switch (el.type) {
      case elemFCTypes.signature:
        return el.subType === elemSubTypes[elemTypes.signature].initials
          ? Ui.icon.types.initials
          : Ui.icon.types.sendToSign;
      case elemFCTypes.checkmark:
        return Ui.icon.types.checkbox;
      case elemFCTypes.image:
        return Ui.icon.types.redesignPicture;
      case elemFCTypes.formula:
        return Ui.icon.types.formula;
      case elemFCTypes.text:
        switch (el.subType) {
          case elemFCTypes.number:
            return Ui.icon.types.number;
          case elemFCTypes.date:
            return Ui.icon.types.date;
          case elemFCTypes.formula:
            return Ui.icon.types.formula;
          case elemFCTypes.dropdown:
            return Ui.icon.types.dropdown;
          default:
            return Ui.icon.types.text;
        }
      default:
        return Ui.icon.types.text;
    }
  }
  return Ui.icon.types.text;
};
