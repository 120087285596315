import { useContext } from 'react';
import FocusControllerContext from './FocusControllerContext';
import { isNodeHaveInterceptDataset } from './FocusControllerRestoreFocusArea';

const useTemporaryDisableBlur = (onStart, onEnd) => {
  const {
    restoreFocus,
    disableBlur,
    enableBlur,
  } = useContext(FocusControllerContext);

  const onStartWithDisableBlur = (...args) => {
    disableBlur();
    if (onStart) onStart(...args);
  };

  const onEndWithDisableBlur = (event, ...args) => {
    if (onEnd) onEnd(event, ...args);
    const relatedTarget = event && event.relatedTarget;
    if (!relatedTarget || !isNodeHaveInterceptDataset(relatedTarget)) {
      restoreFocus();
    }
    enableBlur();
  };

  return [onStartWithDisableBlur, onEndWithDisableBlur];
};

export default useTemporaryDisableBlur;
