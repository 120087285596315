import React from 'react';
import Icon, { iconTypes, iconSizes } from '../../Icon';

const Separator = () => {
  return (
    <div
      aria-hidden="true"
      className="breadcrumbs__separator"
    >
      <Icon
        type={iconTypes.arrowRight}
        size={iconSizes.parent}
      />
    </div>
  );
};

export default Separator;
