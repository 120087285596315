import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Component } from 'react';
import * as selectors from '../../store/selectors';

@connect(
  (state) => {
    return {
      frameOffset: selectors.getFrameOffset(
        state,
        selectors.base.getActivePageId(state),
      ),
    };
  },
)
export default class ExperimentDetectOffset extends Component {
  static propTypes = {
    frameOffset: PropTypes.shape({}).isRequired,
    onOffsetUpdated: PropTypes.func.isRequired,
  };

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.frameOffset !== this.props.frameOffset) {
      this.props.onOffsetUpdated();
    }
  }

  render() {
    return null;
  }
}
