import PropTypes from 'prop-types';
import React from 'react';

const TodoListBody = ({ children, storeRef, attributes }) => {
  return (
    <div {...attributes} className="todo__cell">
      <div className="todo__list" ref={storeRef}>
        <ul className="list">
          {children}
        </ul>
      </div>
    </div>
  );
};

TodoListBody.propTypes = {
  attributes: PropTypes.shape({}),
  children: PropTypes.node,
  storeRef: PropTypes.func,
};

TodoListBody.defaultProps = {
  attributes: {},
  children: null,
  storeRef: null,
};

export default TodoListBody;
