export default function doUsers(operations, state) {
  const newUsersMap = {};
  operations.forEach((operation) => {
    operation.properties.list.forEach((user) => {
      const { id, name, email, avatar } = user;
      newUsersMap[id] = { name, email, avatar };
    });
  });

  return {
    users: {
      ...state.users,
      ...newUsersMap,
    },
  };
}
