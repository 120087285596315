import {
  applyDefaultsContent,
  send,
  createIdAndNext,
  getIsNumberTemplateId,
  getIsFormulaTemplateId,
  getIsDateTemplateId,
} from '../Utils';
import modes from '../../../../constants/editorModes';
import {
  GROUPS,
  TYPES,
} from '../../../../constants';

import isSignNow from '../../../../isSignNow';

export function doDefaults(operations, { defaults = {} }) {
  return {
    ...operations
      .filter((operation) => {
        return operation.properties.content;
      })
      .reduce(
        (acc, operation) => {
          return {
            ...acc,
            ...operation.properties,

            // TODO: TEMPORARY
            // Внеменно добавляем в дефолты дефолтные валидаторы для date и number
            ...(
              operation.properties.template
                ? {
                  template: operation.properties.template.map((template) => {
                    const { id } = template;
                    const isNumberTemplate = getIsNumberTemplateId(id);
                    const isFormulaTemplate = getIsFormulaTemplateId(id);
                    const isDateTemplate = getIsDateTemplateId(id);

                    if (!isSignNow()) {
                      if (isNumberTemplate || isFormulaTemplate) {
                        return {
                          ...template,
                          validatorId: '1109cfbbb06311a06a4c7f8d04f1f0d5c44103cb',
                        };
                      }

                      if (isDateTemplate) {
                        return {
                          ...template,
                          validatorId: '13435fa6c2a17f83177fcbb5c4a9376ce85befeb',
                        };
                      }
                    }

                    return template;
                  }),
                }
                : {}
            ),
          };
        },
        defaults,
      ),

    ...operations
      .filter((operation) => {
        return !operation.properties.content;
      })
      .reduce(
        (acc, operation) => {
          return ({
            ...applyDefaultsContent(acc, operation.properties),
          });
        },
        defaults,
      ),
  };
}

export const doScenarios = (operations, scenarios) => {
  const { onStart, onDone, onConstructorExit } = operations[0].properties;

  if (!onStart && !onDone && !onConstructorExit && !scenarios) {
    return scenarios;
  }

  if (!scenarios) {
    return ({
      onStart: onStart || [],
      onDone: onDone || [],
      onConstructorExit: onConstructorExit || [],
    });
  }

  const {
    onStart: scenariosOnStart = [],
    onDone: scenariosOnDone = [],
    onConstructorExit: scenariosOnConstructorExit = [],
  } = scenarios;

  return ({
    onStart: onStart
      ? [...scenariosOnStart, ...onStart]
      : scenariosOnStart,
    onDone: onDone
      ? [...scenariosOnDone, ...onDone]
      : scenariosOnDone,
    onConstructorExit: onConstructorExit
      ? [...scenariosOnConstructorExit, ...onConstructorExit]
      : scenariosOnConstructorExit,
  });
};

export const doFeatures = (operations, features) => {
  return ({
    ...features,
    ...operations[0].properties,
  });
};

export const doGUI = (operations, gui) => {
  return ({
    ...gui,
    ...operations[0].properties,
  });
};

export const doExperiments = (operations, experiments) => {
  return ({
    ...experiments,
    ...operations[0].properties,
  });
};

export const doStatistics = (operations, statistics) => {
  return ({
    ...statistics,
    ...operations[0].statistics,
  });
};

export const doActivateFCMode = (transport, sendOperationsCount, action) => {
  send(transport,
    { clientId: 0, localId: sendOperationsCount.local + 1 },
    { group: GROUPS.editor, type: TYPES.mode, subType: modes.constructor, initial: action.initial },
  );
  return {
    sendOperationsCount: { local: sendOperationsCount.local + 1 },
    isFCActive: true,
  };
};

export const doActivateDefaultMode = (
  transport,
  sendOperationsCount,
  { isNeedToClearSnapshot },
) => {
  send(transport,
    { clientId: 0, localId: sendOperationsCount.local + 1 },
    { group: GROUPS.editor, type: TYPES.mode, subType: modes.main },
  );
  return {
    sendOperationsCount: { local: sendOperationsCount.local + 1 },
    isFCActive: false,
    ...(
      isNeedToClearSnapshot
        ? { wsEditModeSnapshot: null }
        : {}
    ),
  };
};

export const doCancelFCMode = (transport, sendOperationsCount) => {
  send(transport,
    { clientId: 0, localId: sendOperationsCount.local + 1 },
    {
      group: GROUPS.operations,
      type: TYPES.cancel,
      subType: 'byMode',
      byMode: modes.constructor,
      forcePull: false,
    },
  );
  return {
    sendOperationsCount: { local: sendOperationsCount.local + 1 },
    isFCActive: false,
  };
};

export const doTriggerOperation = (transport, lastOperationsCount, { element, trigger }) => {
  const { id, sendOperationsCount } = createIdAndNext(lastOperationsCount);

  send(transport,
    id, { group: GROUPS.integrations, type: TYPES.trigger, subType: trigger, element },
  );
  return { sendOperationsCount };
};

export const doActivateMode = (transport, lastOperationsCount, { mode }) => {
  const { id, sendOperationsCount } = createIdAndNext(lastOperationsCount);

  send(transport,
    id, { group: GROUPS.editor, type: TYPES.mode, subType: mode },
  );
  return { sendOperationsCount };
};

export const doEditorMode = (operations) => {
  const { subType } = operations[0].properties;

  return modes[subType];
};

export const doActivateVersionsMode = (transport, state) => {
  const { sendOperationsCount } =
    doActivateMode(transport, state.sendOperationsCount, { mode: modes.versions });

  return {
    ...state,
    sendOperationsCount,
    versions: {
      ...state.versions,
      isRequested: true,
    },
  };
};

export const doCancelVersionsMode = (transport, lastOperationsCount) => {
  const { id, sendOperationsCount } = createIdAndNext(lastOperationsCount);
  send(
    transport,
    id,
    {
      group: GROUPS.operations,
      type: TYPES.cancel,
      subType: 'byMode',
      byMode: modes.versions,
      forcePull: true,
      sourceRequired: true,
    },
  );
  return { sendOperationsCount };
};
