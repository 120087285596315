import React from 'react';
import PropTypes from 'prop-types';

const ListConfiguratorField = ({ children }) => {
  return (
    <div
      className="list-configurator__field"
    >
      {children}
    </div>
  );
};

ListConfiguratorField.propTypes = {
  children: PropTypes.node.isRequired,
};

export default ListConfiguratorField;
