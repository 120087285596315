import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

const FillableFieldLabel = ({ children, style, isCells, isCellsLinesHidden }) => {
  return (
    <div
      className={classnames('fillable-field__label', {
        'is-cells': isCells,
      })}
      data-cells-lines-hidden={isCellsLinesHidden}
      style={style}
    >
      <div className="fillable-field__label-wrapper">
        {children}
      </div>
    </div>
  );
};

FillableFieldLabel.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.string,
  ]).isRequired,
  isCells: PropTypes.bool,
  isCellsLinesHidden: PropTypes.bool,
  style: PropTypes.shape({}),
};

FillableFieldLabel.defaultProps = {
  style: null,
  isCellsLinesHidden: false,
  isCells: false,
};

export default FillableFieldLabel;
