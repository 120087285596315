import React from 'react';
import PropTypes from 'prop-types';

const RightSidebarBody = ({ children }) => {
  return (
    <div className="collapsed-right-sidebar">
      <div className="collapsed-right-sidebar__body">
        {children}
      </div>
    </div>
  );
};

RightSidebarBody.propTypes = {
  children: PropTypes.node,
};

RightSidebarBody.defaultProps = {
  children: null,
};

export default RightSidebarBody;
