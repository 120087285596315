import PropTypes from 'prop-types';
import React, { Component } from 'react';
import cls from 'classnames';

export default class ToolBarAdd extends Component {
  static propTypes = {
    children: PropTypes.node,
    isActive: PropTypes.bool,
  };

  static defaultProps = {
    children: null,
    isActive: false,
  };

  render() {
    return (
      <div
        className={cls(
          'toolbar__add', {
            'is-active': this.props.isActive,
          },
        )}
      >
        {this.props.children}
      </div>
    );
  }
}
