const openModalHooks = [];
const closeModalHooks = [];
const promisesStore = {};

export const openModal = ({ type, open }, ...args) => {
  return (dispatch) => {
    openModalHooks.forEach((func) => {
      dispatch(func());
    });

    return new Promise((resolve) => {
      dispatch(open(...args));
      promisesStore[type] = resolve;
    });
  };
};

export const closeModal = ({ type, close }, ...args) => {
  return (dispatch) => {
    closeModalHooks.forEach((func) => {
      dispatch(func());
    });

    dispatch(close(...args));
    promisesStore[type](...args);
  };
};

export const addOpenModalHook = (func) => {
  openModalHooks.push(func);
};

export const addCloseModalHook = (func) => {
  closeModalHooks.push(func);
};
