import {
  SET_CONSTRUCTOR_ERROR,
  SET_SIMPLE_ERROR,
  CLEAR_SIMPLE_ERROR,
  SET_CONSTRUCTOR_ERRORS,
} from '../reducers/errorsReducer';

export const setConstructorError = ({ id, isValid, errors }) => {
  return {
    type: SET_CONSTRUCTOR_ERROR,
    id,
    isValid,
    errors,
  };
};

export const setConstructorErrors = (errors) => {
  return {
    type: SET_CONSTRUCTOR_ERRORS,
    errors,
  };
};

export const setSimpleError = (simpleError) => {
  return {
    type: SET_SIMPLE_ERROR,
    simpleError,
  };
};

export const clearSimpleError = () => {
  return {
    type: CLEAR_SIMPLE_ERROR,
  };
};
