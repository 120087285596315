import React from 'react';
import PropTypes from 'prop-types';
import cls from 'classnames';

export const flexibleListBodySizes = {
  medium: 'medium',
  null: null,
};

export const flexibleListBodyOffsets = {
  small: 'small',
  large: 'large',
  null: null,
};

const FlexibleListBody = ({ size, offset, storeRef, onMouseDown, children }) => {
  return (
    <div
      className={cls('flexible-list', {
        [`flexible-list--size--${size}`]: size,
        [`flexible-list--offset--${offset}`]: offset,
      })}
      onMouseDown={onMouseDown}
    >
      <ul className="flexible-list__body" ref={storeRef}>
        {children}
      </ul>
    </div>
  );
};

FlexibleListBody.propTypes = {
  children: PropTypes.node,
  storeRef: PropTypes.func,
  onMouseDown: PropTypes.func,
  size: PropTypes.oneOf(Object.values(flexibleListBodySizes)),
  offset: PropTypes.oneOf(Object.values(flexibleListBodyOffsets)),
};

FlexibleListBody.defaultProps = {
  children: null,
  storeRef: null,
  onMouseDown: null,
  size: flexibleListBodySizes.null,
  offset: flexibleListBodyOffsets.null,
};

export default FlexibleListBody;
