import React from 'react';
import PropTypes from 'prop-types';

const TabsList = ({ children }) => {
  return (
    <div className="tabs__list" role="tablist">
      {children}
    </div>
  );
};

TabsList.propTypes = {
  children: PropTypes.node.isRequired,
};

export default TabsList;
