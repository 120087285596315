import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Component } from 'react';
import * as selectors from '../../store/selectors';

@connect(
  (state) => {
    return {
      scale: selectors.getScale(
        state,
        selectors.base.getActivePageId(state),
      ),
    };
  },
)
export default class ExperimentDetectScale extends Component {
  static propTypes = {
    scale: PropTypes.number.isRequired,
    onScaleUpdated: PropTypes.func.isRequired,
  };

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.scale !== this.props.scale) {
      this.props.onScaleUpdated();
    }
  }

  render() {
    return null;
  }
}
