import get from 'lodash/get';
import findDependentElements from '../../helpers/findDependentElements';
import { getDependency, dependenciesMap } from '../../di';


/**
 * Function should return true if name depend on 'id' element
 * @return {Boolean} isCircularDependency
 */
const isCircularDependency = ({ id, name }) => {
  return (dispatch, getState) => {
    // injected dependencies
    const jsfcoreModalsActions = getDependency(dependenciesMap.jsfcoreModalsActions);
    const jsfcoreModalsTypes = getDependency(dependenciesMap.jsfcoreModalsTypes);
    const jsfcoreSelectors = getDependency(dependenciesMap.jsfcoreSelectors);

    const state = getState();

    if (!name) {
      return false;
    }

    let elementToChange;
    let elementWhichDepends;

    const elements = jsfcoreSelectors.elements.getElements(state);
    for (let i = 0; i < elements.length; i++) {
      if (elements[i].id === id) {
        elementToChange = elements[i];
      }
      if (get(elements[i], 'template.name') === name) {
        elementWhichDepends = elements[i];
      }
    }

    const dependentElements = findDependentElements(
      elementToChange.template.name,
      elements.filter((elem) => {
        return (
          // Filter elements without dependencies
          elem.template && elem.template.dependency && elem.template.conditional
        );
      }),
    );

    if (dependentElements.length > 0) {
      for (let i = 0; i < dependentElements.length; i++) {
        if (dependentElements[i].id === elementWhichDepends.id) {
          dispatch(
            jsfcoreModalsActions.openModal(
              jsfcoreModalsTypes.modalsMap[jsfcoreModalsTypes.modalTypes.warning],
              jsfcoreModalsTypes.warningTypes.circularWarning,
              { name },
            ),
          );
          return true;
        }

        if (
          isCircularDependency({
            id: dependentElements[i].id,
            name,
          })(dispatch, getState)
        ) {
          return true;
        }
      }
    }

    return false;
  };
};

export default isCircularDependency;
