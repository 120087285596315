import React from 'react';
import PropTypes from 'prop-types';

const SortableElementCheck = ({ children }) => {
  return (
    <div className="sortable-element__check">
      {children}
    </div>
  );
};

SortableElementCheck.propTypes = {
  children: PropTypes.node.isRequired,
};

export default SortableElementCheck;
