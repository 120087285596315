const mapTypes = {
  text: {
    none: 'basic_field_text',
    number: 'basic_field_number',
    date: 'basic_field_date',
    dropdown: 'basic_field_dropdown',
    formula: 'basic_field_formula',
  },
  checkmark: {
    none: 'basic_field_checkmark',
    x: 'basic_field_checkmark',
    v: 'basic_field_checkmark',
    o: 'basic_field_checkmark',
  },
  radio: {
    none: 'basic_field_radio',
    x: 'basic_field_radio',
    v: 'basic_field_radio',
    o: 'basic_field_radio',
  },
  signature: {
    none: 'basic_field_signature',
    initials: 'basic_field_initials',
  },
  image: {
    none: 'basic_field_image',
  },
};

export default function toolIdPatchFrom(operations) {
  return operations.map((op) => {
    const { group } = op.properties;
    if (group !== 'tools') {
      return op;
    }

    const { properties: element } = op;
    if (element.hasOwnProperty('template') && !element.template.hasOwnProperty('toolId')) {
      const { type, subType } = element;
      if (mapTypes[type] && mapTypes[type][subType] !== undefined) {
        return {
          ...op,
          properties: {
            ...element,
            template: {
              ...element.template,
              toolId: mapTypes[type][subType],
            },
          },
        };
      }

      // eslint-disable-next-line no-console
      console.error('unknown type ', type, subType);
    }

    return op;
  });
}
