import React from 'react';
import PropTypes from 'prop-types';
import cls from 'classnames';

export const thumbnailBodyThemes = {
  primary: 'primary',
  default: 'default',
  null: null,
};

const PageThumbnailBody = ({ isSelected, isLoading, theme, icon, onClick, children }) => {
  const indexTheme =
    isSelected && !isLoading
      ? thumbnailBodyThemes.primary
      : thumbnailBodyThemes.default;

  return (
    <div
      className={cls('page-thumbnail', {
        [`page-thumbnail--theme--${theme}`]: theme,
        'is-selected': isSelected && !isLoading,
        'is-loading': isLoading,
      })}
      onClick={onClick}
    >
      <div className="page-thumbnail__body">
        {isSelected && !isLoading &&
          <div className="page-thumbnail__indicator">
            {icon}
          </div>
        }
        <div className="page-thumbnail__counter">
          <div
            className={cls('index-number', {
              [`index-number--theme--${indexTheme}`]: indexTheme,
            })}
          />
        </div>
        {children}
      </div>
    </div>
  );
};

PageThumbnailBody.propTypes = {
  isSelected: PropTypes.bool,
  isLoading: PropTypes.bool,
  theme: PropTypes.oneOf(Object.values(thumbnailBodyThemes)),
  icon: PropTypes.element,
  onClick: PropTypes.func.isRequired,
  children: PropTypes.node,
};

PageThumbnailBody.defaultProps = {
  isSelected: false,
  isLoading: false,
  theme: thumbnailBodyThemes.null,
  icon: null,
  children: null,
};

export default PageThumbnailBody;
