const prefix = 'conditions';
export const ENABLE_CHOISE_BY_CLICKING_MODE = `${prefix}/ENABLE_CHOISE_BY_CLICKING_MODE`;
export const DISABLE_CHOISE_BY_CLICKING_MODE = `${prefix}/DISABLE_CHOISE_BY_CLICKING_MODE`;

const initialState = {
  isActiveChoiseByClickingMode: false,
};

export default function conditionsReducer(state = initialState, action = {}) {
  switch (action.type) {
    case ENABLE_CHOISE_BY_CLICKING_MODE:
      return {
        ...state,
        isActiveChoiseByClickingMode: true,
      };

    case DISABLE_CHOISE_BY_CLICKING_MODE:
      return {
        ...state,
        isActiveChoiseByClickingMode: false,
      };

    default:
      return state;
  }
}
