import { checkDiffAndApplyDefaultsAndSend } from '../Utils';
import { GROUPS, TYPES, TOOL_TYPES } from '../../../../constants';

// JSF-4028: in cases when we have fickle image urls, we need to check
// them by id to avoid re-load entire images list on every change
export const createGalleryList = (listFromState, listFromOperations) => {
  if (!listFromState) {
    return listFromOperations;
  }

  return listFromOperations.map((itemFromOperations) => {
    const itemWithSameIdFromState = listFromState.find((itemFromState) => {
      return itemFromState.id === itemFromOperations.id;
    });

    if (itemWithSameIdFromState) {
      return itemWithSameIdFromState;
    }
    return itemFromOperations;
  });
};

export const addToState = (stateItems, item, entity) => {
  return ([
    {
      ...item,
      type: entity,
      group: GROUPS.tools, // TODO: make investigation why this is setting here
    },
    ...(stateItems || []),
  ]);
};

export const deleteFromState = (state, item) => {
  return Array.isArray(state) && state.filter((el) => {
    return el.id !== item.id;
  });
};

export function doImages(operations, images) {
  const { properties } = operations[0];

  switch (properties.type) {
    case TYPES.list:
      return createGalleryList(images, properties.images);
    case TYPES.delete:
      return deleteFromState(images, properties);
    case TYPES.add:
      return addToState(images, properties, TOOL_TYPES.image);
    default:
      return images;
  }
}

export function doSignatures(operations, signatures) {
  const { properties } = operations[0];

  switch (operations[0].properties.type) {
    case TYPES.list: {
      return createGalleryList(signatures, properties.signatures);
    }
    case TYPES.delete:
      return deleteFromState(signatures, properties);
    case TYPES.add:
      return addToState(signatures, properties, TOOL_TYPES.signature);
    default:
      return signatures;
  }
}

export function updateSignatureDefaults(transport, state, action) {
  const { signature } = action;

  if (signature.curves && signature.curves[0]) {
    const fakeElement = {
      subType: signature.subType,
      type: TOOL_TYPES.signature,
      content: {
        color: signature.curves[0].color,
        lineWidth: {
          desktop: signature.curves[0].lineWidth,
        },
      },
    };

    return checkDiffAndApplyDefaultsAndSend(transport, fakeElement, state);
  }

  return {};
}
