import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

export const radioGroupBehaviors = {
  wrapItems: 'wrap-items',
  null: null,
};

export const radioGroupOffsets = {
  small: 'small',
  smallVertical: 'small-vertical',
  smallHorizontal: 'small-horizontal',
  null: null,
};

export const radioGroupTags = {
  div: 'div',
  ul: 'ul',
};

export const radioGroupTypes = {
  compact: 'compact',
  null: null,
};

const RadioGroup = ({
  behavior,
  children,
  offset,
  tag: Tag,
  type,
  attributes,
}) => {
  return (
    <Tag
      className={classnames('radio-group', {
        [`radio-group--behavior--${behavior}`]: behavior,
        [`radio-group--offset--${offset}`]: offset,
        [`radio-group--type--${type}`]: type,
      })}
      {...attributes}
    >
      {children}
    </Tag>
  );
};

RadioGroup.propTypes = {
  behavior: PropTypes.oneOf(Object.values(radioGroupBehaviors)),
  children: PropTypes.node.isRequired,
  offset: PropTypes.oneOf(Object.values(radioGroupOffsets)),
  tag: PropTypes.oneOf(Object.values(radioGroupTags)),
  type: PropTypes.oneOf(Object.values(radioGroupTypes)),
  attributes: PropTypes.shape({}),
};

RadioGroup.defaultProps = {
  behavior: radioGroupBehaviors.null,
  offset: radioGroupOffsets.null,
  tag: radioGroupTags.div,
  type: radioGroupTypes.null,
  attributes: null,
};

export default RadioGroup;
