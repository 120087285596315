import React from 'react';
import PropTypes from 'prop-types';

const CustomSettingsGroup = ({ children }) => {
  return (
    <div className="custom-settings__group">
      {children}
    </div>
  );
};

CustomSettingsGroup.propTypes = {
  children: PropTypes.node.isRequired,
};

export default CustomSettingsGroup;
