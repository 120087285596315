import omit from 'lodash/omit';

import { updateFillableElement } from 'ws-editor-lib/actions';
import * as conditionsActions from '@pdffiller/jsf-conditions/store/actions';

import * as selectors from '../selectors';

import {
  isNeedToPasteName,
  pastePreviousName,
  pastePreviousPrompt,
  isNeedToPastePrompt,
  isNeedToPasteMaxChars,
  pasteMaxCharsInCells,
} from '../helpers/functions';
import { isSignNow } from '../../helpers/utils';

function updateFillableElementWithPreprocessing(activeElement, opts = {}) {
  return (dispatch, getState) => {
    let activeElementTemplate = omit(activeElement.template, 'order');
    // we should automatically change "Max characters" empty value of the
    // active text/number element, if it has enabled "Characters in cells" param
    if (isNeedToPasteMaxChars(activeElement)) {
      activeElementTemplate = pasteMaxCharsInCells(activeElementTemplate);
    }
    // we should set the previous name/prompt if it is empty when user defocus
    // fillable field with an empty name\prompt
    // for SignNow app it's possible to have an empty name\prompt
    if (
      !isSignNow() && (
        isNeedToPasteName(activeElement) ||
        isNeedToPastePrompt(activeElement)
      )
    ) {
      const state = getState();
      const previousStateOfActiveElement =
        selectors.elements.getElementByIdFactory(activeElement.id)(state);

      if (isNeedToPasteName(activeElement)) {
        activeElementTemplate = pastePreviousName(
          activeElementTemplate,
          previousStateOfActiveElement.template,
        );
      }
      if (isNeedToPastePrompt(activeElement)) {
        activeElementTemplate = pastePreviousPrompt(
          activeElementTemplate,
          previousStateOfActiveElement.template,
        );
      }
    }

    return dispatch(updateFillableElement({
      id: activeElement.id,
      template: activeElementTemplate,
      subType: activeElement.subType,
    }, opts));
  };
}

export function updateFillableElementWithConditionsCheck(elem, opts) {
  return (dispatch, getState) => {
    conditionsActions.conditionsCheck(
      updateFillableElementWithPreprocessing,
      elem,
    )(elem, opts)(dispatch, getState);
  };
}

export default updateFillableElementWithPreprocessing;
