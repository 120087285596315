import { useContext, useEffect } from 'react';
import FocusControllerContext from './FocusControllerContext';

const FocusControllerDisableFocus = ({ disabled }) => {
  const { disableFocus, enableFocus } = useContext(FocusControllerContext);

  useEffect(() => {
    if (disabled) {
      disableFocus();
    } else {
      enableFocus();
    }
  }, [disabled]);

  return null;
};

export default FocusControllerDisableFocus;
