import React from 'react';
import PropTypes from 'prop-types';

const ContentConfiguratorFooter = ({ children }) => {
  return (
    <div className="content-configurator__footer">
      {children}
    </div>
  );
};

ContentConfiguratorFooter.propTypes = {
  children: PropTypes.node,
};

ContentConfiguratorFooter.defaultProps = {
  children: null,
};

export default ContentConfiguratorFooter;
