import React from 'react';
import PropTypes from 'prop-types';
import cls from 'classnames';

export const dialogBodySizes = {
  small: 'small',
  null: null,
};

export const dialogBodyThemes = {
  lightgray: 'lightgray',
  flatwhite: 'flat-white',
  null: null,
};

export const dialogBodyBehaviors = {
  contain: 'contain',
  insetOffsets: 'inset-offsets',
  null: null,
};

export const dialogBodyOffsets = {
  medium: 'medium',
  large: 'large',
  null: null,
};

const DialogBody = ({ theme, behavior, size, style, children }) => {
  return (
    <div
      className={cls('dialog', {
        [`dialog--size--${size}`]: size,
        [`dialog--theme--${theme}`]: theme,
        [`dialog--behavior--${behavior}`]: behavior,
      })}
      style={style}
    >
      <div className="dialog__body">
        {children}
      </div>
    </div>
  );
};

DialogBody.propTypes = {
  children: PropTypes.node,
  size: PropTypes.oneOf(Object.values(dialogBodySizes)),
  theme: PropTypes.oneOf(Object.values(dialogBodyThemes)),
  behavior: PropTypes.oneOf(Object.values(dialogBodyBehaviors)),
  style: PropTypes.shape({}),
};

DialogBody.defaultProps = {
  children: null,
  size: dialogBodySizes.null,
  theme: dialogBodyThemes.null,
  behavior: dialogBodyBehaviors.null,
  style: {},
};

export default DialogBody;
