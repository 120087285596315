import find from 'lodash/find';
import { wizardTraverseModes } from '../../helpers/const';
import {
  isFilledElement,
  isFillableElement,
  isRequiredElement,
  isDisabledElement,
} from '../helpers/functions';
import getIsEnabledByDependencies from './getIsEnabledByDependencies';
import * as baseSelectors from './baseSelectors';
import * as modeSelectors from './modeSelectors';

export const mixIsFillablePropertyToElement = ({ element }) => {
  return { ...element, isFillable: isFillableElement(element) };
};

export const mixIsFilledPropertyToElement = ({ element }) => {
  return { ...element, isFilled: isFilledElement(element) };
};

export const mixIsHighlightedPropertyToElement = ({ element, wizardTraverseMode }) => {
  const reqUnfill = wizardTraverseMode === wizardTraverseModes.requiredUnfilled;
  const allUnfill = wizardTraverseMode === wizardTraverseModes.allUnfilled;
  if (
    element.isFillable &&
    ((reqUnfill && isRequiredElement(element)) || allUnfill)
  ) {
    return { ...element, isHighlighted: !isFilledElement(element) };
  }

  return { ...element, isHighlighted: false };
};

const getElementsMapWithActiveElement = (activeElement, elementsMap) => {
  if (!activeElement) {
    return elementsMap;
  }
  return {
    ...elementsMap,
    [activeElement.id]: activeElement,
  };
};

export const mixConditionalProperty = (element) => {
  // for simple elements - do nothing
  if (!element.hasOwnProperty('template') || element.template === undefined) {
    return element;
  }

  // for elements that already have 'conditional' prop - do nothing
  if (element.template.hasOwnProperty('conditional')) {
    return element;
  }

  // make 'conditional' prop only for fillable elements that dont have 'conditional' at template
  return {
    ...element,
    template: {
      ...element.template,
      conditional: Boolean(element.template.dependency) !== false,
    },
  };
};

export const mixEnabledPropertyToElementConditions =
  ({
    element,
    activeElement,
    elementsMap: elMap,
    isFConstructorShown,
    roleId,
  }) => {
    const elementsMap = getElementsMapWithActiveElement(activeElement, elMap);
    const isEnabledByDependencies =
      // Need calc enabled if element has 'dependency'
      element.template && element.template.dependency &&

      // and it's not fConstructor mode
      !isFConstructorShown

        ? getIsEnabledByDependencies({
          dependency: element.template.dependency,
          elementsMap,
        })
        : true;
    const isDisabled = isDisabledElement(element, roleId);
    return {
      ...element,
      enabled: !isDisabled && isEnabledByDependencies,
      disabledByDependencies: !isEnabledByDependencies,
    };
  };

export function getIsEnabledByDependenciesSelector(elementId) {
  return (state) => {
    const elementsMap = baseSelectors.getElementsMap(state);
    const isFillingMode = modeSelectors.isFilling(state);

    const element = elementsMap[elementId];

    if (!element) {
      return true;
    }

    // Need calc enabled if element has 'dependency' and it's not fConstructor mode
    if (element.template && element.template.dependency && isFillingMode) {
      const elementsMapWithActive = getElementsMapWithActiveElement(element, elementsMap);

      return getIsEnabledByDependencies({
        activeElementId: elementId,
        dependency: element.template.dependency,
        elementsMap: elementsMapWithActive,
      });
    }

    return true;
  };
}

export function mixValidator(el, validators) {
  if (el.template && el.template.validatorId && validators) {
    const validator = find(validators, { id: el.template.validatorId });
    if (validator) {
      return {
        ...el,
        template: {
          ...el.template,
          validator,
        },
      };
    }
  }

  return el;
}
