const inputNodeName = 'input'.toUpperCase();
const textareaNodeName = 'textarea'.toUpperCase();
const divNodeName = 'div'.toUpperCase();
const reactSelectInput = 'Select-input';

export const getRelatedTarget = ({ isInternetExplorer11, event }) => {
  if (isInternetExplorer11 && !event.relatedTarget) {
    return document.activeElement;
  }

  return event.relatedTarget;
};

export const isInputOrTextarea = (node) => {
  if (node.disabled) return false;
  if (node.readOnly) return false; // dropdown in signnow fix SNF-1717
  if (node.nodeName === textareaNodeName) return true;
  if (
    (
      node.nodeName === inputNodeName && (
        node.attributes.type
          ? (
            node.attributes.type.value === 'text' ||
            node.attributes.type.value === 'number'
          )
          : true
      )
    ) ||
    (node.classList && node.classList.contains(reactSelectInput))
  ) return true;

  return false;
};

export const isDateInput = (node) => {
  if (
    node.nodeName === inputNodeName && (
      node.attributes.type
        ? node.attributes.type.value === 'date'
        : true
    )
  ) return true;

  return false;
};

export const isContentEditable = node => (
  node.nodeName === divNodeName &&
  node.attributes.contenteditable &&
  node.attributes.contenteditable.value === 'true'
);
