import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import LazyComponent from './LazyComponent';

function LazyComponentSubscribed({
  isModuleLoaded,
  literal,
  ...props
}) {
  return isModuleLoaded
    ? <LazyComponent literal={literal} props={props} />
    : props.children;
}

LazyComponentSubscribed.propTypes = {
  isModuleLoaded: PropTypes.bool.isRequired,
  literal: PropTypes.string.isRequired,
  children: PropTypes.node,
};

LazyComponentSubscribed.defaultProps = {
  children: null,
};

export default connect(
  (state, ownProps) => {
    const [moduleName] = ownProps.literal.split('.');
    return {
      isModuleLoaded: state.lazyLoad[moduleName],
    };
  },
)(LazyComponentSubscribed);
