import PropTypes from 'prop-types';
import React, { Component } from 'react';
import cls from 'classnames';

export const gridSizes = {
  parentWidth: 'parent-width',
  small: 'small',
  null: null,
};

export const gridOffsets = {
  small: 'small',
  null: null,
};

export const gridTypes = {
  compact: 'compact',
  topLayer: 'top-layer',
  null: null,
};

export const gridJustifies = {
  center: 'center',
  null: null,
};

export default class GridCell extends Component {
  static propTypes = {
    children: PropTypes.node,
    style: PropTypes.shape({}),
    attributes: PropTypes.shape({}),
    size: PropTypes.oneOf(
      Object.values(gridSizes),
    ),
    type: PropTypes.oneOf(
      Object.values(gridTypes),
    ),
    storeRef: PropTypes.func,
    onScroll: PropTypes.func,
    onMouseDown: PropTypes.func,
  };

  static defaultProps = {
    children: null,
    attributes: {},
    size: gridSizes.null,
    type: gridTypes.null,
    storeRef: null,
    onScroll: null,
    onMouseDown: null,
    style: {},
  };

  render() {
    const {
      size,
      type,
    } = this.props;

    return (
      <div
        ref={this.props.storeRef}
        onScroll={this.props.onScroll}
        onMouseDown={this.props.onMouseDown}
        style={this.props.style}
        className={cls('grid__cell', {
          [`grid__cell--size--${size}`]: size,
          [`grid__cell--type--${type}`]: type,
        })}
        {...this.props.attributes}
      >
        {this.props.children}
      </div>
    );
  }
}
