import omit from 'lodash/omit';
import { actionTypes } from 'ws-editor-lib/actions';

import {
  CONSTRUCTOR_SHOW,
} from '../../store/modules/viewport';

const prefix = 'constructorsErrors';
export const SET_CONSTRUCTOR_ERROR = `${prefix}/SET_CONSTRUCTOR_ERROR`;
export const SET_CONSTRUCTOR_ERRORS = `${prefix}/SET_CONSTRUCTOR_ERRORS`;
export const SET_SIMPLE_ERROR = `${prefix}/SET_SIMPLE_ERROR`;
export const CLEAR_SIMPLE_ERROR = `${prefix}/CLEAR_SIMPLE_ERROR`;

const initialState = {
  constructorErrors: {},
  simpleError: null,
};

export default function errorsReduces(state = initialState, action = {}) {
  switch (action.type) {
    case actionTypes.REMOVE_FILLABLE_ELEMENT: {
      return {
        ...state,
        constructorErrors: omit(state.constructorErrors, action.id),
      };
    }

    case actionTypes.REMOVE_ELEMENTS: {
      return {
        ...state,
        constructorErrors: omit(state.constructorErrors, action.ids),
      };
    }

    case SET_CONSTRUCTOR_ERROR:
      return {
        ...state,
        constructorErrors: {
          ...state.constructorErrors,
          [action.id]: {
            isValid: action.isValid,
            errors: action.errors,
          },
        },
      };

    case SET_CONSTRUCTOR_ERRORS:
      return {
        ...state,
        constructorErrors: {
          ...state.constructorErrors,
          ...action.errors,
        },
      };

    case SET_SIMPLE_ERROR:
      return {
        ...state,
        simpleError: action.simpleError,
      };

    case CLEAR_SIMPLE_ERROR:
      return {
        ...state,
        simpleError: initialState.simpleError,
      };

    case CONSTRUCTOR_SHOW:
    case actionTypes.HIDE_CONSTRUCTOR_PREVIEW:
      return initialState;

    default:
      return state;
  }
}
