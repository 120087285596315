import PropTypes from 'prop-types';
import React, { Component } from 'react';
import PortalLayerRefContext from './PortalLayerRefContext';

export default class PortalLayerRefProvider extends Component {
  static propTypes = {
    children: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      portalLayerRef: null,
    };
  }

  getPortalLayerRef = () => {
    return this.state.portalLayerRef;
  };

  storeRef = (ref) => {
    this.setState({
      portalLayerRef: ref,
    });
  };

  render() {
    return (
      <PortalLayerRefContext.Provider value={this.state.portalLayerRef}>
        {this.props.children({ storeRef: this.storeRef })}
      </PortalLayerRefContext.Provider>
    );
  }
}
