import clamp from 'lodash/clamp';
import { getValidColor } from '../../../helpers/graphicUtils';
import { allPagesLiteral } from '../../../helpers/const';
import { isNumeric } from '../../../helpers/utils';

/**
 * get css-style for positioning
 *
 * @param {string} align - string of 2 chars: '[TMB][LCR]' - top/middle/bottom, left/center/right
 * @param {number} offsetT - offset from top in pixels
 * @param {number} offsetB - offset from bottom in pixels
 * @param {number} offsetL - offset from left in pixels
 * @param {number} offsetR - offset from right in pixels
 * @param {number} rotation - rotation of item, in degrees
 * @returns {object} css-compatible object
 */
export const getPageAttributePosition = (attrContent) => {
  const { align, offsetT, offsetB, offsetL, offsetR, rotation } = attrContent;
  const transform = [
    align.indexOf('M') > -1
      ? 'translateY(-50%)'
      : '',

    align.indexOf('C') > -1
      ? 'translateX(-50%)'
      : '',

    rotation
      ? `rotate(${rotation}deg)`
      : '',

  ].filter((e) => {
    return e !== '';
  }).join(' ').trim();

  return {
    ...(
      align.indexOf('T') > -1
        ? { top: offsetT }
        : {}
    ),
    ...(
      align.indexOf('B') > -1
        ? { bottom: offsetB }
        : {}
    ),
    ...(
      align.indexOf('L') > -1
        ? { left: offsetL }
        : {}
    ),
    ...(
      align.indexOf('R') > -1
        ? { right: offsetR }
        : {}
    ),
    ...(
      align.indexOf('M') > -1
        ? { top: '50%', transform: 'translateY(-50%)' }
        : {}
    ),
    ...(
      align.indexOf('C') > -1
        ? { left: '50%', transform: 'translateX(-50%)' }
        : {}
    ),
    ...(transform
      ? { transform }
      : {}
    ),
  };
};

/**
 * get style for given attributes
 *
 * @param {object} attrs - given attributes
 * @param {number} scale - need for fontSize
 * @returns {object} css-compatible object
 */
export const getPageAttributeStyle = (attrContent) => {
  return {
    opacity: isNumeric(attrContent.alpha)
      ? attrContent.alpha
      : 1,
    color: getValidColor(attrContent.fontColor),

    fontFamily: attrContent.fontFamily,
    fontWeight: attrContent.bold
      ? 'bold'
      : 'normal',

    fontSize: `${attrContent.fontSize}px`,
    fontStyle: attrContent.italic
      ? 'italic'
      : 'normal',

    textDecoration: attrContent.underline
      ? 'underline'
      : 'none',
    ...getPageAttributePosition(attrContent),
  };
};

export const getDraggingItemPos = ({
  attrContent,
  originalSize,
  dragStartPoint,
  dragEndPoint,
  dragLimiterProps,
  scale,
}) => {
  const { width, height } = dragLimiterProps;

  const position = getPageAttributePosition(attrContent);

  const dPoint = {
    x: (dragEndPoint.x - dragStartPoint.x) / scale,
    y: (dragEndPoint.y - dragStartPoint.y) / scale,
  };

  const maxX = Math.max(
    (originalSize.width / 2) - width,
    (isNumeric(position.left)
      ? position.left
      : 0
    ) + (isNumeric(position.right)
      ? position.right
      : 0
    ),
  );
  const maxY = Math.max(
    (originalSize.height / 4) - height,
    (isNumeric(position.top)
      ? position.top
      : 0
    ) + (
      isNumeric(position.bottom)
        ? position.bottom
        : 0
    ),
  );

  return {
    ...(isNumeric(position.top)
      ? {
        top: clamp(position.top + dPoint.y, 0, maxY),
      }
      : {}
    ),
    ...(isNumeric(position.bottom)
      ? {
        bottom: clamp(position.bottom - dPoint.y, 0, maxY),
      }
      : {}
    ),
    ...(isNumeric(position.left)
      ? {
        left: clamp(position.left + dPoint.x, 0, maxX),
      }
      : {}
    ),
    ...(isNumeric(position.right)
      ? {
        right: clamp(position.right - dPoint.x, 0, maxX),
      }
      : {}
    ),
  };
};

export const getOffsetObject = ({ top, left, bottom, right }) => {
  return {
    offsetT: isNumeric(top)
      ? top
      : 0,

    offsetB: isNumeric(bottom)
      ? bottom
      : 0,

    offsetL: isNumeric(left)
      ? left
      : 0,

    offsetR: isNumeric(right)
      ? right
      : 0,
  };
};

export const getLimiterStyle = ({ align, width, height, offset }) => {
  return {
    ...(align.match('T')
      ? { top: 0 }
      : {}),

    ...(align.match('B')
      ? { bottom: 0 }
      : {}),

    ...(align.match('L')
      ? { left: 0 }
      : {}),

    ...(align.match('R')
      ? { right: 0 }
      : {}),

    ...(align.match('C')
      ? {
        width,
        left: '50%',
        transform: 'translateX(-50%)',
      }
      : {}),

    ...(!align.match('C')
      ? {
        minWidth: width + offset.leftright, // if item's width with offset is more than 50%
      }
      : {}),

    minHeight: height + offset.topbottom, // if item's height with offset is more than 25%
  };
};

export const getIsPageAttributeVisible = (attrContent, pageIndex) => {
  const { visible, pagesRange } = attrContent;

  if (!visible) {
    return false;
  }
  if (pagesRange === allPagesLiteral || !pagesRange) {
    return true;
  }

  // pagesRange is string, i.e.: '1,2-13,40-108'
  const ranges = pagesRange // '1,2-13,40-108'
    .split(',') // => ['1', '2-13', '40-108']
    .map((range) => {
      // [=> [[1], [2, 13], [40, 108]]
      return range
        .split('-')
        .map((bound) => {
          return parseInt(bound, 10);
        });
    })
    .map((boundArr) => {
      // => [[1, 1], [2, 13], [40, 108]]
      return boundArr.length < 2
        ? boundArr.concat(boundArr)
        : boundArr;
    });

  for (let index = 0; index < ranges.length; ++index) {
    // '+1' is because pagesRange comes in user indexes (start from 1),
    // activePage - logical (starts from 0)
    if (pageIndex >= ranges[index][0] && pageIndex <= ranges[index][1]) {
      return true;
    }
  }

  return false;
};

export const getBatesStringValue = ({ startNumber, digitCount }, pageIndex = 0) => {
  if (digitCount === 0) {
    return '';
  }

  const numberVal = String(startNumber + pageIndex);
  if (numberVal.length >= digitCount) {
    return numberVal;
  }

  // добавим в начало строки нули, чтобы длина строки получилась не меньше digitCount
  const zerosString = Array((digitCount - numberVal.length) + 1).join('0');
  return zerosString + numberVal;
};
