import { takeEvery, select, put, all } from 'redux-saga/effects';
import {
  activateTool,
  activateMode,
  actionTypes as AT,
} from 'ws-editor-lib/actions';
import editorModes from 'ws-editor-lib/constants/editorModes';
import {
  CONSTRUCTOR_SHOW,
  CONSTRUCTOR_HIDE,
  PAGE_ATTRIBUTES_CONSTRUCTOR_SHOW,
  VERSIONS_PANEL_SHOW,
} from 'jsfcore/store/modules/viewport';
import {
  SET_SEARCH_MODE_ACTIVE,
  setIsSearchModeActive,
} from 'jsfcore/store/modules/search';
import openProcessingModal from 'jsfcore/store/sagas/modals/openProcessingModal';
import { selectors } from 'jsfcore';

export function* deactivateSearchIfNeed() {
  const isSearchModeActive = yield select(selectors.mode.isSearch);
  if (isSearchModeActive) {
    yield put(setIsSearchModeActive(false));
  }
}

/**
 * Activate default tool on search activate
 */
export function* onSearchActivate(action) {
  if (action.isActive) {
    const activeTool = yield select(selectors.base.getActiveTool);

    if (activeTool) {
      const isConstructorMode = yield select(selectors.mode.isConstructor);

      const { type, subType } = isConstructorMode
        ? yield select(selectors.getConstructorDefaultTool)
        : yield select(selectors.getDefaultTool);

      if (activeTool.type !== type || activeTool.subType !== subType) {
        yield put(activateTool(type, subType));
      }
    }
  }
}

function* watchAnyConstructorShow() {
  yield takeEvery(
    [
      CONSTRUCTOR_SHOW,
      PAGE_ATTRIBUTES_CONSTRUCTOR_SHOW,
      AT.SHOW_CONSTRUCTOR_PREVIEW,
      VERSIONS_PANEL_SHOW,
    ],
    deactivateSearchIfNeed,
  );
}

export function* onSetActiveElement({ activeStatus }) {
  if (activeStatus) {
    yield deactivateSearchIfNeed();
  }
}

function* onActivatePendingModeForAddons() {
  yield* openProcessingModal({ shouldReturnFocusAfterClose: false });
  yield put(activateMode(editorModes.pending));
}

function* watchFConstructorHide() {
  yield takeEvery(CONSTRUCTOR_HIDE, deactivateSearchIfNeed);
}

function* watchActivateFillableTool() {
  yield takeEvery(AT.ACTIVATE_FILLABLE_TOOL, deactivateSearchIfNeed);
}

function* watchSetActiveElement() {
  yield takeEvery(AT.SET_ACTIVE_ELEMENT, onSetActiveElement);
}

function* watchSearchActive() {
  yield takeEvery(SET_SEARCH_MODE_ACTIVE, onSearchActivate);
}

function* watchOnActivatePendingModeForAddons() {
  yield takeEvery(
    AT.ACTIVATE_PENDING_MODE_FOR_ADDONS,
    onActivatePendingModeForAddons,
  );
}

export default function* modesRootSaga() {
  yield all([
    watchAnyConstructorShow(),
    watchFConstructorHide(),
    watchSearchActive(),
    watchActivateFillableTool(),
    watchSetActiveElement(),
    watchOnActivatePendingModeForAddons(),
  ]);
}
