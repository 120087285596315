import mapValues from 'lodash/mapValues';
import omitBy from 'lodash/omitBy';
import findIndex from 'lodash/findIndex';
import getIsSignnowModalVisible from '../selectors/getIsSignnowModalVisible';
import elementsSelectors from '../selectors/elementsSelectors';

import { popupStatuses } from '../../helpers/const';
import { elemTypes, elemSubTypes } from '../../helpers/elemTypes';

import {
  isUnfilledElement,
  isFilledCheckmarkElement,
  isRadioElement,
} from './functions';

// TODO: write test
export const reduceRadioByGroups = (state, elem) => {
  return {
    ...state,
    [elem.template.radioGroupId]: [
      ...(state[elem.template.radioGroupId]
        ? state[elem.template.radioGroupId]
        : []
      ),
      elem,
    ],
  };
};

export const unfilledFillableElements = (elems) => {
  return {
    radio: omitBy(
      mapValues(
        elems.filter(isRadioElement).reduce(reduceRadioByGroups, {}),
        (array) => {
          return array.filter(isFilledCheckmarkElement).length > 0
            ? false
            : array;
        },
      ),
      (item) => {
        return !item;
      },
    ),
    other: elems.filter((el) => {
      return !isRadioElement(el);
    }).filter(isUnfilledElement),
  };
};

export const groupByRadioGroup = (elems) => {
  // Кэшируем радио группы для большей производительности
  const cashObj = {};
  return elems.reduce((state, elem) => {
    if (isRadioElement(elem)) {
      const indexExist =
        cashObj[elem.template.radioGroupId] !== undefined
          ? cashObj[elem.template.radioGroupId]
          : -1;

      if (indexExist !== -1) {
        state[indexExist].push(elem);
        return state;
      }

      cashObj[elem.template.radioGroupId] = state.length;
      state.push([elem]);
      return state;
    }

    state.push(elem);
    return state;
  }, []);
};

export const getFocusElement = (state) => {
  return elementsSelectors.getActiveElement(state);
};

export const isModalVisible = (state) => {
  return (
    state.modal &&
    state.modal.interfaceLock
  ) || (
    state.viewport &&
    state.viewport.isHrefModalShown
  ) ||
  getIsSignnowModalVisible(state) ||
  false;
};

export const isModalItemsToRender = (state) => {
  return (
    state.modal &&
    state.modal.itemsToRender.length > 0
  );
};

export const isPopupVisible = (state) => {
  return (
    state.viewport &&
    state.viewport.popupVisibility !== popupStatuses.hidden
  ) || false;
};

export const getForceFocusElementObject = (state, element) => {
  if (
    !state.events ||
    !state.events.forceFocusElementObject ||
    !element || !element.id
  ) {
    return false;
  }

  if (state.events.forceFocusElementObject.id === element.id) {
    return state.events.forceFocusElementObject;
  }

  return false;
};

export const getDefaultTool = (state) => {
  const { toolbar } = state.ws.features;
  const textId = `tools.${elemTypes.text}.${elemSubTypes.none}`;
  const hasText = findIndex(toolbar, ({ id, visible }) => {
    return id === textId && visible;
  }) > -1;
  if (hasText) {
    return { type: elemTypes.text, subType: elemSubTypes.none };
  }

  return { type: elemTypes.selection, subType: elemSubTypes.none };
};
