import PropTypes from 'prop-types';
import React, { Component } from 'react';
import cls from 'classnames';

export const logoSizes = {
  extraSmall: 'extra-small',
  large: 'large',
  null: null,
};

export const logoOffsets = {
  extraSmallHorizontal: 'extra-small-horizontal',
  mediumHorizontal: 'medium-horizontal',
  smallHorizontal: 'small-horizontal',
  null: null,
};

export const logoBehaviors = {
  centeredContent: 'centered-content',
  null: null,
};

export default class Logo extends Component {
  static propTypes = {
    children: PropTypes.node,

    size: PropTypes.oneOf(
      Object.values(logoSizes),
    ),
    offset: PropTypes.oneOf(
      Object.values(logoOffsets),
    ),
    behavior: PropTypes.oneOf(
      Object.values(logoBehaviors),
    ),

    onClick: PropTypes.func,
  };

  static defaultProps = {
    children: null,
    size: logoSizes.null,
    offset: logoOffsets.mediumHorizontal,
    behavior: logoBehaviors.null,

    onClick: null,
  };

  render() {
    const { size, offset, behavior } = this.props;

    return (
      <button
        type="button"
        className={cls('logo', {
          [`logo--size--${size}`]: size,
          [`logo--offset--${offset}`]: offset,
          [`logo--behavior--${behavior}`]: behavior,
        })}
        onClick={this.props.onClick}
      >
        {this.props.children}
      </button>
    );
  }
}
