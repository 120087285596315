/* eslint-disable */

const ZERO_TOL = 0.0001;

class Segment {
  constructor() {
    //bisector 'right' normal
    this.nr = [0, 0];

    //bisector 'left' normal
    this.nl = [0, 0];

    // delta, first segment
    this.d1 = [0, 0];

    // delta, second segment
    this.d2 = [0, 0];

    // first segment length
    this.dl1 = 0;

    // first second length
    this.dl2 = 0;

    // unit vector, direction of bisector
    this.u = [0, 0];

    // distance measure from segment intersection, along direction of bisector
    this.dist = 0;
  }
}

export class BezierSpline {
  constructor(points) {
    this.points = points;
    this.crp = [];
  }

  construct(t) {
    const count = this.points.length - 1;
    if (count < 2) return;      // safety valve

    const s = new Segment();

    this.left(s, t);        // 'leftmost' cage, open spline (requires a reflection point)

    // 'middle' cages
    for (let i = 1; i < count - 1; i++) {
      this.middle(s, i, t);
    }

    this.right(s, t);        // 'rightmost' cage, open splne (requires a reflection point)
  }

  // compute 'middle' control cages
  middle(s, i, t) {
    this.normals(s, i);

    this.crp[i] = [];
    this.crp[i][0] = [this.points[i][0], this.points[i][1]];
    this.crp[i][1] = [s.nl[0], s.nl[1]];

    if (s.dist > ZERO_TOL) {
      if (this.iscw(this.points, i)) this.cw(s, i, t);
      else this.ccw(s, i, t);
    } else {
      s.nr[0] = this.points[i][0] + t * s.d1[0];
      s.nr[1] = this.points[i][1] + t * s.d1[1];
      s.nl[0] = this.points[i][0] + t * s.d2[0];
      s.nl[1] = this.points[i][1] + t * s.d2[1];
    }

    this.crp[i][2] = [s.nr[0], s.nr[1]];
    this.crp[i][3] = [this.points[i + 1][0], this.points[i + 1][1]];
  }

  normals(s, i) {
    s.d1[0] = this.points[i][0] - this.points[i + 1][0];
    s.d1[1] = this.points[i][1] - this.points[i + 1][1];
    s.dl1 = Math.sqrt(s.d1[0] * s.d1[0] + s.d1[1] * s.d1[1]);
    s.d1[0] /= s.dl1;
    s.d1[1] /= s.dl1;

    s.d2[0] = this.points[i + 2][0] - this.points[i + 1][0];
    s.d2[1] = this.points[i + 2][1] - this.points[i + 1][1];
    s.dl2 = Math.sqrt(s.d2[0] * s.d2[0] + s.d2[1] * s.d2[1]);
    s.d2[0] /= s.dl2;
    s.d2[1] /= s.dl2;

    s.u[0] = s.d1[0] + s.d2[0];
    s.u[1] = s.d1[1] + s.d2[1];
    s.dist = Math.sqrt(s.u[0] * s.u[0] + s.u[1] * s.u[1]);
    s.u[0] /= s.dist;
    s.u[1] /= s.dist;
  }

  // 'leftmost' control cage, open spline
  left(s, t) {
    this.normals(s, 0);

    const p = [0, 0];
    if (s.dist > ZERO_TOL) {
      if (this.iscw(this.points, 0)) this.cw(s, 0, t);
      else this.ccw(s, 0, t);

      const m = [
        0.5 * (this.points[0][0] + this.points[1][0]),
        0.5 * (this.points[0][1] + this.points[1][1]),
      ];

      p[0] = this.points[0][0] - m[0];
      p[1] = this.points[0][1] - m[1];

      // normal at midpoint
      const n = [-2.0 / s.dl1 * p[1], 2.0 / s.dl1 * p[0]];

      // upper triangle of symmetric transform matrix
      const a11 = n[0] * n[0] - n[1] * n[1];
      const a12 = 2 * n[1] * n[1];
      const a22 = n[1] * n[1] - n[0] * n[0];

      const d = [s.nr[0] - m[0], s.nr[1] - m[1]];

      // coordinates of reflected vector
      p[0] = m[0] + a11 * d[0] + a12 * d[1];
      p[1] = m[1] + a12 * d[0] + a22 * d[1];
    } else {
      s.nr[0] = this.points[1][0] + t * s.d1[0];
      s.nr[1] = this.points[1][1] + t * s.d1[1];

      s.nl[0] = this.points[1][0] + t * s.d2[0];
      s.nl[1] = this.points[1][1] + t * s.d2[1];

      p[0] = this.points[0][0] + t * s.d1[0];
      p[1] = this.points[0][1] + t * s.d1[1];
    }

    this.crp[0] = [];
    this.crp[0][0] = [this.points[0][0], this.points[0][1]];
    this.crp[0][1] = [p[0], p[1]];
    this.crp[0][2] = [s.nr[0], s.nr[1]];
    this.crp[0][3] = [this.points[1][0], this.points[1][1]];
  }

  // 'rightmost' control cage, open spline
  right(s, t) {
    const count = this.points.length - 1;
    const p = [0, 0];
    if (s.dist > ZERO_TOL) {
      const m = [
        0.5 * (this.points[count - 1][0] + this.points[count][0]),
        0.5 * (this.points[count - 1][1] + this.points[count][1]),
      ];

      p[0] = this.points[count][0] - m[0];
      p[1] = this.points[count][1] - m[1];

      // normal at midpoint
      const n = [-2.0 / s.dl2 * p[1], 2.0 / s.dl2 * p[0]];

      // upper triangle of symmetric transform matrix
      const a11 = n[0] * n[0] - n[1] * n[1];
      const a12 = 2 * n[1] * n[1];
      const a22 = n[1] * n[1] - n[0] * n[0];

      const d = [s.nl[0] - m[0], s.nl[1] - m[1]];

      // coordinates of reflected vector
      p[0] = m[0] + a11 * d[0] + a12 * d[1];
      p[1] = m[1] + a12 * d[0] + a22 * d[1];
    } else {
      p[0] = this.points[count][0] - t * s.d2[0];
      p[1] = this.points[count][1] - t * s.d2[1];
    }

    this.crp[count - 1] = [];
    this.crp[count - 1][0] = [this.points[count - 1][0], this.points[count - 1][1]];
    this.crp[count - 1][1] = [s.nl[0], s.nl[1]];
    this.crp[count - 1][2] = [p[0], p[1]];
    this.crp[count - 1][3] = [this.points[count][0], this.points[count][1]];
  }

  // bisector normal computations, clockwise knot order
  cw(s, i, t) {
    let dt = t * s.dl1;

    s.nr[0] = this.points[i + 1][0] - dt * s.u[1];
    s.nr[1] = this.points[i + 1][1] + dt * s.u[0];

    dt = t * s.dl2;
    s.nl[0] = this.points[i + 1][0] + dt * s.u[1];
    s.nl[1] = this.points[i + 1][1] - dt * s.u[0];
  }

  // bisector normal computations, counter-clockwise knot order
  ccw(s, i, t) {
    let dt = t * s.dl2;

    s.nl[0] = this.points[i + 1][0] - dt * s.u[1];
    s.nl[1] = this.points[i + 1][1] + dt * s.u[0];

    dt = t * s.dl1;
    s.nr[0] = this.points[i + 1][0] + dt * s.u[1];
    s.nr[1] = this.points[i + 1][1] - dt * s.u[0];
  }

  // clockwise order for three-knot sequence?
  iscw(p, i) {
    return (
      (p[i + 2][1] - p[i][1]) * (p[i + 1][0] - p[i][0]) >
      (p[i + 1][1] - p[i][1]) * (p[i + 2][0] - p[i][0])
    );
  }
}

/* eslint-enable */
