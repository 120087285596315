import includes from 'lodash/includes';
import mapValues from 'lodash/mapValues';
import get from 'lodash/get';
import find from 'lodash/find';
import map from 'lodash/map';
import { thisDevice } from '@pdffiller/jsf-useragent';

import { getValidColor } from '../../../../helpers/graphicUtils';
import {
  iosKeyboards,
  iosKeyboardsWithPanel,
  iosDateKeyboards,
  valignValues,
  fontsMap,
  letterCases,
  textToolFillablePadding,
  textFieldViewModes,
  defaultLineHeight,
} from '../../../../helpers/const';
import {
  textToolSettings,
  elemSubTypes,
  isGraphicTextType,
} from '../../../../helpers/elemTypes';
import {
  isFillable,
} from '../../../../store/helpers/functions';
import isSignNow from '../../../../helpers/const/isSignNow';
import { getClosestItemOfArray, getElementCursor } from '../../../../helpers/utils';

const sentenceRegExp = /(\.|^)\s*[\wа-яА-Я]/g;
const getSentenceAssignedText = (text) => {
  return text.replace(sentenceRegExp, (substr) => {
    return substr.slice(0, -1) + substr.slice(-1).toUpperCase();
  });
};

export const getCaseAssignedText = ({ text, letterCase }) => {
  if (!text || text === '') {
    return text;
  }

  switch (letterCase) {
    case letterCases.upper:
      return text.toUpperCase();

    case letterCases.lower:
      return text.toLowerCase();

    case letterCases.sentence:
      return getSentenceAssignedText(text);

    case letterCases.none:
    default:
      return text;
  }
};

export const getPaginationScrollFix = ({ pageId, activePageId, workspace }) => {
  if (activePageId < pageId) {
    return -1 * (pageId - activePageId) * workspace.height;
  }
  return 0;
};

export const isReadonly = ({
  happeningNowResize,
  isDropdown,
  isDate,
  isSignature,
  isDragging,
  customDropdownValueSelected,
  isFillable: isFillableElement,
}) => {
  const isReadOnlyDevice = isSignNow()
    ? thisDevice.isTablet
    : !thisDevice.isIOS;

  return (
    happeningNowResize || isSignature ||

    // Это Fillable дата
    // отобразим стандартный контрол если это телефон/планшет
    // apple и поле типа date
    (isFillableElement && isDate && isReadOnlyDevice) ||

    // В Safari desktop происходит Drag
    (isDragging && thisDevice.isSafariDesktop) ||

    (isDropdown && !customDropdownValueSelected)
  );
};

export const getLinesCount = ({
  height, fontSize, lineHeight = defaultLineHeight, padding = 0,
}) => {
  const realLineHeight = fontSize * lineHeight;
  return Math.round((height - (padding * 2)) / realLineHeight);
};

export const getVisibleLinesCount = ({
  height, fontSize, lineHeight = defaultLineHeight, padding = 0,
}) => {
  const realLineHeight = fontSize * lineHeight;
  return Math.floor((height - (padding * 2)) / realLineHeight);
};

export const getTextToolSettings = ({ type, subType }) => {
  if (subType === elemSubTypes.text.overlying) {
    return textToolSettings[subType];
  }

  return textToolSettings[type];
};

const scalebleProps = [
  'width', 'height', 'fontSize', 'marginTop', 'marginLeft',
  'marginRight', 'marginBottom', 'padding', 'minHeight',
  'minWidth', 'paddingTop', 'x', 'y', 'maxWidth', 'maxHeight',
];

const pxableProps = [
  'width', 'height', 'maxWidth', 'maxHeight', 'paddingTop', 'fontSize',
  'minHeight', 'minWidth', 'padding', 'paddingLeft', 'paddingRight',
  'paddingTop', 'paddingBottom',
];

export const pxProps = (obj) => {
  return mapValues(obj, (value, prop) => {
    return includes(pxableProps, prop) && !prop.length
      ? `${value}px`
      : value;
  });
};

export const scaleProps = (obj, scale) => {
  return mapValues(obj, (value, prop) => {
    if (prop === 'resizingGeometry' && value) {
      return scaleProps(value, scale);
    }
    return includes(scalebleProps, prop)
      ? value * scale
      : value;
  });
};

export const unscaleProps = (obj, scale) => {
  return mapValues(obj, (value, prop) => {
    if (prop === 'resizingGeometry' && value) {
      return scaleProps(value, scale);
    }
    return includes(scalebleProps, prop)
      ? value / scale
      : value;
  });
};

export const getStyle = (props, isForTextarea) => {
  const {
    paddingTop,
    align,
    fontSize,
    lineHeight = defaultLineHeight,
    width,
    height,
    isSticky,
    isTextBox,
    bold,
    italic,
    underline,
    fontFamily,
    fontColor,
    padding,
    maxWidth,
    maxHeight,
    isSignature,
    isFillable: isFillableElement,
    isActiveElement,
    whiteSpace,
  } = props;

  const font = fontsMap[fontFamily];

  const letterSpacing = font && font.letterSpacing
    ? font.letterSpacing
    : false;

  const crossBrowserWidthFix = (
    thisDevice.isIOS
      ? 6
      : 0
  );

  // IE11: free tool: пробел, fillable: enter. Без такого фикса дергается
  const crossBrowserHeightFix = (
    thisDevice.isIOS
      ? 1
      : 0
  ) + (
    thisDevice.isFirefoxDesktop
      ? padding
      : 0
  );

  const crossBroserMargins = isForTextarea
    ? {
      marginTop: thisDevice.isIOS
        ? '-1px'
        : '0px',

      marginLeft: thisDevice.isIOS
        ? '-3px'
        : '0px',
    }
    : {};

  return pxProps({
    height: height + (
      isForTextarea
        ? crossBrowserHeightFix
        : 0
    ),
    width: width + (
      isForTextarea
        ? crossBrowserWidthFix
        : 0
    ),
    fontWeight: bold
      ? 'bold'
      : 'normal',

    fontStyle: italic
      ? 'italic'
      : 'normal',

    textDecoration: underline
      ? 'underline'
      : 'none',

    color: getValidColor(fontColor),
    textAlign: align,
    textRendering: 'geometricPrecision',
    fontSize,
    lineHeight,
    fontFamily,
    cursor: getElementCursor(isActiveElement),
    ...(isForTextarea
      ? { whiteSpace }
      : {}
    ),
    padding: isForTextarea && padding
      ? padding
      : 0,
    ...(
      isForTextarea
        ? { overflow: 'hidden' }
        : {}
    ),

    ...(isForTextarea && paddingTop
      ? { paddingTop }
      : {}),

    ...crossBroserMargins,

    ...(letterSpacing
      ? { letterSpacing }
      : {}),
    ...(!(isSticky || isTextBox)
      ? {
        maxHeight: maxHeight + (
          isForTextarea
            ? crossBrowserHeightFix
            : 0
        ),
        maxWidth: maxWidth + (
          isForTextarea
            ? crossBrowserWidthFix
            : 0
        ),
      }
      : {}
    ),
    ...(isSignature && !isFillableElement &&
      { textRendering: 'optimizeSpeed' }
    ),
  });
};

export const getRulerStyle = (props) => {
  const {
    fontSize,
    lineHeight = defaultLineHeight,
    bold,
    italic,
    underline,
    fontFamily,
    maxWidth,
    minWidth,
    minHeight,
    padding,
    resizingGeometry,
  } = props;

  const font = fontsMap[fontFamily];
  const letterSpacing = font && font.letterSpacing
    ? font.letterSpacing
    : false;

  return pxProps({
    fontWeight: bold
      ? 'bold'
      : 'normal',

    fontStyle: italic
      ? 'italic'
      : 'normal',

    textDecoration: underline
      ? 'underline'
      : 'none',

    fontSize,
    fontFamily,
    whiteSpace: 'pre-wrap',
    wordWrap: 'break-word',
    display: 'inline-block',
    pointerEvents: 'none',
    textRendering: 'geometricPrecision',
    lineHeight,

    paddingTop: padding,
    paddingBottom: padding,
    paddingLeft: padding,
    paddingRight: padding,

    ...(letterSpacing
      ? { letterSpacing }
      : {}),

    ...(resizingGeometry
      ? {
        width: resizingGeometry.width,
      }
      : {
        ...(minHeight
          ? { minHeight }
          : {}),

        ...(minWidth
          ? { minWidth }
          : {}),

        ...(maxWidth
          ? { maxWidth }
          : {}),
      }),
  });
};

export const fixRulerText = (text) => {
  return text + (
    text.slice(-1) === '\n'
      ? ' '
      : ''
  ) + (
    text.length === 0
      ? ' '
      : ''
  );
};

const getPaddingTopOnTopVA = ({ scale, scaledPadding }) => {
  return scaledPadding / scale;
};

const getPaddingTopOnMiddleVA =
  ({ unscaledHeight, scale, height, scaledPadding }) => {
    return (((unscaledHeight * scale) - (height - (scaledPadding * 2))) / 2) / scale;
  };

const getPaddingTopOnBottomVA =
  ({ unscaledHeight, scale, height, scaledPadding }) => {
    return ((unscaledHeight * scale) - (height - scaledPadding)) / scale;
  };

const getPaddingTopOnVA = (valign) => {
  if (!valign) {
    return () => {
      return textToolFillablePadding;
    };
  }
  return {
    [valignValues.top]: getPaddingTopOnTopVA,
    [valignValues.middle]: getPaddingTopOnMiddleVA,
    [valignValues.bottom]: getPaddingTopOnBottomVA,
  }[valign];
};

export const getStateByNewRuler = (
  { width, height, linesCount, realSize },
  props,
  state,
) => {
  const {
    scale,
    element,
    viewMode,
    valign,
    fontSize,
    lineHeight,
    padding,
  } = props;

  const scaledPadding = (state.padding || 0) * scale;
  const unscaledWidth = Math.ceil(width * 10) / 10.0 / scale;
  const unscaledHeight = Math.ceil(height * 10) / 10.0 / scale;

  const isWidthFixed = state.isWidthFixed && !props.resizingGeometry;
  const isOnlyIncrease = state.isOnlyIncrease && !props.resizingGeometry;
  const isFillableElement = isFillable(element);

  const calcPaddingTop = getPaddingTopOnVA(valign);

  const isMiddleVA = props.valign === valignValues.middle;
  const isBottomVA = props.valign === valignValues.bottom;
  const isFillableSignature = (props.isSignature && isFillable(element));
  const isWarningOrNotActiveCombo = viewMode === 'warning' || (viewMode === 'combo' && !props.isActiveElement);
  const isMultiLine = getVisibleLinesCount({
    height: get(element, 'template.height', height),
    fontSize,
    lineHeight,
    padding,
  }) > 1;

  const calculatedPaddingTop = calcPaddingTop({
    unscaledHeight,
    scale,
    height: get(realSize, 'height', height),
    scaledPadding,
    ...(
      (isMiddleVA || isBottomVA || isFillableSignature) &&
      isFillable(element)
        ? {
          unscaledHeight: element.template.height,
        }
        : {}
    ),
    ...(
      isMiddleVA && isWarningOrNotActiveCombo && !isMultiLine
        ? {
          height: (fontSize * lineHeight + state.padding * 2) * scale,
        }
        : {}
    ),
    ...(
      isBottomVA && isWarningOrNotActiveCombo && !isMultiLine && !isFillableSignature
        ? {
          height: (fontSize * lineHeight + state.padding * 2) * scale,
        }
        : {}
    ),
  });

  const paddingTop = Math.max(calculatedPaddingTop, state.padding || 0);

  return {
    ruler: { width, height, linesCount, realSize },

    ...(
      !isFillableElement
        ? {
          width: isWidthFixed
            ? state.width
            : unscaledWidth,

          height: unscaledHeight,
        }
        : {}
    ),

    ...(
      viewMode !== 'classic' &&
      viewMode !== 'warning' &&
      element.template
        ? {
          // expandable fillable minimum size - template size, but may be expanded
          width: Math.max(element.template.width, unscaledWidth),
          height: linesCount > 1
            ? Math.max(element.template.height, unscaledHeight)
            : element.template.height,
        }
        : {}
    ),

    ...(
      isOnlyIncrease
        ? {
          minWidth: isWidthFixed
            ? state.width
            : unscaledWidth,

          minHeight: unscaledHeight,
        }
        : {}
    ),

    ...(props.resizingGeometry
      ? {
        minHeight: unscaledHeight,
        minWidth: unscaledWidth,
      }
      : {}),

    ...(
      paddingTop
        ? { paddingTop }
        : {}
    ),
  };
};

export const isForceFocus = (nextProps, props) => {
  return (
    nextProps.forceFocusElementObject !== props.forceFocusElementObject &&
    nextProps.forceFocusElementObject &&
    nextProps.forceFocusElementObject.id === props.id
  );
};

export const isForceCaret = (prevProps, props) => {
  return (
    prevProps.forceCaretPosition !== props.forceCaretPosition &&
    props.isActiveElement
  );
};

export const getKeyboardSize = ({ isCells, isDate }) => {
  const size = `${window.screen.width}x${window.screen.height}`;
  if (isDate) {
    return iosDateKeyboards[size];
  }

  // JSF-3102 in IOS Chrome cropped page due to keyboard,
  // but visually keyboard has same size that in Safari
  return isCells || thisDevice.isChromeIOS
    ? iosKeyboardsWithPanel[size]
    : iosKeyboards[size];
};

// const caretProps = [
//   'width',
//   'height',
//
//   'fontStyle',
//   'fontSize',
//   'fontFamily',
//
//   'textAlign',
//   'textDecoration',
// ];

// const cachedCaretElements = {
//   wrapper: null,
//   caretElement: null,
// };

// https://bugzilla.mozilla.org/show_bug.cgi?id=876693#c0
// setSelectionRange doesn't scroll textarea with overflow: hidden in FF
// used only for overflowed field by text and FF
// creates div with same element styles and text content
// in caret position creates span and returns it offset to created div
// this makes it possible to determine the caret position relative to the element
// export const getFFCaretCoordinatesForOverflowedTool = (element, position) => {
//   if (!cachedCaretElements.wrapper && !cachedCaretElements.caretElement) {
//     const wrapper = document.createElement('div');
//     cachedCaretElements.wrapper = wrapper;
//     const { style } = wrapper;
//     style.whiteSpace = 'pre-wrap';
//     style.wordWrap = 'break-word';
//     style.position = 'absolute';
//     style.visibility = 'hidden';
//     cachedCaretElements.caretElement = document.createElement('span');
//     document.body.appendChild(cachedCaretElements.wrapper);
//   }
//
//   const { wrapper, caretElement } = cachedCaretElements;
//   wrapper.innerHTML = '';
//
//   const { style } = wrapper;
//   const { style: elementStyle } = element;
//
//   caretProps.forEach((prop) => {
//     style[prop] = elementStyle[prop];
//   });
//   style.lineHeight = `${parseInt(elementStyle.fontSize, 10) * lineHeight}px`;
//
//   wrapper.textContent = element.value.substring(0, position);
//   caretElement.textContent = element.value.substring(position) || '.';
//   wrapper.appendChild(caretElement);
//
//   return {
//     top: caretElement.offsetTop,
//     left: caretElement.offsetLeft,
//   };
// };

export const getCaretPosition = (node) => {
  let caretPosition = 0;
  if (document.selection) {
    node.focus();
    const selection = document.selection.createRange();
    selection.moveStart('character', -node.value.length);
    caretPosition = selection.text.length;
  } else if (
    node.selectionStart || node.selectionEnd ||
    node.selectionStart === '0'
  ) {
    caretPosition = node.selectionEnd || node.selectionStart;
  }

  return caretPosition;
};

export const setCaretPosition = (node, caretPosition) => {
  if (!node) {
    return;
  }
  if (node.setSelectionRange) {
    node.focus();
    node.setSelectionRange(caretPosition, caretPosition);
  } else if (node.createTextRange) {
    const range = node.createTextRange();
    range.collapse(true);
    range.moveEnd('character', caretPosition);
    range.moveStart('character', caretPosition);
    range.select();
  }
};

export const getTextLength = (text) => {
  return text.length - (text.match(/\n/g) || []).length;
};

export const getPasteTestText =
  ({ selectionStart, selectionEnd, clipboardText, good, step }) => {
    const stepLen = Math.ceil(clipboardText.length / (2 * step || 1));
    return (
      good.slice(0, selectionStart) +
      clipboardText.slice(0, stepLen) +
      good.slice(selectionEnd)
    );
  };

export const getPasteCaretPosition =
  ({ selectionStart, clipboardText, step }) => {
    const stepLen = Math.ceil(clipboardText.length / (2 * step || 1));
    return selectionStart + stepLen;
  };

export const getMiddleText = (text1, text2) => {
  return text2.slice(0, Math.ceil((text2.length - text1.length) / 2) + text1.length);
};

/**
 * Возвращает domNode активный в данный момент (с кареткой)
 * Почти всем браузером можно верить document.getSelection().focusNode
 * Но при снятии каретки из поля для IE мы на всякий случай верим document.activeElement
 * т.к. document.getSelection().focusNode === false, а каретка стоит
 */
export const getSelectionNode =
  (byActiveElement = false) => {
    if (
      (
        thisDevice.isInternetExplorer11 ||
        thisDevice.isFirefoxDesktop ||
        thisDevice.isIOS ||
        thisDevice.isAndroid
      ) && byActiveElement
    ) {
      return document.activeElement || false;
    }

    return document.getSelection()
      ? document.getSelection().focusNode
      : false;
  };

/**
 * @param {string} fontFamily
 * @return {number} value 0..1 - baseline position based on lineHeight (lineHeight = 1)
 */
const defaultBaseLine = 100 / 121; // TODO: make ALL fontfamilies to appear at 'const::fonts'
export const getFontBaseline = (fontFamily, lineHeight = defaultLineHeight) => {
  const font = fontsMap[fontFamily];
  if (!font) {
    return defaultBaseLine;
  }
  const baseline = get(font.baselines, `${lineHeight}`);
  if (!baseline) {
    const baselinesArray = map(font.baselines, (value, key) => {
      return Number(key);
    });
    const closest = getClosestItemOfArray(baselinesArray, lineHeight);
    return get(font.baselines, `${closest}`);
  }
  return baseline;
};

/**
 * Returns adjustment top coordinate of element for save the baseline
 * on change fontSize
 * @param {Object} oldElementParams
 * @param {Object} newElementParams
 * @return {number} top coord adjustment
 */
const getAdjustmentTopToSaveBaselineByFontSize = (oldElementParams, newElementParams) => {
  const { fontFamily } = oldElementParams;
  const { fontSize, lineHeight = defaultLineHeight } = newElementParams;
  const fontSizeDifference = fontSize - oldElementParams.fontSize;
  const lineHeightDifference = fontSizeDifference * lineHeight;
  const baseline = getFontBaseline(fontFamily, lineHeight);
  return -lineHeightDifference * baseline;
};

/**
 * Returns adjustment top coordinate of element for save the baseline
 * on change fontFamily
 * @param {Object} oldElementParams
 * @param {Object} newElementParams
 * @return {number} top coord adjustment
 */
const getAdjustmentTopToSaveBaselineByFontFamily = (oldElementParams, newElementParams) => {
  const oldBaseline = getFontBaseline(
    oldElementParams.fontFamily,
    oldElementParams.lineHeight || defaultLineHeight,
  );
  const newBaseline = getFontBaseline(
    newElementParams.fontFamily,
    newElementParams.lineHeight || defaultLineHeight,
  );
  return -(newBaseline - oldBaseline) * oldElementParams.fontSize;
};

/**
 * NOTE: Proxy for methods:
 * - getAdjustmentTopToSaveBaselineByFontSize,
 * - getAdjustmentTopToSaveBaselineByFontFamily;
 * Returns adjustment top coordinate of element for save the baseline
 * @param {Object} oldElementParams
 * @param {Object} newElementParams
 * @return {number} top coord adjustment
 */
export const getAdjustmentTopToSaveBaseline = (oldElementParams, newElementParams) => {
  if (isGraphicTextType(oldElementParams.type)) {
    return 0;
  }
  if (isSignNow()) {
    return 0;
  }

  if (newElementParams.fontSize && oldElementParams.fontSize !== newElementParams.fontSize) {
    return getAdjustmentTopToSaveBaselineByFontSize(oldElementParams, newElementParams);
  }

  if (newElementParams.fontFamily && oldElementParams.fontFamily !== newElementParams.fontFamily) {
    return getAdjustmentTopToSaveBaselineByFontFamily(oldElementParams, newElementParams);
  }

  return 0;
};

export const directions = {
  toTextTool: 'toTextTool', // default
  fromTextTool: 'fromTextTool',
};

const objectToAdd = (marginsObject, direction) => {
  let { marginLeft, marginRight, marginTop, marginBottom } = marginsObject;
  marginLeft = marginLeft || 0;
  marginRight = marginRight || 0;
  marginTop = marginTop || 0;
  marginBottom = marginBottom || 0;
  return mapValues(
    {
      x: marginLeft,
      y: marginTop,
      width: -marginLeft - marginRight,
      height: -marginTop - marginBottom,
    },
    (value) => {
      return (direction === directions.fromTextTool
        ? -1
        : 1
      ) * value;
    },
  );
};

export const modificatePropsByBorders = (props, borders, direction) => {
  const marginsObject = objectToAdd(borders, direction);
  return mapValues(
    mapValues(props, (value, key) => {
      return marginsObject[key]
        ? value + marginsObject[key]
        : value;
    }),
    (value, key) => {
      return key === 'content'
        ? modificatePropsByBorders(value, borders, direction)
        : value;
    },
  );
};

export const isTextareaOrContentEditable = (node) => {
  if (node.getAttribute('contenteditable') === 'true') {
    return true;
  }
  if (node.tagName === 'textarea'.toUpperCase()) {
    return true;
  }
  return false;
};

export const getIsValidatorWithDatePicker = (validator) => {
  return get(validator, 'useDatePicker', false);
};

export const getIsElementValidatorWithDatePicker = (element, dateValidators) => {
  const validatorId = get(element, 'template.validatorId');
  const validator = find(dateValidators, { id: validatorId });

  return getIsValidatorWithDatePicker(validator);
};

export const getIsOnlyHeightUpdatedInStretchOrComboViewMode = (
  content,
  contentForUpdate,
  viewMode,
) => {
  const isStretchOrComboViewMode =
    viewMode === textFieldViewModes.stretch || viewMode === textFieldViewModes.combo;

  if (!isStretchOrComboViewMode) {
    return false;
  }

  const updatedContentKeys = Object.keys(contentForUpdate).filter((key) => {
    return contentForUpdate[key] !== content[key];
  });

  return updatedContentKeys.length === 1 && updatedContentKeys[0] === 'height';
};
