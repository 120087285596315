import {
  select,
  fork,
  all,
} from 'redux-saga/effects';
import { selectors } from 'jsfcore';

import interfaceRootSaga from './interfaceSaga';
import authSaga from './authSaga';
import beforeUnload from './beforeUnload';
import systemStatusWatcher from './systemStatusWatcher';
import connectionStatusWatcher from './connectionStatusWatcher';
import initializeDestroyModule from './destroySaga';
import saveSaga from './saveSaga';

/**
 * Общее описание модуля
 *
 * Начало работы с редактором возможно 2-я путями:
 * - Напрямую, используя query-string параметры (projectId)
 *
 *   В этом случае мы напрямую запускаем механизи авторизации
 *   из этого модуля
 *
 * - Через interface, для редактора встроенного в другое приложение
 *   (angular signNow app, airSlate main app)
 *
 *   В этом случае мы создаем интерфейс, вызывая фукцнии которого
 *   можно запустить авторизацию, дестрой и так далее
 *
 * Модуль занимается не только авторизацией, но и логикой ухода
 * из редактора см. initializeDestroyModule
 */

export default function* authRootSaga({ projectId, viewerId, embedded, isOfflineMode }) {
  const appStarted = yield select(selectors.base.getAppStarted);

  // NOTE: Это нужно для HMR, чтобы не было повторной авторизации
  // после релоада рутовой саги
  if (appStarted) {
    return;
  }

  yield all([
    ...(
      embedded || isOfflineMode
        ? [
          fork(interfaceRootSaga),
        ]
        : [
          fork(interfaceRootSaga),
          fork(authSaga, { projectId, viewerId }),
        ]
    ),

    fork(systemStatusWatcher),
    fork(connectionStatusWatcher),
    fork(initializeDestroyModule),
    fork(saveSaga),

    // TODO: нужно проверить, что beforeUnload нужен
    // в обоих случаях (embedded и !embedded)
    fork(beforeUnload),
  ]);
}
