import get from 'lodash/get';
import { GROUPS, TYPES, TOOL_TYPES } from '../../../../constants';

export const addToState = (state, item) => {
  return ([
    {
      ...item,
      type: TOOL_TYPES.attachment,
      group: GROUPS.tools,
    },
    ...state,
  ]);
};

export const deleteFromState = (state, item) => {
  return state.filter((el) => {
    return el.id !== item.id;
  });
};

// as I could understand, new gallery image creates without an id
// and it would get id using server response
// that is why we need function like that
export const updateStateOfElementWithoutId = (state, item) => {
  return state.map((el) => {
    if (el.id === undefined) {
      return {
        ...el,
        id: item.id,
        subType: item.subType,
        fileId: item.fileId,
        fileName: item.fileName,
        fileSize: item.fileSize,
        fileType: item.fileType,
      };
    }
    return el;
  });
};

export function doAttachments(operations, attachments) {
  const { properties } = operations[0];
  switch (properties.type) {
    case TYPES.list:
      if (get(properties, 'attachments', false)) {
        return properties.attachments.map((el) => {
          return { ...el };
        });
      }
      return [];
    case TYPES.delete:
      return deleteFromState(attachments, properties);
    case TYPES.add:
      return addToState(attachments, properties);
    default:
      return attachments;
  }
}

export function addAttachments(operation, attachments = []) {
  const { width, height } = operation.image;

  return addToState(
    attachments,
    {
      visible: true,
      height,
      width,
    },
  );
}

export const deleteAttachments = (operation, attachments = []) => {
  return deleteFromState(attachments, operation.image);
};

export const updateAttachments = (operation, attachments = []) => {
  return updateStateOfElementWithoutId(attachments, operation[0].properties);
};
