import { compose } from 'redux';

const injectLazyReducer = (store, createReducer, name, lazyReducer) => {
  // eslint-disable-next-line
  store.lazyReducers[name] = lazyReducer;
  store.replaceReducer(createReducer(store.wsTransport, store.lazyReducers));
};

const injectLazyMiddleware = (store, name, lazyMiddlewares) => {
  let chain = [];

  // eslint-disable-next-line
  store.lazyMiddlewares[name] = lazyMiddlewares;
  const middlewareAPI = {
    getState: store.getState,
    dispatch: (action) => {
      store.dispatch(action);
    },
  };

  // eslint-disable-next-line
  for (const middlewareArr of Object.keys(store.lazyMiddlewares)) {
    chain = chain.concat(
      store.lazyMiddlewares[middlewareArr]
        .map((middleware) => {
          return middleware(middlewareAPI);
        }),
    );
  }

  // eslint-disable-next-line
  store.lazyMiddlewaresChain = compose(...chain);
  window.lazyMiddlewaresChain = store.lazyMiddlewaresChain;
};

const initializeModule = (module, moduleName, store, createReducer) => {
  if (module.reducer) {
    injectLazyReducer(store, createReducer, moduleName, module.reducer);
  }
  if (module.rootSaga) {
    window.sagaMiddleware.run(module.rootSaga);
  }
  if (module.middlewares) {
    injectLazyMiddleware(store, moduleName, module.middlewares);
  }
};

export default initializeModule;
