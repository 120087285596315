import React from 'react';
import PropTypes from 'prop-types';

const FillableWizardFooter = ({
  children,
}) => {
  return (
    <div className="fillable-wizard__footer">
      {children}
    </div>
  );
};

FillableWizardFooter.propTypes = {
  children: PropTypes.element.isRequired,
};

export default FillableWizardFooter;
