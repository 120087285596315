import React from 'react';
import PropTypes from 'prop-types';

const BoxMain = ({ children }) => {
  return (
    <div className="box__main">
      {children}
    </div>
  );
};

BoxMain.propTypes = {
  children: PropTypes.element.isRequired,
};

export default BoxMain;
