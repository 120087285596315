import React from 'react';
import PropTypes from 'prop-types';

const PageNavigationCell = ({ children, attributes, onClick }) => {
  return (
    <div
      className="page-navigation__cell"
      {...attributes}
      onClick={onClick}
    >
      {children}
    </div>
  );
};

PageNavigationCell.propTypes = {
  children: PropTypes.node,
  attributes: PropTypes.shape({}),
  onClick: PropTypes.func,
};

PageNavigationCell.defaultProps = {
  children: null,
  attributes: {},
  onClick: null,
};

export default PageNavigationCell;
