import logger from '@pdffiller/jsf-logger/clientLogger';
import moment from 'moment';
import { delay } from 'redux-saga';
import {
  call,
  select,
  put, take,
} from 'redux-saga/effects';
import constants from 'combine-modals/lib/constants/dialog.modal';
import * as combineModalsActions from 'combine-modals/lib/actions/actionCreator';
import { hideChoiceIframe, cancelShowingBeforeUnloadPromptConfirmation } from 'jsfcore/store/modules/viewport';
import { EMBEDDED } from 'jsfcore/helpers/const/interface';
import { selectors } from 'jsfcore';
import { redirectToHref } from 'jsfcore/helpers/url';
import { destroyed } from './interfaceSaga';

import {
  appendDestroyCallback,
  appendDestroyedCallback,
} from './systemStatusWatcher';

export function* onDestroyedReceived(system) {
  const appStarted = yield select(selectors.base.getAppStarted);

  // "changes" indicate that was send one or more tools group operations
  // from client in current session
  // modal should be shown when "changes" = true
  // https://pdffiller.atlassian.net/browse/JSF-3963
  // https://pdffiller.atlassian.net/browse/JSF-4054
  const destroyWithSuccessModal = appStarted && system.doneProgress && system.changes;

  if (destroyWithSuccessModal) {
    const successModal = yield select(selectors.locale.getSuccessModalLocale);
    yield put(
      combineModalsActions.openModal('DialogModal', {
        size: constants.SIZE_SM,
        modalType: constants.SUCCESS,
        title: successModal.title,
        text: successModal.text.replace('%version%',
          moment().format('M/D/YYYY hh:mm a')),
      }),
    );
    yield delay(1000);
  }

  const isDestroyed = yield call(destroyed);
  const preventRedirect = yield select(selectors.base.getPreventRedirect);

  if (!isDestroyed || preventRedirect) {
    return;
  }
  yield put(cancelShowingBeforeUnloadPromptConfirmation());
  if (system.destroyUrl === EMBEDDED) {
    window.location.reload();
  } else {
    if (window && window.windowOnDestroy) {
      window.windowOnDestroy.location.href = window.windowOnDestroy.url;
      window.windowOnDestroy = null;
    }
    redirectToHref(system.destroyUrl);
  }
}

export function* onDestroyReceived(system) {
  const appStarted = yield select(selectors.base.getAppStarted);

  if (system.doneProgress && appStarted) {
    const processingModal = yield select(selectors.locale.getProcessingModalLocale);

    yield put(
      combineModalsActions.openModal('DialogModal', {
        size: constants.SIZE_MD,
        modalType: constants.PROCESSING,
        title: processingModal.title,
        text: processingModal.text,
      }),
    );
    // wait for appearance of modal , without this UI will blink
    yield take('SET_INTERFACE_LOCK');
    yield put(hideChoiceIframe());
  }
}

export default function destroyRootSaga() {
  appendDestroyedCallback(logger.loggerDestroy);
  appendDestroyedCallback(onDestroyedReceived);
  appendDestroyCallback(onDestroyReceived);
}
