import { takeEvery, select, put, all, call, take } from 'redux-saga/effects';
import includes from 'lodash/includes';
import fill from 'lodash/fill';
import { thisDevice } from '@pdffiller/jsf-useragent';
import isSignNow from 'ws-editor-lib/isSignNow';

import {
  SET_ACTIVE_PAGE,
  SET_ALL_SCALES,
  SET_ALL_SCALES_CONTINIOUS,
  SET_SCALE,
  CHANGE_PAGE,
  CHANGE_SCALE,
  INITIALIZE_ARRAYS,
} from '../../modules/navigationActionTypes';
import {
  removeCanvases,
  setNeedRender,
  GET_PAGE_SUCCESS,
  GET_DOCUMENT_SUCCESS,
  SET_VISIBLE_THUMBNAILS,
  setNeedGet,
  setNeedRenderThumbnail,
  setAllPdfTextContent,
} from '../../modules/pdf';
import { needRender as needRenderAdjacent, storedCanvases } from '../../../helpers/const';
import { selectors } from '../../..';
import {
  getAdjacentPageId,
  isContiniousPagination,
  findPageSettingsByPageId,
} from '../../../helpers/utils';
import {
  UPDATE_WORKSPACE,
  UPDATE_WORKSPACE_PADDING,
  ACTIVATE_FAKE_EDIT,
} from '../../modules/viewport';
import { RESET_FOR_RELOAD_PDF } from '../../modules/actions';
import { SET_SEARCH_MODE_ACTIVE } from '../../modules/search';
import pdfDocumentStore from '../../helpers/pdfDocumentStore';
import getPageTextItems from './pdfText/getPageTextItems';
import pdfTextStore from '../../helpers/pdfTextStore';

export function* getUnnecessaryCanvases({ activePageId }) {
  const canvases = yield select(selectors.base.getCanvases);
  const pageIdsForSaveCanvases = storedCanvases.map((id) => {
    return id + activePageId;
  });
  if (!canvases) {
    return [];
  }
  return canvases.reduce(
    (acc, canvasId, canvasPageId) => {
      return (
        canvasId === null || includes(pageIdsForSaveCanvases, canvasPageId)
          ? acc
          : [...acc, canvasPageId]
      );
    },
    [],
  );
}

export function* getNeedRender({ activePageId }) {
  const canvases = yield select(selectors.base.getCanvases);
  const pdfPages = yield select(selectors.base.getPdfPages);
  const pagesSettings = yield select(selectors.navigation.getPagesSettings);
  if (!pagesSettings) {
    return [];
  }

  return needRenderAdjacent
    // Находим id страниц соседних с активной
    .map((id) => {
      return getAdjacentPageId(activePageId, pagesSettings, id);
    })
    // Нам нужны те, у которых есть страница и нет канваса
    .filter((id) => {
      return !canvases[id] && pdfPages[id];
    });
}

export function* onChangeVisibleThumbnails({ indexes }) {
  yield put(setNeedGet(indexes));
  yield put(setNeedRenderThumbnail(indexes));
}

export function* onChangeActivePageId({ nextPage: activePageId }) {
  // ------------------------ Удаление лишних canvas'ов ------------------------
  // Например у нас есть канвас страниц 1, 2, 3
  // Мы переключаемся на 3-ю страницу.
  // storedCanvases это массив сколько соседних страниц нужно хранить
  // Т.к. он [-1, 0, 1] то можно удалить страницу 1
  // из canvasStore и из state.pdf.canvases
  const unnecessaryCanvases = yield getUnnecessaryCanvases({ activePageId });
  if (unnecessaryCanvases.length > 0) {
    yield put(removeCanvases(unnecessaryCanvases));
  }

  // ------------ Запуск рендеринга при изменении активной страницы ------------
  // Раньше, ререндеринг канвасов работал только при изменении frameSize
  // - с одного значения на другое (rerender, при изменении масштаба)
  // - или с null на значение (initial, после получения страницы)
  // т.к. не подразумевалось удаление уже готовых канвасов. Теперь же, из-за
  // утечек памяти на iOS нам нужно быть проверять отрендерена ли эта страница
  // и если нет - запускать рендеринг.
  if (!isSignNow()) {
    const needRender = yield getNeedRender({ activePageId });
    if (needRender.length > 0) {
      yield put(setNeedRender(needRender));
    }
  }
}

export const makeOnChangeScales = () => {
  let prevScales;
  return {
    onChangeScales: function* onChangeScalesF() {
      const scales = yield select(selectors.base.getScales);
      // hotfix. pageSettings не переносить в 2.18
      const pageSettings = yield select(selectors.navigation.getPagesSettings);
      if (!prevScales) {
        prevScales = fill(Array(scales.length), undefined);
      }
      if (pageSettings) {
        const pageIdsWithNewScale = [];
        for (let i = 0; i < scales.length; i++) {
          // TODO: Тут возможно нужно проверять не на равенство, а:
          // abs(scales[i] - prevScales[i]) > XX
          // чтобы игнорировать изменение масштаба в каких-то небольших пределах
          // но может быть проблема если пользователь будет несколько раз
          // изменять масштаб в этих пределах
          // (чтобы это исправить нужно будет не переприсваивать prevScales)
          const hasPageSettings = pageSettings.findIndex(({ source }) => {
            return i === source;
          }) !== -1;
          if (scales[i] !== prevScales[i] && hasPageSettings) {
            pageIdsWithNewScale.push(i);
          }
        }
        if (pageIdsWithNewScale.length > 0) {
          yield put(setNeedRender(pageIdsWithNewScale));
        }
      }
      prevScales = scales;
    },
    resetPrevScales: function resetPrevScalesF() {
      prevScales = false;
    },
  };
};

const { onChangeScales, resetPrevScales } = makeOnChangeScales();

function* watchActivePageId() {
  if (isContiniousPagination()) {
    yield takeEvery([SET_ACTIVE_PAGE, CHANGE_PAGE], onChangeActivePageId);
  } else {
    yield takeEvery(SET_ACTIVE_PAGE, onChangeActivePageId);
  }
}

export function* watchVisibleThumbnails() {
  yield takeEvery(SET_VISIBLE_THUMBNAILS, onChangeVisibleThumbnails);
}

function* watchScale() {
  yield takeEvery(
    thisDevice.isDesktop
      /**
       * В процессе реализации Zoom CSS увеличился список
       * экшнов меняющих scale на десктопе
       */
      ? [
        SET_ALL_SCALES, SET_SCALE, GET_PAGE_SUCCESS,
        CHANGE_SCALE, UPDATE_WORKSPACE, UPDATE_WORKSPACE_PADDING,
        SET_ALL_SCALES_CONTINIOUS,
      ]
      : [SET_ALL_SCALES, SET_ALL_SCALES_CONTINIOUS, SET_SCALE, GET_PAGE_SUCCESS],
    onChangeScales,
  );
}

export function* loadTextContent(pdf, pageId) {
  const page = yield pdf.getPage(pageId + 1);
  const textContent = yield page.getTextContent();

  const pagesSettings = yield select(selectors.navigation.getPagesSettings);
  const pageSetting = findPageSettingsByPageId(pagesSettings, pageId);
  // eslint-disable-next-line no-underscore-dangle
  const items = getPageTextItems(textContent, page._pageInfo, pageSetting);
  pdfTextStore.putPdfText(pageId, items);
  return items;
}

function* watchInitializeArrays() {
  yield takeEvery([INITIALIZE_ARRAYS, GET_DOCUMENT_SUCCESS], resetPrevScales);
}

export function* loadAllTextContentOnFakeEditOrSearchActivate() {
  const needPages = yield select(selectors.getUnloadedTextContent);
  const pdf = pdfDocumentStore.getPdfDocument();

  if (needPages.length && pdf) {
    yield all(needPages.map((pageId) => {
      return call(loadTextContent, pdf, pageId);
    }));

    yield put(setAllPdfTextContent(needPages));
  }
}

export function* loadAllTextContentOnResetForReloadPdf() {
  const isFakeEditActive = yield select(selectors.base.getIsFakeEditActive);
  const isSearchActive = yield select(selectors.base.getIsSearchModeActive);

  if (isFakeEditActive || isSearchActive) {
    const isPdf = pdfDocumentStore.hasPdfDocument();

    if (!isPdf) {
      yield take(GET_DOCUMENT_SUCCESS);
    }

    yield call(loadAllTextContentOnFakeEditOrSearchActivate);
  }
}

function* watchTextOnSearchOrFakeEdit() {
  yield takeEvery(
    [SET_SEARCH_MODE_ACTIVE, ACTIVATE_FAKE_EDIT],
    loadAllTextContentOnFakeEditOrSearchActivate,
  );
}

function* watchTextOnResetForReloadPdf() {
  yield takeEvery(RESET_FOR_RELOAD_PDF, loadAllTextContentOnResetForReloadPdf);
}

export default function* pdfRootSaga() {
  yield all([
    watchActivePageId(),
    watchVisibleThumbnails(),
    watchScale(),
    watchInitializeArrays(),
    watchTextOnSearchOrFakeEdit(),
    watchTextOnResetForReloadPdf(),
  ]);
}
