import PropTypes from 'prop-types';
import React, { Component } from 'react';
import cls from 'classnames';

export const boxThemes = {
  primary: 'primary',
  null: null,
};

export default class Box extends Component {
  static propTypes = {
    children: PropTypes.element.isRequired,
    isActive: PropTypes.bool,
    isFocused: PropTypes.bool,
    storeRef: PropTypes.func,
    style: PropTypes.shape({}),
    theme: PropTypes.oneOf(Object.values(boxThemes)),
  }

  static defaultProps = {
    isActive: false,
    isFocused: false,
    storeRef: null,
    style: {},
    theme: boxThemes.null,
  };

  storeRefProxy = (ref) => {
    if (this.props.storeRef) {
      this.props.storeRef(ref);
    }
  }

  render() {
    const {
      children,
      style,
      theme,
      isActive,
      isFocused,
    } = this.props;

    return (
      <div
        className={cls('box', {
          [`box--theme--${theme}`]: theme,
          'is-active': isActive,
          'is-focused': isFocused,
        })}
        style={style}
        ref={this.storeRefProxy}
      >
        {children}
      </div>
    );
  }
}
