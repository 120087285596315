
import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

export const pageThumbnailLabelThemes = {
  primary: 'primary',
  null: null,
};
export const pageThumbnailLabelTypes = {
  flag: 'flag',
  null: null,
};

const PageThumbnailLabel = ({ children, theme, type }) => {
  return (
    <div className="page-thumbnail__label">
      <div
        className={classnames(
          'label',
          {
            [`label--theme--${theme}`]: theme,
            [`label--type--${type}`]: type,
          },
        )}
      >
        <div className="label__body">
          <div className="label__text">
            {children}
          </div>
        </div>
      </div>
    </div>
  );
};

PageThumbnailLabel.propTypes = {
  children: PropTypes.node,
  theme: PropTypes.oneOf(
    Object.values(pageThumbnailLabelThemes),
  ),
  type: PropTypes.oneOf(
    Object.values(pageThumbnailLabelTypes),
  ),
};

PageThumbnailLabel.defaultProps = {
  children: null,
  theme: pageThumbnailLabelThemes.null,
  type: pageThumbnailLabelTypes.null,
};

export default PageThumbnailLabel;
