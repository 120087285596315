import PropTypes from 'prop-types';
import React, { Component } from 'react';
import classnames from 'classnames';

const menuBodyJustifies = {
  center: 'center',
  null: null,
};

const menuBodyThemes = {
  null: null,
  default: 'default',
};

const menuBodyTypes = {
  null: null,
  tile: 'tile',
  grid: 'grid',
};

const menuBodySizes = {
  null: null,
  parentWidth: 'parent-width',
};

export default class MenuBody extends Component {
  static propTypes = {
    children: PropTypes.node,
    type: PropTypes.oneOf(
      Object.values(menuBodyTypes),
    ),
    justify: PropTypes.oneOf(
      Object.values(menuBodyJustifies),
    ),
    theme: PropTypes.oneOf(
      Object.values(menuBodyThemes),
    ),
    size: PropTypes.oneOf(
      Object.values(menuBodySizes),
    ),
    style: PropTypes.shape({}),
    attributes: PropTypes.shape({}),
    storeRef: PropTypes.func,
  };

  static defaultProps = {
    children: null,
    type: menuBodyTypes.null,
    theme: menuBodyThemes.null,
    justify: menuBodyJustifies.null,
    size: menuBodySizes.null,
    attributes: null,
    style: null,
    storeRef: null,
  };

  render() {
    const { type, justify, theme, size, style, storeRef, attributes } = this.props;
    return (
      <div
        className={classnames('menu', {
          [`menu--justify--${justify}`]: justify,
          [`menu--theme--${theme}`]: theme,
          [`menu--type--${type}`]: type,
          [`menu--size--${size}`]: size,
        })}
        style={style}
        ref={storeRef}
        {...attributes}
      >
        <div className="menu__body">
          {this.props.children}
        </div>
      </div>
    );
  }
}

export { menuBodyThemes, menuBodyJustifies, menuBodyTypes, menuBodySizes };
