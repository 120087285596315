import * as reducer from './modalsReducer';
import * as actions from './store/modalsActions';
import * as types from './types';
import Warning from './components/Warning';
import Confirmation from './components/Confirmation';

export {
  reducer,
  actions,
  types,
  Warning,
  Confirmation,
};
