import React from 'react';
import PropTypes from 'prop-types';

const CounterItem = ({ children }) => {
  return (
    <div className="counter__item">
      {children}
    </div>
  );
};

CounterItem.propTypes = {
  children: PropTypes.number,
};

CounterItem.defaultProps = {
  children: null,
};

export default CounterItem;
