export const CONDITION_MESSAGE_INITIAL = '';
export const INTRO_MESSAGE_TYPE = 'intro';

export const MessageCondition = {

  getInitialState: () => {
    return CONDITION_MESSAGE_INITIAL;
  },

  destroy: (locale) => {
    return locale && locale.messages
      ? locale.messages.exiting
      : 'Error';
  },

  loadingPage: (locale, modeId, { page, pages }) => {
    if (page === pages) {
      return locale.messages.intro[modeId];
    }
    return locale.messages.loadingPages
      .replace(/%current%/g, page)
      .replace(/%total%/g, pages);
  },

  change: (locale, modeId, { messageType }) => {
    if (messageType === INTRO_MESSAGE_TYPE) {
      return locale.messages.intro[modeId];
    }
    return locale.messages[messageType];
  },

};
