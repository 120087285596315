import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

export const panelThemes = {
  default: 'default',
  flatWhite: 'flat-white',
  lightgray: 'lightgray',
  null: null,
};

export const panelTypes = {
  collapsible: 'collapsible',
  null: null,
};

export const panelOffsetsBottom = {
  large: 'large',
  small: 'small',
  null: null,
};

export const panelOffsetsHorizontal = {
  large: 'large',
  null: null,
};

export const panelOffsetsTop = {
  small: 'small',
  null: null,
};

export const panelOffsetsVertical = {
  xlarge: 'xlarge',
  large: 'large',
  small: 'small',
  null: null,
};

const PanelBody = ({
  children,
  isOpened,
  offsetBottom,
  offsetHorizontal,
  offsetTop,
  offsetVertical,
  theme,
  type,
  storeRef,
  onClick,
}) => {
  return (
    <div
      className={classnames('panel', {
        'is-opened': isOpened,
        [`panel--offset-bottom--${offsetBottom}`]: offsetBottom,
        [`panel--offset-horizontal--${offsetHorizontal}`]: offsetHorizontal,
        [`panel--offset-top--${offsetTop}`]: offsetTop,
        [`panel--offset-vertical--${offsetVertical}`]: offsetVertical,
        [`panel--theme--${theme}`]: theme,
        [`panel--type--${type}`]: type,
      })}
      ref={storeRef}
      onClick={onClick}
    >
      <div className="panel__body">
        {children}
      </div>
    </div>
  );
};

PanelBody.propTypes = {
  children: PropTypes.node.isRequired,
  isOpened: PropTypes.bool,
  offsetBottom: PropTypes.oneOf(
    Object.values(panelOffsetsBottom),
  ),
  offsetHorizontal: PropTypes.oneOf(
    Object.values(panelOffsetsHorizontal),
  ),
  offsetTop: PropTypes.oneOf(
    Object.values(panelOffsetsTop),
  ),
  offsetVertical: PropTypes.oneOf(
    Object.values(panelOffsetsVertical),
  ),
  theme: PropTypes.string,
  type: PropTypes.string,
  storeRef: PropTypes.func,
  onClick: PropTypes.func,
};

PanelBody.defaultProps = {
  isOpened: false,
  offsetBottom: panelOffsetsBottom.null,
  offsetHorizontal: panelOffsetsHorizontal.null,
  offsetTop: panelOffsetsTop.null,
  offsetVertical: panelOffsetsVertical.null,
  theme: panelThemes.null,
  type: panelTypes.null,
  onClick: null,
  storeRef: null,
};

export default PanelBody;
