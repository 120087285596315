import fill from 'lodash/fill';
import findIndex from 'lodash/findIndex';
import { actionTypes } from 'ws-editor-lib/actions';

import { thisDevice } from '@pdffiller/jsf-useragent';

import { RESET_FOR_RELOAD_PDF } from './actions';
import * as selectors from '../selectors/selectors';

import {
  isSignNow,
  isContiniousPagination,
  getFitScale,
  getSnScaleFromHeaderScale,
  getSnFillerWorkspace,
} from '../../helpers/utils';
import {
  minContiniousScale,
  maxContiniousScale,
  validScrollInfelicity,
} from '../../helpers/const';

import { RESET_ALL_REDUCERS } from './events';
import { GET_DOCUMENT_SUCCESS, GET_PAGE_SUCCESS, INIT_DOCUMENT } from './pdf';
import {
  UPDATE_WORKSPACE,
  UPDATE_WORKSPACE_PADDING,
  getNewWorkspaceAndBody,
  updFramePadding,
} from './viewport';
import deprecate from '../../helpers/deprecate';

import {
  CHANGE_PAGE,
  CHANGE_SCALE, CLEAR_CARET_POSITION, GO_TO_FLASH_SITE, INITIALIZE_ARRAYS,
  SAVE_ACTIVE_PAGE, SAVE_CARET_POSITION,
  SCALE_FIT_PAGE,
  SCALE_FIT_WIDTH,
  SCROLL_TO_TOP_SN,
  SET_ACTIVE_PAGE,
  SET_ALL_SCALES,
  SET_ALL_SCALES_CONTINIOUS, SET_CAN_GO_NEXT_PAGE, SET_CAN_GO_PREV_PAGE,
  SET_CONTINIOUS_FRAME_SCROLL, SET_DRAG_SCROLL_DISTINCTION,
  SET_FRAME_SCROLL,
  SET_GHOST_PAGE_ID,
  SET_ORIGINAL_SIZE,
  SET_SCALE,
  SET_IS_CONTINIOUS_SCROLL_ANIMATED,
} from './navigationActionTypes';

const initialState = {
  activePageId: 0, // TODO: catch initial page from server, process it
  ghostPageId: 0,
  // сюда запоминается activePageId при входе в pageRearrange.
  // в sagas/loadPdf проверим это значение при загрузке страницы
  savedActivePageId: false,
  nextActive: false,
  prevActive: false,

  originalSizes: false,
  frameScrolls: false,

  scales: false,
  nextScale: 0,
  headerScales: false,

  canGoNextPage: false,
  canGoPrevPage: false,
  caretPosition: {
    id: '',
    position: null,
  },

  dragScrollDistinction: false,

  isContiniousScrollAnimated: false,

  workspace: {
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    width: 0,
    height: 0,
    framePadding: {},
  },
  body: {
    bottom: 0,
    height: 0,
    left: 0,
    right: 0,
    top: 0,
    width: 0,
    x: 0,
    y: 0,
  },

  scrollToTopSn: {},
};

const isFitScale = (scale) => {
  return (
    scale && (
      scale === SCALE_FIT_WIDTH ||
      scale === SCALE_FIT_PAGE
    )
  );
};

const getInitialScale = () => {
  if (isSignNow()) {
    // for tablet and desktop set the initial zoom 100%
    // for phone FIT_WIDTH
    return thisDevice.isPhone
      ? SCALE_FIT_WIDTH
      : 1;
  }

  if (thisDevice.isMobile) {
    return SCALE_FIT_PAGE;
  }

  return SCALE_FIT_WIDTH;
};

export const getCanGo = ({
  frameSizes,
  workspace,
  pageId,
  scrollTop,
}) => {
  const frameSize = frameSizes[pageId];
  const { framePadding } = workspace;
  const height = frameSize.height + framePadding.top + framePadding.bottom;
  return {
    next: height - scrollTop < workspace.height + validScrollInfelicity,
    prev: scrollTop < validScrollInfelicity,
  };
};

// scale = FIT_WIDTH -> state.defaultScales[index][FIT_WIDTH]
// scale = 2.5 -> 2.5
const getScaleValue = ({ state, scale, index }) => {
  return selectors.getDefaultScale({ navigation: state }, index)[scale] || scale;
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case RESET_ALL_REDUCERS:
      return {
        ...initialState,
        body: state.body,
        workspace: state.workspace,
      };

    case actionTypes.RESET_WS_PAGES:
      return {
        ...state,
        activePageId: initialState.activePageId,
        nextActive: initialState.activePageId,
      };

    case RESET_FOR_RELOAD_PDF: {
      return {
        ...state,
        frameScrolls: false,
        // TODO: maybe something else?
      };
    }

    case SCROLL_TO_TOP_SN:
      return {
        ...state,
        scrollToTopSn: {},
      };

    case SET_GHOST_PAGE_ID:
      return {
        ...state,
        ghostPageId: action.ghostPageId,
      };

    case UPDATE_WORKSPACE: {
      const { body, workspace } = getNewWorkspaceAndBody(state, action);

      const [scale] = state.headerScales || [false];
      const newState = {
        ...state,
        body,
        workspace,
      };

      if (isFitScale(scale) && thisDevice.isDesktop) {
        return {
          ...newState,
          nextScale: 0,
          headerScales: fill(Array(state.headerScales.length), scale),
          scales: state.scales.map((val, i) => {
            return !val
              ? val
              : getScaleValue({ state: newState, scale, index: i });
          }),
        };
      }

      return newState;
    }

    case UPDATE_WORKSPACE_PADDING: {
      const newState = updFramePadding(state, action);
      const [scale] = state.headerScales || [false];

      if (isFitScale(scale) && thisDevice.isDesktop) {
        return {
          ...newState,
          nextScale: 0,
          headerScales: fill(Array(state.headerScales.length), scale),
          scales: state.scales.map((val, i) => {
            return !val
              ? val
              : getScaleValue({ state: newState, scale, index: i });
          }),
        };
      }

      return newState;
    }

    // NOTE: SET_ORIGINAL_SIZE используется только для тестов
    case SET_ORIGINAL_SIZE: {
      const { pageId, originalSize } = action;
      const newState = {
        ...state,
        originalSizes: [
          ...state.originalSizes.slice(0, pageId),
          originalSize,
          ...state.originalSizes.slice(pageId + 1),
        ],
      };
      return {
        ...newState,
        scales: [
          ...newState.scales.slice(0, pageId),
          getScaleValue({
            state: newState,
            scale: newState.headerScales[pageId],
            index: pageId,
          }),
          ...newState.scales.slice(pageId + 1),
        ],
      };
    }

    case GET_PAGE_SUCCESS: {
      const { pageId, pagesSettings, result, pdfScale } = action;
      const pageSetting = pagesSettings[
        findIndex(pagesSettings, ({ source }) => {
          return source === pageId;
        })
      ];
      const rotation = pageSetting
        ? pageSetting.rotation
        : 0;

      const originalViewport = result.getViewport(
        pdfScale, rotation + result.rotate,
      );
      const originalSize = {
        width: pageSetting && pageSetting.size
          ? pageSetting.size.width
          : originalViewport.width,
        height: pageSetting && pageSetting.size
          ? pageSetting.size.height
          : originalViewport.height,
      };
      const intermediateStateWithOriginalSize = {
        ...state,
        originalSizes: [
          ...state.originalSizes.slice(0, pageId),
          originalSize,
          ...state.originalSizes.slice(pageId + 1),
        ],
      };

      if (isSignNow()) {
        const {
          headerScales,
          scales,
        } = intermediateStateWithOriginalSize;
        const headerScaleVal = getSnScaleFromHeaderScale(headerScales[pageId]);

        const initialPageScale = getScaleValue({
          state: intermediateStateWithOriginalSize,
          scale: headerScaleVal,
          index: pageId,
        });

        return {
          ...intermediateStateWithOriginalSize,
          scales: [
            ...scales.slice(0, pageId),
            initialPageScale,
            ...scales.slice(pageId + 1),
          ],
        };
      }

      const intermediateStateWithScale = {
        ...intermediateStateWithOriginalSize,
        scales: [
          ...intermediateStateWithOriginalSize.scales.slice(0, pageId),
          getScaleValue({
            state: intermediateStateWithOriginalSize,
            scale: intermediateStateWithOriginalSize.headerScales[pageId],
            index: pageId,
          }),
          ...intermediateStateWithOriginalSize.scales.slice(pageId + 1),
        ],
      };

      const canGo = getCanGo({
        workspace: intermediateStateWithScale.workspace,
        frameSizes: selectors.getFrameSizes({
          navigation: intermediateStateWithScale,
        }),
        pageId,
        scrollTop: intermediateStateWithScale.frameScrolls[pageId]
          ? intermediateStateWithScale.frameScrolls[pageId].scrollTop
          : 0,
      });

      return {
        ...intermediateStateWithScale,
        canGoNextPage: [
          ...intermediateStateWithScale.canGoNextPage.slice(0, pageId),
          canGo.next,
          ...intermediateStateWithScale.canGoNextPage.slice(pageId + 1),
        ],
        canGoPrevPage: [
          ...intermediateStateWithScale.canGoPrevPage.slice(0, pageId),
          canGo.prev,
          ...intermediateStateWithScale.canGoPrevPage.slice(pageId + 1),
        ],
      };
    }

    case SET_ACTIVE_PAGE:
      return {
        ...state,
        activePageId: action.nextPage,
        nextActive: action.nextPage,
        activePageIdChangedByScenario: action.opts
          ? action.opts.scenario
          : false,
      };

    case CHANGE_PAGE: {
      if (isContiniousPagination()) {
        return {
          ...state,
          nextActive: action.nextPage,
          prevActive: action.prevPage,
          activePageIdChangedByScenario:
            (action.opts && action.opts.scenario) || false,
        };
      }

      return {
        ...state,
        nextActive: action.nextPage,
        prevActive: action.prevPage,
        activePageIdChangedByScenario: action.opts
          ? action.opts.scenario
          : false,
      };
    }

    case SAVE_ACTIVE_PAGE:
      return {
        ...state,
        savedActivePageId: action.pageId,
      };

    case SET_SCALE: {
      const { scale, index } = action;

      return {
        ...state,
        nextScale: 0,
        scales: [
          ...state.scales.slice(0, index),
          getScaleValue({ state, scale: scale || state.headerScales[index], index }),
          ...state.scales.slice(index + 1),
        ],
      };
    }

    case SET_ALL_SCALES: {
      const { scale, index } = action;

      // для мобилок устонавливаем масштаб только для текущей страницы,
      // для остальных страниц масштаб сьрасывается после перелистывания страницы
      if (thisDevice.isMobile) {
        return {
          ...state,
          nextScale: 0,
          scales: [
            ...state.scales.slice(0, index),
            getScaleValue({ state, scale, index }),
            ...state.scales.slice(index + 1),
          ],
          headerScales: [
            ...state.headerScales.slice(0, index),
            scale || 1,
            ...state.headerScales.slice(index + 1),
          ],
        };
      }

      return {
        ...state,
        nextScale: 0,
        scales: state.scales.map((val, i) => {
          return !val
            ? val
            : getScaleValue({ state, scale, index: i });
        }),
        headerScales: fill(Array(state.headerScales.length), scale),
      };
    }

    case SET_ALL_SCALES_CONTINIOUS: {
      const { scale: newHeaderScale } = action;
      const { originalSizes, workspace, activePageId } = state;

      if (newHeaderScale > maxContiniousScale || newHeaderScale < minContiniousScale) {
        return state;
      }

      const scales = originalSizes.map((originalSize) => {
        if (!originalSize) {
          return undefined;
        }
        if (isSignNow()) {
          return getScaleValue({
            state,
            scale: newHeaderScale,
            index: activePageId,
          });
        }
        return getFitScale(getSnFillerWorkspace(workspace))(originalSize)(newHeaderScale);
      });

      return {
        ...state,
        scales,
        headerScales: fill(
          Array(state.headerScales.length),
          +newHeaderScale,
        ),
      };
    }

    case CHANGE_SCALE: {
      const { scale } = action;

      /**
       * Для десктопов мы минуем nextScale т.к.
       * используется упрощенная версия PagePinch которая не умеет обрабатывать
       * nextScale
       */
      if (thisDevice.isDesktop) {
        return {
          ...state,
          nextScale: 0,
          scales: state.scales.map((val, i) => {
            return !val
              ? val
              : getScaleValue({ state, scale, index: i });
          }),
          headerScales: fill(Array(state.headerScales.length), scale),
        };
      }

      return {
        ...state,
        nextScale: action.scale,
      };
    }

    case SET_FRAME_SCROLL:
      return {
        ...state,
        frameScrolls: [
          ...state.frameScrolls.slice(0, action.index),
          {
            scrollTop: action.scroll.scrollTop,
            scrollLeft: action.scroll.scrollLeft,
          },
          ...state.frameScrolls.slice(action.index + 1),
        ],
      };

    case SET_CONTINIOUS_FRAME_SCROLL: {
      const scroll = {
        scrollTop: action.scrollTop,
        scrollLeft: action.scrollLeft,
      };
      return {
        ...state,
        frameScrolls: fill(
          Array(state.frameScrolls.length), scroll,
        ),
      };
    }

    case SET_IS_CONTINIOUS_SCROLL_ANIMATED: {
      return {
        ...state,
        isContiniousScrollAnimated: action.isContiniousScrollAnimated,
      };
    }

    case SET_CAN_GO_NEXT_PAGE:
      return {
        ...state,
        canGoNextPage: [
          ...state.canGoNextPage.slice(0, action.index),
          action.can,
          ...state.canGoNextPage.slice(action.index + 1),
        ],
      };

    case SET_CAN_GO_PREV_PAGE:
      return {
        ...state,
        canGoPrevPage: [
          ...state.canGoPrevPage.slice(0, action.index),
          action.can,
          ...state.canGoPrevPage.slice(action.index + 1),
        ],
      };

    case INIT_DOCUMENT:
    case INITIALIZE_ARRAYS:
    case GET_DOCUMENT_SUCCESS: {
      const pdfCount = action.result
        // for GET_DOCUMENT_SUCCESS
        ? action.result.numPages
        // for deprecated INITIALIZE_ARRAYS
        : action.count;

      const initialScale = getInitialScale();
      const newState = {
        ...state,
        frameScrolls: [...Array(pdfCount)].map(() => {
          return { scrollTop: 0, scrollLeft: 0 };
        }),
        canGoNextPage: fill(Array(pdfCount), null),
        canGoPrevPage: fill(Array(pdfCount), null),
        pagesScroll: [...Array(pdfCount)], // real scroll position
        storedScroll: [...Array(pdfCount)], // set scroll position
        updateScaleTimes: [...Array(pdfCount)],
      };

      if (isSignNow()) {
        return {
          ...newState,
          originalSizes: state.originalSizes || [...Array(pdfCount)],
          scales: state.scales || [...Array(pdfCount)],
          headerScales: state.headerScales || fill(Array(pdfCount), initialScale),
        };
      }

      return {
        ...newState,
        originalSizes: [...Array(pdfCount)],
        scales: [...Array(pdfCount)],
        headerScales: fill(Array(pdfCount), initialScale),
      };
    }

    case SET_DRAG_SCROLL_DISTINCTION:
      return {
        ...state,
        dragScrollDistinction: action.dragScrollDistinction,
      };

    case SAVE_CARET_POSITION:
      return {
        ...state,
        caretPosition: action.caretPosition,
      };

    case CLEAR_CARET_POSITION:
      return {
        ...state,
        caretPosition: null,
      };

    default:
      return state;
  }
}

export function scrollToTopSn() {
  return { type: SCROLL_TO_TOP_SN };
}

export function setOriginalSize(pageId, originalSize) {
  return { type: SET_ORIGINAL_SIZE, pageId, originalSize };
}

export const initializeNavigation = deprecate(
  `Action ${INITIALIZE_ARRAYS} deprecated, use getDocumentSuccess`,
  (count) => {
    return { type: INITIALIZE_ARRAYS, count };
  },
);

export function setCanGoNextPage(can, index) {
  return { type: SET_CAN_GO_NEXT_PAGE, can, index };
}

export function setCanGoPrevPage(can, index) {
  return { type: SET_CAN_GO_PREV_PAGE, can, index };
}

export function setFrameScroll(scroll, index) {
  return { type: SET_FRAME_SCROLL, scroll, index };
}

export function setContiniousFrameScroll({ scrollTop, scrollLeft }) {
  return { type: SET_CONTINIOUS_FRAME_SCROLL, scrollTop, scrollLeft };
}

export function setIsContiniousScrollAnimated(isContiniousScrollAnimated) {
  return { type: SET_IS_CONTINIOUS_SCROLL_ANIMATED, isContiniousScrollAnimated };
}

export function setActivePage(nextPage, opts) {
  return { type: SET_ACTIVE_PAGE, nextPage, opts };
}

export function changePage(nextPage, prevPage, activeElementId) {
  // prevPage is optional. You can send it if you want scroll nextPage
  // - to top (if prevPage < nextPage)
  // - to bottom (if prevPage > nextPage)
  //
  // activeElementId is optional. You can send it if you want
  // set active element after page rendered
  return { type: CHANGE_PAGE, nextPage, prevPage, activeElementId };
}

// if scale is null, used header scale
export function setScale(scale, index) {
  return { type: SET_SCALE, scale, index };
}

export function setAllScales(scale, index) {
  return { type: SET_ALL_SCALES, scale, index };
}

export function setAllScalesContinious(scale) {
  return { type: SET_ALL_SCALES_CONTINIOUS, scale };
}

export function changeScale(scale, index) {
  return { type: CHANGE_SCALE, scale, index };
}

export function setDragScrollDistinction(dragScrollDistinction) {
  return { type: SET_DRAG_SCROLL_DISTINCTION, dragScrollDistinction };
}

export function goToFlashSite() {
  return { type: GO_TO_FLASH_SITE };
}

export function saveActivePage(pageId) {
  return { type: SAVE_ACTIVE_PAGE, pageId };
}

export const activePageIdSelector = (state) => {
  return state.navigation.activePageId;
};

export function setGhostPageId(ghostPageId) {
  return { type: SET_GHOST_PAGE_ID, ghostPageId };
}

export function saveCaretPosition(caretPosition) {
  return { type: SAVE_CARET_POSITION, caretPosition };
}

export function clearCaretPosition() {
  return { type: CLEAR_CARET_POSITION };
}
