import PropTypes from 'prop-types';
import React from 'react';
import classnames from 'classnames';

export const sliderThemes = {
  lightgray: 'lightgray',
  null: null,
};

const RangeSlider =
  ({ percents, storeRef, handlers, theme }) => {
    return (
      <div
        className={classnames('range-slider', {
          [`range-slider--theme--${theme}`]: theme,
        })}
        ref={storeRef}
        {...handlers}
      >
        <div className="range-slider__body">
          <div className="range-slider__substrate" />
          <div className="range-slider__progress" style={{ width: `${percents}%` }} />
        </div>

        <div
          tabIndex="0"
          role="slider"
          aria-valuemin="0"
          aria-valuenow={percents}
          aria-valuemax="100"
          aria-label="Range slider"
          className="range-slider__handle"
          style={{ left: `${percents}%` }}
        />
      </div>
    );
  };

RangeSlider.propTypes = {
  percents: PropTypes.number.isRequired,
  storeRef: PropTypes.func.isRequired,
  handlers: PropTypes.shape({
    onMouseDown: PropTypes.func,
    onTouchStart: PropTypes.func,
  }).isRequired,
  theme: PropTypes.oneOf(Object.values(sliderThemes)),
};

RangeSlider.defaultProps = {
  theme: sliderThemes.null,
};

export default RangeSlider;
