import { all, put, takeEvery } from 'redux-saga/effects';
import { trackPoint } from 'ws-editor-lib/actions';

import {
  ACTIVATE_FAKE_EDIT,
  DEACTIVATE_FAKE_EDIT,
} from 'jsfcore/store/modules/viewport';

export const FAKE_EDIT_ACTIVE = 'FAKE_EDIT_ACTIVE';
export const FAKE_EDIT_ACTIVATED = 'FAKE_EDIT_ACTIVATED';
export const FAKE_EDIT_DEACTIVATED = 'FAKE_EDIT_DEACTIVATED';

const getOnActivateFakeEdit = () => {
  let wasItTheFirstActivation = false;

  return function* onActivateFakeEdit(action) {
    if (action.byScenario === true) {
      if (!wasItTheFirstActivation) {
        yield put(trackPoint(FAKE_EDIT_ACTIVE, {}));
        wasItTheFirstActivation = true;
      }
      return;
    }
    yield put(trackPoint(FAKE_EDIT_ACTIVATED, {}));
  };
};

const onActivateFakeEdit = getOnActivateFakeEdit();

function* onDeactivateFakeEdit() {
  yield put(trackPoint(FAKE_EDIT_DEACTIVATED, {}));
}

function* watchOnActivateFakeEdit() {
  yield takeEvery(ACTIVATE_FAKE_EDIT, onActivateFakeEdit);
}

function* watchOnDeactivateFakeEdit() {
  yield takeEvery(DEACTIVATE_FAKE_EDIT, onDeactivateFakeEdit);
}

export default function* fakeEditRootSaga() {
  yield all([
    watchOnActivateFakeEdit(),
    watchOnDeactivateFakeEdit(),
  ]);
}
