import React, { useContext, useCallback } from 'react';
import PropTypes from 'prop-types';
import FocusControllerContext from './FocusControllerContext';
import Singlify from './Singlify';
import { interceptFocusDataset } from './FocusControllerRestoreFocusArea';

const focusControllerElementDecorator = (Component) => {
  const FocusControllerProvider = (props) => {
    const { id } = props;

    const context = useContext(FocusControllerContext);

    // eslint-disable-next-line react/destructuring-assignment
    const storeRef = useCallback(context.storeRef(id), [id]);

    // eslint-disable-next-line react/destructuring-assignment
    const onBlur = useCallback(context.onBlur(id), [id]);

    // eslint-disable-next-line react/destructuring-assignment
    const onFocus = useCallback(context.onFocus(id), [id]);

    // eslint-disable-next-line react/destructuring-assignment
    const onMouseDown = useCallback(context.onMouseDown(id), [id]);

    return (
      <Component
        {...props}
        storeRef={storeRef}
        onBlur={onBlur}
        onFocus={onFocus}
        onMouseDown={onMouseDown}
        interceptFocusDataset={interceptFocusDataset}
      />
    );
  };

  FocusControllerProvider.propTypes = {
    id: PropTypes.string.isRequired,
  };

  return Singlify(Component)(FocusControllerProvider);
};

export default focusControllerElementDecorator;
