
import PropTypes from 'prop-types';
import React from 'react';

const TodoItemCheck = ({ children }) => {
  return (
    <p className="todo-item__check">
      {children}
    </p>
  );
};

TodoItemCheck.propTypes = {
  children: PropTypes.node,
};

TodoItemCheck.defaultProps = {
  children: null,
};

export default TodoItemCheck;
