import { directions, invertDirection } from '../direction';
import { modifiers } from '../const';

const ARROW_SIZE = 7;
const DISTANCE_TO_ARROW_FROM_EDGE = 11.5 + (ARROW_SIZE / 2);

export function appendArrowToRectSize(args) {
  const { elementRect, side } = args;

  if (side === directions.left || side === directions.right) {
    return {
      ...elementRect,
      width: elementRect.width + ARROW_SIZE,
      right: elementRect.right + ARROW_SIZE,
    };
  }

  if (side === directions.top || side === directions.bottom) {
    return {
      ...elementRect,
      height: elementRect.height + ARROW_SIZE,
      bottom: elementRect.bottom + ARROW_SIZE,
    };
  }
  return elementRect;
}

function createArrowPositionFunction(align) {
  return (alignAdvanced) => {
    return `${align}-${alignAdvanced}`;
  };
}

function getArrowDirection({ side, anchorRect, viewportRect, isValid }) {
  const invertionNeeded = isValid ||
    (side === directions.bottom && anchorRect.top <= viewportRect.bottom) ||
    (side === directions.top && anchorRect.bottom >= viewportRect.top) ||
    (side === directions.right && anchorRect.left >= viewportRect.right) ||
    (side === directions.left && anchorRect.right >= viewportRect.left);

  return invertionNeeded
    ? invertDirection(side)
    : side;
}

export function getArrowPosition(args) {
  const { alignedRect, sideCenteredRect, side } = args;
  const createArrowPosition = createArrowPositionFunction(getArrowDirection(args), args);

  if (side === directions.left || side === directions.right) {
    const quartHeight = alignedRect.height / 4;
    const distanceFromCenter = sideCenteredRect.top - alignedRect.top;
    if (Math.abs(distanceFromCenter) <= quartHeight) {
      return createArrowPosition(directions.center);
    }
    if (distanceFromCenter > 0) {
      return createArrowPosition(directions.bottom);
    }
    return createArrowPosition(directions.top);
  }
  const quartWidth = alignedRect.width / 4;
  const distanceFromCenter = sideCenteredRect.left - alignedRect.left;
  if (Math.abs(distanceFromCenter) <= quartWidth) {
    return createArrowPosition(directions.center);
  }
  if (distanceFromCenter > 0) {
    return createArrowPosition(directions.right);
  }
  return createArrowPosition(directions.left);
}

export function appendEdgeOffsetToCenterArrow(args) {
  const { alignedRect, align, anchorRect, modifier } = args;

  if (modifier === modifiers.edge) {
    const leftOffset = DISTANCE_TO_ARROW_FROM_EDGE + (anchorRect.width / 2);
    const topOffset = DISTANCE_TO_ARROW_FROM_EDGE + (anchorRect.height / 2);
    switch (align) {
      case directions.right:
        return {
          ...alignedRect,
          left: alignedRect.left - leftOffset,
          right: alignedRect.right - leftOffset,
        };

      case directions.left:
        return {
          ...alignedRect,
          left: alignedRect.left + leftOffset,
          right: alignedRect.right + leftOffset,
        };

      case directions.top:
        return {
          ...alignedRect,
          top: alignedRect.top - topOffset,
          bottom: alignedRect.top - topOffset,
        };

      case directions.bottom:
        return {
          ...alignedRect,
          top: alignedRect.top + topOffset,
          bottom: alignedRect.top + topOffset,
        };

      default:
        return alignedRect;
    }
  }

  return alignedRect;
}

export const appendVerticalOffsetToAlignedRect = ({ alignedRect, side, verticalOffset }) => {
  if (!verticalOffset) {
    return alignedRect;
  }

  if (side === directions.top) {
    return {
      ...alignedRect,
      top: alignedRect.top - verticalOffset,
      bottom: alignedRect.bottom - verticalOffset,
    };
  }

  if (side === directions.bottom) {
    return {
      ...alignedRect,
      top: alignedRect.top + verticalOffset,
      bottom: alignedRect.bottom + verticalOffset,
    };
  }

  return alignedRect;
};

export default {
  postMeasure: (args) => {
    const { elementRect, side } = args;
    return {
      ...args,
      elementRect: appendArrowToRectSize({ elementRect, side }),
    };
  },
  postCalculation: (args) => {
    const {
      side, align, modifier, alignedRect, anchorRect,
      otherArgs: { verticalOffset },
    } = args;

    const alignedRectWithVerticalOffset = appendVerticalOffsetToAlignedRect({
      alignedRect,
      side,
      verticalOffset,
    });

    return {
      ...args,
      alignedRect: appendEdgeOffsetToCenterArrow({
        alignedRect: alignedRectWithVerticalOffset,
        anchorRect,
        align,
        modifier,
      }),
    };
  },
  postClamping: (args) => {
    const { alignedRect, sideCenteredRect, side, anchorRect, viewportRect, isValid } = args;

    return {
      ...args,
      otherArgs: {
        ...args.otherArgs,
        arrowPosition: getArrowPosition({
          alignedRect,
          sideCenteredRect,
          side,
          anchorRect,
          viewportRect,
          isValid,
        }),
      },
    };
  },
};
