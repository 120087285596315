import { isValidDropdownElement } from '../../../store/helpers/functions';
import { elementSettingsTypes } from '../../../helpers/elemTypes';
import * as consts from '../../const';

const isPrefilledDropdownValid = (element) => {
  const { template: { prefilled, allowCustomText, list } } = element;
  const prefilledDefined = (
    prefilled !== '' &&
    prefilled !== undefined
  );

  if (allowCustomText || !prefilledDefined) {
    return true;
  }

  if (list.indexOf(prefilled) !== -1) {
    return true;
  }

  return false;
};

const validateDropdown = (element) => {
  const dropdownHasValues = isValidDropdownElement(element);
  const prefilledIsValid = isPrefilledDropdownValid(element);
  const errors = [];

  if (!dropdownHasValues) {
    errors.push({
      field: elementSettingsTypes.answerChoices,
      errorText: 'Please specify one or more answer choices',
      isGlobal: true,
    });
  }

  if (!prefilledIsValid) {
    errors.push({
      field: elementSettingsTypes.prefilled,
      errorText: 'Value of “Prefilled” field has to be one of the "Answer choices"',
      isGlobal: true,
    });
  }

  if (errors.length === 0) {
    return consts.valid;
  }

  return {
    isValid: false,
    errors,
  };
};

export default validateDropdown;
