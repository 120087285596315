import { createElement } from 'react';
import PropTypes from 'prop-types';

import getLazy from '../getLazy';

function LazyComponent({ literal, props }) {
  return createElement(
    getLazy(literal),
    props,
  );
}

LazyComponent.propTypes = {
  literal: PropTypes.string.isRequired,
  props: PropTypes.shape({}),
};

LazyComponent.defaultProps = {
  props: undefined,
};

export default LazyComponent;
