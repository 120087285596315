import PropTypes from 'prop-types';
import React, { Component } from 'react';
import cls from 'classnames';

export const groupSizes = {
  small: 'small',
  parent: 'parent',
  parentHeight: 'parent-height',
  parentWidth: 'parent-width',
  null: null,
};

export const groupTypes = {
  compact: 'compact',
  null: null,
};

export const groupOffsets = {
  largeVertical: 'large-vertical',
  extraSmall: 'extra-small',
  extraSmallHorizontal: 'extra-small-horizontal',
  extraLarge: 'extra-large',
  small: 'small',
  smallHorizontal: 'small-horizontal',
  null: null,
};

export const groupOffsetsHorizontal = {
  small: 'small',
  null: null,
};

export const groupBehaviors = {
  flattenRadius: 'flatten-radius',
  flexboxWrap: 'flexbox-wrap',
  null: null,
};

export const groupAligns = {
  center: 'center',
  flexStart: 'flex-start',
  null: null,
};

export const groupJustifies = {
  center: 'center',
  flexEnd: 'flex-end',
  spaceBetween: 'space-between',
  null: null,
};

export default class Group extends Component {
  static propTypes = {
    children: PropTypes.node,

    size: PropTypes.oneOf(
      Object.values(groupSizes),
    ),
    type: PropTypes.oneOf(
      Object.values(groupTypes),
    ),
    offset: PropTypes.oneOf(
      Object.values(groupOffsets),
    ),
    offsetHorizontal: PropTypes.oneOf(
      Object.values(groupOffsetsHorizontal),
    ),
    behavior: PropTypes.oneOf(
      Object.values(groupBehaviors),
    ),
    align: PropTypes.oneOf(
      Object.values(groupAligns),
    ),
    justify: PropTypes.oneOf(
      Object.values(groupJustifies),
    ),
  };

  static defaultProps = {
    children: null,

    size: groupSizes.null,
    type: groupTypes.null,
    offset: groupTypes.null,
    offsetHorizontal: groupOffsetsHorizontal.null,
    behavior: groupBehaviors.null,
    align: groupAligns.null,
    justify: groupJustifies.null,
  };

  render() {
    const {
      align,
      behavior,
      justify,
      offset,
      offsetHorizontal,
      size,
      type,
    } = this.props;

    return (
      <div
        className={cls('group', {
          [`group--size--${size}`]: size,
          [`group--type--${type}`]: type,
          [`group--offset--${offset}`]: offset,
          [`group--offset-horizontal--${offsetHorizontal}`]: offsetHorizontal,
          [`group--behavior--${behavior}`]: behavior,
          [`group--align--${align}`]: align,
          [`group--justify--${justify}`]: justify,
        })}
      >
        {this.props.children}
      </div>
    );
  }
}
