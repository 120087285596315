import PropTypes from 'prop-types';
import React, { Component } from 'react';

export default class StatusMessage extends Component {
  static propTypes = {
    text: PropTypes.element.isRequired,
    icon: PropTypes.element,
  };

  static defaultProps = {
    icon: null,
  };

  render() {
    return (
      <p
        className="status-bar status-bar--justify--flex-end"
        aria-live="polite"
        aria-relevant="text"
      >
        <span className="status-bar__body">

          {this.props.icon &&
            <span className="status-bar__icon">
              {this.props.icon}
            </span>
          }

          {this.props.text &&
            <span className="status-bar__text">
              {this.props.text}
            </span>
          }

        </span>
      </p>
    );
  }
}
