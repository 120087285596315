import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

const Tabs = ({ children, theme, behavior, offset }) => {
  return (
    <div
      className={classnames('tabs', {
        [`tabs--theme--${theme}`]: theme,
        [`tabs--behavior--${behavior}`]: behavior,
        [`tabs--offset--${offset}`]: offset,
      })}
    >
      <div className="tabs__body">
        {children}
      </div>
    </div>
  );
};

const tabsThemes = {
  null: null,
  default: 'default',
  lightgray: 'lightgray',
  primary: 'primary',
};

const tabsBehaviors = {
  null: null,
  tablistRight: 'tablist-right',
  flexbox: 'flexbox',
};

const tabsOffsets = {
  null: null,
  small: 'small',
};

Tabs.propTypes = {
  children: PropTypes.node.isRequired,
  theme: PropTypes.oneOf(Object.values(tabsThemes)),
  behavior: PropTypes.oneOf(Object.values(tabsBehaviors)),
  offset: PropTypes.oneOf(Object.values(tabsOffsets)),
};

Tabs.defaultProps = {
  theme: tabsThemes.null,
  behavior: tabsBehaviors.null,
  offset: tabsOffsets.null,
};

export default Tabs;
export { tabsThemes, tabsBehaviors, tabsOffsets };
