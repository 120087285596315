import actionTypes from './types.js';
import { DEFAULT_DESTROY_TYPE } from './constants';
import messageTypes from './messageTypes.js';

const {
  AUTH_INIT, AUTH_RECEIVE, AUTH_RESTORE, SET_EXTERNAL_USER, OPERATIONS_RECEIVE, ACTIVATE_TOOL,
  ACTIVATE_MODE, TIMER_TICK, ADD_GALLERY_IMAGE, UPDATE_GALLERY_IMAGE, GALLERY_IMAGES_LIST,
  EDITOR_FEEDBACK, SET_CONFIRMED_OPS, ADD_ELEMENT, GET_QR_URL, SEND_PHONE_NUMBER, SEND_EMAIL,
  UPDATE_ELEMENT, UPDATE_ELEMENTS, SET_ACTIVE_ELEMENT, REMOVE_ELEMENT,
  DESTROY, DELETE_GALLERY_SIGNATURE, GALLERY_SIGNATURES_LIST,
  CLIENT_OPERATION, USER_OPERATION, PROJECT_OPERATION, GET_ACCESS, SERVER_DISCONNECT,
  UPDATE_GALLERY_SIGNATURE, UPDATE_TODO_LIST,
  ADD_ERROR, SERVER_ERROR, SERVER_CONNECT,
  CLEAR_ELEMENT, DESTROY_RECEIVE, AUTH_FAKE, DELETE_GALLERY_IMAGE, LOADING_PAGE,
  CHANGE_MESSAGE, UPDATE_DEFAULT_SETTINGS, ERROR_POINT, TRACK_POINT, RECONNECT,
  API_HASH_SET, PROBLEM_REPORT, APPLY_CONFIG_PAGES, MESSAGE_SET,
  ACTIVATE_FILLABLE_TOOL, UPDATE_FILLABLE_ELEMENT, UPDATE_FILLABLE_ELEMENTS,
  REMOVE_FILLABLE_ELEMENT, ADD_FILLABLE_ELEMENT, UPDATE_ORDER_FILLABLE_ELEMENTS, REMOVE_ELEMENTS,
  ADD_ELEMENTS, ADD_FILLABLE_ELEMENTS, ACTIVATE_FCMODE, CANCEL_FCMODE, ACTIVATE_DEFAULT_MODE,
  FULL_RELOAD, UPDATE_FILLABLE_GROUPS, ADD_FILLABLE_ELEMENT_AS_IS,
  UPDATE_ROUTING,
  SAVE_ROUTING_SIGNERS_TEMPLATE,
  REMOVE_ROUTING_SIGNERS_TEMPLATE,
  SET_ACTIVE_SIGNER,
  SET_DEFAULT_FILLABLE_CHECKMARK_SUBTYPE,
  ACTIVATE_PENDING_MODE_FOR_ADDONS,
  ADD_GALLERY_SIGNATURE,

  // PA Actions
  SET_PA_SETTINGS, UPDATE_ATTR_DEFAULTS,

  // TEMP
  SET_ELEMENTS,
  // Comments
  ADD_COMMENT, UPDATE_COMMENT, DELETE_COMMENT,
  // recognize font by image
  SEND_FONT_RECOGNITION_IMAGE,

  SET_EXPERIMENTAL_FEATURES,
  CHANGE_ALLOW_EDITING,
  DELETE_CONTENT_FOR_PREVIEW_MODE,

  // pdf
  SET_PDF_DOCUMENT_SCALE,

  AUTOFILL,
  SET_ORGANIZATION,
  ACTIVATE_VERSIONS_MODE,
  GET_VERSIONS_LIST,
  CANCEL_VERSIONS_MODE,
  PREVIEW_VERSION,
  RESTORE_VERSION,
  SAVE_VERSION,

  RESET_ELEMENTS,
  RESET_WS_PAGES,
  ON_OPERATIONS_DOCUMENTSOURCE_TOOLSPAGES,
  SET_WS_CONNECTION_FAILED,
  SEND_TRIGGER_OPERATION,
  FLUSH_OPERATIONS_QUEUE,

  // externalValidation
  CHECK_EXTERNAL_VALIDATION,
} = actionTypes;


// TEMP
const setElements = (elements) => {
  return ({
    type: SET_ELEMENTS,
    elements,
  });
};

const authInit = ({ projectId, viewerId, config, sessionHash, _host, organization }) => {
  return ({
    type: AUTH_INIT,
    config,
    projectId,
    sessionHash,
    viewerId,
    _host,
    organization,
  });
};

const authRestore = ({ projectId, viewerId, config, sessionHash, _host, organization, uid }) => {
  return ({
    type: AUTH_RESTORE,
    config,
    projectId,
    sessionHash,
    viewerId,
    _host,
    organization,
    uid,
  });
};

const authFake = (projectId, viewerId, config) => {
  return ({
    type: AUTH_FAKE,
    config,
    projectId,
    viewerId,
  });
};

// authReconnect: config => ({
//   type: AUTH_RECONNECT,
//   config,
// }),

const serverConnect = () => {
  return ({
    type: SERVER_CONNECT,
  });
};

const serverDisconnect = (code) => {
  return ({
    type: SERVER_DISCONNECT,
    code,
  });
};

const serverError = (err) => {
  return ({
    type: SERVER_ERROR,
    err,
  });
};

const destroy = (
  url,
  doneProgress = false,
  destroyType = DEFAULT_DESTROY_TYPE,
  error = false,
  saveChanges = true,
) => {
  return ({
    type: DESTROY,
    params: {
      location: url,
      destroyType,
      error,
      clearDataFlag: !saveChanges,
    },
    doneProgress,
  });
};

const destroyBeforeUnload = (url, doneProgress = false) => {
  return {
    type: DESTROY,
    params: {
      location: url,
      destroyType: DEFAULT_DESTROY_TYPE,
      error: false,
      clearDataFlag: false,
      isNeedToWSDisconnect: true,
    },
    doneProgress,
  };
};

const destroyByScenario = (params, doneProgress = false) => {
  return ({
    type: DESTROY,
    params,
    doneProgress,
  });
};

const addError = (err) => {
  return ({
    type: ADD_ERROR,
    err,
  });
};

const clientOperation = (operation) => {
  return ({
    type: CLIENT_OPERATION,
    operation,
  });
};

const userOperation = (operation) => {
  return ({
    type: USER_OPERATION,
    operation,
  });
};

const projectOperation = (operation) => {
  return ({
    type: PROJECT_OPERATION,
    operation,
  });
};

const authReceive = (auth) => {
  return ({
    type: AUTH_RECEIVE,
    auth,
  });
};

const setExternalUser = (user) => {
  return ({
    type: SET_EXTERNAL_USER,
    user,
  });
};

const setConfirmedOps = (confirmedOps) => {
  return ({
    type: SET_CONFIRMED_OPS,
    confirmedOps,
  });
};

const operationReceive = (operations) => {
  return ({
    type: OPERATIONS_RECEIVE,
    operations,
  });
};

const destroyReceive = ({ location, force, params = {} }) => {
  return ({
    type: DESTROY_RECEIVE,
    location,
    force,
    params,
  });
};

const activateTool = (toolType, toolSubType, item) => {
  return ({
    type: ACTIVATE_TOOL,
    toolType,
    toolSubType,
    item,
  });
};

const addElement = (element, opts = {}) => {
  return ({
    type: ADD_ELEMENT,
    element,
    opts,
  });
};

const addElements = (elements, opts = {}) => {
  return ({
    type: ADD_ELEMENTS,
    elements,
    opts,
  });
};

const addFillableElements = (elements, opts = {}) => {
  return ({
    type: ADD_FILLABLE_ELEMENTS,
    elements,
    opts,
  });
};

const getQrUrl = () => {
  return ({
    type: GET_QR_URL,
  });
};

const sendPhoneNumber = (number) => {
  return ({
    type: SEND_PHONE_NUMBER,
    number,
  });
};

const sendEmail = (email) => {
  return ({
    type: SEND_EMAIL,
    email,
  });
};

const updateElement = (id, content, opts = {}) => {
  return ({
    type: UPDATE_ELEMENT,
    id,
    content,
    opts,
  });
};

const updateElements = (elements, opts = {}) => {
  return ({
    type: UPDATE_ELEMENTS,
    elements,
    opts,
  });
};

// deprecated method
const updateElementContent = (id, content, opts = {}) => {
  return ({
    type: UPDATE_ELEMENT,
    id,
    content,
    opts,
  });
};

const updateTodoList = (todoListState) => {
  return ({
    type: UPDATE_TODO_LIST,
    todoListState,
  });
};

const updateDefaultSettings = (key, value) => {
  return ({
    type: UPDATE_DEFAULT_SETTINGS,
    key,
    value,
  });
};

const setActiveElement = (id, activeStatus, opts = {}) => {
  return ({
    type: SET_ACTIVE_ELEMENT,
    activeStatus,
    id,
    opts,
  });
};

const removeElement = (id, opts = {}) => {
  return ({
    type: REMOVE_ELEMENT,
    id,
    opts,
  });
};

const removeElements = (ids, opts = {}) => {
  return ({
    type: REMOVE_ELEMENTS,
    ids,
    opts,
  });
};

const clearElement = (id) => {
  return ({
    type: CLEAR_ELEMENT,
    id,
  });
};

const getAccess = () => {
  return ({
    type: GET_ACCESS,
  });
};

const timerTick = (accessTimer) => {
  return ({
    type: TIMER_TICK,
    accessTimer,
  });
};

const loadingPage = (page, pages) => {
  return ({
    type: LOADING_PAGE,
    page,
    pages,
  });
};
const changeMessage = (messageType) => {
  return ({
    type: CHANGE_MESSAGE,
    messageType,
  });
};

const setMessage = (message) => {
  return ({
    type: MESSAGE_SET,
    message,
  });
};

const addGalleryImage = (image) => {
  return ({
    type: ADD_GALLERY_IMAGE,
    image,
  });
};

const updateGalleryImage = (image) => {
  return ({
    type: UPDATE_GALLERY_IMAGE,
    image,
  });
};

const galleryImagesList = () => {
  return ({
    type: GALLERY_IMAGES_LIST,
  });
};

const gallerySignaturesList = () => {
  return ({
    type: GALLERY_SIGNATURES_LIST,
  });
};

const deleteGalleryImages = (image) => {
  return ({
    type: DELETE_GALLERY_IMAGE,
    image,
  });
};

const fullReload = () => {
  return ({
    type: FULL_RELOAD,
  });
};

const addGallerySignature = (signature, isNeedToUse) => {
  return ({
    type: ADD_GALLERY_SIGNATURE,
    signature,
    isNeedToUse,
  });
};

const updateGallerySignature = (signature, isNeedToUse) => {
  return ({
    type: UPDATE_GALLERY_SIGNATURE,
    signature,
    isNeedToUse,
  });
};
const deleteGallerySignature = (signature) => {
  return ({
    type: DELETE_GALLERY_SIGNATURE,
    signature,
  });
};
const trackPoint = (pointName, options = {}) => {
  return ({
    type: TRACK_POINT,
    pointName,
    options,
  });
};
const errorPoint = (pointName, options) => {
  return ({
    type: ERROR_POINT,
    options,
    pointName,
  });
};

const reconnect = (config, reconnectCounter = 1) => {
  return ({
    type: RECONNECT,
    config,
    reconnectCounter,
  });
};

const feedback = (message) => {
  return ({
    type: EDITOR_FEEDBACK,
    message,
  });
};

const setApiHash = (apiHash) => {
  return ({
    type: API_HASH_SET,
    apiHash,
  });
};

const reportProblem = (payload) => {
  return ({
    type: PROBLEM_REPORT,
    payload,
  });
};

const applyConfigPages = (pages, opts = {}) => {
  return ({
    type: APPLY_CONFIG_PAGES,
    pages,
    opts,
  });
};

const activateFillableTool = (tool) => {
  return { type: ACTIVATE_FILLABLE_TOOL, tool };
};

const addFillableElement = (element, opts) => {
  return { type: ADD_FILLABLE_ELEMENT, element, opts };
};

const addFillableElementAsIs = (element, opts) => {
  return { type: ADD_FILLABLE_ELEMENT_AS_IS, element, opts };
};

const updateFillableElement = ({ id, template = {}, subType }, opts) => {
  return { type: UPDATE_FILLABLE_ELEMENT, id, template, subType, opts };
};

const updateFillableElements = (elements) => {
  return { type: UPDATE_FILLABLE_ELEMENTS, elements };
};

const removeFillableElement = (id, opts) => {
  return { type: REMOVE_FILLABLE_ELEMENT, id, opts };
};

const updateRoles = (steps, cc) => {
  return { type: UPDATE_ROUTING, steps, cc };
};

const updateRouting = (steps, cc, opts = {}) => {
  return { type: UPDATE_ROUTING, steps, cc, opts };
};

const saveRoutingSignersTemplate = (steps, cc) => {
  return { type: SAVE_ROUTING_SIGNERS_TEMPLATE, steps, cc };
};

const removeRoutingSignersTemplate = () => {
  return { type: REMOVE_ROUTING_SIGNERS_TEMPLATE };
};

const setActiveSigner = (roleId, opts = {}) => {
  return { type: SET_ACTIVE_SIGNER, roleId, opts };
};

const updateOrderFillableElements = ({
  order, prevOrder, id, hasBeenMoved, croppedPosition = false,
}, opts) => {
  return {
    type: UPDATE_ORDER_FILLABLE_ELEMENTS,
    order,
    prevOrder,
    id,
    hasBeenMoved,
    croppedPosition,
    opts,
  };
};

const setPaSettings = (newAttrContent, opts = {}) => {
  return { type: SET_PA_SETTINGS, newAttrContent, opts };
};

const updateAttrDefaults = () => {
  return { type: UPDATE_ATTR_DEFAULTS };
};

const updateFillableGroups = (fillableGroups = {}, opts = {}) => {
  return { type: UPDATE_FILLABLE_GROUPS, fillableGroups, opts };
};

const activateFCMode = (initial) => {
  const initialParameter = initial
    ? { initial: true }
    : {};
  return {
    type: ACTIVATE_FCMODE,
    ...initialParameter,
  };
};

const cancelFCMode = () => {
  return { type: CANCEL_FCMODE };
};

const doActivateDefaultMode = (isNeedToClearSnapshot) => {
  return {
    type: ACTIVATE_DEFAULT_MODE,
    isNeedToClearSnapshot,
  };
};

const activateMode = (mode) => {
  return { type: ACTIVATE_MODE, mode };
};

const activatePendingModeForAddons = () => {
  return { type: ACTIVATE_PENDING_MODE_FOR_ADDONS };
};

const sendTriggerOperation = ({ element, trigger }) => {
  return { type: SEND_TRIGGER_OPERATION, element, trigger };
};

const addComment = (comment) => {
  return ({
    type: ADD_COMMENT,
    comment,
  });
};

const updateComment = (comment) => {
  return ({
    type: UPDATE_COMMENT,
    comment,
  });
};

const deleteComment = (comment) => {
  return ({
    type: DELETE_COMMENT,
    comment,
  });
};

const sendFontRecognitionImage = (base64, format, text) => {
  return { type: SEND_FONT_RECOGNITION_IMAGE, base64, format, text };
};

const setExperimentalFeatures = (experimentalFeatures) => {
  return {
    type: SET_EXPERIMENTAL_FEATURES,
    experimentalFeatures,
  };
};

const setDefaultCheckmarkSubtype = (value) => {
  return {
    type: SET_DEFAULT_FILLABLE_CHECKMARK_SUBTYPE,
    value,
  };
};

const changeAllowEditing = (roleId) => {
  return {
    type: CHANGE_ALLOW_EDITING,
    roleId,
  };
};

const deleteContentForPreviewMode = () => {
  return {
    type: DELETE_CONTENT_FOR_PREVIEW_MODE,
  };
};
const setPdfDocumentScale = (pdfScale) => {
  return {
    type: SET_PDF_DOCUMENT_SCALE,
    pdfScale,
  };
};

const doAutofill = (projectId) => {
  return {
    type: AUTOFILL,
    projectId,
  };
};

const setOrganization = (organization) => {
  return {
    type: SET_ORGANIZATION,
    organization,
  };
};

const turnWizardLite = () => {
  return { type: actionTypes.TURN_WIZARD_LITE };
};

const turnWizardFull = () => {
  return { type: actionTypes.TURN_WIZARD_FULL };
};

const setLastActiveFillableElementId = (value) => {
  return { type: actionTypes.SET_LAST_ACTIVE_FILLABLE_ELEMENT_ID, value };
};

const activateVersionsMode = () => {
  return { type: ACTIVATE_VERSIONS_MODE };
};

const getVersionsList = () => {
  return { type: GET_VERSIONS_LIST };
};

const cancelVersionsMode = () => {
  return { type: CANCEL_VERSIONS_MODE };
};

const previewVersion = (versionType, versionId) => {
  return {
    type: PREVIEW_VERSION,
    versionId,
    versionType,
  };
};

const restoreVersion = (versionType, versionId) => {
  return {
    type: RESTORE_VERSION,
    versionId,
    versionType,
  };
};

const saveVersion = (versionType, versionId) => {
  return {
    type: SAVE_VERSION,
    versionId,
    versionType,
  };
};

const resetElements = () => {
  return {
    type: RESET_ELEMENTS,
  };
};

const resetWSPages = () => {
  return {
    type: RESET_WS_PAGES,
  };
};

const setToolAndPages = (operations) => {
  return {
    type: ON_OPERATIONS_DOCUMENTSOURCE_TOOLSPAGES,
    operations: operations.reduce((acc, operation) => {
      return Object.assign(acc, operation.list);
    }, {}),
  };
};

const setWsConnectionFailed = (params, isError) => {
  return {
    type: SET_WS_CONNECTION_FAILED,
    params,
    isError,
  };
};

const changeElementPageId = ({ pageId, elementId }, opts = {}) => {
  return {
    type: actionTypes.CHANGE_ELEMENT_PAGE_ID,
    pageId,
    elementId,
    opts,
  };
};

const resetComments = () => {
  return {
    type: actionTypes.RESET_COMMENTS,
  };
};

const flushOperationsQueue = () => {
  return {
    type: FLUSH_OPERATIONS_QUEUE,
  };
};

const checkExternalValidation = (validationMode, destroyParams) => {
  return {
    type: CHECK_EXTERNAL_VALIDATION,
    validationMode,
    destroyParams,
  };
};

export {
  setElements,
  authInit,
  authRestore,
  authFake,
  serverConnect,
  serverDisconnect,
  serverError,
  destroy,
  destroyBeforeUnload,
  destroyByScenario,
  addError,
  clientOperation,
  userOperation,
  projectOperation,
  authReceive,
  setExternalUser,
  setConfirmedOps,
  operationReceive,
  destroyReceive,
  activateTool,
  addElement,
  addElements,
  addFillableElements,
  getQrUrl,
  sendPhoneNumber,
  sendEmail,
  updateElement,
  updateElements,
  updateElementContent,
  updateTodoList,
  updateDefaultSettings,
  setActiveElement,
  removeElement,
  removeElements,
  clearElement,
  getAccess,
  timerTick,
  loadingPage,
  changeMessage,
  setMessage,
  addGalleryImage,
  updateGalleryImage,
  galleryImagesList,
  gallerySignaturesList,
  deleteGalleryImages,
  fullReload,
  addGallerySignature,
  updateGallerySignature,
  deleteGallerySignature,
  trackPoint,
  errorPoint,
  reconnect,
  feedback,
  setApiHash,
  reportProblem,
  applyConfigPages,
  activateFillableTool,
  addFillableElement,
  addFillableElementAsIs,
  updateFillableElement,
  updateFillableElements,
  removeFillableElement,
  updateRoles,
  updateRouting,
  saveRoutingSignersTemplate,
  removeRoutingSignersTemplate,
  setActiveSigner,
  updateOrderFillableElements,
  setPaSettings,
  updateAttrDefaults,
  updateFillableGroups,
  activateFCMode,
  cancelFCMode,
  doActivateDefaultMode,
  activateMode,
  activatePendingModeForAddons,
  addComment,
  updateComment,
  deleteComment,
  sendFontRecognitionImage,
  setExperimentalFeatures,
  setDefaultCheckmarkSubtype,
  changeAllowEditing,
  deleteContentForPreviewMode,
  setPdfDocumentScale,
  doAutofill,
  setOrganization,
  turnWizardLite,
  turnWizardFull,
  setLastActiveFillableElementId,
  activateVersionsMode,
  getVersionsList,
  cancelVersionsMode,
  previewVersion,
  restoreVersion,
  saveVersion,
  resetElements,
  resetWSPages,
  setToolAndPages,
  setWsConnectionFailed,
  changeElementPageId,
  resetComments,
  flushOperationsQueue,
  checkExternalValidation,
  sendTriggerOperation,

  actionTypes,
  messageTypes,
};
