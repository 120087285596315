import get from 'lodash/get';
import omit from 'lodash/omit';
import groupBy from 'lodash/groupBy';
import cloneDeep from 'lodash/cloneDeep';
import isEmpty from 'lodash/isEmpty';
import { isRadioElement } from 'jsfcore/store/helpers/functions';
import { isIntegrationField } from 'jsfcore/helpers/utils';

import findDependentElements from '../../helpers/findDependentElements';
import { getDependenciesByPath } from '../../helpers/conditionsUtils';
import { getDependency, dependenciesMap } from '../../di';
import * as conditionalSelector from '../../selectors';

export function changeDependencies({
  dependentElements, newName = null, newRadioValue = null, next,
}) {
  return (dispatch, getState) => {
    const state = getState();
    const jsfcoreSelectors = getDependency(dependenciesMap.jsfcoreSelectors);
    const updateFillableElementWithPreprocessing =
      getDependency(dependenciesMap.updateFillableElementWithPreprocessing);

    const activeElement = jsfcoreSelectors.elements.getActiveElement(state);
    const groupedDependentElementByElementId = groupBy(dependentElements, ({ id }) => {
      return id;
    });

    const isSignNow = getDependency(dependenciesMap.isSignNow)();
    const ids = Object.keys(groupedDependentElementByElementId);

    for (let i = 0; i < ids.length; i++) {
      const id = ids[i];
      const deps = groupedDependentElementByElementId[id];

      const el = jsfcoreSelectors.elements.getElementByIdFactory(id)(state);
      const clonnedDependencies = cloneDeep(el.template.dependency);

      for (let k = 0; k < deps.length; k++) {
        const dependenciesByPath = getDependenciesByPath(clonnedDependencies, deps[k].path);
        if (newName !== null) {
          dependenciesByPath.name = newName;
        }

        if (newRadioValue !== null) {
          // этот код будет удален после имплементации графа
          // тут такой кейс если мы полностью удалили radioValue и вставили новое
          // необходимо обновить не все элементы радио группы а только те у которых есть
          // такое же radioValue в dependency
          if (isSignNow) {
            if (
              dependenciesByPath.value === '' ||
              newRadioValue === dependenciesByPath.value ||
              dependenciesByPath.value === activeElement.template.radioValue
            ) {
              dependenciesByPath.value = newRadioValue;
            }
          } else {
            dependenciesByPath.value = newRadioValue;
          }
        }
      }

      if (activeElement.id === id) {
        dispatch(next({
          id,
          template: {
            dependency: clonnedDependencies,
          },
        }));
      } else {
        dispatch(
          updateFillableElementWithPreprocessing({
            id,
            template: {
              dependency: clonnedDependencies,
            },
          }),
        );
      }
    }
  };
}

export function getDependentElements(template, id, value) {
  return (getState) => {
    const jsfcoreSelectors = getDependency(dependenciesMap.jsfcoreSelectors);

    const elements = jsfcoreSelectors.elements.getElements(getState());
    const elementsWithDeps = elements.filter((elem) => {
      return (
        // Filter activeElement
        elem.id !== id &&
        // Filter elements without dependencies
        get(elem, 'template.dependency', null) && elem.template.conditional
      );
    });

    return findDependentElements(
      template.name,
      elementsWithDeps,
      value,
    );
  };
}

/**
 * @param {Array} elements - array of elements
 * @return {Boolean}
 */
export const hasElementWithCondition = (elements) => {
  return elements.some((element) => {
    return element.template.conditional;
  });
};

/**
 * @param {Array} elements - array of elements
 * @return {Boolean} isGroup
 */
export const isGroupOfElements = (elements) => {
  return elements.length > 1;
};

function conditionsCheck(next, checkingElement) {
  return (elem, opts) => {
    return async (dispatch, getState) => {
      const jsfcoreSelectors = getDependency(dependenciesMap.jsfcoreSelectors);
      const jsfcoreModalsActions = getDependency(dependenciesMap.jsfcoreModalsActions);
      const jsfcoreModalsTypes = getDependency(dependenciesMap.jsfcoreModalsTypes);
      const isSignNow = getDependency(dependenciesMap.isSignNow)();

      const activeElement = checkingElement ||
        jsfcoreSelectors.elements.getActiveElement(getState());
      const oldElement = jsfcoreSelectors.elements.getElementByIdFactory(
        activeElement.id,
      )(getState());
      const oldTemplate = oldElement.template;
      const newTemplate = elem.template;
      const isIntegration = isIntegrationField(activeElement);

      if (newTemplate.conditional || newTemplate.name) {
        /*
          мы должны запретить пользователю изменять "Database Field Name" и conditional если:
          1) он пытается сделать одинаковый "Database Field Name" с полем,
          которое "conditional: true";
          2) он пытается установить conditionl полю,
          которое состоит в группе "Database Field Name".

          https://pdffiller.atlassian.net/browse/JSF-5209

          ** проверку пришлось делать в этом месте, из-за возможноcти менять
          "Database Field Name" полю на которое есть dendency у другого поля.

          Был найден баг, позволяющий поменять "Database Field Name" элементу "1"
          на имя элемента "2" у которого есть есть зависимость от элемента "1".

          пример:
          элемент с именем А зависит от элемента с именем Б
          мы пытаемся именить имя элемента Б на А
          что происходило:
            - у элемента с именем Б изменялось имя на А
            - у элемента с именем А менялась зависимость с Б на А
            в итоге елемент А зависил от елемента А
          сейчас:
            - у елемента не изменяется имя, если он пытается поменять его на
            имя элемента, у которого "conditional: true"
        */

        const elements = jsfcoreSelectors.elements.getElements(getState());
        const name = newTemplate.name
          ? newTemplate.name
          : activeElement.template.name;
        const filteredElements = elements.filter((element) => {
          return (
            !isRadioElement(element) &&
            !isIntegrationField(element) &&
            get(element, 'template.name') === name
          );
        });

        /*
         * TODO:
         * isSignNow пришел из SNF-1084
         * проверить такой же кейс в JSF, если там тоже есть такой баг - убрать if
         */
        const elementsToCheckForAllowChangeNameOrConditional = isSignNow
          ? filteredElements
          : [...filteredElements, activeElement];
        const isNotAllowCangeNameOrConditional = newTemplate.name
          ? hasElementWithCondition(elementsToCheckForAllowChangeNameOrConditional)
          : isGroupOfElements(filteredElements);

        if (isNotAllowCangeNameOrConditional && !isIntegration) {
          jsfcoreModalsActions.openModal(
            jsfcoreModalsTypes.modalsMap[jsfcoreModalsTypes.modalTypes.warning],
            jsfcoreModalsTypes.warningTypes.setConditionalOrChangeDBName,
          )(dispatch);

          const elementWithOmitedTemplate = omit(elem.template, 'name', 'conditional');

          if (isEmpty(elementWithOmitedTemplate.template)) {
            return null;
          }

          return next({
            ...elem,
            template: elementWithOmitedTemplate,
          }, opts)(dispatch, getState);
        }
      }

      const nameChanged = (
        !isIntegration &&
        oldTemplate && newTemplate.name &&
        oldTemplate.name !== newTemplate.name
      );
      const radioValueChanged = (
        oldTemplate &&
        newTemplate.radioValue !== undefined &&
        oldTemplate.radioValue !== newTemplate.radioValue
      );

      if (nameChanged || radioValueChanged) {
        const dependentElements = getDependentElements(
          oldTemplate,
          oldElement.id,
          radioValueChanged
            ? oldTemplate.radioValue
            : undefined,
        )(getState);
        if (dependentElements.length > 0) {
          if (radioValueChanged) {
            const newRadioValue = newTemplate.radioValue;

            const hasDependenciesContainsOldRadioValue = dependentElements
              .some((dependentElement) => {
                const dependentElementFromMap = jsfcoreSelectors
                  .elements
                  .getElementByIdFactory(dependentElement.id)(getState());

                const value = get(dependentElementFromMap, 'template.dependency.value', null);
                const clauses = get(dependentElementFromMap, 'template.dependency.clauses', []);

                if (value !== null) {
                  return value === oldTemplate.radioValue;
                }

                return conditionalSelector
                  .getDependenciesValuesDeep(clauses)
                  .includes(oldTemplate.radioValue);
              });

            if (!hasDependenciesContainsOldRadioValue) {
              return next(elem, opts)(dispatch, getState);
            }

            if (isSignNow) {
              const radioGroupValues = jsfcoreSelectors
                .getRadioElementsByRadioGroupName(getState(), oldTemplate.name);
              const isValueUnique = radioGroupValues.filter((element) => {
                return element.template.radioValue === oldTemplate.radioValue;
              }).length === 1;

              if (isValueUnique) {
                // in SignNow for radio value we save changes anyway
                changeDependencies({
                  dependentElements,
                  newRadioValue,
                  next,
                })(dispatch, getState);
              } else {
                // but user choose update conditions or not
                // in case there are more than one equal radio values
                const modalPromise = jsfcoreModalsActions.openModal(
                  jsfcoreModalsTypes.modalsMap[jsfcoreModalsTypes.modalTypes.confirmation],
                  jsfcoreModalsTypes.confirmationTypes.updateRadio,
                )(dispatch);
                const isConfirmed = await modalPromise;

                if (isConfirmed) {
                  changeDependencies({
                    dependentElements,
                    newRadioValue,
                    next,
                  })(dispatch, getState);
                }
              }
            } else {
              const modalPromise = jsfcoreModalsActions.openModal(
                jsfcoreModalsTypes.modalsMap[jsfcoreModalsTypes.modalTypes.confirmation],
                jsfcoreModalsTypes.confirmationTypes.updateConditions,
              )(dispatch);
              const isConfirmed = await modalPromise;

              if (isConfirmed) {
                changeDependencies({
                  dependentElements,
                  newRadioValue,
                  next,
                })(dispatch, getState);
              } else {
                return next({
                  ...elem,
                  template: omit(elem.template, ['radioValue']),
                }, opts)(dispatch, getState);
              }
            }
          }

          if (nameChanged) {
            const newName = newTemplate.name;

            if (isSignNow) {
              // in SignNow we skip user dialogue and force conditions update
              changeDependencies({
                dependentElements,
                newName,
                next,
              })(dispatch, getState);
            } else {
              const modalPromise = jsfcoreModalsActions.openModal(
                jsfcoreModalsTypes.modalsMap[jsfcoreModalsTypes.modalTypes.confirmation],
                jsfcoreModalsTypes.confirmationTypes.updateConditions,
              )(dispatch);
              const isConfirmed = await modalPromise;

              if (isConfirmed) {
                changeDependencies({
                  dependentElements,
                  newName,
                  next,
                })(dispatch, getState);
              } else {
                return next({
                  ...elem,
                  template: omit(elem.template, ['name']),
                }, opts)(dispatch, getState);
              }
            }
          }
        }
      }

      return next(elem, opts)(dispatch, getState);
    };
  };
}

export default conditionsCheck;
