import PropTypes from 'prop-types';
import React, { Component } from 'react';
import cls from 'classnames';

import { gridSizes, gridOffsets, gridTypes, gridJustifies } from './GridCell';

export default class FlexGrid extends Component {
  static propTypes = {
    children: PropTypes.node,
    size: PropTypes.oneOf(
      Object.values(gridSizes),
    ),
    offset: PropTypes.oneOf(
      Object.values(gridOffsets),
    ),
    type: PropTypes.oneOf(
      Object.values(gridTypes),
    ),
    justify: PropTypes.oneOf(
      Object.values(gridJustifies),
    ),
  };

  static defaultProps = {
    children: null,
    size: gridSizes.null,
    offset: gridOffsets.null,
    type: gridTypes.null,
    justify: gridJustifies.null,
  };

  render() {
    const { size, offset, type, justify } = this.props;

    return (
      <div
        className={cls('flex-grid', {
          [`flex-grid--size--${size}`]: size,
          [`flex-grid--offset--${offset}`]: offset,
          [`flex-grid--type--${type}`]: type,
          [`flex-grid--justify--${justify}`]: justify,
        })}
      >
        {this.props.children}
      </div>
    );
  }
}
