import clamp from 'lodash/clamp';

import { modifiers } from './const';
import {
  directions,
  getPossibleDirections,
} from './direction';
import getPresetObject from './presets';

export const getNewRectGeneratorBasedOnElementSize = ({ height, width }) => {
  return ({ left = false, top = false, right = false, bottom = false }) => {
    if (left !== false && top !== false) {
      return {
        left,
        top,
        width,
        height,
        right: left + width,
        bottom: top + height,
      };
    }

    if (left !== false && bottom !== false) {
      return {
        left,
        bottom,
        width,
        height,
        top: bottom - height,
        right: left + width,
      };
    }

    if (right !== false && bottom !== false) {
      return {
        right,
        bottom,
        width,
        height,
        left: right - width,
        top: bottom - height,
      };
    }

    if (right !== false && top !== false) {
      return {
        right,
        top,
        width,
        height,
        left: right - width,
        bottom: top + height,
      };
    }

    throw new Error(
      'function recalcRectByFourValues should accept x and y position + width and height',
    );
  };
};

export function getNodeRect(element, omitPosition = false) {
  const { width, height, top: topBound, left: leftBound } = element.getBoundingClientRect();
  const { pageXOffset: scrollX, pageYOffset: scrollY } = window;

  const left = omitPosition
    ? 0
    : leftBound + scrollX;
  const top = omitPosition
    ? 0
    : topBound + scrollY;

  return getNewRectGeneratorBasedOnElementSize({ width, height })({ left, top });
}

export function getCenterDotByRect(nodeRect) {
  return {
    x: nodeRect.right - (nodeRect.width / 2),
    y: nodeRect.bottom - (nodeRect.height / 2),
  };
}

export function getSideCenteredRect({ elementRect, anchorRect, side, modifier }) {
  const anchorCenter = getCenterDotByRect(anchorRect);
  const { width, height } = elementRect;
  const halfWidth = width / 2;
  const halfHeight = height / 2;
  const generateRectByTwoPositions = getNewRectGeneratorBasedOnElementSize({ width, height });

  if (modifier === modifiers.inner) {
    switch (side) {
      case directions.top:
        return generateRectByTwoPositions({
          top: anchorRect.top,
          left: anchorCenter.x - halfWidth,
        });
      case directions.bottom:
        return generateRectByTwoPositions({
          left: anchorCenter.x - halfWidth,
          top: anchorRect.bottom - height,
        });
      case directions.left:
        return generateRectByTwoPositions({
          top: anchorCenter.y - halfHeight,
          left: anchorRect.left,
        });
      case directions.right:
        return generateRectByTwoPositions({
          top: anchorCenter.y - halfHeight,
          left: anchorRect.right - width,
        });

      case directions.center:
      default:
        return generateRectByTwoPositions({
          top: anchorCenter - halfHeight,
          left: anchorCenter - halfWidth,
        });
    }
  }

  if (modifier === modifiers.edge || modifier === modifiers.outer) {
    switch (side) {
      case directions.top:
        return generateRectByTwoPositions({
          top: anchorRect.top - height,
          left: anchorCenter.x - halfWidth,
        });
      case directions.bottom:
        return generateRectByTwoPositions({
          top: anchorRect.bottom,
          left: anchorCenter.x - halfWidth,
        });
      case directions.left:
        return generateRectByTwoPositions({
          top: anchorCenter.y - halfHeight,
          left: anchorRect.left - width,
        });
      case directions.right:
        return generateRectByTwoPositions({
          left: anchorRect.right,
          top: anchorCenter.y - halfHeight,
        });
      default:
        return elementRect;
    }
  }

  return elementRect;
}

export function getAlignedRect({ sideCenteredRect, anchorRect, align, modifier }) {
  const { width, height } = sideCenteredRect;
  const generateRectByTwoPositions = getNewRectGeneratorBasedOnElementSize({ width, height });

  if (modifier === modifiers.edge) {
    switch (align) {
      case directions.top: {
        return generateRectByTwoPositions({
          left: sideCenteredRect.left,
          bottom: anchorRect.top,
        });
      }
      case directions.left: {
        return generateRectByTwoPositions({
          top: sideCenteredRect.top,
          right: anchorRect.left,
        });
      }
      case directions.bottom: {
        return generateRectByTwoPositions({
          left: sideCenteredRect.left,
          top: anchorRect.bottom,
        });
      }
      case directions.right: {
        return generateRectByTwoPositions({
          left: anchorRect.right,
          top: sideCenteredRect.top,
        });
      }
      default:
        return sideCenteredRect;
    }
  }

  if (modifier === modifiers.outer || modifier === modifiers.inner) {
    switch (align) {
      case directions.top: {
        return generateRectByTwoPositions({
          left: sideCenteredRect.left,
          top: anchorRect.top,
        });
      }
      case directions.left: {
        return generateRectByTwoPositions({
          top: sideCenteredRect.top,
          left: anchorRect.left,
        });
      }
      case directions.bottom: {
        return generateRectByTwoPositions({
          left: sideCenteredRect.left,
          bottom: anchorRect.bottom,
        });
      }
      case directions.right: {
        return generateRectByTwoPositions({
          right: anchorRect.right,
          top: sideCenteredRect.top,
        });
      }
      default:
        return sideCenteredRect;
    }
  }
  return sideCenteredRect;
}

export function isAlignmentValid(nodeRect, viewportRect) {
  // check that nodeRect in viewportRect
  const vertical = nodeRect.top >= viewportRect.top && nodeRect.bottom <= viewportRect.bottom;
  const horizontal = nodeRect.left >= viewportRect.left && nodeRect.right <= viewportRect.right;

  return vertical && horizontal;
}

export function measureNodes({ inputElements, ...rest }) {
  const { element, anchor, viewport } = inputElements;

  return {
    ...rest,
    elementRect: element instanceof Node
      ? getNodeRect(element, true)
      : element,
    anchorRect: anchor instanceof Node
      ? getNodeRect(anchor)
      : anchor,
    viewportRect: viewport instanceof Node
      ? getNodeRect(viewport)
      : viewport,
  };
}

export function calculatePositions(args) {
  const { elementRect, anchorRect, side, align, modifier } = args;
  // get elementNode rect applied to center anchorNode by side
  const sideCenteredRect = getSideCenteredRect({
    elementRect,
    anchorRect,
    side,
    modifier,
  });

  const alignedRect = getAlignedRect({
    sideCenteredRect,
    anchorRect,
    align,
    modifier,
  });

  return {
    ...args,
    sideCenteredRect,
    alignedRect,
  };
}

export function validateCalculation(args) {
  const { alignedRect, viewportRect } = args;
  const isValid = isAlignmentValid(alignedRect, viewportRect);

  return {
    ...args,
    isValid,
  };
}

export function clampPositions(args) {
  const { alignedRect, viewportRect, isValid, clampToViewport } = args;

  // if align is valid, there is no need to clamp
  if (isValid || !clampToViewport) {
    return args;
  }

  const [clampX, clampY] = clampToViewport;
  const top = clampY
    ? clamp(alignedRect.top, viewportRect.top, viewportRect.bottom - alignedRect.height)
    : alignedRect.top;

  const left = clampX
    ? clamp(alignedRect.left, viewportRect.left, viewportRect.right - alignedRect.width)
    : alignedRect.left;

  return {
    ...args,
    alignedRect: {
      ...alignedRect,
      top,
      bottom: top + alignedRect.height,
      left,
      right: left + alignedRect.width,
    },
  };
}

export function finalizeResult(args) {
  const { alignedRect, hideIfAnchorOutOfViewport, otherArgs } = args;
  const { left, top } = alignedRect;

  if (hideIfAnchorOutOfViewport) {
    const { viewportRect, anchorRect } = args;
    if (
      anchorRect.bottom < viewportRect.top ||
      anchorRect.right < viewportRect.left ||
      anchorRect.left > viewportRect.right ||
      anchorRect.top > viewportRect.bottom
    ) {
      return {
        ...otherArgs,
        left: 0,
        top: 0,
        isVisible: false,
      };
    }
  }

  return {
    ...otherArgs,
    left: Math.round(left),
    top: Math.round(top),
    isVisible: true,
  };
}

/**
 * measureNodes(),
 ** postMeasure(),
 *
 * calculatePositions(),
 ** postCalculation(),
 * validateCalculation(),
 ** postValidation(),
 *
 * clampPositions(),
 ** postClamping(),
 * finalizerResult(),
 */

const flowReduce = (acc, fn) => {
  return fn(acc);
};

export function processFlow(args, presetObject) {
  const measures = [
    measureNodes,
    presetObject.postMeasure,
  ].reduce(
    flowReduce,
    args,
  );

  const newArgs = getPossibleDirections(measures).reduce((acc, pos) => {
    const { isValid } = acc;
    if (isValid) {
      return acc;
    }

    const [side, align] = pos;
    return [
      calculatePositions,
      presetObject.postCalculation,
      validateCalculation,
      presetObject.postValidation,
    ].reduce(flowReduce, {
      ...acc,
      side,
      align,
      isValid,
    });
  }, {
    ...measures,
    isValid: false,
  });

  return [
    clampPositions,
    presetObject.postClamping,
    finalizeResult,
  ].reduce(
    flowReduce,
    newArgs,
  );
}

export function computeOffsetsWithinElement({
  element,
  anchor,
  viewport,
  position,

  smartPosition = true,
  hideIfAnchorOutOfViewport = false,
  clampToViewport = [true, true],
  restrictedPositions = null,
  preset,
  ...otherArgs
}) {
  const [side, align, modifier] = position.split('-');
  const inputElements = { element, anchor, viewport };
  const presetObject = getPresetObject(preset);

  return processFlow({
    inputElements,
    side,
    align,
    modifier,

    smartPosition,
    hideIfAnchorOutOfViewport,
    clampToViewport,
    restrictedPositions,
    otherArgs,
  }, presetObject);
}
