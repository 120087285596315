import flow from 'lodash/flow';
import { preprocessBeforeSend } from './beforeSend';
import { preprocessBeforeUpdate } from './beforeUpdate';

const preprocess = (preprocessFunctions) => {
  return (element) => {
    return flow(
      preprocessFunctions
        .filter(({ statementFunction }) => {
          return statementFunction(element);
        })
        .map(({ preprocessFunction }) => {
          return preprocessFunction();
        }),
    )(element);
  };
};

export const preprocessBeforeSendElement = preprocess(preprocessBeforeSend);
export const preprocessBeforeUpdateElement = preprocess(preprocessBeforeUpdate);
