import PropTypes from 'prop-types';
import React, { Component } from 'react';
import classnames from 'classnames';
import {
  defaultZoomScale,
  positions,
} from '../../../helpers/const';
import ResizeBox from '../../ResizeBox/ResizeBox';

const borderWidth = 2; // TODO: from static css
export const textboxClassName = 'jsf-document-attr--text-box';

export default class TextBoxToolView extends Component {
  static propTypes = {
    children: PropTypes.arrayOf(
      PropTypes.element.isRequired,
    ).isRequired,
    borderColor: PropTypes.string,
    bgColor: PropTypes.string,
    scale: PropTypes.number,
    innerStyle: PropTypes.shape({
      marginBottom: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
      ]),
      marginLeft: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
      ]),
      marginRight: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
      ]),
      marginTop: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
      ]),
    }).isRequired, // css-object
    width: PropTypes.number.isRequired,
    height: PropTypes.number.isRequired,
    isGhost: PropTypes.bool,
    isActiveElement: PropTypes.bool,
    isDisabled: PropTypes.bool.isRequired,

    // from elementDraggableDecorator
    // not required for ghost
    onResizeDragStart: PropTypes.func,
    onResizeDragMove: PropTypes.func,
    onResizeDragStop: PropTypes.func,
    isDragging: PropTypes.bool,
    resizeIndex: PropTypes.number,
  };

  static defaultProps = {
    borderColor: '000000',
    bgColor: 'FFFFFF',
    scale: defaultZoomScale,
    isGhost: false,
    isActiveElement: false,
    onResizeDragStart: () => {
      return false;
    },
    onResizeDragMove: () => {
      return false;
    },
    onResizeDragStop: () => {
      return false;
    },
    isDragging: false,
    resizeIndex: positions.none,
  }

  getDocumentAttrStyle = (innerStyle) => {
    return {
      top: innerStyle.marginTop,
      bottom: innerStyle.marginBottom,
      left: innerStyle.marginLeft,
      right: innerStyle.marginRight,
    };
  }

  render() {
    const { bgColor, borderColor, innerStyle, scale, width, height, isDisabled } = this.props;

    // TODO: to static css
    const staticStyle = { width, height, display: 'inline-block' };
    const textboxStyle = {
      ...staticStyle,
      backgroundColor: `#${bgColor}`,
      borderColor: `#${borderColor}`,
      borderWidth: `${borderWidth * scale}px`,
    };

    const innerStyleMapped = this.getDocumentAttrStyle(innerStyle);
    return (
      <div
        style={textboxStyle}
        className={classnames('jsf-document-attr', textboxClassName, {
          'elementHover-Content': !isDisabled,
        })}
      >
        {!this.props.isGhost && this.props.isActiveElement && <ResizeBox
          onResizeDragStart={this.props.onResizeDragStart}
          onResizeDragMove={this.props.onResizeDragMove}
          onResizeDragStop={this.props.onResizeDragStop}
          isDragging={this.props.isDragging}
          resizeIndex={this.props.resizeIndex}
          scale={this.props.scale}
        />}
        <div className="jsf-document-attr-inner" style={innerStyleMapped}>
          {this.props.children}
        </div>
      </div>
    );
  }
}
