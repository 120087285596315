import React from 'react';
import PropTypes from 'prop-types';

const PanelMain = ({ children }) => {
  return (
    <div className="panel__main">
      <div className="panel__content">
        {children}
      </div>
    </div>
  );
};

PanelMain.propTypes = {
  children: PropTypes.node,
};

PanelMain.defaultProps = {
  children: null,
};

export default PanelMain;
