import { takeEvery, put, select, take } from 'redux-saga/effects';
import { actionTypes } from 'ws-editor-lib/actions';
import editorModes from 'ws-editor-lib/constants/editorModes';
import { versionsPanelShow } from 'jsfcore/store/modules/viewport';
import { clearUndoRedoHistory } from 'jsfcore/store/actions/undoRedo/undoRedo';
import { getVersionsModeErrorModalLocale } from 'jsfcore/store/selectors/localesSelectors';
import { isVersions } from 'jsfcore/store/selectors/modeSelectors';
import { closeModal, openModal } from 'combine-modals/lib/actions/actionCreator';
import { DIALOG_MODAL, ERROR_MODAL } from 'combine-modals/lib/constants/modal.classnames';

export function* onVersionsMode({
  operations: [{ properties: { subType: currentAppMode, allowed } }],
}) {
  const isVersionsMode = yield select(isVersions);
  if (currentAppMode !== editorModes.versions || isVersionsMode) {
    return;
  }

  if (allowed) {
    yield put(versionsPanelShow());
    yield put(clearUndoRedoHistory());
    yield take(actionTypes.ON_OPERATIONS_VERSIONS_LIST);
    yield put(closeModal(DIALOG_MODAL));
  } else {
    const { title, text, okButtonText } = yield select(getVersionsModeErrorModalLocale);
    const options = {
      title,
      content: text,
      btnConfirmText: okButtonText,
    };
    yield put(closeModal(DIALOG_MODAL));
    yield put(openModal(ERROR_MODAL, options));
  }
}

export default function* () {
  yield takeEvery(
    [actionTypes.ON_OPERATIONS_EDITOR_MODE],
    onVersionsMode,
  );
}
