import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import uniqueId from 'lodash/uniqueId';

export const checkboxSizes = {
  parent: 'parent',
  smallStretched: 'small-stretched',
  null: null,
};

export const checkboxThemes = {
  transparentGray: 'transparent-gray',
  null: null,
};

export const checkboxTypes = {
  indicator: 'indicator',
  null: null,
};

export const checkboxOffsets = {
  medium: 'medium',
  null: null,
};

export const checkboxDataAsteriskPositions = {
  top: 'top',
  null: null,
};

export const checkboxDataIndicators = {
  custom: 'custom',
  null: null,
};

export default class Checkbox extends Component {
  static propTypes = {
    children: PropTypes.element.isRequired,
    dataAsteriskPosition: PropTypes.oneOf(
      Object.values(checkboxDataAsteriskPositions),
    ),
    dataIndicator: PropTypes.oneOf(
      Object.values(checkboxDataIndicators),
    ),
    input: PropTypes.oneOfType([
      PropTypes.element,
      PropTypes.arrayOf(PropTypes.oneOfType([
        PropTypes.element,
        PropTypes.bool,
      ])),
    ]),
    isChecked: PropTypes.bool.isRequired,
    isInaccessible: PropTypes.bool,
    isReadOnly: PropTypes.bool,
    isRequired: PropTypes.bool,
    onMouseEnter: PropTypes.func,
    onMouseLeave: PropTypes.func,
    size: PropTypes.oneOf(
      Object.values(checkboxSizes),
    ),
    storeRef: PropTypes.func,
    theme: PropTypes.oneOf(
      Object.values(checkboxThemes),
    ),
    type: PropTypes.oneOf(
      Object.values(checkboxTypes),
    ),
    offset: PropTypes.oneOf(
      Object.values(checkboxOffsets),
    ),
    title: PropTypes.string,
    onChange: PropTypes.func,
    onClick: PropTypes.func,
  };

  static defaultProps = {
    dataAsteriskPosition: checkboxDataAsteriskPositions.null,
    dataIndicator: checkboxDataIndicators.null,
    input: null,
    isInaccessible: false,
    isReadOnly: false,
    isRequired: false,
    onMouseEnter: null,
    onMouseLeave: null,
    size: checkboxSizes.null,
    storeRef: null,
    theme: checkboxThemes.null,
    type: checkboxTypes.null,
    offset: checkboxOffsets.null,
    title: null,
    onChange: null,
    onClick: null,
  };

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillMount() {
    this.id = uniqueId('checkbox');
  }

  render() {
    const {
      children,
      dataAsteriskPosition,
      dataIndicator,
      isChecked,
      isInaccessible,
      input,
      isReadOnly,
      isRequired,
      size,
      storeRef,
      theme,
      title,
      type,
      offset,
    } = this.props;

    return (
      <label
        className={classnames('checkbox', {
          [`checkbox--size--${size}`]: size,
          [`checkbox--theme--${theme}`]: theme,
          [`checkbox--type--${type}`]: type,
          [`checkbox--offset--${offset}`]: offset,
          'is-checked': isChecked,
          'is-inaccessible': isInaccessible,
          'is-required': isRequired,
        })}
        data-asterisk-position={dataAsteriskPosition}
        data-indicator={dataIndicator}
        htmlFor={this.id}
        onMouseEnter={this.props.onMouseEnter}
        onMouseLeave={this.props.onMouseLeave}
        ref={storeRef}
        title={title}
        tabIndex="0"
        onClick={this.props.onClick}
      >
        <span className="checkbox__body">
          <span className="checkbox__indicator">
            <input
              id={this.id}
              className="checkbox__object"
              readOnly={isReadOnly}
              type="checkbox"
              checked={isChecked}
              tabIndex="-1"
              onChange={this.props.onChange}
            />
            <span
              className="checkbox__custom-object"
              aria-hidden="true"
            />
          </span>
          <span className="checkbox__label">
            {children}
            {input}
          </span>
        </span>
      </label>
    );
  }
}
