import { Component } from 'react';
import PropTypes from 'prop-types';

export default class FormattedMessage extends Component {
  static propTypes = {
    id: PropTypes.oneOfType([
      PropTypes.string.isRequired,
      PropTypes.arrayOf(PropTypes.string).isRequired,
    ]).isRequired,
    interpolationParam: PropTypes.arrayOf(
      PropTypes.oneOfType([
        PropTypes.shape({}),
        PropTypes.string,
      ]),
    ),

    children: PropTypes.func.isRequired,
  };

  static contextTypes = {
    formatMessage: PropTypes.func.isRequired,
  };

  static defaultProps = {
    interpolationParam: [],
  };

  render() {
    if (typeof this.props.id === 'string') {
      return this.props.children(
        this.context.formatMessage('', this.props.id, ...this.props.interpolationParam),
      );
    }

    return this.props.children(
      ...(this.props.id.map((idString, i) => {
        return this.context.formatMessage('', idString, this.props.interpolationParam[i]);
      })),
    );
  }
}
