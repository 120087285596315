import pick from 'lodash/pick';
import find from 'lodash/find';
import { thisDevice } from '@pdffiller/jsf-useragent';
import { getModulePromise, modulesLiterals } from '@pdffiller/jsf-lazyload';
import { actionTypes as AT } from 'ws-editor-lib/actions';
import { TODO_LIST_STATES as todoListStates } from 'ws-editor-lib/constants';

import {
  popupMenu,
  zeroSpace,
  popupStatuses,
  pageFrameDefaultPaddingMobile,
  pageFrameDefaultPadding,
  pageFrameDefaultPaddingMobileSnFiller,
  pageFrameCommentsPaddingWithPointer,
  pageFramePointerPadding,
  pageFrameCommentsPadding,
  DRAGGABLE_ENTITIES,
} from '../../helpers/const';
import { getPopupSize, isSignNow } from '../../helpers/utils';
import { RESET_ALL_REDUCERS } from './events';
import * as selectors from '../selectors/selectors';

export const UPDATE_WORKSPACE = 'viewport/UPDATE_WORKSPACE';
export const UPDATE_WORKSPACE_PADDING = 'viewport/UPDATE_WORKSPACE_PADDING';

export const TOGGLE_THUMBNAILS_VISIBILITY = 'viewport/TOGGLE_THUMBNAILS_VISIBILITY';

export const SET_ACTIVE_POPUP_MENU = 'tools/SET_ACTIVE_POPUP_MENU';
export const TOGGLE_POPUP = 'tools/TOGGLE_POPUP';
export const RESET_POPUP = 'tools/RESET_POPUP';
export const SET_POPUP_SHOWN = 'tools/SET_POPUP_SHOWN';
export const SET_WIZZARD_FOOTER_HEIGHT = 'viewport/SET_WIZZARD_FOOTER_HEIGHT';
export const SET_WIZZARD_ASSISTANT_SIZE = 'viewport/SET_WIZZARD_ASSISTANT_SIZE';

export const SHOW_FIND_ANOTHER_FORM_BUTTON = 'viewport/SHOW_FIND_ANOTHER_FORM_BUTTON';
export const HIDE_FIND_ANOTHER_FORM_BUTTON = 'viewport/HIDE_FIND_ANOTHER_FORM_BUTTON';
export const SHOW_FIND_ANOTHER_FORM_BUBBLE = 'viewport/SHOW_FIND_ANOTHER_FORM_BUBBLE';
export const HIDE_FIND_ANOTHER_FORM_BUBBLE = 'viewport/HIDE_FIND_ANOTHER_FORM_BUBBLE';
export const SHOW_MESSAGE_BUTTON = 'viewport/SHOW_MESSAGE_BUTTON';
export const HIDE_MESSAGE_BUTTON = 'viewport/HIDE_MESSAGE_BUTTON';
export const SET_MESSAGE_BUTTON_CONTENT = 'viewport/SET_MESSAGE_BUTTON_CONTENT';
export const DECLINE_BUTTON_SHOW = 'viewport/DECLINE_BUTTON_SHOW';
export const DECLINE_BUTTON_HIDE = 'viewport/DECLINE_BUTTON_HIDE';
export const DRAFT_BUTTON_SHOW = 'viewport/DRAFT_BUTTON_SHOW';
export const DRAFT_BUTTON_HIDE = 'viewport/DRAFT_BUTTON_HIDE';

export const ADD_TOOLBAR_OVERLAY = 'viewport/ADD_TOOLBAR_OVERLAY';
export const REMOVE_TOOLBAR_OVERLAY = 'viewport/REMOVE_TOOLBAR_OVERLAY';

export const SET_KEYBOARD_SHOWN = 'viewport/SET_KEYBOARD_SHOWN';
export const SET_KEYBOARD_SIZE = 'viewport/SET_KEYBOARD_SIZE';

export const PAGE_ATTRIBUTES_CONSTRUCTOR_SHOW = 'viewport/PAGE_ATTRIBUTES_CONSTRUCTOR_SHOW';
export const PAGE_ATTRIBUTES_CONSTRUCTOR_HIDE = 'viewport/PAGE_ATTRIBUTES_CONSTRUCTOR_HIDE';
export const SET_PA_ACTIVE_SECTION = 'viewport/SET_PA_ACTIVE_SECTION';

export const CONSTRUCTOR_SHOW = 'viewport/CONSTRUCTOR_SHOW';
export const CONSTRUCTOR_HIDE = 'viewport/CONSTRUCTOR_HIDE';

export const OPEN_HREF_MODAL = 'viewport/OPEN_HREF_MODAL';
export const CLOSE_HREF_MODAL = 'viewport/CLOSE_HREF_MODAL';

export const SHOW_COMMENTS = 'viewport/SHOW_COMMENTS';
export const HIDE_COMMENTS = 'viewport/HIDE_COMMENTS';

export const TOGGLE_MOBILE_TOOLS_VISIBILITY = 'viewport/TOGGLE_MOBILE_TOOLS_VISIBILITY';

export const BUSY_LOCKER_SHOW = 'viewport/BUSY_LOCKER_SHOW';
export const BUSY_LOCKER_HIDE = 'viewport/BUSY_LOCKER_HIDE';

export const CONDITIONAL_FIELDS_SHOW = 'viewport/CONDITIONAL_FIELDS_SHOW';
export const CONDITIONAL_FIELDS_HIDE = 'viewport/CONDITIONAL_FIELDS_HIDE';

export const UPDATE_DRAGGABLE_PANEL_POSITION = 'viewport/UPDATE_DRAGGABLE_PANEL_POSITION';

export const TOGGLE_SIGNATURE_MANAGER = 'viewport/TOGGLE_SIGNATURE_MANAGER';
export const TOGGLE_IMAGE_MANAGER = 'viewport/TOGGLE_IMAGE_MANAGER';

export const ACTIVATE_FAKE_EDIT = 'viewport/ACTIVATE_FAKE_EDIT';
export const DEACTIVATE_FAKE_EDIT = 'viewport/DEACTIVATE_FAKE_EDIT';
export const FAKE_EDIT_INITIAL_HINT_SHOW = 'viewport/FAKE_EDIT_INITIAL_HINT_SHOW';
export const FAKE_EDIT_INITIAL_HINT_HIDE = 'viewport/FAKE_EDIT_INITIAL_HINT_HIDE';
export const FAKE_EDIT_ROLLOVER_HINT_SHOW = 'viewport/FAKE_EDIT_ROLLOVER_HINT_SHOW';
export const FAKE_EDIT_ROLLOVER_HINT_HIDE = 'viewport/FAKE_EDIT_ROLLOVER_HINT_HIDE';

export const SHOW_POINTER = 'viewport/SHOW_POINTER';
export const HIDE_POINTER = 'viewport/HIDE_POINTER';

export const SHOW_CHOICE_IFRAME = 'viewport/SHOW_CHOICE_IFRAME';
export const HIDE_CHOICE_IFRAME = 'viewport/HIDE_CHOICE_IFRAME';

export const VERSIONS_PANEL_SHOW = 'viewport/VERSIONS_PANEL_SHOW';
export const VERSIONS_PANEL_HIDE = 'viewport/VERSIONS_PANEL_HIDE';

export const SIGNING_SESSION_SHOW = 'viewport/SIGNING_SESSION_SHOW';
export const SIGNING_SESSION_HIDE = 'viewport/SIGNING_SESSION_HIDE';
export const SET_SIGNING_SESSION_TEXTSET = 'viewport/SET_SIGNING_SESSION_TEXTSET';

export const SHOW_CONSTRUCTOR_PREVIEW_DISPATCHER =
  'fConstructor/SHOW_CONSTRUCTOR_PREVIEW_DISPATCHER';

export const CANCEL_SHOWING_BEFORE_UNLOAD_PROMPT_CONFIRMATION =
  'viewport/CANCEL_SHOWING_BEFORE_UNLOAD_PROMPT_CONFIRMATION';

// when they are null - we must use 'zeroSpace' object to compare correctly:
// {portrait: 0, landscape: 0}!=={portrait: 0, landscape: 0} (it are different objects in memory)
// but zeroSpace===zeroSpace :)
export const initialState = {
  isFindAnotherFormButtonVisible: false,
  isFindAnotherFormBubbleVisible: false,
  isMessageButtonVisible: false,
  isDeclineButtonVisible: false,
  isDraftButtonVisible: false,
  isKeyboardShown: false,
  keyboardSize: zeroSpace,
  isThumbnailsOpen: true,
  toolbarOverlays: [],
  messageButtonContent: null,

  // mobile popup state
  activeMenu: popupMenu.main,
  popupVisibility: popupStatuses.hidden,
  canShowPopup: false,
  popupSize: false,
  workspace: {
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    width: 0,
    height: 0,
    framePadding: {},
  },
  body: {
    bottom: 0,
    height: 0,
    left: 0,
    right: 0,
    top: 0,
    width: 0,
    x: 0,
    y: 0,
  },

  // Contain height of wizardFooter div on touch devices
  // (update on wizardFocus)
  wizardFooterHeight: 0,

  // Contain size of wizardAssistant div
  wizardAssistantSize: { width: 0, height: 0 },

  isPAConstructorShown: false,
  activePAConstructorSection: false,
  isFConstructorShown: false,

  isHrefModalShown: false,
  isCommentsShown: false,

  mobileToolsAreVisible: false,

  isBusyLockerShown: false,

  isConditionalFieldsShown: false,

  draggablePanelPosition: {
    [DRAGGABLE_ENTITIES.conditions]: {},
    [DRAGGABLE_ENTITIES.supportChatButton]: {},
  },

  isSignatureManagerShown: false,
  isImageManagerShown: false,

  isFakeEditActive: false,
  isFakeEditInitialHintShown: false,
  isFakeEditRolloverHintShown: true,

  isPointerShown: false,

  isChoiceIframeShown: false,

  isVersionsShown: false,

  isSigningSessionShown: false,
  signingSessionTextSet: false,
};

export const getNewWorkspaceAndBody = (state, action) => {
  return {
    workspace: {
      // get rid of useless values
      ...pick(action.workspace, ['top', 'right', 'bottom', 'left', 'width', 'height']),
      // save workspace padding
      framePadding: state.workspace.framePadding,
    },
    body: action.body,
  };
};

export const updDraggablePanelPosition = (state, action) => {
  return {
    ...state,
    draggablePanelPosition: {
      ...state.draggablePanelPosition,
      [action.entity]: {
        // get rid of useless values
        ...pick(action.draggablePanelPosition, ['top', 'left']),
      },
    },
  };
};

export const updFramePadding = (state, action) => {
  return {
    ...state,
    workspace: {
      ...state.workspace,
      framePadding: action.framePadding,
    },
  };
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case RESET_ALL_REDUCERS:
      return {
        ...initialState,
        body: state.body,
        workspace: state.workspace,
        isThumbnailsOpen: state.isThumbnailsOpen,
      };

    case SHOW_FIND_ANOTHER_FORM_BUTTON:
      return {
        ...state,
        isFindAnotherFormButtonVisible: true,
      };

    case HIDE_FIND_ANOTHER_FORM_BUTTON:
      return {
        ...state,
        isFindAnotherFormButtonVisible: false,
      };

    case SHOW_FIND_ANOTHER_FORM_BUBBLE:
      return {
        ...state,
        isFindAnotherFormBubbleVisible: true,
      };

    case HIDE_FIND_ANOTHER_FORM_BUBBLE:
      return {
        ...state,
        isFindAnotherFormBubbleVisible: false,
      };

    case TOGGLE_THUMBNAILS_VISIBILITY:
      return {
        ...state,
        isThumbnailsOpen: !state.isThumbnailsOpen,
      };

    case SET_KEYBOARD_SHOWN:
      return {
        ...state,
        isKeyboardShown: action.isKeyboardShown,
      };

    case SET_KEYBOARD_SIZE:
      return {
        ...state,
        keyboardSize: action.keyboardSize,
      };

    case UPDATE_WORKSPACE: {
      const { workspace, body } = getNewWorkspaceAndBody(state, action);

      return {
        ...state,
        workspace,
        body,
      };
    }

    case UPDATE_WORKSPACE_PADDING:
      return updFramePadding(state, action);

    case SET_ACTIVE_POPUP_MENU:
      return {
        ...state,
        activeMenu: action.activeMenu,
      };

    // we also reset activeMenu to main
    case TOGGLE_POPUP: {
      const newPopupVisibility =
        state.popupVisibility !== popupStatuses.hidden
          ? popupStatuses.hidden
          : popupStatuses.readyToShow;

      return {
        ...state,
        popupVisibility: newPopupVisibility,
        activeMenu: popupMenu.main,
        ...(newPopupVisibility === popupStatuses.hidden
          ? { popupSize: false }
          : {}),
      };
    }

    case RESET_POPUP:
      return {
        ...state,
        popupVisibility: popupStatuses.hidden,
        popupSize: false,
        activeMenu: popupMenu.main,
      };

    case SET_POPUP_SHOWN:
      return {
        ...state,
        popupVisibility: popupStatuses.shown,
        popupSize: getPopupSize(),
      };

    case SET_WIZZARD_FOOTER_HEIGHT:
      return {
        ...state,
        wizardFooterHeight: action.value,
      };

    case SET_WIZZARD_ASSISTANT_SIZE:
      return {
        ...state,
        wizardAssistantSize: action.wizardAssistantSize,
      };

    case SHOW_MESSAGE_BUTTON:
      return {
        ...state,
        isMessageButtonVisible: true,
      };

    case HIDE_MESSAGE_BUTTON:
      return {
        ...state,
        isMessageButtonVisible: false,
      };

    case SET_MESSAGE_BUTTON_CONTENT:
      return {
        ...state,
        messageButtonContent: action.content,
      };

    case DECLINE_BUTTON_SHOW:
      return {
        ...state,
        isDeclineButtonVisible: true,
      };

    case DECLINE_BUTTON_HIDE:
      return {
        ...state,
        isDeclineButtonVisible: false,
      };

    case DRAFT_BUTTON_SHOW:
      return {
        ...state,
        isDraftButtonVisible: true,
      };

    case DRAFT_BUTTON_HIDE:
      return {
        ...state,
        isDraftButtonVisible: false,
      };

    case ADD_TOOLBAR_OVERLAY:
      return {
        ...state,
        toolbarOverlays: [
          ...state.toolbarOverlays, action.overlay,
        ],
      };

    case REMOVE_TOOLBAR_OVERLAY:
      return {
        ...state,
        toolbarOverlays:
          state.toolbarOverlays.filter((el) => {
            return el !== action.overlay;
          }),
      };

    case PAGE_ATTRIBUTES_CONSTRUCTOR_SHOW:
      return {
        ...state,
        isPAConstructorShown: true,
      };

    case SET_PA_ACTIVE_SECTION:
      return {
        ...state,
        activePAConstructorSection: action.activeSection,
      };

    case PAGE_ATTRIBUTES_CONSTRUCTOR_HIDE:
      return {
        ...state,
        isPAConstructorShown: false,
      };

    case CONSTRUCTOR_SHOW:
      return {
        ...state,
        isFConstructorShown: true,
      };

    case CONSTRUCTOR_HIDE:
      return {
        ...state,
        isFConstructorShown: false,
      };

    case OPEN_HREF_MODAL:
      return {
        ...state,
        isHrefModalShown: true,
      };

    case CLOSE_HREF_MODAL:
      return {
        ...state,
        isHrefModalShown: false,
      };

    case SHOW_COMMENTS:
      return {
        ...state,
        isCommentsShown: true,
      };

    case HIDE_COMMENTS:
      return {
        ...state,
        isCommentsShown: false,
      };

    case TOGGLE_MOBILE_TOOLS_VISIBILITY:
      return {
        ...state,
        mobileToolsAreVisible: !state.mobileToolsAreVisible,
      };

    case BUSY_LOCKER_SHOW:
      return {
        ...state,
        isBusyLockerShown: true,
      };

    case BUSY_LOCKER_HIDE:
      return {
        ...state,
        isBusyLockerShown: false,
      };

    case CONDITIONAL_FIELDS_SHOW:
      return {
        ...state,
        isConditionalFieldsShown: true,
      };

    case CONDITIONAL_FIELDS_HIDE:
      return {
        ...state,
        isConditionalFieldsShown: false,
      };

    case UPDATE_DRAGGABLE_PANEL_POSITION:
      return updDraggablePanelPosition(state, action);

    case TOGGLE_SIGNATURE_MANAGER:
      return {
        ...state,
        isSignatureManagerShown: !state.isSignatureManagerShown,
      };

    case TOGGLE_IMAGE_MANAGER:
      return {
        ...state,
        isImageManagerShown: !state.isImageManagerShown,
      };

    case ACTIVATE_FAKE_EDIT:
      return {
        ...state,
        isFakeEditActive: true,
      };

    case DEACTIVATE_FAKE_EDIT:
      return {
        ...state,
        isFakeEditActive: false,
      };

    case FAKE_EDIT_INITIAL_HINT_SHOW:
      return {
        ...state,
        isFakeEditInitialHintShown: true,
      };

    case FAKE_EDIT_INITIAL_HINT_HIDE:
      return {
        ...state,
        isFakeEditInitialHintShown: false,
      };

    case FAKE_EDIT_ROLLOVER_HINT_SHOW:
      return {
        ...state,
        isFakeEditRolloverHintShown: true,
      };

    case FAKE_EDIT_ROLLOVER_HINT_HIDE:
      return {
        ...state,
        isFakeEditRolloverHintShown: false,
      };

    case SHOW_POINTER:
      return {
        ...state,
        isPointerShown: true,
      };

    case HIDE_POINTER:
      return {
        ...state,
        isPointerShown: false,
      };

    case SHOW_CHOICE_IFRAME:
      return {
        ...state,
        isChoiceIframeShown: true,
      };

    case HIDE_CHOICE_IFRAME:
      return {
        ...state,
        isChoiceIframeShown: false,
      };

    case VERSIONS_PANEL_SHOW:
      return {
        ...state,
        isVersionsShown: true,
      };

    case VERSIONS_PANEL_HIDE:
      return {
        ...state,
        isVersionsShown: false,
      };

    case SIGNING_SESSION_SHOW:
      return {
        ...state,
        isSigningSessionShown: true,
      };

    case SIGNING_SESSION_HIDE:
      return {
        ...state,
        isSigningSessionShown: false,
      };

    case SET_SIGNING_SESSION_TEXTSET:
      return {
        ...state,
        signingSessionTextSet: action.textSet,
      };

    default:
      return state;
  }
}

export function showFindAnotherFormButton() {
  return { type: SHOW_FIND_ANOTHER_FORM_BUTTON };
}

export function hideFindAnotherFormButton() {
  return { type: HIDE_FIND_ANOTHER_FORM_BUTTON };
}

export function showFindAnotherFormBubble() {
  return { type: SHOW_FIND_ANOTHER_FORM_BUBBLE };
}

export function hideFindAnotherFormBubble() {
  return { type: HIDE_FIND_ANOTHER_FORM_BUBBLE };
}

export function setKeyboardShown(isKeyboardShown) {
  return { type: SET_KEYBOARD_SHOWN, isKeyboardShown };
}

export function setKeyboardSize(keyboardSize) {
  return { type: SET_KEYBOARD_SIZE, keyboardSize };
}

export function updateWorkspace(workspace, body) {
  return { type: UPDATE_WORKSPACE, workspace, body };
}

export function showPointer() {
  return { type: SHOW_POINTER };
}

export function hidePointer() {
  return { type: HIDE_POINTER };
}


function updateWorkspacePaddingAction(framePadding) {
  return { type: UPDATE_WORKSPACE_PADDING, framePadding };
}
export function recomputeWorkspacePadding() {
  return (dispatch, getState) => {
    const state = getState();
    if (thisDevice.isMobile) {
      dispatch(
        updateWorkspacePaddingAction(
          isSignNow()
            ? pageFrameDefaultPaddingMobileSnFiller
            : pageFrameDefaultPaddingMobile,
        ),
      );
    } else if (selectors.mode.isComments(state) && selectors.mode.isPointer(state)) {
      dispatch(updateWorkspacePaddingAction(pageFrameCommentsPaddingWithPointer));
    } else if (selectors.mode.isComments(state)) {
      dispatch(updateWorkspacePaddingAction(pageFrameCommentsPadding));
    } else if (selectors.mode.isPointer(state)) {
      dispatch(updateWorkspacePaddingAction(pageFramePointerPadding));
    } else {
      dispatch(updateWorkspacePaddingAction(pageFrameDefaultPadding));
    }
  };
}

/**
 * Здесь мы хардкодим значения ширин
 * левой и правой панели, для того, чтобы сразу после их
 * открытия или закрытия изменить workspaceSize
 * на уменьшенный/увеличенный
 */
const getThumbnailsWidth = (body) => {
  if (body.width <= 480) {
    return 100;
  }
  if (body.width <= 768) {
    return 142;
  }
  return 175;
};

const getTodoListWidth = (getState) => {
  const state = getState();
  const { extrasbar } = selectors.base.getFeatures(state);
  const isTodoListOpened = selectors.base.getTodoListState(state) === todoListStates.maximized;
  const extrasbarWidth = find(extrasbar, { visible: true })
    ? 28
    : 0;

  return (
    isTodoListOpened
      ? 265 + extrasbarWidth
      : 0 + extrasbarWidth
  );
};

const getConstructorWidth = () => {
  return 300;
};

export function toggleThumbnailsVisibility() {
  return (dispatch, getState) => {
    const { isThumbnailsOpen, workspace, body } = getState().viewport;
    dispatch({ type: TOGGLE_THUMBNAILS_VISIBILITY });

    const thumbnailsWidth = getThumbnailsWidth(body);
    const newWorkspace = isThumbnailsOpen
      // close
      ? {
        ...workspace,
        width: workspace.width + thumbnailsWidth,
        left: workspace.left - thumbnailsWidth,
      }
      // open
      : {
        ...workspace,
        width: workspace.width - thumbnailsWidth,
        left: workspace.left + thumbnailsWidth,
      };

    dispatch(updateWorkspace(newWorkspace, body));
  };
}

export function setActivePopupMenu(activeMenu = popupMenu.main) {
  return { type: SET_ACTIVE_POPUP_MENU, activeMenu };
}

export function togglePopup() {
  return { type: TOGGLE_POPUP };
}

export function resetPopup() {
  return { type: RESET_POPUP };
}

export function setPopupShown() {
  return { type: SET_POPUP_SHOWN };
}

export function setWizardFooterHeight(value) {
  return { type: SET_WIZZARD_FOOTER_HEIGHT, value };
}

export function setWizardAssistantSize(wizardAssistantSize) {
  return { type: SET_WIZZARD_ASSISTANT_SIZE, wizardAssistantSize };
}

export function showMessageButton() {
  return { type: SHOW_MESSAGE_BUTTON };
}

export function hideMessageButton() {
  return { type: HIDE_MESSAGE_BUTTON };
}

export function setMessageButtonContent(content) {
  return {
    type: SET_MESSAGE_BUTTON_CONTENT,
    content,
  };
}

export function declineButtonShow() {
  return { type: DECLINE_BUTTON_SHOW };
}

export function declineButtonHide() {
  return { type: DECLINE_BUTTON_HIDE };
}

export function draftButtonShow() {
  return { type: DRAFT_BUTTON_SHOW };
}

export function draftButtonHide() {
  return { type: DRAFT_BUTTON_HIDE };
}

export function addToolbarOverlay(overlay) {
  return { type: ADD_TOOLBAR_OVERLAY, overlay };
}

export function removeToolbarOverlay(overlay) {
  return { type: REMOVE_TOOLBAR_OVERLAY, overlay };
}

export function paConstructorShow() {
  return { type: PAGE_ATTRIBUTES_CONSTRUCTOR_SHOW };
}

export function paConstructorHide() {
  return { type: PAGE_ATTRIBUTES_CONSTRUCTOR_HIDE };
}

export function recalcWorkspace({ getState, dispatch, toConstructor = true }) {
  const { workspace, body } = getState().viewport;
  const constructorWidth = getConstructorWidth(getState);
  const todoListWidth = getTodoListWidth(getState);

  const newWorkspace = toConstructor === true
    ? {
      ...workspace,
      width: workspace.width - constructorWidth + todoListWidth,
      right: workspace.right + constructorWidth - todoListWidth,
    }
    : {
      ...workspace,
      width: workspace.width + constructorWidth - todoListWidth,
      right: workspace.right - constructorWidth + todoListWidth,
    };

  dispatch(updateWorkspace(newWorkspace, body));
}

export function fConstructorShow(initial) {
  const initialParameter = initial
    ? { initial: true }
    : {};

  return isSignNow()
    ? { type: CONSTRUCTOR_SHOW }
    : async function fConstructorShowJSF(dispatch, getState) {
      /**
       * Здесь, мы должны дождаться загрузки модуля перед открытием
       * конструктора. Без этого кода, раньше, UI реагировал не совсем
       * хорошо.
       *
       * Сейчас -
       * 1) Ждем загрузки
       * 2) Открываем конструктор
       * 3) Диспатчим изменение workspaceSize
       */
      await getModulePromise(modulesLiterals.fConstructor);
      dispatch({
        type: CONSTRUCTOR_SHOW,
        ...initialParameter,
      });
      recalcWorkspace({ getState, dispatch, toConstructor: true });
    };
}

export function fConstructorHide() {
  return isSignNow()
    ? { type: CONSTRUCTOR_HIDE }
    : (dispatch, getState) => {
      dispatch({ type: CONSTRUCTOR_HIDE });
      recalcWorkspace({ getState, dispatch, toConstructor: false });
    };
}

export function hideFConstructorPreview() {
  return isSignNow()
    ? { type: AT.HIDE_CONSTRUCTOR_PREVIEW }
    : (dispatch, getState) => {
      dispatch({ type: AT.HIDE_CONSTRUCTOR_PREVIEW });
      recalcWorkspace({ getState, dispatch, toConstructor: true });
    };
}

export function showFConstructorPreviewDispatcher() {
  return (dispatch, getState) => {
    recalcWorkspace({ getState, dispatch, toConstructor: false });
    dispatch({ type: SHOW_CONSTRUCTOR_PREVIEW_DISPATCHER });
  };
}

export function openHrefModal() {
  return { type: OPEN_HREF_MODAL };
}

export function closeHrefModal() {
  return { type: CLOSE_HREF_MODAL };
}

export function showComments() {
  return { type: SHOW_COMMENTS };
}

export function hideComments() {
  return { type: HIDE_COMMENTS };
}

export function setPaActiveSection(activeSection, opts = {}) {
  return { type: SET_PA_ACTIVE_SECTION, activeSection, opts };
}

export function toggleMobileToolsVisibility() {
  return { type: TOGGLE_MOBILE_TOOLS_VISIBILITY };
}

export function showBusyLocker() {
  return { type: BUSY_LOCKER_SHOW };
}

export function hideBusyLocker() {
  return { type: BUSY_LOCKER_HIDE };
}

export function showConditionalFields() {
  return { type: CONDITIONAL_FIELDS_SHOW };
}

export function hideConditionalFields() {
  return { type: CONDITIONAL_FIELDS_HIDE };
}

export function toggleConditionalFields() {
  return (dispatch, getState) => {
    const state = getState();
    const isConditionalFieldsShown = selectors.base.getIsConditionalFieldsShown(state);

    if (isConditionalFieldsShown) {
      dispatch(hideConditionalFields());
    } else {
      dispatch(showConditionalFields());
    }
  };
}

export function updateDraggablePanelPosition(entity, draggablePanelPosition) {
  return { type: UPDATE_DRAGGABLE_PANEL_POSITION, entity, draggablePanelPosition };
}

export function toggleSignatureManager() {
  return { type: TOGGLE_SIGNATURE_MANAGER };
}

export function toggleImageManager() {
  return { type: TOGGLE_IMAGE_MANAGER };
}

export function activateFakeEdit(byScenario = false) {
  return { type: ACTIVATE_FAKE_EDIT, byScenario };
}

export function deactivateFakeEdit() {
  return { type: DEACTIVATE_FAKE_EDIT };
}

export function showFakeEditInitialHint() {
  return { type: FAKE_EDIT_INITIAL_HINT_SHOW };
}

export function hideFakeEditInitialHint() {
  return { type: FAKE_EDIT_INITIAL_HINT_HIDE };
}

export function showFakeEditRolloverHint() {
  return { type: FAKE_EDIT_ROLLOVER_HINT_SHOW };
}

export function hideFakeEditRolloverHint() {
  return { type: FAKE_EDIT_ROLLOVER_HINT_HIDE };
}

export function showChoiceIframe() {
  return { type: SHOW_CHOICE_IFRAME };
}

export function hideChoiceIframe() {
  return { type: HIDE_CHOICE_IFRAME };
}

export function versionsPanelShow() {
  return { type: VERSIONS_PANEL_SHOW };
}

export function versionsPanelHide() {
  return { type: VERSIONS_PANEL_HIDE };
}

export function signingSessionShow() {
  return { type: SIGNING_SESSION_SHOW };
}

export function signingSessionHide() {
  return { type: SIGNING_SESSION_HIDE };
}

export function setSigningSessionTextSet(textSet) {
  return { type: SET_SIGNING_SESSION_TEXTSET, textSet };
}

export function cancelShowingBeforeUnloadPromptConfirmation() {
  return { type: CANCEL_SHOWING_BEFORE_UNLOAD_PROMPT_CONFIRMATION };
}
