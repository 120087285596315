import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';

import {
  createId,
  getNextLocalId,
  flatIdToId,
  sendToolElementList,
  applyToDefaultsAndUpdateGhost,
  placeElementAtTheTop,
  isFillableElement,
  reCreateGhostElement,
  updateElement,
  updateElements,
  addElement,
  deleteElement,
  scalingImageSignatureByDefaults,
  sendToolElement,
  getElementsToUpdateAccordingToGroup,
  createFlatId,
  send,
  alignIfRequiresContentPos,
  getSendableData,
  isElementErase,
  isElementOverlyingText,
  getIsGalleryOrSignatureOrCheckmarkOrRadio,
  getIsGalleryOrSignature,
  isEmptyFillableElement,
} from '../Operations/Utils';
import {
  filterContentForGhost,
  filterContentForFillableGhost,
  doFillFillableElementWithGroup,
  removeRadioGroupFilledElements,
} from './elementsUtils';
import {
  getNeedUpdateGhost,
} from '../../helpers';
import {
  GROUPS,
  TYPES,
} from '../../../constants';
import isSignNow from '../../../isSignNow';

export function updateGhostAfterUpdateElement(state, action) {
  const { ghostElement, activeElement: activeElId, elementsMap } = state;

  if (!activeElId || !ghostElement) {
    return null;
  }

  const activeEl = elementsMap[activeElId];
  if (!activeEl) {
    return null;
  }

  const contentForGhost = filterContentForGhost(activeEl, action.content);

  if (
    activeEl.type === ghostElement.type &&
    activeEl.subType === ghostElement.subType &&
    !isEmpty(contentForGhost) &&
    getNeedUpdateGhost(activeEl)
  ) {
    return {
      ...ghostElement,
      content: {
        ...ghostElement.content,
        ...contentForGhost,
      },
    };
  }

  return null;
}

export function updateGhostAfterUpdateFillableElement(state, action) {
  const { ghostElement, activeElement: activeElId, elementsMap } = state;

  if (!activeElId || !ghostElement) {
    return null;
  }

  const activeEl = elementsMap[activeElId];
  if (!activeEl) {
    return null;
  }

  const contentForGhost = filterContentForFillableGhost(activeEl, action.template);
  if (
    ghostElement.template &&
    activeEl.type === ghostElement.type &&
    activeEl.subType === ghostElement.subType &&
    !isEmpty(contentForGhost)
  ) {
    return {
      ...ghostElement,
      template: {
        ...ghostElement.template,
        ...contentForGhost,
      },
    };
  }

  return null;
}

export function doActivateTool(transport, state, action) {
  const { defaults } = state;
  const { item, toolType, toolSubType } = action;

  const {
    ghostElement,
    sendOperationsCount,
  } = reCreateGhostElement(
    defaults.content,
    toolType,
    toolSubType,
    state.sendOperationsCount,
    item,
  );

  return {
    ghostElement: scalingImageSignatureByDefaults(ghostElement),
    activeTool: {
      type: toolType,
      subType: toolSubType,
    },
    sendOperationsCount,
  };
}

export function doAddElement(transport, state, action) {
  const id = flatIdToId(action.element.id);
  const updatedElement = alignIfRequiresContentPos({
    ...state.ghostElement,
    ...action.element,
    element: id,
  });

  sendToolElement(transport, id, updatedElement);

  const { elements, elementsMap } = addElement(updatedElement, state);
  const { dontUpdateDefaults } = action.opts;
  return {
    ...(
      applyToDefaultsAndUpdateGhost(transport, updatedElement, state, dontUpdateDefaults)
    ),
    elements,
    elementsMap,
  };
}

export function doAddElements(transport, state, action) {
  let sendOperationsCount = { ...state.sendOperationsCount };
  const newElements = action.elements.map((element) => {
    sendOperationsCount = getNextLocalId(sendOperationsCount);
    const elementId = element.id
      ? flatIdToId(element.id)
      : createId(sendOperationsCount);
    const newId = element.id || createFlatId(elementId);
    return {
      ...element,
      element: elementId,
      id: newId,
      enabled: true,
      template: undefined,
    };
  });

  const { elements, elementsMap } = newElements.reduce((currentState, element) => {
    sendToolElement(transport, element.element, element);
    return {
      ...currentState,
      ...addElement(element, currentState),
    };
  }, state);

  return {
    sendOperationsCount,
    elements,
    elementsMap,
    ...reCreateGhostElement(
      state.defaults.content,
      state.ghostElement.type,
      state.ghostElement.subType,
      sendOperationsCount,
    ),
  };
}

export function doUpdateElement(transport, state, action) {
  if (state.ghostElement && action.id === state.ghostElement.id) {
    return {
      ghostElement: {
        ...state.ghostElement,
        content: {
          ...state.ghostElement.content,
          ...action.content,
        },
      },
    };
  }

  const elementToUpdate = state.elementsMap[action.id];

  if (elementToUpdate === undefined) {
    return {};
  }

  const updatedElement = {
    ...elementToUpdate,
    content: {
      ...elementToUpdate.content,
      ...action.content,
    },
    ...(
      isEmptyFillableElement(elementToUpdate)
        ? { isNeedToBeFilledWithTemplate: true }
        : {}
    ),
  };

  const newStateWithUpdatedElement = updateElement(updatedElement, state);

  if (isFillableElement(elementToUpdate)) {
    const stateWithFilledElements =
      doFillFillableElementWithGroup(
        transport,
        elementToUpdate,
        {
          ...state,
          ...newStateWithUpdatedElement,
        },
      );
    const elementsToUpdate =
      getElementsToUpdateAccordingToGroup(updatedElement, stateWithFilledElements);
    const newStateWithUpdatedElements = updateElements(
      elementsToUpdate,
      stateWithFilledElements,
    );

    // https://pdffiller.atlassian.net/browse/JSF-6502
    // Во время рендера stretch/combo филдов, отправлялся ненужный апдейт в сокет
    // Опция для обновления данных только в сторе, без отправки в сокет
    const isLocalUpdate = get(action, 'opts.isLocalUpdate', false);
    const sendOperationsCount = isLocalUpdate
      ? state.sendOperationsCount
      : sendToolElementList(
        transport,
        state.sendOperationsCount,
        [...elementsToUpdate, updatedElement].map((updatedEl) => {
          const sourceElement = state.elementsMap[updatedEl.id];
          return getSendableData(sourceElement, updatedEl);
        }),
      );


    return {
      ...newStateWithUpdatedElements,
      sendOperationsCount,
      formulaCache: state.formulaCache,
      fillableGroups: state.fillableGroups,
    };
  }

  const sendOperationsCount = getNextLocalId(state.sendOperationsCount);
  const elementDiff = getSendableData(elementToUpdate, updatedElement);
  sendToolElement(transport, createId(sendOperationsCount), elementDiff);
  const newGhostElement = updateGhostAfterUpdateElement(state, action);

  return {
    ...newStateWithUpdatedElement,
    ...newGhostElement && {
      ghostElement: newGhostElement,
    },
    sendOperationsCount,
  };
}

export function doUpdateElements(transport, state, action) {
  const { elements } = action;
  return elements.reduce((curState, element) => {
    return ({
      ...curState,
      ...doUpdateElement(transport, curState, element),
    });
  }, state);
}

export function doExitFConstructor(transport, state) {
  const { activeElement, elementsMap } = state;
  if (!activeElement) {
    return {};
  }

  const elementToFill = elementsMap[activeElement];
  if (!elementToFill) {
    return {};
  }

  return doFillFillableElementWithGroup(transport, elementToFill, state);
}

export function doSetActiveElement(transport, state, action) {
  const { id, activeStatus } = action;
  // id === 0 comes from wizard and tools to reset focus
  if (activeStatus === false && id === 0) {
    return {
      activeElement: false,
    };
  }

  // NOTE: FIX 05.07.2017 by github.com/juicyigor
  // Обнаружена возможность такой ситуации:
  // state = { activeElement: '1-1' }
  // action = { type: AT.SET_ACTIVE_ELEMENT, id: '1-2', activeStatus: false }
  //
  // В такой ситуации, без фикса nextState станет { activeElement: false }.
  //
  // Безусловно это нужно фиксить и на стороне jsfiller3
  if (activeStatus === false && state.activeElement !== id) {
    // eslint-disable-next-line no-console
    console.error('An attempt to de-activate element that wasn\'t active');
    return {};
  }

  const elementToActivate = state.elementsMap[id];

  if (elementToActivate === undefined) {
    // case when has been deleted an element and, after that,
    // we set active the element false
    if (!activeStatus) {
      return { activeElement: false };
    }

    // eslint-disable-next-line no-console
    console.error('An attempt to activate element that doesn\'t exist');
    return {};
  }

  // этот кусок кода должен быть перед логикой обработки activeElement
  if (activeStatus === false) {
    if (state.isFCActive) {
      return {
        activeElement: false,
      };
    }

    // Примерная замена UPDATE_ELEMENT при фокус-ауте
    const newStateWithUpdatedGhost = getNeedUpdateGhost(elementToActivate)
      ? applyToDefaultsAndUpdateGhost(
        transport,
        elementToActivate,
        state,
        false,
        isFillableElement(elementToActivate),
      )
      : null;

    return {
      ...newStateWithUpdatedGhost,
      activeElement: false,
    };
  }

  const activeElement = activeStatus && id;

  if (isFillableElement(elementToActivate)) {
    const isElementForFill = isSignNow()
      ? !getIsGalleryOrSignatureOrCheckmarkOrRadio(elementToActivate)
      : !getIsGalleryOrSignature(elementToActivate);

    if (activeStatus && isElementForFill) {
      // TODO: разобраться нужен ли в state.ws isFCActive, ибо сейчас выглядит так,
      // что можно вынести в опции setActiveElement
      if (state.isFCActive) {
        return {
          activeElement,
          lastActiveFillableElementId: activeElement,
        };
      }

      return {
        ...doFillFillableElementWithGroup(transport, elementToActivate, state),
        activeElement,
        lastActiveFillableElementId: activeElement,
      };
    }

    return {
      activeElement,
      lastActiveFillableElementId: activeElement,
    };
  }

  if (
    activeStatus &&
    !isElementErase(elementToActivate) &&
    !isElementOverlyingText(elementToActivate)
  ) {
    const {
      elements,
    } = placeElementAtTheTop(elementToActivate, state);

    return {
      elements,
      activeElement,
    };
  }

  return {
    activeElement,
  };
}

const getClearedElements = (elementsToClear, opts) => {
  const clearedElementsForStore = [];
  const clearedElementsForSendingToWs = [];

  elementsToClear.forEach((elem) => {
    clearedElementsForStore.push({
      ...elem,
      content: undefined,
    });

    clearedElementsForSendingToWs.push({
      ...elem,
      content:
        get(opts, 'needEraseContent', false)
          ? undefined
          : {
            ...elem.content,
            visible: false,
          },
    });
  });

  return { clearedElementsForStore, clearedElementsForSendingToWs };
};

export function doClearElement(transport, state, action) {
  const elementToUpdate = state.elementsMap[action.id];
  const { opts } = action;

  if (elementToUpdate === undefined) {
    // eslint-disable-next-line no-console
    console.error('An attempt to clear an element that doesn\'t exist');
    return {};
  }

  const newStateWithClearedGroup = removeRadioGroupFilledElements(
    transport,
    state,
    elementToUpdate,
  );

  const elemGroupName = get(elementToUpdate, 'template.name', false);
  const elementsToClear = elemGroupName
    ? state.elements.filter((element) => {
      return get(element, 'template.name') === elemGroupName;
    })
    : [elementToUpdate];

  const {
    clearedElementsForStore,
    clearedElementsForSendingToWs,
  } = getClearedElements(elementsToClear, opts);

  const newStateWithClearedElements = updateElements(
    clearedElementsForStore,
    newStateWithClearedGroup,
  );

  const sendOperationsCount = sendToolElementList(
    transport,
    state.sendOperationsCount,
    clearedElementsForSendingToWs.map((updatedEl) => {
      const sourceElement = state.elementsMap[updatedEl.id];
      return getSendableData(sourceElement, updatedEl);
    }),
  );

  return {
    ...newStateWithClearedElements,
    sendOperationsCount,
    formulaCache: state.formulaCache,
    fillableGroups: state.fillableGroups,
  };
}

export function doRemoveElement(transport, state, action) {
  const { elementsMap } = state;
  const { id } = action;
  const elementToRemove = elementsMap[id];

  if (elementToRemove === undefined) {
    // eslint-disable-next-line no-console
    console.error('An attempt to remove element that doesn\'t exist');
    return {};
  }

  // clear fillable
  if (isFillableElement(elementToRemove)) {
    return doClearElement(transport, state, action);
  }

  const removeElement = {
    ...elementToRemove,
    content: {
      ...elementToRemove.content,
      visible: false,
    },
  };

  const sendOperationsCount = getNextLocalId(state.sendOperationsCount);
  sendToolElement(transport, createId(sendOperationsCount), removeElement);

  const { elements, elementsMap: uElementsMap } = deleteElement(removeElement, state);
  return {
    sendOperationsCount,
    elementsMap: uElementsMap,
    elements,
  };
}

export function doRemoveElements(transport, state, action) {
  const { ids, opts } = action;
  return ids.reduce((curState, id) => {
    return ({
      ...curState,
      ...doRemoveElement(transport, curState, { id, opts }),
    });
  }, state);
}

export function doUpdateDefaultSettings(transport, state, action) {
  const { defaults } = state;
  const { key, value } = action;
  const sendOperationsCount = getNextLocalId(state.sendOperationsCount);
  const id = createFlatId(createId(sendOperationsCount));

  send(transport, id, {
    [key]: value,
    group: GROUPS.editor,
    type: TYPES.defaults,
  });

  return {
    defaults: {
      ...defaults,
      [key]: value,
    },
    sendOperationsCount,
  };
}
