import React from 'react';
import PropTypes from 'prop-types';

const SettingsBoxItem = ({ children, storeRef }) => {
  return (
    <div ref={storeRef} className="settings-box__item">
      {children}
    </div>
  );
};

SettingsBoxItem.propTypes = {
  children: PropTypes.element.isRequired,
  storeRef: PropTypes.func,
};

SettingsBoxItem.defaultProps = {
  storeRef: null,
};

export default SettingsBoxItem;
