import PropTypes from 'prop-types';
import React, { Component } from 'react';
import cls from 'classnames';

export const scrollBoxSizes = {
  small: 'small',
};

export default class ScrollBox extends Component {
  static propTypes = {
    children: PropTypes.node,
    style: PropTypes.shape({}),
    storeRef: PropTypes.func,
    size: PropTypes.oneOf(
      Object.values(scrollBoxSizes),
    ),
  };

  static defaultProps = {
    children: null,
    style: null,
    storeRef: null,
    size: null,
  };

  render() {
    const {
      style,
      size,
    } = this.props;

    const className = cls('scroll-box', {
      [`scroll-box--size--${size}`]: size,
    });

    return (
      <div className={className}>
        <div
          className="scroll-box__body"
          style={style}
          ref={this.props.storeRef}
        >
          {this.props.children}
        </div>
      </div>
    );
  }
}
