export const INITIALIZE_ARRAYS = 'navigation/INITIALIZE_ARRAYS';
export const CHANGE_PAGE = 'navigation/CHANGE_PAGE';
export const SET_ACTIVE_PAGE = 'navigation/SET_ACTIVE_PAGE';
export const SET_GHOST_PAGE_ID = 'navigation/SET_GHOST_PAGE_ID';

export const SET_FRAME_SCROLL = 'navigation/SET_FRAME_SCROLL';
export const SET_CONTINIOUS_FRAME_SCROLL = 'navigation/SET_CONTINIOUS_FRAME_SCROLL';

export const SET_SCALE = 'navigation/SET_SCALE';
export const CHANGE_SCALE = 'navigation/CHANGE_SCALE';
export const SET_ALL_SCALES = 'navigation/SET_ALL_SCALES';
export const SET_ALL_SCALES_CONTINIOUS = 'navigation/SET_ALL_SCALES_CONTINIOUS';

export const SET_CAN_GO_NEXT_PAGE = 'navigation/SET_CAN_GO_NEXT_PAGE';
export const SET_CAN_GO_PREV_PAGE = 'navigation/SET_CAN_GO_PREV_PAGE';

export const SET_DRAG_SCROLL_DISTINCTION = 'navigation/SET_DRAG_SCROLL_DISTINCTION';
// На это действие переходим во flash версию редактора
export const GO_TO_FLASH_SITE = 'navigation/GO_TO_FLASH_SITE';

export const SCALE_FIT_PAGE = 'FIT_PAGE';
export const SCALE_FIT_WIDTH = 'FIT_WIDTH';
export const SCALE_890 = 'SCALE_890';

export const SAVE_ACTIVE_PAGE = 'navigation/SAVE_ACTIVE_PAGE';
export const SET_ORIGINAL_SIZE = 'navigation/SET_ORIGINAL_SIZE';
export const SCROLL_TO_TOP_SN = 'navigation/SCROLL_TO_TOP_SN';

export const SAVE_CARET_POSITION = 'navigation/SAVE_CARET_POSITION';
export const CLEAR_CARET_POSITION = 'navigation/CLEAR_CARET_POSITION';

export const SET_IS_CONTINIOUS_SCROLL_ANIMATED = 'navigation/SET_IS_CONTINIOUS_SCROLL_ANIMATED';
