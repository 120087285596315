import createFormulaVariableInText from '../../helpers/createFormulaVariableInText';

export const getFormulaCache = (state) => {
  return state.formula;
};

export const getFormulasDependentOnElement = (element) => {
  return (state) => {
    const { expandedFormulas } = getFormulaCache(state);
    const formulaVariableInText = createFormulaVariableInText(element.template.name);

    return Object.values(expandedFormulas).filter((formulaObject) => {
      return formulaObject.initialFormula.includes(formulaVariableInText);
    });
  };
};

export const getNumberToFormulasDependencies = (state) => {
  return state.formula.numberToFormulasDependencies;
};

export const getExpandedFormulas = (state) => {
  return state.formula.expandedFormulas;
};
