
import PropTypes from 'prop-types';
import React from 'react';

const TodoItemIcon = ({ children }) => {
  return (
    <p className="todo-item__icon">
      {children}
    </p>
  );
};

TodoItemIcon.propTypes = {
  children: PropTypes.node,
};

TodoItemIcon.defaultProps = {
  children: null,
};

export default TodoItemIcon;
