import React, { Component } from 'react';
import PropTypes from 'prop-types';
import refsStorageDecorator from './refsStorageDecorator';
import FocusControllerContext from './FocusControllerContext';
import initializeCaret2End from './caret2end/caret2end';
import storeRefProxyWatcher from './storeRefProxyWatcher';
import initializeKeyboardController from './iosKeyboard/keyboardController';
import initializeHackPosition from './hackPosition';

class FocusController extends Component {
  static propTypes = {
    children: PropTypes.node.isRequired,

    storeRef: PropTypes.func.isRequired,
    getRef: PropTypes.func.isRequired,

    activeElementId: PropTypes.string,
    thisDevice: PropTypes.shape({
      isInternetExplorer11: PropTypes.bool,
      isMobile: PropTypes.bool,
      isIOS: PropTypes.bool,
    }),

    setActiveElementId: PropTypes.func.isRequired,
    setKeyboardShown: PropTypes.func.isRequired,
    setKeyboardSize: PropTypes.func.isRequired,
  };

  static defaultProps = {
    activeElementId: null,
    thisDevice: {},
  };

  constructor(props) {
    super(props);

    const {
      storeRef,
      thisDevice,
      setKeyboardShown,
      setKeyboardSize,
    } = props;

    this.caret2End = initializeCaret2End({
      thisDevice,
    });
    this.ctx = {
      storeRef: storeRefProxyWatcher({
        storeRef,
        focusToRef: this.focusToRef,
        getActiveElementId: this.getActiveElementId,
        caret2End: this.caret2End,
      }),
      restoreFocus: this.restoreFocus,
      onBlur: this.onBlur,
      onFocus: this.onFocus,
      onMouseDown: this.onMouseDown,
      disableBlur: this.disableBlur,
      enableBlur: this.enableBlur,
      disableFocus: this.disableFocus,
      enableFocus: this.enableFocus,
      disableBlurGlobally: this.disableBlurGlobally,
      enableBlurGlobally: this.enableBlurGlobally,
    };
    this.onBlurTimeout = null;
    this.blurEnabled = true;
    this.blurEnabledGlobally = true;
    this.focusEnabled = true;
    this.keyboardController = initializeKeyboardController({
      thisDevice,
      setKeyboardShown,
      setKeyboardSize,
    });
    this.cancelFocus = () => {};
    this.hackPosition = initializeHackPosition({ thisDevice });
  }

  componentDidUpdate(prevProps) {
    const { activeElementId } = this.props;
    const { activeElementId: activeElementIdPrev } = prevProps;

    if (activeElementId !== activeElementIdPrev) {
      if (activeElementId) {
        this.focus(activeElementId);
      } else {
        this.cancelFocus();
        if (activeElementIdPrev) this.blur(activeElementIdPrev);
      }
    }
  }

  getActiveElementId = () => {
    const { activeElementId } = this.props;
    return activeElementId;
  };

  disableFocus = () => {
    this.focusEnabled = false;
  };

  enableFocus = () => {
    this.focusEnabled = true;
  };

  disableBlur = () => {
    clearTimeout(this.onBlurTimeout);
    this.blurEnabled = false;
  };

  enableBlur = () => {
    this.blurEnabled = true;
  };

  disableBlurGlobally = () => {
    clearTimeout(this.onBlurTimeout);
    this.blurEnabledGlobally = false;
  };

  enableBlurGlobally = () => {
    this.blurEnabledGlobally = true;
  };

  focusToRef = isCancelled => (ref) => {
    if (isCancelled()) return;
    const { activeElementId, thisDevice } = this.props;

    /**
     * `caret2End.getCallback` should be called before ref.focus()
     * because focus-events are strange and interrupt the execution of the
     * current code in favor of the focus handler
     */
    const caret2EndCb = this.caret2End.getCallback(activeElementId, ref);
    const fromHackPosition = this.hackPosition(ref);
    ref.focus();
    if (thisDevice.isInternetExplorer11) {
      /**
       *  Задача - https://pdffiller.atlassian.net/browse/JSF-7610
       *  Видео - https://www.notion.so/isachivka/focusController-b1362c8d70e747d28d3db3b463dc377b#acc1fbe977c4491c9a8d539f89e7ad31
       *  В IE при активном FillableField / SimpleTool,
       *  новый SimpleTool после добавления деактивируется:
       *
       *  есть активный SimpleTextTool с id 0-1
       *  мы кликаем на документ чтобы добавить новый SimpleTextTool с id 0-2
       *  срабатывает onBlur для SimpleTextTool 0-1
       *  для него устанавливается таймаут 300мс
       *  добавляется SimpleTextTool 0-2
       *  удаляется 0-1
       *  0-2 делается активным
       *  срабатывает componentDidUpdate с новым активным SimpleTextTool 0-2
       *  вызывается this.focus(0-2) в componentDidUpdate
       *  но onFocus для SimpleTextTool 0-2 не вызывается по необъяснимым причинам
       *  поэтому не очищается таймаут для SimpleTextTool 0-1 установленный в onBlur
       *  это приводит к вызову setActiveElement(null)
       *  вызывается componentDidUpdate({ activeElementId: null, ...otherProps })
       *  и так-как нет нового элемента
       *  вызывается this.blur(0-2)
       *
       *  чтобы это предотвратить чистим таймаут
       */
      clearTimeout(this.onBlurTimeout);
    }
    fromHackPosition();
    caret2EndCb();
  };

  blurToRef = (ref) => {
    ref.blur();
  };

  focus = (id) => {
    const { getRef } = this.props;

    this.cancelFocus();

    this.cancelFocus = (() => {
      let cancelled = false;

      const isCancelled = () => cancelled;

      getRef(id)
        .then(this.focusToRef(isCancelled))
        // eslint-disable-next-line no-console
        .catch(err => console.error(err));

      return () => {
        cancelled = true;
      };
    })();
  };

  blur = (id) => {
    const { getRef } = this.props;

    this.keyboardController.hideKeyboard();

    getRef(id)
      .then(this.blurToRef)
      // eslint-disable-next-line no-console
      .catch(err => console.error(err));
  };

  restoreFocus = () => {
    const { activeElementId } = this.props;
    if (activeElementId) this.focus(activeElementId);
  };

  /**
   * We should toggle 'hackPosition' before onFocus event on iOS
   */
  onMouseDown = id => (event) => {
    const { activeElementId, thisDevice } = this.props;
    if (!thisDevice.isIOS) return;

    if (activeElementId !== id) {
      this.focusToRef(() => false)(event.target);
    }
  };

  // id => event =>
  onBlur = () => () => {
    if (!this.blurEnabledGlobally || !this.blurEnabled) return;

    this.onBlurTimeout = setTimeout(() => {
      const { setActiveElementId } = this.props;
      this.keyboardController.onBlur();
      this.caret2End.remember(null);
      setActiveElementId(null);
    }, 300);
  };

  // id => event =>
  onFocus = id => (event) => {
    if (!this.focusEnabled) return;

    const { target } = event;
    this.keyboardController.onFocus(target);
    const { setActiveElementId } = this.props;
    clearTimeout(this.onBlurTimeout);
    this.caret2End.remember(id);
    setActiveElementId(id);
  };

  render() {
    const { children } = this.props;
    return (
      <>
        <FocusControllerContext.Provider value={this.ctx}>
          {children}
        </FocusControllerContext.Provider>
      </>
    );
  }
}

export default refsStorageDecorator(FocusController);
