import { takeEvery, select, put } from 'redux-saga/effects';
import { ZOOM_IN_BY_KEYBOARD,
  ZOOM_OUT_BY_KEYBOARD } from 'jsfcore/store/modules/events';
import { changeScale } from 'jsfcore/store/modules/navigation';
import { zoomMaxValue, zoomMinValue, zoomTick } from 'jsfcore/helpers/const';

export const getScale = (state) => {
  return state.navigation.scales[state.navigation.activePageId];
};
export const getActivePageId = (state) => {
  return state.navigation.activePageId;
};

export const setPossibleValuePlus = (val) => {
  return val < zoomMaxValue
    ? val + zoomTick
    : val;
};
export const setPossibleValueMinus = (val) => {
  return val > zoomMinValue
    ? val - zoomTick
    : val;
};

export const setNearestScale = ({ scale, isPlus }) => {
  return isPlus
    ? setPossibleValuePlus(scale)
    : setPossibleValueMinus(scale);
};

export function* zoomInByKeyboard() {
  const scale = yield select(getScale);
  const pageId = yield select(getActivePageId);
  const nearestScale = setNearestScale({ scale, isPlus: true });

  yield put(changeScale(nearestScale, pageId));
}

export function* zoomOutByKeyboard() {
  const scale = yield select(getScale);
  const pageId = yield select(getActivePageId);
  const nearestScale = setNearestScale({ scale, isPlus: false });

  yield put(changeScale(nearestScale, pageId));
}

export function* watchZoomInByKeyboard() {
  yield takeEvery(ZOOM_IN_BY_KEYBOARD, zoomInByKeyboard);
}

export function* watchZoomOutByKeyboard() {
  yield takeEvery(ZOOM_OUT_BY_KEYBOARD, zoomOutByKeyboard);
}
