import PropTypes from 'prop-types';
import React, { Component } from 'react';
import cls from 'classnames';

export const colorPickerItemTypes = {
  group: 'group',
  list: 'list',
  set: 'set',
  item: 'item',
  null: null,
};

export const colorPickerItemTags = {
  div: 'div',
  ul: 'ul',
  li: 'li',
};

export default class ColorPickerItemByType extends Component {
  static propTypes = {
    children: PropTypes.node,
    type: PropTypes.oneOf(
      Object.values(colorPickerItemTypes),
    ),
    tag: PropTypes.oneOf(
      Object.values(colorPickerItemTags),
    ),
  };

  static defaultProps = {
    children: null,
    tag: colorPickerItemTags.div,
    type: colorPickerItemTypes.null,
  };

  render() {
    const { type } = this.props;
    const Tag = this.props.tag;

    const className = cls({
      [`color-picker__${type}`]: type,
    });

    return (
      <Tag className={className}>
        {this.props.children}
      </Tag>
    );
  }
}
