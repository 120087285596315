import React from 'react';
import PropTypes from 'prop-types';

const ContentConfiguratorCell = ({ children }) => {
  return (
    <div className="content-configurator__cell">
      {children}
    </div>
  );
};

ContentConfiguratorCell.propTypes = {
  children: PropTypes.node,
};

ContentConfiguratorCell.defaultProps = {
  children: null,
};

export default ContentConfiguratorCell;
