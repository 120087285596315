import React from 'react';
import PropTypes from 'prop-types';

const SideBarCell = ({ children, attributes, ...props }) => {
  return (
    <div className="sidebar__cell" {...props} {...attributes}>
      {children}
    </div>
  );
};

SideBarCell.propTypes = {
  children: PropTypes.node,
  attributes: PropTypes.shape({}),
};

SideBarCell.defaultProps = {
  children: null,
  attributes: {},
};

export default SideBarCell;
