import find from 'lodash/find';
import get from 'lodash/get';
import checkIsElementInFormulas from '@pdffiller/jsf-formula/helpers/checkIsElementInFormulas';
import { elementSettingsTypes } from '../../../helpers/elemTypes';
import * as consts from '../../const';
import * as selectors from '../../selectors';

const getNumberLengthWithoutDotsOrCommas = (number) => {
  return number.replace(/,/g, '').replace(/\./g, '').length;
};

const validateText = (element, validators, dispatch, getState, formatMessage) => {
  if (
    element.template.payment &&
    (
      !element.template.validatorId ||
      element.template.validatorId === 'none'
    )
  ) {
    return {
      isValid: false,
      errors: [{
        field: elementSettingsTypes.validation,
        errorText: 'SERVER_VALIDATION.FIELDS.VALIDATION_TYPE_REQUIRED_ERROR',
        isGlobal: false,
      }],
    };
  }

  if (element.template.validatorId && element.template.validatorId !== 'none') {
    const validator = find(validators, { id: element.template.validatorId });

    if (
      !get(validator, 'formulaCalculation', false) &&
      checkIsElementInFormulas(dispatch, getState, element, false)
    ) {
      return {
        isValid: false,
        errors: [{
          field: elementSettingsTypes.validation,
          errorText: 'SERVER_VALIDATION.FIELDS.VALIDATION_TYPE_NOT_APPLIED_TO_FORMULA_ERROR',
          isGlobal: true,
        }],
      };
    }

    if (
      validator &&
      element.template.prefilled
    ) {
      const { prefilled } = element.template;
      const regexp = new RegExp(validator.regexExpression);
      const isNeedLengthValidation = selectors.validators.needNumberLengthValidation(validator);
      if (
        isNeedLengthValidation &&
        getNumberLengthWithoutDotsOrCommas(prefilled) > consts.MAX_LENGTH_FOR_FORMULA_FIELD
      ) {
        return {
          isValid: false,
          errors: [{
            field: elementSettingsTypes.prefilled,
            errorText: validator.errorMessage,
            isGlobal: false,
          }],
        };
      }

      if (!regexp.test(prefilled)) {
        return {
          isValid: false,
          errors: [{
            field: elementSettingsTypes.prefilled,
            errorText: validator.errorMessage,
            isGlobal: true,
          }],
        };
      }
    }
  }

  const { prefilled, maxChars } = element.template;
  if (
    prefilled &&
    maxChars &&
    prefilled.length > maxChars
  ) {
    return {
      isValid: false,
      errors: [{
        field: elementSettingsTypes.prefilled,
        errorText: formatMessage('', 'SERVER_VALIDATION.FIELDS.VALIDATION_TYPE_MAX_CHARS_ERROR', maxChars),
        isGlobal: true,
      }],
    };
  }

  return consts.valid;
};

export default validateText;
