/**
 * This functions can't be covered by Jest
 * because js-dom do not support some features
 */

export const c2eForContentEditable = (node) => {
  const range = document.createRange();
  const sel = window.getSelection();
  const pos = node.textContent.length;
  range.setStart(node, pos);
  range.collapse(true);
  sel.removeAllRanges();
  sel.addRange(range);
};

export const c2eForInputInIE11 = (node) => {
  const pos = node.value.length;
  const range = node.createTextRange();
  range.collapse(true);
  range.moveEnd('character', pos);
  range.moveStart('character', pos);
  range.select();
};

export const c2eForInputDefault = (node) => {
  const pos = node.value.length;
  node.focus();
  node.setSelectionRange(pos, pos);
};
