import minilog from 'minilog';
import superagent from 'superagent';
import StackTrace from 'stacktrace-js';
import StackTraceGPS from 'stacktrace-gps';
import queryString from 'query-string';
import extend from 'lodash/extend';
import map from 'lodash/map';
import * as Sentry from '@sentry/browser';
import { thisDevice, getDeviceProps } from '@pdffiller/jsf-useragent';
import { logInterval } from 'jsfcore/helpers/const';
import config from 'jsfcore/helpers/clientConfig';
import isSignNow from 'jsfcore/helpers/isSignNow';
import JSFillerError from 'jsfcore/errors/JSFillerError';
import { getSessionInstanceId } from '@pdffiller/jsf-loader/auth';
import profiler, { popEvents } from 'jsfcore/helpers/profiler';

const instanceId = getSessionInstanceId();

// Error which has been reproduced in FireFox x64 for DetectRTC in
// window.indexedDB.open('test'). Do not catch this error.
const InvalidStateError = 'InvalidStateError';

const COMMON_MARKERS = {
  ERROR: 'ERROR',
  REDUX_ACTION: 'REDUX_ACTION',
  APP_LOADED: 'APP_LOADED',
  APP_STARTED: 'APP_STARTED',
  APP_DESTROYED: 'APP_DESTROYED',
  APP_LOST_CONNECTION: 'APP_LOST_CONNECTION',
  APP_RECONNECT: 'APP_RECONNECT',
  AUTH_INIT_WARN: 'AUTH_INIT_WARN',
  AUTH_SEND: 'AUTH_SEND',
  AUTH_RECEIVED: 'AUTH_SEND',
  API_DONE: 'API_DONE',
  REACT_RENDER: 'REACT_RENDER',
  WS_CONNECTION_WARNING: 'WS_CONNECTION_WARNING',
  WS_CONNECTION_ERROR: 'WS_CONNECTION_ERROR',
};

const ONLY_JSF_MARKERS = {
  APP_AUTH_INIT_WARN: 'APP_AUTH_INIT_WARN',
};

const ONLY_SNF_MARKERS = {
  API_ERROR: 'API_ERROR',
  API_OPEN: 'API_OPEN',
};

export const MARKERS = Object.assign({}, COMMON_MARKERS, isSignNow()
  ? ONLY_SNF_MARKERS
  : ONLY_JSF_MARKERS,
);

function addStore(store) {
  minilog.store = store;
}

function addAction(action, startedTime, duration) {
  if (!minilog.actions) {
    minilog.actions = [];
  }

  minilog.actions.push({ ...action, timestamp: startedTime, duration });

  if (minilog.actions.length > config.logs.logActionsCount) {
    minilog.actions.shift();
  }
}

function stateTransformer(state) {
  const cache = [];

  function deepCopy(src, originalSkipFolding) {
    let skipFolding = originalSkipFolding;
    const dst = {};

    // eslint-disable-next-line
    if (src.pdfInfo || src._pdfInfo || src.$$typeof || src._transport) {
      return null;
    }

    Object.keys(src).forEach((property) => {
      try {
        if (src &&
          src.hasOwnProperty &&
          src.hasOwnProperty(property)) {
          if (property === 'locale' || src[`_<${property}>NoSerialize`]) {
            return;
          }
          if (property === 'pdfjs' || property === 'html' || property === 'initialHtml') {
            skipFolding = true;
          }

          let value = src[property];

          if (value) {
            if (typeof value === 'object') {
              if (cache.indexOf(value) !== -1) {
                value = '<REF>';
              } else {
                cache.push(value);
                if (value instanceof Array) {
                  value = deepCopy(value, skipFolding);
                } else if (value instanceof HTMLElement) {
                  value = `<${value.tagName}>`;
                } else if (value instanceof Error) {
                  value = `${value.name}: ${value.message}`;
                } else if (value instanceof Function) {
                  value = '<FUNC>';
                } else {
                  value = deepCopy(value, skipFolding);
                }
              }
            } else if (typeof value === 'string') {
              if (value.startsWith('data:image')) {
                value = '<IMG>';
              } else if (property === 'password') {
                value = '<###>';
              } else if (
                !skipFolding &&
                !(
                  property === 'userAgent' || property === 'error' || property === 'fileName' ||
                  property === 'version' || property === 'message' || property === 'functionName' ||
                  property === 'build' || property === 'cookie'
                ) && value.length > 100
              ) {
                value = `${value.substring(0, 100)}<...>`;
              }
            }
          }

          dst[property] = value;
        }
      } catch (err) {
        // eslint-disable-next-line no-console
        console.error('stateTransformer error', err);
      }
    });

    return dst;
  }

  return deepCopy(state);
}

export class LoggingContext {
  constructor(data) {
    extend(this, data);
  }
}

export class SessionContext extends LoggingContext {
  constructor(data) {
    super(data);
    this.timestamp = performance.now();
    this.version = `${config.build}`;
    this.device = {
      ...getDeviceProps(),
      model: thisDevice.iosDeviceModel,
    };
    if (minilog.store) {
      const state = minilog.store.getState();

      if (state && state.ws) {
        this.projectId = state.ws.projectId;
        this.viewerId = state.ws.viewerId;
      }

      if (!this.projectId) {
        const qs = queryString.parse(window.location.search);
        this.projectId = qs.projectId;
      }
    }
  }
}

export class ErrorContext extends SessionContext {
  constructor(error, event, frames) {
    super({});
    if (error) {
      this.error = error;
      this.type = error.name;

      if (error instanceof JSFillerError) {
        Object.assign(this, error.data);
      }
    }

    if (event) {
      this.filename = event.filename;
      this.lineno = event.lineno;
      this.colno = event.colno;
    }

    if (frames) {
      this.frames = frames;
    }

    if (minilog.store) {
      this.state = stateTransformer(minilog.store.getState());
    }

    if (minilog.actions) {
      this.history = map(minilog.actions, stateTransformer);
    }

    /* eslint-disable */
    if (window && window.performance && window.performance.getEntriesByType) {
      try {
        const performance = window.performance.getEntriesByType('resource');
        this.performance = [];
        for (const i in performance) {
          if (typeof performance[i] !== 'function') {
            this.performance[i] = {};
            for (const j in performance[i]) {
              if (typeof performance[i][j] !== 'function') {
                this.performance[i][j] = performance[i][j];
              }
            }
          }
        }
      } catch (err) {
      }
    }

    if (window && window.navigator) {
      try {
        const navigator = { };
        for (const i in window.navigator) {
          if (typeof window.navigator[i] !== 'function') {
            navigator[i] = window.navigator[i];
          }
        }
        delete navigator.plugins;
        delete navigator.mimeTypes;
        if (window.navigator.connection) {
          const connection = {};
          for (const i in window.navigator.connection) {
            if (typeof window.navigator.connection[i] !== 'function') {
              connection[i] = window.navigator.connection[i];
            }
          }
          navigator.connection = connection;
        }
        this.navigator = navigator;
      } catch (err) {
      }
    }
    /* eslint-enable */
  }
}

const hex = {
  black: '#000',
  red: '#c23621',
  green: '#25bc26',
  yellow: '#bbbb00',
  blue: '#492ee1',
  magenta: '#d338d3',
  cyan: '#33bbc8',
  gray: '#808080',
  purple: '#708',
};

const colors = { info: 'blue', warn: 'yellow', error: 'red', fatal: 'magenta' };

export const LEVELS = {
  debug: 3,
  info: 2,
  warn: 1,
  error: 0,
  fatal: -1,
};

const rootTransform = new minilog.Transform();
const loggers = (config && config.logs && config.logs.loggers)
  ? config.logs.loggers
  : null;

rootTransform.write = function write(name, level, args) {
  if (args && args.length > 0 && args[0] && typeof args[0] === 'string' && loggers) {
    let next = false;

    if (loggers[name] && loggers[name].level && LEVELS[loggers[name].level] >= LEVELS[level]) {
      next = true;
    }

    let newLevel = level;

    if (
      loggers.default &&
      loggers.default.level &&
      LEVELS[loggers.default.level] >= LEVELS[level]
    ) {
      next = true;
    }

    if (next) {
      let props = args;

      if (!MARKERS[args[0]]) {
        props = ['', ...args];
      }

      let error = null;
      let errorContext = null;

      if (level === 'error' && props.length > 2) {
        newLevel = 'fatal';

        if (props[0] === MARKERS.WS_CONNECTION_ERROR) {
          newLevel = 'error';
        }

        if (!error && props[2] instanceof ErrorContext) {
          error = props[2].error; // eslint-disable-line prefer-destructuring
          errorContext = props[2]; // eslint-disable-line prefer-destructuring

          // TODO: refactor after "jsfiller-logger"
          if (name === 'global') {
            if (
              (props[1] === InvalidStateError) ||
              (
                isSignNow() &&
                !(
                  errorContext.filename &&
                  (
                    errorContext.filename.indexOf('snfiller-cdn.signnow.com') > 0 ||
                    errorContext.filename.indexOf('pdffiller.com') > 0 ||
                    errorContext.filename.indexOf('pdffillers.com') > 0 ||
                    errorContext.filename.indexOf('biarum.com') > 0 ||
                    errorContext.filename.indexOf('spbfiller.com') > 0 ||
                    errorContext.filename.indexOf('signnow.com') > 0 ||
                    errorContext.filename.indexOf('signnow.xyz') > 0 ||
                    errorContext.filename.indexOf('jsfiller') > 0 ||
                    errorContext.filename.indexOf('snfiller') > 0 ||
                    errorContext.filename.indexOf('localhost') > 0
                  )
                )
              ) ||
              !(
                errorContext.filename &&
                errorContext.filename.indexOf('.js') > 0 &&
                (
                  errorContext.filename.indexOf('pdffiller.com') > 0 ||
                  errorContext.filename.indexOf('biarum.com') > 0 ||
                  errorContext.filename.indexOf('jsfiller') > 0 ||
                  errorContext.filename.indexOf('localhost') > 0 ||
                  errorContext.filename.indexOf('127.0.0.1') > 0
                )
              )
            ) {
              newLevel = 'error';
            }
          }
        }

        if (!error && props[2] instanceof Error) {
          error = props[2]; // eslint-disable-line prefer-destructuring
        }

        if (!error && props[2].stack) {
          error = props[2]; // eslint-disable-line prefer-destructuring
        }
      }

      if (error && !__DEVELOPMENT__) {
        if (!errorContext) {
          errorContext = new ErrorContext(error, null);
          props[2] = errorContext;
        }

        StackTrace.fromError(errorContext.error, { offline: true }).then((frames) => {
          errorContext.frames = frames;
          this.emit('item', name || '', newLevel, props);
        }).catch(() => {
          errorContext.stack = errorContext.error.stack;
          this.emit('item', name || '', newLevel, props);
        });
      } else {
        this.emit('item', name || '', newLevel, props);
      }
    }
  }
};

let popupAppender = null;

const globalErrorHandler = (event) => {
  if (!__DEVELOPMENT__ || isSignNow()) {
    minilog('global').error(MARKERS.ERROR, event.message, new ErrorContext(event.error, event));
  } else {
    // eslint-disable-next-line no-console
    console.error('Got global error', event);
  }
};

const sagaErrorHandler = (err) => {
  if (!__DEVELOPMENT__) {
    minilog('saga').error(MARKERS.ERROR, err.message, new ErrorContext(err));
  } else {
    // eslint-disable-next-line no-console
    console.error('Got saga error', err);
  }
};

const sagaLogger = (level, message, error) => {
  if (typeof window === 'undefined') {
    // eslint-disable-next-line
    console.log(`redux-saga ${level}: ${message}\n${(error && error.stack || error)}`);
  } else {
    // eslint-disable-next-line no-lonely-if
    if (error) {
      minilog('saga').error(MARKERS.ERROR, message, new ErrorContext(error));
    } else {
      console[level](message, error); // eslint-disable-line no-console
    }
  }
};

if (__CLIENT__ && !__TEST__) {
  window.getStore = () => {
    // eslint-disable-next-line no-console
    console.log(stateTransformer(minilog.store.getState()));
  };

  window.getOriginalStack = (col, line, file) => {
    const gps = new StackTraceGPS();
    gps.pinpoint({
      columnNumber: col,
      lineNumber: line,
      fileName: file,
    }).then((res) => {
      // eslint-disable-next-line no-console
      console.log(res);
    });
  };

  const ConsoleLogger = function ConsoleLogger(options) {
    this.level = options.level || 'info';
    this.newlines = /\n+$/;
  };

  minilog.Transform.mixin(ConsoleLogger);

  ConsoleLogger.prototype.write = function write(name, level, originalArgs) {
    if (__TEST__ || __INTEGRATION_TEST__) {
      return;
    }
    const args = originalArgs;
    let idx = args.length - 1;

    // eslint-disable-next-line no-console
    if (typeof console === 'undefined' || !console.log) {
      return;
    }

    if (LEVELS[this.level] < LEVELS[level]) {
      return;
    }

    // eslint-disable-next-line no-console
    if (console.log.apply) {
      const color = colors[level];

      if (color) {
        // eslint-disable-next-line no-console
        console.log(
          ...[`%c${name} ${level}`, `color: ${hex[color]};`].concat(args),
        );
      } else {
        // eslint-disable-next-line no-console
        console.log(...[name, level].concat(args));
      }
    } else if (JSON && JSON.stringify) {
      // console.log.apply is undefined in IE8 and IE9
      // for IE8/9: make console.log at least a bit less awful
      if (args[idx] && typeof args[idx] === 'string') {
        args[idx] = args[idx].replace(this.newlines, '');
      }

      try {
        for (idx = 0; idx < args.length; idx++) {
          args[idx] = JSON.stringify(args[idx]);
        }
      } catch (ex) { } // eslint-disable-line

      if (isSignNow()) {
        console.log(args.join(' ')); // eslint-disable-line no-console
      }
    }
  };

  const AjaxLogger = function AjaxLogger(options) {
    this.level = options.level || 'info';
    this.url = options.url || '';
    this.cache = [];
    this.timer = null;
    this.interval = options.interval || 30 * 1000;
    this.enabled = true;
    this.request = superagent;
    this.extras = {};
  };

  minilog.Transform.mixin(AjaxLogger);

  AjaxLogger.prototype.write = function write(name, level, args) {
    if (!this.timer) {
      this.init();
    }

    if (LEVELS[this.level] < LEVELS[level]) {
      return;
    }

    this.cache.push([name, level, new Date().toString()].concat(args));

    if (args[0] === MARKERS.APP_DESTROYED || level === 'fatal') {
      this.init(true);

      if (!isSignNow()) {
        this.enabled = false;
      }
    }
  };

  AjaxLogger.prototype.init = function init(force) {
    // jscs:disable
    const self = this;

    // jscs:enable
    if (!(this.enabled && this.request)) {
      return;
    }

    const process = () => {
      if (self.timer) {
        clearTimeout(self.timer);
        self.timer = null;
      }

      const logs = [];
      const url = self.url; // eslint-disable-line prefer-destructuring

      if (self.cache.length === 0) {
        return self.init();
      }

      let total = 0;

      while (self.cache.length > 0 && total < 10000) {
        try {
          const item = stateTransformer(self.cache.shift());
          const log = JSON.stringify(item);
          logs.push(log);
          total += log.length;
        } catch (ex) { } // eslint-disable-line
      }

      const events = popEvents();
      if (events.length > 0) {
        const item = stateTransformer([
          'profiler',
          'info',
          new Date().toString(),
          'PROFILLER',
          'profiler',
        ]);
        item[5] = {
          events,
          device: {
            ...getDeviceProps(),
            model: thisDevice.iosDeviceModel,
          },
        };
        const log = JSON.stringify(item);
        logs.push(log);
      }

      const ajaxData = `[${logs.join(',')}]`;
      self.request
        .post(url)
        .timeout(10000)
        .set('Content-Type', 'application/json')
        .set('X-Requested-With', 'XMLHttpRequest')
        .set('Expires', '-1')
        .set('Cache-Control', 'no-cache,no-store,must-revalidate,max-age=-1,private')
        .send(ajaxData)
        .end((err, res) => {
          if (err) {
            self.interval = 30000;
          } else if (self.cache.length > 0) {
            self.interval = 250;
          } else if (res.body && res.body.interval) {
            self.interval = Math.max(1000, res.body.interval);
          }

          self.init();
        });

      return null;
    };

    if (force) {
      process();
    } else if (!this.timer) {
      this.timer = setTimeout(process, this.interval);
    }
  };

  AjaxLogger.prototype.end = function end() {};

  const SentryLogger = function SentryLogger(options) {
    this.level = options.level || 'info';
    Sentry.init({
      dsn: options.dns,
      debug: config.logs.appenders.sentry.debug,
      release: config.build,
      environment: config.metricsEnv,
      serverName: config.hostName,
      maxBreadcrumbs: 100,
      defaultIntegrations: [
        // Common
        // new Sentry.Integrations.Dedupe(),
        new Sentry.Integrations.InboundFilters(),
        new Sentry.Integrations.FunctionToString(),
        // Native Wrappers
        // new Sentry.Integrations.TryCatch(),
        new Sentry.Integrations.Breadcrumbs(),
        // Global Handlers
        // new Sentry.Integrations.GlobalHandlers(),
        // Misc
        new Sentry.Integrations.LinkedErrors(),
        new Sentry.Integrations.UserAgent(),
      ],
    });
  };

  minilog.Transform.mixin(SentryLogger);

  SentryLogger.prototype.write = function write(name, level, args) {
    const context = args[2];
    if (LEVELS[this.level] < LEVELS[level]) {
      if (LEVELS.debug > LEVELS[level]) {
        Sentry.addBreadcrumb({
          category: name,
          message: `${args[0]}: ${args[1]}`,
          level,
        });
      }
      return;
    }

    Sentry.withScope((scope) => {
      scope.setLevel(level);
      scope.setTag('marker', args[0]);
      scope.setTag('logger', name);

      scope.setExtra('instanceId', instanceId);
      scope.setExtra('projectId', context.projectId);
      scope.setExtra('viewerId', context.viewerId);

      if (context instanceof ErrorContext) {
        const { error } = context;

        if (!error) {
          scope.setExtra('error', args[1]);
          Sentry.captureMessage(args[1], level);
        } else {
          if (!(error instanceof Error || error.stack)) {
            Sentry.addBreadcrumb({
              category: name,
              message: args[1],
              level,
            });
          }
          Sentry.captureException(error);
        }
      } else {
        scope.setExtra('error', args[1]);
        Sentry.captureMessage(args[1]);
      }

      if (args[0] === MARKERS.APP_DESTROYED) {
        const client = Sentry.getCurrentHub().getClient();
        client.close();
      }
    });
  };

  SentryLogger.prototype.end = function end() {};

  const PopupLogger = function PopupLogger(options) {
    this.level = options.level || 'error';
    this.develop = options.develop || false;
    this.component = null;
  };

  minilog.Transform.mixin(PopupLogger);

  PopupLogger.prototype.write = function write(name, level, args) {
    if (!this.handler || LEVELS[this.level] < LEVELS[level]) {
      return;
    }

    try {
      this.handler(name, level, args);
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error('PopupLogger: write error.', e, name, level, args);
    }
  };

  PopupLogger.prototype.setHandler = function setHandler(handler) {
    this.handler = handler;
  };

  let pipe = minilog;
  pipe = pipe.pipe(rootTransform);

  if (config &&
    config.logs &&
    config.logs.appenders &&
    config.logs.appenders.console &&
    config.logs.appenders.console.enable) {
    pipe.pipe(new ConsoleLogger({
      level: config.logs.appenders.console.level || config.logs.loggers.default.level,
    }));
  }

  if (config &&
    config.logs &&
    config.logs.appenders &&
    config.logs.appenders.popup &&
    config.logs.appenders.popup.enable) {
    popupAppender = new PopupLogger({
      level: config.logs.appenders.popup.level || 'error',
      develop: config.logs.appenders.popup.develop || false,
    });
    pipe.pipe(popupAppender);
  }

  if (!__DEVELOPMENT__ && config &&
    config.logs &&
    config.logs.appenders &&
    config.logs.appenders.ajax &&
    config.logs.appenders.ajax.enable &&
    config.logs.appenders.ajax.url) {
    pipe.pipe(new AjaxLogger({
      level: config.logs.appenders.ajax.level || config.logs.loggers.default.level,
      url: `${config.logs.appenders.ajax.url}?instanceId=${instanceId}`,
      interval: logInterval,
    }));
  }

  if (
    !__DEVELOPMENT__ &&
    config &&
    config.logs &&
    config.logs.appenders &&
    config.logs.appenders.sentry &&
    config.logs.appenders.sentry.enable &&
    config.logs.appenders.sentry.dns
  ) {
    pipe.pipe(new SentryLogger({
      level: config.logs.appenders.sentry.level || config.logs.loggers.default.level,
      dns: config.logs.appenders.sentry.dns,
    }));
  }

  if (__CLIENT__) {
    window.addEventListener('error', globalErrorHandler, false);
  }
}

const loggerInit = () => {
  profiler('AppLoaded');
  const log = minilog('application');
  log.info(
    MARKERS.APP_LOADED,
    'application loaded',
    new SessionContext({}),
  );
};

const loggerDestroy = () => {
  profiler('AppDestroyed');
  const log = minilog('application');
  log.info(
    MARKERS.APP_DESTROYED,
    'application destroyed',
    new SessionContext({}),
  );
};

const logConnectionLost = () => {
  const log = minilog('application');
  log.info(
    MARKERS.APP_LOST_CONNECTION,
    'application lost connection',
    new SessionContext({}),
  );
};

const logReconnect = () => {
  const log = minilog('application');
  log.info(
    MARKERS.APP_RECONNECT,
    'application reconnected',
    new SessionContext({}),
  );
};

const logAppStart = (modeId, pdfUrl) => {
  profiler('AppStarted');
  const log = minilog('application');
  log.info(
    MARKERS.APP_STARTED,
    'application started',
    new SessionContext({
      modeId,
      pdfUrl,
      userTimeZone: new Date().getTimezoneOffset(),
    }),
  );
};

const logAuthSend = () => {
  profiler('AuthSend');
  const log = minilog('application');
  log.info(
    MARKERS.AUTH_SEND,
    'auth send',
    new SessionContext({}),
  );
};

const logAuthReceived = () => {
  profiler('AuthReceived');
  const log = minilog('application');
  log.info(
    MARKERS.AUTH_RECEIVED,
    'auth received',
    new SessionContext({}),
  );
};

const logAuthInitWarn = (data) => {
  const log = minilog('application');
  log.warn(
    MARKERS.AUTH_INIT_WARN,
    'auth init viewerId -1',
    // зачем новую
    new SessionContext(data),
  );
};

const logApiError = (req, err) => {
  const log = minilog('api');
  log.warn(
    MARKERS.API_ERROR,
    err.message,
    new SessionContext({ err, req, res: err.response }),
  );
};

const logApiOpen = (document, user) => {
  const log = minilog('api');
  log.info(
    MARKERS.API_OPEN,
    'api open',
    new SessionContext({ document, user }),
  );
};

const logApiDone = (payload) => {
  const log = minilog('api');
  log.info(
    MARKERS.API_DONE,
    'api done',
    new SessionContext({ payload }),
  );
};

const logReactRenderError = (error) => {
  const log = minilog('react/render');
  log.error(MARKERS.REACT_RENDER, error.message, error);
};

const logWsConnectionFailed = (wsFallbackData, isError) => {
  const log = minilog('application');

  if (isError) {
    log.error(
      MARKERS.WS_CONNECTION_ERROR,
      'ws connection error',
      new SessionContext({ wsFallbackData }),
    );
  } else {
    log.warn(
      MARKERS.WS_CONNECTION_WARNING,
      'ws connection warning',
      new SessionContext({ wsFallbackData }),
    );
  }
};

export default {
  getLogger: minilog,
  addStore,
  addAction,
  stateTransformer,
  popupAppender,
  loggerInit,
  loggerDestroy,
  logAppStart,
  logAuthInitWarn,
  logConnectionLost,
  logReconnect,
  logApiError,
  logApiDone,
  logApiOpen,
  logAuthSend,
  logAuthReceived,
  logReactRenderError,
  logWsConnectionFailed,
  getSessionInstanceId,
  sagaErrorHandler,
  sagaLogger,
};
