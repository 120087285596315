const formulaLocales = {
  VALID_NUMBER: 'CALCULATE.VALID_NUMBER',
  VALID_FIELD: 'CALCULATE.VALID_FIELD',
  VALID_DIGITS: 'CALCULATE.VALID_DIGITS',
  VALID_FIELD_VALIDATOR: 'CALCULATE.VALID_FIELD_VALIDATOR',
  VALID_FIELD_OPTIONS: 'CALCULATE.VALID_FIELD_OPTIONS',
  CUSTOM_OPTIONS: 'CALCULATE.CUSTOM_OPTIONS',
  PARSE_NUMBER: 'CALCULATE.PARSE_NUMBER',
  DIVISION_BY_ZERO: 'CALCULATE.DIVISION_BY_ZERO',
  EMPTY_TERM: 'CALCULATE.EMPTY_TERM',
  EMPTY_TERM_LEFT: 'CALCULATE.EMPTY_TERM_LEFT',
  EMPTY_TERM_RIGHT: 'CALCULATE.EMPTY_TERM_RIGHT',
  BRACES: 'CALCULATE.BRACES',
  UNEXPECTED_TOKEN: 'CALCULATE.UNEXPECTED_TOKEN',
  INVALID_SYMBOL: 'CALCULATE.INVALID_SYMBOL',
  MISSING_CLOSING_PARENTHESIS: 'CALCULATE.MISSING_CLOSING_PARENTHESIS',
  MISSING_PARAMETERS: 'CALCULATE.MISSING_PARAMETERS',
  MISSING_OPENING_PARENTHESIS: 'CALCULATE.MISSING_OPENING_PARENTHESIS',
  MISSING_BRACES: 'CALCULATE.MISSING_BRACES',
  MISSING_VARIABLE: 'CALCULATE.MISSING_VARIABLE',
  ONLY_NUMBERIC: 'CALCULATE.ONLY_NUMBERIC',
  DISABLE_DROPDOWN_ANOTHER_OPTION: 'CALCULATE.DISABLE_DROPDOWN_ANOTHER_OPTION',
  RECURSION: 'CALCULATE.RECURSION',
  UNKNOWN_FUNCTION: 'CALCULATE.UNKNOWN_FUNCTION',
  FORMULA_DESCRIPTION_JSF: 'ADD_CALCULATED_FIELD.FORMULA_DESCRIPTION_JSF',
  FORMULA_WITHOUT_OPERATOR: 'CALCULATE.FORMULA_WITHOUT_OPERATOR',
  FORMULA_UNKNOWN_ERROR: 'CALCULATE.FORMULA_UNKNOWN_ERROR',
};

export default formulaLocales;
