export const offsetFromText = {
  left: 5,
  top: 5,
};

export const oneLineHeight = 13;

export default {
  postCalculation(args) {
    const { alignedRect, otherArgs: { rtaOffset } } = args;
    return {
      ...args,
      alignedRect: {
        ...alignedRect,
        left: alignedRect.left + rtaOffset.left + offsetFromText.left,
        right: alignedRect.right + rtaOffset.left + offsetFromText.left,
        top: alignedRect.top + rtaOffset.top + offsetFromText.top,
        bottom: alignedRect.bottom + rtaOffset.top + offsetFromText.top,
      },
    };
  },
  postValidation(args) {
    const { isValid } = args;
    if (isValid) {
      return args;
    }

    // move to left under the text, if do not fit
    const { anchorRect, alignedRect } = args;
    return {
      ...args,
      isValid: true,
      alignedRect: {
        left: anchorRect.left + offsetFromText.left,
        top: alignedRect.top + oneLineHeight + offsetFromText.top,
        right: anchorRect.right + offsetFromText.left,
        bottom: alignedRect.bottom + oneLineHeight + offsetFromText.top,
      },
    };
  },
};
