import { compose } from 'redux';

export default function applyMiddleware(...middlewares) {
  return (createStore) => {
    return (...createStoreArgs) => {
      const store = createStore(...createStoreArgs);
      // because of `combine-modals` I have to remove warning about dispatching during init
      let { dispatch } = store;

      const callDispatch = (...args) => {
        return dispatch(...args);
      };
      const middlewareAPI = {
        getState: store.getState,
        dispatch: callDispatch,
      };
      const replaceMiddlewares = (...newMiddlewares) => {
        const chain = newMiddlewares.map((middleware) => {
          return middleware(middlewareAPI);
        });
        dispatch = compose(...chain)(store.dispatch);
      };

      replaceMiddlewares(...middlewares);

      return {
        ...store,
        dispatch: callDispatch,
        replaceMiddlewares,
      };
    };
  };
}
