import React from 'react';
import PropTypes from 'prop-types';

const DialogMain = ({ children }) => {
  return (
    <div className="dialog__main">
      {children}
    </div>
  );
};

DialogMain.propTypes = {
  children: PropTypes.node,
};

DialogMain.defaultProps = {
  children: null,
};

export default DialogMain;
