import React from 'react';
import PropTypes from 'prop-types';

const FlexibleBarCell = ({ attributes, children }) => {
  return (
    <div
      className="flexible-bar__cell"
      {...attributes}
    >
      {children}
    </div>
  );
};

FlexibleBarCell.propTypes = {
  children: PropTypes.node,
  attributes: PropTypes.shape({}),
};

FlexibleBarCell.defaultProps = {
  children: null,
  attributes: {},
};

export default FlexibleBarCell;
