import { focusControllerSelectDecorator } from '@pdffiller/jsf-focuscontroller';

import ReactSelectCreatable from './ReactSelectCreatableOverridden';
import ReactSelect from './ReactSelectOverridden';
import selectWrapperFactory from './selectWrapperFactory';

export {
  selectThemes,
  selectTypes,
  selectSizes,
  selectMenuHorizontalAlign,
} from './selectWrapperFactory';

export const Select = selectWrapperFactory(ReactSelect);
export const Creatable = selectWrapperFactory(ReactSelectCreatable);

/**
 * Export components with focus controller decorator
 */
export const FocusControlledCreatableSelect =
  selectWrapperFactory(focusControllerSelectDecorator(ReactSelectCreatable));

export default selectWrapperFactory(focusControllerSelectDecorator(ReactSelect));
