import React from 'react';
import PropTypes from 'prop-types';
import { Creatable } from 'react-select-pdffiller';
import ReactSelectOverridden from './ReactSelectOverridden';

const ReactSelectCreatableOverridden = (props) => {
  return (
    <Creatable
      {...props}
      // generates Warning: CreatableSelect: `ref` is not a prop,
      // but this prop is needed by Creatable
      // https://github.com/pdffiller/react-select/blob/master/src/Creatable.js#L293
      ref={props.storeRef}
    >
      {(creatableProps) => {
        const mergedProps = { ...props, ...creatableProps };
        return <ReactSelectOverridden {...mergedProps} />;
      }}
    </Creatable>
  );
};

ReactSelectCreatableOverridden.propTypes = {
  storeRef: PropTypes.func,
};

ReactSelectCreatableOverridden.defaultProps = {
  storeRef: null,
};

export default ReactSelectCreatableOverridden;
