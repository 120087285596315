import { takeEvery, select, put, take, call, race } from 'redux-saga/effects';
import { delay } from 'redux-saga';

import { closeModal } from 'combine-modals/lib/actions/actionCreator';

import { actionTypes } from 'ws-editor-lib/actions';

import editorModes from 'ws-editor-lib/constants/editorModes';
import openProcessingModal from './modals/openProcessingModal';
import { RENDER_CANVAS_SUCCESS } from '../modules/pdf';
import { selectors } from '../..';
import { setAppStarted, SET_APP_STARTED } from '../modules/events';

export function* onCanvasSuccess({ pageId }) {
  const isAppStarted = yield select(selectors.base.getAppStarted);
  const activePageId = yield select(selectors.base.getActivePageId);
  if (pageId === activePageId && !isAppStarted) {
    yield put(setAppStarted());
  }
}

export function* toggleProcessingModalOnAppStarted() {
  const {
    operations: [{ properties: { subType: appMode } }],
  } = yield take(actionTypes.ON_OPERATIONS_EDITOR_MODE);

  const { delayWins, actionWins } = yield race({
    actionWins: take(actionTypes.ON_OPERATIONS_EDITOR_MODE),
    delayWins: call(delay, 2000),
  });

  // Если между экшенами меньше двух секунд, то не показываем модалку
  if (actionWins) {
    return;
  }

  // https://pdffiller.atlassian.net/browse/JSF-3245
  // Модалка: когда всё есть, а контента нет, и его нужно ждать на фоне загруженного PDF
  if (
    delayWins &&
    appMode === editorModes.init
  ) {
    // take(SET_APP_STARTED) здесь для того, чтобы модалка не показывалась
    // поверх главного лоадера, только после него
    const appStarted = yield select(selectors.base.getAppStarted);
    if (!appStarted) {
      yield take(SET_APP_STARTED);
    }
    // Убеждаемся в том, что пока мы ждали SET_APP_STARTED не поменялся mode
    const currentAppMode = yield select(selectors.base.getMode);

    if (
      appMode === editorModes.init &&
      currentAppMode === editorModes.init
    ) {
      yield* openProcessingModal();

      // Может быть ситуация при реконнекте, когда получаем еще раз init
      while (true) {
        // Любой другой экшен для смены мода
        const {
          operations: [{ properties: { subType: nextAppMode } }],
        } = yield take(actionTypes.ON_OPERATIONS_EDITOR_MODE);

        // закрыть модалку после того, как mode сменится с init на любой другой
        if (nextAppMode !== editorModes.init) {
          yield put(closeModal('DialogModal'));

          // Закончить сагу, если закрыли модалку
          return;
        }
      }
    }
  }
}

export const getPendingModeController = () => {
  let prevAppMode = null;

  return function* onOperationsEditorModePending({
    operations: [{ properties: { subType: currentAppMode } }],
  }) {
    // Отделена логика открытия и закрытия модалки от init mode потому что:
    // 1) Нет условий для перекрывания главным лоадером
    // 2) Логика init mode может стать другой
    // 3) Нет условий для 2х секундной разницы между получения нового режима

    // https://pdffiller.atlassian.net/browse/JSF-5262
    // Если текущий режим pending, и нам пришел любой другой - закрываем модалку
    if (
      prevAppMode === editorModes.pending &&
      currentAppMode !== editorModes.pending
    ) {
      yield put(closeModal('DialogModal'));

      prevAppMode = currentAppMode;
      return;
    }

    const modeFromState = yield select(selectors.base.getMode);
    // данная проверка нужна, т.к. для работы с аддонами необходимо сразу класть
    // pending-моде в store, не дожидаясь операции ON_OPERATIONS_EDITOR_MODE
    const isNotPendingAlreadyByAddonsReason = modeFromState !== editorModes.pending;
    // Если режим сменился на pending, открываем модалку до следущего режима
    if (
      prevAppMode !== editorModes.pending &&
      currentAppMode === editorModes.pending &&
      isNotPendingAlreadyByAddonsReason
    ) {
      yield* openProcessingModal();
    }

    prevAppMode = currentAppMode;
  };
};

const pendingModeController = getPendingModeController();

export function* watchOnOperationsEditorModePending() {
  yield takeEvery(
    actionTypes.ON_OPERATIONS_EDITOR_MODE,
    pendingModeController,
  );
}

export default function* watchRenderCanvasSuccess() {
  yield takeEvery(RENDER_CANVAS_SUCCESS, onCanvasSuccess);
}
