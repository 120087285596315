import deepForceUpdate from 'react-deep-force-update';
import getPromise from 'jsfcore/helpers/getPromise';
import { loadModuleAttemptTimeout, loadModuleMaxAttempts } from '../const';
import { setModuleLoaded } from '../store/reducer';
import initializeModule from './initializeModule';

export const modulesStore = {};

const {
  promise: loadModuleFunctionsPromise,
  resolve: resolveLoadModuleFunctionsPromise,
} = getPromise();
const {
  promise: controllerLoadedPromise,
  resolve: resolveControllerLoaded,
} = getPromise();

export { resolveLoadModuleFunctionsPromise, resolveControllerLoaded };

const modulesPromises = {};

export default function loadModule(moduleName) {
  if (modulesPromises[moduleName] === undefined) {
    modulesPromises[moduleName] = new Promise((resolve, reject) => {
      let loadAttempts = 0;
      Promise.all([
        loadModuleFunctionsPromise,
        controllerLoadedPromise,
      ]).then(([[generateModulePromise, runAcceptFunc], { store, createReducer }]) => {
        if (module.hot) {
          runAcceptFunc(moduleName, async () => {
            const { default: module } = await generateModulePromise(moduleName);
            deepForceUpdate(window.reactInstance);
            modulesStore[moduleName] = module;
          });
        }

        function loadThatModule() {
          generateModulePromise(moduleName)
            .then(({ default: result }) => {
              resolve([result, store, createReducer]);
            })
            .catch((error) => {
              if (loadAttempts < loadModuleMaxAttempts) {
                setTimeout(loadThatModule, loadModuleAttemptTimeout);
                loadAttempts += 1;
              } else {
                reject(error);
              }
            });
        }

        loadThatModule();
      });
    })
      .then(([result, store, createReducer]) => {
        if (result === undefined) {
          throw new Error(`${moduleName} returned undefined! Check your lazy module code.`);
        }

        modulesStore[moduleName] = result;
        initializeModule(result, moduleName, store, createReducer);
        if (result && result.functions && typeof result.functions.initModule === 'function') {
          result.functions.initModule(store, result);
        }

        store.dispatch(setModuleLoaded(moduleName));
        return Promise.resolve(result);
      })
      .catch((error) => {
        const errorMessage = new Error(`loadModule: (${moduleName}) load failed: ${error}`);
        // eslint-disable-next-line no-console
        console.error(errorMessage);
      });
  }

  return modulesPromises[moduleName];
}
