import { CONDITION_STATUS_NONE } from '../../../../constants';

export const CONDITION_CONNECTION_CANCEL_URL = '/en/forms.htm?method=connection';
export const CONDITION_CONNECTION_CONNECT_STATUS = 'connect';
export const CONDITION_CONNECTION_DISCONNECT_STATUS = 'disconnect';
export const CONDITION_CONNECTION_ERROR_STATUS = 'error';

const getNewConnectionStatus = (prevStatus, nextStatus) => {
  return prevStatus === CONDITION_CONNECTION_DISCONNECT_STATUS
    ? prevStatus
    : nextStatus;
};

export const ConnectionCondition = {

  getInitialState: () => {
    return {
      status: CONDITION_STATUS_NONE,
      cancelUrl: CONDITION_CONNECTION_CANCEL_URL,
    };
  },

  connect: (state) => {
    return {
      ...state,
      status: CONDITION_CONNECTION_CONNECT_STATUS,
    };
  },

  disconnect: (state) => {
    return {
      ...state,
      status: CONDITION_CONNECTION_DISCONNECT_STATUS,
    };
  },

  reconnect: (state) => {
    return {
      ...state,
      status: getNewConnectionStatus(state.status, CONDITION_STATUS_NONE),
    };
  },

  error: (state) => {
    return {
      ...state,
      status: getNewConnectionStatus(state.status, CONDITION_CONNECTION_ERROR_STATUS),
    };
  },

};
