import { createSelector } from 'reselect';
import clamp from 'lodash/clamp';
import last from 'lodash/last';
import max from 'lodash/max';
import * as base from './baseSelectors';

/**
 * Selectors for navigation
 */
class NavigationSelectors {
  /**
   * Из массива координат каждой страницы [{ top, bottom }, ...]
   * делаем массив видимых координат каждой страницы [{ top, bottom }, ...]
   * чтобы затем отнять top от bottom и узнать высоту видимой области каждой
   * страницы
   */
  clampFrameBoundsByWorkspaceAndScrollTop =
    (frameBoundsRelativelyScroll, scrollTop, workspaceHeight) => {
      return frameBoundsRelativelyScroll.map((frameBound) => {
        return (
          frameBound
            ? {
              top: clamp(frameBound.top - scrollTop, 0, workspaceHeight),
              bottom: clamp(frameBound.bottom - scrollTop, 0, workspaceHeight),
            }
            : false
        );
      });
    };

  /**
   * Из массива frameSizes делаем массив координат { top, bottom }
   * для каждой страницы, относительно Workspace,
  */
  getFrameBounds = createSelector(
    [
      ({ frameSizes }) => {
        return frameSizes;
      },
      ({ workspace }) => {
        return workspace;
      },
    ],
    (frameSizes, workspace) => {
      const acc = [];
      for (let i = 0; i < frameSizes.length; i++) {
        const frameSize = frameSizes[i];
        if (frameSize && (i === 0 || last(acc))) {
          const top = i === 0
            ? workspace.framePadding.bottom
            : last(acc).bottom + workspace.framePadding.bottom;
          acc.push({
            top,
            bottom: top + frameSize.height,
            left: workspace.framePadding.left,
            right: workspace.framePadding.right + frameSize.width,
          });
        } else {
          acc.push(false);
        }
      }
      return acc;
    },
  );

  /**
   * Из массива видимых координат каждой страницы [{ top, bottom }, ...]
   * высчитываем видимые высоты всех страниц, находим максимальную высоту и ее
   * индекс, этот индекс и есть активная страница
  */
  getActivePageIndex = (clampedFrameBounds) => {
    const visibleHeights = clampedFrameBounds.map((frameBound) => {
      return frameBound
        ? frameBound.bottom - frameBound.top
        : 0;
    });
    const maxVisibleHeight = max(visibleHeights);
    return visibleHeights.indexOf(maxVisibleHeight);
  };

  calulateActivePageId = (frameSizes, workspace, scrollTop) => {
    return this.getActivePageIndex(
      this.clampFrameBoundsByWorkspaceAndScrollTop(
        this.getFrameBounds({ frameSizes, workspace }),
        scrollTop, workspace.height,
      ),
    );
  };

  getPagesSettings = createSelector(
    [base.getWsPages],
    (wsPages) => {
      if (wsPages && wsPages.length > 0) {
        return wsPages.filter(({ visible }) => {
          return !!visible;
        });
      }

      return null;
    },
  );
}

const navigationSelectorsInstance = new NavigationSelectors();

export default navigationSelectorsInstance;
