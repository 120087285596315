import { types as jsfcoreModalsTypes } from 'jsfcore/jsf-modals';

const prefix = 'jsf-modals';

/**
 * Confirmation
 */
export const SHOW_CONFIRMATION_MODAL = `${prefix}/SHOW_CONFIRMATION_MODAL`;
export const HIDE_CONFIRMATION_MODAL = `${prefix}/HIDE_CONFIRMATION_MODAL`;

export const confirmationModalInitialState = { isConfirmationModalVisible: false };

export function handleShowConfirmationModal(state, action) {
  return {
    ...state,
    isConfirmationModalVisible: true,
    modalData: {
      type: action.confirmationType,
    },
  };
}

export function handleHideConfirmationModal(state) {
  return {
    ...state,
    isConfirmationModalVisible: false,
    modalData: {},
  };
}

export const showConfirmationModal = (confirmationType) => {
  return { type: SHOW_CONFIRMATION_MODAL, confirmationType };
};

export const hideConfirmationModal = () => {
  return { type: HIDE_CONFIRMATION_MODAL };
};


/**
 * Warning
 */
export const SHOW_WARNING_MODAL = `${prefix}/SHOW_WARNING_MODAL`;
export const HIDE_WARNING_MODAL = `${prefix}/HIDE_WARNING_MODAL`;

export const warningModalInitilState = { isWarningModalVisible: false };

export function handleShowWarningModal(state, action) {
  return {
    ...state,
    isWarningModalVisible: true,
    modalData: {
      type: action.warningType,
      opts: action.opts,
    },
  };
}

export function handleHideWarningModal(state) {
  return {
    ...state,
    isWarningModalVisible: false,
    modalData: {},
  };
}

export const showWarningModal = (warningType, opts) => {
  return { type: SHOW_WARNING_MODAL, warningType, opts };
};

export const hideWarningModal = () => {
  return { type: HIDE_WARNING_MODAL };
};


/**
 * Radio
 */
export const SHOW_RADIO_MODAL = `${prefix}/SHOW_RADIO_MODAL`;
export const HIDE_RADIO_MODAL = `${prefix}/HIDE_RADIO_MODAL`;
export const HIDE_RADIO_REMOVE_MODAL = `${prefix}/HIDE_RADIO_REMOVE_MODAL`;
export const SHOW_RADIO_REMOVE_MODAL = `${prefix}/SHOW_RADIO_REMOVE_MODAL`;

export const radioModalInitialState = { isRadioModalVisible: false };
export const radioRemoveModalInitialState = { isRadioRemoveModalVisible: false };

export function handleShowRadioModal(state) {
  return { ...state, isRadioModalVisible: true };
}

export function handleHideRadioModal(state) {
  return { ...state, isRadioModalVisible: false };
}

export function handleShowRadioRemoveModal(state) {
  return {
    ...state,
    isConfirmationModalVisible: true,
    modalData: {
      type: jsfcoreModalsTypes.modalTypes.radioRemove,
    },
  };
}

export function handleHideRadioRemoveModal(state) {
  return {
    ...state,
    isConfirmationModalVisible: false,
    modalData: {},
  };
}


export const showRadioModal = () => {
  return { type: SHOW_RADIO_MODAL };
};

export const hideRadioModal = (radio = null) => {
  return { type: HIDE_RADIO_MODAL, radio };
};


export const showRadioRemoveModal = () => {
  return { type: SHOW_RADIO_REMOVE_MODAL };
};

export const hideRadioRemoveModal = (radioRemove = null) => {
  return { type: HIDE_RADIO_REMOVE_MODAL, radioRemove };
};
