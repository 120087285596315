import { createSelector } from 'reselect';

export const getConstructorErrors = (state) => {
  return state.constructorErrors;
};

// "isGlobal" - it's a boolean flag which define visibility of this error under the "Done" button
// false value means that error is local and shouldn't influence on Done button
export const getAllConstructorErrors = createSelector(
  [getConstructorErrors],
  (constructorErrors) => {
    const allConstructorErrors = [];

    Object.values(constructorErrors).forEach(({ errors }) => {
      allConstructorErrors.push(...(errors.filter(({ isGlobal }) => {
        return isGlobal;
      })));
    });

    return allConstructorErrors;
  },
);

const defaultValidatorState = {
  isValid: true,
  errors: [],
};

export const getConstructorErrorsByElement = (state, elementId) => {
  return getConstructorErrors(state)[elementId] || defaultValidatorState;
};
