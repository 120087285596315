import moment from 'moment';
import cloneDeep from 'lodash/cloneDeep';
import mergeWith from 'lodash/mergeWith';
import get from 'lodash/get';

import {
  sendToolOperation,
  getNextLocalId,
  createId,
  createFlatId,
  getElementDefaults,
} from '../Operations/Utils';

import { ATTRIBUTE_NAMES } from '../../../constants';

const sendAttributes = ({ transport, attrsContentForSend, state }) => {
  const { attributes } = state;
  let { sendOperationsCount } = state;

  const newIds = {};
  Object.keys(attrsContentForSend).forEach((attrName) => {
    sendOperationsCount = getNextLocalId(sendOperationsCount);
    const id = createId(sendOperationsCount);

    const oldElementId = get(attributes[attrName], 'element');
    const elementId = oldElementId || createFlatId(id);
    if (!oldElementId) {
      newIds[attrName] = elementId;
    }

    const attrForSend = {
      type: ATTRIBUTE_NAMES.attributes,
      subType: attrName,
      element: elementId,
      content: attrsContentForSend[attrName],
    };
    sendToolOperation(transport, id, attrForSend);
  });

  return { sendOperationsCount, newIds };
};

const applyIds = (attributes, newIds) => {
  return Object.keys(newIds).reduce((result, attrName) => {
    return {
      ...result,
      [attrName]: {
        ...result[attrName],
        element: newIds[attrName],
      },
    };
  }, attributes);
};

export const getAttrsContentForSend = (state, newAttrContent) => {
  const { defaults, attributes } = state;
  const attrsContentForSend = Object.keys(newAttrContent)
    .reduce((acc, attrName) => {
      const defaultContent = getElementDefaults(
        defaults.content,
        ATTRIBUTE_NAMES.attributes,
        attrName,
      );
      const currentAttrContent = get(attributes[attrName], 'content', {});
      const updatedAttrContent = newAttrContent[attrName];

      const isCurrentContentVisible =
        get(currentAttrContent, 'visible', false) === true;

      if (!isCurrentContentVisible) {
        return {
          ...acc,
          [attrName]: {
            ...defaultContent,
            ...currentAttrContent,
            visible: true,
            ...updatedAttrContent,
          },
        };
      }

      return {
        ...acc,
        [attrName]: updatedAttrContent,
      };
    }, {});

  /* https://pdffiller.atlassian.net/browse/JSF-5171
  данный блок if дополняет по смыслу последнюю строчку логики в DatePicker.js,
  ```
    const dateValue = timeUTC
      ? new Date(timeUTC)
      : new Date();
  ```
  и нужен для отправки на сервер текущего timeUTC; иначе, несмотря на
  отображение текущей даты в интерфейсе, на экспорте будет 01.01.1970
   */
  const hasDateContentTimeUTC = get(attributes, 'date.content.timeUTC', false);
  const hasDateForSend = get(attrsContentForSend, 'date');
  const hasDateTimeUTCForSend = get(hasDateForSend, 'timeUTC', false);

  if (
    hasDateForSend &&
    !hasDateTimeUTCForSend &&
    !hasDateContentTimeUTC
  ) {
    attrsContentForSend.date.timeUTC = moment.utc().valueOf();
  }

  return attrsContentForSend;
};

function doUpdateAttributes(transport, state, action) {
  const { newAttrContent, opts } = action;

  // need clone here because 'mergeWith' mutates this object
  const oldAttributes = cloneDeep(state.attributes);

  const attrsContentForSend = getAttrsContentForSend(state, newAttrContent);

  const attributes = mergeWith(
    oldAttributes,
    attrsContentForSend,
    (oldAttrValue, attrContentForSendValue) => {
      if (!attrContentForSendValue) {
        return oldAttrValue;
      }
      if (!oldAttrValue) {
        return { content: attrContentForSendValue };
      }

      return {
        ...oldAttrValue,
        content: {
          ...oldAttrValue.content,
          ...attrContentForSendValue,
        },
      };
    },
  );

  // if action.opts.sendOperation === undefined - we DO send them.
  // Skip send only if we explicitly set it to false and nothing became visible
  // if anytghing became visible - send it anyway, to let backend initialize attrs content
  const isAnythingBecameVisible = Object.keys(attrsContentForSend).findIndex((key) => {
    return (
      get(state.attributes[key], 'content.visible', false) === false &&
      attrsContentForSend[key].visible === true
    );
  }) > -1;

  if (opts.sendOperation === false && !isAnythingBecameVisible) {
    return { attributes };
  }

  const { sendOperationsCount, newIds } =
    sendAttributes({ transport, attrsContentForSend, state });

  return {
    attributes: applyIds(attributes, newIds),
    sendOperationsCount,
  };
}

export default doUpdateAttributes;
