/* eslint-disable import/prefer-default-export */

/**
 * Унифицируем getRef, getRefPromise
 * Фундаментально хэлпер нужен чтобы найти реф в одном из каких-то пропсов
 *
 * @param {...(function|undefined)} args
 * @return {Promise} промис либо с рефом, либо с ошибкой
 *
 * @example
 *
 *   promisifyGetRef(this.props.getRef, this.props.getRefPromise)
 */
export async function promisifyGetRef(...args) {
  for (let i = 0; i < args.length; i++) {
    const fn = args[i];

    if (typeof fn === 'function') {
      const val = fn();

      if (val === undefined) {
        throw new Error('promisifyGetRef: ref should be DOMElement or null');
      }

      return val;
    }
  }

  throw new Error('promisifyGetRef: none of the provided functions returns ref');
}
