import PropTypes from 'prop-types';
import React from 'react';

export const toolbarItemTypes = {
  control: 'control',
  select: 'select',
  customField: 'custom-field',
  rangeSlider: 'range-slider',
  counter: 'counter',
  groupModule: 'group-module',
};

const ToolBarItem = ({ id, type, storeRef, children, ...props }) => {
  return (
    <div
      {...props}
      ref={storeRef}
      className="toolbar__item"
      id={id}
    >
      {children && (
        <div className={`toolbar__${type}`}>
          {children}
        </div>
      )}
    </div>
  );
};

ToolBarItem.propTypes = {
  children: PropTypes.node,
  type: PropTypes.oneOf(
    Object.values(toolbarItemTypes),
  ),
  id: PropTypes.string,

  storeRef: PropTypes.func,
};

ToolBarItem.defaultProps = {
  type: toolbarItemTypes.control,
  id: null,
  children: null,
  storeRef: null,
};

export default ToolBarItem;
