import superagent from 'superagent';
import get from 'lodash/get';
import { select, put, all } from 'redux-saga/effects';
import { setPreview } from 'jsfcore/store/modules/query';
import config from 'jsfcore/helpers/clientConfig';

const showFormPreview = get(config.app, 'formPreview.enabled', false);

export const formPreviewRequest = (body) => {
  return new Promise((resolve) => {
    if (!__CLIENT__ || !showFormPreview) {
      return;
    }
    const { url: formPreviewUrl, appKey: formPreviewAppKey } = config.app.formPreview;

    superagent
      .post(formPreviewUrl)
      .set('appKey', formPreviewAppKey)
      .set('Content-Type', 'application/x-www-form-urlencoded')
      .send(body)
      .then((res) => {
        return resolve(res.body);
      });
  });
};

const getProjectId = (state) => {
  return state.query.projectId;
};

function* takePreview() {
  try {
    const response = yield formPreviewRequest({
      project_id: yield select(getProjectId),
    });
    if (response && response.data) {
      yield put(setPreview(response.data));
    }
  } catch (ex) {
    // eslint-disable-next-line no-console
    console.log('Error loading form preview', ex);
  }
}

export default function* formPreviewRootSaga() {
  yield all([
    takePreview(),
  ]);
}
