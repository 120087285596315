import hasToFlushOperationsNow from './hasToFlushOperationsNow';
import optimizeOperations from './optimizeOperations';

const OPS_TRANSPORT_INTERVAL = 3000;
const MAX_CHUNK_SIZE = 100;

function getOptimizeAndSend(websocketClient, postSendCallback = () => {}) {
  let operationsQueue = [];
  let flushTimeout = null;

  function flushQueue() {
    if (operationsQueue.length > 0) {
      const pkg = { operations: optimizeOperations(operationsQueue) };
      websocketClient.send(pkg);
      postSendCallback(pkg);
    }

    operationsQueue = [];
    clearTimeout(flushTimeout);
    flushTimeout = null;
  }

  function enqueueOperations(operations, config) {
    const operationsSendInterval = config.operationTransportInterval || OPS_TRANSPORT_INTERVAL;
    const maxQueueLength = config.maxChunkSize || MAX_CHUNK_SIZE;
    const isQueueOverflowed = operationsQueue.length > maxQueueLength;
    const forceFlush = hasToFlushOperationsNow(operations);

    operationsQueue = [...operationsQueue, ...operations];

    if (isQueueOverflowed || forceFlush) {
      flushQueue();
      return;
    }

    if (flushTimeout === null) {
      flushTimeout = setTimeout(flushQueue, operationsSendInterval);
    }
  }

  function optimizeAndSend(operations) {
    if (!websocketClient.config) {
      return;
    }

    enqueueOperations(operations, websocketClient.config);
  }

  optimizeAndSend.flush = flushQueue;

  return optimizeAndSend;
}

export default getOptimizeAndSend;
