/* eslint-disable no-param-reassign */

import {
  isInputOrTextarea,
  isContentEditable,
  isDateInput,
} from './focusUtils';

const parseScaleFromTransformString = (transform) => {
  const parsedTransform = transform.match(/scale\((\d+(\.\d)?\d*)\)/);
  if (!parsedTransform) {
    return undefined;
  }

  return parsedTransform[1] || undefined;
};

const empty = () => {};

const initializeHackPosition = ({ thisDevice }) => (node) => {
  if (!thisDevice.isIOS) return empty;

  const inputOrTextarea = isInputOrTextarea(node);
  const contentEditable = isContentEditable(node);
  const dateInput = isDateInput(node);
  const needToHack = (
    inputOrTextarea ||
    contentEditable ||
    // для iPad не нужно менять позицию у поля input[type="date"]
    (dateInput && !thisDevice.isTablet)
  );

  if (!needToHack) return empty;

  /**
   * position: fixed работает относительно window
   * кроме случаев когда position: fixed блок вписан в блок
   * с трансформацией (в помощью которой мы скейлим элементы)
   *
   * Поэтому чтобы механизм hackPosition работал, нужно найти
   * transformBox (блок с трансформацией), узнать его позицию и его скейл
   * (т.к. top: Xpx скейлится) и использовать эти данные для расчета top
   */
  const transformBox = node.closest('.content-Content');
  if (!transformBox) {
    // eslint-disable-next-line no-console
    console.warn('FocusController: transformBox not found');
    return empty;
  }

  const scale = parseScaleFromTransformString(transformBox.style.transform);
  if (scale === undefined) {
    // eslint-disable-next-line no-console
    console.warn('FocusController: scale not found on transformBox');
    return empty;
  }

  const transformBoxTop = transformBox.getBoundingClientRect().top;
  const transformBoxLeft = transformBox.getBoundingClientRect().left;

  node.style.position = 'fixed';
  node.style.top = `${transformBoxTop * -1 / scale}px`;
  node.style.left = `${transformBoxLeft * -1 / scale}px`;

  return () => {
    node.style.position = 'relative';
    node.style.top = 'auto';
    node.style.left = 'auto';
  };
};

export default initializeHackPosition;
