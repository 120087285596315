import clamp from 'lodash/clamp';

import {
  elementMinSize,
  positions,
  defaultMargins,
  textToolFillablePadding,
  amendmentToTheWind,
  defaultLineHeight,
} from './const';
import {
  getTextToolSettings,
} from '../components/Tools/TextTool/utils/textToolUtils';
import { roundTo2 } from './utils';
import { getIsAspectRatioPreserved, elemTypes, elemSubTypes } from './elemTypes';
import isSignNow from './isSignNow';

// common utils

// NOTE: 'export' only for test
export const isLeft = (resizeIndex) => {
  return resizeIndex === positions.topleft ||
    resizeIndex === positions.left ||
    resizeIndex === positions.bottomleft;
};

// NOTE: 'export' only for test
export const isTop = (resizeIndex) => {
  return resizeIndex === positions.topleft ||
    resizeIndex === positions.top ||
    resizeIndex === positions.topright;
};

// NOTE: 'export' only for test
export const isRight = (resizeIndex) => {
  return resizeIndex === positions.topright ||
    resizeIndex === positions.right ||
    resizeIndex === positions.bottomright;
};

// NOTE: 'export' only for test
export const isBottom = (resizeIndex) => {
  return resizeIndex === positions.bottomleft ||
    resizeIndex === positions.bottom ||
    resizeIndex === positions.bottomright;
};

// NOTE: 'export' only for test
export const isStrictLeftRight = (resizeIndex) => {
  return resizeIndex === positions.left || resizeIndex === positions.right;
};

// NOTE: 'export' only for test
export const isStrictTopBottom = (resizeIndex) => {
  return resizeIndex === positions.top || resizeIndex === positions.bottom;
};

// NOTE: 'export' only for test
export const getRatio = (geometry) => {
  return geometry.width / geometry.height;
};

// NOTE: 'export' only for test
export const chooseMaxBounds = (...sizesArg) => {
  const sizes = sizesArg.filter((item) => {
    return item !== false;
  });
  return {
    width: Math.max(...sizes.filter((size) => {
      return size.width !== false;
    }).map((size) => {
      return size.width;
    })),
    height: Math.max(...sizes.filter((size) => {
      return size.height !== false;
    }).map((size) => {
      return size.height;
    })),
  };
};

// NOTE: 'export' only for test
export const chooseMinBounds = (...sizesArg) => {
  const sizes = sizesArg.filter((item) => {
    return item !== false;
  });
  return {
    width: Math.min(...sizes.filter((size) => {
      return size.width !== false;
    }).map((size) => {
      return size.width;
    })),
    height: Math.min(...sizes.filter((size) => {
      return size.height !== false;
    }).map((size) => {
      return size.height;
    })),
  };
};

// get fillable bounds

// NOTE: 'export' only for test
export const FCJsfElementMinSize = {
  [elemTypes.text]: {
    // минимальная ширина поля "Дата" рассчитывается
    // позже в компоненте ElementMinWidthRuler
    [elemSubTypes.text.date]: { width: 0, height: 15 },
    default: { width: 30, height: 15 },
  },
  [elemTypes.checkmark]: {
    default: { width: 6, height: 6 },
  },
  [elemTypes.image]: {
    none: { width: 30, height: 30 },
  },
  [elemTypes.signature]: {
    default: { width: 30, height: 20 },
  },
  [elemTypes.radio]: {
    default: { width: 6, height: 6 },
  },
  default: { width: 15, height: 15 },
};

export const FCSignNowElementMinSize = {
  [elemTypes.text]: {
    // минимальная ширина поля "Дата" рассчитывается
    // позже в компоненте ElementMinWidthRuler
    [elemSubTypes.text.date]: { width: 0, height: 7 },
    [elemSubTypes.text.formula]: { width: 50, height: 10 },
    [elemSubTypes.text.dropdown]: { width: 100, height: 17 },
    [elemSubTypes.none]: { width: 50, height: 10 },
  },
  [elemTypes.checkmark]: {
    [elemSubTypes.checkmark.x]: { width: 17, height: 17 },
  },
  [elemTypes.radio]: {
    [elemSubTypes.none]: { width: 19, height: 19 },
  },
  [elemTypes.signature]: {
    default: { width: 80, height: 16 },
    [elemSubTypes.signature.initials]: { width: 52, height: 17 },
    [elemSubTypes.none]: { width: 80, height: 16 },
  },
  [elemTypes.attachment]: {
    [elemSubTypes.none]: { width: 50, height: 17 },
  },
};

export const FCElementMinSize = isSignNow()
  ? FCSignNowElementMinSize
  : FCJsfElementMinSize;

const FCJsfElementMaxSize = {
  [elemTypes.checkmark]: {
    default: { width: 100, height: 100 },
  },
};

const FCSignNowElementMaxSize = {
  [elemTypes.radio]: {
    [elemSubTypes.none]: { width: 100, height: 100 },
  },
  [elemTypes.checkmark]: {
    default: { width: 100, height: 100 },
  },
  [elemTypes.text]: {
    [elemSubTypes.text.dropdown]: { width: false, height: 17 },
    default: { width: false, height: false },
  },
};

const FCElementMaxSize = isSignNow()
  ? FCSignNowElementMaxSize
  : FCJsfElementMaxSize;

export const getFillableMinHeightByFontSize = (fontSize, lineHeight = defaultLineHeight) => {
  if (!fontSize) {
    return false;
  }

  return (fontSize * lineHeight) + (textToolFillablePadding * 2) + amendmentToTheWind;
};

// NOTE: 'export' only for test
export const getFillableSizeBounds = (element) => {
  const { type, subType, template: { fontSize, lineHeight } } = element;
  const minHeight = getFillableMinHeightByFontSize(fontSize, lineHeight);
  const minSize = FCElementMinSize[type]
    ? FCElementMinSize[type][subType] || FCElementMinSize[type].default
    : FCElementMinSize.default;

  return {
    min: {
      ...minSize,
      ...(minHeight
        ? { height: minHeight }
        : {}),
    },
    max: (
      FCElementMaxSize[type] &&
      (
        FCElementMaxSize[type][subType] ||
        FCElementMaxSize[type].default
      )
    ) || false,
  };
};

const simpleSignNowElementMinSize = {
  [elemTypes.checkmark]: { width: 17, height: 17 },
  [elemTypes.signature]: { width: 100, height: false },
};

const simpleSignNowElementMaxSize = {
  [elemTypes.checkmark]: { width: 100, height: 100 },
};

export const getSignNowSizeBounds = (element) => {
  return {
    min: simpleSignNowElementMinSize[element.type] || false,
    max: simpleSignNowElementMaxSize[element.type] || false,
  };
};

// get max size by frame

// NOTE: 'export' only for test
export const getMaxWidthByFrame = ({ resizeIndex, originalSize, initialGeometry }) => {
  const { x, width } = initialGeometry;
  if (isRight(resizeIndex)) {
    return originalSize.width - x;
  }
  if (isLeft(resizeIndex)) {
    return x + width;
  }
  return Math.min(
    width + (x * 2),
    width + ((originalSize.width - x - width) * 2),
  );
};

// NOTE: 'export' only for test
export const getMaxHeightByFrame = ({ resizeIndex, originalSize, initialGeometry }) => {
  const { y, height } = initialGeometry;
  if (isBottom(resizeIndex)) {
    return originalSize.height - y;
  }
  if (isTop(resizeIndex)) {
    return y + height;
  }
  return Math.min(
    height + (y * 2),
    height + ((originalSize.height - y - height) * 2),
  );
};

// NOTE: 'export' only for test
export const fixSizeBoundsWithRatio = ({ sizeBounds, ratio }) => {
  const min = sizeBounds.min.width > sizeBounds.min.height * ratio
    ? {
      width: sizeBounds.min.width,
      height: sizeBounds.min.width / ratio,
    }
    : {
      width: sizeBounds.min.height * ratio,
      height: sizeBounds.min.height,
    };

  const max = sizeBounds.max.width < sizeBounds.max.height * ratio
    ? {
      width: sizeBounds.max.width,
      height: sizeBounds.max.width / ratio,
    }
    : {
      width: sizeBounds.max.height * ratio,
      height: sizeBounds.max.height,
    };

  return { min, max };
};

const reduceBoundsByMargins = ({ sizeBounds, resizeIndex, margins }) => {
  const max = {
    ...sizeBounds.max,
    ...(isRight(resizeIndex)
      ? {
        width: sizeBounds.max.width - margins.marginLeft - margins.marginRight,
      }
      : {}),

    ...(isBottom(resizeIndex)
      ? {
        height: sizeBounds.max.height - margins.marginTop - margins.marginBottom,
      }
      : {}),
  };
  return {
    min: sizeBounds.min,
    max,
  };
};

// функция устанавливает минимально или максимальное возможные значения width height,
// Для филлабл-полей:
// максимальное значение width, height есть только у checkmark, минимальное есть
// у всех элементов.
// Для не-филлабл полей: минимальное - elementMinSize (так было в коде, возможно следует
// переименовать эту константу)
// Максимальный размер ограничен размерами холста (а у филлабл-чекмарок - еще и свой максимум)
// NOTE: 'export' only for test
export const getSizeBounds = ({
  resizeIndex, originalSize, initialGeometry,
  isFillable, isRatioPreserved, element,
}) => {
  const margins = getTextToolSettings(element) || defaultMargins;

  const maxSizeByFrame = {
    width: getMaxWidthByFrame({ resizeIndex, originalSize, initialGeometry }),
    height: getMaxHeightByFrame({ resizeIndex, originalSize, initialGeometry }),
  };

  const commonBounds = {
    min: elementMinSize,
    max: maxSizeByFrame,
  };
  const ratio = getRatio(initialGeometry);

  const simpleBounds = isSignNow() && !isFillable
    ? getSignNowSizeBounds(element)
    : { min: false, max: false };

  const fillableBounds = isFillable
    ? getFillableSizeBounds(element)
    : { min: false, max: false };

  const boundsNoRatio = {
    min: chooseMaxBounds(fillableBounds.min, commonBounds.min, simpleBounds.min),
    max: chooseMinBounds(fillableBounds.max, commonBounds.max, simpleBounds.max),
  };

  // JSF-2924: если у нас уже нарисован элемент, у которого размер меньше минимального или
  // больше максимального - то растянем наши границы до этого размера, чтобы не было дерганий
  const boundsWithInitial = {
    min: chooseMinBounds(boundsNoRatio.min, initialGeometry),
    max: chooseMaxBounds(boundsNoRatio.max, initialGeometry),
  };

  // _maybe_ with ratio
  const boundsWithRatio = isRatioPreserved
    ? fixSizeBoundsWithRatio({ sizeBounds: boundsWithInitial, ratio })
    : boundsWithInitial;

  return reduceBoundsByMargins({ sizeBounds: boundsWithRatio, resizeIndex, margins });
};

// NOTE: 'export' only for test
export const applyDPos = ({ resizeIndex, initialGeometry, dPos }) => {
  const { x, y, width, height } = initialGeometry;
  return {
    ...initialGeometry,

    ...(isLeft(resizeIndex)
      ? {
        x: x + dPos.x,
        width: width - dPos.x,
      }
      : {}),

    ...(isTop(resizeIndex)
      ? {
        y: y + dPos.y,
        height: height - dPos.y,
      }
      : {}),

    ...(isRight(resizeIndex)
      ? {
        width: width + dPos.x,
      }
      : {}),

    ...(isBottom(resizeIndex)
      ? {
        height: height + dPos.y,
      }
      : {}),
  };
};

// NOTE: 'export' only for test
export const applyRatio = ({ resizeIndex, geometry, initialGeometry }) => {
  const ratio = getRatio(initialGeometry);
  const dWidth = initialGeometry.width - geometry.width;
  const dHeight = initialGeometry.height - geometry.height;

  if (isStrictTopBottom(resizeIndex)) {
    return {
      ...geometry,
      x: initialGeometry.x + ((dHeight * ratio) / 2),
      width: geometry.height * ratio,
    };
  }

  if (isStrictLeftRight(resizeIndex)) {
    return {
      ...geometry,
      y: initialGeometry.y + (dWidth / ratio / 2),
      height: geometry.width / ratio,
    };
  }

  const isWidthCorrect = geometry.width > geometry.height * ratio;
  const { x, y, width, height } = geometry;
  return {
    width: isWidthCorrect
      ? width
      : height * ratio,

    height: !isWidthCorrect
      ? height
      : width / ratio,

    x: !isWidthCorrect && isLeft(resizeIndex)
      ? initialGeometry.x + (dHeight * ratio)
      : x,

    y: isWidthCorrect && isTop(resizeIndex)
      ? initialGeometry.y + (dWidth / ratio)
      : y,
  };
};

export const applyBounds = ({ geometry, sizeBounds, resizeIndex }) => {
  const boundedSize = {
    width: clamp(geometry.width, sizeBounds.min.width, sizeBounds.max.width),
    height: clamp(geometry.height, sizeBounds.min.height, sizeBounds.max.height),
  };

  return {
    ...geometry,
    ...boundedSize,
    ...(isLeft(resizeIndex)
      ? {
        x: geometry.x + (geometry.width - boundedSize.width),
      }
      : {}),

    ...(isTop(resizeIndex)
      ? {
        y: geometry.y + (geometry.height - boundedSize.height),
      }
      : {}),
  };
};

export const getRoundedGeometry = (geometry) => {
  return {
    x: roundTo2(geometry.x),
    y: roundTo2(geometry.y),
    width: roundTo2(geometry.width),
    height: roundTo2(geometry.height),
  };
};

/*
 * MAIN. Entry point here
 */
export const getResizeGeometry =
  ({ resizeIndex, isFillable, element, originalSize, initialGeometry, dPos }) => {
    const isRatioPreserved =
      getIsAspectRatioPreserved(element.type, isFillable);

    const sizeBounds = getSizeBounds(
      { resizeIndex, originalSize, initialGeometry, isRatioPreserved, isFillable, element },
    );
    const geometry = applyDPos({ resizeIndex, initialGeometry, dPos });
    const boundedGeometry = applyBounds({ geometry, sizeBounds, resizeIndex });

    const fixedGeometry = isRatioPreserved
      ? applyRatio({ initialGeometry, geometry: boundedGeometry, resizeIndex })
      : boundedGeometry;

    return getRoundedGeometry(fixedGeometry);
  };
