import omitBy from 'lodash/omitBy';
import pickBy from 'lodash/pickBy';
import flow from 'lodash/flow';
import get from 'lodash/get';

import {
  createId,
  getNextLocalId,
  updateElement,
  sendToolElement,
  isEmptyFillableElement,
  fillFillable,
  isFilledFillableElement,
  clearFillable,
  getElementGroupNames,
  getIsGalleryOrSignature,
} from '../Operations/Utils';

import isSignNow from '../../../isSignNow';

const ignoreContentMap = {
  text: ['width', 'height', 'text', 'hyperlink', 'contentX', 'contentY'],
  line: ['width', 'height', 'hyperlink', 'controlPoints'],
  arrow: ['width', 'height', 'hyperlink', 'controlPoints'],
  pen: ['width', 'height', 'hyperlink', 'controlPoints'],
  erase: ['width', 'height', 'hyperlink', 'controlPoints'],
  highlight: ['width', 'height', 'hyperlink', 'controlPoints'],
  blackout: ['width', 'height', 'hyperlink', 'controlPoints'],
  checkmark: ['hyperlink'],
  signature: ['hyperlink'],
  image: ['hyperlink'],
};

export function filterContentForGhost(activeElement, content) {
  if (ignoreContentMap[activeElement.type]) {
    return omitBy(content, (val, key) => {
      return ignoreContentMap[activeElement.type].indexOf(key) !== -1;
    });
  }

  return content;
}

const filterContentForFillableGhostJSF = (activeElement, content) => {
  return pickBy(content, (val, key) => {
    return key === 'width' || key === 'height';
  });
};

const filterContentForFillableGhostSNF = (activeElement, content) => {
  return pickBy(content, (val, key) => {
    return (
      key === 'width' ||
      key === 'height' ||
      key === 'fontColor' ||
      key === 'fontSize' ||
      key === 'fontFamily' ||
      key === 'bold' ||
      key === 'italic' ||
      key === 'underline' ||
      key === 'align' ||
      key === 'required'
    );
  });
};

export const filterContentForFillableGhost = isSignNow()
  ? filterContentForFillableGhostSNF
  : filterContentForFillableGhostJSF;

const addOptionalInitialFlagToElement = (element) => {
  const isGalleryOrSignature = getIsGalleryOrSignature(element);

  return isGalleryOrSignature
    ? element
    : { ...element, initial: true };
};

export const fillEmptyFillableElementById = (transport, id, state) => {
  const elementToActivate = state.elementsMap[id];

  if (
    isEmptyFillableElement(elementToActivate) ||
    get(elementToActivate, 'isNeedToBeFilledWithTemplate', false)
  ) {
    const filledElement = fillFillable(elementToActivate);
    const {
      elements,
      elementsMap,
    } = updateElement(filledElement, state);

    const elementWithOptionalInitialFlag = addOptionalInitialFlagToElement(filledElement);
    const sendOperationsCount = getNextLocalId(state.sendOperationsCount);
    sendToolElement(transport, createId(sendOperationsCount), elementWithOptionalInitialFlag);

    return {
      ...state,
      elements,
      elementsMap,
      sendOperationsCount,
    };
  }

  return state;
};

export const fillFlatGroupEmptyElements = (transport, state, element) => {
  const { flat: flatName } = getElementGroupNames(element);
  if (flatName) {
    const flatGroup = state.fillableGroups.flat[flatName];
    if (flatGroup && flatGroup.length > 1) {
      const idsOfOtherElementsOfFlatGroup = flatGroup.filter(
        (elId) => {
          return elId !== element.id;
        },
      );

      const stateWithFilledElement =
        idsOfOtherElementsOfFlatGroup.reduce(((accState, elId) => {
          return fillEmptyFillableElementById(transport, elId, accState);
        }), state);
      return stateWithFilledElement;
    }
  }

  return state;
};

export const fillRadioGroupEmptyElements = (transport, state, element) => {
  const { radio: radioName } = getElementGroupNames(element);
  if (radioName) {
    const radioGroup = state.fillableGroups.radio[radioName];
    if (radioGroup && radioGroup.length > 1) {
      const idsOfOtherElementsOfRadioGroup = radioGroup.filter(
        (elId) => {
          return elId !== element.id;
        },
      );

      const stateWithFilledElement =
        idsOfOtherElementsOfRadioGroup.reduce(((accState, elId) => {
          return fillEmptyFillableElementById(transport, elId, accState);
        }), state);
      return stateWithFilledElement;
    }
  }

  return state;
};


export const removeFilledFillableElementById = (transport, id, state) => {
  const elementToActivate = state.elementsMap[id];
  if (!isFilledFillableElement(elementToActivate)) {
    return state;
  }

  const removedElement = clearFillable(elementToActivate);
  const {
    elements,
    elementsMap,
  } = updateElement(removedElement, state);

  const sendOperationsCount = getNextLocalId(state.sendOperationsCount);
  sendToolElement(transport, createId(sendOperationsCount), removedElement);

  return {
    ...state,
    elements,
    elementsMap,
    sendOperationsCount,
  };
};

export const removeRadioGroupFilledElements = (transport, state, element) => {
  const { radio: radioName } = getElementGroupNames(element);
  if (radioName) {
    const radioGroup = state.fillableGroups.radio[radioName];
    if (radioGroup && radioGroup.length > 1) {
      const idsOfOtherElementsOfRadioGroup = radioGroup.filter(
        (elId) => {
          return elId !== element.id;
        },
      );

      const stateWithRemovedElement =
        idsOfOtherElementsOfRadioGroup.reduce(((accState, elId) => {
          return removeFilledFillableElementById(transport, elId, accState);
        }), state);
      return stateWithRemovedElement;
    }
  }

  return state;
};

export function doFillFillableElementWithGroup(transport, elementToFill, state) {
  // Если мы запускаем механизм fill empty fillable element
  // для одного элемента радио или флет группы, то должны запустить его
  // и для всех остальных:
  // https://pdffiller.atlassian.net/browse/JSF-1880
  // https://pdffiller.atlassian.net/browse/JSF-2080
  return flow(
    (accState) => {
      return fillEmptyFillableElementById(transport, elementToFill.id, accState);
    },
    (accState) => {
      return fillFlatGroupEmptyElements(transport, accState, elementToFill);
    },
    (accState) => {
      return fillRadioGroupEmptyElements(transport, accState, elementToFill);
    },
  )(state);
}
