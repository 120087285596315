import omitBy from 'lodash/omitBy';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import Portal from '../Portal';
import DetectScrollProvider from '../LegacyDetectScrollProvider';
import { computeOffsetsWithinElement } from '../helpers/portalUtils';
import { possiblePositions } from '../helpers/const';
import { presetsNames } from '../helpers/presets';

import * as Popover from '../../../ui/Popover';
import {
  popoverArrowPositions,
  popoverThemes,
  popoverOffsets,
} from '../../../ui/Popover/Popover';
import isSignNow from '../../../helpers/const/isSignNow';

const initialPosition = { left: -10000, top: -10000 };

export default class PortalWrapper extends PureComponent {
  static propTypes = {
    children: PropTypes.node.isRequired,
    getRef: PropTypes.func.isRequired,

    locatorArgs: PropTypes.shape({
      position: PropTypes.oneOf(Object.values(possiblePositions)),
      presets: PropTypes.oneOfType([
        PropTypes.oneOf(Object.values(presetsNames)),
        PropTypes.shape({
          postMeasure: PropTypes.func,
          postCalculation: PropTypes.func,
          postClamping: PropTypes.func,
        }),
      ]),
      smartPosition: PropTypes.bool,
    }),

    storeRef: PropTypes.func,
    onClick: PropTypes.func,

    isAnchorNodeInFocus: PropTypes.bool,
    useTriggerWidth: PropTypes.bool,
    useTriggerHeight: PropTypes.bool,
    closeOnAnchorNodeScroll: PropTypes.bool,

    width: PropTypes.oneOfType([
      PropTypes.number, PropTypes.string,
    ]),
    minWidth: PropTypes.oneOfType([
      PropTypes.number, PropTypes.string,
    ]),
    maxWidth: PropTypes.oneOfType([
      PropTypes.number, PropTypes.string,
    ]),
    height: PropTypes.oneOfType([
      PropTypes.number, PropTypes.string,
    ]),

    useArrow: PropTypes.bool,
    arrowPosition: PropTypes.oneOf(
      Object.values(popoverArrowPositions),
    ),
    theme: PropTypes.oneOf(
      Object.values(popoverThemes),
    ),
    offset: PropTypes.oneOf(
      Object.values(popoverOffsets),
    ),
    className: PropTypes.string,
    zIndex: PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.string,
    ]),
    getViewportNode: PropTypes.func,
    hideIfAnchorOutOfViewport: PropTypes.bool,
    disableClickOutside: PropTypes.bool,
    aboveModals: PropTypes.bool,
  };

  static defaultProps = {
    useArrow: true,
    useTriggerWidth: false,
    useTriggerHeight: false,
    closeOnAnchorNodeScroll: false,
    isAnchorNodeInFocus: true,
    className: '',
    locatorArgs: {
      position: possiblePositions.bottomCenter,
    },
    width: 'initial',
    height: 'initial',
    minWidth: 'initial',
    maxWidth: 'initial',

    theme: popoverThemes.primary,
    arrowPosition: popoverArrowPositions.null,
    offset: popoverOffsets.null,
    zIndex: 'initial',

    storeRef: null,
    onClick: null,

    getViewportNode:
      __CLIENT__
        ? () => {
          return document.body;
        }
        : null,
    hideIfAnchorOutOfViewport: false,
    disableClickOutside: false,
    aboveModals: false,
  };

  constructor(props) {
    super(props);
    this.blockRef = null;
    this.state = {
      isPopoverVisible: true,
      position: initialPosition,
      width: props.width,
      height: props.height,
      minWidth: props.minWidth,
      maxWidth: props.maxWidth,
      isVisible: true,
    };
  }

  componentDidMount() {
    this.preprocessNode();
  }

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillReceiveProps({ isAnchorNodeInFocus }) {
    if (
      this.props.closeOnAnchorNodeScroll &&
      this.props.isAnchorNodeInFocus !== isAnchorNodeInFocus
    ) {
      this.setState({
        isPopoverVisible: isAnchorNodeInFocus,
      });
    }
  }

  componentDidUpdate() {
    this.preprocessNode();
  }

  getArrowPositionToPass() {
    const { arrowPosition: arrowPassedByProps, useArrow } = this.props;
    const { arrowPosition: calculatedArrowByPosition } = this.state;

    if (!useArrow) {
      return null;
    }

    return arrowPassedByProps !== null
      ? arrowPassedByProps
      : calculatedArrowByPosition;
  }

  updatePositionIfNeed = () => {
    const { locatorArgs, hideIfAnchorOutOfViewport } = this.props;
    const anchor = this.props.getRef();
    const viewport = this.props.getViewportNode();
    const element = this.blockRef;

    if (anchor === null || element === null || viewport === null) {
      return;
    }

    const { left, top, isVisible, arrowPosition } = computeOffsetsWithinElement({
      viewport,
      anchor,
      element,
      hideIfAnchorOutOfViewport,
      ...locatorArgs,
    });

    const { position: { left: oldLeft, top: oldTop }, isVisible: oldIsVisible } = this.state;
    const areComputedValuesValid = !Number.isNaN(left) && !Number.isNaN(top);
    const didSomeValuesChangedAfterComputing = (
      left !== oldLeft ||
      top !== oldTop ||
      isVisible !== oldIsVisible
    );

    if (areComputedValuesValid && didSomeValuesChangedAfterComputing) {
      this.setState({
        position: { left, top },
        isVisible,
        arrowPosition,
      });
    }
  };

  preprocessNode() {
    const newStateObj = {};
    const target = this.props.getRef();
    if (this.props.useTriggerWidth) {
      newStateObj.minWidth = target.offsetWidth;
    }
    if (this.props.useTriggerHeight) {
      newStateObj.height = target.offsetHeight;
    }
    if (
      newStateObj.minWidth !== this.state.minWidth ||
      newStateObj.height !== this.state.height
    ) {
      this.setState(newStateObj, this.updatePositionIfNeed);
    } else {
      this.updatePositionIfNeed();
    }
  }

  storeBlockRef = (ref) => {
    this.blockRef = ref;
    if (this.props.storeRef) {
      this.props.storeRef(ref);
    }
  };

  composeStyles() {
    return omitBy(
      {
        left: this.state.position.left,
        top: this.state.position.top,
        width: this.state.width,
        height: this.state.height,
        minWidth: this.state.minWidth,
        maxWidth: this.state.maxWidth,
        position: 'absolute',
        zIndex: this.props.zIndex,
        display: this.state.isVisible
          ? 'block'
          : 'none',
      },
      (val) => {
        return val === 'initial';
      },
    );
  }

  // TODO: https://pdffiller.atlassian.net/browse/JSF-5435
  // refactor all of toggling visibility logic
  onAnchorNodeScroll = () => {
    const { closeOnAnchorNodeScroll } = this.props;

    if (closeOnAnchorNodeScroll) {
      if (this.state.isPopoverVisible) {
        const anchorNode = this.props.getRef();

        if (anchorNode.contains(document.activeElement)) {
          document.activeElement.blur();
        }

        this.setState({
          isPopoverVisible: false,
        });
      }
    } else if (isSignNow()) {
      this.updatePositionIfNeed();
    }
  };

  render() {
    const {
      theme,
      className,
      offset,
      closeOnAnchorNodeScroll,
      disableClickOutside,
      aboveModals,
    } = this.props;
    const styles = this.composeStyles();

    if (this.state.isPopoverVisible) {
      return (
        <Portal>
          <Popover.Index
            storeRef={this.storeBlockRef}
            onClick={this.props.onClick}
            style={styles}
            className={className}
            theme={theme}
            offset={offset}
            arrowPosition={this.getArrowPositionToPass()}
            disableClickOutside={disableClickOutside}
            aboveModals={aboveModals}
          >
            <Popover.Body>
              {this.props.children}
            </Popover.Body>
          </Popover.Index>
          {(closeOnAnchorNodeScroll || isSignNow()) && (
            <DetectScrollProvider
              getRef={this.props.getRef}
              onScroll={this.onAnchorNodeScroll}
            />
          )}
        </Portal>
      );
    }
    return null;
  }
}
