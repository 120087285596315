import React from 'react';
import PropTypes from 'prop-types';

const SortableElementGroup = ({ children }) => {
  return (
    <div className="sortable-element__group">
      {children}
    </div>
  );
};

SortableElementGroup.propTypes = {
  children: PropTypes.node.isRequired,
};

export default SortableElementGroup;
