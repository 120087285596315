import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

export const thumbnailBodyThemes = {
  primary: 'primary',
  default: 'default',
  null: null,
};

// TODO: https://pdffiller.atlassian.net/browse/JSF-7220
// Объединить компоненты в один PageThumbnailBodyMain и PageThumbnailBody,
// PageThumbnailContentMain и PageThumbnailContent.

const PageThumbnailBodyMain = ({
  theme,
  thumbnail,
  isActivePage,
  pageId,
  onClick,
  children,
  tabIndex,
  storeRef,
}) => {
  return (
    <div
      key={pageId}
      tabIndex={tabIndex}
      className={classnames(
        'page-thumbnail',
        'page-thumbnail--theme--default',
        {
          [`list--theme--${theme}`]: theme,
          'is-loading': !thumbnail,
          'is-selected': isActivePage,
        },
      )}
    >
      <div
        ref={storeRef}
        className="page-thumbnail__body"
        onClick={onClick}
      >
        {children}
      </div>
    </div>
  );
};

PageThumbnailBodyMain.propTypes = {
  theme: PropTypes.oneOf(Object.values(thumbnailBodyThemes)),
  isActivePage: PropTypes.bool,
  pageId: PropTypes.number,
  storeRef: PropTypes.func,
  onClick: PropTypes.func,
  children: PropTypes.node,
  thumbnail: PropTypes.number,
  tabIndex: PropTypes.number,
};

PageThumbnailBodyMain.defaultProps = {
  tabIndex: 0,
  theme: null,
  isActivePage: false,
  pageId: null,
  storeRef: null,
  onClick: null,
  children: null,
  thumbnail: null,
};

export default PageThumbnailBodyMain;
