export const getIsRadioModalVisible = (state) => {
  return state.modals.isRadioModalVisible;
};

export const getIsRadioRemoveModalVisible = (state) => {
  return state.modals.isRadioRemoveModalVisible;
};

export const getIsConfirmationModalVisible = (state) => {
  return state.modals.isConfirmationModalVisible;
};

export const getIsWarningModalVisible = (state) => {
  return state.modals.isWarningModalVisible;
};

export const getModalsData = (state) => {
  return state.modals.modalData;
};

export const getConfirmationType = (state) => {
  return getModalsData(state).type;
};

export const getWarningType = (state) => {
  return getModalsData(state).type;
};

export const getWarningOpts = (state) => {
  return getModalsData(state).opts;
};

export const getIsPaymentRequestModalVisible = (state) => {
  return state.modals.isPaymentRequestModalVisible;
};

export const getIsMakePaymentModalVisible = (state) => {
  return state.modals.isMakePaymentModalVisible;
};

export const getIsRemoveRolesVisible = (state) => {
  return state.modals.isRemoveRolesVisible;
};

export const getIsAttachmentPreviewModalVisible = (state) => {
  return state.modals.isAttachmentPreviewModalVisible;
};

export const getIsDeclineToSignVisible = (state) => {
  return state.modals.isDeclineToSignVisible;
};

export const getElementId = (state) => {
  return getModalsData(state).elementId;
};

export const getIsCalculatedModalVisible = (state) => {
  return state.modals.isCalculatedModalVisible;
};

export const getCalculatedModalOpts = (state) => {
  return getModalsData(state);
};

export const getIsPreviewModalVisible = (state) => {
  return state.modals.isPreviewModalVisible;
};

export const getIsShortCutsModalVisible = (state) => {
  return state.modals.isShortCutsModalVisible;
};

export const geIsUnlockDocumentModalVisible = (state) => {
  return state.modals.isUnlockDocumentModalVisible;
};

export const getIsSuccessfullySignedModalVisible = (state) => {
  return state.modals.isSuccessfullySignedModalVisible;
};

export const getIsForwardInviteModalVisible = (state) => {
  return state.modals.isForwardInviteModalVisible;
};

export const getIsAnyModalOpened = (state) => {
  return Object.values(state.modals).some((modalState) => {
    return modalState === true;
  });
};

export const getIsBurgerMenuModalVisible = (state) => {
  return state.modals.isBurgerMenuModalVisible;
};

export const getIsViewSignersVisible = (state) => {
  return state.modals.isViewSignersVisible;
};

export const getIsInviteExpiredVisible = (state) => {
  return state.modals.isInviteExpiredVisible;
};

export const getIsVerifyBankAccountModalVisible = (state) => {
  return state.modals.isVerifyBankAccountModalVisible;
};

export const getIsCrossRoleConditionalFieldsModalVisible = (state) => {
  return state.modals.isCrossRoleConditionalFieldsModalVisible;
};

export const getIsCannotEditSigningOrderModalVisible = (state) => {
  return state.modals.isCannotEditSigningOrderModalVisible;
};

export const getIsDocumentGroupInviteStatusVisible = (state) => {
  return state.modals.isDocumentGroupInviteStatusVisible;
};
