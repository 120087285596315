import React from 'react';
import PropTypes from 'prop-types';

const TabsItem = ({
  onMouseEnter,
  onMouseLeave,
  storeRef,
  children,
  attributes,
}) => {
  return (
    <div
      className="tabs__item"
      ref={storeRef}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      {...attributes}
    >
      {children}
    </div>
  );
};

TabsItem.propTypes = {
  attributes: PropTypes.shape({}).isRequired,
  onMouseEnter: PropTypes.func.isRequired,
  onMouseLeave: PropTypes.func.isRequired,
  storeRef: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
};

export default TabsItem;
