import {
  setActiveElement as setActiveElementWs,
  removeElement,
  removeElements,
} from 'ws-editor-lib/actions';
import editorModes from 'ws-editor-lib/constants/editorModes';
import { cancellableOpts } from '../modules/undoRedo';
import * as selectors from '../selectors';
import { isGraphicTextType, elemTypes } from '../../helpers/elemTypes';
import { resetPopup } from '../modules/viewport';
import { popupStatuses } from '../../helpers/const';

import {
  isFillable,
  isFormulaElement,
  isLinkedElement,
} from '../helpers/functions';

import {
  updateElementWithBeforeSendProcessing,
  updateElementWithBeforeUpdateProcessing,
} from './updateElement';

/**
 * We're should reset opened popup on any change of activeElement.
 * Do this here, because we're should hide popup at once
 * for correct date flow.
 */
const resetPopupOnActiveElementChange = (dispatch, getState) => {
  const popupVisibility = selectors.base.getSettingsPopupVisibility(getState());

  if (popupVisibility !== popupStatuses.hidden) {
    dispatch(resetPopup());
  }
};

export const getShouldActivateAndPreprocessElement = ({
  activeElementId,
  newId,
  activeStatus,
}) => {
  const isActivatingOther = activeStatus === true && newId !== activeElementId;
  const isDeactivating = activeStatus === false && activeElementId && newId === activeElementId;

  return isDeactivating || isActivatingOther;
};

/**
 * If we are deactivating (or activation other) simple empty text element
 * we should remove it
 *
 * (this is not relevant for sticky, textbox)
 */
const removeSimpleEmptyTextElements = (dispatch, getState, id, activeStatus) => {
  const activeElement = selectors.elements.getActiveElement(getState());

  if (!activeElement) {
    return;
  }

  const shouldActivateAndPreprocessElement = getShouldActivateAndPreprocessElement({
    activeElementId: activeElement.id,
    newId: id,
    activeStatus,
  });

  if (shouldActivateAndPreprocessElement) {
    if (isFillable(activeElement)) {
      return;
    }

    // skip sticky, textbox
    if (isGraphicTextType(activeElement.type)) {
      return;
    }

    // skip non-empty
    if (activeElement.content && activeElement.content.text !== '') {
      return;
    }

    if (!isLinkedElement(activeElement)) {
      dispatch(removeElement(activeElement.id, cancellableOpts));
    } else {
      dispatch(removeElements([
        activeElement.id,
        activeElement.content.linkedEraseId,
      ], cancellableOpts));
    }
  }
};

const getIsPendingEditorMode = (state) => {
  const mode = selectors.base.getMode(state);

  return mode === editorModes.pending;
};

const onSetActiveElementConstructor = ({ activeElement, dispatch, opts }) => {
  /* Update content for checkmark and text
    * As they need updated content.checked or content.text
    * for defaultValue logic
  */
  const isText = activeElement.type === elemTypes.text;
  const isCheckmark = activeElement.type === elemTypes.checkmark;
  if (
    // formula elements doesn't contain default values
    !isFormulaElement(activeElement) &&
    (isCheckmark || isText)
  ) {
    updateElementWithBeforeSendProcessing(activeElement, dispatch, opts);
  }

  const isImage = activeElement.type === elemTypes.image;
  const isSignature = activeElement.type === elemTypes.signature;
  const isShouldContainCroppedSize = isImage || isSignature;

  if (isShouldContainCroppedSize) {
    updateElementWithBeforeSendProcessing(activeElement, dispatch, opts);
  }
};

const preprocessPrevElementBeforeSendIfNeeded = ({
  element,
  opts,
  dispatch,
}) => {
  if (
    element &&
    !isFormulaElement(element) &&
    // После очистки элемента никакой апдейт не нужен
    element.content !== undefined
  ) {
    updateElementWithBeforeSendProcessing(element, dispatch, opts);
  }
};

const preprocessElementBeforeUpdateIfNeeded = ({
  element,
  opts,
  dispatch,
  activeStatus,
}) => {
  if (element && activeStatus === true) {
    updateElementWithBeforeUpdateProcessing(element, dispatch, opts);
  }
};

const setActiveElement = (id, activeStatus, opts = {}) => {
  return (dispatch, getState) => {
    const state = getState();
    const isPendingEditorMode = getIsPendingEditorMode(state);

    if (!opts.fromRemove && !opts.fromUndoRedo) {
      removeSimpleEmptyTextElements(dispatch, getState, id, activeStatus);
    }

    resetPopupOnActiveElementChange(dispatch, getState, id, activeStatus);

    // если мы активировали pending-мод не посредством получения операции с
    // бэка (ON_OPERATIONS_EDITOR_MODE), а вручную положили в state и только
    // после этого вызвали экшн ACTIVATE_MODE (с "pending"), то в этот момент
    // мы можем находиться в моменте переключения между полями и тогда следующее
    // поле не должно быть активировано
    if (isPendingEditorMode && activeStatus) {
      return;
    }

    const prevActiveElementId = selectors.base.getActiveElementId(state);
    const activeElement = selectors.elements.getElementByIdFactory(id)(state);

    const shouldActivateAndPreprocessElement = getShouldActivateAndPreprocessElement({
      activeElementId: prevActiveElementId,
      newId: id,
      activeStatus,
    });

    if (shouldActivateAndPreprocessElement) {
      dispatch(setActiveElementWs(id, activeStatus, opts));

      if (activeElement && activeStatus !== false) {
        const isFConstructorShown = selectors.base.getIsFConstructorShown(state);
        const isFConstructorPreviewShown = selectors.base.getIsFConstructorPreviewShown(state);

        if (
          isFConstructorShown &&
          !isFConstructorPreviewShown &&
          isFillable(activeElement)
        ) {
          onSetActiveElementConstructor({ activeElement, dispatch, opts });
          return;
        }
      }

      const prevActiveElement =
        selectors.elements.getElementByIdFactory(prevActiveElementId)(state);

      if (!opts.shouldNotPreprocess) {
        const preprocessArgs = { dispatch, opts };

        preprocessPrevElementBeforeSendIfNeeded({
          ...preprocessArgs,
          element: prevActiveElement,
        });

        preprocessElementBeforeUpdateIfNeeded({
          ...preprocessArgs,
          activeStatus,
          element: activeElement,
        });
      }
    }
  };
};

export default setActiveElement;
