import {
  activateTool,
} from 'ws-editor-lib/actions';
import * as selectors from '../selectors';

const activateDefaultTool = () => {
  return (dispatch, getState) => {
    const state = getState();
    const activeTool = selectors.base.getActiveTool(state);
    const isConstructor = selectors.mode.isConstructor(state);
    const defaultTool = isConstructor
      ? selectors.getConstructorDefaultTool(state)
      : selectors.getDefaultTool(state);

    if (
      activeTool.type !== defaultTool.type ||
      activeTool.subType !== defaultTool.subType
    ) {
      dispatch(activateTool(defaultTool.type, defaultTool.subType));
    }
  };
};

export default activateDefaultTool;
