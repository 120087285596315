import {
  put,
} from 'redux-saga/effects';
import {
  trackPoint,
  authInit,
} from 'ws-editor-lib/actions';
import { getUserAndAPIHash } from 'ws-editor-lib/store/helpers';
import logger from '@pdffiller/jsf-logger/clientLogger';
import { getDeviceProps } from '@pdffiller/jsf-useragent/devicePropsStore';
import config from 'jsfcore/helpers/clientConfig';

const getRestHost = () => {
  if (config.ws.config.restHost) {
    return config.ws.config.restHost;
  }

  return (
    config.ws.config.host
      .replace('websocket', 'rest')
      .replace('wss', 'https')
  );
};

export default function* auth(project) {
  const { projectId, organization } = project;
  const viewerId = project.viewerId || getUserAndAPIHash().viewerId;

  const instanceId = logger.getSessionInstanceId();
  yield put(
    trackPoint('SESSION_INIT', {
      domain: getRestHost(),
      sessionHash: instanceId,
      projectId,
      deviceProps: getDeviceProps,
    }),
  );

  const authParams = {
    projectId,
    viewerId,
    organization,
    config: config.ws.config,
    sessionHash: instanceId,
    // eslint-disable-next-line no-underscore-dangle
    _host: config.ws._host,
  };

  if (!viewerId) {
    logger.logAuthInitWarn({
      cookie: document.cookie,
      authParams,
    });
  }

  yield put(authInit(authParams));
}
