import { takeEvery, put, take, select, all } from 'redux-saga/effects';
import RestApi from 'api-rest-component/lib/modules/Base';
import * as cm from 'combine-modals/lib/actions/actionCreator';
import { NOTARIZE_MODAL } from 'combine-modals/lib/constants/modal.classnames';
import { trackPoint } from 'ws-editor-lib/actions';
import { getUserAndAPIHash } from 'ws-editor-lib/store/helpers';
import { setForceFocusElement } from 'jsfcore/store/modules/events';
import { getFocusElement } from 'jsfcore/store/helpers/stateToProps';
import { isText } from 'jsfcore/store/helpers/functions';
import { selectors, thunks } from 'jsfcore';
import { thisDevice } from '@pdffiller/jsf-useragent';

/*
  • это z-index у класса "popover", который используется для, например,
  календаря, тултипа у симпл тула и прочего (указан в css)
  • продублируем его здесь, чтобы в дальнейшем использовать для определения
  z-index'а всех существующих модалок (в combine-modals).
*/
const popoverZIndex = 119;

export function* returnFocus() {
  const elementWithFocus = yield select(getFocusElement);

  if (elementWithFocus && isText(elementWithFocus)) {
    yield put(setForceFocusElement(elementWithFocus.id));
  }
}

export function* watchModalClosing() {
  yield takeEvery(cm.SET_INTERFACE_UNLOCK, returnFocus);
}

export function* sendNotarizeTrackPointAndStatistic(action) {
  if (action.modalId === NOTARIZE_MODAL) {
    yield put(trackPoint('NOTARIZE_CALLED', {}));

    const { baseAPI } = yield select(selectors.base.getEndPoints);
    const url = `${baseAPI}/my_forms/notarizeStatistic`;
    const { apiHash: token, viewerId: userId } = getUserAndAPIHash();
    const APP_KEY = 'x65dls049KserPfvs2';
    const headers = {
      appKey: APP_KEY,
      userId,
      token,
    };
    const body = { type: window.webPageId };
    const api = new RestApi();

    api.postFormRequest(url, headers, body).then(() => {}, () => {});
  }
}

export function* watchNotarizeModalOpen() {
  yield takeEvery(cm.OPEN_MODAL, sendNotarizeTrackPointAndStatistic);
}

export function* initializeModalZIndexMode() {
  yield put(cm.setZIndexMode(popoverZIndex));
}

function* onOpenModal(openAction) {
  const isIPad = thisDevice.isIOS && thisDevice.isTablet;

  if (!isIPad) {
    return;
  }

  const activeElementId = yield select(selectors.base.getActiveElementId);
  if (activeElementId) {
    yield put(thunks.deactivateElement());
    const closeAction = yield take(cm.CLOSE_MODAL);

    if (openAction.modalId === closeAction.modalId) {
      const elementsIds = yield select(selectors.elements.getElementsIds);

      // if element with activeId is exist
      if (elementsIds.includes(activeElementId)) {
        yield put(thunks.setActiveElement(activeElementId, true));
      }
    }
  }
}

export function* watchOpenCloseModal() {
  yield takeEvery(cm.OPEN_MODAL, onOpenModal);
}

export default function* modalRootSaga() {
  yield all([
    initializeModalZIndexMode(),
    watchModalClosing(),
    watchNotarizeModalOpen(),
    watchOpenCloseModal(),
  ]);
}
