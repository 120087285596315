import superagent from 'superagent';
import get from 'lodash/get';
import { select, take, fork, cancel, all } from 'redux-saga/effects';
import { actionTypes } from 'ws-editor-lib/actions';
import config from 'jsfcore/helpers/clientConfig';
import { SET_APP_STARTED } from 'jsfcore/store/modules/events';

/**
 * https://pdffiller.atlassian.net/browse/JSF-4538
 * Переделать auth receive на REST сервис (/session/update) в формате
 * {
 *   "userId": "1231321",
 *   "projectId": "123123123"
 *   "sessionHash": "55fc05b0a7c14b568e2c51703932ad34",
 *   "data": {
 *     "status": "timeout" (*в случае timeout)
 *     "timestamp": {
 *       "AUTH_TIME": (authTime из старого запроса),
 *       "OPERATIONS_TIME": (operationsTime из старого запроса),
 *       "READY_TIME": (readyTime из старого запроса) (*в случае success)
 *     }
 *   }
 * }
 */

const startEditorTime = +new Date();
const restLogUrl = `${config.ws.config.restHost}/session/update`;
let authTime = 0;
let operationsTime = 0;
let renderTime = 0;


export const sendSessionUpdate = (credentials, data) => {
  return superagent
    .post(restLogUrl)
    .set('Content-Type', 'application/json')
    .send({
      ...credentials,
      data,
    });
};

export const restLogQuery = (credentials, data) => {
  return new Promise((resolve) => {
    if (!__CLIENT__) {
      return;
    }

    sendSessionUpdate(credentials, data)
      .end(resolve());
  });
};

const selector = (state) => {
  return {
    userId: state.ws.viewerId,
    projectId: state.ws.projectId,
    editorHash: state.ws.sessionHash,
  };
};

const credentialsSelector = (state) => {
  const { editorHash: sessionHash, ...rest } = selector(state);
  return {
    ...rest,
    sessionHash,
  };
};

const wait = (ms) => {
  return new Promise((resolve) => {
    return setTimeout(resolve, ms);
  });
};

function* authTimeout() {
  yield wait(30 * 1000);
  const credentials = yield select(credentialsSelector);

  yield restLogQuery(credentials, {
    status: 'timeout',
    timestamp: {
      AUTH_TIME: (authTime - startEditorTime < 0)
        ? -1
        : authTime - startEditorTime,
      OPERATIONS_TIME: (operationsTime - startEditorTime < 0)
        ? -1
        : operationsTime - startEditorTime,
    },
  });
}

function* takeAuthReceive() {
  const task = yield fork(authTimeout);
  const action = yield take([
    actionTypes.AUTH_RECEIVE,
    actionTypes.ON_OPERATIONS_DOCUMENT_ACCESS,
  ]);

  // Busy
  if (action.type === actionTypes.ON_OPERATIONS_DOCUMENT_ACCESS) {
    yield cancel(task);
  }

  if (action.type === actionTypes.AUTH_RECEIVE) {
    authTime = +new Date();
    yield take(actionTypes.SET_CONFIRMED_OPS);
    operationsTime = +new Date();
    yield take(SET_APP_STARTED);
    yield cancel(task);
    renderTime = +new Date();
    const readyTime = renderTime - get(window, 'performance.timing.navigationStart', 0);

    const credentials = yield select(credentialsSelector);
    yield restLogQuery(credentials, {
      timestamp: {
        AUTH_TIME: authTime - startEditorTime,
        OPERATIONS_TIME: operationsTime - startEditorTime,
        READY_TIME: (readyTime === renderTime)
          ? -1
          : readyTime,
      },
    });
  }
}

export default function* phpLogsRootSaga({ isOfflineMode }) {
  if (isOfflineMode) {
    return;
  }

  yield all([
    takeAuthReceive(),
  ]);
}
