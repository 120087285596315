import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

const SimpleTool = ({
  children,
  style,
  onMouseDown,
  onTouchStart,
  onMouseUp,
  onTouchEnd,
  isActiveElement,
  isFillableElement,
  isEnabled,
  isHoverable,
  isHoverableErase,
  isStretching,
  isTextToolBased,
}) => {
  const className = classnames(
    'element-Element',
    // 1: Классы для элементов использующих TextTool устанавливаются
    //    внутри него
    // 2: Все эти классы не могут быть у disabled элементов

    isEnabled && !isTextToolBased
      ? {
        'edit-Element': isActiveElement && !isStretching && !isFillableElement,
        'hoverable-Element': isHoverable,
        'hoverableErase-Element': isHoverableErase,
      }
      : {},
  );

  return (
    <div
      style={style}
      className={className}
      onMouseDown={onMouseDown}
      onTouchStart={onTouchStart}
      onMouseUp={onMouseUp}
      onTouchEnd={onTouchEnd}
    >
      {children}
    </div>
  );
};

SimpleTool.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.element.isRequired,
    PropTypes.array.isRequired,
  ]).isRequired,
  style: PropTypes.shape({}),
  isActiveElement: PropTypes.bool,
  isFillableElement: PropTypes.bool,
  isEnabled: PropTypes.bool,
  isStretching: PropTypes.bool,
  isHoverable: PropTypes.bool,
  isHoverableErase: PropTypes.bool,
  isTextToolBased: PropTypes.bool,

  onMouseUp: PropTypes.func,
  onTouchEnd: PropTypes.func,
  onMouseDown: PropTypes.func,
  onTouchStart: PropTypes.func,
};

SimpleTool.defaultProps = {
  isActiveElement: false,
  isFillableElement: false,
  isEnabled: false,
  isStretching: false,
  style: {},
  isHoverable: false,
  isHoverableErase: false,
  isTextToolBased: false,

  onMouseUp: () => {},
  onTouchEnd: () => {},
  onMouseDown: () => {},
  onTouchStart: () => {},
};

export default SimpleTool;
