import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import uniqueId from 'lodash/uniqueId';

export const radioButtonDataIndicators = {
  custom: 'custom',
  default: 'default',
  null: null,
};

export const radioButtonThemes = {
  primary: 'primary',
  transparentGray: 'transparent-gray',
  null: null,
};

export default class RadioButton extends Component {
  static propTypes = {
    checked: PropTypes.bool.isRequired,
    children: PropTypes.node.isRequired,
    dataIndicator: PropTypes.oneOf(Object.values(radioButtonDataIndicators)),
    theme: PropTypes.oneOf(Object.values(radioButtonThemes)),
    title: PropTypes.string,
    onChange: PropTypes.func,
    attributes: PropTypes.shape({}),
  };

  static defaultProps = {
    dataIndicator: radioButtonDataIndicators.null,
    theme: radioButtonThemes.null,
    title: null,
    onChange: null,
    attributes: null,
  };

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillMount() {
    this.id = uniqueId('radioButton');
  }

  render() {
    const {
      checked,
      children,
      dataIndicator,
      theme,
      title,
      attributes,
    } = this.props;

    return (
      <label
        htmlFor={this.id}
        className={classnames('radio-button', {
          [`radio-button--theme--${theme}`]: theme,
        })}
        data-indicator={dataIndicator}
        name="variants"
        tabIndex={0}
        title={title}
        {...attributes}
      >
        <span className="radio-button__body">
          <span className="radio-button__indicator">
            <input
              id={this.id}
              className="radio-button__object"
              checked={checked}
              onChange={this.props.onChange}
              type="radio"
              tabIndex={-1}
            />
            <span
              className="radio-button__custom-object"
              aria-hidden="true"
            />
          </span>
          <span className="radio-button__label">
            <span className="radio-button__text">
              {children}
            </span>
          </span>
        </span>
      </label>
    );
  }
}
