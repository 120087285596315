import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

const PageThumbnailCounter = ({ thumbnail, isActivePage }) => {
  return (
    <div className="page-thumbnail__counter" aria-hidden="true">
      <div
        className={classnames(
          'index-number',
          'index-number--size--medium',
          {
            'index-number--theme--loading': !thumbnail,
            'index-number--theme--default': !isActivePage,
            'index-number--theme--primary': isActivePage,
          },
        )}
        aria-hidden="true"
      />
    </div>
  );
};

PageThumbnailCounter.propTypes = {
  thumbnail: PropTypes.number,
  isActivePage: PropTypes.bool,
};

PageThumbnailCounter.defaultProps = {
  thumbnail: false,
  isActivePage: false,
};

export default PageThumbnailCounter;
