import { thisDevice } from '@pdffiller/jsf-useragent';

import {
  sizes,
  scrollMarginToElement,
  valignValues,
  pointerWidth,
} from '../../helpers/const';

import { isTextToolBasedElement } from '../../helpers/elemTypes';
import { getElementProps, isFillable } from '../../store/helpers/functions';
import { scaleProps } from '../../components/Tools/TextTool/utils/textToolUtils';

// TODO: Fix
export const assistantSize = 90;
export const assistantSizeMobile = 15;
export const minElemSize = 10;

export const selectByMinimalShift = (array, value) => {
  const shifts = array.map((val) => {
    return Math.abs(val - value);
  });
  return array[shifts.indexOf(Math.min(...shifts))];
};

export const getScrollToElement = ({ workspace, frameOffset, scale, element }) => {
  const { height: workspaceHeight, width: workspaceWidth, framePadding } = workspace;
  const { scrollTop, scrollLeft } = frameOffset;
  let scaledElementProps = scaleProps(getElementProps(element), scale);

  scaledElementProps = {
    ...scaledElementProps,

    // Для свежесозданных текстовых элементов отсутствует размер :(
    width: scaledElementProps.width || minElemSize,
    height: scaledElementProps.height || minElemSize,
  };

  const additionalPointerWidth =
    (isFillable(element) && thisDevice.isDesktop)
      ? pointerWidth
      : 0;

  const additionalAssistantHeight =
    (isFillable(element) && thisDevice.isDesktop)
      ? assistantSize
      : 0;

  const additionalMobileHeight = (thisDevice.isMobile && !__TEST__)
    ? 15
    : 0;

  const elementBounding = {
    top: (scaledElementProps.y + framePadding.top) - scrollMarginToElement,
    left: (scaledElementProps.x + framePadding.left) - additionalPointerWidth -
      scrollMarginToElement,
    right: scaledElementProps.x + scaledElementProps.width + framePadding.left +
      scrollMarginToElement,
    bottom: scaledElementProps.y + scaledElementProps.height +
      additionalAssistantHeight + additionalMobileHeight + framePadding.top +
      scrollMarginToElement,
  };

  const frameBounding = {
    top: scrollTop,
    left: scrollLeft,
    right: scrollLeft + workspaceWidth,
    bottom: scrollTop + workspaceHeight,
  };

  // one of 'left', 'center', 'right'
  const align = scaledElementProps.align || 'center';

  // one of valignValues.top, valignValues.middle, valignValues.bottom
  const valign = scaledElementProps.valign || valignValues.middle;

  const isСompletelyInvisibleElement =

    // Если нижняя граница элемента выше верхней границы фрейма
    elementBounding.bottom <= frameBounding.top ||

    // Если правая граница элемента левее левой границы фрейма
    elementBounding.right <= frameBounding.left ||

    // Если левая граница элемента правее правой границы фрейма
    elementBounding.left >= frameBounding.right ||

    // Если верхняя граница элемента ниже нижней границы фрейма
    elementBounding.top >= frameBounding.bottom;

  const { isTopLeftInvisible, isTopRightInvisible,
    isBottomLeftInvisible, isBottomRightInvisible,
  } = {
    isTopLeftInvisible:

      // Если левая граница элемента левее левой границы фрейма
      elementBounding.left < frameBounding.left ||

      // Если верхняя граница элемента выше верхней границы фрейма
      elementBounding.top < frameBounding.top,
    isTopRightInvisible:

      // Если верхняя граница элемента выше верхней границы фрейма
      elementBounding.top < frameBounding.top ||

      // Если правая граница элемента правее правой границы фрейма
      elementBounding.right > frameBounding.right,
    isBottomLeftInvisible:

      // Если нижня граница элемента ниже нижней границы фрейма
      elementBounding.bottom > frameBounding.bottom ||

      // Если левая граница элемента левее левой границы фрейма
      elementBounding.left < frameBounding.left,
    isBottomRightInvisible:

      // Если нижня граница элемента ниже нижней границы фрейма
      elementBounding.bottom > frameBounding.bottom ||

      // Если правая граница элемента правее правой границы фрейма
      elementBounding.right > frameBounding.right,
  };

  let newScrollTop = scrollTop;
  let newScrollLeft = scrollLeft;

  // Признаком необходимости изменения скрола по горизонтали является отсутствие на экране или
  // 2-х левых точек, или 2-х правых. Если отсутствует только одна точка, то положение дел
  // исправит изменение вертикального скрола
  if (
    (isBottomRightInvisible && isTopRightInvisible) ||
    (isTopLeftInvisible && isBottomLeftInvisible)
  ) {
    // Если элемент может полностью поместится на экран по горизонтали, то выбираем позицию
    // скрола по минимальному сдвигу от текущего скрола, при этом не важно к какому краю
    // мы будет скролить

    const newScrollLeftByLeft = elementBounding.left;

    let newScrollLeftByRight = elementBounding.right - workspaceWidth;
    newScrollLeftByRight = newScrollLeftByRight < 0
      ? 0
      : newScrollLeftByRight;

    let newScrollLeftByCenter = (elementBounding.left + (scaledElementProps.width / 2)) -
      (workspaceWidth / 2);
    newScrollLeftByCenter = newScrollLeftByCenter < 0
      ? 0
      : newScrollLeftByCenter;

    const newScrollLeftMinimalShift = selectByMinimalShift([
      newScrollLeftByLeft, newScrollLeftByRight, newScrollLeftByCenter,
    ], scrollLeft);

    switch (align) {
      case 'left':
        newScrollLeft = (scaledElementProps.width < workspaceWidth)
          ? newScrollLeftMinimalShift
          : newScrollLeftByLeft;
        break;
      case 'right':
        newScrollLeft = (scaledElementProps.width < workspaceWidth)
          ? newScrollLeftMinimalShift
          : newScrollLeftByRight;
        break;
      default:
        newScrollLeft = (scaledElementProps.width < workspaceWidth)
          ? newScrollLeftMinimalShift
          : newScrollLeftByCenter;
    }
  }

  // Признаком необходимости изменения скрола по вертикали является отсутствие на экране или
  // 2-х верхних точек, или 2-х нижних. Если отсутствует только одна точка, то положение дел
  // исправит изменение горизонтального скрола
  if (
    (isBottomRightInvisible && isBottomLeftInvisible) ||
    (isTopRightInvisible && isTopLeftInvisible)
  ) {
    // Если элемент может полностью поместится на экран по вертикали, то выбираем позицию
    // скрола по минимальному сдвигу от текущего скрола, при этом не важно к какому краю
    // мы будет скролить

    const newScrollTopByTop = elementBounding.top;

    let newScrollTopByBottom = elementBounding.bottom - workspaceHeight;
    newScrollTopByBottom = newScrollTopByBottom < 0
      ? 0
      : newScrollTopByBottom;

    let newScrollTopByMiddle = (elementBounding.top + (scaledElementProps.height / 2)) -
      (workspaceHeight / 2);
    newScrollTopByMiddle = newScrollTopByMiddle < 0
      ? 0
      : newScrollTopByMiddle;

    const newScrollTopMinimalShift = selectByMinimalShift([
      newScrollTopByTop, newScrollTopByBottom, newScrollTopByMiddle,
    ], scrollTop);

    switch (valign) {
      case valignValues.top:
        newScrollTop = scaledElementProps.height < workspaceHeight
          ? newScrollTopMinimalShift
          : newScrollTopByTop;
        break;
      case valignValues.bottom:
        newScrollTop = scaledElementProps.height < workspaceHeight
          ? newScrollTopMinimalShift
          : newScrollTopByBottom;
        break;
      default:
        newScrollTop = scaledElementProps.height < workspaceHeight
          ? newScrollTopMinimalShift
          : newScrollTopByMiddle;
    }
  }

  // Если элемент полностью не виден то скролим к нужной его части (align, valign) -
  // для текстовых элементов, и к центру для остальных
  if (isСompletelyInvisibleElement) {
    return {
      scrollTop: newScrollTop,
      scrollLeft: newScrollLeft,
      animation: true,
    };
  }

  if (
    !isTextToolBasedElement(element) && (
      (scaledElementProps.height > workspaceHeight) ||
      (scaledElementProps.width > workspaceWidth)
    )
  ) {
    // Ранее скролл в этом кейсе не происходил, но причины такого поведения были
    // неизвестны. Включил скролл в связи с багом JSF-6729.
    return {
      scrollTop: newScrollTop,
      scrollLeft: newScrollLeft,
      animation: true,
    };
  }

  // Если элемент частично не виден - для текстовых скролим только если не видно align, valign
  // часть элемента, при этом скролим именно к этой части
  if (
    (
      align === 'left' && valign === valignValues.top &&
      isTopLeftInvisible
    ) || (
      align === 'left' && valign === valignValues.bottom &&
      isBottomLeftInvisible
    ) || (
      align === 'right' && valign === valignValues.top &&
      isTopRightInvisible
    ) || (
      align === 'right' && valign === valignValues.bottom &&
      isBottomRightInvisible
    ) || (
      valignValues.middle && (
        isBottomRightInvisible || isTopRightInvisible ||
        isTopRightInvisible || isTopLeftInvisible
      )
    )
  ) {
    return {
      scrollTop: newScrollTop,
      scrollLeft: newScrollLeft,
      animation: true,
    };
  }

  return false;
};

export const fontSizes = sizes.map((item) => {
  return { value: item, label: item };
});

export const getFontSize = (now, more) => {
  const sizesArray = sizes.includes(now)
    ? sizes
    : [...sizes, now];

  const targetSizes = sizesArray
    .filter((size) => {
      return more
        ? size > now
        : size < now;
    });
  if (more) {
    return targetSizes[0] || sizesArray[sizesArray.length - 1];
  }

  return targetSizes[targetSizes.length - 1] || sizes[0];
};

export const getSize = (now, more, minSize, maxSize, sizeDelta) => {
  const param = more
    ? sizeDelta
    : -sizeDelta;

  const size = now.width > now.height
    ? {
      width: (now.width + param) || minSize.width,
      height: (now.height + ((param * now.height) / now.width)) || minSize.height,
    }
    : {
      width: (now.width + ((param * now.width) / now.height)) || minSize.width,
      height: (now.height + param) || minSize.height,
    };

  if (
    size.width > maxSize.width ||
    size.height > maxSize.height
  ) {
    const widthRatio = size.width / maxSize.width;
    const heightRatio = size.height / maxSize.height;
    const maxRatio = widthRatio > heightRatio
      ? widthRatio
      : heightRatio;

    return {
      width: size.width / maxRatio,
      height: size.height / maxRatio,
    };
  }

  if (
    size.height < minSize.height ||
    size.width < minSize.width
  ) {
    const widthRatio = size.width / minSize.width;
    const heightRatio = size.height / minSize.height;
    const minRatio = widthRatio > heightRatio
      ? heightRatio
      : widthRatio;
    return {
      width: size.width / minRatio,
      height: size.height / minRatio,
    };
  }

  return size;
};
