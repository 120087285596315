import React from 'react';
import PropTypes from 'prop-types';
import cls from 'classnames';

export const flexibleBarBodyOffsets = {
  medium: 'medium',
  small: 'small',
  smallHorizontal: 'small-horizontal',
  mediumVertical: 'mediumVertical',
  null: null,
};

export const flexibleBarBodyJustifies = {
  spaceBetween: 'space-between',
  flexEnd: 'flex-end',
  center: 'center',
  null: null,
};

const FlexibleBarBody = ({ offsetHorizontal, offsetVertical, offset, justify, children }) => {
  return (
    <div
      className={cls('flexible-bar', {
        [`flexible-bar--offset-horizontal--${offsetHorizontal}`]: offsetHorizontal,
        [`flexible-bar--offset-vertical--${offsetVertical}`]: offsetVertical,
        [`flexible-bar--offset--${offset}`]: offset,
        [`flexible-bar--justify--${justify}`]: justify,
      })}
    >
      <div className="flexible-bar__body">
        {children}
      </div>
    </div>
  );
};

FlexibleBarBody.propTypes = {
  offsetHorizontal: PropTypes.oneOf(Object.values(flexibleBarBodyOffsets)),
  offsetVertical: PropTypes.oneOf(Object.values(flexibleBarBodyOffsets)),
  offset: PropTypes.oneOf(Object.values(flexibleBarBodyOffsets)),
  justify: PropTypes.oneOf(Object.values(flexibleBarBodyJustifies)),
  children: PropTypes.node,
};

FlexibleBarBody.defaultProps = {
  offsetHorizontal: flexibleBarBodyOffsets.null,
  offsetVertical: flexibleBarBodyOffsets.null,
  offset: flexibleBarBodyOffsets.null,
  justify: flexibleBarBodyJustifies.null,
  children: null,
};

export default FlexibleBarBody;
