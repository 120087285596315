import {
  updateElement,
  updateFillableElement,
} from 'ws-editor-lib/actions';

import isEqual from 'lodash/isEqual';

import * as selectors from '../selectors';
import { isFillable } from '../helpers/functions';
import { getFillableMinHeightByFontSize } from '../../helpers/resizeUtils';
import { cancellableOpts } from '../modules/undoRedo';
import {
  preprocessBeforeSendElement,
  preprocessBeforeUpdateElement,
} from '../helpers/preprocess';

const updateElementThunk = (id, content, opts = cancellableOpts) => {
  return (dispatch, getState) => {
    const state = getState();
    const fConstructorLoadedAndShown = selectors.mode.isConstructor.includingPreview(state);
    const isFConstructorPreviewShown = selectors.base.getIsFConstructorPreviewShown(state);
    const activeElement = selectors.elements.getActiveElement(state);

    if (
      fConstructorLoadedAndShown &&
      !isFConstructorPreviewShown &&
      isFillable(activeElement)
    ) {
      // на изменение fontSize изменять height
      if (content.hasOwnProperty('fontSize')) {
        const { height } = activeElement.template;
        const newHeight = getFillableMinHeightByFontSize(content.fontSize);

        if (newHeight > height) {
          dispatch(
            updateFillableElement({
              id,
              template: { ...content, height: newHeight },
            }, opts),
          );
          return;
        }
      }

      dispatch(
        updateFillableElement({ id, template: content }, opts),
      );
      return;
    }

    dispatch(
      updateElement(id, content, opts),
    );
  };
};

const updateWithProcessedContent = (preprocessFunc) => {
  return (element, dispatch, opts) => {
    const preprocessedContent = preprocessFunc(element);
    if (!isEqual(preprocessedContent, element.content)) {
      dispatch(updateElement(element.id, preprocessedContent, opts));
    }
  };
};

export const updateElementWithBeforeSendProcessing =
  updateWithProcessedContent(preprocessBeforeSendElement);

export const updateElementWithBeforeUpdateProcessing =
  updateWithProcessedContent(preprocessBeforeUpdateElement);

export default updateElementThunk;
