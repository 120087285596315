import React, { Component } from 'react';
import PropTypes from 'prop-types';
import cls from 'classnames';

export const popoverItemOffsets = {
  large: 'large',
};
export const popoverItemThemes = {
  info: 'informational',
};

export default class PopoverItem extends Component {
  static propTypes = {
    offset: PropTypes.oneOf(
      Object.keys(popoverItemOffsets),
    ),
    theme: PropTypes.oneOf(
      Object.keys(popoverItemThemes),
    ),
    children: PropTypes.oneOfType([
      PropTypes.array.isRequired,
      PropTypes.element.isRequired,
    ]),
  }

  static defaultProps = {
    offset: null,
    theme: null,
    children: null,
  }

  render() {
    const { offset, theme } = this.props;
    return (
      <div className={cls('item', {
        [`item--offset--${offset}`]: offset,
        [`item--theme--${theme}`]: theme,
      })}
      >
        {this.props.children}
      </div>
    );
  }
}
