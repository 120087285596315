import React from 'react';
import PropTypes from 'prop-types';

const FlexibleListItem = ({ children }) => {
  return (
    <li className="flexible-list__item">
      {children}
    </li>
  );
};

FlexibleListItem.propTypes = {
  children: PropTypes.node,
};

FlexibleListItem.defaultProps = {
  children: null,
};

export default FlexibleListItem;
