/**
 * Получить угол поворта из матрицы преобразований, которая лежит в pdf-текстовом чанке.
 *
 * @param {array} transform - transform matrix
 * @returns {string} value for css 'transform: rotate'
 */
const getItemRotate = (transform) => {
  // in all internets you can see transform matrix as [a, b, c, d, tx, ty]. Get 'a' and 'b'
  const a = transform[0];
  const b = transform[1];

  // NOTE: Math.atan returns correct value even if a = 0
  const angle = Math.atan(-b / a); // radians
  // Откуда взялся '+0' в конце: Math.round(-0) = -0, если мы хотим в этом случае получить
  // просто '0' - то прибавим ноль. Другие числа останутся неизменны.
  return Math.round((angle * 180) / Math.PI) + 0; // degrees
};

export default getItemRotate;
