export const doAccess = (operations, access) => {
  const { properties } = operations[0];
  return {
    ...access,
    ...properties,
    status: properties.subType,
    basicContent: properties.basicContent || 0,
    templateFields: properties.templateFields || 0,
    templateOrigin: properties.templateOrigin || 0,
    timeout: properties.timeout || 0,
    location: properties.location || 0,
    message: properties.message || 0,
    busyUser: properties.busyUser || '',
    accessTimer: properties.timeout,
  };
};

export function doSource(operations, source) {
  const { properties } = operations[0];
  return {
    ...source,
    pdf: properties.pdf,
    swf: properties.swf,
  };
}
