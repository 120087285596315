/* eslint-disable no-param-reassign */
import get from 'lodash/get';
import pick from 'lodash/pick';
import groupBy from 'lodash/groupBy';
import mapValues from 'lodash/mapValues';

import {
  actionTypes,
  addElement,
  addElements,
  updateElement,
  updateElements,
  removeElement,
  removeElements,
  addFillableElementAsIs,
  removeFillableElement,
  updateOrderFillableElements,
  setPaSettings,
} from 'ws-editor-lib/actions';
import {
  getNextElementId,
} from 'ws-editor-lib/store/models/Operations/Utils';
import {
  isFillable,
  isRadioElement,
  isDropdownElement,
  isPicture,
  isSignature,
  isSignatureCurve,
} from '../helpers/functions';
import updateFillableElementThunk from '../thunks/updateFillableElement';
import { removeFillableElements } from '../thunks/remove';
import { setPaActiveSection, SET_PA_ACTIVE_SECTION } from '../modules/viewport';
import {
  isCancellableAction,
  addHistoryInfo,
} from '../modules/undoRedo';
import { selectors } from '../..';
import isSignNow from '../../helpers/const/isSignNow';
import { updateFillableElementWithPreprocessing } from '../thunks';

const {
  UPDATE_FILLABLE_ELEMENT,
  ADD_FILLABLE_ELEMENT,
  REMOVE_FILLABLE_ELEMENT,
  UPDATE_ELEMENT,
  ADD_ELEMENT,
  ADD_ELEMENTS,
  ADD_FILLABLE_ELEMENTS,
  REMOVE_ELEMENT,
  REMOVE_ELEMENTS,
  UPDATE_ORDER_FILLABLE_ELEMENTS,
  SET_PA_SETTINGS,
  CHANGE_ELEMENT_PAGE_ID,
  UPDATE_FILLABLE_GROUPS,
} = actionTypes;

const getUndoActionForSetPASettings = (action, getState) => {
  const state = getState();
  const prevAttributes = state.ws.attributes;

  if (typeof prevAttributes !== 'undefined') {
    const prevAttributesContent = mapValues(prevAttributes, (value) => {
      return value.content;
    });

    const changedAttributesNames = Object.keys(action.newAttrContent);
    const attributesContentBeforeChange = changedAttributesNames.reduce((result, attrName) => {
      const changedKeys = Object.keys(action.newAttrContent[attrName]);
      return {
        ...result,
        [attrName]: {
          ...pick(prevAttributesContent[attrName], changedKeys),
          ...(
            get(prevAttributes[attrName], 'content.visible', false) === false &&
            { visible: false }
          ),
        },
      };
    }, {});

    return setPaSettings(attributesContentBeforeChange);
  }

  return setPaSettings(mapValues(action.newAttrContent, () => {
    return { visible: false };
  }));
};

const parseContentDefaultValue = (key, value) => {
  if (key === 'checked' || key === 'italic') {
    return false;
  }

  return value;
};

const getUndoActionForUpdateFillableElement = (action, element) => {
  const undoAction = { id: action.id, subType: element.subType };
  if (action.subType && action.subType !== element.subType) {
    undoAction.subType = element.subType;
  }
  undoAction.template = Object.keys(action.template).reduce((acc, key) => {
    if (element.template[key] === action.template[key]) {
      return acc;
    }
    acc[key] = element.template[key];
    return acc;
  }, {});

  if (isRadioElement(element)) {
    return updateFillableElementWithPreprocessing(undoAction);
  }

  return updateFillableElementThunk(undoAction);
};

const getRedoActionForUpdateFillableElement = (action, element) => {
  if (isRadioElement(element)) {
    return updateFillableElementWithPreprocessing(action);
  }

  return updateFillableElementThunk(action);
};

const getUndoActionContent = (action, element) => {
  if (action.content === null) {
    return { ...element.content };
  }
  const result = Object.keys(action.content).reduce((acc, key) => {
    if (
      typeof element.content === 'undefined' ||
      element.content[key] === action.content[key]
    ) {
      return acc;
    }

    acc[key] = element.content[key] || parseContentDefaultValue(key, element.content[key]);

    return acc;
  }, {});
  if (Object.keys(result).length === 0) {
    return undefined;
  }
  return result;
};

const getRedoActionContent = (action, element) => {
  if (action.content === null) {
    return null;
  }
  const result = Object.keys(action.content).reduce((acc, key) => {
    if (
      typeof element.content !== 'undefined' &&
      element.content[key] === action.content[key]
    ) {
      return acc;
    }

    acc[key] = action.content[key];

    return acc;
  }, {});
  if (Object.keys(result).length === 0) {
    return undefined;
  }
  return result;
};


const getNewElementsWithIds = (elements, ghostId) => {
  const { withId, withoutId } = groupBy(elements, (element) => {
    if (element.id) {
      return 'withId';
    }
    return 'withoutId';
  });

  let currentId = ghostId;
  return [
    ...(withId || []),
    ...(withoutId
      ? withoutId.map((element) => {
        currentId = getNextElementId(currentId);
        return {
          ...element,
          id: currentId,
        };
      })
      : []
    ),
  ];
};

const getIsEmptySignatureFilling = (element, actionContent) => {
  if (!isSignature(element) || !isFillable(element)) {
    return false;
  }

  if (isSignatureCurve(element)) {
    return actionContent && !(actionContent.curves || actionContent.dateStamp);
  }

  return actionContent && !(actionContent.text || actionContent.dateStamp);
};

const getIsEmptyPictureFilling = (element, actionContent) => {
  return isFillable(element) && isPicture(element) && actionContent && !actionContent.url;
};


const getUndoActionForUpdateElement = (action, element) => {
  const actionContent = getUndoActionContent(action, element);
  const isEmptyRadioFilling = isRadioElement(element) && actionContent && !actionContent.checked;
  const isEmptyDropdownFilling = isDropdownElement(element) && actionContent && !actionContent.text;
  const isEmptyPictureFilling = getIsEmptyPictureFilling(element, actionContent);
  const isEmptySignatureFilling = getIsEmptySignatureFilling(element, actionContent);
  const isEmptyPictureOrSignatureFilling = isEmptyPictureFilling || isEmptySignatureFilling;
  const isSignNowEditor = isSignNow();

  if (!isSignNowEditor && isEmptyPictureOrSignatureFilling) {
    return removeElement(action.id);
  }

  return actionContent && !isEmptyRadioFilling && !isEmptyDropdownFilling
    ? updateElement(action.id, actionContent)
    : removeElement(action.id, { needEraseContent: isSignNowEditor });
};

const getRedoActionForUpdateElement = (action, element) => {
  const actionContent = getRedoActionContent(action, element);
  if (!actionContent && actionContent !== null) {
    return undefined;
  }
  return updateElement(action.id, actionContent);
};

export default (additionalMiddlewareInjection = null) => {
  return ({ getState }) => {
    return (next) => {
      return (action) => {
        if (!isCancellableAction(action)) {
          return next(action);
        }

        switch (action.type) {
          case UPDATE_ORDER_FILLABLE_ELEMENTS: {
            const undo = updateOrderFillableElements({
              ...action,
              order: action.prevOrder,
              prevOrder: action.order,
            });
            const redo = updateOrderFillableElements({
              ...action,
              order: action.order,
              prevOrder: action.prevOrder,
            });

            const activeElementId = selectors.base.getActiveElementId(getState());

            return next(addHistoryInfo(action, undo, redo, activeElementId));
          }

          case SET_PA_ACTIVE_SECTION: {
            const undo = setPaActiveSection(
              selectors.base.getPAConstructorActiveSection(getState()));
            const redo = { ...action };

            const activeElementId = selectors.base.getActiveElementId(getState());

            return next(addHistoryInfo(action, undo, redo, activeElementId));
          }

          case SET_PA_SETTINGS: {
            const activeElement = selectors.elements.getActiveElement(getState());
            const additionalFields = { actionDateTime: Date.now() };

            const undo = getUndoActionForSetPASettings(action, getState);
            const redo = { ...action };

            return next(addHistoryInfo(action, undo, redo, activeElement.id, additionalFields));
          }

          case UPDATE_FILLABLE_ELEMENT: {
            const state = getState();
            const element = selectors.elements.getElementFromMapFactory(action.id)(state);

            if (!element) {
              return next(action);
            }

            const undo = getUndoActionForUpdateFillableElement(action, element);
            const redo = getRedoActionForUpdateFillableElement(action, element);

            return next(addHistoryInfo(action, undo, redo, element.id));
          }

          case ADD_FILLABLE_ELEMENT: {
            const undo = removeFillableElement(action.element.id);
            const redo = { ...action };

            return next(addHistoryInfo(action, undo, redo, action.element.id));
          }

          case ADD_FILLABLE_ELEMENTS: {
            const elementsIds = action.elements.map((element) => {
              return element.id;
            });

            const undo = removeFillableElements(elementsIds);
            const redo = { ...action };

            return next(addHistoryInfo(action, undo, redo, elementsIds[0]));
          }

          case REMOVE_FILLABLE_ELEMENT: {
            const state = getState();
            const activeElement = selectors.elements.getActiveElement(state);
            const prevElement = activeElement.id === action.id
              ? activeElement
              : selectors.elements.getElementFromMapFactory(action.id)(state);

            if (!prevElement) {
              return next(action);
            }

            const undo = addFillableElementAsIs(prevElement);
            const redo = { ...action };

            return next(addHistoryInfo(action, undo, redo, action.id));
          }

          case UPDATE_ELEMENT: {
            const state = getState();
            const element = selectors.elements.getElementFromMapFactory(action.id)(state);

            if (!element) {
              return next(action);
            }

            const undo = getUndoActionForUpdateElement(action, element);
            const redo = getRedoActionForUpdateElement(action, element);

            return next(addHistoryInfo(action, undo, redo, element.id));
          }

          case ADD_ELEMENT: {
            const undo = removeElement(action.element.id);
            const redo = { ...action };

            return next(addHistoryInfo(action, undo, redo, action.element.id));
          }

          case ADD_ELEMENTS: {
            const state = getState();
            const ghostElement = selectors.base.getGhostElement(state);
            action.elements = getNewElementsWithIds(action.elements, ghostElement.id);
            const elementIds = action.elements.map((element) => {
              return element.id;
            });

            const undo = removeElements(elementIds);
            const redo = { ...action };

            return next(addHistoryInfo(action, undo, redo, action.elements[0].id));
          }

          case REMOVE_ELEMENT: {
            const state = getState();
            const activeElement = selectors.elements.getActiveElement(state);
            const prevElement = activeElement.id === action.id
              ? activeElement
              : selectors.elements.getElementFromMapFactory(action.id)(state);

            if (!prevElement) {
              return next(action);
            }

            const undo = isFillable(prevElement)
              ? updateElement(action.id, { ...prevElement.content })
              : addElement(prevElement);
            const redo = { ...action };

            return next(addHistoryInfo(action, undo, redo, action.id));
          }

          case REMOVE_ELEMENTS: {
            const state = getState();
            const element = selectors.elements.getActiveElement(state);
            const prevElements = action.ids.map((id) => {
              return element.id === id
                ? element
                : selectors.elements.getElementFromMapFactory(id)(state);
            });

            // during ss we could clear(!) fillable elements chain by REMOVE_ELEMENTS
            // so in this case we need to updateElements instead of addElements
            const undo = prevElements.every(isFillable)
              ? updateElements(prevElements)
              : addElements(prevElements);
            const redo = { ...action };

            return next(addHistoryInfo(action, undo, redo, element.id));
          }

          case CHANGE_ELEMENT_PAGE_ID: {
            const element = selectors.elements.getActiveElement(getState());
            const undo = {
              ...action,
              pageId: element.pageId,
            };
            const redo = { ...action };

            return next(addHistoryInfo(action, undo, redo, element.id));
          }

          case UPDATE_FILLABLE_GROUPS: {
            const fillableGroups = selectors.base.getGroups(getState());
            const undo = { ...action, fillableGroups };
            const redo = { ...action };

            return next(addHistoryInfo(action, undo, redo));
          }

          default:
            return additionalMiddlewareInjection
              ? additionalMiddlewareInjection(action, getState, next)
              : next(action);
        }
      };
    };
  };
};
