const emptyFn = () => {};
const dependenciesMap = {
  jsfcoreSelectors: 'jsfcoreSelectors',
  cancellableOpts: 'cancellableOpts',
  isSignNow: 'isSignNow',
  elemSubTypes: 'elemSubTypes',
  elemFCTypes: 'elemFCTypes',
  isDropdownElement: 'isDropdownElement',
  FormattedMessage: 'FormattedMessage',
  Select: 'Select',
  updateFillableElementThunk: 'updateFillableElementThunk',
  updateFillableElementWithPreprocessing: 'updateFillableElementWithPreprocessing',
  jsfcoreModalsActions: 'jsfcoreModalsActions',
  jsfcoreModalsTypes: 'jsfcoreModalsTypes',
  jsfcoreModalsReducer: 'jsfcoreModalsReducer',
  nodeIsElement: 'nodeIsElement',
  isRadioElement: 'isRadioElement',
  isElementFromDeletedPage: 'isElementFromDeletedPage',
  getDeletedPages: 'getDeletedPages',
  elementsSelectors: 'elementsSelectors',
  isFillableElement: 'isFillableElement',
  isElementWithContent: 'isElementWithContent',
  isVisibleElement: 'isVisibleElement',
  actionTypes: 'actionTypes',
  updateElements: 'updateElements',
  removeElements: 'removeElements',
  createMapFromElements: 'createMapFromElements',
  blurNativeActiveElement: 'blurNativeActiveElement',
  snRoleSelectors: 'snRoleSelectors',
  snfillerModalsTypes: 'snfillerModalsTypes',
  snfillerModalsMap: 'snfillerModalsMap',
};

const dependencies = Object.keys(dependenciesMap).reduce((acc, key) => {
  acc[key] = emptyFn;
  return acc;
}, {});

const depsPromises = Object.keys(dependenciesMap).reduce((acc, key) => {
  let callResolve = null;
  const promise = new Promise((resolve) => {
    callResolve = resolve;
  });

  acc[key] = {
    promise,
    resolve: callResolve,
  };
  return acc;
}, {});

function injectDependency(name, impl) {
  dependencies[name] = impl;
  if (depsPromises[name]) {
    depsPromises[name].resolve(impl);
  }
}

function injectDependencies(deps) {
  Object.keys(deps).forEach((key) => {
    injectDependency(key, deps[key]);
  });
}

function getDependency(name) {
  if (dependencies[name] === emptyFn) {
    // eslint-disable-next-line no-console
    console.warn(`You are trying to use dependency ${name} that haven't loaded yet!`);
  }
  return dependencies[name];
}

function getDependenciesPromise(array) {
  return Promise.all(
    array.map((fnName) => {
      return depsPromises[fnName].promise;
    }),
  );
}

function getDependencyPromise(fnName) {
  return depsPromises[fnName].promise;
}

export {
  injectDependency,
  injectDependencies,
  getDependency,
  dependenciesMap,
  getDependenciesPromise,
  getDependencyPromise,
};
