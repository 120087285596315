import omitBy from 'lodash/omitBy';
import React, { Fragment, PureComponent } from 'react';
import PropTypes from 'prop-types';
import Portal from '../Portal';
import DetectWindowResizeProvider from '../DetectWindowResizeProvider';
import ExperimentDetectScrollProvider from '../ExperimentDetectScrollProvider';
import ExperimentDetectScale from '../ExperimentDetectScale';
import ExperimentDetectOffset from '../ExperimentDetectOffset';
import { computeOffsetsWithinElement } from '../helpers/portalUtils';
import { possiblePositions } from '../helpers/const';
import { presetsNames } from '../helpers/presets';
import * as Ui from '../../../ui';

// This is experiment version for tooltip

const initialPosition = { left: -10000, top: -10000 };

export default class PortalWrapperExperiment extends PureComponent {
  static propTypes = {
    children: PropTypes.node.isRequired,
    getAnchorRefPromise: PropTypes.func.isRequired,

    locatorArgs: PropTypes.shape({
      positions: PropTypes.oneOf(Object.values(possiblePositions)),
      presets: PropTypes.oneOfType([
        PropTypes.oneOf(Object.values(presetsNames)),
        PropTypes.shape({
          postMeasure: PropTypes.func,
          postCalculation: PropTypes.func,
          postClamping: PropTypes.func,
        }),
      ]),
      hideIfAnchorOutOfViewport: PropTypes.bool,
      smartPosition: PropTypes.bool,
      clampToViewport: PropTypes.oneOfType([
        PropTypes.bool,
        PropTypes.arrayOf(PropTypes.bool),
      ]),
      restrictedPositions: PropTypes.arrayOf(
        PropTypes.arrayOf(PropTypes.string),
      ),
    }),

    storeRef: PropTypes.func,

    width: PropTypes.oneOfType([
      PropTypes.number, PropTypes.string,
    ]),
    height: PropTypes.oneOfType([
      PropTypes.number, PropTypes.string,
    ]),

    useArrow: PropTypes.bool,
    arrowPosition: PropTypes.oneOf(
      Object.values(Ui.popover.arrowPositions),
    ),
    theme: PropTypes.oneOf(
      Object.values(Ui.popover.themes),
    ),
    offset: PropTypes.oneOf(
      Object.values(Ui.popover.offsets),
    ),
    zIndex: PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.string,
    ]),
    getViewportRef: PropTypes.func,
    isDisabled: PropTypes.bool,
    disableClickOutside: PropTypes.bool,
  };

  static defaultProps = {
    useArrow: true,
    locatorArgs: {
      position: possiblePositions.bottomCenter,
      smartPosition: true,
      hideIfAnchorOutOfViewport: false,
      clampToViewport: true,
    },
    width: 'initial',
    height: 'initial',

    theme: Ui.popover.themes.primary,
    arrowPosition: Ui.popover.arrowPositions.null,
    offset: Ui.popover.offsets.null,
    zIndex: 'initial',

    storeRef: null,
    getViewportRef:
      __CLIENT__
        ? () => {
          return document.body;
        }
        : null,

    isDisabled: false,
    disableClickOutside: false,
  };

  constructor(props) {
    super(props);
    this.blockRef = null;
    this.state = {
      position: initialPosition,
      isVisible: true,
    };
    this.updatePositionIfNeed();
  }

  componentDidUpdate() {
    this.updatePositionIfNeed();
  }

  getArrowPositionToPass() {
    const { arrowPosition: arrowPassedByProps, useArrow } = this.props;
    const { arrowPosition: calculatedArrowByPosition } = this.state;

    if (!useArrow) {
      return null;
    }

    return arrowPassedByProps !== null
      ? arrowPassedByProps
      : calculatedArrowByPosition;
  }

  getElementRef = () => {
    return this.elementRef;
  };

  updatePositionIfNeed = async () => {
    if (!this.isUpdateQueued) {
      let anchor;

      try {
        this.isUpdateQueued = true;
        anchor = await this.props.getAnchorRefPromise();
      } catch (e) {
        if (__DEVELOPMENT__) {
          // eslint-disable-next-line no-console
          console.error('getAnchorRefPromise error', e);
        }
        return;
      } finally {
        this.isUpdateQueued = false;
      }

      const { locatorArgs } = this.props;
      const viewport = this.props.getViewportRef();
      const element = this.getElementRef();

      if (anchor === null || element === null || viewport === null) {
        return;
      }

      const { left, top, isVisible, arrowPosition } = computeOffsetsWithinElement({
        viewport,
        anchor,
        element,
        ...locatorArgs,
      });

      const { position: { left: oldLeft, top: oldTop }, isVisible: oldIsVisible } = this.state;

      const areComputedValuesValid = !Number.isNaN(left) && !Number.isNaN(top);
      const didSomeValuesChangedAfterComputing = (
        left !== oldLeft ||
        top !== oldTop ||
        isVisible !== oldIsVisible
      );

      if (areComputedValuesValid && didSomeValuesChangedAfterComputing) {
        this.setState({
          position: { left, top },
          isVisible,
          arrowPosition,
        });
      }
    }
  };

  storeElementRef = (ref) => {
    this.elementRef = ref;
    if (this.props.storeRef) {
      this.props.storeRef(ref);
    }
  };

  composeStyles() {
    const { left, top } = this.state.position;
    const { width, height, zIndex, isDisabled } = this.props;
    return omitBy(
      {
        width,
        height,
        zIndex,
        position: 'absolute',
        display: this.state.isVisible
          ? 'initial'
          : 'none',
        transform: `translate(${left}px, ${top}px)`,
        pointerEvents: isDisabled
          ? 'none'
          : 'initial',
      },
      (val) => {
        return val === 'initial';
      },
    );
  }

  render() {
    const {
      getAnchorRefPromise,
      locatorArgs,
      zIndex,
      getViewportRef,
      useArrow,
      isDisabled,
      disableClickOutside,
      ...rest
    } = this.props;

    return (
      <Fragment>
        <Portal>
          <Ui.Popover.Index
            {...rest}
            storeRef={this.storeElementRef}
            style={this.composeStyles()}
            arrowPosition={this.getArrowPositionToPass()}
          >
            {this.props.children}
          </Ui.Popover.Index>
        </Portal>

        <ExperimentDetectScrollProvider
          getRefPromise={getAnchorRefPromise}
          onScroll={this.updatePositionIfNeed}
        />
        <ExperimentDetectScale
          onScaleUpdated={this.updatePositionIfNeed}
        />
        <ExperimentDetectOffset
          onOffsetUpdated={this.updatePositionIfNeed}
        />
        <DetectWindowResizeProvider
          onResize={this.updatePositionIfNeed}
        />
      </Fragment>
    );
  }
}
