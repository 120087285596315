import updateElement from './updateElement';
import { toolbar } from '../selectors';

const setFontSize = (fontSize) => {
  return (dispatch, getState) => {
    const { id } = toolbar.getElementPropsForToolbar(getState());
    return dispatch(updateElement(id, { fontSize }));
  };
};

export default setFontSize;
