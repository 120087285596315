import React from 'react';
import PropTypes from 'prop-types';

const DialogHeader = ({ children }) => {
  return (
    <div className="dialog__header">
      {children}
    </div>
  );
};

DialogHeader.propTypes = {
  children: PropTypes.node,
};

DialogHeader.defaultProps = {
  children: null,
};

export default DialogHeader;
