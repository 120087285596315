import React, { useContext, useCallback } from 'react';
import PropTypes from 'prop-types';
import FocusControllerContext from './FocusControllerContext';
import {
  getRelatedTarget,
  isInputOrTextarea,
} from './focusUtils';

const restoreFocusPropName = ('focusControllerRestoreFocus').toLowerCase();
const excludeFromRestoreFocusPropName = ('focusControllerPreventRestoreFocus').toLowerCase();
const interceptFocusPropName = ('focusControllerInterceptFocus').toLowerCase();

export const excludeFromRestoreFocus = {
  [`data-${excludeFromRestoreFocusPropName}`]: true,
};

export const interceptFocusDataset = {
  [`data-${interceptFocusPropName}`]: true,
};

const restoreFocusDataset = {
  [`data-${restoreFocusPropName}`]: true,
};

const isDatasetContainsRestore = dataset => (
  {}.hasOwnProperty.call(dataset, restoreFocusPropName)
);
const isNodeHaveExcludedDataset = node => (
  {}.hasOwnProperty.call(node.dataset, excludeFromRestoreFocusPropName)
);
export const isNodeHaveInterceptDataset = node => (
  {}.hasOwnProperty.call(node.dataset, interceptFocusPropName)
);
const isNodeReactSelect = node => (
  (
    node.classList.contains('Select') ||
    node.classList.contains('Select-menu')
  ) && !node.classList.contains('is-disabled')
);


const isExcludedNodeBy = (...fns) => (target) => {
  let node = target;

  while (node) {
    // Children may be in Portal
    if (node === document.body) return false;
    if (isDatasetContainsRestore(node.dataset)) return false;

    for (let i = 0; i < fns.length; i += 1) {
      if (fns[i](node)) return true;
    }

    node = node.parentNode;
  }

  return false;
};

const isExcluded = isExcludedNodeBy(
  isNodeHaveExcludedDataset,
  isNodeReactSelect,
);

const useRestoreFocus = ({
  isSafariDesktop,
  isInternetExplorer11,
}) => {
  const {
    restoreFocus,
    disableBlur,
    enableBlur,
  } = useContext(FocusControllerContext);

  const handleClickCapture = useCallback(
    (event) => {
      if (isExcluded(event.target)) return;
      if (isInputOrTextarea(event.target)) return;
      restoreFocus();
    },
    [restoreFocus],
  );

  const handleFocusCapture = useCallback(
    (event) => {
      if (isInputOrTextarea(event.target)) {
        disableBlur();
      }
    },
    [disableBlur],
  );

  const handleBlurCapture = useCallback(
    isSafariDesktop
      ? (event) => {
        const relatedTarget = getRelatedTarget({ event, isInternetExplorer11 });
        if (isExcluded(event.target)) return;
        if (relatedTarget && isInputOrTextarea(relatedTarget)) return;
        if (isInputOrTextarea(event.target)) {
          enableBlur();
          setTimeout(restoreFocus, 0);
        }
      }
      : (event) => {
        const relatedTarget = getRelatedTarget({ event, isInternetExplorer11 });
        if (isExcluded(event.target)) return;
        if (relatedTarget && isInputOrTextarea(relatedTarget)) return;
        if (isInputOrTextarea(event.target)) {
          enableBlur();

          if (!relatedTarget || !isNodeHaveInterceptDataset(relatedTarget)) {
            restoreFocus();
          }
        }
      },
    [enableBlur, restoreFocus],
  );

  const handleMouseDownCapture = disableBlur;

  const handleMouseUpCapture = (event) => {
    enableBlur();
    if (isExcluded(event.target)) return;
    if (isInputOrTextarea(event.target)) return;


    if (isInternetExplorer11) {
      // JSF-7438: restoreFocus should be called with delay in ie11
      // that prevented specific multiple event captures
      setTimeout(restoreFocus, 0);
    } else {
      restoreFocus();
    }
  };

  return {
    handleClickCapture,
    handleFocusCapture,
    handleBlurCapture,

    handleMouseUpCapture,
    handleMouseDownCapture,
  };
};

const FocusControllerRestoreFocusArea = ({
  children,
  isSafariDesktop,
  isInternetExplorer11,
  ...props
}) => {
  const {
    handleClickCapture,
    handleFocusCapture,
    handleBlurCapture,

    handleMouseUpCapture,
    handleMouseDownCapture,
  } = useRestoreFocus({ isSafariDesktop, isInternetExplorer11 });

  return (
    <div
      onClickCapture={handleClickCapture}
      onFocusCapture={handleFocusCapture}
      onBlurCapture={handleBlurCapture}
      onMouseDownCapture={handleMouseDownCapture}
      onMouseUpCapture={handleMouseUpCapture}
      {...restoreFocusDataset}
      {...props}
    >
      {children}
    </div>
  );
};

FocusControllerRestoreFocusArea.propTypes = {
  children: PropTypes.node.isRequired,
  isSafariDesktop: PropTypes.bool,
  isInternetExplorer11: PropTypes.bool,
};

FocusControllerRestoreFocusArea.defaultProps = {
  isSafariDesktop: false,
  isInternetExplorer11: false,
};

export default FocusControllerRestoreFocusArea;
