import PropTypes from 'prop-types';
import React, { Component } from 'react';
import cls from 'classnames';

export const toolbarThemes = {
  lite: 'lite',
  bordered: 'bordered',
  solidLightgray: 'solid-lightgray',
  null: null,
};

export const toolbarSizes = {
  small: 'small',
  medium: 'medium',
  extraLarge: 'extra-large',
  extraSmall: 'extra-small',
  parent: 'parent',
  mediumStretched: 'medium-stretched',
  null: null,
};

export const toolbarTypes = {
  narrow: 'narrow',
  common: 'common',
  slideItems: 'slide-items',
  tile: 'tile',
  separated: 'separated',
  null: null,
};

export const toolbarBehaviors = {
  wrapItems: 'wrap-items',
  null: null,
};

export const toolbarOffsets = {
  extraSmallHorizontal: 'extra-small-horizontal',
  extraSmall: 'extra-small',
  null: null,
};

export default class ToolBarBody extends Component {
  static propTypes = {
    children: PropTypes.node,

    theme: PropTypes.oneOf(
      Object.values(toolbarThemes),
    ),
    size: PropTypes.oneOf(
      Object.values(toolbarSizes),
    ),
    type: PropTypes.oneOf(
      Object.values(toolbarTypes),
    ),
    behavior: PropTypes.oneOf(
      Object.values(toolbarBehaviors),
    ),
    offset: PropTypes.oneOf(
      Object.values(toolbarOffsets),
    ),
    storeRef: PropTypes.func,
  };

  static defaultProps = {
    children: null,
    theme: toolbarThemes.null,
    size: toolbarSizes.null,
    type: toolbarTypes.null,
    behavior: toolbarBehaviors.null,
    offset: toolbarOffsets.null,
    storeRef: null,
  };

  render() {
    const { theme, size, type, behavior, offset } = this.props;

    return (
      <div
        className={cls(
          'toolbar', {
            [`toolbar--theme--${theme}`]: theme,
            [`toolbar--size--${size}`]: size,
            [`toolbar--type--${type}`]: type,
            [`toolbar--behavior--${behavior}`]: behavior,
            [`toolbar--offset--${offset}`]: offset,
          },
        )}
      >
        <div className="toolbar__body" ref={this.props.storeRef}>
          {this.props.children}
        </div>
      </div>
    );
  }
}
