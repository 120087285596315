import { eventChannel, delay } from 'redux-saga';
import find from 'lodash/find';
import isEqual from 'lodash/isEqual';
import isNumber from 'lodash/isNumber';
import { take, takeEvery, select, put, fork, cancel, call } from 'redux-saga/effects';
import logger from '@pdffiller/jsf-logger/clientLogger';
import { trackPoint, loadingPage, destroy, actionTypes, applyConfigPages } from 'ws-editor-lib/actions';
import getLoaderApi from '@pdffiller/jsf-loader/api';
import getPdfjsPromise from '../../../jsf-pdfjs';
import { setActivePage, saveActivePage } from '../../modules/navigation';
import {
  // getDocument,
  setPdfCompletelyLoaded,
  setLoadingProgress,
  SET_PDF_COMPLETELY_LOADED,
  GET_DOCUMENT_SUCCESS,

  getDocumentActionCreator,
  getDocumentSuccessActionCreator,
  getDocumentFailedActionCreator,
} from '../../modules/pdf';
import pdfDocumentStore from '../../helpers/pdfDocumentStore';
import { resetForReloadPdf } from '../../modules/actions';
import config from '../../../helpers/clientConfig';
import { selectors } from '../../..';
import JSFillerError from '../../../errors/JSFillerError';
import profiler from '../../../helpers/profiler';
import { getDefaultPagesSettings } from '../../../helpers/utils';

const log = logger.getLogger('middleware');

const rangeChunkSize = 65536 * 16;

let pdfjs = null;
let tryCounter = 1;

const sourceStatuses = {
  error: 'ERROR',
  finished: 'FINISHED',
  pending: 'PENDING',
};

export const selector = (state) => {
  return {
    activePageId: selectors.base.getActivePageId(state),
    hasPdfDocument:
      state.pdf.hasPdfDocument,
    sourceFilename:
      (state.ws.source &&
      state.ws.source.pdf &&
      state.ws.source.pdf.url) || false,
    sourceStatus:
      (state.ws.source &&
      state.ws.source.pdf &&
      state.ws.source.pdf.status) || false,
    pages: selectors.base.getWsPages(state),
    errorLocation:
      (state.ws.source &&
      state.ws.source.pdf &&
      state.ws.source.pdf.errorLocation) || false,
  };
};

const countSelector = (state) => {
  return {
    count: state.pdf.count,
  };
};

const loadingProgressSelector = (state) => {
  return state.pdf.loadingProgress;
};

export function* watchOnProgress(channel) {
  let progressData = yield take(channel);
  const {
    document: loaderDocumentStepIndex,
    editor: loaderEditorStepIndex,
  } = getLoaderApi().getStepsIndexes();

  function* done() {
    getLoaderApi().setStep({
      stepIndex: loaderEditorStepIndex,
    });
    const isVersionMode = yield select(selectors.mode.isVersions);
    if (!isVersionMode) {
      yield put(trackPoint('DOCUMENT_LOADED', {}));
    }
    const count = yield select(countSelector);
    yield put(loadingPage(count, count));
    yield put(setPdfCompletelyLoaded());
  }

  // AB: в FF иногда не приходит total из-за этого мы никогда не выходим.
  // Ждем 1с после последнего progress и выходим
  function* delayDone() {
    yield call(delay, 1000);
    yield put(setLoadingProgress(100));
    yield call(done);
  }

  let doneTimeout = yield fork(delayDone);

  // eslint-disable-next-line no-constant-condition
  while (true) {
    progressData = yield take(channel);

    if (doneTimeout) {
      yield cancel(doneTimeout);
      doneTimeout = null;
    }

    if (!progressData.total) {
      doneTimeout = yield fork(delayDone);
    }

    if (!Number.isNaN(progressData.loaded)) {
      const prevProgress = yield select(loadingProgressSelector);
      const total = !progressData.total ? 1024*1024 : progressData.total; // eslint-disable-line
      const level = progressData.loaded / total;
      let percent = Math.round(level * 100);
      if (!progressData.total && percent >= 100) {
        percent = 99;
      }
      if (prevProgress > percent) {
        percent = prevProgress;
      }
      if (percent !== prevProgress) {
        yield put(setLoadingProgress(percent));
      }
      getLoaderApi().setStepProgress({
        stepIndex: loaderDocumentStepIndex,
        percent,
      });
      if (progressData.total && percent >= 100) {
        yield call(done);
      }
    }
  }
}

function* setActivePageIfNeed({ pages, activePageId }) {
  const activePageIdFromStore = yield select(selectors.base.getSavedActivePageId);
  const activePageIdFromRearrange = yield select(selectors.base.getSavedActivePageFromRearrange);

  const savedActivePageId = isNumber(activePageIdFromRearrange) && activePageIdFromRearrange >= 0
    ? activePageIdFromRearrange
    : activePageIdFromStore;

  const savedPageSettings = find(pages, (page) => {
    return page.source === savedActivePageId;
  });

  if (savedActivePageId && savedPageSettings && savedPageSettings.visible) {
    if (savedActivePageId !== activePageId) {
      yield put(setActivePage(savedActivePageId, { scenario: true }));
    }
    yield put(saveActivePage(false));
    return;
  }

  const firstVisiblePage = find(pages, ({ visible }) => {
    return !!visible;
  });
  if (firstVisiblePage && firstVisiblePage.source !== activePageId) {
    yield put(setActivePage(firstVisiblePage.source, { scenario: true }));
  }
}

export function* getDocument(loadingTask) {
  profiler('BeginGetPdf');
  yield put(getDocumentActionCreator());

  try {
    const result = yield loadingTask;
    const wsPages = yield select(selectors.base.getWsPages);

    if (!wsPages) {
      yield put(applyConfigPages(
        getDefaultPagesSettings(result.numPages), {
          isInitialize: true,
          isOnlyStateUpdate: yield select(selectors.mode.isVersions),
        },
      ));
    }

    const pagesSettings = yield select(selectors.navigation.getPagesSettings);

    yield put(getDocumentSuccessActionCreator({
      result,
      pagesSettingsLength: pagesSettings.length,
    }));
    profiler('EndGetPdf');
  } catch (error) {
    if (tryCounter < 3) {
      const { activePageId, sourceFilename, pages } = yield select(selector);
      const arrayBuffer = pdfDocumentStore.getLocalPdf();
      tryCounter += 1;
      yield delay(1000);
      // eslint-disable-next-line no-use-before-define
      yield doLoad({ pages, sourceFilename, activePageId, arrayBuffer });
    } else {
      log.error(`Error in getDocument thunk (${tryCounter} attempts)`, error);

      yield put(getDocumentFailedActionCreator({ error }));
    }
  }
}

export function* doLoad({ pages, sourceFilename, activePageId, arrayBuffer }) {
  const {
    document: loaderDocumentStepIndex,
  } = getLoaderApi().getStepsIndexes();

  getLoaderApi().setStep({
    stepIndex: loaderDocumentStepIndex,
  });

  let emitter;
  const channel = eventChannel((em) => {
    emitter = em;
    return () => {};
  });

  let loadingTask = null;
  const progressFork = yield fork(watchOnProgress, channel);

  const isVersionMode = yield select(selectors.mode.isVersions);
  if (!isVersionMode) {
    yield put(trackPoint('DOCUMENT_STARTED', { url: sourceFilename }));
  }

  if (pdfDocumentStore.getPdfDocument()) {
    loadingTask = new Promise((resolve) => {
      resolve(pdfDocumentStore.getPdfDocument());
    });
    setTimeout(() => {
      emitter({ loaded: 1, total: 1 });
    }, 5);
  } else {
    let pdfjsModule;
    try {
      pdfjsModule = yield getPdfjsPromise();
      pdfjs = pdfjsModule.default;
    } catch (error) {
      throw new JSFillerError(`JSF-6406 getPdfjsPromise ${error}`);
    }

    /**
     * Temporary try-catch for debugging task:
     * https://pdffiller.atlassian.net/browse/JSF-6406
     */
    try {
      loadingTask = pdfjs.getDocument({
        ...(arrayBuffer
          ? { data: arrayBuffer }
          : { url: sourceFilename }),
        rangeChunkSize,
        cMapPacked: true,
        cMapUrl: `${config.publicPath}js/cmaps/`,
      });
    } catch (error) {
      throw new JSFillerError(`JSF-6406 ${error}`, {
        pdfjsModuleKeys: pdfjsModule
          ? JSON.stringify(Object.keys(pdfjsModule))
          : pdfjsModule,
        pdfjsKeys: pdfjs
          ? JSON.stringify(Object.keys(pdfjs))
          : pdfjs,
        arrayBuffer: !!arrayBuffer,
        sourceFilename: !!sourceFilename,
      });
    }
    loadingTask.onProgress = emitter;
  }

  yield setActivePageIfNeed({ pages, activePageId });
  yield fork(getDocument, loadingTask);
  yield take(SET_PDF_COMPLETELY_LOADED);
  yield put({ type: '@@page-rearrange/SET_PDF_COMPLETELY_LOADED' });
  yield cancel(progressFork);
}

export function* pendingDestroy({ errorLocation }) {
  yield delay(
    config.app
      ? config.app.documentProcessing.loadPendingDelay
      : 5000,
  );
  yield put(destroy(errorLocation));
}

const isInitialPagesAction = (action) => {
  return (
    action &&
    action.type === actionTypes.APPLY_CONFIG_PAGES &&
    action.opts.isInitialize === true
  );
};

const isResetWSPages = (action) => {
  return (
    action &&
    action.type === actionTypes.RESET_WS_PAGES
  );
};

const getIsSourceEqual = (next, prev) => {
  return (
    next.sourceStatus === prev.sourceStatus &&
    next.sourceFilename === prev.sourceFilename
  );
};

const getIsPagesChanged = (next, prev) => {
  return (
    next.pages !== prev.pages &&
    !isEqual(next.pages, prev.pages)
  );
};

export const getLoadPdfController = () => {
  let selectedValues = {
    activePageId: 0,
    hasPdfDocument: false,
    sourceFilename: false,
    sourceStatus: false,
    pages: null,
    errorLocation: false,
  };
  let pendingDestroyTask = false;

  return function* loadPdfControllerF(action) {
    const newSelectedValues = yield select(selector);

    if (isInitialPagesAction(action) || isResetWSPages(action)) {
      selectedValues = newSelectedValues;
      return;
    }

    let isPagesChangedAndHasPdfDocument = false;
    const isSourceEqual = getIsSourceEqual(newSelectedValues, selectedValues);
    const isPagesChanged = getIsPagesChanged(newSelectedValues, selectedValues);

    if (isSourceEqual && isPagesChanged) {
      selectedValues.pages = newSelectedValues.pages;
      if (newSelectedValues.hasPdfDocument) {
        isPagesChangedAndHasPdfDocument = true;
      }
    }

    if (!isSourceEqual || isPagesChangedAndHasPdfDocument) {
      selectedValues = newSelectedValues;
      const {
        sourceFilename,
        sourceStatus,
        errorLocation,
        hasPdfDocument,
        pages,
        activePageId,
      } = selectedValues;

      if (errorLocation && !isPagesChangedAndHasPdfDocument) {
        if (sourceStatus === sourceStatuses.pending) {
          pendingDestroyTask = yield fork(pendingDestroy, { errorLocation });
        }

        if (sourceStatus === sourceStatuses.error) {
          if (pendingDestroyTask) {
            yield cancel(pendingDestroyTask);
          }
          yield put(destroy(errorLocation));
        }
      }

      if (sourceStatus === sourceStatuses.finished) {
        if (pendingDestroyTask) {
          yield cancel(pendingDestroyTask);
        }
        if (hasPdfDocument) {
          yield put(resetForReloadPdf(!isSourceEqual));
        }

        const arrayBuffer = pdfDocumentStore.getLocalPdf();
        yield doLoad({ pages, sourceFilename, activePageId, arrayBuffer });
      }
    }
  };
};

export const loadPdfController = getLoadPdfController();

export default function* loadPdfRootSaga() {
  const watchedActions = [
    actionTypes.ON_OPERATIONS_DOCUMENTSOURCE_TOOLSPAGES, // pages
    actionTypes.ON_OPERATIONS_TOOLS_PAGES, // pages
    actionTypes.APPLY_CONFIG_PAGES, // pages
    actionTypes.RESET_WS_PAGES, // pages
    actionTypes.SET_PDF_DOCUMENT_SCALE, // pages
    actionTypes.ON_OPERATIONS_DOCUMENT_SOURCE, // source
    actionTypes.FULL_RELOAD, // source
    GET_DOCUMENT_SUCCESS, // hasPdfDocument
  ];
  yield takeEvery(watchedActions, loadPdfController);
}
