import without from 'lodash/without';
import pick from 'lodash/pick';
import uniq from 'lodash/uniq';
// import isEmpty from 'lodash/isEmpty';
import {
  createFlatId,
  isVisible,
  needHandleOperationFromServer,
  createCommentFromOperation,
  getCommentUsersToFetch,
  transformCommentToClientScheme,
  createCommentsMapFromArray,
  queryUsers,
} from '../Utils';

import {
  applyUpdateToComment,
  addCommentInComments,
} from '../../Comments/utils';
import { TYPES } from '../../../../constants';

export function updateCommentsByOps(batch, state) {
  const { comments, commentsMap } = state;
  const newComments = comments
    .map((el) => {
      const ops = batch[el.id];
      if (ops === undefined) {
        return el;
      }

      return ops.reduce((element, op) => {
        return applyUpdateToComment(
          transformCommentToClientScheme(op.properties),
          element,
        );
      }, commentsMap[el.id]);
    });

  return {
    sendOperationsCount: state.sendOperationsCount,
    comments: newComments,
    commentsMap: createCommentsMapFromArray(newComments),
  };
}

export function deleteCommentsByOps(batch, state) {
  if (batch === undefined) {
    return state;
  }

  const commentsToDelete = Object.keys(batch);
  if (commentsToDelete.length === 0) {
    return state;
  }

  const commentsWithoutDeletedElements = without(
    state.comments,
    ...commentsToDelete.map((id) => {
      return state.commentsMap[id];
    }),
  );

  return {
    ...state,
    comments: commentsWithoutDeletedElements,
    commentsMap: createCommentsMapFromArray(commentsWithoutDeletedElements),
  };
}

export function createCommentsByOps(batch, state) {
  const ops = Object.values(batch);
  if (ops === undefined || ops.length === 0) {
    return state;
  }

  const newState = ops.reduce(
    (acc, opsArray) => {
      const { element, sendOperationsCount: soc } = createCommentFromOperation(
        opsArray[0],
        { sendOperationsCount: acc.sendOperationsCount },
      );

      return {
        sendOperationsCount: soc,
        comments: addCommentInComments(element, acc.comments),
      };
    },
    state,
  );

  return {
    ...newState,
    commentsMap: createCommentsMapFromArray(newState.comments),
  };
}

function getAction(acc, id, op) {
  const { batch, state } = acc;

  if (!isVisible(op.properties)) {
    return TYPES.delete;
  }

  // do update op if element exists in batch and is visible
  // do update op if element is in commentsMap
  // do not take UPDATE_OP in a batch calculation if it was contains TYPES.delete
  if (
    state.commentsMap[id] !== undefined ||
    (acc.action !== TYPES.delete && batch[id] !== undefined)
  ) {
    return TYPES.update;
  }

  return TYPES.create;
}

function applyBatchToState(acc) {
  const { action, state, batch } = acc;
  switch (action) {
    case TYPES.update:
      return updateCommentsByOps(batch, state);
    case TYPES.delete:
      return deleteCommentsByOps(batch, state);
    case TYPES.create:
      return createCommentsByOps(batch, state);
    default:
      return state;
  }
}

export function doComments(transport, operations, state) {
  const usersToFetch = [];
  const result = operations.reduce((acc, op, index) => {
    if (!needHandleOperationFromServer({
      operation: op,
      operations,
      operationIndex: index,
      // JSF-6671 JSF-7238
      // elements map need for check that element was deleted and added after undo/redo
      // in this case delete operation will be skipped
      elementsMap: state.commentsMap,
    })) {
      return acc;
    }

    const id = createFlatId(op.properties.element);
    const { action: prevAction } = acc;
    const action = getAction(acc, id, op);
    if (action === TYPES.update || action === TYPES.create) {
      getCommentUsersToFetch(op.properties).forEach((userId) => {
        if (state.users[userId] !== undefined) {
          return;
        }
        usersToFetch.push(userId);
      });
    }
    if (action !== prevAction) {
      const appliedBatchToState = applyBatchToState(acc);
      return {
        action: getAction({
          ...acc,
          state: appliedBatchToState,
        }, id, op),
        state: appliedBatchToState,
        batch: { [id]: [op] },
      };
    }

    acc.batch[id] = acc.batch[id]
      ? [...acc.batch[id], op]
      : [op];

    return acc;
  }, {
    state: pick(state, ['comments', 'commentsMap', 'sendOperationsCount']),
    action: '',
    batch: {},
  });

  // ensure to apply last batch
  const lastBatchState = applyBatchToState(result);
  const { sendOperationsCount } = queryUsers(
    transport,
    uniq(usersToFetch),
    { sendOperationsCount: lastBatchState.sendOperationsCount },
  );

  return {
    ...state,
    sendOperationsCount,
    comments: lastBatchState.comments,
    commentsMap: createCommentsMapFromArray(lastBatchState.comments),
  };
}
