import { select, put, takeEvery } from 'redux-saga/effects';
import { destroy } from 'ws-editor-lib/actions';
import { GO_TO_FLASH_SITE } from 'jsfcore/store/modules/navigationActionTypes';

const exitExperimentUrl = (state) => {
  return state.ws.exitExperimentUrl;
};

export function* redirectToFlashEditor() {
  const url = yield select(exitExperimentUrl);

  if (url) {
    yield put(destroy(url));
  }
}

export function* watchRedirectToFlashEditor() {
  yield takeEvery(GO_TO_FLASH_SITE, redirectToFlashEditor);
}
