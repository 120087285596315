import mergeWith from 'lodash/mergeWith';

import { actionTypes } from 'ws-editor-lib/actions';
import { selectors, thunks } from 'jsfcore';

import { changePage } from '../../modules/navigation';
import { SET_PA_ACTIVE_SECTION } from '../../modules/viewport';
import { toggleSearchNext, toggleSearchPrev } from '../../modules/search';

import {
  UNDO,
  REDO,
  MERGE_UNDO_REDO_FROM_WATERMARK,
  CLEAR_UNDO_REDO_HISTORY,
  DIVIDE_UNDO_REDO,
  getOptsForUndoRedo,
} from '../../modules/undoRedo';

const {
  ADD_ELEMENT,
  ADD_FILLABLE_ELEMENT,
  ADD_ELEMENTS,
  UPDATE_ELEMENT,
  UPDATE_ORDER_FILLABLE_ELEMENTS,
  REMOVE_FILLABLE_ELEMENT,
  REMOVE_ELEMENT,
  REMOVE_ELEMENTS,
  SET_PA_SETTINGS,
} = actionTypes;

const mergeUndoRedoActions = (mergingActions) => {
  /**
   * 'Mergeable' элементы истории должны быть смержены в один элемент
   * истории, например: [{
   *   undo: updateElement({ text: '', bold: false }),
   *   redo: updateElement({ text: '2', bold: true }),
   * }, {
   *   undo: updateElement({ text: '2', italic: false }),
   *   redo: updateElement({ text: '1', italic: true }),
   * }]
   *
   * Должны быть объединены в: {
   *   undo: updateElement({ text: '', bold: false, italic: false }),
   *   redo: updateElement({ text: '1', bold: true, italic: true }),
   * }
   *
   * В данный момент используется для объединения updateElement
   * при изменении lineWidth с помощью range-slider
   * Далее планируется использовать еще и при ресайзе текстовых элементов
   */
  return mergingActions.reduce((acc, action, index) => {
    if (!acc.length || typeof action === 'function') {
      acc.push(action);
      return acc;
    }
    const lastMergedAction = acc[acc.length - 1];
    if (
      mergingActions[index].id === lastMergedAction.id &&
      mergingActions[index].type === lastMergedAction.type
    ) {
      mergeWith(lastMergedAction, mergingActions[index],
        // by default _.merge doesn't assign property of source to destination object
        // if source object's property is undefined
        // but we need to assign it in this case
        (objValue, srcValue, key, object) => {
          if (srcValue === undefined) {
            // eslint-disable-next-line no-param-reassign
            delete object[key];
          }
        },
      );
    } else {
      acc.push(action);
    }
    return acc;
  }, []);
};

const injectMergedActionsIntoHistory = (history, mergedActions, cursor, actionType) => {
  return history.reduce((acc, step, index) => {
    if (index === cursor) {
      acc.push({
        ...step,
        ...actionType === UNDO && {
          undo: mergedActions,
          isUndoMerged: true,
        },
        ...actionType === REDO && {
          redo: mergedActions,
          isRedoMerged: true,
        },
      });
    } else {
      acc.push(step);
    }
    return acc;
  }, []);
};

export function onUndoRedo(action, additionalActionsInjection) {
  return (dispatch, getState) => {
    const state = getState();

    const {
      history,
      cursor,
      activePageId,
    } = selectors.getUndoRedo(state);
    const isUndo = action.type === UNDO;
    const isRedo = action.type === REDO;
    // Needed to store in redux
    const newCursor = isUndo
      ? cursor + 1
      : cursor - 1;
    // Needed to handle current actions array in history
    const currentHistoryCursor = isUndo
      ? cursor
      : cursor - 1;

    const historyByCursor = history[currentHistoryCursor];

    const {
      undo: undoHistoryActions,
      redo: redoHistoryActions,
      isUndoMerged,
      isRedoMerged,
    } = historyByCursor;

    const mergingActions = isUndo
      ? undoHistoryActions
      : redoHistoryActions;

    const isMerged = (isUndo && isUndoMerged) || (isRedo && isRedoMerged);

    const mergedHistoryActions = isMerged
      ? mergingActions
      : mergeUndoRedoActions(mergingActions);

    if (isUndo || isRedo) {
      dispatch({
        type: action.type,
        payload: {
          cursor: newCursor,
          ...!isMerged && {
            updatedHistory: injectMergedActionsIntoHistory(
              history,
              mergedHistoryActions,
              currentHistoryCursor,
              action.type,
            ),
          },
        },
      });
    }

    if (historyByCursor === undefined) {
      return;
    }

    const ensureActivePage = (newPageId) => {
      if (newPageId != null && activePageId !== newPageId) {
        dispatch(changePage(newPageId, activePageId));
      }
    };
    const ensureActivePageForElement = (elementId) => {
      const element = selectors.elements.getElementFromMapFactory(elementId)(state);
      if (element) {
        ensureActivePage(element.pageId);
      }
    };
    const ensureActiveElement = (newElementId) => {
      const currentActiveElementId = selectors.base.getActiveElementId(getState());

      if (currentActiveElementId === newElementId) {
        return;
      }

      if (currentActiveElementId) {
        dispatch(thunks.setActiveElement(
          currentActiveElementId,
          false,
          getOptsForUndoRedo(),
        ));
      }

      if (newElementId && selectors.elements.getElementFromMapFactory(newElementId)(getState())) {
        dispatch(thunks.setActiveElement(newElementId, true, getOptsForUndoRedo()));
      }
    };

    const dispatchUndoRedoAction = (undoRedoAction) => {
      // execute external actions from another editors
      if (additionalActionsInjection) {
        dispatch(additionalActionsInjection(undoRedoAction, { isUndo }));
      }

      const { id: elementId } = undoRedoAction;

      switch (undoRedoAction.type) {
        case UPDATE_ELEMENT: {
          ensureActivePageForElement(elementId);
          ensureActiveElement(elementId);
          dispatch(undoRedoAction);
          return;
        }

        case ADD_FILLABLE_ELEMENT:
        case ADD_ELEMENT: {
          ensureActivePageForElement(elementId);
          ensureActiveElement();
          dispatch(undoRedoAction);
          dispatch(thunks.setActiveElement(elementId, true, getOptsForUndoRedo()));
          return;
        }

        case REMOVE_FILLABLE_ELEMENT:
        case REMOVE_ELEMENT: {
          ensureActivePageForElement(elementId);

          const currentActiveElementId = selectors.base.getActiveElementId(getState());
          const needToClearActiveElement = currentActiveElementId === elementId;
          if (needToClearActiveElement) {
            ensureActiveElement();
          }

          dispatch(undoRedoAction);
          break;
        }

        case UPDATE_ORDER_FILLABLE_ELEMENTS:
        case SET_PA_SETTINGS:
        case SET_PA_ACTIVE_SECTION:
          dispatch(undoRedoAction);
          break;

        case ADD_ELEMENTS: {
          const anyElement = undoRedoAction.elements[0];
          const occurences = selectors.base.getOccurences(state);

          if (anyElement) {
            ensureActivePage(anyElement.pageId);
          }

          if (occurences.length > 1) {
            dispatch(toggleSearchNext());
          }

          dispatch(undoRedoAction);
          break;
        }

        case REMOVE_ELEMENTS: {
          const anyElementId = undoRedoAction.ids[0];
          const removingElement = selectors.base.getElement(state, anyElementId);
          const occurences = selectors.base.getOccurences(state);

          if (removingElement) {
            ensureActivePage(removingElement.pageId);
          }

          if (occurences.length > 1) {
            dispatch(toggleSearchPrev());
          }

          const currentActiveElementId = selectors.base.getActiveElementId(getState());
          const needToClearActiveElement = undoRedoAction.type === REMOVE_ELEMENTS &&
            undoRedoAction.ids.includes(currentActiveElementId);

          if (needToClearActiveElement) {
            ensureActiveElement();
          }

          dispatch(undoRedoAction);
          break;
        }

        default:
          dispatch(undoRedoAction);
      }
    };

    mergedHistoryActions.forEach((subAction) => {
      dispatchUndoRedoAction(subAction);
    });
  };
}

export function undo() {
  return (dispatch) => {
    dispatch(onUndoRedo({ type: UNDO }));
  };
}

export function redo() {
  return (dispatch) => {
    dispatch(onUndoRedo({ type: REDO }));
  };
}

export function mergeUndoRedoFromWatermark() {
  return { type: MERGE_UNDO_REDO_FROM_WATERMARK };
}

export function clearUndoRedoHistory() {
  return { type: CLEAR_UNDO_REDO_HISTORY };
}

export function divideUndoRedo(divideIndex = 0) {
  return {
    type: DIVIDE_UNDO_REDO,
    payload: {
      divideIndex,
    },
  };
}
