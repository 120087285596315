import {
  send,
  createIdAndNext,
} from '../Utils';
import { GROUPS, TYPES } from '../../../../constants';

export const doVersionsList = (transport, lastOperationsCount) => {
  const { id, sendOperationsCount } = createIdAndNext(lastOperationsCount);

  const properties = {
    group: GROUPS.versions,
    type: TYPES.list,
  };

  send(
    transport,
    id,
    properties,
  );

  return { sendOperationsCount };
};

export const doPreviewVersion = (transport, lastOperationsCount, action) => {
  const { id, sendOperationsCount } = createIdAndNext(lastOperationsCount);
  const { versionId, versionType } = action;

  const properties = {
    group: GROUPS.versions,
    type: TYPES.preview,
    subType: versionType,
    version: versionId,
  };

  send(
    transport,
    id,
    properties,
  );

  return { sendOperationsCount };
};

export const doRestoreVersion = (transport, lastOperationsCount, action) => {
  const { id, sendOperationsCount } = createIdAndNext(lastOperationsCount);
  const { versionId, versionType } = action;

  const properties = {
    group: GROUPS.versions,
    type: TYPES.restore,
    subType: versionType,
    version: versionId,
  };

  send(
    transport,
    id,
    properties,
  );

  return { sendOperationsCount };
};

export const doSaveVersion = (transport, lastOperationsCount, action) => {
  const { id, sendOperationsCount } = createIdAndNext(lastOperationsCount);
  const { versionId, versionType } = action;

  const properties = {
    group: GROUPS.versions,
    type: TYPES.save,
    subType: versionType,
    version: versionId,
  };

  send(
    transport,
    id,
    properties,
  );

  return { sendOperationsCount };
};
