import { createSelector } from 'reselect';
import get from 'lodash/get';
import elementsSelectors from '../../store/selectors/elementsSelectors';
import * as baseSelectors from '../../store/selectors/baseSelectors';
import { getMergedValidators } from './validatorsSelectors';
import { isDate } from '../../store/helpers/functions';

export const getConstructorErrors = (state) => {
  return state.errors.constructorErrors;
};

// "isGlobal" - it's a boolean flag which define visibility of this error under the "Done" button
// false value means that error is local and shouldn't influence on Done button
export const getAllConstructorErrors = createSelector(
  [getConstructorErrors],
  (constructorErrors) => {
    const allConstructorErrors = [];

    Object.values(constructorErrors).forEach(({ errors }) => {
      allConstructorErrors.push(...(errors.filter(({ isGlobal }) => {
        return isGlobal;
      })));
    });

    return allConstructorErrors;
  },
);

export const defaultValidatorState = {
  isValid: true,
  errors: [],
};

export const getConstructorErrorsByElement = (state, elementId) => {
  return getConstructorErrors(state)[elementId] || defaultValidatorState;
};

export const getElementsWithValidationToPageIdMap = createSelector(
  [
    elementsSelectors.getFillableEnabledElements,
    getMergedValidators,
    baseSelectors.getIsFConstructorShown,
  ],
  (elements, validators, isFConstructorShown) => {
    const pathToContentForValidation = isFConstructorShown
      ? 'template.initial'
      : 'content.text';
    return elements.reduce((acc, element) => {
      const validatorId = get(element, 'template.validatorId', '');
      const value = get(element, pathToContentForValidation, '');

      if (!validatorId) {
        return acc;
      }

      const validator = validators.find(({ id }) => {
        return id === validatorId;
      });

      if (!validator) {
        return acc;
      }

      // https://pdffiller.atlassian.net/browse/JSF-5140
      // removed validation for Date field with No Date
      // for selected No Date option
      // initial stores date format
      // and it equal validator.momentFormat value
      if (isFConstructorShown && isDate(element) && value === validator.momentFormat) {
        return acc;
      }

      const { pageId } = element;
      acc[pageId] = acc[pageId] || [];
      acc[pageId].push({ id: element.id, value, validator });

      return acc;
    }, {});
  },
);

export const getElementsWithWValidationToCurrentPage = createSelector(
  [getElementsWithValidationToPageIdMap, baseSelectors.getActivePageId],
  (elements, pageId) => {
    return elements[pageId] || [];
  },
);
