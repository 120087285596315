import remove from 'lodash/remove';

const getRootDeps = (clauses) => {
  return {
    operator: 'or',
    clauses,
    negated: false,
  };
};

export const getNullDeps = () => {
  return {
    value: true,
    name: '',
    negated: false,
  };
};

const normalize = (dependencies) => {
  let depsCopy = dependencies;
  if (depsCopy.clauses) {
    if (depsCopy.clauses.length === 1) {
      depsCopy = normalize(depsCopy.clauses[0]);
    } else {
      depsCopy.clauses = depsCopy.clauses.map((dep) => {
        return normalize(dep);
      });
    }
  }

  return depsCopy;
};

const makeFilterFunc = (index) => {
  const findI = parseInt(index, 10);
  return (_, i) => {
    return i === findI;
  };
};

export const removeDependencyByPath = (dependencies, path) => {
  const pathArray = path.split('.').slice(1);

  if (pathArray.length === 1) {
    remove(
      dependencies.clauses,
      makeFilterFunc(pathArray[0]),
    );
  } else {
    remove(
      dependencies.clauses[pathArray[0]].clauses,
      makeFilterFunc(pathArray[1]),
    );
  }
  return normalize(dependencies);
};

export const getDependenciesByPath = (dependencies, path) => {
  if (path === '0') {
    return dependencies;
  }
  const pathArray = path.split('.').slice(1);
  let partOfDependencies = dependencies;
  for (let i = 0; i < pathArray.length; i++) {
    const road = pathArray[i];
    partOfDependencies = partOfDependencies.clauses[road];
  }

  return partOfDependencies;
};

export const makeLevelInPath = (dependencies, path) => {
  const pathArray = path.split('.').slice(1);
  const depsCopy = dependencies;

  if (pathArray.length === 1) {
    depsCopy.clauses[pathArray[0]] = getRootDeps([
      depsCopy.clauses[pathArray[0]],
      getNullDeps(),
    ]);
  }

  return depsCopy;
};

export const addDepInPath = (dependencies, path) => {
  const depsCopy = dependencies;

  if (path === '0') {
    if (depsCopy.clauses) {
      depsCopy.clauses.push(getNullDeps());
      return depsCopy;
    }

    return getRootDeps([
      dependencies,
      getNullDeps(),
    ]);
  }

  const pathArray = path.split('.').slice(1);
  if (pathArray.length === 1) {
    depsCopy.clauses[pathArray[0]].clauses.push(getNullDeps());
  }

  return depsCopy;
};

export const isAnyFilledDependencyClause = (dependency) => {
  let dependencyClauses = [];
  if (dependency.clauses) {
    dependencyClauses = dependency.clauses;
  } else {
    dependencyClauses.push(dependency);
  }
  return dependencyClauses.some((clause) => {
    return clause.name;
  });
};
