import get from 'lodash/get';
import * as consts from '../../const';

import { elementSettingsTypes } from '../../../helpers/elemTypes';

const validateSmart = (element) => {
  const name = get(element, 'template.name');

  // Smart/Salesforce поле, в которое еще не ни разу не вводилось значение, имеет name === null
  // чтобы не вызывать ошибку сразу после добавления элемента - не валидируем такой случай
  if (name === null) {
    return consts.valid;
  }

  if (!name || !name.trim()) {
    return {
      isValid: false,
      errors: [{
        field: elementSettingsTypes.uniqueFieldName,
        errorText: 'Field is required',
        isGlobal: true,
      }],
    };
  }

  return consts.valid;
};

export default validateSmart;
