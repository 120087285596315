import findLastIndex from 'lodash/findLastIndex';
import flatten from 'lodash/flatten';

import { occurenceTypes, scrollMarginToElement } from './const';
import { getIdFromGeometry } from './eraseShapesForTextContent';
import pdfTextStore from '../store/helpers/pdfTextStore';
import { getIndexByPageId } from './utils';

const divSeparator = ' ';

/**
 * Соединить все пдф-чанки текста в одну строку (разделив пробелом)
 * чтобы искать текст, который простирается на несколько чанков
 * Сохранить позиции, где начинаются чанки в конкатенрованном тексте
 *
 * @param {array} textContent - массив чанков одной страницы, который хранится в сторе
 * @returns {{ text, divPositions }} где text - сконкатенированный текст,
 *   divPositions - arrayOf(int), где i-тый элемент - позиция в тексте где начинается i-й чанк
 */
export const getSquashedText = (textContent) => {
  return textContent
    .reduce((result, item) => {
      const oldText = result.text;

      const separator = oldText.length > 0
        ? divSeparator
        : '';

      const newText = oldText + separator + item.text;
      return {
        text: newText,
        divPositions: [...result.divPositions, oldText.length + separator.length],
      };
    }, { text: '', divPositions: [] });
};

/**
 * Поиск подстроки в строке. Возвращает массивом все нахождения.
 * Так как в пдф-тексте пробелы могут стоять по нескольку подряд -
 * то формируем регулярку которая кушает такие вхождения как один пробел
 * и сохраняем длину нахождения (в символах)
 *
 * @param {string} text - текст, по которому производим поиск
 * @param {string} searchTerm - искомая строка
 * @returns {arrayOf({ pos, length })} где i-тый элемент -
 *   { pos - позиция в тексте, length - длина нахождения }
 */
export const searchInText = (text, searchTerm) => {
  if (typeof searchTerm !== 'string' || searchTerm === '') {
    return [];
  }

  const escapedTerm = searchTerm.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
  // make 'search term   here' to regex 'search +string +here'
  // Нужно в первую очеред для поиска в пдф-тексте: там может быть много пробелов подряд
  const modifiedTerm = escapedTerm.replace(/ +/g, ' +');
  const regex = new RegExp(modifiedTerm, 'ig');

  let result = [];
  let current = null;
  while (current = regex.exec(text)) { // eslint-disable-line
    result = [
      ...result,
      { pos: current.index, length: current[0].length },
    ];
  }
  return result;
};

/**
 * Поиск текста по элементам. Если в одном элементе текст найден несколько раз -
 * то сохраняем для него несколько вхождений.
 *
 * @param {string} searchTerm - искомая строка
 * @param {array} elements - state.ws.elements
 * @returns {array} Массив occurence, они пойдут в state.search.occurences
 */
export const searchInElements = (searchTerm, elements) => {
  if (searchTerm === '') {
    return [];
  }

  // NOTE: we'll PUSH into this array (performance-code)
  const result = [];
  for (let i = 0; i < elements.length; ++i) {
    const element = elements[i];
    if (element.content) {
      const occurences = searchInText(element.content.text, searchTerm);
      if (occurences.length > 0) {
        result.push(...occurences.map((occurence) => {
          return {
            type: occurenceTypes.element,
            elementId: element.id,
            pageId: element.pageId,
            occurPos: occurence.pos,
            occurLength: occurence.length,
            x: element.content.x,
            y: element.content.y,
          };
        }));
      }
    }
  }

  return result;
};

/**
 * Находим, что за массив находится на данной позиции в тексте
 *
 * @param {int} position - позиция в тексте
 * @param {array} divPositions - arrayOf(int), где i-тый элемент -
 * позиция в тексте где начинается i-й чанк (результат ф-ии getSquashedText)
 * @returns {int} индекс массива, который соответствует данной позиции
 */
export const getDivIndexByPosition = (position, divPositions) => {
  return findLastIndex(divPositions, (divPos) => {
    return position >= divPos;
  });
};

/**
 * Поиск текста по pdf-контенту одной страницы.
 *
 * @param {string} searchTerm - искомая строка
 * @param {array} textContent - массив pdf-текст-чанков
 * @param {int} pageId - страница, по которой ищем
 * @returns {array} Массив occurence, они пойдут в state.search.occurences
 */
export const searchInPdfTextPage = (searchTerm, textContent, pageId) => {
  if (searchTerm === '') {
    return [];
  }

  const { text, divPositions } = getSquashedText(textContent);

  const result = searchInText(text, searchTerm)
    .reduce((accum, occurence) => {
      const occurPosEnd = occurence.pos + occurence.length;
      const occurLastDivIndex = getDivIndexByPosition(occurPosEnd, divPositions);

      let currentResult = {
        type: occurenceTypes.pdfText,
        pageId,
        divs: [],
      };

      let mutOccurPos = occurence.pos;
      while (mutOccurPos < occurPosEnd) {
        const divIndex = getDivIndexByPosition(mutOccurPos, divPositions);
        const contentItem = textContent[divIndex];
        const localOccurPos = mutOccurPos - divPositions[divIndex];

        const occurLength =
          divIndex >= occurLastDivIndex
            ? occurPosEnd - mutOccurPos
            : contentItem.text.length - localOccurPos;

        const newItem = {
          content: contentItem,
          occurPos: localOccurPos,
          occurLength,
        };

        currentResult = {
          ...currentResult,
          divs: [...currentResult.divs, newItem],
        };
        mutOccurPos = mutOccurPos + occurLength + divSeparator.length;
      }

      currentResult = {
        ...currentResult,
        // next two - only for sort at 'processSearchTerm' func
        x: currentResult.divs[0].content.x,
        y: currentResult.divs[0].content.y,
        occurPos: currentResult.divs[0].occurPos,
      };
      return [...accum, currentResult];
    }, []);

  return result;
};

/**
 * Поиск текста по pdf-контенту всей pdf-ки.
 *
 * @param {string} searchTerm - искомая строка
 * @param {array} textContent - масств массивов pdf-текст-чанков, по массиву для каждой страницы
 * @returns {array} Массив occurence, они пойдут в state.search.occurences
 */
export const searchInPdfText = (
  searchTerm,
  textContentPages,
  eraseElementShapes,
) => {
  return flatten(
    textContentPages
      .filter((pageId) => {
        return pageId !== null;
      })
      .map((pageId) => {
        const pageTextContent = pdfTextStore.getPdfText(pageId);
        const filteredPageTextContent = pageTextContent.filter(
          (textContent) => {
            if (eraseElementShapes[getIdFromGeometry(textContent)]) {
              return false;
            }

            return true;
          },
        );

        return searchInPdfTextPage(searchTerm, filteredPageTextContent, pageId);
      }),
  );
};

/**
 * Процедура поиска текста.
 * Ищем по элементам, по пдф-тексту, после этого все вместе сортируем "сверху вниз"
 *
 * @param {string} {searchTerm - искомая строка
 * @param {array} elements - state.ws.elements
 * @param {array} textContent} - state.search.textContent
 * @returns {array} state.search.occurences
 */
export const processSearchTerm = (
  {
    searchTerm,
    elements,
    textContentPages,
    pagesSettings,
  },
  eraseElementShapes,
) => {
  const elementOccurences = searchInElements(searchTerm, elements);
  const pdfTextOccurences = searchInPdfText(searchTerm, textContentPages, eraseElementShapes);

  // Т.к. в getIndexByPageId происходит поиск по массиву,
  // то будем кешировать значения чтобы слишком много его не вызывать в сортировке
  const pageIdIndexMap = {};

  return [...elementOccurences, ...pdfTextOccurences]
    .sort((item, another) => {
      // NOTE: порядок важен: самое приоритетное в сортировке - pageId,
      // если они равны - идем дальше, и т.д.
      if (pageIdIndexMap[item.pageId] === undefined) {
        pageIdIndexMap[item.pageId] = getIndexByPageId(item.pageId, pagesSettings);
      }
      if (pageIdIndexMap[another.pageId] === undefined) {
        pageIdIndexMap[another.pageId] = getIndexByPageId(another.pageId, pagesSettings);
      }

      if (pageIdIndexMap[item.pageId] < pageIdIndexMap[another.pageId]) {
        return -1;
      }
      if (pageIdIndexMap[item.pageId] > pageIdIndexMap[another.pageId]) {
        return 1;
      }

      if (item.y < another.y) {
        return -1;
      }
      if (item.y > another.y) {
        return 1;
      }

      if (item.x < another.x) {
        return -1;
      }
      if (item.x > another.x) {
        return 1;
      }

      return item.occurPos < another.occurPos
        ? -1
        : 1;
    })
    .map((item, index) => {
      return { ...item, index };
    });
};

/**
 * Вызывается из SearchLayer.
 * Преобразуем массив occurences (в каждом из которых - массив divs) в плоский список.
 * Добавив каждому объекту флаг 'isActive' - для подсветки текущего нахождения
 * Название функции довольно корявое.
 */
export const getSearchDivFlatList = (searchResults) => {
  return searchResults.reduce((accum, item) => {
    return [
      ...accum,
      ...item.divs.map((div) => {
        return {
          ...div,
          index: item.index,
          pageId: item.pageId,
          type: item.type,
        };
      }),
    ];
  }, []);
};

/**
 * В этом методе мы получаем значения { dLeft, dTop } - на сколько нам нужно подскроллить
 * страницу чтобы данный rect оказался видим полностью
 * Если rect настолько большой, что не влезает по ширине - то скроллим так, чтобы левая грань
 * находилась у верхней границы (по высоте аналогично)
 *
 * leftMargin, topMargin - те отступы, которые оставляем чтобы элемент не был совсем впритык
 * к краю. Если он на момент подскролла полностью виден, но находится очень близко к краю
 * (меньше чем scrollMarginToElement) - то подскролла не происходит (как во флеше)
 */
export const getScrollToRect = ({ workspace, rect }) => {
  let leftMargin = 0;
  let topMargin = 0;

  // Сначала баундим по правому-нижнему краю. Если там все влезает - то остается без изменений
  const isRightVisible = rect.left <= workspace.right - rect.width;
  const leftBoundedByRight = isRightVisible
    ? rect.left
    : workspace.right - rect.width;

  leftMargin = !isRightVisible
    ? scrollMarginToElement
    : 0;

  const isBottomVisible = rect.top <= workspace.bottom - rect.height;
  const topBoundedByBottom = isBottomVisible
    ? rect.top
    : workspace.bottom - rect.height;

  topMargin = !isBottomVisible
    ? scrollMarginToElement
    : 0;

  // Теперь по верхне-левому. Почему это делается в два этапа: если какой-то rect настолько
  // большрой, что не влезает в экран - то мы делаем видимой левуй-верхнюю сторону
  const isLeftVisible = leftBoundedByRight >= workspace.left;
  const boundedLeft = isLeftVisible
    ? leftBoundedByRight
    : workspace.left;

  leftMargin = !isLeftVisible
    ? -scrollMarginToElement
    : leftMargin;

  const isTopVisible = topBoundedByBottom >= workspace.top;
  const boundedTop = isTopVisible
    ? topBoundedByBottom
    : workspace.top;

  topMargin = !isTopVisible
    ? -scrollMarginToElement
    : topMargin;

  return {
    dLeft: (rect.left - boundedLeft) + leftMargin,
    dTop: (rect.top - boundedTop) + topMargin,
  };
};
