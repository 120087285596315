import React from 'react';
import PropTypes from 'prop-types';

const SideBarPagination = ({ children, ...props }) => {
  return (
    <div className="sidebar__pagination" {...props}>
      {children}
    </div>
  );
};

SideBarPagination.propTypes = {
  children: PropTypes.node,
};

SideBarPagination.defaultProps = {
  children: null,
};

export default SideBarPagination;
