import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

export const pageNavigationOffsets = {
  medium: 'medium',
  null: null,
};

const PageNavigationBody = ({ children, offset }) => {
  return (
    <div
      className={classnames(
        'page-navigation',
        {
          [`page-navigation--offset--${offset}`]: offset,
        },
      )}
    >
      <div className="page-navigation__body">
        {children}
      </div>
    </div>
  );
};

PageNavigationBody.propTypes = {
  children: PropTypes.node,
  offset: PropTypes.oneOf(Object.values(pageNavigationOffsets)),
};

PageNavigationBody.defaultProps = {
  children: null,
  offset: null,
};

export default PageNavigationBody;
