import PropTypes from 'prop-types';
import React, { Component } from 'react';
import classnames from 'classnames';
import {
  defaultZoomScale,
  positions,
} from '../../../helpers/const';
import ResizeBox from '../../ResizeBox/ResizeBox';

export const stickyClassName = 'jsf-document-sticky';

export default class StickyToolView extends Component {
  static propTypes = {
    children: PropTypes.arrayOf(
      PropTypes.element.isRequired,
    ).isRequired,
    bgColor: PropTypes.string.isRequired,
    innerStyle: PropTypes.shape({
      marginBottom: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
      ]),
      marginLeft: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
      ]),
      marginRight: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
      ]),
      marginTop: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
      ]),
    }).isRequired, // css-object
    scale: PropTypes.number,
    width: PropTypes.number.isRequired,
    height: PropTypes.number.isRequired,
    isGhost: PropTypes.bool,
    isDisabled: PropTypes.bool.isRequired,
    isActiveElement: PropTypes.bool,

    // from elementDraggableDecorator
    // not required for ghost
    onResizeDragStart: PropTypes.func,
    onResizeDragMove: PropTypes.func,
    onResizeDragStop: PropTypes.func,
    isDragging: PropTypes.bool,
    resizeIndex: PropTypes.number,
  };

  static defaultProps = {
    scale: defaultZoomScale,
    isGhost: false,
    isActiveElement: false,
    onResizeDragStart: () => {
      return false;
    },
    onResizeDragMove: () => {
      return false;
    },
    onResizeDragStop: () => {
      return false;
    },
    isDragging: false,
    resizeIndex: positions.none,
  }

  getDocumentAttrStyle = (innerStyle) => {
    return {
      top: innerStyle.marginTop,
      bottom: innerStyle.marginBottom,
      left: innerStyle.marginLeft,
      right: innerStyle.marginRight,
    };
  };

  render() {
    const { bgColor, isActiveElement, isGhost, isDisabled,
      innerStyle, scale, height, width } = this.props;

    const staticStyle = { width, height };
    const stickyStyle = {
      ...staticStyle,
      backgroundColor: `#${bgColor}`,
    };

    const iconStyle = {
      transform: `scale(${scale})`,
      transformOrigin: '50% 0',
    };

    return (
      <div
        style={stickyStyle}
        className={classnames(stickyClassName, {
          'elementHover-Content': !isDisabled,
        })}
      >
        <div className="jsf-document-sticky__icon-btn" style={iconStyle} />
        <div className="jsf-document-attr" style={this.getDocumentAttrStyle(innerStyle)}>
          {!isGhost && isActiveElement &&
            <ResizeBox
              onResizeDragStart={this.props.onResizeDragStart}
              onResizeDragMove={this.props.onResizeDragMove}
              onResizeDragStop={this.props.onResizeDragStop}
              scale={this.props.scale}
              isDragging={this.props.isDragging}
              resizeIndex={this.props.resizeIndex}
            />
          }
          <div className="jsf-document-attr-inner">
            {this.props.children}
          </div>
        </div>
      </div>
    );
  }
}
