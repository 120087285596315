import get from 'lodash/get';
import { excludeFromRestoreFocus } from '@pdffiller/jsf-focuscontroller';
import { getNumberWithoutDotsOrCommas } from 'jsfcore/helpers/numbers';
import { numberRegex } from 'jsfcore/helpers/const';
import {
  isFormulaElement,
  isVisibleElement,
  isFillableElement,
  isDisabledElement,
} from 'jsfcore/store/helpers/functions';
import * as Portal from 'jsfcore/components/Portal';
import * as jsfcoreUi from 'jsfcore/ui';
import * as selectors from 'jsfcore/store/selectors';
import getChangedElementsIsEnabledByDepFactory from 'jsfcore/store/sagas/getChangedElementsIsEnabledByDepFactory';
import {
  getFormulaFormatValidators,
  getNumberValidatorsIds,
} from 'jsfcore/jsf-validations/selectors/validatorsSelectors';
import * as jsfValidationErrorsSelectors from 'jsfcore/jsf-validations/selectors/errorsSelectors';
import { unformat } from 'jsfcore/jsf-validations/utils/formatUnformat';
import {
  types as jsfcoreModalsTypes,
  actions as jsfcoreModalsActions,
} from 'jsfcore/jsf-modals';
import { elemSubTypes } from 'jsfcore/helpers/elemTypes';
import { CONSTRUCTOR_HIDE } from 'jsfcore/store/modules/viewport';

// ws
import isSignNow, { setIsSignNow } from 'ws-editor-lib/isSignNow';
import editorModes from 'ws-editor-lib/constants/editorModes';
import {
  actionTypes,
  updateElement,
  updateElements,
  updateFillableElements,
} from 'ws-editor-lib/actions';

const { getElementsMap, getActiveElementId, getMode, getPdfPages } = selectors.base;
const {
  getElementByIdFactory,
  getFillableEnabledElements,
  getFillableElements,
} = selectors.elements;
const { doFillableElementsExist } = selectors;

// todo remove later
export const snSelectors = {
  getIsFreeFormMode(state) {
    return get(state, 'ws.external.document.viewMode', null) === 'freeform';
  },

  getIsAnnotation(state) {
    return get(state, 'ws.external.document.viewMode', null) === 'annotation';
  },
};

export {
  excludeFromRestoreFocus,
  getNumberWithoutDotsOrCommas,
  getChangedElementsIsEnabledByDepFactory,
  isFormulaElement,
  isFillableElement,
  Portal,
  isVisibleElement,
  isDisabledElement,
  jsfcoreModalsActions,
  jsfcoreModalsTypes,
  jsfcoreUi,
  getFormulaFormatValidators,
  getNumberValidatorsIds,
  getElementsMap,
  getFillableEnabledElements,
  getFillableElements,
  getElementByIdFactory,
  getActiveElementId,
  unformat,
  jsfValidationErrorsSelectors,
  elemSubTypes,
  numberRegex,
  doFillableElementsExist,
  CONSTRUCTOR_HIDE,

  // ws
  isSignNow,
  setIsSignNow,

  updateElement,
  updateElements,
  updateFillableElements,
  actionTypes,
  getMode,
  editorModes,
  getPdfPages,
};
