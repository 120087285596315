import setActiveElement from './setActiveElement';
import { getActiveElementId } from '../selectors/baseSelectors';

const deactivateElement = () => {
  return (dispatch, getState) => {
    const state = getState();
    const activeElementId = getActiveElementId(state);
    if (activeElementId) {
      dispatch(setActiveElement(activeElementId, false));
    }
  };
};

export default deactivateElement;
