import React from 'react';
import PropTypes from 'prop-types';

const CustomFieldCell = ({
  children,
  attributes,
  ...props
}) => {
  return (
    <div
      className="custom-field__cell"
      {...attributes}
      {...props}
    >
      {children}
    </div>
  );
};

CustomFieldCell.propTypes = {
  children: PropTypes.node.isRequired,
  attributes: PropTypes.shape({}),
};

CustomFieldCell.defaultProps = {
  attributes: null,
};

export default CustomFieldCell;
