import { createSelector } from 'reselect';
import pick from 'lodash/pick';
import navigationSelectors from './navigationSelectors';
import * as base from './baseSelectors';

export const getPages = createSelector(
  [navigationSelectors.getPagesSettings], (pages) => {
    if (!pages) {
      return pages;
    }
    return pages.map((page) => {
      return pick(page, ['rotation', 'source', 'visible', 'size', 'sourceUrl']);
    });
  },
);

export const getDeletedPages = createSelector(
  [base.getWsPages],
  (pages) => {
    if (pages && pages.length > 0) {
      return pages
        .filter(({ visible }) => {
          return !visible;
        })
        .map(({ source: pageId }) => {
          return pageId;
        });
    }

    return [];
  },
);
