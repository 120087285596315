import { createSelector } from 'reselect';
import get from 'lodash/get';
import pick from 'lodash/pick';
import * as base from './baseSelectors';

export const getLocale = (state) => {
  return state.locale;
};

export const getCommentsLocale = (state) => {
  return getLocale(state).comments;
};

export const getShowPagesButtonTooltip = (state) => {
  return getLocale(state).editor.toolbarNavigation.tooltips.showPages;
};

export const getRearrangeButtonTooltip = (state) => {
  return getLocale(state).editor.toolbarNavigation.tooltips.rearrange;
};

export const getEditorLocale = (state) => {
  return getLocale(state).editor;
};

export const getEditorAssistantLocale = (state) => {
  return getEditorLocale(state).assistant;
};

export const getToolsLocale = (state) => {
  return getEditorAssistantLocale(state).tools;
};

export const getHeaderToolsLocale = (state) => {
  return getEditorLocale(state).headerTools;
};

export const getToolbarAttributesLocale = (state) => {
  return getEditorLocale(state).toolbarAttributes;
};

export const getVersionsLocale = (state) => {
  return getLocale(state).versions;
};

export const getSmallScreenMessage = (state) => {
  return getEditorLocale(state).smallScreenMessage;
};

export const getPrintLocale = (state) => {
  return getEditorLocale(state).pagePrint;
};

export const getSNFillerLocale = (state) => {
  return getLocale(state).snfiller;
};

export const getServerValidationLocale = createSelector(
  getLocale,
  (locale) => {
    // нам необходим ключ "SERVER_VALIDATION", т.к. мы ориентируемся на него
    // потом из валидаторов в "description", а всю локаль хранить необязательно
    return pick(locale, 'SERVER_VALIDATION');
  },
);

export const getFConstructorLocale = (state) => {
  return getLocale(state).fConstructor;
};

export const getDoneButtonLocale = (state) => {
  const labelFromFeatures = get(base.getDoneButton(state), 'main.label');
  const labelFromLocales = get(getEditorLocale(state), 'doneButton.main.label');

  return labelFromFeatures || labelFromLocales;
};

export const getHelpModalLocale = (state) => {
  return getEditorLocale(state).helpModal;
};

export const getConditionsLocale = (state) => {
  return getSNFillerLocale(state).CONDITIONAL_FIELDS;
};

export const getModalsLocale = (state) => {
  return getEditorLocale(state).modals;
};

export const getAutofillModalLocale = (state) => {
  return getModalsLocale(state).autofill;
};

export const getLoadingModalLocale = (state) => {
  return getModalsLocale(state).loading;
};

export const getAutofillErrorModalLocale = (state) => {
  return getModalsLocale(state).autofillError;
};

export const getVersionsModeErrorModalLocale = (state) => {
  return getModalsLocale(state).versionsModeError;
};

export const getLocaleLabels = (state) => {
  return getEditorLocale(state).labels;
};

export const getMiscLabels = (state) => {
  return get(getLocaleLabels(state), 'misc', {});
};

export const getMiscLabel = (state, id) => {
  return get(getMiscLabels(state), id, '');
};

export const getToolbarLabels = (state) => {
  return get(getLocaleLabels(state), 'toolbar', {});
};

export const getToolbarLabel = (state, id) => {
  return get(getToolbarLabels(state), id, '');
};

export const getWizardTodoLocale = (state) => {
  return getEditorLocale(state).wizardTodo;
};

export const getWizardFullLiteButtonLocale = (state) => {
  return getEditorLocale(state).wizardFullLiteButton;
};

export const getSearchModalLocale = (state) => {
  return getEditorLocale(state).searchModal;
};

export const getEditorInfoLocale = (state) => {
  return getEditorLocale(state).editorInfo;
};

export const getSuccessModalLocale = (state) => {
  return getEditorLocale(state).successModal;
};

export const getProcessingModalLocale = (state) => {
  return getEditorLocale(state).processingModal;
};

export const getSignatureManagerLocale = (state) => {
  return getLocale(state).signatureManager;
};


export const getMobileToolsLabelsLocale = createSelector(
  [getToolbarLabels, getHeaderToolsLocale],
  (labels, headerTools) => {
    return {
      ...labels,
      'tools.signature.none': labels['tools.signature.*'],
      'tools.select.none': headerTools.select,
    };
  },
);

export const getSupportChatLocale = (state) => {
  return getLocale(state).supportChat;
};
