import numeral from 'numeral';
import get from 'lodash/get';
import find from 'lodash/find';

import conformToMask from './conformToMask';

const usdFormat = '$0,0.00';

const currencyFormatsMap = {
  usd: 'usd',
  eur: 'eur',
  gbp: 'gbp',
};

export function getOldFormat(element) {
  // getElementByIdFactory подмешивает validator в элементы
  // по validatorId, поэтому эта функция нормально работает
  // только с элементами из селектора
  return get(element, 'template.validator.format');
}

export function getAutoFormatPlaceholders(element) {
  return get(element, 'template.validator.autoFormatPlaceholders');
}

function parseVal(val) {
  const trimmedValue = val.trim();
  // Нужно вырезать все нули в конце строки после
  // точки, если точка вообще есть
  if (/\./.test(trimmedValue)) {
    return trimmedValue.replace(/\.?0*$/, '');
  }
  return trimmedValue;
}

function isStringWithFloatNumber(val) {
  return parseFloat(val).toString() === parseVal(val);
}

export const mask = (value, autoFormatPlaceholders) => {
  const valueLength = get(value, 'length', 0);

  const optimalPlaceholder = find(autoFormatPlaceholders, (placeholder) => {
    return valueLength === placeholder.stringLength;
  });

  if (!optimalPlaceholder) {
    return value;
  }

  const conformedValueObject = conformToMask(
    value,
    optimalPlaceholder.placeholder,
    { guide: false },
  );

  return conformedValueObject.conformedValue;
};

export function unformat(value, element) {
  const autoFormatPlaceholders = getAutoFormatPlaceholders(element);

  if (autoFormatPlaceholders) {
    // TODO:
    // temporary unformat behavior (hardcoded regexp) for autoFormat placeholders
    return value.replace(/[^\d]/g, '');
  }

  if (getOldFormat(element)) {
    return value.replace(/\$?€?£?,?/g, '');
  }

  return value;
}

export function currencyFormat(value, currencyType = 'usd') {
  const result = numeral(value).format(usdFormat);

  if (currencyType === currencyFormatsMap.eur) {
    return result.replace('$', '€');
  }

  if (currencyType === currencyFormatsMap.gbp) {
    return result.replace('$', '£');
  }

  return result;
}

export function format(value = '', element, originalValue) {
  const oldFormat = getOldFormat(element);
  const autoFormatPlaceholders = getAutoFormatPlaceholders(element);

  if (autoFormatPlaceholders) {
    return mask(value, autoFormatPlaceholders);
  }

  const splitedValue = value.split('.');
  if (splitedValue.length > 2) {
    return originalValue;
  }

  if (
    isStringWithFloatNumber(value) &&
    Object.values(currencyFormatsMap).indexOf(oldFormat) >= 0
  ) {
    return currencyFormat(value, oldFormat);
  }

  return originalValue;
}
