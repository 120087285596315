import { selectors } from 'jsfcore';
import { getLazy, formula, mocks } from '@pdffiller/jsf-lazyload';

import { updateFillableElement } from 'ws-editor-lib/actions';

import { isSignNow } from '../../helpers/utils';
import { cancellableOpts } from '../modules/undoRedo';
import { elemTypes, elemSubTypes } from '../../helpers/elemTypes';

const propagateUpdateToOtherFormulaElements = ({ dispatch, getState, template }) => {
  if (isSignNow() && template.hasOwnProperty('formula')) {
    const parse = getLazy(formula.functions.parser, mocks.fns.object);

    const activeElementId = selectors.base.getActiveElementId(getState());
    const fillableEnabledElements = selectors.getFillableEnabledElements(getState());

    const otherFormulaElements = fillableEnabledElements.filter((el) => {
      return (
        el.id !== activeElementId &&
        el.type === elemTypes.text &&
        el.subType === elemSubTypes.text.formula
      );
    });
    for (let i = 0; i < otherFormulaElements.length; ++i) {
      dispatch(updateFillableElement({
        id: otherFormulaElements[i].id,
        template: {
          calculationFormula: parse(
            otherFormulaElements[i].template.formula,
            fillableEnabledElements,
          ),
        },
      }, cancellableOpts));
    }
  }
};

export default propagateUpdateToOtherFormulaElements;
