import { takeEvery, take, put, select } from 'redux-saga/effects';
import { actionTypes as AT, activateMode } from 'ws-editor-lib/actions';
import editorModes from 'ws-editor-lib/constants/editorModes';
import openProcessingModal from 'jsfcore/store/sagas/modals/openProcessingModal';
import { handleDestroyOnButtonClick } from 'jsfcore/store/thunks/onDoneButtonOptionClickFactory';
import { showMessageButton, setMessageButtonContent } from 'jsfcore/store/modules/viewport';
import { triggerMessageButtonClick } from 'jsfcore/store/modules/events';
import { getIsMessageButtonVisible } from 'jsfcore/store/selectors/baseSelectors';

// TODO: cover with tests (https://pdffiller.atlassian.net/browse/JSF-8024)
function* showExternalValidationAlert(message) {
  const isMessageButtonVisible = yield select(getIsMessageButtonVisible);
  if (!isMessageButtonVisible) {
    yield put(showMessageButton());
  }

  const content = {
    title: '',
    message: JSON.parse(message).join('\n\n'),
  };

  yield put(setMessageButtonContent(content));
  yield put(triggerMessageButtonClick());
}

function* onCheckExternalValidation(action) {
  yield* openProcessingModal();
  yield put(activateMode(editorModes.pending));

  const {
    operations: [{ properties: { valid: isValid } }],
  } = yield take(AT.ON_OPERATIONS_VALIDATORS_EXTERNAL);

  if (isValid) {
    yield put(activateMode(editorModes.main));
    yield put(handleDestroyOnButtonClick(action.destroyParams));
  } else {
    const {
      operations: [{ properties: { message } }],
    } = yield take(AT.ON_OPERATIONS_EDITOR_ALERT);

    yield put(activateMode(editorModes.main));

    if (message) {
      yield* showExternalValidationAlert(message);
    }
  }
}

export default function* watchOnCheckExternalValidation() {
  yield takeEvery(AT.CHECK_EXTERNAL_VALIDATION, onCheckExternalValidation);
}
