import React, { Component } from 'react';
import PropTypes from 'prop-types';

export const offsets = {
  small: 'large',
};

export default class PopoverItemBody extends Component {
  static propTypes = {
    children: PropTypes.node,
  }

  static defaultProps = {
    children: null,
  }

  render() {
    return (
      <div className="item__body">
        {this.props.children}
      </div>
    );
  }
}
