import React from 'react';
import PropTypes from 'prop-types';

const CustomSettingsCell = ({
  attributes,
  children,
}) => {
  return (
    <div
      className="custom-settings__cell"
      {...attributes}
    >
      {children}
    </div>
  );
};

CustomSettingsCell.propTypes = {
  attributes: PropTypes.shape({}),
  children: PropTypes.node.isRequired,
};

CustomSettingsCell.defaultProps = {
  attributes: null,
};

export default CustomSettingsCell;
