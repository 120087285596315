import { takeEvery, select, put } from 'redux-saga/effects';
import { selectors } from 'jsfcore';
import { actionTypes } from 'ws-editor-lib/actions';
import { signingSessionHide, signingSessionShow } from 'jsfcore/store/modules/viewport';
import { isFillable } from 'jsfcore/store/helpers/functions';

export function* showSigningSessionOperationsEditorScenarios() {
  const isSigningSessionMode = yield select(selectors.getIsSigningSessionMode);
  const isSigningSessionShown = yield select(selectors.base.getIsSigningSessionShown);
  const activeElement = yield select(selectors.elements.getActiveElement);

  if (isSigningSessionMode && !isSigningSessionShown && !activeElement) {
    yield put(signingSessionShow());
  }
}

function* toggleSigningSession(action) {
  const isSigningSessionMode = yield select(selectors.getIsSigningSessionMode);

  if (isSigningSessionMode) {
    const isSigningSessionShown = yield select(selectors.base.getIsSigningSessionShown);
    const activeElement = yield select(selectors.elements.getActiveElement);

    const isActiveFillable = action.activeStatus && isFillable(activeElement);
    const isActiveNonFillable = action.activeStatus && !isFillable(activeElement);

    if (isActiveFillable && !isSigningSessionShown) {
      yield put(signingSessionShow());
    }

    if (isActiveNonFillable && isSigningSessionShown) {
      yield put(signingSessionHide());
    }
  }
}

export function* watchOnOperationsEditorScenarios() {
  yield takeEvery(
    actionTypes.ON_OPERATIONS_EDITOR_SCENARIOS,
    showSigningSessionOperationsEditorScenarios,
  );
}

export default function* toggleSigningSessionOnSetActiveElement() {
  yield takeEvery(actionTypes.SET_ACTIVE_ELEMENT, toggleSigningSession);
}
