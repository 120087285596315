import get from 'lodash/get';
import { WIZARD_STATES } from 'ws-editor-lib/constants';
import { wizardTraverseModes } from '../../helpers/const';

const DEFAULT_TEXT_FIELD_VIEW_MODE = 'classic';
export const DEFAULT_WIZARD_STATE = WIZARD_STATES.FULL;
// need some initial state to show before data comes from ws
const DEFAULT_DONE_BUTTON = {
  main: {
    id: '',
    location: '',
  },
  list: [],
  visible: true,
};

const modeIds = {
  G7: 'G7',
};

/**
 * Events:
 */
export const isPagePinching = (state) => {
  return state.events.pagePinching;
};
export const isPageScaling = (state) => {
  return state.events.pageScaling;
};
export const isPageChanging = (state) => {
  return state.events.pageChanging;
};
export const isPagePanning = (state) => {
  return state.events.pagePanning;
};
export const getEvents = (state) => {
  return state.events;
};
export const getDragElementId = (state) => {
  return state.events.dragElementId;
};
export const getStretchingElementId = (state) => {
  return state.events.stretchingElementId;
};
export const getIsDrawingNewGraphicElement = (state) => {
  return state.events.isDrawingNewGraphicElement;
};
export const getIsToolDragging = (state) => {
  return state.events.isToolDragging;
};
export const getIsPageChanging = (state) => {
  return state.events.pageChanging;
};

// TODO: Should be refactored
export const getAppStarted = (state) => {
  return state.events.appStarted;
};
export const getFatalError = (state) => {
  return state.events.fatalError;
};
export const getFillablePictureSelectingId = (state) => {
  return state.events.fillablePictureSelectingId;
};

export const getGhostPos = (state) => {
  return state.events.ghostPos;
};

export const getIsDropdownToolOpen = (state) => {
  return state.events.isDropdownToolOpen;
};

export const getIsDbFieldNameFocused = (state) => {
  return state.events.isDbFieldNameFocused;
};

/**
 * Pdf:
 */
export const getCanvases = (state) => {
  return state.pdf.canvases;
};
export const getPdfPages = (state) => {
  return state.pdf.pdfPages;
};
export const getPagesCount = (state) => {
  return get(state.pdf, 'pdfPages.length', 0);
};

export const getPdfCount = (state) => {
  return state.pdf.count;
};
export const getTextContentPages = (state) => {
  return state.pdf.pdfTextContent;
};
export const getCanvasLoadingPage = (state) => {
  return state.pdf.canvasLoading;
};
export const getPdfDocumentLoading = (state) => {
  return state.pdf.pdfDocumentLoading;
};
export const getCanvasContainerSize = (state) => {
  return state.pdf.canvasContainerSize;
};
export const getLoadingProgress = (state) => {
  return state.pdf.loadingProgress;
};

/*
 * undoRedo
 */
export const getUndoRedoHistory = (state) => {
  return state.undoRedo.history;
};

export const getUndoRedoCursor = (state) => {
  return state.undoRedo.cursor;
};

/**
 * WS:
 */
export const getElements = (state) => {
  return state.ws.elements;
};
export const getElementsMap = (state) => {
  return state.ws.elementsMap;
};
export const getElement = (state, id) => {
  return getElementsMap(state)[id];
};

export const getModeId = (state) => {
  return state.ws.modeId;
};
export const getAccess = (state) => {
  return state.ws.access;
};
export const getSource = (state) => {
  return state.ws.source;
};
export const getSourcePdfUrl = (state) => {
  return get(getSource(state), 'pdf.url');
};
export const getRoleId = (state) => {
  const access = getAccess(state);

  return access && access.roleId;
};
export const getAccessStatus = (state) => {
  return getAccess(state).status;
};
export const getAccessCancelUrl = (state) => {
  return getAccess(state).cancelUrl;
};
export const getAccessBusyUser = (state) => {
  return getAccess(state).busyUser;
};
export const getAccessTimer = (state) => {
  return getAccess(state).accessTimer;
};
export const getViewMode = (state) => {
  return get(state, 'ws.external.document.viewMode', null);
};
export const getDefaultViewMode = (state) => {
  return get(state, 'ws.defaults.textFieldViewMode', DEFAULT_TEXT_FIELD_VIEW_MODE);
};
export const getTodoListState = (state) => {
  return get(state, 'ws.defaults.todoListState', null);
};
export const getSigDateStampChangeable = (state) => {
  return get(state, 'ws.access.sigDateStampChangeable', null);
};
export const isG7Mode = (state) => {
  return getModeId(state) === modeIds.G7;
};
export const getMode = (state) => {
  return state.ws.mode;
};
export const getSystem = (state) => {
  return state.ws.system;
};
export const getErrorList = (state) => {
  return state.ws.errorList;
};
export const getSystemStatus = (state) => {
  return getSystem(state).status;
};
export const getFeatures = (state) => {
  return state.ws.features;
};
export const getDoneButton = (state) => {
  const features = getFeatures(state);
  return get(features, 'doneButton', DEFAULT_DONE_BUTTON);
};
export const getWSMessage = (state) => {
  return state.ws.message;
};
export const getLaunchFromAuth = (state) => {
  return state.ws.launch;
};
export const getFeaturesMisc = (state) => {
  return get(state, 'ws.features.misc', null);
};
export const getFeaturesToolbar = (state) => {
  return get(state, 'ws.features.toolbar', null);
};
export const getFeaturesLogo = (state) => {
  return get(state, 'ws.features.logo', null);
};
export const getActiveTool = (state) => {
  return state.ws.activeTool;
};
export const getActiveToolId = (state) => {
  const activeTool = getActiveTool(state);

  return get(activeTool, 'id', false);
};
export const getDefaults = (state) => {
  return state.ws.defaults;
};
export const getUseSigDateStamp = (state) => {
  return getDefaults(state).useSigDateStamp;
};
export const getDefaultContents = (state) => {
  return getDefaults(state).content;
};
export const getWizardState = (state) => {
  const defaults = getDefaults(state);
  return get(defaults, 'fillableWizardState', DEFAULT_WIZARD_STATE);
};
export const getIsWizardFull = (state) => {
  return getWizardState(state) === WIZARD_STATES.FULL;
};
export const getIsWizardLite = (state) => {
  return getWizardState(state) === WIZARD_STATES.LITE;
};
export const getWSSignatures = (state) => {
  return state.ws.signatures;
};
export const getEnabledElementsMap = (state) => {
  const elementsMap = getElementsMap(state);
  const enabledElementsMap = {};

  Object.keys(elementsMap).forEach((elementId) => {
    if (elementsMap[elementId].enabled) {
      enabledElementsMap[elementId] = { ...elementsMap[elementId] };
    }
  });

  return enabledElementsMap;
};

export const getWsPages = (state) => {
  return state.ws.pages;
};

export const getProjectName = (state) => {
  return state.ws.projectName || '';
};

export const getGhostElement = (state) => {
  return state.ws.ghostElement;
};
export const getAttributes = (state) => {
  return state.ws.attributes;
};
export const getExternal = (state) => {
  return state.ws.external;
};
export const getRecognizedFont = (state) => {
  return get(state, 'ws.recognizedFont', false);
};

const emptyGroups = {
  flat: {},
  radio: {},
};

export const getGroups = (state) => {
  return state.ws.fillableGroups || emptyGroups;
};
export const getRadioGroups = (state) => {
  return getGroups(state).radio;
};
export const getFlatGroups = (state) => {
  return getGroups(state).flat;
};
export const getUsers = (state) => {
  return state.ws.users;
};

export const getEndPoints = (state) => {
  return state.ws.endPoints;
};

export const getPdfScale = (state) => {
  return state.ws.pdf.pdfScale;
};

export const getNumberToFormulasDependencies = (state) => {
  return get(state.ws, 'formulaCache.numberToFormulasDependencies', {});
};

export const getViewerId = (state) => {
  return state.ws.viewerId;
};

export const getProjectId = (state) => {
  return state.ws.projectId;
};

export const getModeBarFeature = (state) => {
  return get(state, 'ws.features.modebar', null);
};

export const getConstructorFeature = (state) => {
  return get(state, 'ws.features.constructor', null);
};

export const getWsScenarios = (state) => {
  return state.ws.scenarios;
};

export const getConnection = (state) => {
  return state.ws.connection;
};

export const getConnectionStatus = (state) => {
  return getConnection(state).status;
};

export const getAutoCheck = (state) => {
  return state.ws.autoCheck;
};

export const getActiveElementId = (state) => {
  return state.ws.activeElement;
};

export const getIsLoggedInViewer = (state) => {
  return state.ws.isLoggedInViewer;
};

/**
 * Wizard:
 */
export const getWizardTraverseMode = (state) => {
  return get(state, 'wizard.wizardTraverseMode', wizardTraverseModes.all);
};
export const getWizardSignatureCache = (state) => {
  return get(state, 'wizard.cacheSignature', false);
};

/**
 * Viewport:
 */
export const getWorkspaceFromViewport = (state) => {
  return state.viewport.workspace;
};
export const getIsFConstructorShown = (state) => {
  return state.viewport.isFConstructorShown;
};
export const getIsPAConstructorShown = (state) => {
  return state.viewport.isPAConstructorShown;
};
export const getBody = (state) => {
  return state.viewport.body;
};
export const getIsKeybordShown = (state) => {
  return state.viewport.isKeyboardShown;
};
export const getIsCommentsShown = (state) => {
  return state.viewport.isCommentsShown;
};
export const getIsPointerShown = (state) => {
  return state.viewport.isPointerShown;
};
export const getIsSignatureManagerShown = (state) => {
  return state.viewport.isSignatureManagerShown;
};
export const getIsImageManagerShown = (state) => {
  return state.viewport.isImageManagerShown;
};
export const getToolbarOverlays = (state) => {
  return state.viewport.toolbarOverlays;
};
export const getIsFakeEditActive = (state) => {
  return state.viewport.isFakeEditActive;
};
export const getIsFakeEditInitialHintShown = (state) => {
  return state.viewport.isFakeEditInitialHintShown;
};
export const getIsFakeEditRolloverHintShown = (state) => {
  return state.viewport.isFakeEditRolloverHintShown;
};
export const getIsThumbnailsOpen = (state) => {
  return state.viewport.isThumbnailsOpen;
};

export const getViewport = (state) => {
  return state.viewport;
};
export const getSettingsPopupVisibility = (state) => {
  return state.viewport.popupVisibility;
};
export const getPAConstructorActiveSection = (state) => {
  return state.viewport.activePAConstructorSection;
};
export const getIsBusyLockerShown = (state) => {
  return state.viewport.isBusyLockerShown;
};
export const getIsConditionalFieldsShown = (state) => {
  return state.viewport.isConditionalFieldsShown;
};
export const getDraggablePanelPosition = (state, entity) => {
  return state.viewport.draggablePanelPosition[entity];
};
export const getIsVersionsShown = (state) => {
  return state.viewport.isVersionsShown;
};
export const getIsChoiceIframeShown = (state) => {
  return state.viewport.isChoiceIframeShown;
};
export const getKeyboardSize = (state) => {
  return state.viewport.keyboardSize;
};
export const getKeyboardShown = (state) => {
  return state.viewport.isKeyboardShown;
};

export const getActiveMenu = (state) => {
  return state.viewport.activeMenu;
};

export const getIsSigningSessionShown = (state) => {
  return state.viewport.isSigningSessionShown;
};

export const getSigningSessionTextSet = (state) => {
  return state.viewport.signingSessionTextSet;
};

export const getIsMessageButtonVisible = (state) => {
  return state.viewport.isMessageButtonVisible;
};

export const getMessageButtonContent = (state) => {
  return state.viewport.messageButtonContent;
};

/**
 * fConstructor
 */
export const getIsFConstructorOrderShown = (state) => {
  return get(state, 'fConstructor.isFConstructorOrderShown', false);
};
export const getIsFConstructorPreviewShown = (state) => {
  return get(state, 'fConstructor.isFConstructorPreviewShown', false);
};
// копирует логику "getIsFConstructorOrderShown", но пока используются оба
export const isFConstructorOrderShown = (state) => {
  return state.fConstructor.isFConstructorOrderShown;
};

/**
 * Navigation:
 */
export const getActivePageId = (state) => {
  return state.navigation.activePageId;
};
export const getNavigationBody = (state) => {
  return state.navigation.body;
};
export const getWorkspace = (state) => {
  return state.navigation.workspace;
};
export const getFramePadding = (state) => {
  return state.navigation.workspace.framePadding;
};
export const getHeaderScales = (state) => {
  return state.navigation.headerScales;
};
export const getNextScale = (state) => {
  return state.navigation.nextScale;
};
export const getFrameScrolls = (state) => {
  return state.navigation.frameScrolls;
};
export const getGhostPageId = (state) => {
  return state.navigation.ghostPageId;
};
export const getNextPageId = (state) => {
  return state.navigation.nextActive;
};
export const getPrevPageId = (state) => {
  return state.navigation.prevActive;
};
export const getSavedActivePageId = (state) => {
  return state.navigation.savedActivePageId;
};
export const getCaretPosition = (state) => {
  return state.navigation.caretPosition;
};
export const getOriginalSizes = (state) => {
  return state.navigation.originalSizes;
};
export const getScales = (state) => {
  return state.navigation.scales;
};
export const getIsContiniousScrollAnimated = (state) => {
  return state.navigation.isContiniousScrollAnimated;
};

/*
 * Signnow modals
 */
export const getSignnowModals = (state) => {
  return state.modals;
};
/**
 * Search:
 */
export const getOccurences = (state) => {
  return state.search.occurences;
};
export const getIsSearchModeActive = (state) => {
  return state.search.isSearchModeActive;
};

export const getActiveOccurence = (state) => {
  return get(state.search.occurences, `[${state.search.searchIndex}]`, false);
};
export const getRects = (state) => {
  return state.search.occurenceRects;
};
export const getSearchTerm = (state) => {
  return state.search.searchTerm;
};

/**
 * Rearrange:
 */
export const getSavedActivePageFromRearrange = (state) => {
  return get(state, 'rr.lastActivePageId', false);
};

/**
 * Wizard:
 */
export const getIsWizardActive = (state) => {
  return get(state, 'wizard.wizardActive', false);
};
export const isWizardStartButtonVisible = (state) => {
  return get(state, 'wizard.isWizardStartButtonVisible', false);
};
export const getLastActiveFillableElementId = (state) => {
  return get(state, 'ws.lastActiveFillableElementId', false);
};

/**
 * Query:
 */
export const getIsSpellCheckEnabled = (state) => {
  return get(state, 'query.enableSpellCheck', false);
};

export const getPreventRedirect = (state) => {
  return get(state, 'query.preventRedirect');
};

export const getEditType = (state) => {
  return get(state, 'query.editType');
};

export const getIsEmbedded = (state) => {
  return get(state, 'query.embedded', false);
};

/**
 * Versions:
 */

export const getVersions = (state) => {
  return state.ws.versions;
};

export const getIsVersionsRequested = (state) => {
  return getVersions(state).isRequested;
};

export const getHidePointerInFullWizard = (state) => {
  const features = getFeatures(state);
  return get(features, 'wizard.hidePointerInFullWizard', false);
};
