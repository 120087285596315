import { takeEvery, put, call } from 'redux-saga/effects';
import get from 'lodash/get';
import { actionTypes, updateElements } from 'ws-editor-lib/actions';
import { isCheckmarkElement, isDate } from 'jsfcore/store/helpers/functions';
import getChangedElementsIsEnabledByDepFactory from 'jsfcore/store/sagas/getChangedElementsIsEnabledByDepFactory';

export const getUpdatedElement = (disabledElements, element) => {
  const keyForUpdate = isCheckmarkElement(element)
    ? 'checked'
    : 'text';

  const needToIgnoreInitialValue = isDate(element) && (
    get(element, 'template.initial', '') === get(element, 'template.validator.momentFormat', '')
  );

  const updatedElement = {
    id: element.id,
    content: {
      [keyForUpdate]: needToIgnoreInitialValue
        ? ''
        : element.template.initial,
    },
  };

  return [
    ...disabledElements,
    updatedElement,
  ];
};

export function* updateVisibleElements(disabledElementsWithInitial) {
  const updatedDisabledElements = disabledElementsWithInitial.reduce(getUpdatedElement, []);
  yield put(updateElements(updatedDisabledElements));
}

export function getVisibleElementsWithInitial(elements) {
  return elements
    .filter(({ isEnabledByDependencies }) => {
      return isEnabledByDependencies;
    })
    .filter((element) => {
      const initial = get(element, 'template.initial', '');
      return initial.length > 0 || initial === true;
    });
}

// export need for onUpdateIsEnabledByDependency testing
export const getChangedElementsIsEnabledByDep = getChangedElementsIsEnabledByDepFactory();

export function* onUpdateIsEnabledByDependency() {
  const changedElementsIsEnabledByDep = yield call(getChangedElementsIsEnabledByDep);
  if (changedElementsIsEnabledByDep.length === 0) {
    return;
  }

  const disabledElementsWithInitial = getVisibleElementsWithInitial(changedElementsIsEnabledByDep);
  if (disabledElementsWithInitial.length === 0) {
    return;
  }

  yield call(updateVisibleElements, disabledElementsWithInitial);
}

export default function* watchIsEnabledByDependency() {
  yield takeEvery(
    actionTypes.UPDATE_ELEMENT,
    onUpdateIsEnabledByDependency,
  );
}
