import get from 'lodash/get';
import * as conditionsActions from '@pdffiller/jsf-conditions/store/actions';
import { selectors as jsfValidationsSelectors } from 'jsfcore/jsf-validations';
import { getLazy, formula, mocks } from '@pdffiller/jsf-lazyload';

import { updateFillableElement } from 'ws-editor-lib/actions';

import { textToolArrangements, defaultCharSettingsSN } from '../../helpers/const';
import { isSignNow } from '../../helpers/utils';

import { elements as elementsSelectors } from '../selectors';
import { isRadioElement } from '../helpers/functions';
import updateFormulasDependentOnElement from './updateFormulasDependentOnElement';
import propagateUpdateToOtherFormulaElements from './propagateUpdateToOtherFormulaElements';
import propagateTemplateToOtherElementsOfRadioGroup from './propagateTemplateToOtherElementsOfRadioGroup';

// 'export' for test only
export const getUpdatedElementSN = ({ oldElement, updatedElement, getState }) => {
  const template = get(updatedElement, 'template', {});

  if (
    // on validatorId update we should update also maxChars/maxLines
    template.hasOwnProperty('validatorId') &&
    // but validatorId may be undefined for payment field
    template.validatorId !== undefined
  ) {
    if (template.validatorId !== 'none') {
      const { maxChars, maxLines } = jsfValidationsSelectors.validators.getValidatorWithoutDefaults(
        getState(),
        template.validatorId,
      );

      return {
        ...updatedElement,
        template: {
          ...updatedElement.template,
          maxChars,
          maxLines,
        },
      };
    }

    const { maxChars, maxLines } = oldElement.template.arrangement === textToolArrangements.cells
      ? defaultCharSettingsSN
      : { maxChars: 0, maxLines: 0 };
    return {
      ...updatedElement,
      template: {
        ...updatedElement.template,
        maxChars,
        maxLines,
      },
    };
  }

  // on arrangement update we should update also maxChars/maxLines
  if (template.hasOwnProperty('arrangement')) {
    if (oldElement.template.validatorId && oldElement.template.validatorId !== 'none') {
      return {
        ...updatedElement,
        template: {
          ...updatedElement.template,
          maxLines: 1,
        },
      };
    }

    const fixedMaxCharsLines = template.arrangement === textToolArrangements.cells
      ? defaultCharSettingsSN
      : { maxChars: 0, maxLines: 0 };
    return {
      ...updatedElement,
      template: {
        ...updatedElement.template,
        ...fixedMaxCharsLines,
      },
    };
  }

  return updatedElement;
};

function updateFillableElementThunk(elem, opts = {}) {
  return async (dispatch, getState) => {
    const activeElement = elementsSelectors.getElementByIdFactory(elem.id)(getState());
    const isActiveElementRadio = isRadioElement(activeElement);

    const elementAfterRemoveDependenciesConfirmation =
      elem.template.hasOwnProperty('conditional')
        ? await conditionsActions.confirmationRemoveDependencies(elem)(dispatch, getState)
        : elem;

    if (isSignNow()) {
      const elementWithFixedSNProps = getUpdatedElementSN({
        oldElement: activeElement,
        updatedElement: elementAfterRemoveDependenciesConfirmation,
        getState,
      });
      dispatch(updateFillableElement(elementWithFixedSNProps, opts));
    } else {
      dispatch(updateFillableElement(elementAfterRemoveDependenciesConfirmation, opts));
    }
    const updateFormulasThunk = getLazy(formula.functions.updateFormulasThunk, mocks.fns.object);

    dispatch(updateFormulasThunk());
    updateFormulasDependentOnElement({
      dispatch,
      getState,
      prevTemplate: activeElement.template,
      activeTemplate: elementAfterRemoveDependenciesConfirmation.template,
    });

    if (isActiveElementRadio) {
      // Все апдейты связанные с апдейтами на канвасе
      if (
        elem.template.hasOwnProperty('width') ||
        elem.template.hasOwnProperty('height')
      ) {
        propagateTemplateToOtherElementsOfRadioGroup(activeElement, dispatch, getState, {
          width: elem.template.width,
          height: elem.template.height,
        });
      }

      // Все апдейты связанные с боковой частью конструктора
      if (
        elem.template.hasOwnProperty('roleId') ||
        elem.template.hasOwnProperty('required') ||
        elem.template.hasOwnProperty('prefilled') ||
        elem.template.hasOwnProperty('initial') ||
        elem.template.hasOwnProperty('name') ||
        elem.template.hasOwnProperty('label') ||
        elem.template.hasOwnProperty('conditional') ||
        elem.template.hasOwnProperty('dependency') ||
        (elementAfterRemoveDependenciesConfirmation.hasOwnProperty('subType') && elem.subType)
      ) {
        propagateTemplateToOtherElementsOfRadioGroup(
          activeElement, dispatch, getState, elem.template, elem.subType,
        );
      }
    }

    propagateUpdateToOtherFormulaElements({ dispatch, getState, template: elem.template });
  };
}

export const updateFillableElementThunkWithConditionsCheck = conditionsActions.conditionsCheck(
  updateFillableElementThunk,
);

export default updateFillableElementThunkWithConditionsCheck;
