import React from 'react';
import PropTypes from 'prop-types';

const SortableElementEdit = ({ children }) => {
  return (
    <div className="sortable-element__edit">
      {children}
    </div>
  );
};

SortableElementEdit.propTypes = {
  children: PropTypes.node.isRequired,
};

export default SortableElementEdit;
