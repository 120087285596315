/**
 * Эта функция необходима для того, чтобы вызывать
 * focusToRef в случаях, когда ref у активного элемента
 * изменился. Например, при открытии и закрытии конструктора
 * необходимо обновить фокус на новый обновленный элемент
 *
 * Фундаментально функция следит за изменением ref у активного
 * элемента.
 */

const isCancelled = () => false;

function storeRefProxyWatcher({
  storeRef,
  focusToRef,
  getActiveElementId,
  caret2End,
}) {
  let idOfLastElementThatHadNullRef = null;

  return id => (ref) => {
    storeRef(id)(ref);

    const activeElementId = getActiveElementId();

    if (id === activeElementId) {
      if (ref === null) {
        idOfLastElementThatHadNullRef = id;
      } else if (
        idOfLastElementThatHadNullRef === activeElementId &&
        id === activeElementId
      ) {
        focusToRef(isCancelled)(ref);
        caret2End.doCaret2End(ref);
      }
    }
  };
}

export default storeRefProxyWatcher;
