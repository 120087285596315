import {
  ENABLE_CHOISE_BY_CLICKING_MODE,
  DISABLE_CHOISE_BY_CLICKING_MODE,
} from '../reducers/conditionsReducer';
import { getDependency, dependenciesMap } from '../../di';

let resolve;
let onClick;

export const disable = (name = null, value = null) => {
  return (dispatch) => {
    document.removeEventListener('click', onClick);
    resolve({ name, value });
    dispatch({ type: DISABLE_CHOISE_BY_CLICKING_MODE });
  };
};

const onClickDocumentFactory = (dispatch) => {
  return (event) => {
    const nodeIsElement = getDependency(dependenciesMap.nodeIsElement);

    if (nodeIsElement(event.target)) {
      return;
    }

    dispatch(disable());
  };
};

export const enable = () => {
  return (dispatch) => {
    onClick = onClickDocumentFactory(dispatch);
    document.addEventListener('click', onClick);
    dispatch({ type: ENABLE_CHOISE_BY_CLICKING_MODE });
    return new Promise((res) => {
      resolve = res;
    });
  };
};
