import { createSelector } from 'reselect';
import find from 'lodash/find';
import get from 'lodash/get';
import filter from 'lodash/filter';
import { getServerValidationLocale } from '../../store/selectors/localesSelectors';

export const numberShortName = 'Number';

const emptyArr = [];
const emptyObj = {};

export const getValidators = (state) => {
  return state.ws.validators || emptyObj;
};

const getValidatorsDescriptionsSelector = (locales, validators) => {
  return Object.values(validators).reduce((acc, validatorsList) => {
    return {
      ...acc,
      ...validatorsList.reduce((descriptions, validator) => {
        return {
          ...descriptions,
          [validator.id]: get(locales, validator.description),
        };
      }, {}),
    };
  }, {});
};

export const getValidatorsDescriptions = createSelector(
  [getServerValidationLocale, getValidators],
  getValidatorsDescriptionsSelector,
);

export const getDateValidators = (state) => {
  return getValidators(state).dateAndTime || emptyArr;
};

const getMappedDateValidators = (state) => {
  return getDateValidators(state).map(({ momentFormat, id }) => {
    return {
      label: momentFormat,
      value: id,
    };
  });
};

export const getTextValidators = (state) => {
  return getValidators(state).text || emptyArr;
};

export const getPaymentValidators = (state) => {
  return getValidators(state).payment || emptyArr;
};

const mapValidatorsForSelect = ({ id, name, formulaCalculation }) => {
  return {
    label: name,
    value: id,
    formulaCalculation,
  };
};

const filterTextValidators = createSelector(getTextValidators, (validators) => {
  return [
    { value: '', label: 'None' },
    ...validators.filter(({ category }) => {
      return category === 'String';
    }).map(mapValidatorsForSelect),
  ];
});

const filterNumberValidators = createSelector(getTextValidators, (validators) => {
  return validators.filter(({ category }) => {
    return category === 'Number';
  }).map(mapValidatorsForSelect);
});

export const needNumberLengthValidation = (validator) => {
  return (
    validator.category === numberShortName &&
    validator.validateLength === true
  );
};

export const getDateValidator = (state, validatorId) => {
  const dateValidators = getDateValidators(state);
  return find(dateValidators, { id: validatorId }) || get(dateValidators, '[0]', null);
};

const commonListForDropdown = (validators) => {
  return validators.map(({
    id,
    webShortName,
    description,
    formulaCalculation,
    common,
    disabled,
  }) => {
    return {
      value: id,
      label: webShortName,
      hint: description,
      formulaCalculation,
      common,
      disabled,
    };
  });
};

const makeListForDateDropdown = (validators) => {
  return [
    ...commonListForDropdown(validators),
  ];
};

const makeListForDropdown = (validators) => {
  return [
    {
      label: 'None',
      value: 'none',
      tooltype: '',
      formulaCalculation: false,
      common: true,
      disabled: false,
    },
    ...commonListForDropdown(validators),
  ];
};

export const getDateValidatorsForDropdown = createSelector(
  [getDateValidators],
  makeListForDateDropdown,
);

export const getTextValidatorsForDropdown = createSelector(
  [getTextValidators],
  makeListForDropdown,
);

export const getPaymentValidatorsForDropdown = createSelector(
  [getPaymentValidators],
  makeListForDropdown,
);

export const getMergedValidators = createSelector(
  [getDateValidators, getTextValidators, getPaymentValidators],
  (dateValidators, textValidators, paymentValidators) => {
    if (!dateValidators || !textValidators || !paymentValidators) {
      return [];
    }
    return [
      ...dateValidators,
      ...textValidators,
      ...paymentValidators,
    ];
  },
);

export const getValidatorsWithAutoFormatPlaceholders = (state) => {
  const validators = getMergedValidators(state);
  return filter(validators, 'autoFormatPlaceholders');
};

export const getValidatorWithoutDefaults = (state, validatorId) => {
  if (!validatorId) {
    return null;
  }
  const validators = getMergedValidators(state);
  return find(validators, { id: validatorId });
};

export const getNumberValidators = (state) => {
  const textValidators = getTextValidators(state);
  const numberValidator = filter(textValidators, { category: numberShortName });

  return numberValidator;
};

export const getNumberValidatorsIds = (state) => {
  return getNumberValidators(state).map((validator) => {
    return validator.id;
  });
};

export const getNumberValidator = (state) => {
  const textValidators = getTextValidators(state);
  const numberValidator = find(textValidators, { webShortName: numberShortName });
  return numberValidator || null;
};

export const getNumberValidatorId = (state) => {
  const numberValidator = getNumberValidator(state);
  return numberValidator
    ? numberValidator.id
    : null;
};

export const getFormulaValidators = createSelector(
  getTextValidators,
  (textValidators) => {
    return textValidators.filter((validator) => {
      return validator.formulaCalculation;
    });
  },
);

const isElementUsedInFormulas = (element) => {
  return (state) => {
    return state.ws.formulaCache.numberToFormulasDependencies[element.id] !== undefined;
  };
};

const filterNumberFormulaValidators = createSelector(
  getFormulaValidators,
  (validators) => {
    return validators.map(mapValidatorsForSelect);
  },
);

const mapOfValidators = {
  none: filterTextValidators,
  number: filterNumberValidators,
  date: getMappedDateValidators,
  numberFormula: filterNumberFormulaValidators,
};

export const getValidatorsByElement = (element) => {
  return (state) => {
    const { subType: type } = element;

    if (type === 'number' && isElementUsedInFormulas(element)(state)) {
      return mapOfValidators.numberFormula(state);
    }
    return mapOfValidators[type](state);
  };
};

export const getFormulaFormatValidators = createSelector(
  getValidators,
  (allValidators) => {
    return Object.values(allValidators).reduce((result, validators) => {
      return validators.reduce((acc, validator) => {
        if (
          validator.formulaCalculation === false ||
          validator.format === undefined ||
          validator.label === undefined
        ) {
          return acc;
        }

        acc.push(validator);
        return acc;
      }, result);
    }, []);
  },
);

export const getFormulaFormatValidatorsForSelect = createSelector(
  getFormulaFormatValidators,
  (formulaFormatValidators) => {
    return formulaFormatValidators.map(({ id: value, label }) => {
      return { value, label };
    });
  },
);
