import findIndex from 'lodash/findIndex';
import get from 'lodash/get';
import { elemTypes } from '../../helpers/elemTypes';
import isSignNow from '../../helpers/const/isSignNow';

import {
  isDropdownElement,
  isFillable,
  isRadioElement,
  isCheckmarkElement,
} from './functions';

import { getContentXY } from '../../helpers/getContentXY';
import { unformat, format } from '../../jsf-validations/utils/formatUnformat';

const otherVal = 'Other';
const customTextFunc = (t = '') => {
  if (
    t.trim().substr(-7) !== `(${otherVal})` &&
    t.trim() !== otherVal
  ) {
    return `${t} (${otherVal})`;
  }
  return t;
};

const dropdownBeforeSendSnf = () => {
  return (element) => {
    const { content = {} } = element;

    const isCustom =
      content &&
      content.customDropdownValueSelected &&
      !content.mixTextToList;
    if (isCustom) {
      return {
        ...element,
        content: {
          ...element.content,
          text: customTextFunc(content.text),
          mixTextToList: true,
        },
      };
    }

    return element;
  };
};

const dropdownBeforeSendJsf = () => {
  return (element) => {
    const { template: { list }, content = {} } = element;
    const { text, customDropdownValueSelected } = content;
    if (customDropdownValueSelected && findIndex(list, ['label', text]) !== -1) {
      return {
        ...element,
        content: {
          ...element.content,
          customDropdownValueSelected: false,
        },
      };
    }

    return element;
  };
};

const dropdownBeforeSend = isSignNow()
  ? dropdownBeforeSendSnf
  : dropdownBeforeSendJsf;

const shouldContainContentXYAndCroppedSize = (element) => {
  return (
    element.type === elemTypes.image ||
    element.type === elemTypes.signature
  ) && isFillable(element);
};

const addContentXYAndCroppedSizeBeforeSend = () => {
  return (element) => {
    return {
      ...element,
      content: {
        ...element.content,
        ...getContentXY(element),
      },
    };
  };
};

const shouldToogleCheckmarkValueFromUndefinedToFalse = (element) => {
  return (
    isFillable(element) &&
    isCheckmarkElement(element) &&
    !isRadioElement(element) &&
    !get(element, 'content.checked', false)
  );
};

const toggleValueFromUpdafinedToFalse = () => {
  return (element) => {
    return {
      ...element,
      content: {
        ...element.content,
        checked: false,
      },
    };
  };
};

const hasContentText = (el) => {
  return get(el, 'content.text', false);
};

const unformatFormat = () => {
  return (el) => {
    return {
      ...el,
      content: {
        ...el.content,
        text: format(unformat(el.content.text, el), el, el.content.text),
      },
    };
  };
};

// eslint-disable-next-line
export const preprocessBeforeSend = [
  {
    statementFunction: isDropdownElement,
    preprocessFunction: dropdownBeforeSend,
  },
  {
    statementFunction: shouldContainContentXYAndCroppedSize,
    preprocessFunction: addContentXYAndCroppedSizeBeforeSend,
  },
  {
    statementFunction: shouldToogleCheckmarkValueFromUndefinedToFalse,
    preprocessFunction: toggleValueFromUpdafinedToFalse,
  },
  {
    statementFunction: hasContentText,
    preprocessFunction: unformatFormat,
  },
  {
    // For all elements, LAST because transform element { ...element.content }
    statementFunction: () => {
      return true;
    },
    preprocessFunction: () => {
      return (element) => {
        return {
          ...element.content,
        };
      };
    },
  },
];
