import React from 'react';
import PropTypes from 'prop-types';
import useTemporaryDisableBlur from './useTemporaryDisableBlur';

const focusControllerSelectDecorator = (ReactSelect) => {
  const DecoratedReactSelect = React.forwardRef((props, ref) => {
    const { onFocus, onBlur } = props;

    const [
      onFocusWithDisableBlur,
      onBlurWithDisableBlur,
    ] = useTemporaryDisableBlur(onFocus, onBlur);

    return (
      <ReactSelect
        {...props}
        ref={ref}
        onFocus={onFocusWithDisableBlur}
        onBlur={onBlurWithDisableBlur}
      />
    );
  });

  DecoratedReactSelect.propTypes = {
    onFocus: PropTypes.func,
    onBlur: PropTypes.func,
  };

  DecoratedReactSelect.defaultProps = {
    onFocus: null,
    onBlur: null,
  };

  return DecoratedReactSelect;
};

export default focusControllerSelectDecorator;
