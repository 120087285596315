import sortedIndexBy from 'lodash/sortedIndexBy';
// import get from 'lodash/get';
import { flatIdToId } from '../Operations/Utils';
import { TOOL_TYPES, TOOL_SUB_TYPES } from '../../../constants';

export function fillComment(comment, state, isReply) {
  const timestamp = +new Date();
  const extendByType = isReply
    ? { id: String(comment.content.date || timestamp) }
    : {
      element: flatIdToId(comment.id),
      type: TOOL_TYPES.comment,
      subType: TOOL_SUB_TYPES.none,
      content: {
        width: 1,
        height: 1,
        resolved: false,
      },
    };

  return {
    ...extendByType,
    ...comment,
    content: {
      ...extendByType.content,
      author: state.viewerId,
      message: '',
      date: timestamp,
      ...comment.content,
    },
  };
}

export function deleteCommentInComments(comment, comments) {
  const result = comments.filter((el) => {
    return el.id !== comment.id;
  });

  // if nothing was changed remove old reference
  if (result.length === comments.length) {
    return comments;
  }
  return result;
}

export function applyUpdateToComment(commentUpdate, comment) {
  return {
    ...comment,
    content: {
      ...comment.content,
      ...commentUpdate.content,
    },
  };
}

export function updateCommentInComments(comment, comments) {
  return comments.map((el) => {
    if (el.id !== comment.id) {
      return el;
    }

    return comment;
  });
}

// convert two properties to number coef
function getSearchCoefficient(comment) {
  return (comment.pageId * 100000) + comment.content.y;
}

function insertAtIndex(index, comment, comments) {
  return [...comments.slice(0, index), comment, ...comments.slice(index)];
}

export function addCommentInComments(comment, comments) {
  // const thisCommentCoef = getSearchCoefficient(comment);
  const nextIndex = sortedIndexBy(
    comments,
    comment,
    (searchComment) => {
      return getSearchCoefficient(searchComment);
    },
  );

  return insertAtIndex(nextIndex, comment, comments);
}

export function moveCommentToNewIndex(comment, comments) {
  const commentsWithoutComment = comments.filter((el) => {
    return el.id !== comment.id;
  });
  if (commentsWithoutComment.length === comments.length) {
    return comments;
  }
  return addCommentInComments(comment, commentsWithoutComment);
}
