import React from 'react';
import PropTypes from 'prop-types';

const CustomFieldArrowsItem = ({ children }) => {
  return (
    <div className="custom-field__arrows-item">
      {children}
    </div>
  );
};

CustomFieldArrowsItem.propTypes = {
  children: PropTypes.element.isRequired,
};

export default CustomFieldArrowsItem;
