import isRetina from 'is-retina';
import { thisDevice } from '@pdffiller/jsf-useragent';
import config from 'jsfcore/helpers/clientConfig';
import isSignNow from './isSignNow';
import letterCases from './letterCases';

export { letterCases };

// Key codes set + -
/* 189 = -
 * 187 = +
 * 107 = num +
 * 109 = num -
 * 61 + & 173 - for FIREFOX
 */
export const keyCodesMap = {
  backspace: 8,
  esc: 27,
  tab: 9,
  enter: 13,
  del: 46,
  space: 32,
  left: 37,
  up: 38,
  right: 39,
  down: 40,
  e: 69,
  z: 90,
  s: 83,
  v: 86,
  0: 48,
  9: 57,

  // plusNumPad: 107, plusEqualFirefox: 61, plusEqualAll: 187,
  plus: [107, 61, 187],

  // minusNumPad: 109, minusEqualFirefox: 173, minusEqualAll: 189,
  minus: [109, 173, 189],
  other: -1,
};

export const logInterval = 5000;

/* --- Scale/Zoom --- */

export const outputScale = isRetina()
  ? 2.0
  : 1.0;

export const maxCanvasSize = 2000 * 2600;

export const decreaseScaleFactor = 0.8;
export const increaseScaleFactor = 1.25;
export const maxScale = isSignNow()
  ? 5.0
  : 3.0;
export const minScale = 0.25;

export const getMaxResolution = () => {
  if (thisDevice.isMobile) {
    return 2500 * 2500;
  }

  return 5000 * 5000;
};

export const defaultTransformOrigin = '50% 50% 0';

/* --- Animations --- */

const animation = 'cubic-bezier(0.000, 0.835, 0.440, 1.000)';

export const scaleAnimaion = animation;
export const pinchAnimation = animation;
export const zoomAnimation = animation;
export const paginationAnimation = animation;

export const pinchAnimationDuration = 400;

export const pageCountLabelAnimationTime = 1000; // ms
export const pageCountLabelAnimation = `opacity ${pageCountLabelAnimationTime}ms`;
export const pageCountLabelTimeout = 100; // ms
export const pageCountLabelForceTimeout = 2000; // ms

/* --- Pagination --- */
export const paginationTypes = {

  // Первая версия пагинации, на одном экране - одна страница
  simple: 'simple',

  // Новая версия пагинации, все страницы на одном экране (для SignNow)
  continious: 'continious',
};
export const paginationPanDistance = 100;
export const changePageAnimationTime = 1000;
export const scalePageAnimationTime = 550;
export const psScrollBarHidingTimeOnAnimation = 700;

export const validScrollInfelicity = 2;
export const scrollChangeTimer = 50;
export const minimumDragScrolling = 3;
export const requiredDistanceToScroll = 50;

export const changePageScrollPosition = 3;

/* --- UI --- */
export const pointerBodyWidth = 100;
export const pointerWidth = 120;

export const pageFrameDefaultPadding = {
  top: 20,
  right: 20,
  bottom: 20,
  left: 20,
};

export const commentsWidth = 325;
export const commentsLeftOffsetOnActive = -15;

export const pageFrameCommentsPadding = {
  ...pageFrameDefaultPadding,
  right: pageFrameDefaultPadding.right + commentsWidth,
};

export const pageFrameCommentsPaddingWithPointer = {
  ...pageFrameDefaultPadding,
  right: pageFrameDefaultPadding.right + commentsWidth,
  left: pageFrameDefaultPadding.left + pointerBodyWidth,
};

export const pageFramePointerPadding = {
  ...pageFrameDefaultPadding,
  left: pageFrameDefaultPadding.left + pointerBodyWidth,
};

export const pageFrameDefaultPaddingMobile = {
  top: 5,
  right: 5,
  bottom: 5,
  left: 5,
};

export const pageFrameDefaultPaddingMobileSnFiller = {
  top: 8,
  right: 2,
  bottom: 8,
  left: 2,
};

export const toolbarAttributesContainerType = {
  toolbar: 'toolbar',
  popover: 'popover',
  null: null,
};

// mobile popup menu entries
export const popupMenu = {
  main: 'main',
  fontFamily: 'fontFamily',
  fontSize: 'fontSize',
  fontColor: 'fontColor',
  color: 'color',
  backgroundColor: 'backgroundColor',
  borderColor: 'borderColor',
  fillColor: 'fillColor',
};

// desktop popup menu entries
export const popupMenuDesktop = {
  align: 'align',
  valign: 'valign',
  href: 'href',
  arrow: 'arrow',
  lineWidth: 'lineWidth',
  fontStyle: 'fontStyle',
};

// popupVisibility statuses
export const popupStatuses = {
  hidden: 'hidden',
  readyToShow: 'readyToShow', // wait until keyboard is closed
  shown: 'shown',
};

export const basicColors = [
  '000000', '0000FF', 'FF0000', 'FFFFFF', '6AA84F',
];

export const smallColors = [
  '4D4D4D',
  '333333',
  '000000',
  '999999',
  '808080',
  '666666',
  'FFFFFF',
  'CCCCCC',
  'B3B3B3',
  'F44E3B',
  'D33115',
  '9F0500',
  'FE9200',
  'E27300',
  'C45100',
  'FCDC00',
  'FCC400',
  'FB9E00',
  'DBDF00',
  'B0BC00',
  '808900',
  'A4DD00',
  '68BC00',
  '194D33',
  '68CCCA',
  '16A5A5',
  '0C797D',
];

export const ghostPosition = isSignNow()
  ? {
    top: 0,
    left: 0,
  }
  : {
    top: -15,
    left: 0,
  };
export const orientations = {
  portrait: 'portrait',
  landscape: 'landscape',
};
export const iosKeyboards = {
  '320x480': { portrait: 216, landscape: 0 }, // iphone 4
  '320x568': { portrait: 216, landscape: 0 },
  '375x667': { portrait: 216, landscape: 0 },
  '375x812': { portrait: 252, landscape: 0 }, // iphone X, XS
  '414x736': { portrait: 226, landscape: 0 },
  '414x896': { portrait: 262, landscape: 0 }, // iphone XS MAX, XR
  '768x1024': { portrait: 313, landscape: 398 },
  '834x1112': { portrait: 313, landscape: 398 },
  '1024x1366': { portrait: 371, landscape: 459 },
  '1024x768': { portrait: 398, landscape: 313 },
  '1366x1024': { portrait: 459, landscape: 371 },
};
export const iosKeyboardsWithPanel = {
  '320x480': { portrait: 216, landscape: 0 }, // iphone 4
  '320x568': { portrait: 253, landscape: 0 },
  '375x667': { portrait: 258, landscape: 0 },
  '375x812': { portrait: 252, landscape: 0 }, // iphone X, XS
  '414x736': { portrait: 226, landscape: 0 },
  '414x896': { portrait: 262, landscape: 0 }, // iphone XS MAX, XR
  '768x1024': { portrait: 313, landscape: 398 }, // fix
  '834x1112': { portrait: 313, landscape: 398 },
  '1024x1366': { portrait: 371, landscape: 459 }, // fix
  '1024x768': { portrait: 398, landscape: 313 },
  '1366x1024': { portrait: 459, landscape: 371 },
};
export const iosDateKeyboards = {
  '320x480': { portrait: 216, landscape: 0 }, // iphone 4
  '320x568': { portrait: 216, landscape: 0 },
  '375x667': { portrait: 216, landscape: 0 },
  '375x812': { portrait: 195, landscape: 0 }, // iphone X, XS
  '414x736': { portrait: 226, landscape: 0 },
  '414x896': { portrait: 205, landscape: 0 }, // iphone XS MAX, XR
  '768x1024': { portrait: 313, landscape: 398 },
  '834x1112': { portrait: 313, landscape: 398 },
  '1024x1366': { portrait: 371, landscape: 459 },
  '1024x768': { portrait: 398, landscape: 313 },
  '1366x1024': { portrait: 459, landscape: 371 },
};
export const zeroSpace = { portrait: 0, landscape: 0 };
export const thumbnailsMargin = 20;
export const thumbnailMaxWidth = 130;
export const initialCanvasContainerWidth = 130;
export const initialCanvasContainerHeight = 170;
export const initialCanvasBackgroundColor = '#f2f2f2';
export const marginOfLeftSideBar = { marginLeft: '-184px' };
export const ids = {
  wrapper: 'wrapper',
};
export const refs = {
  pageRef: 'page',
  wrapperRef: 'wrapper',
  workspaceRef: 'workspaceRef',
  pageZoomRef: 'pageZoomRef',
  paginationRef: 'paginationRef',
  paginationScrollerRef: 'paginationScrollerRef',
  pagesRef: (index) => {
    return `page${index}`;
  },
  pageFrameRef: 'pageFrameRef',
  pageScrollRef: 'pageScrollRef',
  pinchHammerRef: 'pinchHammerRef',
  pinchElRef: 'pinchElRef',
  pageCanvasRef: 'pageCanvasRef',
  thumbnailRef: (index) => {
    return `thumbnail${index}Ref`;
  },
  thumbnailElRef: 'thumbnailElRef',
  thumbnailsRef: 'thumbnailsRef',
  thumbnailsWrapRef: 'thumbnailsWrapRef',
  contentRef: 'contentRef',
  elementRef: 'elementRef',
  elementToolRef: 'elementToolRef',
  elemRef: (index) => {
    return `element${index}Ref`;
  },
  textToolTextareaRef: 'textToolTextareaRef',
  toolSizeRef: 'toolSizeRef',
  dragLayerElemRef: 'dragLayerElemRef',
  flexGridRef: 'flexGridRef',
  headerToolRef: (tool) => {
    return `header${tool}Ref`;
  },
  headerFormRef: (tool) => {
    return `header${tool}formRef`;
  },
};

/* --- PDF --- */
export const storedCanvases =
  config.app.paginationType === paginationTypes.simple
    ? [-1, 0, 1]
    : [-5, -4, -3, -2, -1, 0, 1, 2, 3, 4, 5];

export const needGet = [-1, 0, 1];
export const needRender = [-1, 0, 1];
export const run = {
  get: [-5, -4, -3, -2, -1, 0, 1, 2, 3, 4, 5],
  render: [-1, 0, 1],
  renderThumbnail: [-5, -4, -3, -2, -1, 0, 1, 2, 3, 4, 5],
};

// on iOS: long tap on text makes magnifier for cursor move.
// here is timeout for this
export const pressCancelTimeout = 500;

/* --- Elements --- */
export const sizes = [
  8, 9, 10, 11, 12, 14, 16, 18, 20, 24, 28, 32, 36, 42, 48, 52, 60, 72,
];

export const minFontSizeSN = 11; // minimal fontSize for SignNow

// export const minSVGSize = { width: 5, height: 5 };
// export const maxSVGSize = { width: 200, height: 200 };
// export const defaultSVGSize = { width: 10, height: 10 };
// export const svgSizeInc = 1.25;
// export const svgSizeDec = 0.8;

// export const imageSizeInc = 1.25;
// export const imageSizeDec = 0.8;
export const sizeDeltas = {
  checkmark: 5, // px
  image: 5, // px
  signature: 5, // px
};
export const elementMinSize = { width: 5, height: 5 };

export const defaultMargins = { marginTop: 0, marginBottom: 0, marginLeft: 0, marginRight: 0 };

export const thicknessValues = {
  min: 1,
  max: 50,
  step: 1,
  defaultValue: 1,
};

export const valignValues = {
  top: 'top',
  middle: 'middle',
  bottom: 'bottom',
};

export const alignValues = {
  left: 'left',
  center: 'center',
  right: 'right',
};

export const defaultLineHeight = 1.21;

export const textRendering = 'geometricPrecision';

export const fonts = {
  Arial: 'Arial',
  TimesNewRoman: 'Times New Roman',
  CourierNew: 'Courier New',
  Tahoma: 'Tahoma',
  ComicSansMS: 'Comic Sans MS',
  JellykaCuttyCupcakes: 'Jellyka CuttyCupcakes',
  Signature: 'Signature',
};

/** map-object with fonts parameters */
export const fontsMap = {
  [fonts.Arial]: {
    value: fonts.Arial,
    label: 'Arial',
    className: 'font-arial',
    baseline: 89.5 / 121,
    baselines: {
      0.7: 62.5 / 70,
      1: 76.5 / 100,
      1.21: 89.5 / 121,
      2: 127.5 / 200,
      3.5: 202.5 / 350,
      1.5: 102.5 / 150,
    },
  },
  [fonts.TimesNewRoman]: {
    value: fonts.TimesNewRoman,
    label: 'Times New Roman',
    className: 'font-times-new-roman',
    baseline: 98.5 / 121,
    baselines: {
      0.7: 70 / 70,
      1: 87.5 / 100,
      1.21: 98.5 / 121,
      2: 138.5 / 200,
      3.5: 213.5 / 350,
      1.5: 113.5 / 150,
    },
  },
  [fonts.CourierNew]: {
    value: fonts.CourierNew,
    label: 'Courier New',
    className: 'font-courier-new',
    baseline: 87.5 / 121,
    letterSpacing: '-1.21px',
    baselines: {
      0.7: 62.5 / 70,
      1: 77.5 / 100,
      1.21: 87.5 / 121,
      2: 127.5 / 200,
      3.5: 202.5 / 350,
      1.5: 102.5 / 150,
    },
  },
  [fonts.Tahoma]: {
    value: fonts.Tahoma,
    label: 'Tahoma',
    className: 'font-tahoma',
    baseline: 100.5 / 121,
    baselines: {
      0.7: 70 / 70,
      1: 91 / 100,
      1.21: 100.5 / 121,
      2: 140.5 / 200,
      3.5: 215.5 / 350,
      1.5: 115.5 / 150,
    },
  },
  [fonts.ComicSansMS]: {
    value: fonts.ComicSansMS,
    label: 'Comic Sans MS',
    className: 'font-comic-sans',
    baseline: 103.5 / 121,
    baselines: {
      0.7: 73.5 / 70,
      1: 94.5 / 100,
      1.21: 103.5 / 121,
      2: 144.5 / 200,
      3.5: 219.5 / 350,
      1.5: 120.5 / 150,
    },
  },
  [fonts.JellykaCuttyCupcakes]: {
    value: fonts.JellykaCuttyCupcakes,
    label: 'Signature',
    className: 'font-JellykaCuttyCupcakes',
    baseline: 77.5 / 121,
    baselines: {
      0.7: 51.5 / 70,
      1: 66.5 / 100,
      1.21: 77.5 / 121,
      2: 115.5 / 200,
      3.5: 192.5 / 350,
      1.5: 91.5 / 150,
    },
  },
  [fonts.Signature]: {
    value: fonts.Signature,
    label: 'Signature Old',
    className: 'font-signature',
    baseline: 86.5 / 121,
    letterSpacing: '-0.51px',
    baselines: {
      0.7: 60.5 / 70,
      1: 75.5 / 100,
      1.21: 86.5 / 121,
      2: 125.5 / 200,
      3.5: 199.5 / 350,
      1.5: 100.5 / 150,
    },
  },
};

/** list of fonts, used for render menu */
export const fontsList = [
  fonts.Arial,
  fonts.TimesNewRoman,
  fonts.CourierNew,
  fonts.Tahoma,
  fonts.ComicSansMS,
  fonts.JellykaCuttyCupcakes,
  fonts.Signature,
];

export const fontsUnsupportedBold = [
  'Jellyka CuttyCupcakes',
  'Signature',
  'Tahoma',
];

export const fontsUnsupportedItalic = [
  'Jellyka CuttyCupcakes',
  'Signature',
  'Tahoma',
];

export const picGhostOutline = '1px solid #F00';

export const maxCharsInTextTool = 30000;

// Next two numbers got from static css. WARNING: keep it actual
export const mobilePopupHeightPx = 240;
export const mobilePopupMinScreenWidthPx = 767;

export const defaultDateFormat = 'MM/DD/YYYY';

// a padding between graphic tool element and corresponding SizeTool border
export const graphicToolPaddingPx = 2;

// className for Content::nodeIsElement
export const mouseFocusClassName = 'mouseFocusClassName';

// className for Content::onLongTap
export const longTapClassName = 'longTapClassName';

// keys in element.content for different types of colors ('fillColor' is for grapic tools)
export const colorKeys = {
  color: 'color',
  fontColor: 'fontColor',
  fillColor: 'fillColor',
  bgColor: 'bgColor',
  borderColor: 'borderColor',
};

// state.ws.connection.status values go here
// TODO: Warning! Keep it actual with ws-editor-lib
export const connectionStatuses = {
  none: 'none',
  connect: 'connect',
  disconnect: 'disconnect',
  error: 'error',
  destroy: 'destroy',
  destroyed: 'destroyed',
};

// state.ws.system.status values go here
// TODO: Warning! Keep it actual with ws-editor-lib
export const systemStatuses = {
  none: 'none',
  destroy: 'destroy',
  destroyed: 'destroyed',
  operationsReceive: 'operations receive',
};

export const accessStatuses = {
  none: 'none',
  busy: 'busy',
  edit: 'edit',
};

// When we press and hold 'size up/down' button
export const sizeChangeInterval = 150; // ms

export const textToolArrangements = {
  cells: 'cells',
  none: 'none',
};

export const longTapTimeout = 1000;

export const doubleClickTimeout = 300; // ms

// if diff between two values less than this param - we'll think it are equal
export const sizeEpsilon = 0.1;

// Метод SearchHighlighter::getHighlightRects() отдает чуть-чуть разные результаты
// при разных scale. При масштабе 50% и 250% разница достигает 1.3px. Цифра 2 взята с запасом
export const searchSizeEpsilon = 2;

// When we scrollToElement - givi it 20px margins (JSF-1111)
export const scrollMarginToElement = 20; // px

// Максимальная ширина body при которой попап настроек фиксируется внизу страницы
// NOTE: нужно держать в актуальном с версткой состоянии
export const bodyWidthFixed = 767;

// used in Pagination.js, need to describe if we want go to the next page or previous
export const changePageDirection = {
  next: 'next',
  prev: 'prev',
  none: 'none',
};

// eslint-disable-next-line no-script-url
export const onDoneScenarioString = 'javascript:runScenario(\'onDone\')';

export const editType = {
  standard: 'standard',
  l2f: 'l2f',
  s2s: 's2s',
  as: 'as',
};

export const modalWrapperClassname = 'jsf-wrap-modal';

export const brushTypes = {
  brush: 'brush',
  square: 'square',
  round: 'round',
};

export const drawingLayerTypes = {
  points: 'points',
  curve: 'curve',
  none: 'none',
};

export const elemTypes = {
  text: 'text',
  checkmark: 'checkmark',
  sticky: 'sticky',
  textbox: 'textbox',

  line: 'line',
  arrow: 'arrow',

  blackout: 'blackout',
  highlight: 'highlight',
  erase: 'erase',
  pen: 'pen',

  signature: 'signature',
  image: 'image',

  // not actually element type, but tool type
  // пустой тул, для выделения элементов на тех документах, где textTool disabled
  selection: 'selection',
};

// id кнопки Message в заголовке
export const messageButtonHeaderId = 'main_header-msgBtn';

// Идентификатор заголовка
export const mainHeaderId = 'main_header';

// id - tools dropdown button, only for phones,
// when we're not in the fillable mode
export const toolsDropDownButtonId = 'tools_dropdown_button';

// Коэффициент размера icon относительно размера шрифта поля ввода
export const iconSizeFactor = 0.5;

// Возможные значения зума
export const possibleZoomRange = [
  { value: 0.25, label: '25%' },
  { value: 0.50, label: '50%' },
  { value: 0.75, label: '75%' },
  { value: 1, label: '100%' },
  { value: 1.25, label: '125%' },
  { value: 1.50, label: '150%' },
  { value: 2, label: '200%' },
  { value: 3, label: '300%' },
  { value: 5, label: '500%' },
];

export const defaultZoomScale = 1;
export const zoomMaxValue = 3.0;
export const zoomMinValue = 0.25;
export const zoomTick = 0.05;
export const zoomTickSNF = 0.2;

/**
 * Windows and Linux: Press Ctrl and +
 * Mac: Press ⌘ and +
 * Chrome OS: Press Ctrl and +
 * Without Shift key
 */
export const getIsPressedMetaKey = ({ ctrlKey, metaKey, shiftKey }) => {
  return !shiftKey && thisDevice.isMacOSX
    ? metaKey
    : ctrlKey;
};


export const positions = {
  none: 0,
  top: 0x1,
  bottom: 0x2,
  left: 0x4,
  right: 0x8,
  topleft: 0x5, // 0x1 | 0x4
  topright: 0x9, // 0x1 | 0x8
  bottomleft: 0x6, // 0x2 | 0x4
  bottomright: 0xA, // ox2 | 0x8
};

// Allowable time for document loading, after that time throw an error.
export const appStartTimeoutLimit = 90 * 1000;

export const wizardTraverseModes = {
  all: 'all',
  requiredUnfilled: 'requiredUnfilled',
  allUnfilled: 'allUnfilled',
};

export const HintModal = 'HintModal';
export const LockModal = 'LockModal';

export const elemPrevUserActedTrackPoint = {
  done: 'DoneButton',
  logo: 'LogoImage',
};

export const allowToCreateTimeoutMs = 800;

export const occurenceTypes = {
  element: 'element',
  pdfText: 'pdfText',
};

export const searchTermChangeTimeout = 350;

export const checkMarkSubTypes = {
  x: 'x',
  v: 'v',
  o: 'o',
};

export const quantityOfVisibleOptions = 4;
const selectOptionHeight = 35;

export const menuSelectStyle = {
  overflowX: 'auto',
  maxHeight: quantityOfVisibleOptions * selectOptionHeight,
  maxWidth: 220,
};

export const paSections = {
  watermark: 'watermark',
  date: 'date',
  numbering: 'numbering',
  bates: 'bates',
};

export const textToolFillablePadding = 2;

/**
 * Поправка на ветер при расчете максимального размера
 * поломанных fillable полей.
 *
 * Поломанным полем считается fillable поле размер которого приходит таким,
 * что в него нельзя записать даже одну строку в заданных настройках.
 *
 * Например:
 * Размер шрифта 8, высота строки 9.68, 2 пикселя паддинг с обеих сторон
 * Высота поля которая должна была придти с сервера - 11.68
 * А приходит например 11.5.
 *
 * Поправка же на ветер нужна в связи с тем, что даже 11.68 (из примера выше)
 * оказывается неспособным вместить в себя текст в некоторых браузерах. Число
 * выбрано наугад, и должно решать проблему, но быть меньше высоты которая
 * позволит вместить в это поле 2 строки.
 */
export const amendmentToTheWind = 0.6;

export const minSearchTermLength = 1;

export const magneticDistance = 4;

export const magneticLineDirections = {
  horizontal: 'horizontal',
  vertical: 'vertical',
};
// rightReserveForScroll - PS scroll section width in px
export const rightReserveForScroll = 10;

// If we see '-1' at watermark.pagesRange - it means all pages
export const allPagesLiteral = '-1';

export const defaultUser = {
  id: 0,
  name: 'Vasya Pupkin',
  email: 'user@email.com',
};

export const tooltipTimeout = 700; // ms

// similar ws.features.logo.urls
export const logoUrls = {
  urls: {
    large: 'https://static-ak.pdffiller.com/components/global-ui/g-logo/img/svg/logo-pdffiller.svg',
    small: 'https://static-ak.pdffiller.com/img/header/logo-pdffiller-small.svg',
  },
};

export const mobileDefaultDateFormat = 'yyyy-MM-dd';

export const stylePositionAbsolute = { position: 'absolute' };

export const styleOpacityZero = { opacity: 0 };

export const ghostVisibleOverClassName = 'ghostVisibleOverClassName';

// hide ghost when mouse is on comment
export const commentsHideGhostClassname = 'commentsHideGhostClassname';

export const fakeEditHighlighterClassname = 'fakeEditHighlighterClassname';

// classname for various buttons to prevent default click behavior (element creating etc.)
// e.g. endPoints, button at upper-right corner of FakeEditHighlighter or EraseTool...
export const pageClickBlockerClassname = 'pageClickBlockerClassname';

export const miscIds = {
  search: { id: 'common.search', label: 'Search' },
  rearrange: { id: 'tools.pages', label: 'Rearrange' },
  thumbnails: { id: 'common.thumbnails', label: 'Thumbnails' },
  help: { id: 'common.help', label: 'Help' },
  feedback: { id: 'common.feedback', label: 'Feedback' },
  comments: { id: 'tools.comments', label: 'Comments' },
  fakePdfEdit: { id: 'tools.fakePdfEdit', label: 'Alter original' },
};

export const extrasbarIds = {
  fConstructor: { id: 'tools.template' },
  watermark: { id: 'tools.attributes' },
  versions: { id: 'versions' },
};

export const elementIsDraggingTimeout = 200; // ms

export const webPageId = 'js_editor';

export const supportPageUrl = 'https://www.pdffiller.com/en/support';

export const modeIds = {
  main: 'mode.main',
  constructor: 'mode.constructor',
  preview: 'mode.preview',
};

export const constructorButtonIds = {
  breadcrumbs: 'control.breadcrumbs',
  cancel: 'button.cancel',
  preview: 'button.preview',
  save: 'button.save',
};

// add to pdfText item 29% of its height for fontRecognize
// в item должны влезать все хвостики снизу и сверху
// Значение получено "на глазок", экспериментальным путем
export const pdfTextItemMargin = 0.29;

export const constructorProperties = {
  fieldName: 'properties.fieldName',
};

export const snDefaultPageWidth = 890;

export const minContiniousScale = isSignNow()
  ? 0.1
  : 0.5;
export const maxContiniousScale = 3;

export const launchTypes = {
  constructor: 'constructor',
};

export const smoothScrollDefaultOptions = {
  duration: 100,
};

export const SUPPORT_CHAT_ID = 'chat-app';

export const defaultSidesOfDraggablePanel = {
  left: 'left',
  right: 'right',
  bottomRight: 'bottomRight',
};

export const DRAGGABLE_ENTITIES = {
  conditions: 'conditions',
  supportChatButton: 'supportChatButton',
};

export const SIGNATURE_MANAGER_MODAL_ID = 'SignatureManagerWrapper';
export const numberRegex = /^\d+([.,]\d+)?$/;
// as described at https://pdffiller.atlassian.net/browse/JSF-5284
export const textFieldViewModes = {
  classic: 'classic',
  warning: 'warning',
  stretch: 'stretch',
  combo: 'combo', // looks like 'stretch' while focused, looks like 'warning' when unfocused
};

export const textFieldMaxLengths = {
  [textFieldViewModes.classic]: Infinity,
  [textFieldViewModes.warning]: 5000,
  [textFieldViewModes.stretch]: 5000,
  [textFieldViewModes.combo]: 5000,
};

export const signNowSignatureLabel = 'SignNow';

export const IFRAME = 'iframe';

// минимальное количество пикселей (event.deltaY), которое необходимо для переключения страницы
export const minimumDeltaY = 5;
export const minimumDeltaYFF = 2;

export const bigPdfPagesCount = 150;

export const scrollAnimationDurationMS = 300;

// SNF-758, SNF-759
// maxChars, maxLines для SignNow обозначены хардкоженными константами,
// если не установлено валидатора, в валидаторах свои значения
export const defaultCharSettingsSN = {
  maxChars: 8,
  maxLines: 1,
};

export const headerOverlayTypes = {
  wizard: 'wizard',
  readOnly: 'read_only',
  signingSession: 'signingSession',
  empty: '',
};

export const signingSessionTextSets = {
  linktofill: 'linktofill',
  pdffiller: 'pdffiller',
  airslate: 'airslate',
};

export const availableFieldsModes = {
  requiredAndOptional: 'requiredAndOptional',
  requiredOnly: 'requiredOnly',
  optionalOnly: 'optionalOnly',
};

export const fieldsFilledModes = {
  before: 'before',
  during: 'during',
  after: 'after',
};

export const defaultControlPoints = [0, 0, 0, 0];

export const toolsTrackPoints = {
  ADDED_SIMPLE_TOOL: 'ADDED_SIMPLE_TOOL',
  ADDED_SIMPLE_TEXT: 'ADDED_SIMPLE_TEXT',
  ADDED_FILLABLE_FIELD: 'ADDED_FILLABLE_FIELD',
  // unused at this time
  // ADDED_FIELD_CONTENT: 'ADDED_FIELD_CONTENT',
};
