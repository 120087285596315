import pickBy from 'lodash/pickBy';
import partition from 'lodash/partition';

import { getEventPath, hasClass, formatDate } from '../../helpers/utils';
import {
  elemTypes,
  isTextToolBasedElement,
  isLineType,
  isDrawRectType,
} from '../../helpers/elemTypes';
import { defaultDateFormat, drawingLayerTypes } from '../../helpers/const';
import { isDate, isCheckmarkElement, isSignatureCurve,
  isSignatureText } from '../../store/helpers/functions';
import { getFocusElement } from '../../store/helpers/stateToProps';

export const getObjectForAppendToNewElement = (ghost) => {
  return {
    ...(
      isTextToolBasedElement(ghost) && (!ghost.content || !ghost.content.text)
        ? { text: '' }
        : {}
    ),

    ...(
      (isDate(ghost) && {
        width: 72,
        text: formatDate(new Date(), defaultDateFormat),
        height: 17,
      }) || {}
    ),

    ...(
      isCheckmarkElement(ghost)
        ? { checked: true }
        : {}
    ),
    ...(isSignatureCurve(ghost)
      ? {
        width: ghost.content.width,
        height: ghost.content.height,
      }
      : {}),

    ...(isSignatureText(ghost)
      ? {
        bold: false,
        italic: false,
        underline: false,
      }
      : {}),
  };
};

export const getFocusElements = (elements, focusElementId) => {
  return Object.values(
    pickBy(
      elements,
      (elem) => {
        return elem && typeof elem === 'object' && elem.id && elem.id === focusElementId;
      },
    ),
  );
};

export const getEraseSeparately = (elements) => {
  const parts = partition(elements, ['type', elemTypes.erase]);
  return {
    eraseElements: parts[0],
    otherElements: parts[1],
  };
};

/**
 * Get drawingLayerType for render on Content
 *
 * @param {object} state - redux state
 * @returns {string} one of helpers/const::drawingLayerTypes values
 */
export const getDrawingLayerType = (state) => {
  if (
    !state ||
    !state.ws || !state.ws.elementsMap ||
    !state.ws.ghostElement || !state.events
  ) {
    return drawingLayerTypes.none;
  }

  const {
    ws: { elementsMap, ghostElement },
    events: { stretchingElementId },
  } = state;

  // Для вычисления drawingLayerPoints порядок важен:
  // В первую очередь смотрим на stretchingElement, если его нет -
  // то на focusElement, если и его нет - то на гост.
  // Для drawingLayerCurve смотрим только на ghostElement
  const drawingElement =
    elementsMap[stretchingElementId] ||
    getFocusElement(state) ||
    ghostElement;

  if (isLineType(drawingElement.type)) {
    return drawingLayerTypes.points;
  }

  if (ghostElement.type === elemTypes.pen || isDrawRectType(ghostElement.type)) {
    return drawingLayerTypes.curve;
  }

  return drawingLayerTypes.none;
};

export const getElementIdByNode = (node) => {
  const nodePath = getEventPath(node);
  const nodes = nodePath.filter((item) => {
    return hasClass(item, 'wrapper-Element');
  });
  if (nodes.length < 1) {
    return false;
  }
  return nodes[0].dataset.id;
};

export const getDefaultDateStamp = () => {
  return (formatDate(new Date(), defaultDateFormat));
};

export const applyDateStampIfNeed = ({ ghost, useSigDateStamp }) => {
  return useSigDateStamp && ghost.type === elemTypes.signature
    ? { dateStamp: getDefaultDateStamp() }
    : {};
};
