import { take, select, put, all } from 'redux-saga/effects';
import {
  actionTypes as AT,
  activatePendingModeForAddons,
  sendTriggerOperation,
} from 'ws-editor-lib/actions';
import { selectors } from 'jsfcore';
import {
  isCheckmarkElement,
  isDropdownElement,
  isFillable,
  isRadioElement,
} from 'jsfcore/store/helpers/functions';
import get from 'lodash/get';
import { isTextToolBasedElement } from 'jsfcore/helpers/elemTypes';
import editorModes from 'ws-editor-lib/constants/editorModes';
import { TRIGGER_TYPES } from 'ws-editor-lib/constants';
import { deactivateElement } from 'jsfcore/store/thunks';

const getValueHasChanged = (elementBeforeUpdate, elementAfterUpdate) => {
  if (!elementBeforeUpdate || !elementBeforeUpdate.content) {
    return false;
  }

  const pathToValue = isCheckmarkElement(elementBeforeUpdate) || isRadioElement(elementBeforeUpdate)
    ? 'checked'
    : 'text';

  const elementValue = get(elementBeforeUpdate, `content.${pathToValue}`);
  const nextValue = get(elementAfterUpdate, `content.${pathToValue}`);

  return nextValue === undefined
    ? false
    : elementValue !== nextValue;
};

function* getShouldActivatePendingMode() {
  const mode = yield select(selectors.base.getMode);

  return mode === editorModes.main;
}

function* getIsElementContainTriggers(element) {
  const isFConstructorShown = yield select(selectors.base.getIsFConstructorShown);
  const isElementMightContainTriggers = isTextToolBasedElement(element) ||
    isDropdownElement(element) ||
    isCheckmarkElement(element) ||
    isRadioElement(element);

  if (
    !element ||
    !isFillable(element) ||
    isFConstructorShown ||
    !isElementMightContainTriggers
  ) {
    return false;
  }
  const triggersLength = get(element, 'template.triggers.length', 0);

  return triggersLength > 0;
}

function* activatePendingModeForAddonsIfNeed(elementBeforeUpdate, elementAfterUpdate) {
  if (!(elementBeforeUpdate && elementAfterUpdate)) {
    return;
  }

  const isElementContainTriggers = yield* getIsElementContainTriggers(elementBeforeUpdate);
  const isValueChanged = getValueHasChanged(elementBeforeUpdate, elementAfterUpdate);
  // we want to sendTriggers only if an element contains triggers and has changed value
  if (!(isElementContainTriggers && isValueChanged)) {
    return;
  }

  const shouldActivatePendingMode = yield* getShouldActivatePendingMode();

  if (shouldActivatePendingMode) {
    yield put(deactivateElement());
    yield put(activatePendingModeForAddons());
  }
  yield put(sendTriggerOperation({
    element: elementAfterUpdate.element,
    trigger: TRIGGER_TYPES.focusOut,
  }));
}

function* onSetActiveElement() {
  while (true) {
    // first we get an element before another SET_ACTIVE_ELEMENT call
    const elementBeforeUpdate = yield select(selectors.elements.getActiveElement);
    const action = yield take(AT.SET_ACTIVE_ELEMENT);

    // then (after SET_ACTIVE_ELEMENT call) we check if the element was deactivated or changed
    const isElementChanged = elementBeforeUpdate && action.id !== elementBeforeUpdate.id;
    const isElementDeactivated = elementBeforeUpdate &&
      action.id === elementBeforeUpdate.id &&
      action.activeStatus === false;

    if (isElementChanged || isElementDeactivated) {
      // if the element was changed we get an element with updated state and call
      // activatePendingModeForAddonsIfNeed
      const elementAfterUpdate = yield select(selectors.base.getElement, elementBeforeUpdate.id);
      yield* activatePendingModeForAddonsIfNeed(elementBeforeUpdate, elementAfterUpdate);
    }
  }
}

function* onUpdateElements() {
  while (true) {
    const elementsBeforeUpdate = yield select(selectors.base.getElementsMap);
    const action = yield take(AT.UPDATE_ELEMENTS);
    const elementsAfterUpdate = yield select(selectors.base.getElementsMap);
    const elements = get(action, 'elements', []);

    for (let i = 0; i < elements.length; i += 1) {
      const elementAfterUpdate = elementsAfterUpdate[elements[i].id];
      const elementBeforeUpdate = elementsBeforeUpdate[elementAfterUpdate.id];

      yield* activatePendingModeForAddonsIfNeed(elementBeforeUpdate, elementAfterUpdate);
    }
  }
}

export default function* () {
  yield all([
    onSetActiveElement(),
    onUpdateElements(),
  ]);
}
