import React from 'react';
import PropTypes from 'prop-types';

import classnames from 'classnames';

export const pointerThemes = {
  primary: 'primary',
  default: null,
};

export const pointerSizes = {
  medium: 'medium',
  default: null,
};

export const dataPointerDirections = {
  null: null,
  top: 'top',
  left: 'left',
  right: 'right',
  bottom: 'bottom',
};

const Pointer = ({
  theme,
  size,
  dataPointerDirection,
  children,
  pointerBodyWidth,
  ...restProps
}) => {
  return (
    <div
      className={classnames(
        'pointer-assistant', {
          [`pointer-assistant--size--${size}`]: size,
          [`pointer-assistant--theme--${theme}`]: theme,
        },
      )}
      data-pointer-direction={dataPointerDirection}
      {...restProps}
    >
      <div
        className="pointer-assistant__body"
        style={{ width: pointerBodyWidth }}
      >
        {children}
      </div>
    </div>
  );
};

Pointer.propTypes = {
  children: PropTypes.element,
  size: PropTypes.oneOf(Object.values(pointerSizes)),
  theme: PropTypes.oneOf(Object.values(pointerThemes)),
  dataPointerDirection: PropTypes.oneOf(Object.values(dataPointerDirections)),
  pointerBodyWidth: PropTypes.number.isRequired,
};

Pointer.defaultProps = {
  children: null,
  size: pointerSizes.default,
  theme: pointerThemes.default,
  dataPointerDirection: dataPointerDirections.null,
};

export default Pointer;
