import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

const SortableElement = ({
  attributes,
  children,
  theme,
  size,
  offset,
  isActive,
  ...props
}) => {
  return (
    <div
      className={classnames('sortable-element', {
        [`sortable-element--theme--${theme}`]: theme,
        [`sortable-element--size--${size}`]: size,
        [`sortable-element--offset--${offset}`]: offset,
        'is-active': isActive,
      })}
      {...attributes}
      {...props}
    >
      <div className="sortable-element__body">
        {children}
      </div>
    </div>
  );
};

const sortableElementThemes = {
  null: null,
  default: 'default',
};

const sortableElementSizes = {
  null: null,
  small: 'small',
};

const sortableElementOffsets = {
  null: null,
  extraSmall: 'extra-small',
  small: 'small',
};

SortableElement.propTypes = {
  attributes: PropTypes.shape({}),
  children: PropTypes.node.isRequired,
  theme: PropTypes.string,
  size: PropTypes.string,
  offset: PropTypes.string,
  isActive: PropTypes.bool,
};

SortableElement.defaultProps = {
  attributes: {},
  theme: sortableElementThemes.null,
  size: sortableElementSizes.null,
  offset: sortableElementOffsets.null,
  isActive: false,
};

export { sortableElementThemes, sortableElementSizes, sortableElementOffsets };
export default SortableElement;
