import React from 'react';
import PropTypes from 'prop-types';

const TextBoxList = ({ children }) => {
  return (
    <ul className="text-box__list">
      {children}
    </ul>
  );
};

TextBoxList.propTypes = {
  children: PropTypes.node.isRequired,
};

export default TextBoxList;
