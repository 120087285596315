import { formula, getLazy, mocks } from '@pdffiller/jsf-lazyload';
import isSignNow from 'ws-editor-lib/isSignNow';
import { updateFillableElements } from 'ws-editor-lib/actions';

import { elements as elementsSelectors, base } from '../selectors';

const updateFormulasDependentOnElement = ({
  dispatch,
  getState,
  prevTemplate,
  activeTemplate,
}) => {
  if (activeTemplate.name === undefined) {
    return;
  }

  const state = getState();
  const activeElementId = base.getActiveElementId(state);

  const { getFormulasDependentOnElement } = getLazy(formula.objects.selectors, {
    getFormulasDependentOnElement: mocks.fns.object,
  });
  const { endSymbol, startSymbol } = getLazy(formula.objects.consts, {
    endSymbol: mocks.null,
    startSymbol: mocks.null,
  });

  const getElementsToUpdateSNF = getLazy(
    formula.functions.getElementsToUpdateSNF,
    mocks.fns.object,
  );
  const getElementsToUpdateJSF = getLazy(
    formula.functions.getElementsToUpdateJSF,
    mocks.fns.object,
  );
  const createFormulaVariableInText = getLazy(
    formula.functions.createFormulaVariableInText,
    mocks.fns.object,
  );

  const formulasToUpdate = getFormulasDependentOnElement({ template: prevTemplate })(state);
  if (formulasToUpdate.length === 0) {
    return;
  }

  const oldFormulaInTextRegExp = new RegExp(
    `\\${startSymbol}${prevTemplate.name}\\${endSymbol}`, 'g',
  );
  const newFormulaInText = createFormulaVariableInText(activeTemplate.name);
  const elementsToUpdate = isSignNow()
    ? getElementsToUpdateSNF({
      formulasToUpdate,
      oldFormulaInTextRegExp,
      newFormulaInText,
      elements: elementsSelectors.getFillableEnabledElements(state),
      activeElementId,
      template: activeTemplate,
    })
    : getElementsToUpdateJSF({
      formulasToUpdate,
      oldFormulaInTextRegExp,
      newFormulaInText,
    });

  dispatch(updateFillableElements(elementsToUpdate));
};

export default updateFormulasDependentOnElement;
