import { isIntegrationField } from 'jsfcore/helpers/utils';

import findDependentElements from '../../helpers/findDependentElements';
import { getDependency, dependenciesMap } from '../../di';

/**
 * Function should check whether the element is in dependencies of other elements
 * (only in fConstructor mode)
 * - if there is - show warning, return false
 * - if not, return true
 * @return {bool} is 'free' element
 */
const conditionsRemoveElementCheck = (
  dispatch,
  getState,
  id,
) => {
  const jsfcoreSelectors = getDependency(dependenciesMap.jsfcoreSelectors);
  const jsfcoreModalsActions = getDependency(dependenciesMap.jsfcoreModalsActions);
  const jsfcoreModalsTypes = getDependency(dependenciesMap.jsfcoreModalsTypes);

  const state = getState();
  const element = jsfcoreSelectors.elements.getElementByIdFactory(id)(state);

  if (!jsfcoreSelectors.base.getIsFConstructorShown(state)) {
    return true;
  }
  if (!element.template) {
    return true;
  }
  if (isIntegrationField(element)) {
    return true;
  }

  const elements = jsfcoreSelectors.elements.getElements(state);
  const elementsWithDeps = elements.filter((elem) => {
    return (
      // Filter activeElement
      elem.id !== id &&

      // Filter elements without dependencies
      elem.template && elem.template.dependency && elem.template.conditional
    );
  });

  const dependentElements = findDependentElements(
    element.template.name,
    elementsWithDeps,
  );

  if (dependentElements.length > 0) {
    dispatch(
      jsfcoreModalsActions.openModal(
        jsfcoreModalsTypes.modalsMap[jsfcoreModalsTypes.modalTypes.warning],
        jsfcoreModalsTypes.warningTypes.removeWarning,
      ),
    );
    return false;
  }

  return true;
};

export default conditionsRemoveElementCheck;
