const hrefMargins = 2;
const hrefOutlineSize = 1;
const hrefShadow = {
  top: 0,
  left: 1,
  right: 1,
  bottom: 4,
};

export default {
  postMeasure(args) {
    const { anchorRect, elementRect } = args;
    return {
      ...args,
      anchorRect: {
        ...anchorRect,
        top: anchorRect.top - hrefOutlineSize,
        right: anchorRect.right + hrefOutlineSize + hrefMargins,
        bottom: anchorRect.bottom + hrefOutlineSize,
        left: anchorRect.left - hrefOutlineSize - hrefMargins,

        width: anchorRect.width + hrefOutlineSize * 2 + hrefMargins * 2,
        height: anchorRect.height + hrefOutlineSize * 2,
      },

      elementRect: {
        ...elementRect,
        top: elementRect.top + hrefShadow.top,
        right: elementRect.right + hrefShadow.right,
        bottom: elementRect.bottom + hrefShadow.bottom,
        left: elementRect.left - hrefShadow.left,

        width: elementRect.width + hrefShadow.left + hrefShadow.left,
        height: elementRect.height + hrefShadow.top + hrefShadow.bottom,
      },
    };
  },
};
