import request from 'superagent';
import get from 'lodash/get';
import actionCreator from 'combine-modals/lib/actions/actionCreator';
import RestApi from 'api-rest-component';
import { mergeLocales } from 'global-locale/lib/actions/actionCreators';
import JSFillerError from 'jsfcore/errors/JSFillerError';
import modes from '../../constants/editorModes';
import NetController from '../../controllers/NetController';
import {
  DENIED,
  TIME_FOR_REPEATED_REQUEST_CONSTANTS_HELP_STRUCTURE,
  WS_EDITOR_REST_API,
  QUERY_PARAMETERS,
  GROUPS,
  TYPES,
  TOOL_TYPES,
  TOOL_SUB_TYPES,
  VERSIONS_SUB_TYPES,
  POST_MESSAGES,
  ERROR_MESSAGES,
} from '../../constants';
import { ERROR_DESTROY_TYPE } from '../../actions/constants';
import {
  getParameterByName,
  getParametersAsObj,
  isOpenPageRearrangeModal,
  getUserAndAPIHash,
  getScreenResolution,
  getScale,
  isObjectSizeValid,
  isEmbedded,
  sendPostMessage,
} from '../helpers';
import {
  actionTypes as AT,
  destroy,
  addError,
  trackPoint,
  setApiHash,
  setMessage,
  activateMode,
  messageTypes,
  setExperimentalFeatures,
  setDefaultCheckmarkSubtype,
  setPdfDocumentScale,
  resetWSPages,
  setToolAndPages,
  setActiveElement,
  resetComments,
  resetElements,
} from '../../actions';
import sortOperationsByGroup from '../models/Operations';
import {
  sendImageList,
  sendSignaturesList,
  getTimeStampNow,
  getElementById,
  isElementCheckmark,
  isEmptyFillableElement,
  isFillableElement,
  fillOperations,
} from '../models/Operations/Utils';
import * as SystemConditions from '../models/Conditions/System';
import * as ConnectionConditions from '../models/Conditions/Connection';
import { MessageCondition } from '../models/Conditions/Message';

const isSystemInDestroyProcess = (status) => {
  return (
    status === SystemConditions.CONDITION_SYSTEM_SEND_DESTROY ||
    status === SystemConditions.CONDITION_SYSTEM_RECEIVE_DESTROY
  );
};

const pdfUrlRegExp = /"https?:\/\/fileservice(-dpf)?.pdffillers?\.com\/.+"/g;

const isMessageHasPdfUrl = (message) => {
  return pdfUrlRegExp.test(message);
};

function leaveAndSave(websocketClient, action, store) {
  const { system, connection } = store.getState().ws;

  if (action.err && action.err.location) {
    if (!isSystemInDestroyProcess(system.status)) {
      store.dispatch(destroy(action.err.location, false, ERROR_DESTROY_TYPE, action.err));
    }

    setTimeout(() => {
      // eslint-disable-next-line
      console.warn('redirect from ws-editor-lib window.location.href', action.err.location);
      window.location.href = action.err.location;
    }, 2000);
  } else {
    if (action.err &&
      connection.status !== ConnectionConditions.CONDITION_CONNECTION_DISCONNECT_STATUS) {
      store.dispatch(addError(action.err));
    }

    websocketClient.connectionLost();
  }
}

const handleBusyAccessStatus = (websocketClient, autoCheck) => {
  if (autoCheck) {
    return websocketClient.runGetAccessTimer();
  }

  websocketClient.disconnect();
  return websocketClient.connectionLost();
};

const processToolAndPagesOperationsIfNeed = (operationsByGroup, store) => {
  const { ws: { mode, source } } = store.getState();
  if (mode !== modes.versions) {
    return operationsByGroup;
  }

  const operationsByGroupObj = operationsByGroup.reduce((acc, operationsGroup) => {
    return {
      ...acc,
      [operationsGroup.group]: { ...operationsGroup.list },
    };
  }, {});

  // В некоторых случаях в режиме Versions
  // в ответе на превью версии не приходит операция pages
  // По этому нужна очистка pages
  // Если есть pages и source, то нужно отправить их одним dispatch(как и после rearrange)
  // Чтобы не возникало двойной работы над pdf(GET_DOCUMENT..)
  // и корректно рендерились страницы, тамбы и т.д.
  const versionsOperations = operationsByGroupObj[GROUPS.versions];
  // https://pdffiller.atlassian.net/browse/JSF-6507
  // Временный фикс, пока на беке не починят cancel version
  // const isCancelOperation = get(operationsByGroupObj, `${GROUPS.operations}.${TYPES.cancel}`);
  const restoreOperationSubType =
    get(operationsByGroupObj, `${GROUPS.versions}.${TYPES.restore}[0].properties.subType`, '');
  const isRestoreCurrentOperation = restoreOperationSubType === VERSIONS_SUB_TYPES.current;
  if (
    // (versionsOperations && !versionsOperations[TYPES.preview]) ||
    // (!versionsOperations && !isCancelOperation)
    !versionsOperations ||
    (
      versionsOperations &&
      !versionsOperations[TYPES.preview] &&
      !isRestoreCurrentOperation
    )
  ) {
    return operationsByGroup;
  }

  const pagesOperations =
    get(operationsByGroupObj, `${GROUPS.tools}.${TYPES.pages}`);
  const sourceOperations =
    get(operationsByGroupObj, `${GROUPS.document}.${TYPES.source}`);
  const currentPdfUrl = get(source, 'pdf.url');
  const receivedPdfUrl = get(sourceOperations, '[0].properties.pdf.url');
  if (!pagesOperations && currentPdfUrl !== receivedPdfUrl) {
    store.dispatch(resetWSPages());
  }

  if (pagesOperations) {
    store.dispatch(setToolAndPages([
      {
        list: { [TYPES.pages]: pagesOperations },
      }, {
        list: { [TYPES.source]: sourceOperations },
      },
    ]));

    // dispatch этих операций происходит выше
    // удаление нужно, чтобы не было повторного dispatch
    // https://github.com/pdffiller/jsfiller/blob/develop/packages/ws-editor-lib/store/middleware/index.js#L298
    delete operationsByGroupObj[GROUPS.tools][TYPES.pages];
    delete operationsByGroupObj[GROUPS.document][TYPES.source];
  }

  return Object.keys(operationsByGroupObj).map((key) => {
    return {
      group: key,
      list: {
        ...operationsByGroupObj[key],
      },
    };
  });
};

const getDataFromEndPointFactory = (errorMessage) => {
  let counter = 0;
  return function sendRequest(endPoint, onSuccess) {
    request
      .get(endPoint)
      .end((err, res) => {
        if (!err && res.statusCode === 200) {
          // AUTH_RECEIVE can be triggered several times
          // so we need to restart this flow
          counter = 0;
          const result = JSON.parse(res.text);
          return onSuccess(result);
        }

        if (counter < 2) {
          counter += 1;
          return setTimeout(() => {
            sendRequest(endPoint, onSuccess);
          }, 250);
        }

        counter = 0;
        throw new JSFillerError(errorMessage, {
          err,
          res,
        });
      });
  };
};

const getValidatorsCollection =
  getDataFromEndPointFactory(ERROR_MESSAGES.validatorsCollectionRequestError);
const getEditorLocale =
  getDataFromEndPointFactory(ERROR_MESSAGES.editorLocaleFailRequestError);

export default (wsTransport) => {
  return (store) => {
    window.trackPoint = (pointName, options) => {
      store.dispatch(trackPoint(pointName, options));
    };

    let websocketClient;
    function applyWebSocketClient(ws) {
      websocketClient = ws;
      if (typeof window === 'object' && ws) {
        window.wsc = ws;
      }

      if (ws && !ws.store) {
        NetController(ws, store);
      }
    }

    if (
      wsTransport &&
      wsTransport.onWebSocketClientChanged &&
      wsTransport.getWebSocketClient
    ) {
      wsTransport.onWebSocketClientChanged((session) => {
        applyWebSocketClient(session.websocketClient);
      });
      applyWebSocketClient(wsTransport.getWebSocketClient());
    }

    const addValidatorsCollection = (validators) => {
      store.dispatch({
        type: AT.ADD_VALIDATORS_COLLECTION,
        validators,
      });
    };

    const mergeEditorLocales = (editorLocales) => {
      store.dispatch(mergeLocales(editorLocales));
    };

    return (next) => {
      const createMiddleware = () => {
        return (originalAction) => {
          const action = { ...originalAction };
          const state = store.getState();
          const { deviceProps } = store.getState();
          let projectId;
          let sessionHash;
          let { viewerId } = getUserAndAPIHash();

          switch (action.type) {
            case AT.AUTH_INIT: {
              let { apiHash } = getUserAndAPIHash();
              const urlParams = {
                ...(__CLIENT__ && { pathname: window.location.pathname }),
                ...getParametersAsObj(),
              };

              if (getParameterByName(QUERY_PARAMETERS.token)) {
                apiHash = getParameterByName(QUERY_PARAMETERS.token);
              }

              const location = {
                origin: window.location.origin,
                href: window.location.href,
              };

              websocketClient.setAuth(
                action.projectId, action.viewerId, action.sessionHash, apiHash,
                // eslint-disable-next-line no-underscore-dangle
                deviceProps, action.config, {}, urlParams, action._host, location,
                action.organization,
              );
              if (isEmbedded()) {
                websocketClient.connect(action.config);
              }

              store.dispatch(setApiHash(apiHash));

              break;
            }

            case AT.AUTH_RESTORE: {
              if (isEmbedded()) {
                websocketClient.send({
                  session: {
                    type: 'request',
                    uid: action.uid,
                  },
                });
              }
              break;
            }

            case AT.AUTH_RECEIVE: {
              const { staticUrl, endPoints } = action.auth.settings;
              const { viewer } = action.auth.project;
              const { initial } = action.auth;
              const wsEditorRestApi = new RestApi(WS_EDITOR_REST_API, {
                baseUrl: staticUrl,
              });

              const { editorLocale, validatorsCollection } = endPoints;

              if (editorLocale) {
                getEditorLocale(editorLocale, mergeEditorLocales);
              }

              if (validatorsCollection) {
                getValidatorsCollection(validatorsCollection, addValidatorsCollection);
              }

              // JSF-7453 Funnel timing: integration to JSfiller
              window.eventMetricsCollector &&
              window.eventMetricsCollector.onCollect('editor', 'onEvent');

              store.dispatch({
                type: AT.ON_OPERATIONS_USERS_LIST,
                operations: [{ properties: { list: [viewer] } }],
              });

              if (initial === true) {
                if (state.ws.confirmedOps !== null) {
                  // after reconnect [Kostya Zhdanov]
                  // if we not called resetElements, contents will be dublicated
                  store.dispatch(resetElements());
                  store.dispatch(resetComments());
                }
                // after reconnect initial = false(new flag isReconnect = true)
                websocketClient.setSession(action.auth.clientId, action.auth.project.modeId);
              }

              wsEditorRestApi.loadHelpStructure(
                function repeatRequest(err, result) {
                  if (err) {
                    if (!__DEVELOPMENT__) {
                      setTimeout(() => {
                        wsEditorRestApi.loadHelpStructure(repeatRequest, {
                          parameters: actionCreator.setHelpStructure,
                          url: endPoints.helpStructure,
                        });
                      }, TIME_FOR_REPEATED_REQUEST_CONSTANTS_HELP_STRUCTURE);
                    }
                    return false;
                  }

                  return store.dispatch(result);
                },
                {
                  parameters: actionCreator.setHelpStructure,
                  url: endPoints.helpStructure,
                },
              );

              break;
            }

            case AT.OPERATIONS_RECEIVE: {
              if (!state.ws.isAuthReceived) {
                // on TryNow auth comes after access operations
                const hasOperationWithAccessType = action.operations.some((operation) => {
                  return get(operation, 'properties.type') === TYPES.access;
                });
                if (!hasOperationWithAccessType) {
                  throw new JSFillerError('Operations came before auth', {
                    sessionHash: websocketClient.sessionHash,
                    projectId: websocketClient.projectId,
                    viewerId,
                  });
                }
              }

              if (
                websocketClient.operationsCounter !== state.ws.confirmedOps &&
                state.ws.confirmedOps === 0
              ) {
                // in case of RESET_ELEMENTS not need call
                // fillOperations because elementsMap is empty
                store.dispatch(resetElements());
              } else {
                action.operations = fillOperations(action.operations, state.ws.elementsMap);
              }

              // JSF-3585 send operations only with a group GROUPS.tools
              const filteredOperationsCounter = action.operations
                .filter((operation) => {
                  return operation.properties.group === GROUPS.tools;
                })
                .length;

              websocketClient.setOperationsCount(filteredOperationsCounter, state.ws.confirmedOps);

              // не забыть про список ошибок
              const operationsCounter = action.operations.length;
              const operationsByGroup = sortOperationsByGroup(action.operations);
              if (operationsCounter === 2) {
                const joinOperationGroup = operationsByGroup.map(
                  (o) => {
                    return o.group + Object.keys(o.list);
                  },
                )
                  .sort()
                  .join('_');
                if (joinOperationGroup === 'documentsource_toolspages') {
                  store.dispatch({ type: AT.APPLY_SUCCESS });
                  store.dispatch(setToolAndPages(operationsByGroup));
                  break;
                }
              }

              const processedOperations =
                processToolAndPagesOperationsIfNeed(operationsByGroup, store);
              processedOperations.forEach((group) => {
                Object.keys(group.list)
                  .forEach((type) => {
                    // rearrange applied
                    if (type === TYPES.pages) {
                      store.dispatch({ type: AT.APPLY_SUCCESS });
                    }

                    if (type === TYPES.resolution) {
                      const resolutionData = group.list[type];

                      if (resolutionData.length) {
                        const resolutionDataProperties = resolutionData[0].properties;

                        if (resolutionDataProperties) {
                          const { contentDPI, pdfDPI } = resolutionDataProperties;

                          store.dispatch(setPdfDocumentScale(contentDPI / pdfDPI));
                        }
                      }
                    }

                    const actionType =
                      `${AT.ON_OPERATIONS_PREFIX}${group.group.toUpperCase()}_${type.toUpperCase()}`;
                    const operations = group.list[type];

                    store.dispatch({
                      type: actionType,
                      operations,
                    });

                    if (
                      group.group === GROUPS.tools &&
                      type === TYPES.elements &&
                      group.list.elements.find((el) => {
                        return el.properties.subType === TOOL_TYPES.formula;
                      })
                    ) {
                      // add formula lazy load trigger here
                    }

                    if (
                      group.group === GROUPS.document &&
                      type === TYPES.access &&
                      operations[0].properties.subType === 'busy'
                    ) {
                      action.shouldUpdateCounterBusy = true;
                      handleBusyAccessStatus(websocketClient, state.ws.access.autoCheck);
                    }
                  });
              });

              break;
            }

            case AT.ON_OPERATIONS_EDITOR_LOCALE: {
              const localeData = get(action, 'operations[0].properties.data', false);
              if (localeData) {
                store.dispatch(mergeLocales(localeData));
              }

              break;
            }

            case AT.UPDATE_ELEMENT: {
              // replace owner for images && signatures in order for urls to work
              const { content, id } = action;

              const element = getElementById(state.ws.elementsMap, id);

              if (element && element.content) {
                const isTypeImage = element.content.type === TOOL_TYPES.image || (
                  element.content.type === TOOL_TYPES.signature &&
                  content.subType === TOOL_SUB_TYPES.signature.image
                );
                const isNewImage = content.url && content.url !== element.content.url;

                if (isNewImage && isTypeImage) {
                  content.owner = state.ws.viewerId;
                }

                if (
                  isElementCheckmark(element) && isFillableElement(element) &&
                  !isEmptyFillableElement(element)
                ) {
                  element.content.checked = !!element.content.checked;
                }
              }

              break;
            }

            case AT.UPDATE_FILLABLE_ELEMENT: {
              const element = getElementById(state.ws.elementsMap, action.id);
              if (
                element &&
                element.template &&
                element.type === TOOL_TYPES.checkmark &&
                action.subType &&
                action.subType !== state.ws.defaultFCCheckmarkSubtype
              ) {
                store.dispatch(setDefaultCheckmarkSubtype(action.subType));
              }
              break;
            }

            case AT.REMOVE_ELEMENT: {
              // in case of Preview Mode we aren't send date to ws, and shoud
              // remove content, for this added 'needEraseContent' key before reducer
              if (get(state, 'fConstructor.isFConstructorPreviewShown', false)) {
                action.opts = { needEraseContent: true };
              }
              break;
            }

            case AT.DESTROY: {
              // clear operations stack
              websocketClient.optimizeAndSend.flush();

              const destroyData = getUserAndAPIHash();
              const destroyMessage = MessageCondition.destroy(state.locale.editor);

              websocketClient.send({
                destroy: true, apiHash: destroyData.apiHash, params: action.params,
              });
              if (action.params.isNeedToWSDisconnect) {
                websocketClient.disconnect();
              }

              // is using for the integrations (JSF-3148, JSF-3601)
              let postData;
              if (action.doneProgress && action.params.destroyType !== ERROR_DESTROY_TYPE) {
                postData = POST_MESSAGES.editorDone;
              }
              if (action.params.destroyType === ERROR_DESTROY_TYPE) {
                postData = action.params.error;
              }
              sendPostMessage(postData);

              store.dispatch(setMessage(destroyMessage));
              break;
            }

            case AT.DESTROY_RECEIVE: {
              // is using for the airSlate (JSF-4393)
              if (action.force || action.params.force) {
                sendPostMessage(POST_MESSAGES.unexpectedDestroy);
              }

              const { system } = store.getState().ws;
              const postData = { action: 'destroy', location: system.destroyUrl };
              sendPostMessage(postData);

              break;
            }

            case AT.GET_ACCESS:

              websocketClient.stopGetAccessTimer();
              websocketClient.sendGetAccess();
              break;

            case AT.GALLERY_IMAGES_LIST:
              sendImageList(websocketClient);
              break;

            case AT.GALLERY_SIGNATURES_LIST: {
              const EXP_NAME = 'mydocs_notarize';
              const getExperimentalFeature = !state.ws.experimentalFeatures.find((feature) => {
                return feature === EXP_NAME;
              });

              if (getExperimentalFeature) {
                const wsEditorRestApi = new RestApi(WS_EDITOR_REST_API);
                const { baseAPI } = state.ws.endPoints;
                const url = `${baseAPI}/my_forms/runUserExperiment`;
                const { apiHash: token } = getUserAndAPIHash();
                const APP_KEY = 'x65dls049KserPfvs2';
                const headers = {
                  appKey: APP_KEY,
                  userId: state.ws.viewerId,
                  token,
                };
                const body = { expName: EXP_NAME };

                wsEditorRestApi
                  .postFormRequest(url, headers, body)
                  .then(
                    (data) => {
                      const isInExperiment = data && data.body.version === 2;
                      if (isInExperiment) {
                        store.dispatch(setExperimentalFeatures([EXP_NAME]));
                      }
                      sendSignaturesList(websocketClient);
                    },
                  )
                  .catch(() => {
                    sendSignaturesList(websocketClient);
                  });
              } else {
                sendSignaturesList(websocketClient);
              }

              break;
            }

            case AT.DELETE_GALLERY_IMAGE:
              websocketClient.send({
                operations: [{
                  properties: {
                    group: GROUPS.images,
                    type: TYPES.delete,
                    id: action.image.id,
                  },
                }],
              });
              break;

            case AT.ADD_GALLERY_IMAGE:
              websocketClient.send({
                operations: [{
                  properties: {
                    group: GROUPS.images,
                    type: TYPES.add,
                    sid: action.image.sid,
                    width: action.image.width,
                    height: action.image.height,
                  },
                }],
              });
              break;

            case AT.UPDATE_GALLERY_IMAGE:
              websocketClient.send({
                operations: [{
                  properties: {
                    group: GROUPS.images,
                    type: TYPES.update,
                    sid: action.image.sid,
                    id: action.image.id,
                    width: action.image.width,
                    height: action.image.height,
                  },
                }],
              });
              break;

            case AT.ADD_GALLERY_SIGNATURE:
            case AT.UPDATE_GALLERY_SIGNATURE: {
              if (action.signature.subType === TOOL_SUB_TYPES.signature.curve) {
                store.dispatch({
                  type: AT.SET_SIGNATURE_DEFAULTS,
                  signature: action.signature,
                });
              }
              if (!action.isSingleUse) {
                const op = {
                  properties: {
                    ...action.signature,
                    group: GROUPS.signatures,
                    type: action.type === AT.ADD_GALLERY_SIGNATURE
                      ? TYPES.add
                      : TYPES.update,
                    ...action.signature.subType === TOOL_SUB_TYPES.signature.text &&
                    { fontSize: action.signature.fontSize || 52 },
                    ...action.signature.subType === TOOL_SUB_TYPES.signature.image &&
                    { name: 'added by WS' },
                  },
                };

                websocketClient.send({ operations: [op] });
              }
              break;
            }

            case AT.DELETE_GALLERY_SIGNATURE:
              websocketClient.send({
                operations: [{
                  properties: {
                    group: GROUPS.signatures,
                    type: TYPES.delete,
                    id: action.signature.id,
                  },
                }],
              });
              break;

            case AT.GET_QR_URL:
              request
                .get(`https://devapp.pdffiller.com/flash/data/signature3.php?op=requestUrl&user_id=${websocketClient.viewerId}&contenttype=json`)
                .end((err, res) => {
                  if (!err && res.statusCode === 200) {
                    const data = JSON.parse(res.text);

                    if (data.result && data.url) {
                      store.dispatch({
                        type: AT.SET_QR_URL,
                        url: data.url,
                      });
                    }
                  }
                });
              break;

            case AT.SEND_PHONE_NUMBER:
              request
                .get(`https://devapp.pdffiller.com/flash/data/signature3.php?op=requestBySms&user_id=${websocketClient.viewerId}&phone=${action.number}&contenttype=json`)
                .end();

              break;

            case AT.SEND_EMAIL:
              request
                .get(`https://devapp.pdffiller.com/flash/data/signature3.php?op=requestByEmail&user_id=${websocketClient.viewerId}&email=${action.email}&contenttype=json`)
                .end();

              break;

            case AT.ON_OPERATIONS_DOCUMENT_ACCESS: {
              const operation = action.operations[0];
              if (operation.properties.subType === DENIED) {
                store.dispatch(destroy(operation.properties.location));
              }
              break;
            }

            case AT.TRACK_POINT:

              if (action.pointName === 'SESSION_INIT') {
                if (getParameterByName(QUERY_PARAMETERS.viewerId)) {
                  viewerId = getParameterByName(QUERY_PARAMETERS.viewerId);
                }

                const clientData = {
                  ...deviceProps,
                  sessionHash: action.options.sessionHash,
                  projectId: action.options.projectId,
                  viewerId,
                  screen: getScreenResolution(),
                };

                // JSF-8184 Cannot read property 'setDomain' of undefined
                // эта проблема появилась у 2 пользователей
                // Такое возможно если сессия не создана (уже уничтожена) на AUTH ответ от сервера
                if (!websocketClient) {
                  throw new JSFillerError('trying apply undefine as websocket', {
                    websocketClient,
                    sessionHash: action.options.sessionHash,
                    projectId: action.options.projectId,
                    viewerId,
                  });
                }

                websocketClient.setDomain(action.options.domain);
                if (window.scriptOnBeforeUnload) {
                  window.removeEventListener('beforeunload', window.scriptOnBeforeUnload);
                }
                request
                  .post(`${websocketClient.getDomain()}/session/init`)
                  .send(clientData)
                  .end();
              } else {
                const { options, pointName } = action;
                const pointInfo = {
                  ...options,
                };

                if (pointName === 'SCRIPT_EXCEPTION') {
                  if (
                    !isObjectSizeValid(pointInfo) &&
                    pointInfo.frames &&
                    pointInfo.frames.length
                  ) {
                    pointInfo.frames = pointInfo.frames.splice(0, 3);
                  }

                  if (isMessageHasPdfUrl(options.message)) {
                    const [pdfUrl] = options.message.match(pdfUrlRegExp);
                    const validMessage = options.message.replace(pdfUrlRegExp, '').trim();
                    pointInfo.message = validMessage;
                    pointInfo.pdfUrl = pdfUrl;
                  }
                }

                const point = {
                  group: GROUPS.editor,
                  type: TYPES.track,
                  subType: 'point',
                  point: pointName,
                  timestamp: getTimeStampNow(),
                  pointInfo,
                };
                if (
                  state.ws.connection.status ===
                  ConnectionConditions.CONDITION_CONNECTION_CONNECT_STATUS &&
                  pointName !== 'EDITOR_WARNING' &&
                  pointName !== 'EDITOR_ERROR' &&
                  pointName !== 'SCRIPT_EXCEPTION'
                ) {
                  websocketClient.sendTrackPoint({
                    operations: [{ properties: point }],
                  });
                } else {
                  request
                    .post(`${websocketClient.getDomain()}/trackpoint`)
                    .send({
                      ...point,
                      sessionHash: options.sessionHash || websocketClient.sessionHash,
                      projectId: options.projectId || websocketClient.projectId,
                      viewerId,
                    })
                    .end();
                }
              }
              break;

            case AT.ERROR_POINT: {
              const clientData = {
                ...deviceProps,
                sessionHash: action.options.sessionHash,
                error: action.options.error,
                action: action.options.action,
                projectId: websocketClient.projectId,
                viewerId: websocketClient.viewerId,
              };

              request.post(`${websocketClient.getDomain()}/session/error/point`)
                .send(clientData)
                .end();

              break;
            }

            case AT.SERVER_ERROR: {
              const { system, connection: { status } = {} } = store.getState().ws;

              if (system) {
                switch (system.status) {
                  case SystemConditions.CONDITION_SYSTEM_SEND_STATUS:
                    leaveAndSave(websocketClient, action, store);
                    break;
                  case SystemConditions.CONDITION_SYSTEM_OPERATIONS_RECEIVE_STATUS:
                    leaveAndSave(websocketClient, action, store);
                    break;
                  default:
                    if (
                      action.err &&
                      status !== ConnectionConditions.CONDITION_CONNECTION_DISCONNECT_STATUS
                    ) {
                      store.dispatch(addError(action.err));
                    }
                    websocketClient.connectionLost();
                }
              }

              break;
            }

            case AT.EDITOR_FEEDBACK: {
              const editorFeedbackAPI = new RestApi(WS_EDITOR_REST_API, {
                baseUrl: 'http://dev3.pdffiller.com',
              });
              // eslint-disable-next-line prefer-destructuring
              sessionHash = websocketClient.sessionHash;
              // eslint-disable-next-line prefer-destructuring
              projectId = websocketClient.projectId;

              editorFeedbackAPI.feedback(sessionHash, projectId, action.message, 0);

              break;
            }

            case AT.PROBLEM_REPORT: {
              const { history } = action.payload;
              const { problemState } = action.payload;
              const { version } = action.payload;

              let { apiHash: token } = getUserAndAPIHash();

              if (getParameterByName(QUERY_PARAMETERS.token)) {
                token = getParameterByName(QUERY_PARAMETERS.token);
              }

              const problemReportApi = new RestApi(WS_EDITOR_REST_API, {
                appKey: 'web_fds3w43342Sbs',
                userId: state.ws.viewerId,
                baseUrl: '',
                token,
              });
              const reportProblemModalId = 'ReportModal';
              const {
                modalTitle: title,
                modalContent: content,
                modalOkButton: btnConfirmText,
              } = state.locale.editor.reportProblem;

              const texts = {
                title,
                content,
                btnConfirmText,
              };

              store.dispatch(actionCreator.setInterfaceLock());
              store.dispatch(actionCreator.openModal(reportProblemModalId, {
                ...texts,
                modalType: 'export-error',
                showCloseButton: true,
                btnCancelCallback: () => {
                  return store.dispatch(actionCreator.setInterfaceUnlock());
                },
                btnConfirmCallback: (message) => {
                  if (message && message.length) {
                    const { referrer } = websocketClient;
                    const { ws } = state;
                    const { os, browser, type, browserName } = deviceProps;
                    const { modeId = '' } = ws;
                    const userId = ws.viewerId;

                    // eslint-disable-next-line prefer-destructuring
                    projectId = ws.projectId;
                    // eslint-disable-next-line prefer-destructuring
                    sessionHash = ws.sessionHash;
                    const options = {
                      os,
                      browser,
                      userId,
                      viewerId: userId,
                      projectId,
                      message,
                      referrer,
                      version,
                      state: JSON.stringify(problemState),
                      actions: JSON.stringify(history),
                      device: type,
                      subject: `JSFiller Problem - ${modeId}, ${projectId}, ${sessionHash}`,
                      scale: getScale(browserName),
                    };

                    problemReportApi.reportProblem(null, options);
                  }

                  store.dispatch(actionCreator.closeModal(reportProblemModalId));
                  store.dispatch(actionCreator.setInterfaceUnlock());
                },
              }));

              break;
            }

            case AT.OPEN_MODAL:
              if (action.modalId === 'FeedbackModal') {
                const activeElementId = get(store.getState(), 'ws.activeElement', false);
                if (activeElementId) {
                  store.dispatch(setActiveElement(activeElementId, false));
                }
              }

              if (isOpenPageRearrangeModal(action)) {
                store.dispatch(activateMode(modes.pages));
              }

              break;

            case AT.CLOSE_MODAL:
              if (isOpenPageRearrangeModal(action)) {
                store.dispatch(activateMode(modes.main));
              }

              break;

            case AT.LOADING_PAGE: {
              const message = MessageCondition.loadingPage(
                state.locale.editor,
                state.ws.modeId, action,
              );

              store.dispatch(setMessage(message));

              break;
            }

            case AT.CHECK_EXTERNAL_VALIDATION: {
              websocketClient.optimizeAndSend.flush();

              websocketClient.send({
                operations: [{
                  properties: {
                    group: GROUPS.validators,
                    type: TYPES.external,
                    subType: action.validationMode,
                  },
                }],
              });

              break;
            }

            case AT.CHANGE_MESSAGE: {
              const { isPAConstructorShown, isFConstructorShown } = state.viewport;
              const isAnyConstructorShown = isPAConstructorShown || isFConstructorShown;
              if (
                action.messageType === messageTypes.INTRO &&
                isAnyConstructorShown
              ) {
                store.dispatch(setMessage(''));
              } else {
                const { editor } = state.locale;
                const { modeId } = state.ws;
                const newMessage = MessageCondition.change(editor, modeId, action);
                store.dispatch(setMessage(newMessage));
              }
              break;
            }

            case AT.SERVER_DISCONNECT:
              break;

            case AT.FLUSH_OPERATIONS_QUEUE: {
              websocketClient.optimizeAndSend.flush();
              break;
            }

            default:
              return next(action);
          }

          return next(action);
        };
      };

      const middleware = createMiddleware();
      return (originalAction) => {
        return middleware(originalAction);
      };
    };
  };
};
