import { createSelector } from 'reselect';
import getElementPropsForToolbar from './getElementPropsForToolbar';
import { getToolbarRenderingProps } from '../../../helpers/elemTypes';

export const getHasVAlignValue = ({
  hasVAlign,
  isFillable,
  isSticky,
  isTextBox,
}) => {
  return hasVAlign && (isFillable || isSticky || isTextBox);
};

const getToolbarAttributesVisilibity = createSelector([
  getElementPropsForToolbar,
], (elementProps) => {
  const { type, isSticky, isTextBox, isFillable } = elementProps;
  const attributesVisibility = getToolbarRenderingProps(type);

  return {
    ...attributesVisibility,
    hasVAlign: getHasVAlignValue({
      hasVAlign: attributesVisibility.hasVAlign,
      isSticky,
      isTextBox,
      isFillable,
    }),
  };
});

export default getToolbarAttributesVisilibity;
