import { createSelector } from 'reselect';
import get from 'lodash/get';
import pickBy from 'lodash/pickBy';
import find from 'lodash/find';
import findIndex from 'lodash/findIndex';

import * as lazySelectors from '@pdffiller/jsf-lazyload/store/selectors';
import { miscIds } from '../../helpers/const';
import { elemTypes, fakeEditTypes, elemSubTypes } from '../../helpers/elemTypes';
import * as base from './baseSelectors';

/**
 * FakeEdit
 */
export const getIsFakeEditAvailable = createSelector(
  base.getFeaturesMisc,
  (misc) => {
    const fakeEditFeatureIndex = findIndex(misc, (feature) => {
      return feature.id === miscIds.fakePdfEdit.id;
    });
    if (fakeEditFeatureIndex === -1) {
      return false;
    }
    return misc[fakeEditFeatureIndex].visible === true;
  },
);

export const getIsInitialFakeEditActive = (misc) => {
  const fakeEditFeatureIndex = findIndex(misc, (feature) => {
    return feature.id === miscIds.fakePdfEdit.id;
  });
  if (fakeEditFeatureIndex === -1) {
    return false;
  }
  return misc[fakeEditFeatureIndex].visible === true;
};

export const getIsFakeEditActive = (state) => {
  const activeType = get(base.getActiveTool(state), 'type');
  return (
    activeType === elemTypes.text &&
    getIsFakeEditAvailable(state) &&
    base.getIsFakeEditActive(state) &&
    !base.getIsFConstructorShown(state) &&
    !base.getIsPAConstructorShown(state)
  );
};

const getHasOverlyingTextElements = createSelector(
  [base.getElements],
  (elements) => {
    return find(elements, (element) => {
      return (
        element.type === elemTypes.text &&
        element.subType === elemSubTypes.text.overlying
      );
    }) !== undefined;
  },
);

export const getNeedLoadFakeEdit = (state) => {
  if (lazySelectors.jsf.getIsFakeEditLoaded(state)) {
    return false;
  }

  return (
    getIsFakeEditAvailable(state) ||
    getHasOverlyingTextElements(state)
  );
};

export const getFakeEditDefaults = createSelector(
  [base.getDefaultContents],
  (defaultContents) => {
    return defaultContents
      .map((content) => {
        return { ...content, type: content.id.split('.')[1] };
      })
      .filter((content) => {
        return fakeEditTypes.indexOf(content.type) > -1;
      })
      .reduce((result, content) => {
        return {
          ...result,
          [content.type]: pickBy(content, (__, key) => {
            return key !== 'id' && key !== 'type';
          }),
        };
      }, {});
  },
);
