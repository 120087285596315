import filter from 'lodash/filter';
import findKey from 'lodash/findKey';
import get from 'lodash/get';
import {
  isFilledElement as isFilled,
  isDropdownElement,
  isCheckmarkElement,
  isVisibleElement,
  isRadioElement,
} from '../helpers/functions';

export const orFunc = (arr) => {
  let result = false;
  for (let i = 0; i < arr.length; i++) {
    result = result || arr[i];
  }
  return result;
};

export const andFunc = (arr) => {
  let result = true;
  for (let i = 0; i < arr.length; i++) {
    result = result && arr[i];
  }
  return result;
};

export const notAndFunc = (arr) => {
  let result = true;
  for (let i = 0; i < arr.length; i++) {
    result = result && !arr[i];
  }
  return result;
};

export const notOrFunc = (arr) => {
  let result = false;
  for (let i = 0; i < arr.length; i++) {
    result = result || !arr[i];
  }
  return result;
};

const operatorsFunctionsMap = {
  or: orFunc,
  and: andFunc,
  'not and': notAndFunc,
  'not or': notOrFunc,
};

const operatorToValueMap = {
  or: {
    operator: 'or',
    negated: false,
  },
  and: {
    operator: 'and',
    negated: false,
  },
  'not and': {
    operator: 'and',
    negated: true,
  },
  'not or': {
    operator: 'or',
    negated: true,
  },
};

const isFilledElement = (el) => {
  return isFilled(el) && isVisibleElement(el);
};

const isUnfilledElement = (el) => {
  return !isFilled(el);
};

const isCheckedRadio = (el) => {
  return isFilledElement(el) && el.content.checked === true;
};

const isUncheckedRadio = (el) => {
  return isFilledElement(el) && el.content.checked !== true;
};

const isCheckedCheckmark = (el) => {
  return isFilledElement(el) && el.content.checked === true;
};

// Матемачиски нейтральный элемент к выражению -
// элемент которое не изменяет значения выражения
// 5 + 15 + 0 = 20 -> 0 - нейтральный элеменет к сложению (в простом случае)
// 20 * 1 = 20 -> 1 - нейтральный элеменет к произведению (в простом случае)

// Функция которая возвращает математически
// нейтральный элемент к логической функции.
// То есть, от операции с которым,
// логическая функция не поменяет своего значения.
// Всей кейсы были проверены по аналогии с SN

// Для логической операции "or"  этот элемент - 0
// Для логической операции "and"  этот элемент - 1
// Для логической операции "not and"  этот элемент - 0
// Для логической операции "not or"  этот элемент - 1
export const getLogicFunIdentityValue = (operatorName) => {
  if (
    operatorName === 'or' ||
    operatorName === 'not and'
  ) {
    return false;
  }

  // and, not or, default
  return true;
};


const getIsEnabledByDependencies = ({
  dependency,
  elementsMap,
  parentCallOperator,
  activeElementId,
}) => {
  if (dependency.operator) {
    const res = dependency.clauses.map((subDependency) => {
      // Это вернет название фунции, чтоб проще было с ней работать
      // и соотнести с мат. нейтрайльным элементом к выражению
      const operatorsFunctionsMapKey = findKey(operatorToValueMap, {
        operator: dependency.operator,
        negated: dependency.negated,
      });

      // Запомним с каким оператором был вызвана текущая функция,
      // чтобы при случае с template.name === "" мы вернули мат.
      // нейтральный элемент, чтоб значение выражения не изменилось
      return getIsEnabledByDependencies({
        dependency: subDependency,
        elementsMap,
        parentCallOperator: operatorsFunctionsMapKey,
        activeElementId,
      });
    });
    const logicFunc = operatorsFunctionsMap[
      findKey(operatorToValueMap, {
        operator: dependency.operator,
        negated: dependency.negated,
      })
    ];
    return logicFunc(res);
  }

  const dependentElements = filter(elementsMap, (el) => {
    return (
      el.template &&
      el.template.name === dependency.name
    );
  });

  // Если сразу отправили/получили template.name === "", вернем true как дефолт.
  // Если у нас есть аргумент parentCallOperator - значит функция была вызвана рекурсивно
  // и нужно вернуть мат. нейтральное значение
  if (dependentElements.length === 0) {
    // Нет проверки на parentCallOperator потому что, если будет undefined, то
    // getLogicFunIdentityValue вернет true по дефолту
    return getLogicFunIdentityValue(parentCallOperator);
  }

  if (dependentElements.length > 1 && isRadioElement(dependentElements[0])) {
    if (dependency.value === true) {
      if (dependency.negated === false) {
        if (dependentElements.some(isCheckedRadio)) {
          return true;
        }
        return false;
      }

      if (dependentElements.some(isCheckedRadio)) {
        return false;
      }
      return true;
    }

    const depElementsByValue = filter(dependentElements, (el) => {
      return el.template.radioValue === dependency.value;
    });

    // check all sub dependency
    const elementsWithSubDependencies = depElementsByValue.filter((depElement) => {
      return get(depElement, 'template.dependency', false);
    });
    if (elementsWithSubDependencies.length) {
      const isEnabledByDependencies = elementsWithSubDependencies.some((depElement) => {
        return getIsEnabledByDependencies({
          dependency: depElement.template.dependency,
          elementsMap,
        });
      });

      // all dependent elements are disabled by sub dependencies
      if (!isEnabledByDependencies) {
        return false;
      }
    }

    // if one of dependent element is unfilled then treat all as unfilled
    if (depElementsByValue.some(isUnfilledElement)) {
      return dependency.negated;
    }

    const isAllDepElementUnchecked = depElementsByValue.every(isUncheckedRadio);
    return dependency.negated === isAllDepElementUnchecked;
  }

  // get active dependent element or first dependent element
  const activeDependentElement = dependentElements.find((element) => {
    return element.id === activeElementId;
  });

  const dependentElement = activeDependentElement || dependentElements[0];

  if (isDropdownElement(dependentElement)) {
    const isVisible = isVisibleElement(dependentElement);
    const dropDownText = get(dependentElement, 'content.text', null);
    // case when selected option 'Any Value'
    const isAnyValueOptionSelectedAndElemHasText =
      dependency.value === true && dropDownText && isVisible;

    if (dependency.negated === false) {
      if (isAnyValueOptionSelectedAndElemHasText) {
        return true;
      }
      return dependency.value === dropDownText && isVisible;
    }

    if (dependency.negated === true) {
      if (isAnyValueOptionSelectedAndElemHasText) {
        return false;
      }
      return dependency.value !== dropDownText && isVisible;
    }
  }

  if (isCheckmarkElement(dependentElement)) {
    return dependency.negated !== isCheckedCheckmark(dependentElement);
  }

  return dependency.negated !== isFilledElement(dependentElement);
};

export default getIsEnabledByDependencies;
