export const SET_PREVIEW = 'query/SET_PREVIEW';

const initialState = {
  editType: 'unknown',
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case SET_PREVIEW: {
      return {
        ...state,
        previewUrl: action.url,
      };
    }

    default:
      return state;
  }
}

export function setPreview(url) {
  return { type: SET_PREVIEW, url };
}
