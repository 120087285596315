import doUsers from './Users';
import doRoles from './Roles';
import doAutofill from './Autofill';
import * as Versions from './Versions';

import * as Document from './Document';
import * as Editor from './Editor';
import * as Tools from './Tools';
import * as Gallery from './Gallery';
import * as Attachments from './Attachments';
import * as Comments from './Comments';
import * as Routing from './Routing';
import { TYPES, TOOL_TYPES } from '../../../constants';

export {
  Document,
  Editor,
  Tools,
  Gallery,
  Attachments,
  Comments,
  Routing,
  Versions,
};

export const Autofill = { doAutofill };
export const Users = { doUsers };
export const Roles = { doRoles };

const {
  text, checkmark, signature, erase, image, highlight, blackout,
  textbox, arrow, line, pen, sticky, radio,
} = TOOL_TYPES;

const ELEMENTS_TYPE_LIST = [
  text, checkmark, signature, erase, image, highlight,
  blackout, textbox, arrow, line, pen, sticky, radio,
];

export default function sortOperationsByGroup(operations) {
  let operationsByGroup = {};
  operations.forEach((operation) => {
    const { group } = operation.properties;
    let { type } = operation.properties;

    if (!operationsByGroup.hasOwnProperty(group)) {
      operationsByGroup[group] = {};
    }

    if (ELEMENTS_TYPE_LIST.indexOf(type) >= 0) {
      type = TYPES.elements;
    }

    if (!operationsByGroup[group].hasOwnProperty(type)) {
      operationsByGroup[group][type] = [];
    }

    operationsByGroup[group][type].push(operation);
  });

  operationsByGroup = Object.keys(operationsByGroup).map((group) => {
    return {
      group,
      list: operationsByGroup[group],
    };
  });

  operationsByGroup.sort((a, b) => {
    if (a.group < b.group) {
      return 1;
    }

    if (a.group > b.group) {
      return -1;
    }

    return 0;
  });

  return operationsByGroup;
}
