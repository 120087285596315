const attributes = {
  editing: { 'data-editing': true },
  grow: { 'data-flex-grow': 1 },
  disableGrow: { 'data-flex-grow': 0 },
  shrink: { 'data-flex-shrink': 1 },
  shrinkOff: { 'data-flex-shrink': 0 },
  overflowHidden: { 'data-overflow-hidden': true },
  basisOff: { 'data-flex-basis': 0 },
  textOverflow: { 'data-text-overflow': true },
  flexDirectionColumn: { 'data-flex-direction': 'column' },
  flexDirectionRow: { 'data-flex-direction': 'row' },
  justifyContentCenter: { 'data-justify-content': 'center' },
  positionTop: { 'data-position': 'top' },
  positionBottom: { 'data-position': 'bottom' },
  redesignTrue: { 'data-redesign': true },
  messageTypeError: { 'data-message-type': 'error' },
  dataHighlight: { 'data-highlight': true },
  dataStretched: { 'data-stretched': true },
  ariaLive: { 'aria-live': 'polite' },
  ariaRelevant: { 'aria-relevant': 'text' },
  ariaLabel: { 'aria-label': 'result number' },
};

export default attributes;
