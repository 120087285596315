import testConfig from './testConfig.json';

const clientConfig = (() => {
  if (__TEST__ || __INTEGRATION_TEST__) {
    return testConfig;
  }

  if (__CLIENT__) {
    // eslint-disable-next-line no-underscore-dangle
    return window.__config;
  }

  if (!__CLIENT__) {
    // eslint-disable-next-line global-require
    return require('./serverConfig').default;
  }

  return undefined;
})();

export default clientConfig;
