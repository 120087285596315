import React from 'react';
import PropTypes from 'prop-types';

const RadioGroupItem = ({ children, attributes }) => {
  return (
    <li className="radio-group__item" {...attributes}>
      {children}
    </li>
  );
};

RadioGroupItem.propTypes = {
  children: PropTypes.node.isRequired,
  attributes: PropTypes.shape({}),
};

RadioGroupItem.defaultProps = {
  attributes: {},
};

export default RadioGroupItem;
