import { setActiveElement } from 'ws-editor-lib/actions';
import * as combineModalsActions from 'combine-modals/lib/actions/actionCreator';
import { setActivePage } from '../modules/navigation';
import { LockModal } from '../../helpers/const';
import { SKIPPED } from '../../helpers/const/interface';

export const onOkHandler = (
  dispatch,
  { activePageId, id, pageId },
  callback,
) => {
  if (callback) {
    callback(SKIPPED);
  }
  dispatch(setActiveElement(id, true));
  if (activePageId !== pageId) {
    dispatch(setActivePage(pageId));
  }
};

const showDropdownIsEmptyModal = (
  { type, subType, id, pageId },
  callback,
) => {
  return (dispatch, getState) => {
    const state = getState();
    const { activePageId } = state.navigation;
    const onOkHandlerParams = { activePageId, id, pageId };
    dispatch(combineModalsActions.openModal(LockModal, {
      type: `${type}_${subType}`,
      handlers: {
        onOk: () => {
          onOkHandler(dispatch, onOkHandlerParams, callback);
        },
      },
    }));
  };
};

export default showDropdownIsEmptyModal;
