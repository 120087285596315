const tool = 'Tool';

export default {
  titles: {
    link: 'Attach a link',
    vAlign: 'Select vertical text alignment',
    hAlign: 'Select horizontal text alignment',
    fontColor: 'Select text color',
    bgColor: 'Select paint color',
    borderColor: 'Select border color',
    color: 'Select color',
    letterUnderline: 'Make text underlined',
    letterItalic: 'Make text italic',
    letterBold: 'Make text bold',
    arrowUpright: 'Top-right arrow tool',
    arrowDownleft: 'Bottom-left arrow tool',
    arrowUprightDownleft: 'Bottom-left to top-right arrow tool',
    text: `Text ${tool}`,
    cross: `Cross ${tool}`,
    check: `Check ${tool}`,
    circle: `Circle ${tool}`,
    sign: `Sign ${tool}`,
    date: `Date ${tool}`,
    picture: `Picture ${tool}`,
    erase: `Erase ${tool}`,
    highlight: `Highlight ${tool}`,
    blackout: `Blackout ${tool}`,
    textbox: `Textbox ${tool}`,
    line: `Line ${tool}`,
    draw: `Draw ${tool}`,
    sticky: `Sticky ${tool}`,
    'arrow-upright': `Arrow ${tool}`,
    help: 'Open help dialog',
    feedback: 'Open feedback dialog',
    more: 'Show more',
    prev: 'Previous item',
    next: 'Next item',
    search: 'Toggle Search Mode',
    comments: 'Toggle Comments',
    stamp: 'Create a stamp',
    zoom: 'Select document width',
    fakeEdit: 'Alter original',
    textField: 'Attention! <br>The length of your text exceeds the field size and will be cut off after export. <br>Shorten your text or adjust the field size.',
    radioVAlign: 'Vertical alignment',
    radioHAlign: 'Horizontal alignment',
  },
};
