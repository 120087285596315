import React from 'react';
import PropTypes from 'prop-types';

const FlexibleBarControl = ({ children }) => {
  return (
    <div className="flexible-bar__control">
      {children}
    </div>
  );
};

FlexibleBarControl.propTypes = {
  children: PropTypes.node,
};

FlexibleBarControl.defaultProps = {
  children: null,
};

export default FlexibleBarControl;
