import * as selectors from '../selectors';
import { setForceFocusElement } from '../modules/events';

function forceFocusTextElement() {
  return (dispatch, getState) => {
    const activeElementId = selectors.base.getActiveElementId(getState());
    dispatch(setForceFocusElement(activeElementId));
  };
}

export default forceFocusTextElement;
