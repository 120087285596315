import React from 'react';
import PropTypes from 'prop-types';

const FillableWizardInfo = ({ children }) => {
  return (
    <div className="fillable-wizard__info">
      <div className="fillable-wizard__info-text">
        {children}
      </div>
    </div>
  );
};

FillableWizardInfo.propTypes = {
  children: PropTypes.element.isRequired,
};

export default FillableWizardInfo;
