import queryString from 'query-string';
import uuid from 'uuid';
import { getDeviceProps } from '@pdffiller/jsf-useragent';

const DEFAULT_ID = -1;
const API_HASH = 'api_js';
const API_AIRSLATE_HASH = 'airSlate.session.token';
export const PREAUTH_HASH = 'jsfiller_preauth_hash';
const PREAUTH_HASH_COOKIE = 'jsfiller_preauth_hash';
const IPREGEXP = '^(([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5]).){3}([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])$';

const getCookieValue = (name, cookie = document.cookie) => {
  const value = `; ${cookie}`;
  const parts = value.split(`; ${name}=`);

  if (parts.length === 2) {
    return parts.pop().split(';').shift();
  }

  return null;
};

const deleteCookie = (name) => {
  const { host } = window.location;
  document.cookie = `${name}= ; path = /; domain = .${host}; expires = Thu, 01 Jan 1970 00:00:00 GMT;`;
};

const getParameterByName = (name, url = window.location.href) => {
  const newName = name.replace(/[[\]]/g, '\\$&');
  const regex = new RegExp(`[?&]${newName}(=([^&#]*)|&|#|$)`);
  const results = regex.exec(url);
  if (!results) {
    return null;
  }

  if (!results[2]) {
    return '';
  }
  return decodeURIComponent(results[2].replace(/\+/g, ' '));
};

const getPreauthHashFromQSOrCookie = () => {
  if (!__CLIENT__) {
    return null;
  }

  const preauthHashFromQueryParam = getParameterByName(PREAUTH_HASH);
  if (preauthHashFromQueryParam) {
    const newLocation = window.location.href.replace(`&${PREAUTH_HASH}=${preauthHashFromQueryParam}`, '');
    window.history.replaceState({}, null, newLocation);
  }

  const preauthHashCookie = getCookieValue(PREAUTH_HASH_COOKIE);
  if (preauthHashCookie) {
    deleteCookie(PREAUTH_HASH_COOKIE);
  }

  return preauthHashCookie || preauthHashFromQueryParam;
};

const preauthHash = getPreauthHashFromQSOrCookie();

const getPreauthHash = () => {
  return preauthHash;
};

export const getQSParams = () => {
  if (__CLIENT__) {
    const qs = queryString.parse(window.location.search);
    return {
      embedded: qs.embedded !== undefined,
      isOfflineMode: qs.isOfflineMode !== undefined,
    };
  }
  return {};
};

// не добавлял проверку на __SNF__, так как там отсутствует кука jsfiller_preauth_hash
let instanceId = 0;

export const getSessionInstanceId = () => {
  return instanceId;
};

const {
  embedded,
} = getQSParams();

export const newSessionInstanceId = (sessionId) => {
  instanceId = sessionId || getPreauthHash() || uuid.v4().replace(/-/g, '');
  // при изменении hash iframe при создании сессии (до ws коннекта)в AS клонируется dashboard
  // при измении hash после коннекта (например при получении auth) все нормально
  // нужен ресерч нужно ли в AS менять hash и если да, то почему такое поведение
  if (__CLIENT__ && !embedded) {
    window.location.replace(`#${instanceId}`);
  }
  return instanceId;
};

if (!embedded) {
  newSessionInstanceId();
}

export const getUserAndAPIHash = (cookie) => {
  const cookieValue = getCookieValue(API_HASH, cookie);

  if (!cookieValue) {
    return {
      viewerId: null,
      apiHash: getCookieValue(API_AIRSLATE_HASH, cookie) || null,
    };
  }

  const [viewerId = null, apiHash = null] = decodeURIComponent(cookieValue).split(':');
  return { viewerId, apiHash };
};

const getParametersAsObj = (paramStr = window.location.search.substr(1)) => {
  return paramStr.split('&').reduce((prev, curr) => {
    const paramObj = { ...prev };
    const p = curr.split('=');
    if (decodeURIComponent(p[0])) {
      paramObj[decodeURIComponent(p[0])] = decodeURIComponent(p[1]);
    }
    return paramObj;
  }, {});
};

const getHostname = () => {
  return window.location.origin;
};

const isIpAddress = (host) => {
  const regex = new RegExp(IPREGEXP);
  return regex.exec(host);
};

const isLocalhost = () => {
  return window.location.origin.indexOf('localhost') >= 0 || isIpAddress(window.location.hostname);
};

export const getAuthProps = (websocketClient) => {
  const qs = queryString.parse(window.location.search);
  const { projectId } = qs;
  const { viewerId: cookieViewerId, apiHash } = getUserAndAPIHash();
  const { viewerId: qsViewerId } = qs;

  const location = {
    origin: window.location.origin,
    href: window.location.href,
  };

  const urlParams = {
    ...(__CLIENT__ && { pathname: window.location.pathname }),
    ...getParametersAsObj(),
  };

  const authProps = {
    projectId,
    viewerId: qsViewerId || cookieViewerId,
    location,
    sessionHash: getSessionInstanceId(),

    clientType: 'js',
    mode: 'edit',
    token: 'galleries',
    launch: qs.launch || 'editor',
    api_hash: apiHash,
    preauth_hash: getPreauthHash(),
    device: {
      ...getDeviceProps(),
      userAgent: window.navigator.userAgent,
    },

    // в ws-editor-lib используется moment().utc().valueOf(),
    // чтобы не загружать библиотеку moment, попробуем более легкую версию
    timestamp: new Date().getTime(),
    checkAuth: false,
    referrer: document.referrer,
    urlParams,

    // передается через аргументы в функции setAuth,
    // не смог найти, где можно получить ipAddress
    ipAddress: '',
    allowExtraData: [
      'formula',
      'dropdown',
    ],
  };

  if (getHostname() && !isLocalhost()) {
    // eslint-disable-next-line no-param-reassign, no-underscore-dangle
    authProps._host = `${getHostname()}/`;
  }

  if (websocketClient.clientId) {
    authProps.clientId = Number(websocketClient.clientId);
    authProps.confirmedOps = websocketClient.operationsCounter;
  }

  // eslint-disable-next-line no-underscore-dangle
  if (websocketClient._host) {
    // eslint-disable-next-line no-param-reassign, no-underscore-dangle
    authProps._host = websocketClient._host;
  }

  if (getParameterByName('_host')) {
    // eslint-disable-next-line no-param-reassign, no-underscore-dangle
    authProps._host = getParameterByName('_host');
  }

  if (websocketClient.clientId) {
    authProps.clientId = Number(websocketClient.clientId);
    authProps.confirmedOps = websocketClient.operationsCounter;
  }


  if (websocketClient.organization) {
    authProps.organization = websocketClient.organization;
  }

  if (getParameterByName('token')) {
    authProps.api_hash = getParameterByName('token');
  }

  if (getParameterByName('viewerId')) {
    authProps.viewerId = getParameterByName('viewerId');
  }

  return authProps;
};

export const updateWebsocketClientOnAuth = (websocketClient, {
  projectId,
  viewerId,
  sessionHash,
  apiHash,
  deviceProps,
  urlParams,
  _host,
  location,
}) => {
  // eslint-disable-next-line no-param-reassign
  websocketClient.projectId = projectId || DEFAULT_ID;
  // eslint-disable-next-line no-param-reassign
  websocketClient.viewerId = viewerId || DEFAULT_ID;
  // eslint-disable-next-line no-param-reassign
  websocketClient.sessionHash = sessionHash;
  // eslint-disable-next-line no-param-reassign
  websocketClient.apiHash = apiHash;
  // eslint-disable-next-line no-param-reassign
  websocketClient.device = deviceProps;
  // eslint-disable-next-line no-param-reassign
  websocketClient.operationsCounter = 0;
  // eslint-disable-next-line no-param-reassign
  websocketClient.referrer = document.referrer;
  // eslint-disable-next-line no-param-reassign
  websocketClient.urlParams = urlParams;
  // eslint-disable-next-line no-param-reassign, no-underscore-dangle
  websocketClient._host = _host;
  // eslint-disable-next-line no-param-reassign
  websocketClient.location = location;
};
