import React, { Component } from 'react';
import PropTypes from 'prop-types';
import cls from 'classnames';
import isSignNow from '../../helpers/isSignNow';

export const disableClickOutsideAttributeName = 'data-disable-click-outside';

export const popoverRootClassName = 'popover';

export const popoverThemes = {
  primary: 'primary',
  primaryUnbordered: 'primary-unbordered',
  dark: 'dark',
  error: 'error',
  tutorial: 'tutorial',
  warning: 'warning',
  transparentGray: 'transparent-gray',
  lightblue: 'lightblue',
  warm: 'warm',
  warmInvalid: 'warm-invalid',
  danger: 'danger',
  white: 'white',
};

export const popoverArrowPositions = {
  topCenter: 'top-center',
  topRight: 'top-right',
  topLeft: 'top-left',
  rightCenter: 'right-center',
  rightTop: 'right-top',
  rightBottom: 'right-bottom',
  leftCenter: 'left-center',
  leftTop: 'left-top',
  leftBottom: 'left-bottom',
  bottomLeft: 'bottom-left',
  bottomCenter: 'bottom-center',
  bottomRight: 'bottom-right',
  null: null,
};

export const popoverSizes = {
  mediumStretched: 'medium-stretched',
  small: 'small',
  medium: 'medium',
  null: null,
};

export const popoverOffsets = {
  small: 'small',
  medium: 'medium',
  extraSmall: 'extra-small',
  large: 'large',
};

export const popoverOffsetsHorizontal = {
  medium: 'medium',
};

export const popoverOffsetsVertical = {
  small: 'small',
};

export const popoverBehaviors = {
  userParentContext: 'use-parent-context',
  null: null,
};

export const popoverZIndexes = {
  extraLarge: 'extra-large',
  null: null,
};

const positionStyles = {
  position: 'absolute',
  top: 'calc(100% + 15px)',
  left: '50%',
  marginLeft: '-106px',
};

export default class Popover extends Component {
  static propTypes = {
    children: PropTypes.node,
    attributes: PropTypes.shape({}),
    closeButton: PropTypes.element,
    theme: PropTypes.oneOf(Object.values(popoverThemes)),
    arrowPosition: PropTypes.string,
    tIndex: PropTypes.string,
    className: PropTypes.string,
    style: PropTypes.shape({}),
    size: PropTypes.oneOf(
      Object.values(popoverSizes),
    ),
    offset: PropTypes.oneOf(
      Object.values(popoverOffsets),
    ),
    offsetHorizontal: PropTypes.oneOf(
      Object.values(popoverOffsetsHorizontal),
    ),
    offsetVertical: PropTypes.oneOf(
      Object.values(popoverOffsetsVertical),
    ),
    behavior: PropTypes.oneOf(
      Object.values(popoverBehaviors),
    ),
    zIndex: PropTypes.oneOf(
      Object.values(popoverZIndexes),
    ),
    bothCenter: PropTypes.bool,
    disableClickOutside: PropTypes.bool,
    aboveModals: PropTypes.bool,
    storeRef: PropTypes.func,
    onClick: PropTypes.func,
  };

  static defaultProps = {
    attributes: {},
    theme: popoverThemes.primary,
    arrowPosition: popoverArrowPositions.topCenter,
    style: isSignNow()
      ? {}
      : positionStyles,
    size: popoverSizes.null,
    offset: popoverOffsets.null,
    offsetHorizontal: null,
    offsetVertical: null,
    behavior: popoverBehaviors.null,
    zIndex: popoverZIndexes.null,
    bothCenter: false,
    tIndex: '-1',
    className: null,
    children: null,
    storeRef: null,
    closeButton: null,
    disableClickOutside: false,
    aboveModals: false,
    onClick: null,
  };

  onClick = (event) => {
    if (this.props.onClick) {
      this.props.onClick(event);
      return;
    }

    event.stopPropagation();
  };

  render() {
    const {
      attributes,
      theme,
      arrowPosition,
      children,
      size,
      offset,
      offsetHorizontal,
      offsetVertical,
      behavior,
      zIndex,
      bothCenter,
      tIndex,
      className,
      storeRef,
      closeButton,
      aboveModals,
      disableClickOutside,
      ...restProps
    } = this.props;

    return (
      <div
        {...restProps}
        {...attributes}
        className={cls(
          popoverRootClassName,
          `popover--theme--${theme}`, {
            [`popover--arrow-position--${arrowPosition}`]: arrowPosition,
            [`popover--size--${size}`]: size,
            [`popover--offset--${offset}`]: offset,
            [`popover--offset-horizontal--${offsetHorizontal}`]: offsetHorizontal,
            [`popover--offset-vertical--${offsetVertical}`]: offsetVertical,
            [`popover--behavior--${behavior}`]: behavior,
            [`popover--z-index--${zIndex}`]: zIndex,
            'popover--both--center': bothCenter,
            'above-modals': aboveModals,
          },
          className,
        )}
        tabIndex={tIndex}
        ref={storeRef}
        onClick={this.onClick}
        {...disableClickOutside && {
          [disableClickOutsideAttributeName]: 'true',
        }}
      >
        {closeButton && (
          <div className="popover__close">
            {closeButton}
          </div>
        )}
        {children}
      </div>
    );
  }
}
