import {
  addElement as addElementWs,
  addFillableElement as addFillableElementWs,
} from 'ws-editor-lib/actions';
import { cancellableOpts } from 'jsfcore/store/modules/undoRedo';
import isSignNow from '../../helpers/const/isSignNow';
import { isToolThatActivateTextOnAdd } from '../../helpers/elemTypes';
import * as selectors from '../selectors';
import activateDefaultTool from './activateDefaultTool';

const activateDefaultToolOnAddElement = () => {
  return (dispatch, getState) => {
    const state = getState();
    const activeTool = selectors.base.getActiveTool(state);
    if (isToolThatActivateTextOnAdd(activeTool)) {
      dispatch(activateDefaultTool());
    }
  };
};

const addElementStrategyJSF = (...args) => {
  return (dispatch) => {
    dispatch(activateDefaultToolOnAddElement());
    dispatch(addElementWs(...args));
  };
};

const addElementStrategySignNow = (...args) => {
  return (dispatch) => {
    if (args.length > 1) {
      dispatch(addElementWs(...args));
    } else {
      dispatch(addElementWs(...args, cancellableOpts));
    }
  };
};

export const addElement = !isSignNow()
  ? addElementStrategyJSF
  : addElementStrategySignNow;

export const addFillableElement = (...args) => {
  return (dispatch) => {
    dispatch(activateDefaultToolOnAddElement());
    dispatch(addFillableElementWs(...args));
  };
};
