import React from 'react';
import loadable from '@loadable/component';
import LazyLoadController from '@pdffiller/jsf-lazyload/Controller/ControllerJsf';
import createReducer from 'jsfcore/store/modules/reducer';
import profiler from 'jsfcore/helpers/profiler';

const LazyRoot = loadable(() => {
  return import(
    /* webpackChunkName: "LazyRoot" */
    // eslint-disable-next-line comma-dangle
    './LazyRoot'
  );
});

profiler('RootInstance');

const Root = () => {
  return (
    <LazyLoadController createReducer={createReducer}>
      <LazyRoot />
    </LazyLoadController>
  );
};

export default Root;
