import get from 'lodash/get';
import { eventChannel } from 'redux-saga';
import {
  select,
  put,
  call,
  take,
  fork,
  cancel,
} from 'redux-saga/effects';
import logger from '@pdffiller/jsf-logger/clientLogger';
import {
  trackPoint,
  destroyBeforeUnload,
  actionTypes,
} from 'ws-editor-lib/actions';
import { thisDevice } from '@pdffiller/jsf-useragent';
import { selectors, thunks } from 'jsfcore';
import { systemStatuses } from 'jsfcore/helpers/const';
import {
  CANCEL_SHOWING_BEFORE_UNLOAD_PROMPT_CONFIRMATION,
} from 'jsfcore/store/modules/viewport';

export const createBeforeUnloadChannelWithPromptConfirmation = () => {
  return eventChannel((emitter) => {
    const onBeforeUnload = (event) => {
      const dialogText = '';
      // Cancel the event as stated by the standard.
      event.preventDefault();
      // Chrome requires returnValue to be set.
      // eslint-disable-next-line no-param-reassign
      event.returnValue = dialogText;

      emitter(true);

      return dialogText;
    };

    window.addEventListener('beforeunload', onBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', onBeforeUnload);
    };
  });
};

export const createBeforeUnloadChannel = () => {
  return eventChannel((emitter) => {
    const eventName = thisDevice.isIOS
      ? 'pagehide'
      : 'beforeunload';
    window.addEventListener(eventName, emitter);

    return () => {};
  });
};

export const createUnloadChannel = () => {
  return eventChannel((emitter) => {
    window.addEventListener('unload', emitter);

    return () => {};
  });
};

export function* onDestroy(channel) {
  yield take([actionTypes.DESTROY, CANCEL_SHOWING_BEFORE_UNLOAD_PROMPT_CONFIRMATION]);
  channel.close();
}

export function* handleBeforeUnloadWithPromptConfirmation() {
  const beforeUnloadChannel = yield call(createBeforeUnloadChannelWithPromptConfirmation);
  const destroyTask = yield fork(onDestroy, beforeUnloadChannel);
  yield take(beforeUnloadChannel);
  const unloadChannel = yield call(createUnloadChannel);
  yield take(unloadChannel);
  yield cancel(destroyTask);
}

export function* handleBeforeUnload() {
  const beforeUnloadChannel = yield call(createBeforeUnloadChannel);
  yield take(beforeUnloadChannel);
}

export default function* watchBeforeUnload() {
  const { auth } = yield take(actionTypes.AUTH_RECEIVE);
  const showPromptConfirmationBeforeExit = get(auth, 'project.promptConfirmationBeforeExit');

  if (showPromptConfirmationBeforeExit && !thisDevice.isIOS && !thisDevice.isFirefoxDesktop) {
    yield call(handleBeforeUnloadWithPromptConfirmation);
  } else {
    yield call(handleBeforeUnload);
  }

  const activeElementId = yield select(selectors.base.getActiveElementId);
  if (activeElementId !== false) {
    yield put(thunks.setActiveElement(activeElementId, false));
  }

  const system = yield select(selectors.base.getSystem);

  if (
    system &&
    system.status !== systemStatuses.none &&
    system.status !== systemStatuses.destroy &&
    system.status !== systemStatuses.destroyed
  ) {
    logger.loggerDestroy();
    yield put(trackPoint('EXIT', { action: 'unknown' }));
    const fatalError = yield select(selectors.base.getFatalError);
    yield put(destroyBeforeUnload('', fatalError === false));
  }
}
