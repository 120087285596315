import get from 'lodash/get';
import {
  // createGalleryImage, createGallerySignature, checkDiffAndApplyDefaultsAndSend,
  getNextLocalId,
  createId,
  sendToolOperation,
} from '../Utils';

const TYPE_STEPS = 'steps';

export function doRouting(operations, roles) {
  const { properties } = operations[0];
  switch (properties.type) {
    case TYPE_STEPS:
      if (properties.subType === 'get' &&
        get(properties, 'steps', false) &&
        get(properties, 'cc', false)) {
        return {
          steps: properties.steps,
          cc: properties.cc,
        };
      }
      return {};
    default:
      return roles;
  }
}

export function updateRouting(transport, state, { steps, cc }) {
  const sendOperationsCount = getNextLocalId(state.sendOperationsCount);
  const id = createId(sendOperationsCount);

  sendToolOperation(transport, id, {
    type: TYPE_STEPS,
    subType: 'set',
    steps,
    cc,
  });

  return {
    ...state,
    sendOperationsCount,
    routing: {
      steps,
      cc,
    },
  };
}
