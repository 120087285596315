import React from 'react';
import PropTypes from 'prop-types';

const ContentConfiguratorHeader = ({ children }) => {
  return (
    <div className="content-configurator__header">
      {children}
    </div>
  );
};

ContentConfiguratorHeader.propTypes = {
  children: PropTypes.node,
};

ContentConfiguratorHeader.defaultProps = {
  children: null,
};

export default ContentConfiguratorHeader;
