import get from 'lodash/get';
// import {
//   createGalleryImage, createGallerySignature, checkDiffAndApplyDefaultsAndSend
// } from '../Utils';
import { TYPES } from '../../../../constants';

export default function doRoles(operations, roles) {
  const { properties } = operations[0];
  switch (properties.type) {
    case TYPES.list:
      if (properties.subType === 'get' && get(properties, 'list', false)) {
        return properties.list;
      }
      return [];
    default:
      return roles;
  }
}

// export function updateRoles(transport, state, { roles }) {
//   const sendOperationsCount = getNextLocalId(state.sendOperationsCount);
//   const id = createId(sendOperationsCount);

//   sendToolOperation(transport, id, {
//     type: TYPES.list,
//     subType: 'set',
//     roles,
//   });

//   return {
//     ...state,
//     sendOperationsCount,
//     ...roles,
//   };
// }
