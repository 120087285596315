import Body from './Body';
import Button from './Button';
import Separator from './Separator';

const Breadcrumbs = {
  Body,
  Button,
  Separator,
};

export default Breadcrumbs;
