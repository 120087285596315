import PropTypes from 'prop-types';
import React, { Component } from 'react';
import cls from 'classnames';

export const menuItemsTypes = {
  text: 'text',
  cell: 'cell',
  toolbar: 'toolbar',
  checkMark: 'check-mark',
  body: 'body',
  control: 'control',
  dropdown: 'dropdown',
  rangeSlider: 'range-slider',
  colorExample: 'color-example',
  button: 'button',
  icon: 'icon',
  null: null,
};

export default class MenuItemByType extends Component {
  static propTypes = {
    children: PropTypes.node,
    attributes: PropTypes.shape({}),
    type: PropTypes.oneOf(
      Object.values(menuItemsTypes),
    ),
  };

  static defaultProps = {
    children: null,
    attributes: {},
    type: menuItemsTypes.cell,
  };

  render() {
    const { type } = this.props;

    return (
      <div
        className={cls({
          [`menu-item__${type}`]: type,
        })}
        {...this.props.attributes}
      >
        {this.props.children}
      </div>
    );
  }
}
