import PropTypes from 'prop-types';
import { Component } from 'react';
import throttle from 'lodash/throttle';
import { thisDevice } from '@pdffiller/jsf-useragent';
import { promisifyGetRef } from '../../helpers/getRefHelpers';

export default class DetectScrollProvider extends Component {
  static propTypes = {
    getRef: PropTypes.func,
    getRefPromise: PropTypes.func,
    onScroll: PropTypes.func.isRequired,
    timeout: PropTypes.number,
  };

  static defaultProps = {
    timeout: 0,
    getRef: null,
    getRefPromise: null,
  };

  constructor(props) {
    super(props);
    this.lastRect = {
      left: 0,
      top: 0,
    };

    if (props.timeout === 0) {
      this.eventHandler = this.proxyScroll;
    } else {
      this.eventHandler = throttle(this.proxyScroll, props.timeout);
    }
  }

  async componentDidMount() {
    document.addEventListener('scroll', this.eventHandler, {
      capture: true,
      passive: true,
    });

    const ref = await promisifyGetRef(
      this.props.getRef,
      this.props.getRefPromise,
    );

    if (ref !== null) {
      this.lastRect = ref.getBoundingClientRect();
    }
  }

  componentWillUnmount() {
    document.removeEventListener('scroll', this.eventHandler, {
      capture: true,
      passive: true,
    });
  }

  proxyScroll = async (event) => {
    // in IE we can get HTMLDocument instead of HTMLElement in specific cases (drag + touchpad zoom)
    if (thisDevice.isInternetExplorer11 && !(event.target instanceof HTMLElement)) {
      return;
    }

    const ref = await promisifyGetRef(
      this.props.getRef,
      this.props.getRefPromise,
    );

    if (ref !== null && event.target.contains(ref)) {
      const newPos = ref.getBoundingClientRect();
      if (newPos.left !== this.lastRect.left || newPos.top !== this.lastRect.top) {
        this.props.onScroll(event);
      }
      this.lastRect = newPos;
    }
  }

  render() {
    return null;
  }
}
