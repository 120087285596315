import { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import * as selectors from 'jsfcore/store/selectors';

// eslint-disable-next-line no-useless-escape
const SIMPLE_INTERPOLATION_PLACEHOLDER_REGEX = /\{.{1,100}?\}/;

// eslint-disable-next-line no-useless-escape
const GROUP_INTERPOLATION_PLACEHOLDER_REGEX = /\{[^\}]*\{.{1,100}?\}\}/;

// eslint-disable-next-line no-useless-escape
const ALL_INSIDE_BRACKETS_REGEX = /\{(.*?)\}/;


export const getLocaleById = (id, acc, dict = id.split('.')) => {
  if (!acc || !dict) {
    return null;
  }

  const locale = acc[dict.shift()];

  /* when got a nested object */
  if (typeof locale === 'object') {
    return getLocaleById(id, locale, dict);
  }

  return locale;
};

export const interpolate = (message, interpolationParams) => {
  if (interpolationParams.length === 0) {
    return message;
  }

  const interpolationParam = interpolationParams.shift();

  // simple interpolation, it seems like this:
  // {placeholder} -> interpolationParam
  // "{foo}" -> "bar"
  if (typeof interpolationParam !== 'object') {
    return interpolate(
      message.replace(SIMPLE_INTERPOLATION_PLACEHOLDER_REGEX, interpolationParam),
      interpolationParams,
    );
  }

  // plural logic starts here. we need to:
  // 1. create regexp like "one{someValue}"
  // 2. pull out this "someValue" as a string
  // 3. replace all expression with this obtained "someValue":
  // "{test, plural, one{someValue} other{otherValue}}" -> "someValue"

  // eslint-disable-next-line no-useless-escape
  const pluralRegexp = new RegExp(`${interpolationParam.name}\{[^\{]*\}`);
  const pluralChoice = message.match(pluralRegexp);

  if (pluralChoice === null) {
    return message;
  }

  // "some{values values}" -> "values values"
  let pluralChoiceInnerValue = pluralChoice[0].match(ALL_INSIDE_BRACKETS_REGEX)[1];

  // if plural has some internal placeholder (#), we should replace it with additional value
  // "# documents" -> "22 documents"
  if (interpolationParam.hasOwnProperty('value')) {
    pluralChoiceInnerValue = pluralChoiceInnerValue.replace('#', interpolationParam.value);
  }

  if (interpolationParam.hasOwnProperty('additional')) {
    pluralChoiceInnerValue = pluralChoiceInnerValue.replace('%', interpolationParam.additional);
  }

  return interpolate(
    message.replace(GROUP_INTERPOLATION_PLACEHOLDER_REGEX, pluralChoiceInnerValue),
    interpolationParams,
  );
};

export const formatMessage = (locale) => {
  return (message, id, ...interpolationParams) => {
    if (id) {
      return interpolate(getLocaleById(id, locale) || id, interpolationParams);
    }

    /* default message it's an original message without text processing */
    return message;
  };
};

@connect(
  (state) => {
    return {
      locale: selectors.locale.getSNFillerLocale(state),
    };
  },
  {},
)
export default class IntlProvider extends Component {
  static propTypes = {
    locale: PropTypes.shape({}).isRequired,
    children: PropTypes.element.isRequired,
  };

  static childContextTypes = {
    formatMessage: PropTypes.func,
  };

  getChildContext() {
    return {
      formatMessage: this.formatMessage,
    };
  }

  formatMessage = (message, id, ...interpolationParams) => {
    return formatMessage(this.props.locale)(message, id, ...interpolationParams);
  };

  render() {
    return this.props.children;
  }
}
