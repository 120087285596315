import { wizard, dispatchAction } from '@pdffiller/jsf-lazyload';
import * as selectors from '../selectors';
import { isFillable } from '../helpers/functions';

import { fConstructorShow } from '../modules/viewport';
import setActiveElement from './setActiveElement';

const toggleFConstructor = () => {
  return (dispatch, getState) => {
    const activeElement = selectors.elements.getActiveElement(getState());

    if (activeElement && !isFillable(activeElement)) {
      dispatch(setActiveElement(activeElement.id, false));
    }

    dispatch(fConstructorShow());
    dispatchAction(wizard.actions.setWizardActive, false);
  };
};

export default toggleFConstructor;
