import { actionTypes as AT } from '../../actions';
import defaultState from './defaults';
import * as Operations from '../models/Operations';
import * as Elements from '../models/Elements';
import doUpdateTodoList from '../models/Fillable';
import * as FillableElements from '../models/FillableElements/FillableElements';
import * as Attributes from '../models/Attributes';
import doUpdatePages from '../models/Pages';
import * as Comments from '../models/Comments';
import * as FontRecognition from '../models/FontRecognition';
import editorModes from '../../constants/editorModes';
import * as Utils from '../models/Operations/Utils';
import * as Wizard from '../models/Operations/Wizard';
import { projectScale } from '../helpers';
import {
  TOOL_TYPES,
  TOOL_SUB_TYPES,
  VERSIONS_SUB_TYPES,
} from '../../constants';
import {
  ConnectionCondition,
  SystemCondition,
  AccessCondition,
  MessageCondition,
} from '../models/Conditions';

export const initialState = {
  access: AccessCondition.getInitialState(),
  attributes: {},
  connection: ConnectionCondition.getInitialState(),
  defaults: {},
  diff: {},
  elements: [],
  elementsMap: {},
  // null is need for image-signature-manager-component/ImageManager to show loader
  images: null,
  attachments: [],
  message: MessageCondition.getInitialState(),
  pages: null,
  // null is need for image-signature-manager-component/SignatureManager to show loader
  signatures: null,
  source: {},
  system: SystemCondition.getInitialState(),
  ...defaultState,
  activeElement: false,
  lastActiveFillableElementId: false,
  activeTool: null,
  addedSignature: null,
  addedImage: null,
  apiHash: null,
  errorList: [],
  fillableGroups: { flat: {}, radio: {} },
  ghostElement: null,
  routing: { steps: [], cc: [] },
  routingTemplate: null,
  activeSigner: false,
  mode: null,
  isFCActive: false,
  confirmedOps: null,
  formulaCache: {
    expandedFormulas: {},
    numberToFormulasDependencies: {},
    formulaToNumbersDependencies: {},
  },
  sendOperationsCount: { local: 0 },

  /**
   * Режим редактирования, может быть:
   * jsfiller - Режим при котором пользователь видит весь UI связанный с заполненеим Fillable форм
   * native - Режим при котором отображется только UI который позволяет править
   * PDF документ на самом низком уровне (режим true edit)
   */
  editMode: 'jsfiller',
  comments: [],
  commentsMap: {},
  users: {},
  experimentalFeatures: [],
  defaultFCCheckmarkSubtype: TOOL_SUB_TYPES[TOOL_TYPES.checkmark].x,
  pdf: {
    pdfScale: projectScale,
  },
  // don't need to render scenarios on init (scenarios && <Scenarios />)
  scenarios: null,
  organization: null,
  versions: {
    list: [],
    isRequested: false,
    isInitialState: true,
  },
  isAuthReceived: false,
  isLoggedInViewer: false,
};

export default (transport) => {
  return (state = initialState, action) => {
    switch (action.type) {
      case AT.RESET_ALL_REDUCERS:
        return initialState;

        // TEMP
      case AT.SET_ELEMENTS: {
        const { elements, fillableGroups } = Utils.createGroupsUsingElements(action.elements);
        return {
          ...state,
          elements,
          elementsMap: Utils.createMapFromElements(elements),
          fillableGroups,
        };
      }

      case AT.SERVER_CONNECT:
        return {
          ...state,
          connection: ConnectionCondition.connect(state.connection),
        };

      case AT.SERVER_DISCONNECT:
        return {
          ...state,
          connection: ConnectionCondition.disconnect(state.connection),
        };

      case AT.RECONNECT:
        return {
          ...state,
          connection: ConnectionCondition.reconnect(state.connection),
          system: SystemCondition.reconnect(state.system),
        };

      case AT.ADD_ERROR:
        return {
          ...state,
          connection: ConnectionCondition.error(state.connection),
          errorList: [
            ...state.errorList,
            action.err,
          ],
        };

      case AT.AUTH_INIT:

        return {
          ...state,
          system: SystemCondition.sendAuth(state.system),
          projectId: action.projectId,
          viewerId: action.viewerId,
          sessionHash: action.sessionHash,
          location: action.location,
        };

      case AT.AUTH_RESTORE:

        return {
          ...state,
          system: SystemCondition.sendAuth(state.system),
          projectId: action.projectId,
          viewerId: action.viewerId,
          sessionHash: action.sessionHash,
          location: action.location,
        };

      case AT.AUTH_RECEIVE:
      // todo modeId in MODEL

        return {
          ...state,
          isAuthReceived: true,
          access: AccessCondition.handleBusyCounter(state.access, true),
          system: SystemCondition.receiveAuth(state.system),
          endPoints: {
            ...action.auth.settings.endPoints,
            staticUrl: action.auth.settings.staticUrl,
            tempStorageUrl: action.auth.settings.tempStorageUrl,
            ping: action.auth.settings.ping,
          },
          modeId: action.auth.project.modeId,
          modeLabel: action.auth.project.modeLabel,
          exitExperimentUrl: action.auth.project.exitExperimentUrl,
          projectName: action.auth.project.name,
          confirmedOps: action.auth.confirmedOps,
          external: action.auth.external,
          launch: action.auth.launch,
          useSignNowTheme: action.auth.project.useSignNowTheme,
          isLoggedInViewer: action.auth.project.viewer.loggedIn,

          features: {
            ...state.features,
            wizard: {
              ...state.features.wizard,
              hidePointerInFullWizard: action.auth.project.hidePointerInFullWizard,
            },
            useNewFeedbackModal: action.auth.project.useNewFeedbackModal,
            showSupportChatButton: action.auth.project.showChatButton,
          },
        };

      case AT.SET_EXTERNAL_USER:
        return {
          ...state,
          external: {
            ...state.external,
            user: action.user,
          },
        };

      case AT.API_HASH_SET:
        return {
          ...state,
          apiHash: action.apiHash,
        };

      case AT.SET_CONFIRMED_OPS:

        return {
          ...state,
          confirmedOps: action.confirmedOps,
        };

      case AT.OPERATIONS_RECEIVE:
        return {
          ...state,
          access:
          action.shouldUpdateCounterBusy
            ? AccessCondition.handleBusyCounter(state.access)
            : state.access,
          system: SystemCondition.receiveOperations(state.system),
        };

      case AT.ON_OPERATIONS_TOOLS_ELEMENTS:
      case AT.ON_OPERATIONS_TOOLS_RADIO:
        return {
          ...state,
          ...Operations.Tools.doElements(transport(), state, action),
        };

      case AT.ON_OPERATIONS_TOOLS_PAGES:

        return {
          ...state,
          pages: Operations.Tools.doPages(action.operations, state.pages),
        };

      case AT.ON_OPERATIONS_DOCUMENTSOURCE_TOOLSPAGES:

        return {
          ...state,
          pages: Operations.Tools.doPages(action.operations.pages, state.pages),
          source: Operations.Document.doSource(action.operations.source, state.source),
        };

      case AT.ON_OPERATIONS_TOOLS_ATTRIBUTES:

        return {
          ...state,
          attributes: Operations.Tools.doAttributes(action.operations, state.attributes),
        };

      case AT.ON_OPERATIONS_DOCUMENT_ACCESS:

        return {
          ...state,
          access: Operations.Document.doAccess(action.operations, state.access),
        };

      case AT.ON_OPERATIONS_DOCUMENT_SOURCE:

        return {
          ...state,
          source: Operations.Document.doSource(action.operations, state.source),
        };

      case AT.ON_OPERATIONS_EDITOR_DEFAULTS:

        return {
          ...state,
          defaults: Operations.Editor.doDefaults(action.operations, state),
        };

      case AT.ON_OPERATIONS_EDITOR_SCENARIOS:

        return {
          ...state,
          scenarios: Operations.Editor.doScenarios(action.operations, state.scenarios),
        };

      case AT.ON_OPERATIONS_EDITOR_FEATURES:

        return {
          ...state,
          features: Operations.Editor.doFeatures(action.operations, state.features),
        };

      case AT.ON_OPERATIONS_EDITOR_GUI:

        return {
          ...state,
          gui: Operations.Editor.doGUI(action.operations, state.gui),
        };

      case AT.ON_OPERATIONS_EDITOR_EXPERIMENTS:

        return {
          ...state,
          experiments: Operations.Editor.doExperiments(action.operations, state.experiments),
        };

      case AT.ON_OPERATIONS_EDITOR_STATISTICS:

        return {
          ...state,
          statistics: Operations.Editor.doStatistics(action.operations, state.statistics),
        };

      case AT.ON_OPERATIONS_EDITOR_MODE:
        return {
          ...state,
          mode: Operations.Editor.doEditorMode(action.operations),
        };

      case AT.ON_OPERATIONS_IMAGES_LIST:
        return {
          ...state,
          images: Operations.Gallery.doImages(action.operations, state.images),
        };

      case AT.ON_OPERATIONS_ROLES_LIST:

        return {
          ...state,
          roles: Operations.Roles.doRoles(action.operations, state.roles),
        };

      case AT.ON_OPERATIONS_ROUTING_STEPS: {
        const routing = Operations.Routing.doRouting(action.operations, state.routing);

        return {
          ...state,
          routing,
          activeSigner: routing.steps[0].signers[0].roleId,
        };
      }

      case AT.UPDATE_GALLERY_IMAGE:
        return {
          ...state,
          images: Operations.Gallery.deleteFromState(state.images, action.image),
        };

      case AT.ON_OPERATIONS_IMAGES_ADD:
      case AT.ON_OPERATIONS_IMAGES_UPDATE: {
        const images = Operations.Gallery
          .addToState(state.images, action.operations[0].properties, TOOL_TYPES.image);

        return {
          ...state,
          images,
          addedImage: images[0],
        };
      }
      case AT.DELETE_GALLERY_IMAGE:
        return {
          ...state,
          images: Operations.Gallery.deleteFromState(state.images, action.image),
        };

      case AT.ON_OPERATIONS_SIGNATURES_LIST:

        return {
          ...state,
          signatures: Operations.Gallery.doSignatures(action.operations, state.signatures),
        };

      case AT.UPDATE_GALLERY_SIGNATURE:

        return {
          ...state,
          signatures: Operations.Gallery.deleteFromState(state.signatures, action.signature),
        };

      case AT.ON_OPERATIONS_SIGNATURES_ADD:
      case AT.ON_OPERATIONS_SIGNATURES_UPDATE: {
        const signatures = Operations.Gallery
          .addToState(state.signatures, action.operations[0].properties, TOOL_TYPES.signature);

        return {
          ...state,
          signatures,
        };
      }
      case AT.ON_OPERATIONS_ATTACHMENTS_LIST:

        return {
          ...state,
          signatures: Operations.Attachments.doAttachments(action.operations, state.attachments),
        };

      case AT.ON_OPERATIONS_ATTACHMENTS_ADD:
        return {
          ...state,
          signatures: Operations.Attachments.updateAttachments(
            action.operations,
            state.attachments,
          ),
        };

      case AT.ON_OPERATIONS_FONT_RECOGNIZE:
        return {
          ...state,
          ...FontRecognition.doReceiveRecognizedFont(state, action),
        };

      case AT.DELETE_GALLERY_SIGNATURE:
        return {
          ...state,
          signatures: Operations.Gallery.deleteFromState(state.signatures, action.signature),
        };

      case AT.DESTROY:
        return {
          ...state,
          system: SystemCondition.destroy(
            state.system,
            action.params.location,
            action.doneProgress,
          ),
        };

      case AT.DESTROY_RECEIVE: {
        const { location, params: { changes } } = action;

        return {
          ...state,
          system: SystemCondition.destroyed(state.system, location, changes),
        };
      }

      case AT.MESSAGE_SET:
        return {
          ...state,
          message: action.message,
        };

      case AT.TIMER_TICK:
        return {
          ...state,
          access: AccessCondition.setAccessTimer(state, action),
        };

      case AT.ACTIVATE_TOOL:
        return {
          ...state,
          ...Elements.doActivateTool(transport(), state, action),
        };

      case AT.ADD_ELEMENT:
        return {
          ...state,
          ...Elements.doAddElement(transport(), state, action),
        };

      case AT.ADD_ELEMENTS:
        return {
          ...state,
          ...Elements.doAddElements(transport(), state, action),
        };

      case AT.ADD_FILLABLE_ELEMENTS:
        return {
          ...state,
          ...FillableElements.doAddFillableElements(transport(), state, action),
        };

      case AT.UPDATE_ELEMENT:
        return {
          ...state,
          ...Elements.doUpdateElement(transport(), state, action),
        };

      case AT.UPDATE_ELEMENTS:
        return {
          ...state,
          ...Elements.doUpdateElements(transport(), state, action),
        };

      case AT.SET_ACTIVE_ELEMENT:
        return {
          ...state,
          ...Elements.doSetActiveElement(transport(), state, action),
        };

      case AT.REMOVE_ELEMENT:
        return {
          ...state,
          ...Elements.doRemoveElement(transport(), state, action),
        };

      case AT.REMOVE_ELEMENTS:
        return {
          ...state,
          ...Elements.doRemoveElements(transport(), state, action),
        };

      case AT.CLEAR_ELEMENT:
        return {
          ...state,
          ...Elements.doClearElement(transport(), state, action),
        };

        // code below only for fill & bulk
      case AT.FULL_RELOAD:
        return {
          ...state,
          source: { ...initialState.source },
          elements: [],
          elementsMap: {},
          activeElement: false,
        };

        // case for cancel from FCMode
      case AT.RESET_ELEMENTS:
        return {
          ...state,
          elements: [],
          elementsMap: {},
          activeElement: false,
        };

      case AT.UPDATE_TODO_LIST:
        return {
          ...state,
          ...doUpdateTodoList(transport(), state, action),
        };

      case AT.SET_SIGNATURE_DEFAULTS:
        return {
          ...state,
          ...Operations.Gallery.updateSignatureDefaults(transport(), state, action),
        };

      case AT.UPDATE_DEFAULT_SETTINGS:
        return {
          ...state,
          ...Elements.doUpdateDefaultSettings(transport(), state, action),
        };

      case AT.ACTIVATE_FILLABLE_TOOL:
        return {
          ...state,
          ...FillableElements.doActivateFillableTool(transport(), state, action),
        };

      case AT.ADD_FILLABLE_ELEMENT:
        return {
          ...state,
          ...FillableElements.doAddFillableElement(transport(), state, action),
        };

      case AT.ADD_FILLABLE_ELEMENT_AS_IS:
        return {
          ...state,
          ...FillableElements.doAddFillableElementAsIs(transport(), state, action),
        };

      case AT.UPDATE_FILLABLE_ELEMENT:
        return {
          ...state,
          ...FillableElements.doUpdateFillableElement(transport(), state, action),
        };

      case AT.UPDATE_FILLABLE_ELEMENTS:
        return {
          ...state,
          ...FillableElements.doUpdateFillableElements(transport(), state, action),
        };

      case AT.REMOVE_FILLABLE_ELEMENT:
        return {
          ...state,
          ...FillableElements.doRemoveFillableElement(transport(), state, action),
        };

      case AT.UPDATE_ORDER_FILLABLE_ELEMENTS:
        return {
          ...state,
          ...FillableElements.doUpdateOrderFillableElements(transport(), state, action),
        };

      case AT.SHOW_CONSTRUCTOR_PREVIEW:
        return {
          ...state,
          ...FillableElements.doDisableNonFillableElement(transport(), state),
          wsSnapshot: { ...state },
        };

      case AT.HIDE_CONSTRUCTOR_PREVIEW:
        return {
          ...state.wsSnapshot,
          /*
            В режиме превью мы можем менять активный элемент,
            а в wsSnapshot может быть записан активный элемент не равный тому,
            который мы выбрали в режиме превью,
            поэтому нам нужно оставить актуальный активный элемент.
          */
          activeElement: state.activeElement,

          /*
            При смене preview режима на другой
            устанавливаем activeTool в fctool
            для нормальной работы в режиме конструктора
            (в airSlate можно перейти в режим preview из edit режима,
            поэтому в wsSnapshot сохранится activeTool: {type: 'text'})
           */
          ...Elements.doActivateTool(transport(), state, {
            toolSubType: TOOL_SUB_TYPES.none,
            toolType: TOOL_TYPES.fctool,
          }),
        };

      case AT.ACTIVATE_FCMODE:
        return {
          ...state,
          ...Operations.Editor.doActivateFCMode(transport(), state.sendOperationsCount, action),
          wsEditModeSnapshot: { ...state },
        };

      case AT.CANCEL_FCMODE:
        return {
          ...state,
          ...state.wsEditModeSnapshot,
          ...Operations.Editor.doCancelFCMode(transport(), state.sendOperationsCount),
          mode: state.mode,
          activeElement: false,
        };

      case AT.ACTIVATE_DEFAULT_MODE:
        return {
          ...state,
          ...Elements.doExitFConstructor(transport(), state),
          ...Operations.Editor.doActivateDefaultMode(
            transport(),
            state.sendOperationsCount,
            action,
          ),
        };

      case AT.ACTIVATE_MODE:
        return {
          ...state,
          ...Operations.Editor.doActivateMode(transport(), state.sendOperationsCount, action),
        };

      case AT.SEND_TRIGGER_OPERATION:
        return {
          ...state,
          ...Operations.Editor.doTriggerOperation(transport(), state.sendOperationsCount, action),
        };

      case AT.ACTIVATE_PENDING_MODE_FOR_ADDONS:
        return {
          ...state,
          mode: editorModes.pending,
        };

      case AT.SET_PA_SETTINGS: {
        return {
          ...state,
          ...Attributes.doUpdateAttributes(transport(), state, action),
        };
      }

      case AT.UPDATE_ATTR_DEFAULTS: {
        return {
          ...state,
          ...Attributes.doUpdateAttrDefaults(transport(), state),
        };
      }

      case AT.UPDATE_FILLABLE_GROUPS: {
        return {
          ...state,
          fillableGroups: action.fillableGroups,
        };
      }

      case AT.SAVE_ROUTING_SIGNERS_TEMPLATE: {
        return {
          ...state,
          routingTemplate: {
            steps: action.steps,
            cc: action.cc,
          },
        };
      }

      case AT.REMOVE_ROUTING_SIGNERS_TEMPLATE: {
        return {
          ...state,
          routingTemplate: null,
        };
      }

      case AT.SET_ACTIVE_SIGNER: {
        return {
          ...state,
          activeSigner: action.roleId,
        };
      }

      case AT.APPLY_CONFIG_PAGES:
        return doUpdatePages(transport(), state, action);

        // Temporary, used in signnow
      case AT.CHANGE_ELEMENT_PAGE_ID: {
        const elements = state.elements.map((element) => {
          if (element.id === action.elementId) {
            return {
              ...element,
              pageId: action.pageId,
            };
          }

          return element;
        });

        return {
          ...state,
          elements,
          elementsMap: Utils.createMapFromElements(elements),
        };
      }

      // comments
      case AT.ON_OPERATIONS_TOOLS_COMMENT:
        return {
          ...state,
          ...Operations.Comments.doComments(transport(), action.operations, state),
        };

      case AT.ON_OPERATIONS_USERS_LIST:
        return {
          ...state,
          ...Operations.Users.doUsers(action.operations, state),
        };

      case AT.ADD_COMMENT:
        return {
          ...state,
          ...Comments.addComment(transport(), state, action),
        };

      case AT.UPDATE_COMMENT:
        return {
          ...state,
          ...Comments.updateComment(transport(), state, action),
        };

      case AT.DELETE_COMMENT:
        return {
          ...state,
          ...Comments.deleteComment(transport(), state, action),
        };

      case AT.UPDATE_ROUTING:
        return {
          ...state,
          ...Operations.Routing.updateRouting(transport(), state, action),
        };

      case AT.SEND_FONT_RECOGNITION_IMAGE:
        return {
          ...state,
          ...FontRecognition.doSendFontRecognitionImage(transport(), state, action),
        };

      case AT.SET_EXPERIMENTAL_FEATURES:
        return {
          ...state,
          experimentalFeatures: [
            ...state.experimentalFeatures,
            ...action.experimentalFeatures,
          ],
        };

      case AT.SET_DEFAULT_FILLABLE_CHECKMARK_SUBTYPE:
        return {
          ...state,
          defaultFCCheckmarkSubtype: action.value,
        };

      case AT.CHANGE_VIEW_MODE:
        return {
          ...state,
          external: {
            ...state.external,
            document: {
              ...state.external.document,
              viewMode: action.viewMode,
            },
          },
        };

      case AT.CHANGE_ALLOW_EDITING:
        return {
          ...state,
          ...FillableElements.doChangeAllowEditing(state, action.roleId),
        };

      case AT.DELETE_CONTENT_FOR_PREVIEW_MODE:
        return {
          ...state,
          ...FillableElements.doDeleteContentForPreviewMode(state),
        };

      case AT.SET_PDF_DOCUMENT_SCALE:
        return {
          ...state,
          pdf: {
            ...state.pdf,
            pdfScale: action.pdfScale,
          },
        };
      // getting validators for snfiller
      case AT.ON_OPERATIONS_VALIDATORS_ELEMENTS:
        return {
          ...state,
          validators: action.operations[0].properties.validators,
        };
      // getting validators for jsfiller
      case AT.ADD_VALIDATORS_COLLECTION:
        return {
          ...state,
          validators: action.validators,
        };

      case AT.AUTOFILL:
        return {
          ...state,
          ...Operations.Autofill.doAutofill(transport(), state, action),
        };

      case AT.SET_ORGANIZATION:
        return {
          ...state,
          organization: action.organization,
        };

      case AT.TURN_WIZARD_LITE:
        return {
          ...state,
          ...Wizard.turnDefaultWizardLite(transport(), state),
        };

      case AT.TURN_WIZARD_FULL:
        return {
          ...state,
          ...Wizard.turnDefaultWizardFull(transport(), state),
        };

      case AT.SET_LAST_ACTIVE_FILLABLE_ELEMENT_ID:
        return {
          ...state,
          lastActiveFillableElementId: action.value,
        };

      case AT.ACTIVATE_VERSIONS_MODE:
        return Operations.Editor.doActivateVersionsMode(transport(), state, action);

      case AT.GET_VERSIONS_LIST:
        return {
          ...state,
          ...Operations.Versions.doVersionsList(transport(), state.sendOperationsCount),
        };

      case AT.ON_OPERATIONS_VERSIONS_LIST:
        return {
          ...state,
          versions: {
            ...state.versions,
            list: action.operations[0].properties.list,
            isInitialState: action.operations[0].properties.initialState,
          },
        };

      case AT.CANCEL_VERSIONS_MODE:
        return {
          ...state,
          // https://pdffiller.atlassian.net/browse/JSF-6507
          // Временный фикс, пока на беке не починят cancel version
          // ...Operations.Editor.doCancelVersionsMode(transport(), state.sendOperationsCount),
          ...Operations.Versions.doRestoreVersion(
            transport(),
            state.sendOperationsCount,
            { versionType: VERSIONS_SUB_TYPES.current },
          ),
          attributes: initialState.attributes,
        };

      case AT.PREVIEW_VERSION:
        return {
          ...state,
          ...Operations.Versions.doPreviewVersion(transport(), state.sendOperationsCount, action),
          attributes: initialState.attributes,
        };

      case AT.RESTORE_VERSION:
        return {
          ...state,
          ...Operations.Versions.doRestoreVersion(transport(), state.sendOperationsCount, action),
        };

      case AT.SAVE_VERSION:
        return {
          ...state,
          ...Operations.Versions.doSaveVersion(transport(), state.sendOperationsCount, action),
        };

      case AT.RESET_WS_PAGES:
        return {
          ...state,
          pages: null,
        };

      case AT.RESET_COMMENTS:
        return {
          ...state,
          comments: initialState.comments,
          commentsMap: initialState.commentsMap,
        };

      default:
        return state;
    }
  };
};
