import PropTypes from 'prop-types';
import React, { Component } from 'react';

import GridCell from './Grid/GridCell';
import GridWrapper from './Grid/GridWrapper';

export default class GridCellAndWrapper extends Component {
  static propTypes = {
    children: PropTypes.node,

    onMouseDown: PropTypes.func,
  };

  static defaultProps = {
    children: null,
    onMouseDown: null,
  };

  render() {
    return (
      <GridCell onMouseDown={this.props.onMouseDown}>
        <GridWrapper>
          {this.props.children}
        </GridWrapper>
      </GridCell>
    );
  }
}
