import {
  all,
  takeEvery,
  put,
  select,
  call,
} from 'redux-saga/effects';
import { selectors, thunks } from 'jsfcore';
import { actionTypes, trackPoint } from 'ws-editor-lib/actions';
import logger from '@pdffiller/jsf-logger/clientLogger';
import { SET_FATAL_ERROR } from 'jsfcore/store/modules/events';
import { freezeEvents } from 'jsfcore/helpers/profiler.js';

function onServerConnect() {
  logger.logAuthSend();
}

function onAuthReceive() {
  logger.logAuthReceived();
}

function* deactivateElement() {
  const activeElementId = yield select(selectors.base.getActiveElementId);
  if (activeElementId) {
    yield put(thunks.setActiveElement(activeElementId, false));
  }
}

function* onSetWsConnectionFailed({ params, isError }) {
  logger.logWsConnectionFailed(params, isError);

  const trackPointName = isError
    ? 'EDITOR_ERROR'
    : 'EDITOR_WARNING';
  yield put(trackPoint(trackPointName, params));

  yield call(deactivateElement);
}

function onFreezeProfiler(action) {
  if (action && action.type === actionTypes.ON_OPERATIONS_DOCUMENT_ACCESS) {
    if (action.operations.length === 1 &&
      action.operations[0].properties &&
      action.operations[0].properties.subType !== 'busy') {
      return;
    }
  }
  freezeEvents();
}

export default function* connectionStatusWatcher() {
  yield all([
    takeEvery(actionTypes.SERVER_CONNECT, onServerConnect),
    takeEvery(actionTypes.AUTH_RECEIVE, onAuthReceive),
    takeEvery(actionTypes.SET_WS_CONNECTION_FAILED, onSetWsConnectionFailed),

    // AB: чтобы метрики снимались более равноменрно,
    // отключаем отправку метрик на сервер в случаях описанных ниже
    takeEvery(
      [
        actionTypes.RECONNECT,
        actionTypes.TIMER_TICK,
        actionTypes.SERVER_ERROR,
        actionTypes.SERVER_DISCONNECT,
        actionTypes.DESTROY_RECEIVE,
        actionTypes.SET_WS_CONNECTION_FAILED,
        actionTypes.ON_OPERATIONS_DOCUMENT_ACCESS,
        SET_FATAL_ERROR,
      ],
      onFreezeProfiler),
  ]);
}
