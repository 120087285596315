import React from 'react';
import PropTypes from 'prop-types';

const ToolBarClose = ({ children }) => {
  return (
    <div className="toolbar__close">
      {children}
    </div>
  );
};

ToolBarClose.propTypes = {
  children: PropTypes.node,
};

ToolBarClose.defaultProps = {
  children: null,
};

export default ToolBarClose;
