import React, { createContext, useContext } from 'react';

const SinglifyContext = createContext(null);

const Singlify = Component => WrappedComponent => (props) => {
  const alreadyInTree = useContext(SinglifyContext);

  if (alreadyInTree) {
    // eslint-disable-next-line no-console
    console.warn('Singlify error: focusControllerElementDecorator applied twice or more', Component);
    return <Component {...props} />;
  }

  return (
    <SinglifyContext.Provider value>
      <WrappedComponent {...props} />
    </SinglifyContext.Provider>
  );
};

export default Singlify;
