import sizeof from 'object-sizeof';
import get from 'lodash/get';
import findIndex from 'lodash/findIndex';
import mapValues from 'lodash/mapValues';
import omit from 'lodash/omit';
import queryString from 'query-string';

import {
  DEFAULT_HELP_TYPE,
  HELP_MODAL_ID,
  API_HASH,
  API_AIRSLATE_HASH,
  DEFAULT_EDITOR_MODE_ID,
  MAX_ERROR_POINT_INFO_SIZE,
  TOOL_SUB_TYPES,
  TOOL_TYPES,
} from '../../constants';

const IPREGEXP = '^(([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5]).){3}([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])$';

// TODO: write tests

// don't update ghost when it's image or signature:
// all necessary props are got from Image/SignatureManager
// also don't update ghost when textTool.overlying is updated
export const getNeedUpdateGhost = ({ type, subType }) => {
  return (
    type !== TOOL_TYPES.image &&
    type !== TOOL_TYPES.signature &&
    !(type === TOOL_TYPES.text && subType === TOOL_SUB_TYPES.text.overlying)
  );
};

export function getParameterByName(name, url = window.location.href) {
  const newName = name.replace(/[[\]]/g, '\\$&');
  const regex = new RegExp(`[?&]${newName}(=([^&#]*)|&|#|$)`);
  const results = regex.exec(url);
  if (!results) {
    return null;
  }

  if (!results[2]) {
    return '';
  }
  return decodeURIComponent(results[2].replace(/\+/g, ' '));
}

export function getHostname() {
  return window.location.origin;
}

export function isIpAddress(host) {
  const regex = new RegExp(IPREGEXP);
  return regex.exec(host);
}

export function isLocalhost() {
  return window.location.origin.indexOf('localhost') >= 0 || isIpAddress(window.location.hostname);
}

export function getParametersAsObj(paramStr = window.location.search.substr(1)) {
  return paramStr.split('&').reduce((prev, curr) => {
    const paramObj = { ...prev };
    const p = curr.split('=');
    if (decodeURIComponent(p[0])) {
      paramObj[decodeURIComponent(p[0])] = decodeURIComponent(p[1]);
    }
    return paramObj;
  }, {});
}

export function getCookieValue(name) {
  if (typeof document !== 'undefined' && document !== null) {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);

    if (parts.length === 2) {
      return parts.pop().split(';').shift();
    }
  }

  return null;
}

export function getUserAndAPIHash() {
  const cookieValue = getCookieValue(API_HASH);

  if (!cookieValue) {
    return {
      viewerId: null,
      apiHash: getCookieValue(API_AIRSLATE_HASH) || null,
    };
  }

  const [viewerId = null, apiHash = null] = decodeURIComponent(cookieValue).split(':');

  return { viewerId, apiHash };
}

export function isOpenCoreHelpModalAction(action) {
  return (action.helpType === DEFAULT_HELP_TYPE && action.modalId === HELP_MODAL_ID);
}

export function isOpenPageRearrangeModal(action) {
  return (action.modalId === 'PageRearrangeWrapper');
}

export function getModeIdFromState(state) {
  return (state.ws && state.ws.modeId)
    ? state.ws.modeId
    : DEFAULT_EDITOR_MODE_ID;
}

export function getScreenResolution() {
  return {
    height: (typeof window === 'object' && window.screen)
      ? window.screen.availHeight
      : null,

    width: (typeof window === 'object' && window.screen)
      ? window.screen.availWidth
      : null,
  };
}

export function getScale(browserName) {
  switch (browserName) {
    case 'Chrome':
    case 'Safari':
    case 'Opera':
      return window.outerWidth / window.innerWidth;
    default:
      return window.devicePixelRatio;
  }
}

export function isObjectSizeValid(object) {
  return sizeof(object) < MAX_ERROR_POINT_INFO_SIZE;
}

export const getPageIdByIndex = (index, pagesSettings) => {
  return pagesSettings[index].source;
};

export const getIndexByPageId = (sourceId, pagesSettings) => {
  return findIndex(pagesSettings, ({ source }) => {
    return source === sourceId;
  });
};

export const getAdjacentPageId = (activePageId, pagesSettings, count) => {
  const index = getIndexByPageId(activePageId, pagesSettings);
  const nextIndex = index + count;
  if (nextIndex < 0) {
    return getPageIdByIndex(0, pagesSettings);
  }
  if (nextIndex >= pagesSettings.length) {
    return getPageIdByIndex(pagesSettings.length - 1, pagesSettings);
  }

  return getPageIdByIndex(nextIndex, pagesSettings);
};

const propsForOmitOnTemplateUpdating = {
  [TOOL_TYPES.signature]: ['width', 'height', 'subType'],
  [TOOL_TYPES.image]: ['width', 'height'],
};

// при изменении dbname в content должен обновиться text, на значение полей с таким же dbname
export function getUpdatedContentByFlatGroup(template, elements) {
  if (!template || !template.name) {
    return {};
  }

  const flatElementIndex = findIndex(elements, (item) => {
    return item.template.name === template.name;
  });

  if (flatElementIndex === -1) {
    return {};
  }

  const text = get(elements, `[${flatElementIndex}].content.text`, null);

  return text
    ? { text }
    : {};
}

export function getUpdatedContentByTemplate(template, content) {
  if (!content || !template) {
    return {};
  }

  return mapValues(content, ((val, key) => {
    return template.hasOwnProperty(key)
      ? template[key]
      : val;
  }));
}

export const getUpdatedContentOnTemplateUpdating = ({
  content,
  template: originalTemplate,
  type,
  subType,
  fillableElements,
}) => {
  // https://pdffiller.atlassian.net/browse/JSF-5552
  // https://pdffiller.atlassian.net/browse/JSF-4549
  // some props shouldn't be copied from template when content exists
  // for example, image/signature width and height
  const template =
    content &&
    propsForOmitOnTemplateUpdating[type] &&
    subType !== TOOL_SUB_TYPES.text.date &&
    subType !== TOOL_SUB_TYPES.text.dropdown
      ? omit(originalTemplate, propsForOmitOnTemplateUpdating[type])
      : originalTemplate;

  const updatedContentByTemplate = getUpdatedContentByTemplate(template, content);
  const updatedContentByFlatGroup = getUpdatedContentByFlatGroup(template, fillableElements);

  return {
    ...updatedContentByTemplate,
    ...updatedContentByFlatGroup,
  };
};

export function getFromDefaultsTemplate(defaultsTemplate, type, subType, opts = {}) {
  const searchId = subType === TOOL_SUB_TYPES.none || !subType || type === TOOL_TYPES.radio
    ? `tools.${type}`
    : `tools.${type}.${subType}`;

  const template = defaultsTemplate.filter((defaultTemplate) => {
    return defaultTemplate.id.indexOf(searchId) !== -1;
  });

  if (template && template[0]) {
    return opts.isImmutable
      ? { ...template[0] }
      : template[0];
  }

  return {};
}

const inheritanceProps = {
  radio: [
    { name: 'label' },
    { name: 'initial' },
    { name: 'hint' },
    { name: 'allowEditing', value: null },
    { name: 'readonly', value: false },
    { name: 'subType' },
    { name: 'checked' },
  ],
  text: [
    { name: 'label' },
    { name: 'initial' },
    { name: 'hint' },
    { name: 'allowEditing', value: null },
    { name: 'readonly', value: false },
    { name: 'maxChars' },
    { name: 'maxLines' },
    { name: 'arrangement' },
    { name: 'letterCase' },
    { name: 'validatorId' },
    { name: 'viewMode' },
  ],
  number: [
    { name: 'label' },
    { name: 'initial' },
    { name: 'hint' },
    { name: 'allowEditing', value: null },
    { name: 'readonly', value: false },
    { name: 'maxChars' },
    { name: 'arrangement' },
    { name: 'validatorId' },
    { name: 'viewMode' },
  ],
  checkmark: [
    { name: 'label' },
    { name: 'initial' },
    { name: 'hint' },
    { name: 'allowEditing', value: null },
    { name: 'readonly', value: false },
    { name: 'subType' },
    { name: 'checked' },
  ],
  date: [
    { name: 'label' },
    { name: 'initial' },
    { name: 'allowEditing', value: null },
    { name: 'readonly', value: false },
    { name: 'hint' },
    { name: 'text' },
    { name: 'validatorId' },
    { name: 'viewMode' },
  ],
  dropdown: [
    { name: 'label' },
    { name: 'list', value: [] },
    { name: 'allowCustomText', value: false },
    { name: 'initial' },
    { name: 'hint' },
    { name: 'viewMode' },
  ],
};

export const propsInheritanceByGroupMap = {
  [TOOL_TYPES.text]: {
    none: inheritanceProps.text,
    [TOOL_SUB_TYPES.text.number]: inheritanceProps.number,
    [TOOL_SUB_TYPES.text.date]: inheritanceProps.date,
    [TOOL_SUB_TYPES.text.dropdown]: inheritanceProps.dropdown,
  },
  [TOOL_TYPES.checkmark]: {
    x: inheritanceProps.checkmark,
    v: inheritanceProps.checkmark,
    o: inheritanceProps.checkmark,
  },
  [TOOL_TYPES.radio]: {
    none: inheritanceProps.radio,
    x: inheritanceProps.radio,
    v: inheritanceProps.radio,
    o: inheritanceProps.radio,
  },
  [TOOL_TYPES.image]: {
    none: [],
  },
  [TOOL_TYPES.signature]: {
    [TOOL_SUB_TYPES.none]: [],
    [TOOL_SUB_TYPES.signature.initials]: [],
  },
};

export const projectScale = 4 / 3;


const {
  text, number, checkmark, signature, initials,
  date, image, dropdown, formula,
} = TOOL_TYPES;

export const elemFCTypes = {
  text,
  number,
  checkmark,
  signature,
  initials,
  date,
  image,
  dropdown,
  formula,
};

export const isEmbedded = () => {
  const qs = queryString.parse(window.location.search);

  return qs.embedded !== undefined;
};

export const sendPostMessage = (postData, targetOrigin = '*') => {
  if (window.parent === window.top) {
    window.top.postMessage(postData, targetOrigin);
  } else {
    window.parent.postMessage(postData, targetOrigin);
    window.top.postMessage(postData, targetOrigin);
  }
};
