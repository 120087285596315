import isSignNow from 'ws-editor-lib/isSignNow';
import { modulesJsf, modulesSnf } from '../const';

const modules = isSignNow()
  ? modulesSnf
  : modulesJsf;

export const SET_MODULE_LOADED = 'lazyLoad/SET_MODULE_LOADED';

const initialState = Object.keys(modules).reduce((acc, moduleName) => {
  acc[moduleName] = false;
  return acc;
}, {});

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case SET_MODULE_LOADED:
      return {
        ...state,
        [action.moduleName]: true,
      };
    default:
      return state;
  }
}

export function setModuleLoaded(moduleName) {
  return { type: SET_MODULE_LOADED, moduleName };
}
