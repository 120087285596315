import PropTypes from 'prop-types';
import React, { Component } from 'react';

export default class EditorActionItem extends Component {
  static propTypes = {
    children: PropTypes.node,

    storeRef: PropTypes.func,
  };

  static defaultProps = {
    children: null,
    storeRef: null,
  };

  render() {
    return (
      <div
        className="editor-action__item"
        ref={this.props.storeRef}
      >
        {this.props.children}
      </div>
    );
  }
}
