import get from 'lodash/get';

import { isValidRadioElement } from '../../../store/helpers/functions';
import { elementSettingsTypes } from '../../../helpers/elemTypes';
import { fieldNameValidators } from './validateFieldName';
import * as consts from '../../const';

const radioIdValidators = {
  emptyName: fieldNameValidators.emptyName,
  validFirstCharacter: fieldNameValidators.validFirstCharacter,
  validCharacters: fieldNameValidators.validCharacters,
  duplicate: {
    ...fieldNameValidators.duplicate,
    isValid: (value, element, elements) => {
      return !elements.some((el) => {
        return get(el, 'template.radioId', '') === value && el.id !== element.id;
      });
    },
  },
};

const validateRadioId = (element, validateField) => {
  const value = get(element, 'template.radioId', '');
  const field = elementSettingsTypes.radioId;

  return validateField(value, field, radioIdValidators);
};

const validateRadio = (element, validateField) => {
  const errors = [];
  const validRadioId = validateRadioId(element, validateField);
  const isRadioValueValid = isValidRadioElement(element);

  if (!validRadioId.isValid) {
    errors.push(...validRadioId.errors);
  }

  if (!isRadioValueValid) {
    errors.push({
      field: elementSettingsTypes.radioValue,
      errorText: 'Radio Value field is required',
      isGlobal: true,
    });
  }

  if (errors.length === 0) {
    return consts.valid;
  }

  return {
    isValid: false,
    errors,
  };
};

export default validateRadio;
