import {
  isInputOrTextarea,
  isContentEditable,
  isDateInput,
} from '../focusUtils';
import {
  iosDateKeyboards,
  iosKeyboards,
  iosKeyboardsWithPanel,
  zeroSpace,
} from './iosKeyboardSizes';

export const getKeyboardSize = ({ isCells, isDate, thisDevice }) => {
  const size = `${window.screen.width}x${window.screen.height}`;

  if (isDate) {
    return iosDateKeyboards[size];
  }

  // JSF-3102 in IOS Chrome cropped page due to keyboard,
  // but visually keyboard has same size that in Safari
  if (isCells || thisDevice.isChromeIOS) {
    return iosKeyboardsWithPanel[size];
  }

  return iosKeyboards[size];
};

const initializeKeyboardController = ({ setKeyboardShown, setKeyboardSize, thisDevice }) => {
  const onBlur = () => {
    if (!thisDevice.isMobile) return;

    setKeyboardShown(false);
    if (thisDevice.isIOS) setKeyboardSize(zeroSpace);
  };

  const hideKeyboard = onBlur;

  const onFocus = (node) => {
    if (!thisDevice.isMobile) return;

    const contentEditable = isContentEditable(node);
    const inputOrTextarea = isInputOrTextarea(node);
    const date = isDateInput(node);
    const isIPadDate = thisDevice.isIOS && thisDevice.isTablet && date;

    if ((!contentEditable && !inputOrTextarea && !date) || isIPadDate) {
      hideKeyboard();
      return;
    }

    setKeyboardShown(true);
    if (thisDevice.isIOS) {
      setKeyboardSize(getKeyboardSize({
        isCells: contentEditable,
        isDate: date,
        thisDevice,
      }));
    }
  };

  return {
    onFocus,
    onBlur,
    hideKeyboard,
  };
};

export default initializeKeyboardController;
