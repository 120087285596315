import PropTypes from 'prop-types';
import React, { Component } from 'react';
import cls from 'classnames';

export const textFamilies = {
  openSans: 'open-sans',
  null: null,
};

export const textWeights = {
  bold: 'bold',
  semiBold: 'semi-bold',
  regular: 'regular',
  normal: 'normal',
  null: null,
};

export const textTransforms = {
  uppercase: 'uppercase',
  null: null,
};

export const textSizes = {
  10: 10,
  11: 11,
  12: 12,
  13: 13,
  14: 14,
  15: 15,
  16: 16,
  18: 18,
  20: 20,
  21: 21,
  26: 26,
};

export const textOffsets = {
  topExtraSmall: 'top--extra-small',
  bottomExtraSmall: 'bottom--extra-small',
  bottomExtraLarge: 'bottom--extra-large',
  bottomSmall: 'bottom--small',
  rightExtraLarge: 'right--extra-large',
  large: 'large',
  null: null,
};

export const textTopOffsets = {
  extraSmall: 'extra-small',
  small: 'small',
  medium: 'medium',
  large: 'large',
  null: null,
};

export const textRightOffsets = {
  extraLarge: 'extra-large',
  null: null,
};

export const textBottomOffsets = {
  small: 'small',
  extraSmall: 'extra-small',
  large: 'large',
  medium: 'medium',
  null: null,
};

// never used in Ui/index.js
export const textVerticalOffsets = {
  extraSmall: 'extra-small',
  null: null,
};

export const textAligns = {
  left: 'left',
  right: 'right',
  center: 'center',
  null: null,
};

export const textLineHeights = {
  small: 'small',
  medium: 'medium',
  large: 'large',
  null: null,
};

export const textThemes = {
  white: 'white',
  danger: 'danger',
  darkgray: 'darkgray',
  dovegray: 'dovegray',
  lightgray: 'lightgray',
  null: null,
};

export const textTags = {
  span: 'span',
  p: 'p',
  time: 'time',
};

export const textWritings = {
  vertical: 'vertical',
  null: null,
};

export const textDisplays = {
  block: 'block',
  inlineFlex: 'inline-flex',
  null: null,
};

export const textStyles = {
  italic: 'italic',
  null: null,
};

export const textBlockTags = {
  div: 'div',
  p: 'p',
  time: 'time',
};

export default class Text extends Component {
  static propTypes = {
    children: PropTypes.node,
    icon: PropTypes.element,
    attributes: PropTypes.shape({}),
    headerText: PropTypes.string,
    italicText: PropTypes.string,
    uppercase: PropTypes.bool,
    bold: PropTypes.bool,
    center: PropTypes.bool,
    html: PropTypes.string,
    blockTag: PropTypes.oneOf(
      Object.values(textBlockTags),
    ),
    hasHTML: PropTypes.bool,
    transform: PropTypes.oneOf(
      Object.values(textTransforms),
    ),
    align: PropTypes.oneOf(
      Object.values(textAligns),
    ),
    block: PropTypes.bool,
    weight: PropTypes.oneOf(
      Object.values(textWeights),
    ),
    size: PropTypes.oneOf(
      Object.values(textSizes),
    ),
    offset: PropTypes.oneOf(
      Object.values(textOffsets),
    ),
    topOffset: PropTypes.oneOf(
      Object.values(textTopOffsets),
    ),
    rightOffset: PropTypes.oneOf(
      Object.values(textRightOffsets),
    ),
    bottomOffset: PropTypes.oneOf(
      Object.values(textBottomOffsets),
    ),
    verticalOffset: PropTypes.oneOf(
      Object.values(textVerticalOffsets),
    ),
    lineHeight: PropTypes.oneOf(
      Object.values(textLineHeights),
    ),
    display: PropTypes.oneOf(
      Object.values(textDisplays),
    ),
    style: PropTypes.oneOf(
      Object.values(textStyles),
    ),
    theme: PropTypes.oneOf(
      Object.values(textThemes),
    ),
    tag: PropTypes.oneOf(
      Object.values(textTags),
    ),
    writing: PropTypes.oneOf(
      Object.values(textWritings),
    ),
    family: PropTypes.oneOf(
      Object.values(textFamilies),
    ),
    id: PropTypes.string,
    interpolationParams: PropTypes.arrayOf(
      PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.shape({}),
        PropTypes.number,
      ]),
    ),
    dataWordBreak: PropTypes.bool,
    dataTextOverflow: PropTypes.bool,
  };

  static contextTypes = {
    formatMessage: PropTypes.func.isRequired,
  };

  static defaultProps = {
    attributes: {},
    children: null,
    icon: null,
    family: textFamilies.null,
    headerText: null,
    italicText: null,
    block: false,
    transform: textTransforms.null,
    weight: textWeights.null,
    // size: textSizes[14],
    size: textSizes[11],
    align: textAligns.null,
    offset: textOffsets.null,
    topOffset: textTopOffsets.null,
    rightOffset: textRightOffsets.null,
    bottomOffset: textBottomOffsets.null,
    verticalOffset: textVerticalOffsets.null,
    lineHeight: textLineHeights.null,
    theme: textThemes.null,
    tag: textTags.span,
    writing: textWritings.null,
    html: null,
    uppercase: false,
    bold: false,
    center: false,
    blockTag: textBlockTags.div,
    hasHTML: false,
    display: textDisplays.null,
    style: textStyles.null,
    id: null,
    interpolationParams: [],
    dataWordBreak: false,
    dataTextOverflow: false,
  };

  render() {
    const {
      attributes,
      family,
      transform,
      weight,
      size,
      align,
      block,
      headerText,
      italicText,
      offset,
      topOffset,
      rightOffset,
      bottomOffset,
      verticalOffset,
      lineHeight,
      children,
      icon,
      theme,
      tag,
      writing,
      html: __html,
      uppercase,
      display,
      style,
      bold,
      center,
      blockTag,
      hasHTML,
      id,
      interpolationParams,
      dataWordBreak,
      dataTextOverflow,
    } = this.props;

    const className = cls('text', {
      [`text--family--${family}`]: family,
      [`text--size--${size}`]: size,
      [`text--transform--${transform}`]: transform,
      [`text--weight--${weight}`]: weight,
      [`text--align--${align}`]: align,
      [`text--offset-${offset}`]: offset,
      [`text--offset-top--${topOffset}`]: topOffset,
      [`text--offset-right--${rightOffset}`]: rightOffset,
      [`text--offset-bottom--${bottomOffset}`]: bottomOffset,
      [`text--offset-vertical--${verticalOffset}`]: verticalOffset,
      [`text--line-height--${lineHeight}`]: lineHeight,
      [`text--theme--${theme}`]: theme,
      [`text--writing--${writing}`]: writing,
      [`text--display--${display}`]: display,
      [`text--style--${style}`]: style,
      [`text--family--${family}`]: family,
      'text--transform--uppercase': uppercase,
      'text--weight--regular': weight === textWeights.regular,
      'text--weight--bold': bold || weight === textWeights.bold,
      'text--align--center': center,
    });

    const Tag = tag;

    const formattedMessage = this.context.formatMessage(children, id, ...interpolationParams);

    if (__html) {
      return (
        <Tag
          className={className}
          dangerouslySetInnerHTML={{ __html }}
          {...attributes}
        />
      );
    }

    if (block) {
      const BlockTag = blockTag;

      if (hasHTML) {
        return (
          <BlockTag
            // eslint-disable-next-line
            dangerouslySetInnerHTML={{ __html: formattedMessage }}
            className={className}
            {...attributes}
          />
        );
      }

      return (
        <div
          className={className}
          {...attributes}
        >
          {formattedMessage}
        </div>
      );
    }

    //      return (
    //       <span className={className}>
    //         {headerText}
    //         {italicText && (
    //           <em>{italicText}&nbsp;</em>
    //         )}
    //         {formattedMessage}
    //       </span>
    //     );
    return (
      <Tag
        className={className}
        data-word-break={dataWordBreak}
        data-text-overflow={dataTextOverflow}
        {...attributes}
      >
        {headerText && (
          <strong>{headerText}&nbsp;</strong>
        )}
        {italicText && (
          <em>{italicText}&nbsp;</em>
        )}
        {formattedMessage}
        {icon}
      </Tag>
    );
  }
}
