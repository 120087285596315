import get from 'lodash/get';

const removeInitial = (operation) => {
  return {
    ...operation,
    properties: {
      ...operation.properties,
      initial: undefined,
    },
  };
};

const getIsInitialExists = (operation) => {
  const initial = get(operation, 'properties.initial', false);
  return initial === true;
};

const removeInitialIfExists = (operation) => {
  const isInitialExists = getIsInitialExists(operation);
  if (isInitialExists) {
    return removeInitial(operation);
  }

  return operation;
};

const getInitialOperations = (operations) => {
  return operations.filter(getIsInitialExists);
};

const removeInitialIfNeed = (operations) => {
  const initialOperations = getInitialOperations(operations);

  if (initialOperations.length === operations.length) {
    return operations;
  }

  return operations.map(removeInitialIfExists);
};

export default (groupedOperations) => {
  return groupedOperations.map(removeInitialIfNeed);
};
