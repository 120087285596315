import {
  send,
  createId,
  getNextLocalId,
} from '../Utils';
import { GROUPS, TYPES } from '../../../../constants';

export default (transport, state, action) => {
  const sendOperationsCount = getNextLocalId(state.sendOperationsCount);
  const id = createId(sendOperationsCount);

  const properties = {
    group: GROUPS.operations,
    type: TYPES.autofill,
    fromProject: action.projectId,
  };

  send(
    transport,
    id,
    properties,
  );

  return sendOperationsCount;
};
