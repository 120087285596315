import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

export const fillableWizardHorizontalOffsets = {
  large: 'large',
  null: null,
};

export const fillableWizardVerticalOffsets = {
  extraLarge: 'extra-large',
  null: null,
};

const FillableWizard = ({
  attributes,
  children,
  style,
  horizontalOffset,
  verticalOffset,
}) => {
  return (
    <div
      className={classnames('fillable-wizard', {
        [`fillable-wizard--offset-horizontal--${horizontalOffset}`]: horizontalOffset,
        [`fillable-wizard--offset-vertical--${verticalOffset}`]: verticalOffset,
      })}
      style={style}
      {...attributes}
    >
      {children}
    </div>
  );
};

FillableWizard.propTypes = {
  attributes: PropTypes.shape({}),
  children: PropTypes.element.isRequired,
  style: PropTypes.shape({}),
  horizontalOffset: PropTypes.oneOf(Object.values(fillableWizardHorizontalOffsets)),
  verticalOffset: PropTypes.oneOf(Object.values(fillableWizardVerticalOffsets)),
};

FillableWizard.defaultProps = {
  attributes: {},
  style: null,
  horizontalOffset: fillableWizardHorizontalOffsets.null,
  verticalOffset: fillableWizardVerticalOffsets.null,
};

export default FillableWizard;
