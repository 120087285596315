import PropTypes from 'prop-types';
import React, { Component } from 'react';
import cls from 'classnames';

import { dataPointerTypes } from '../Control';
import { menuItemsTypes } from './MenuItemByType';

export const menuItemsRoles = {
  button: 'button',
  null: null,
};

export default class MenuItem extends Component {
  static propTypes = {
    children: PropTypes.node,

    theme: PropTypes.string,
    size: PropTypes.string,
    offset: PropTypes.string,
    type: PropTypes.string,
    dataPointer: PropTypes.oneOf(
      Object.values(dataPointerTypes),
    ),
    role: PropTypes.oneOf(
      Object.values(menuItemsRoles),
    ),

    onClick: PropTypes.func,
  };

  static defaultProps = {
    children: null,
    theme: 'default',
    size: 'medium',
    offset: 'medium',
    type: menuItemsTypes.null,
    dataPointer: null,
    role: menuItemsRoles.null,

    onClick: null,
  };

  render() {
    const {
      theme, size, offset, type, dataPointer, role,
    } = this.props;

    return (
      <div
        className={cls('menu-item', {
          [`menu-item--theme--${theme}`]: theme,
          [`menu-item--size--${size}`]: size,
          [`menu-item--offset--${offset}`]: offset,
          [`menu-item--type--${type}`]: type,
        })}
        role={role}
        onClick={this.props.onClick}
        data-pointer={dataPointer}
      >
        {this.props.children}
      </div>
    );
  }
}
