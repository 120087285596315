import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

const TabsPanel = ({ children, isActive, style }) => {
  return (
    <div
      className={classnames('tabs__panel', {
        'is-active': isActive,
      })}
      role="tabpanel"
      tabIndex="-1"
      style={style}
    >
      {children}
    </div>
  );
};

TabsPanel.propTypes = {
  isActive: PropTypes.bool,
  children: PropTypes.node.isRequired,
  style: PropTypes.shape({}),
};

TabsPanel.defaultProps = {
  isActive: false,
  style: null,
};

export default TabsPanel;
