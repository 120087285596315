
import React from 'react';
import PropTypes from 'prop-types';

const ControlBar = ({ children, onClick }) => {
  return (
    <div
      className="control-bar__control"
      onClick={onClick}
    >
      {children}
    </div>
  );
};

ControlBar.propTypes = {
  children: PropTypes.node,
  onClick: PropTypes.func,
};

ControlBar.defaultProps = {
  children: null,
  onClick: null,
};

export default ControlBar;
