import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

const listConfiguratorThemes = {
  default: 'default',
  null: null,
};

const listConfiguratorSizes = {
  small: 'small',
  medium: 'medium',
  null: null,
};

const ListConfigurator = ({
  children,
  size,
  theme,
}) => {
  return (
    <div
      className={classnames('list-configurator', {
        [`list-configurator--size--${size}`]: size,
        [`list-configurator--theme--${theme}`]: theme,
      })}
    >
      <div className="list-configurator__body">
        {children}
      </div>
    </div>
  );
};

ListConfigurator.propTypes = {
  children: PropTypes.node.isRequired,

  size: PropTypes.oneOf(Object.values(listConfiguratorSizes)),
  theme: PropTypes.oneOf(Object.values(listConfiguratorThemes)),
};

ListConfigurator.defaultProps = {
  size: listConfiguratorSizes.null,
  theme: listConfiguratorThemes.null,
};

export default ListConfigurator;
export { listConfiguratorSizes, listConfiguratorThemes };
