import { isSearchHighlightType } from '../elemTypes';
import { searchSizeEpsilon } from '../const';

/**
 * Когда мы спрашиваепм у отрендеренного блока getClientRects(),
 * результат может отличаться в зависимости от scale.
 * Самое большое отличие, что находил - 1.3px. Если позиция-размеры отличаются на меньшее
 * значение - считаем их одинаковыми
 */
const isAlmostEqualSearchValues = (value, another) => {
  return Math.abs(value - another) < searchSizeEpsilon;
};

export const isObjectFitRectangle = (object, rect) => {
  return (
    isAlmostEqualSearchValues(object.x, rect.x) &&
    isAlmostEqualSearchValues(object.y, rect.y) &&
    isAlmostEqualSearchValues(object.width, rect.width) &&
    isAlmostEqualSearchValues(object.height, rect.height)
  );
};

/**
 * Найти erase/highlight/blackout-элементы, которые подходят хотя бы к одному из
 * заданных rects
 */
const searchExistingHighlightElements = (rects, elements) => {
  // Все элементы, которые подходят хотя бы под один из rects
  const fitElements = elements
    .filter((element) => {
      return (
        isSearchHighlightType(element.type) &&
        rects.filter((rect) => {
          return isObjectFitRectangle(element.content, rect);
        }).length > 0
      );
    });

  if (fitElements.length < rects.length || fitElements.length === 0) {
    return false;
  }

  return fitElements;
};

export default searchExistingHighlightElements;
