export const directions = {
  top: 'top',
  right: 'right',
  bottom: 'bottom',
  left: 'left',
  center: 'center',
};

export function invertDirection(direction) {
  switch (direction) {
    case directions.left:
      return directions.right;
    case directions.right:
      return directions.left;
    case directions.top:
      return directions.bottom;
    case directions.bottom:
      return directions.top;
    default:
      return direction;
  }
}

function invertDirections(side, align) {
  const isAlignCentered = align === directions.center;
  const isSideCentered = side === directions.center;
  return [
    [side, align],

    ...(!isSideCentered
      ? [[invertDirection(side), align]]
      : []),

    ...(!isAlignCentered
      ? [[side, invertDirection(align)]]
      : []),

    ...((!isAlignCentered && !isSideCentered)
      ? [[invertDirection(side), invertDirection(align)]]
      : []),
  ];
}

function createDirectionsOutput(directionsArray) {
  if (directionsArray.length === 1) {
    return directionsArray;
  }

  return [
    ...directionsArray,

    // fallback
    directionsArray[0],
  ];
}

export function getPossibleDirections({ side, align, smartPosition, restrictedPositions }) {
  if (smartPosition === false) {
    return [[side, align]];
  }

  if (restrictedPositions) {
    return createDirectionsOutput(restrictedPositions);
  }

  return createDirectionsOutput(invertDirections(side, align));
}
