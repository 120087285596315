import PropTypes from 'prop-types';
import React, { Component } from 'react';

export default class GroupItem extends Component {
  static propTypes = {
    children: PropTypes.node,

    attributes: PropTypes.shape({}),
    id: PropTypes.string,
    onClick: PropTypes.func,
    storeRef: PropTypes.func,
  };

  static defaultProps = {
    children: null,
    attributes: {},
    id: null,
    onClick: null,
    storeRef: null,
  };

  render() {
    const {
      attributes,
      children,
      id,
      onClick,
      storeRef,
      ...props
    } = this.props;

    return (
      <div
        className="group__item"
        id={id}
        onClick={onClick}
        ref={storeRef}
        {...attributes}
        {...props}
      >
        {this.props.children}
      </div>
    );
  }
}
