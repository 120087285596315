import find from 'lodash/find';
import includes from 'lodash/includes';
import { fakeEdit } from '@pdffiller/jsf-lazyload';
import { thisDevice } from '@pdffiller/jsf-useragent';
import { sizeDeltas } from './const';
import isSignNow from './const/isSignNow';
import StickyToolView from '../components/Tools/StickyTool/StickyToolView';
import TextBoxToolView from '../components/Tools/TextBoxTool/TextBoxToolView';

export const basicToolIdPrefix = 'basic';
export const additionalToolIdPrefix = 'additional';

export const toolIds = {
  // basic
  text: `${basicToolIdPrefix}_field_text`,
  number: `${basicToolIdPrefix}_field_number`,
  date: `${basicToolIdPrefix}_field_date`,
  dropdown: `${basicToolIdPrefix}_field_dropdown`,
  checkmark: `${basicToolIdPrefix}_field_checkmark`,
  signature: `${basicToolIdPrefix}_field_signature`,
  initials: `${basicToolIdPrefix}_field_initials`,
  image: `${basicToolIdPrefix}_field_image`,
  formula: `${basicToolIdPrefix}_field_formula`,
  radio: `${basicToolIdPrefix}_field_radio`,
  // additional
  name: `${additionalToolIdPrefix}_field_name`,
  email: `${additionalToolIdPrefix}_field_email`,
  company: `${additionalToolIdPrefix}_field_company`,
  title: `${additionalToolIdPrefix}_field_title`,
  phone: `${additionalToolIdPrefix}_field_phone`,
  usZip: `${additionalToolIdPrefix}_field_us_zip`,
  usCurrency: `${additionalToolIdPrefix}_field_us_currency`,
  euCurrency: `${additionalToolIdPrefix}_field_eu_currency`,
  age: `${additionalToolIdPrefix}_field_age`,
  ssn: `${additionalToolIdPrefix}_field_ssn`,
  ein: `${additionalToolIdPrefix}_field_ein`,
  ccn: `${additionalToolIdPrefix}_field_ccn`,
  states: `${additionalToolIdPrefix}_field_states`,
  gender: `${additionalToolIdPrefix}_field_gender`,
};

export const elemFCTypes = {
  text: 'text',
  number: 'number',
  checkmark: 'checkmark',
  signature: 'signature',
  initials: 'initials',
  date: 'date',
  image: 'image',
  dropdown: 'dropdown',
  radio: 'radio',
  attachment: 'attachment',
  formula: 'formula',
  smart: 'smartfield',
  salesforce: 'salesforce',
};

export const elemTypes = {
  text: 'text',
  checkmark: 'checkmark',
  sticky: 'sticky',
  textbox: 'textbox',

  line: 'line',
  arrow: 'arrow',

  blackout: 'blackout',
  highlight: 'highlight',
  erase: 'erase',
  pen: 'pen',

  signature: 'signature',
  image: 'image',

  // not actually element type, but tool type
  // пустой тул, для выделения элементов на тех документах, где textTool disabled
  selection: 'selection',

  // tool for fconstructor
  fctool: 'fctool',
  radio: 'radio',
  attachment: 'attachment',
  smart: 'smartfield',
  salesforce: 'salesforce',

  // comment tool
  comment: 'comment',
};

// indexes of drag points for draw tools
export const dragPointIndexes = {
  none: -1,
  start: 0,
  end: 1,
};

export const elementSettingsTypes = {
  answerChoices: 'list',
  prefilled: 'prefilled',
  uniqueFieldName: 'name',
  validation: 'validatorId',
  radioValue: 'radioValue',
  radioId: 'radioId',
};

// TODO: fill here other subtypes
export const elemSubTypes = {
  none: 'none',
  [elemTypes.signature]: {
    initials: 'initials',
    curve: 'curve',
    text: 'text',
    image: 'image',
  },
  [elemTypes.text]: {
    number: 'number',
    date: 'date',
    formula: 'formula',
    dropdown: 'dropdown',
    overlying: 'overlying',
  },
  [elemTypes.checkmark]: {
    v: 'v',
    x: 'x',
    o: 'o',
  },
  [elemTypes.radio]: {
    v: 'v',
    x: 'x',
    o: 'o',
  },
};

export const dateTypes = ['date', 'current+MM/dd/yyyy'];

export const getHeaderButtonClasses = (useSignNowTheme) => {
  return {
    'tools.text.none': 'redesign--text',
    'tools.text.date': 'redesign--date',
    'tools.checkmark.x': 'redesign--cross',
    'tools.checkmark.v': 'redesign--check-mark-24',
    'tools.checkmark.o': 'redesign--circle',
    'tools.image.none': 'redesign--picture',
    'tools.erase.none': 'redesign--erase',
    'tools.blackout.none': 'redesign--blackout',
    'tools.highlight.none': 'redesign--highlight',
    'tools.textbox.none': 'redesign--textbox',
    'tools.arrow.none': 'redesign--arrow-upright-24',
    'tools.line.none': 'redesign--line',
    'tools.pen.none': 'redesign--draw',
    'tools.sticky.none': 'redesign--sticky',
    'tools.signature.*': useSignNowTheme
      ? 'colored--sign-now'
      : 'redesign--sign',
  };
};

export const fConstructorTypesToElementTypes = {
  [elemTypes.text]: {
    type: elemTypes.text,
    subType: elemSubTypes.none,
  },
  [elemSubTypes.text.number]: {
    type: elemTypes.text,
    subType: elemSubTypes.text.number,
  },
  [elemTypes.checkmark]: {
    type: elemTypes.checkmark,
    subType: elemSubTypes.checkmark.x,
  },
  [elemTypes.signature]: {
    type: elemTypes.signature,
    subType: elemSubTypes.none,
  },
  [elemSubTypes.signature.initials]: {
    type: elemTypes.signature,
    subType: elemSubTypes.signature.initials,
  },
  [elemSubTypes.text.date]: {
    type: elemTypes.text,
    subType: elemSubTypes.text.date,
  },
  [elemTypes.image]: {
    type: elemTypes.image,
    subType: elemSubTypes.none,
  },
};

const keyboardTypes = [
  { type: elemTypes.text },
  { type: elemTypes.sticky },
  { type: elemTypes.textbox },
  { type: elemTypes.signature, subType: elemSubTypes.signature.text },
];

// TODO: test
export const getSignatureSubtype = (element) => {
  if (!element) {
    return elemSubTypes.none;
  }
  return (element.content && element.content.subType) || element.subType || elemSubTypes.none;
};

export const isTextToolBasedElement = (elem) => {
  if (!elem || !elem.type || !elem.subType) {
    return false;
  }

  const subType = elem.type === elemTypes.signature
    ? getSignatureSubtype(elem)
    : elem.subType;

  return !!find(
    keyboardTypes,
    (item) => {
      return item.type === elem.type &&
      (!item.subType || item.subType === subType);
    },
  );
};

export const getIsFakeEditTextElement = (elem) => {
  if (!elem || !elem.type || !elem.subType) {
    return false;
  }

  return elem.type === elemTypes.text && elem.subType === elemSubTypes.text.overlying;
};

// Array of elemTypes with which focusing is possible
// const focusTypes = [elemTypes.text, elemTypes.date];
/**
 * @param {string} elemType - tool title
 * @return {bool} returns true if with this tool, you can focus in other elements
 */
const focusTypes = isSignNow()
  ? [
    elemTypes.text,
    elemTypes.selection,
    elemTypes.fctool,
  ]
  : [
    elemTypes.text,
    elemTypes.selection,
  ];

export const isFocusTool = (tool) => {
  return includes(focusTypes, tool.type);
};

/**
 * Types that after "ok" change active tool to text
 */
const activateTextOnOkTypes = [
  elemTypes.checkmark,
  elemTypes.line,
  elemTypes.arrow,
  elemTypes.blackout,
  elemTypes.highlight,
  elemTypes.erase,
  elemTypes.pen,
];
export const isActivateTextOnOkType = (type) => {
  return includes(activateTextOnOkTypes, type);
};

/**
 * Types that after creating change active tool to text
 */

// TODO: fix
const activateTextAfterCreateTypes = [
  { type: elemTypes.text, subType: 'date' },
  { type: elemTypes.sticky, subType: 'none' },
  { type: elemTypes.textbox, subType: 'none' },
  { type: elemTypes.image },
  { type: elemTypes.signature },
];
export const isToolThatActivateTextOnAdd = (tool) => {
  return !!activateTextAfterCreateTypes.find(
    (item) => {
      return (
        item.type === tool.type &&
        !tool.id &&
        (!item.subType || item.subType === (tool.subType || 'none'))
      );
    },
  );
};

/**
 * Types that called 'graphic' tools
 */
const graphicToolTypes = [
  elemTypes.line, elemTypes.arrow, elemTypes.blackout,
  elemTypes.highlight, elemTypes.erase, elemTypes.pen,
];
export const isGraphicToolType = (type) => {
  return includes(graphicToolTypes, type);
};

/**
 * Types that have endPoints
 */
const lineTypes = [elemTypes.arrow, elemTypes.line];
export const isLineType = (type) => {
  return includes(lineTypes, type);
};

/**
 * Types that have to draw (drag mouse around to add new element)
 */
const drawTypes = [
  elemTypes.line, elemTypes.arrow, elemTypes.blackout,
  elemTypes.highlight, elemTypes.erase, elemTypes.pen,
];
export const isDrawType = (type) => {
  return includes(drawTypes, type);
};

export const isSelectionType = (type) => {
  return type === elemTypes.selection;
};

/**
 * Graphic text types: sticky and textbox
 */
const graphicTextTypes = [
  elemTypes.sticky, elemTypes.textbox,
];
export const isGraphicTextType = (type) => {
  return graphicTextTypes.indexOf(type) > -1;
};

export const isCommentTool = (type) => {
  return type === elemTypes.comment;
};
/**
 * Types that cannot be focused with mouseclick
 */
const noFocusTypes = [elemTypes.erase];
export const isNoFocusType = (type) => {
  return includes(noFocusTypes, type);
};

/**
 * Graphic types that can be drawn only horizontal
 */
const horizontalGraphicTypes = [elemTypes.highlight, elemTypes.blackout, elemTypes.erase];
export const isHorizontalGraphicType = (type) => {
  return includes(horizontalGraphicTypes, type);
};

const resizableTypes = [elemTypes.sticky, elemTypes.textbox];
export const isResizableType = (type) => {
  return includes(resizableTypes, type);
};

const preserveAspectRatioTypes = isSignNow()
  ? [
    elemTypes.checkmark,
    elemTypes.text,
    elemTypes.signature,
  ]
  : [elemTypes.image];

const preserveAspectRatioFillableTypes = [
  elemTypes.checkmark,
  elemTypes.radio,
];

export const getIsAspectRatioPreserved = (type, isFillable) => {
  return isFillable
    ? includes(preserveAspectRatioFillableTypes, type)
    : includes(preserveAspectRatioTypes, type);
};

/**
 * Return classnames for size buttons
 */
export const getSizeIcons = (element) => {
  if (isTextToolBasedElement(element)) {
    const modificator = !thisDevice.isMobile
      ? '-white'
      : '';

    return {
      more: `icon-svg-text-more${modificator}`,
      less: `icon-svg-text-less${modificator}`,
    };
  }

  const modificator = !thisDevice.isMobile
    ? '-web'
    : '';

  return {
    more: `icon-svg-graphic-more${modificator}`,
    less: `icon-svg-graphic-less${modificator}`,
  };
};

/**
 * Get delta (in pixels) for 'size up/down' button actions
 *
 * @param {string} type - type of element
 * @returns {number} size delta
 */
export const getSizeDelta = (type) => {
  if (type === elemTypes.image) {
    return sizeDeltas.image;
  }
  if (type === elemTypes.signature) {
    return sizeDeltas.signature;
  }
  return sizeDeltas.checkmark;
};

// table below is made according to flash version
// =================================================================================================
// tooltip settings
//                             | hasSize | hasRemove | hasPopup | hasStamp / maybeRect
// text                        |    +    |     +     |     +    |    -     /     -
// cross, circle, check        |    +    |     +     |     +    |    -     /     -
// sign                        |    +    |     +     |     -    |    +     /     -
// date                        |    +    |     +     |     +    |    -     /     -
// picture                     |    +    |     +     |     -    |    -     /     -
// erase, highlight, blackout  |    -    |     +     |     +    |    -     /     +
// textbox                     |    -    |     +     |     +    |    -     /     -
// arrow, line                 |    -    |     +     |     +    |    -     /     -
// draw                        |    -    |     +     |     +    |    -     /     -
// sticky                      |    -    |     +     |     +    |    -     /     -
// fillable                    |    -    |     +     |     -    |    -     /     -
//
// popup settings (sign, picture and fillables doesn't have popup)
// color: 'p' - picker, 's' - screenpicker, 'b' - background, 'r' - border
//                             | hasFont | hasColor  | hasPreview | hasThickness
// text                        |    +    |     p     |     -      |    -
// cross, circle, checkbox     |    -    |     p     |     -      |    -
// date                        |    +    |     p     |     -      |    -
// erase                       |    -    |     s     |     +      |    +
// highlight, blackout         |    -    |     s     |     -      |    +
// textbox                     |    +    |    pbr    |     -      |    -
// arrow                       |    -    |     p     |     -      |    +   (also has arrowstyle)
// line                        |    -    |     p     |     -      |    +
// draw                        |    -    |     p     |     +      |    +
// sticky                      |    -    |    pb     |     -      |    -
// =================================================================================================

// next line is only for convenient 'typesHas...' declaration
const {
  text, checkmark, line, arrow, blackout, highlight, erase,
  pen, sticky, textbox, image, signature, radio,
} = elemTypes;

// desktop tooltip (or mobile toolbar) settings
export const typesHasSize = [text, checkmark, image, signature];
const typesHasPopup = [text, checkmark, sticky, textbox, checkmark, line, arrow, pen, erase];
const typesHasStamp = [signature];

// desktop toolbar (or mobile popup) settings
const typesHasFont = [text, sticky, textbox];
const typesHasColor = [checkmark, radio];
const typeHasFontColor = [text, sticky, textbox];
const typeHasFillColor = [arrow, line, erase, pen];
const typesHasScreenPicker = [erase];
const typesHasBackgroundColor = [sticky, textbox];
const typesHasBorderColor = [textbox];
const typesHasPreview = [];
const typesHasLineWidth = [line, arrow, blackout, highlight, erase, pen];
const typesHasArrowStyle = [arrow];
const typesHasRectDraw = [blackout, highlight, erase];
const typesHasHref = [text, checkmark, image, signature, sticky, textbox,
  line, arrow, pen, blackout, highlight];
const typesHasAlign = [text, sticky, textbox];
const typesHasVAlign = [text, sticky, textbox];

export const isDrawRectType = (type) => {
  return includes(typesHasRectDraw, type);
};

/**
 * Returns object with boolean values
 * that indicate if toolbar must paint corresponding control or not
 *
 * @returns {object}
 */
export const getToolbarRenderingProps = (elemType) => {
  return ({
    hasSize: includes(typesHasSize, elemType),
    hasStamp: includes(typesHasStamp, elemType),
    hasPopup: includes(typesHasPopup, elemType),
    hasFont: includes(typesHasFont, elemType),
    hasColor: includes(typesHasColor, elemType),
    hasFontColor: includes(typeHasFontColor, elemType),
    hasFillColor: includes(typeHasFillColor, elemType),
    hasScreenPicker: includes(typesHasScreenPicker, elemType),
    hasBackgroundColor: includes(typesHasBackgroundColor, elemType),
    hasBorderColor: includes(typesHasBorderColor, elemType),
    hasPreview: includes(typesHasPreview, elemType),
    hasLineWidth: includes(typesHasLineWidth, elemType),
    hasArrowStyle: includes(typesHasArrowStyle, elemType),
    hasRectDraw: isDrawRectType(elemType),
    hasHref: includes(typesHasHref, elemType),
    hasAlign: includes(typesHasAlign, elemType),
    hasVAlign: includes(typesHasVAlign, elemType),
  });
};

// TODO: it's temporary code
const disabledTypes = [

  // elemTypes.sticky,
  // elemTypes.textbox,

  // elemTypes.line,
  // elemTypes.arrow,
  // elemTypes.blackout,
  // elemTypes.highlight,
  // elemTypes.erase,
  // elemTypes.pen,
  // elemTypes.signature,
  // elemTypes.image,
];
export const isDisabledType = (type) => {
  return includes(disabledTypes, type);
};

const preConfigurableTypes = [
  erase, highlight, blackout, textbox, arrow, line, pen, sticky,
];

export const isPreConfigurableType = (type) => {
  return includes(preConfigurableTypes, type);
};

export const searchHighlightTypes = [blackout, highlight, erase];
export const isSearchHighlightType = (type) => {
  return includes(searchHighlightTypes, type);
};

export const typesHasMagneticLinesGhostJSF = [
  elemTypes.text, elemTypes.sticky, elemTypes.line,
  elemTypes.signature, elemTypes.image, elemTypes.textbox,
  elemTypes.checkmark, elemTypes.radio,
];

export const typesHasMagneticLinesGhostSNF = [
  ...typesHasMagneticLinesGhostJSF,
  elemTypes.smart,
  elemTypes.salesforce,
  elemTypes.attachment,
];

export const typesHasMagneticLinesGhost = isSignNow()
  ? typesHasMagneticLinesGhostSNF
  : typesHasMagneticLinesGhostJSF;

export const hasMagneticLines = (elemType) => {
  return typesHasMagneticLinesGhost.some((type) => {
    return type === elemType;
  });
};

export const fakeEditTypes = [text, erase];

export const textToolSettings = {
  [elemTypes.text]: {
    marginTop: 0,
    marginBottom: 0,
    marginLeft: 0,
    marginRight: 0,
    padding: isSignNow()
      ? 0
      : 2,

    WrapperComponent: false,
  },
  [elemTypes.signature]: {
    marginTop: 0,
    marginBottom: 0,
    marginLeft: 0,
    marginRight: 0,
    padding: 2,
    WrapperComponent: false,
  },
  [elemTypes.sticky]: {
    marginTop: 30,
    marginBottom: 10,
    marginLeft: 10,
    marginRight: 10,
    padding: 2,
    WrapperComponent: StickyToolView,
  },
  [elemTypes.textbox]: {
    marginTop: 2,
    marginBottom: 2,
    marginLeft: 2,
    marginRight: 2,
    padding: 2,
    WrapperComponent: TextBoxToolView,
  },
  [elemSubTypes.text.overlying]: {
    marginTop: 0,
    marginBottom: 0,
    marginLeft: 0,
    marginRight: 0,
    padding: 2,

    WrapperComponent: {
      isLazy: true,
      component: fakeEdit.components.FakeEditTextToolWrapper,
    },
  },
};
