import { takeEvery, call } from 'redux-saga/effects';
import { actionTypes } from 'ws-editor-lib/actions';
import { saved } from './interfaceSaga';

function* onVersionsSave() {
  yield call(saved);
}

export default function* saveSaga() {
  yield takeEvery(actionTypes.ON_OPERATIONS_VERSIONS_SAVE, onVersionsSave);
}
