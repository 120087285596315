import React from 'react';
import PropTypes from 'prop-types';

const SideBarContent = ({ children }) => {
  return (
    <div className="sidebar__content">
      {children}
    </div>
  );
};

SideBarContent.propTypes = {
  children: PropTypes.node.isRequired,
};

export default SideBarContent;
