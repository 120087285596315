import PropTypes from 'prop-types';
import { Component } from 'react';
import { connect } from 'react-redux';
import once from 'lodash/once';

import * as selectors from 'jsfcore/store/selectors';
import config from 'jsfcore/helpers/clientConfig';
import { thisDevice } from '@pdffiller/jsf-useragent';
import * as lazySelectors from '../store/selectors';
import { modulesJsf as modules } from '../const';
import { generateModulePromise, runAcceptFunc } from '../modulesJsf';
import loadModule, {
  resolveControllerLoaded,
  resolveLoadModuleFunctionsPromise,
} from '../loadModule';
import loadCss from '../loadCss';
import { getDispatch } from '..';

resolveLoadModuleFunctionsPromise([generateModulePromise, runAcceptFunc]);

@connect(
  (state) => {
    return {
      isWizardLoaded: lazySelectors.common.getIsWizardLoaded(state),
      isManagersLoaded: lazySelectors.jsf.getIsManagersLoaded(state),
      isFConstructorLoaded: lazySelectors.common.getIsConstructorLoaded(state),
      isRearrangeLoaded: lazySelectors.jsf.getIsRearrangeLoaded(state),
      isCommentsLoaded: lazySelectors.jsf.getIsCommentsLoaded(state),
      isFakeEditLoaded: lazySelectors.jsf.getIsFakeEditLoaded(state),
      isFormulaLoaded: lazySelectors.common.getIsFormulaLoaded(state),
      isVersionsLoaded: lazySelectors.common.getIsVersionsLoaded(state),
      isVersionsRequested: selectors.base.getIsVersionsRequested(state),
      isFeedbackLoaded: lazySelectors.jsf.getIsFeedbackLoaded(state),
      useNewFeedbackModal: selectors.feature.getUseNewFeedbackModal(state),

      appStarted: selectors.base.getAppStarted(state),
      hasFillableFields: selectors.elements.getHasFillableFields(state),
      isPAConstructorShown: selectors.base.getIsPAConstructorShown(state),
      isFConstructorShown: selectors.base.getIsFConstructorShown(state),
      isCommentsShown: selectors.base.getIsCommentsShown(state),
      needLoadFakeEdit: selectors.fakeEdit.getNeedLoadFakeEdit(state),
    };
  },
)
export default class LazyLoadControllerJsf extends Component {
  static propTypes = {
    children: PropTypes.element.isRequired,
    createReducer: PropTypes.func.isRequired,

    // TODO: remove eslint-disable after remove UNSAFE_componentWillReceiveProps
    /* eslint-disable react/no-unused-prop-types */
    isWizardLoaded: PropTypes.bool.isRequired,
    isManagersLoaded: PropTypes.bool.isRequired,
    isFConstructorLoaded: PropTypes.bool.isRequired,
    isRearrangeLoaded: PropTypes.bool.isRequired,
    isCommentsLoaded: PropTypes.bool.isRequired,
    isFakeEditLoaded: PropTypes.bool.isRequired,
    isFormulaLoaded: PropTypes.bool.isRequired,
    isVersionsLoaded: PropTypes.bool.isRequired,
    isFeedbackLoaded: PropTypes.bool.isRequired,
    useNewFeedbackModal: PropTypes.bool.isRequired,

    appStarted: PropTypes.bool.isRequired,
    hasFillableFields: PropTypes.bool.isRequired,
    isCommentsShown: PropTypes.bool.isRequired,
    needLoadFakeEdit: PropTypes.bool.isRequired,
    /* eslint-enable react/no-unused-prop-types */

    isPAConstructorShown: PropTypes.bool.isRequired,
    isFConstructorShown: PropTypes.bool.isRequired,
    isVersionsRequested: PropTypes.bool.isRequired,
  };

  static contextTypes = {
    store: PropTypes.shape({
      getState: PropTypes.func.isRequired,
      dispatch: PropTypes.func.isRequired,
    }).isRequired,
  };

  constructor(props, context) {
    super(props);

    resolveControllerLoaded({
      createReducer: props.createReducer,
      store: context.store,
    });

    getDispatch(context.store.dispatch);
  }

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillReceiveProps(nextProps) {
    const {
      isWizardLoaded,
      isFConstructorLoaded,
      isManagersLoaded,
      appStarted,
      hasFillableFields,
      isFConstructorShown,
      isPAConstructorShown,
      isRearrangeLoaded,
      isCommentsLoaded,
      isCommentsShown,
      isFakeEditLoaded,
      needLoadFakeEdit,
      isFormulaLoaded,
      isVersionsLoaded,
      isVersionsRequested,
      isFeedbackLoaded,
      useNewFeedbackModal,
    } = nextProps;

    const needLoadFConstructorImmediatly =
      (isFConstructorShown && !this.props.isFConstructorShown) ||
      (isPAConstructorShown && !this.props.isPAConstructorShown);

    const needLoadFConstructor = !thisDevice.isPhone &&
      (needLoadFConstructorImmediatly || appStarted);

    const needLoadVersions =
        isVersionsRequested && !this.props.isVersionsRequested && !isVersionsLoaded;

    const needLoadFeedback =
      useNewFeedbackModal && !isFeedbackLoaded && appStarted;

    if (needLoadFConstructor && !isFConstructorLoaded) {
      this.loadFConstructor();
    }
    if (this.getNeedLoadWizard({ isWizardLoaded, isFConstructorShown, hasFillableFields })) {
      this.loadWizard();
    }
    if (!isManagersLoaded && appStarted) {
      this.loadManagers();
    }
    if (!isRearrangeLoaded && appStarted) {
      this.loadRearrange();
    }
    if (!isCommentsLoaded && isCommentsShown && appStarted) {
      this.loadComments();
    }
    if (!isFakeEditLoaded && needLoadFakeEdit) {
      this.loadFakeEdit();
    }
    if (!isFormulaLoaded) {
      this.loadFormula();
    }
    if (needLoadVersions) {
      this.loadVersions();
    }
    if (needLoadFeedback) {
      this.loadFeedback();
    }
  }

  getNeedLoadWizard = ({ isWizardLoaded, isFConstructorShown, hasFillableFields }) => {
    if (isWizardLoaded) {
      return false;
    }

    return isFConstructorShown || hasFillableFields;
  };

  loadFConstructor = once(() => {
    loadModule(modules.fConstructor);
  });

  loadWizard = once(() => {
    loadModule(modules.wizard);
  });

  loadManagers = once(() => {
    loadModule(modules.managers);
  });

  loadRearrange = once(() => {
    loadModule(modules.rearrange);
  });

  loadComments = once(() => {
    loadModule(modules.comments);
  });

  loadFakeEdit = once(() => {
    loadModule(modules.fakeEdit);
  });

  loadFormula = once(() => {
    loadModule(modules.formula);
  });

  loadVersions = once(() => {
    loadModule(modules.versions);
  });

  loadFeedback = once(() => {
    loadModule(modules.feedback);
    loadCss(config.supportFeedbackCssUrl);
  });

  render() {
    return this.props.children;
  }
}
