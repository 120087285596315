import { sessionsManager } from '@pdffiller/jsf-loader/JSFillerStores';

const store = {};
let counter = 0;

if (__CLIENT__) {
  window.JSFillerStores = { ...window.JSFillerStores, canvasStore: store };
}

const getStore = () => {
  const sessionId = sessionsManager.getCurrentSessionId();

  if (!store[sessionId]) {
    store[sessionId] = {};
  }

  return store[sessionId];
};

const deleteCanvas = (id) => {
  delete getStore()[id];
};

const deleteCanvasesBySessionId = (id) => {
  delete store[id];
};

const canvasStore = {
  getCanvas: (id) => {
    return getStore()[id];
  },

  putCanvas: (canvas) => {
    counter += 1;
    getStore()[counter] = canvas;
    return counter;
  },

  deleteCanvas,

  clear: () => {
    Object.keys(store)
      .forEach(deleteCanvas);
  },

  dump: () => {
    return getStore();
  },
};

sessionsManager.onSessionRemoved(deleteCanvasesBySessionId);

export default canvasStore;
