import React from 'react';
import cls from 'classnames';
import PropTypes from 'prop-types';

export const contentConfiguratorBodyThemes = {
  default: 'default',
  null: null,
};

const ContentConfiguratorBody = ({ theme, children }) => {
  return (
    <div
      className={cls('content-configurator', {
        [`content-configurator--theme--${theme}`]: theme,
      })}
    >
      <div className="content-configurator__body">
        {children}
      </div>
    </div>
  );
};

ContentConfiguratorBody.propTypes = {
  children: PropTypes.node,
  theme: PropTypes.oneOf(Object.values(contentConfiguratorBodyThemes)),
};

ContentConfiguratorBody.defaultProps = {
  children: null,
  theme: contentConfiguratorBodyThemes.null,
};

export default ContentConfiguratorBody;
