import PropTypes from 'prop-types';
import React, { Component } from 'react';
import cls from 'classnames';

export const toolsDropdownSizes = {
  smallStretched: 'small-stretched',
  stretched: 'stretched',
  null: null,
};

export const toolsDropdownPositions = {
  left: 'left',
  top: 'top',
  null: null,
};

export const toolsDropdownOffsets = {
  medium: 'medium',
  null: null,
};

export default class ToolsDropdown extends Component {
  static propTypes = {
    children: PropTypes.node,

    size: PropTypes.oneOf(
      Object.values(toolsDropdownSizes),
    ),
    position: PropTypes.oneOf(
      Object.values(toolsDropdownPositions),
    ),
    offset: PropTypes.oneOf(
      Object.values(toolsDropdownOffsets),
    ),

    isVisible: PropTypes.bool,

    storeRef: PropTypes.func,
  };

  static defaultProps = {
    children: null,

    size: toolsDropdownSizes.null,
    position: toolsDropdownPositions.null,
    offset: toolsDropdownOffsets.null,

    isVisible: true,

    storeRef: null,
  };

  render() {
    const { size, position, offset } = this.props;

    return (
      <div
        ref={this.props.storeRef}
        className={cls('tools-dropdown', {
          'is-visible': this.props.isVisible,
          [`tools-dropdown--size--${size}`]: size,
          [`tools-dropdown--position--${position}`]: position,
          [`tools-dropdown--offset--${offset}`]: offset,
        })}
      >
        {this.props.children}
      </div>
    );
  }
}
