import PropTypes from 'prop-types';
import React from 'react';

const MenuListItem = ({ children, storeRef, ...props }) => {
  return (
    <li
      {...props}
      ref={storeRef}
      className="menu__list-item"
    >
      {children}
    </li>
  );
};

MenuListItem.propTypes = {
  children: PropTypes.node.isRequired,
  storeRef: PropTypes.func,
};

MenuListItem.defaultProps = {
  storeRef: null,
};

export default MenuListItem;
