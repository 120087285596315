import PropTypes from 'prop-types';
import React from 'react';

const TodoListItemContent = ({ children }) => {
  return (
    <div className="todo-item__content">
      <div className="todo-item__content-text">
        {children}
      </div>
    </div>
  );
};

TodoListItemContent.propTypes = {
  children: PropTypes.node,
};

TodoListItemContent.defaultProps = {
  children: null,
};

export default TodoListItemContent;
