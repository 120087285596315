import get from 'lodash/get';

import { isDropdownElement, isCustomDropdownValueSelected } from './functions';
import isSignNow from '../../helpers/const/isSignNow';
import { unformat } from '../../jsf-validations/utils/formatUnformat';

const dropdownBeforeUpdateSnf = () => {
  return (element) => {
    return element;
  };
};

const dropdownBeforeUpdateJsf = () => {
  return (element) => {
    if (isCustomDropdownValueSelected(element)) {
      return {
        ...element,
        content: {
          ...element.content,
          customDropdownValueSelected: true,
        },
      };
    }

    return element;
  };
};

const dropdownBeforeUpdate =
  isSignNow()
    ? dropdownBeforeUpdateSnf
    : dropdownBeforeUpdateJsf;

const hasContentTextInSNFiller = (el) => {
  return isSignNow() && get(el, 'content.text', false);
};

const textFieldBeforeStartEditing = () => {
  return (el) => {
    return {
      ...el,
      content: {
        ...el.content,
        text: unformat(el.content.text, el),
      },
    };
  };
};

// eslint-disable-next-line
export const preprocessBeforeUpdate = [{
  statementFunction: isDropdownElement,
  preprocessFunction: dropdownBeforeUpdate,
}, {
  statementFunction: hasContentTextInSNFiller,
  preprocessFunction: textFieldBeforeStartEditing,
}, {
  statementFunction: () => {
    return true;
  },
  preprocessFunction: () => {
    return (element) => {
      return element.content;
    };
  },
}];
