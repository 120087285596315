import PropTypes from 'prop-types';
import React from 'react';
import Select from 'react-select-pdffiller';
import classNames from 'classnames';

import omit from 'lodash/omit';
import {
  Wrapper,
  DetectScrollProvider,
  wrapperPresets,
  wrapperPositions,
} from '../Portal';

const selectMultiValueWrapperStyles = {
  height: '100%',
};

class ReactSelectOverridden extends Select {
  static propTypes = {
    ...Select.propTypes,
    locatorArgs: PropTypes.oneOfType([
      PropTypes.shape({}),
      PropTypes.bool,
    ]),
    wrapperProps: PropTypes.shape(
      omit(Wrapper.propTypes, ['getRef', 'children']),
    ),
    selectMenuOuterClassName: PropTypes.string,
    selectMenuClassName: PropTypes.string,
    selectAttributes: PropTypes.shape({}),
    storeRefSelectControl: PropTypes.func,
    onFocusSelectControl: PropTypes.func,
    onBlurSelectControl: PropTypes.func,
  };

  static defaultProps = {
    ...Select.defaultProps,
    locatorArgs: {
      preset: wrapperPresets.hint,
      position: wrapperPositions.bottomCenter,
    },
    selectMenuOuterClassName: 'Select-menu-outer',
    selectMenuClassName: 'Select-menu',
    selectAttributes: null,
    storeRefSelectControl: null,
    onFocusSelectControl: null,
    onBlurSelectControl: null,
  };

  storeRefForMenu = (ref) => {
    this.menu = ref;
  };

  storeMenuContainer = (ref) => {
    this.menuContainer = ref;
  };

  storeControlRef = (ref) => {
    this.control = ref;
    // storing ref for FocusController
    if (this.props.storeRefSelectControl) {
      this.props.storeRefSelectControl(ref);
    }
  };

  getMenuRef = () => {
    return this.menu;
  };

  getWrapperRef = () => {
    return this.control;
  };

  scrollProviderHandler = () => {
    this.setState({
      isOpen: false,
    });
  };

  // Override the necessary methods
  // Don't delete!!!
  handleTouchOutside = () => {
    // do nothing
    return null;
  };

  // open menu method (used in DropdownTool)
  openMenu = () => {
    this.setState({
      isOpen: true,
      isPseudoFocused: false,
    });
  }

  renderOuter(options, valueArray, focusedOption) {
    const menu = this.renderMenu(options, valueArray, focusedOption);
    if (!menu) {
      return null;
    }

    return (
      <Wrapper
        useTriggerWidth
        locatorArgs={this.props.locatorArgs}
        {...this.props.wrapperProps}
        getRef={this.getWrapperRef}
      >
        <div
          ref={this.storeMenuContainer}
          className={this.props.selectMenuOuterClassName}
          data-border-radius="unset"
        >
          <div
            ref={this.storeRefForMenu}
            className={this.props.selectMenuClassName}
            /* eslint-disable no-underscore-dangle */
            id={`${this._instancePrefix}-list`}
            onMouseDown={this.handleMouseDownOnMenu}
            onScroll={this.handleMenuScroll}
            role="listbox"
            tabIndex="-1"
          >
            {menu}
          </div>
          <DetectScrollProvider
            getRef={this.getWrapperRef}
            onScroll={this.scrollProviderHandler}
          />
        </div>
      </Wrapper>
    );
  }

  render() {
    // https://github.com/pdffiller/react-select/blob/master/src/Select.js#L1121
    /* eslint-disable */
    let valueArray = this.getValueArray(this.props.value);
    let options = this._visibleOptions = this.filterOptions(this.props.multi && this.props.removeSelected ? valueArray : null);
    let isOpen = this.state.isOpen;
    if (this.props.multi && !options.length && valueArray.length && !this.state.inputValue) isOpen = false;
    const focusedOptionIndex = this.getFocusableOptionIndex(valueArray[0]);

    let focusedOption = null;
    if (focusedOptionIndex !== null) {
      focusedOption = this._focusedOption = options[focusedOptionIndex];
    } else {
      focusedOption = this._focusedOption = null;
    }
    let className = classNames('Select', this.props.className, {
      'has-value': valueArray.length,
      'is-clearable': this.props.clearable,
      'is-disabled': this.props.disabled,
      'is-focused': this.state.isFocused,
      'is-loading': this.props.isLoading,
      'is-open': isOpen,
      'is-pseudo-focused': this.state.isPseudoFocused,
      'is-searchable': this.props.searchable,
      'Select--multi': this.props.multi,
      'Select--rtl': this.props.rtl,
      'Select--single': !this.props.multi,
    });

    let removeMessage = null;
    if (this.props.multi &&
      !this.props.disabled &&
      valueArray.length &&
      !this.state.inputValue &&
      this.state.isFocused &&
      this.props.backspaceRemoves) {
      removeMessage = (
        <span id={`${this._instancePrefix}-backspace-remove-message`} className="Select-aria-only" aria-live="assertive">
          {this.props.backspaceToRemoveMessage.replace('{label}', valueArray[valueArray.length - 1][this.props.labelKey])}
        </span>
      );
    }

    return (
      <div
         ref={ref => this.wrapper = ref}
         className={className}
         style={this.props.wrapperStyle}
         {...this.props.selectAttributes}
       >
        {this.renderHiddenField(valueArray)}
        <div
          ref={this.storeControlRef}
          className="Select-control"
          style={this.props.style}
          onKeyDown={this.handleKeyDown}
          onMouseDown={this.handleMouseDown}
          onTouchEnd={this.handleTouchEnd}
          onTouchMove={this.handleTouchMove}
          onTouchStart={this.handleTouchStart}
          // for FocusController
          onFocus={this.props.onFocusSelectControl}
          onBlur={this.props.onBlurSelectControl}
        >
          <span
            className="Select-multi-value-wrapper"
            id={`${this._instancePrefix}-value`}
            style={selectMultiValueWrapperStyles}
          >
            {this.renderValue(valueArray, isOpen)}
            {this.renderInput(valueArray, focusedOptionIndex)}
          </span>
          {removeMessage}
          {this.renderLoading()}
          {this.renderClear()}
          {this.renderArrow()}
        </div>
        {isOpen ? this.renderOuter(options, valueArray, focusedOption) : null}
      </div>
    );
    /* eslint-enable */
  }
}

export default ReactSelectOverridden;
