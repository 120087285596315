/* eslint-disable no-param-reassign, no-underscore-dangle */

import flow from 'lodash/flow';
import get from 'lodash/get';
import find from 'lodash/find';
import isEmpty from 'lodash/isEmpty';
import queryString from 'query-string';

import {
  TIME_WAIT_AUTH,
  MAX_RECONNECT_IN_A_ROW_COUNT,
  MAX_RECONNECT_DURING_SESSION_COUNT,
  TIME_FOR_RECONNECT,
  QUERY_PARAMETERS,
  OPERATION_SUB_TYPES,
  GROUPS,
  TYPES,
  TOOL_TYPES,
  POST_MESSAGES,
  DESTROY_FAIL_ERROR,
} from '../../constants';
import {
  getTimeStampNow, createFlatId, createGenerateDevURLfunc,
} from '../../store/models/Operations/Utils';
import {
  getUserAndAPIHash,
  getParameterByName,
  getHostname,
  isLocalhost,
  isEmbedded,
  sendPostMessage,
} from '../../store/helpers';
import * as actions from '../../actions';
import config from '../../config';
import { getFilteredOperations, getIsAirSlate } from './netControllerUtils';
import { mapFromBackend, mapToBackend } from './mapper';

import getOptimizeAndSend from './optimizeAndSend';

const ACCESS_TIMER = 60;
const ACCESS_TIMER_CANCEL_DELAY = 1000 * 60 * 10; // 10 min
const ACCESS_TIMER_UPDATE_DELAY = 1000;
const DEFAULT_ID = -1;

const changeTokenTimeoutExpire = 20000; // 20s, JSF-3121

const pingConfig = {
  reconnectTimeout: 10000,
  sendInterval: 5000,
};

export default (websocketClient, store, options = {}) => {
  let { dispatch } = store;
  const storeDispatch = dispatch;
  let savedTimerId;
  let introTimerId;
  let authTimerId;
  let accessTimerId;
  let accessCancelTimerId = null;
  let accessSecTimerId;
  let reconnectTimerId;
  let destroySent = false;
  let wsErrorSent = false;

  let reconnectInARowCounter = 0;
  let reconnectDuringSessionCounter = 0;
  let accessTimer = ACCESS_TIMER;
  let isAuthReceivedFromServer = false;
  let changeTokenTimeout = null;

  let notConfirmedOperations = {};

  const send = websocketClient.send.bind(websocketClient);

  const dispatchDestroy = () => {
    const state = store.getState();
    const isAirSlate = getIsAirSlate(state);
    if (isAirSlate) {
      sendPostMessage(POST_MESSAGES.unexpectedDestroy);
      return;
    }

    const { loginUrl } = state.ws.access;
    dispatch(actions.destroy(loginUrl));
  };

  const checkSession = (pkg) => {
    const { viewerId, apiHash } = getUserAndAPIHash();
    const token = getParameterByName(QUERY_PARAMETERS.token);

    if (
      !token &&
      websocketClient.apiHash !== apiHash &&
      !pkg.destroy &&
      !destroySent &&
      changeTokenTimeout === null
    ) {
      const state = store.getState();
      if (viewerId === state.ws.viewerId) {
        dispatch(actions.trackPoint('TOKEN_CHANGED'));
        websocketClient.sendChangedToken(apiHash);
      } else {
        dispatchDestroy();
      }
    }

    return pkg;
  };

  const saveDestroy = (pkg) => {
    if (get(pkg, 'destroy', false)) {
      destroySent = true;
    }
    return pkg;
  };

  const resetDestroy = () => {
    destroySent = false;
  };

  websocketClient.store = store;
  websocketClient.suspended = false;
  websocketClient.standby = () => {
    websocketClient.suspended = true;
    dispatch = () => {
      return {};
    };
  };

  websocketClient.resume = () => {
    websocketClient.suspended = false;
    dispatch = storeDispatch;
  };


  websocketClient.resetReconnectInARowCounter = () => {
    reconnectInARowCounter = 0;
  };

  websocketClient.send = flow([checkSession, saveDestroy, send]);

  websocketClient.sendTrackPoint = send;

  websocketClient.setAuth = (
    projectId,
    viewerId,
    sessionHash,
    apiHash,
    deviceProps = {},
    cfg,
    geoData,
    urlParams,
    _host,
    location,
    organization,
  ) => {
    deviceProps.userAgent = navigator.userAgent;
    websocketClient.projectId = projectId || DEFAULT_ID;
    websocketClient.viewerId = viewerId || DEFAULT_ID;
    websocketClient.sessionHash = sessionHash;
    websocketClient.apiHash = apiHash;
    websocketClient.device = deviceProps;
    websocketClient.operationsCounter = 0;
    websocketClient.config = cfg;
    websocketClient.referrer = document.referrer;
    websocketClient.ipAddress = geoData && 'ip' in geoData
      ? geoData.ip
      : '';
    websocketClient.urlParams = urlParams;
    websocketClient._host = _host;
    websocketClient.location = location;
    websocketClient.organization = organization;

    if (cfg.debug && __CLIENT__) {
      window.wsClient = websocketClient;
    }
    if (__CLIENT__) {
      window.generateDevURL = createGenerateDevURLfunc(websocketClient);
    }
  };

  websocketClient.getDomain = () => {
    return websocketClient.domain;
  };

  websocketClient.setDomain = (d) => {
    websocketClient.domain = d;
  };

  websocketClient.getEndPoints = () => {
    return websocketClient.endPoints;
  };

  websocketClient.setEndPoints = (endPoints) => {
    websocketClient.endPoints = endPoints;
  };

  websocketClient.setSession = (clientId, modeId) => {
    websocketClient.clientId = clientId;
    websocketClient.modeId = modeId;
  };

  websocketClient.getTimezoneOffset = () => {
    return (new Date().getTimezoneOffset() / 60);
  };

  websocketClient.getAuthProps = (checkAuth = true) => {
    const qs = queryString.parse(window.location.search);

    const properties = {
      projectId: websocketClient.projectId,
      viewerId: websocketClient.viewerId,
      location: websocketClient.location,
      sessionHash: websocketClient.sessionHash,
      clientType: options.clientType || 'js',
      mode: options.mode || 'edit',
      token: options.token || 'galleries',
      launch: options.launch || qs.launch || GROUPS.editor,
      api_hash: websocketClient.apiHash,
      device: websocketClient.device,
      timestamp: getTimeStampNow(),
      checkAuth,
      referrer: websocketClient.referrer,
      ipAddress: websocketClient.ipAddress,
      urlParams: websocketClient.urlParams,
      allowExtraData: [
        TOOL_TYPES.formula,
        TOOL_TYPES.dropdown,
      ],
    };


    if (getHostname() && !isLocalhost()) {
      properties._host = `${getHostname()}/`;
    }

    if (websocketClient._host) {
      properties._host = websocketClient._host;
    }

    if (getParameterByName('_host')) {
      properties._host = getParameterByName('_host');
    }

    if (websocketClient.clientId) {
      properties.clientId = Number(websocketClient.clientId);
      properties.confirmedOps = websocketClient.operationsCounter;
    }

    if (websocketClient.organization) {
      properties.organization = websocketClient.organization;
    }

    return properties;
  };

  websocketClient.sendAuth = (checkAuth = false) => {
    if (websocketClient.suspended) {
      return;
    }

    const auth = { auth: { properties: websocketClient.getAuthProps(checkAuth) } };

    clearTimeout(authTimerId);

    // todo когда приходят много раз busy это тоже срабатывает
    websocketClient.send(auth);
    authTimerId = setTimeout(() => {
      authTimerId = null;
      // accessTimerId !== null in busy access status
      // sendAuth will be called without reconnect by timeout in websocketClient.runGetAccessTimer
      // https://pdffiller.atlassian.net/browse/JSF-5558
      if (!isAuthReceivedFromServer && !accessTimerId) {
        websocketClient.tryReconnect();
      }
    }, TIME_WAIT_AUTH, store);
  };

  websocketClient.setOperationsCount = (count, confirmedOps) => {
    if (websocketClient.operationsCounter !== confirmedOps && confirmedOps === 0) {
      websocketClient.operationsCounter = count;
    } else {
      websocketClient.operationsCounter += count;
    }
    dispatch(actions.setConfirmedOps(websocketClient.operationsCounter));
  };

  websocketClient.sendChangedToken = (token) => {
    changeTokenTimeout = setTimeout(() => {
      changeTokenTimeout = null;
      dispatchDestroy();
    }, changeTokenTimeoutExpire);

    const tokenOperation = {
      id: {
        clientId: 0,
        localId: 1,
      },

      properties: {
        group: GROUPS.editor,
        type: QUERY_PARAMETERS.token,
        subType: OPERATION_SUB_TYPES.change,
        token,
      },
    };

    websocketClient.sendOperation(tokenOperation);
  };

  websocketClient.sendGetAccess = () => {
    const accessOperation = {
      id: {
        clientId: 0,
        localId: 1,
      },
      properties: {
        group: GROUPS.document,
        type: TYPES.access,
        subType: OPERATION_SUB_TYPES.request,
        authProps: websocketClient.getAuthProps(),
      },
    };

    websocketClient.sendOperation(accessOperation);
  };

  websocketClient.stopGetAccessTimer = () => {
    clearTimeout(accessTimerId);
    clearInterval(accessSecTimerId);
    clearTimeout(accessCancelTimerId);
    accessTimerId = null;
    accessSecTimerId = null;
    accessCancelTimerId = null;
  };

  websocketClient.runGetAccessTimer = () => {
    clearTimeout(accessTimerId);
    clearInterval(accessSecTimerId);

    accessTimerId = setTimeout(() => {
      accessTimerId = null;
      websocketClient.sendAuth(true);
      clearInterval(accessSecTimerId);
      accessSecTimerId = null;
    }, (ACCESS_TIMER * 1000) + 1000);

    if (!accessCancelTimerId) {
      accessCancelTimerId = setTimeout(() => {
        websocketClient.stopGetAccessTimer();
        websocketClient.disconnect();
        websocketClient.connectionLost();
      }, ACCESS_TIMER_CANCEL_DELAY);
    }

    accessTimer = ACCESS_TIMER;

    accessSecTimerId = setInterval(() => {
      dispatch(actions.timerTick(accessTimer));
      accessTimer -= 1;
    }, ACCESS_TIMER_UPDATE_DELAY);
  };

  const postSendCallback = ({ operations }) => {
    operations.forEach((op) => {
      if (op.id) {
        notConfirmedOperations[createFlatId(op.id)] = op;
      }
    });

    const allInitials = operations.findIndex((op) => {
      return !op || !op.properties || !op.properties.initial;
    }) === -1;

    if (!allInitials) {
      clearTimeout(savedTimerId);
      clearTimeout(introTimerId);

      setTimeout(() => {
        dispatch(actions.changeMessage(actions.messageTypes.SAVING));
      }, 0);
      savedTimerId = setTimeout(() => {
        dispatch(actions.changeMessage(actions.messageTypes.SAVED));
        introTimerId = setTimeout(() => {
          dispatch(actions.changeMessage(actions.messageTypes.INTRO));
        }, config.timeout.intro);
      }, config.timeout.saved);
    }
  };

  websocketClient.optimizeAndSend = getOptimizeAndSend(websocketClient, postSendCallback);

  websocketClient.sendOperations = (operations) => {
    const filteredOps = getFilteredOperations(operations, store.getState());
    filteredOps.forEach((op) => {
      if (!isEmpty(op.properties.content) && !op.properties.content.owner) {
        op.properties.content.owner = websocketClient.viewerId;
      }
    });

    const mappedOperations = mapToBackend(filteredOps);
    websocketClient.optimizeAndSend(mappedOperations);
  };

  websocketClient.sendOperation = (operation) => {
    if (operation.properties.group === GROUPS.tools) {
      if (
        operation.properties.type === TOOL_TYPES.text &&
        operation.properties.content && !operation.properties.content.text
      ) {
        operation.properties.content.text = '';
      }
    }

    if (typeof operation.id === 'string') {
      operation.id = {
        clientId: Number(operation.id.split('-')[0]),
        localId: Number(operation.id.split('-')[1]),
      };
    }

    if (operation.properties.element && typeof operation.properties.element === 'string') {
      operation.properties.element = {
        clientId: Number(operation.properties.element.split('-')[0]),
        localId: Number(operation.properties.element.split('-')[1]),
      };
    }

    delete operation.properties.id;

    websocketClient.sendOperations([operation]);
  };

  websocketClient.on('CONNECT', () => {
    dispatch(actions.serverConnect());
    if (isEmbedded()) {
      websocketClient.startPing(pingConfig);
      websocketClient.sendAuth();
    }
  });

  websocketClient.connectionLost = (code) => {
    // eslint-disable-next-line no-console
    console.log('ws connection lost', code);
    clearTimeout(reconnectTimerId);
    reconnectTimerId = null;
    clearTimeout(authTimerId);
    authTimerId = null;
    clearTimeout(savedTimerId);
    savedTimerId = null;
    clearTimeout(introTimerId);
    introTimerId = null;
    dispatch(actions.serverDisconnect(code));
  };

  websocketClient.tryReconnect = (code) => {
    if (reconnectInARowCounter < MAX_RECONNECT_IN_A_ROW_COUNT) {
      if (!reconnectTimerId) {
        reconnectInARowCounter += 1;
        reconnectDuringSessionCounter += 1;
        reconnectTimerId = setTimeout(() => {
          // eslint-disable-next-line no-console
          console.log('reconnect', reconnectDuringSessionCounter);
          reconnectTimerId = null;
          isAuthReceivedFromServer = false;
          websocketClient.stopGetAccessTimer();
          dispatch(actions.reconnect(websocketClient.config, reconnectInARowCounter));
          websocketClient.connect(websocketClient.config, true);
        }, TIME_FOR_RECONNECT);
      }
    } else {
      websocketClient.connectionLost(code);
    }
  };

  websocketClient.on('CLOSE', (code, serverClose) => {
    const state = store.getState();
    const errorList = get(state, 'ws.errorList', []);

    const gotErrorDuringAuth = !isAuthReceivedFromServer &&
      !isEmpty(errorList);
    const isNeedToLogError = (
      reconnectInARowCounter === MAX_RECONNECT_IN_A_ROW_COUNT ||
      reconnectDuringSessionCounter === MAX_RECONNECT_DURING_SESSION_COUNT
    );

    if (!destroySent && !gotErrorDuringAuth) {
      websocketClient.tryReconnect(code, serverClose);

      if (!wsErrorSent) {
        dispatch(actions.setWsConnectionFailed({
          userAgent: navigator.userAgent,
          cookie: document.cookie,
          projectId: websocketClient.projectId,
          viewerId: websocketClient.viewerId,
          sessionHash: websocketClient.sessionHash,
          apiHash: websocketClient.apiHash,
          device: websocketClient.device,
          config: websocketClient.config,
          referrer: websocketClient.referrer,
          ipAddress: websocketClient.ipAddress,
          urlParams: websocketClient.urlParams,
          location: websocketClient.location,
          closeCode: code.code,
        }, isNeedToLogError));

        if (isNeedToLogError) {
          wsErrorSent = true;
        }
      }
    }
  });

  websocketClient.on('ERROR', () => {
    websocketClient.tryReconnect();
  });

  websocketClient.on('SERVER_ERROR', (err) => {
    if (err && err.error) {
      err = err.error;
    }
    const state = store.getState();
    const isAirSlate = getIsAirSlate(state);
    if (isAirSlate && err.message === DESTROY_FAIL_ERROR) {
      const errorData = {
        errorMessage: err.options.errorMessage,
        statusCode: err.options.statusCode,
      };
      sendPostMessage(errorData);
    }
    dispatch(actions.serverError(err));
  });

  websocketClient.on('AUTH', (auth) => {
    if (reconnectDuringSessionCounter < MAX_RECONNECT_DURING_SESSION_COUNT) {
      clearTimeout(reconnectTimerId);
      websocketClient.resetReconnectInARowCounter();
      resetDestroy();
      websocketClient.setEndPoints(auth.settings);

      isAuthReceivedFromServer = true;

      const operations = Object.values(notConfirmedOperations);
      notConfirmedOperations = {};

      if (auth.confirmedOps < websocketClient.operationsCounter + operations.length) {
        websocketClient.sendOperations(operations);
      }

      dispatch(actions.authReceive(auth));
    } else {
      dispatchDestroy();
    }
  });


  websocketClient.on('OPERATIONS', (operations) => {
    if (changeTokenTimeout) {
      const tokenOperation = find(operations, (operation) => {
        return (
          operation.properties.type === QUERY_PARAMETERS.token &&
          operation.properties.subType === OPERATION_SUB_TYPES.change
        );
      });
      if (tokenOperation) {
        websocketClient.apiHash = tokenOperation.properties.token;
        clearTimeout(changeTokenTimeout);
        changeTokenTimeout = null;
      }
    }
    operations.forEach((op) => {
      if (op.id && notConfirmedOperations[createFlatId(op.id)] && op.confirmed) {
        delete notConfirmedOperations[createFlatId(op.id)];
      }
    });

    // Добавлено в задаче https://pdffiller.atlassian.net/browse/JSF-5323,
    // чтобы избежать reconnect приложения после destroy
    if (destroySent) {
      // eslint-disable-next-line no-console
      console.warn('app destroyed with skipping next actions');
    } else {
      const mappedOperations = mapFromBackend(operations);
      dispatch(actions.operationReceive(mappedOperations));
    }
  });

  websocketClient.on('DESTROY', (location, force, params = {}) => {
    if (force || destroySent || !isAuthReceivedFromServer) {
      clearTimeout(reconnectTimerId);
      reconnectTimerId = null;
      clearTimeout(authTimerId);
      authTimerId = null;
      clearTimeout(savedTimerId);
      savedTimerId = null;
      clearTimeout(introTimerId);
      introTimerId = null;
      clearTimeout(changeTokenTimeout);
      changeTokenTimeout = null;
      // https://pdffiller.atlassian.net/browse/JSF-5668
      // notConfirmedOperations clearing is need to avoid sending duplicate operations
      // on airSlate document switch
      notConfirmedOperations = {};
      dispatch(actions.destroyReceive({ location, force, params }));
    }
  });

  return websocketClient;
};
