import React from 'react';
import PropTypes from 'prop-types';

const FillableWizardHeader = ({ children }) => {
  return (
    <div className="fillable-wizard__header">
      {children}
    </div>
  );
};

FillableWizardHeader.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.arrayOf(PropTypes.element),
  ]).isRequired,
};

export default FillableWizardHeader;
