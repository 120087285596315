import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

export const customSettingsOffsets = {
  smallVertical: 'small-vertical',
  large: 'large',
  null: null,
};

const CustomSettings = ({
  children,
  offset,
  separated,
}) => {
  return (
    <div
      className={classnames('custom-settings', {
        [`custom-settings--offset--${offset}`]: offset,
        'custom-settings--type--separated': separated,
      })}
    >
      <div className="custom-settings__body">
        {children}
      </div>
    </div>
  );
};

CustomSettings.propTypes = {
  children: PropTypes.node.isRequired,
  offset: PropTypes.oneOf(Object.values(customSettingsOffsets)),
  separated: PropTypes.bool,
};

CustomSettings.defaultProps = {
  offset: customSettingsOffsets.null,
  separated: false,
};

export default CustomSettings;
