import {
  send,
  createFlatId,
  createId,
  getNextLocalId,
} from '../Operations/Utils';
import { GROUPS, TYPES } from '../../../constants';

// DEPRECATED
export default function doUpdateTodoList(transport, state, action) {
  const { defaults } = state;
  const { todoListState } = action;

  const sendOperationsCount = getNextLocalId(state.sendOperationsCount);
  const id = createFlatId(createId(sendOperationsCount));

  send(transport, id, {
    todoListState,
    group: GROUPS.editor,
    type: TYPES.defaults,
  });

  return {
    defaults: {
      ...defaults,
      todoListState,
    },
    sendOperationsCount,
  };
}
