import { appendScriptTag } from 'jsfcore/helpers/native';
import config from 'jsfcore/helpers/clientConfig';

// eslint-disable-next-line import/prefer-default-export
export function initializeSksTracker() {
  if (config.app.sksTrackerUrl) {
    appendScriptTag(config.app.sksTrackerUrl, true, () => {
      if (window.sksTracker) {
        window.sksTracker.trackEdit();
      }
    });
  }
}
