export default {
  features: {
    wizard: {
      hidePointerInFullWizard: false,
    },
    extrasbar: [
      {
        id: 'tools.template',
        label: 'Constructor',
        visible: false,
      },
      {
        id: 'versions',
        label: 'Versions',
        visible: false,
      },
      {
        id: 'tools.attributes',
        label: 'Watermark',
        visible: false,
      },
    ],
    toolbar: [
      {
        id: 'tools.text.none',
        label: 'Text',
        visible: true,
      },
      {
        id: 'tools.checkmark.x',
        label: 'Cross',
        visible: true,
      },
      {
        id: 'tools.checkmark.v',
        label: 'Check',
        visible: true,
      },
      {
        id: 'tools.checkmark.o',
        label: 'Circle',
        visible: true,
      },
      {
        id: 'tools.signature.*',
        label: 'Sign',
        visible: true,
      },
      {
        id: 'tools.text.date',
        label: 'Date',
        visible: true,
      },
      {
        id: 'tools.image.none',
        label: 'Image',
        visible: true,
      },
      {
        id: 'tools.erase.none',
        label: 'Erase',
        visible: true,
      },
      {
        id: 'tools.highlight.none',
        label: 'Highlight',
        visible: true,
      },
      {
        id: 'tools.blackout.none',
        label: 'Blackout',
        visible: true,
      },
      {
        id: 'tools.textbox.none',
        label: 'Text Box',
        visible: true,
      },
      {
        id: 'tools.arrow.none',
        label: 'Arrow',
        visible: true,
      },
      {
        id: 'tools.line.none',
        label: 'Line',
        visible: true,
      },
      {
        id: 'tools.pen.none',
        label: 'Draw',
        visible: true,
      },
      {
        id: 'tools.sticky.none',
        label: 'Sticky',
        visible: true,
      },
    ],
  },
};
