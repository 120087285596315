import { destroy, trackPoint, activateTool, checkExternalValidation } from 'ws-editor-lib/actions';
import { DONE_BUTTON_DESTROY_TYPE } from 'ws-editor-lib/actions/constants';
import setActiveElement from './setActiveElement';
import * as selectors from '../selectors/selectors';
import { getQSParams } from '../../helpers/utils';
import { onDoneScenarioString, IFRAME } from '../../helpers/const';
import { showChoiceIframe, hideChoiceIframe } from '../modules/viewport';
import { triggerOnDoneScenario } from '../modules/events';
import showDropdownIsEmptyModal from './showDropdownIsEmptyModal';

export const DONE_ID = 'done';
export const DONE_IRS_ID = 'done.irs';
export const LOGO_ID = 'logo';

let handleMessageAddEventListener = null;

export const handleDestroyOnButtonClick = ({ inputLocation, id, blankLocation, destroyType }) => {
  return (dispatch, getState) => {
    // get data from selectors
    const state = getState();
    const doneButton = selectors.base.getDoneButton(state);

    // get location
    const location = inputLocation || doneButton.main.location;

    if (blankLocation && window) {
      window.windowOnDestroy = window.open('', '_blank');
      if (window.windowOnDestroy) {
        window.windowOnDestroy.document.write('Loading... Please wait');
        window.windowOnDestroy.url = blankLocation;
      }
    }

    // destroy handler
    const destroyHandler = (destroyLocation) => {
      if (destroyLocation === onDoneScenarioString) {
        dispatch(triggerOnDoneScenario());
      } else {
        dispatch(destroy(destroyLocation, true, destroyType));
      }
    };

    // handleMessageAddEventListener
    if (!handleMessageAddEventListener) {
      handleMessageAddEventListener = (message) => {
        if (typeof message.data !== 'object') {
          return;
        }
        if (message.data.action === 'choiceClose') {
          dispatch(hideChoiceIframe());
        }
        if (message.data.action === 'choiceDone') {
          destroyHandler(message.data.location);
        }
        window.removeEventListener('message', handleMessageAddEventListener);
      };
    }
    const emptyDropDownElements = selectors.getEmptyDropDownElements(state);
    const isFConstructorShown = selectors.base.getIsFConstructorShown(state);
    if (emptyDropDownElements.length > 0 && isFConstructorShown) {
      dispatch(showDropdownIsEmptyModal(emptyDropDownElements[0]));
      return;
    }
    dispatch(trackPoint('EXIT', { action: id }));

    const { type, subType } = selectors.getDefaultTool(state);
    dispatch(activateTool(type, subType));

    if (doneButton.main.target === IFRAME && (id === DONE_ID || id === LOGO_ID)) {
      dispatch(showChoiceIframe());
      window.addEventListener('message', handleMessageAddEventListener);

      return;
    }

    destroyHandler(location);
  };
};

const onDoneButtonOptionClickFactory = ({
  inputLocation,
  id = DONE_ID,
  blankLocation = null,
  destroyType,
} = {}) => {
  return (dispatch, getState) => {
    return (event) => {
      event.preventDefault();

      const activeElement = selectors.elements.getActiveElement(getState());
      if (activeElement) {
        setActiveElement(activeElement.id, false)(dispatch, getState);
      }

      const { externalValidation: externalValidationMode } = getQSParams();
      if (
        !!externalValidationMode &&
        destroyType === DONE_BUTTON_DESTROY_TYPE &&
        (id === DONE_ID || id === DONE_IRS_ID)
      ) {
        dispatch(checkExternalValidation(
          externalValidationMode,
          { inputLocation, id, blankLocation, destroyType },
        ));
      } else {
        dispatch(handleDestroyOnButtonClick({ inputLocation, id, blankLocation, destroyType }));
      }
    };
  };
};

export default onDoneButtonOptionClickFactory;
