import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import * as Ui from '../../ui';
import * as modalsSelectors from '../selectors';
import * as types from '../types';
import { closeModal } from '../store/modalsActions';

// create function for get DI modules in runtime
const getLocales = () => {
  const locales = {
    [types.warningTypes.removeWarning]: () => {
      return {
        header: 'Cannot remove field',
        body: `
          This field is referenced as a dependency of another field.
          Please remove all conditional references to other fields and try again.
        `,
        select: 'FORMS.OK',
        areaLabelSelect: 'OK',
      };
    },
    [types.warningTypes.changeSignerCrossRolesWarning]: () => {
      return {
        header: 'Cannot Change Role',
        body: `
        This field is referenced as a dependency of another field.
        Please remove all conditional references to other fields and try again.
      `,
        select: 'FORMS.OK',
        areaLabelSelect: 'OK',
      };
    },
    [types.warningTypes.setConditionalOrChangeDBName]: () => {
      return {
        header: 'Oops!',
        body: `
          This name already exists for a conditional field.
          Please set another name for the database field.
        `,
        select: 'FORMS.OK',
        areaLabelSelect: 'OK',
      };
    },
    [types.warningTypes.circularWarning]: ({ name }) => {
      return {
        header: 'Warning',
        body: 'CONDITIONAL_FIELDS.CIRCULAR_DEPENDENCY_ERROR',
        bodyPlurals: [name],
        select: 'FORMS.OK',
        areaLabelSelect: 'OK',
      };
    },
    [types.warningTypes.removePaymentWarning]: () => {
      return {
        header: 'Cannot remove field',
        body: `
          This field is part of a payment request.
          Please update the payment request information and try again.
        `,
        select: 'FORMS.OK',
        areaLabelSelect: 'OK',
      };
    },
    [types.warningTypes.removeFormulaFieldWarning]: () => {
      return {
        header: 'Cannot remove field',
        body: 'CONDITIONAL_FIELDS.REMOVE_FORMULA_FIELD_WARNING',
        select: 'FORMS.OK',
        areaLabelSelect: 'OK',
      };
    },
    [types.warningTypes.preAssignedSignatureError]: () => {
      return {
        header: 'Alert',
        body: 'There was error retrieving your pre-assigned signature. Please try signing later',
        select: 'FORMS.CLOSE',
        areaLabelSelect: 'Close',
      };
    },
    [types.warningTypes.restrictedOptions]: () => {
      return {
        header: 'This is Restisted Options',
        body: `
          The document contains sensitive data. All the export options are not allowed:
          <ul>
            <li>Download</li>
            <li>Email a Copy</li>
            <li>Download with History</li>
            <li>Merge Documents</li>
          </ul>
        `,
        select: 'FORMS.OK',
        areaLabelSelect: 'OK',
      };
    },
  };

  return locales;
};

@connect(
  (state) => {
    return {
      type: modalsSelectors.getWarningType(state),
      opts: modalsSelectors.getWarningOpts(state),
    };
  }, {
    closeModal,
  },
)
class WarningModalProvider extends Component {
  static propTypes = {
    children: PropTypes.func.isRequired,
    closeModal: PropTypes.func.isRequired,
    type: PropTypes.oneOf(
      Object.keys(getLocales()),
    ).isRequired,
    opts: PropTypes.shape({}),
  };

  static defaultProps = {
    opts: null,
  };

  onClose = () => {
    this.props.closeModal(
      types.modalsMap[types.modalTypes.warning],
    );
  };

  onSelect = () => {
    this.props.closeModal(
      types.modalsMap[types.modalTypes.warning],
    );
  };

  render() {
    const locale = getLocales()[this.props.type](this.props.opts);
    return this.props.children({
      onClose: this.onClose,
      header: (
        <Ui.FlexibleBar.Body
          justify={Ui.flexibleBar.justifies.flexEnd}
          offset={Ui.flexibleBar.offsets.medium}
        >
          <Ui.FlexibleBar.Cell attributes={Ui.attributes.grow}>
            <Ui.Group.Index offset={Ui.group.offsets.smallHorizontal}>
              <Ui.Group.Body>
                <Ui.Group.Item>
                  <Ui.Text id={locale.header} size={Ui.text.sizes[18]} bold />
                </Ui.Group.Item>
              </Ui.Group.Body>
            </Ui.Group.Index>
          </Ui.FlexibleBar.Cell>
        </Ui.FlexibleBar.Body>
      ),
      footer: (
        <Ui.FlexibleBar.Body
          justify={Ui.flexibleBar.justifies.center}
          offset={Ui.flexibleBar.offsets.medium}
        >
          <Ui.FlexibleBar.Cell>
            <Ui.Group.Index
              offset={Ui.group.offsets.small}
            >
              <Ui.Group.Body>
                <Ui.Group.Item>
                  <Ui.EditorAction.Index size={Ui.editorAction.sizes.small}>
                    <Ui.EditorAction.Item>
                      <Ui.Control
                        onClick={this.onSelect}
                        offset={Ui.control.offsets.medium}
                        size={Ui.control.sizes.smallStretched}
                        theme={Ui.control.themes.primary}
                        behavior={Ui.control.behaviors.null}
                        justify={Ui.control.justifies.null}
                        role={Ui.control.roles.null}
                        ariaLabel={locale.areaLabelSelect}
                        isBusy={false}
                        isPressed={false}
                      >
                        <Ui.Text
                          id={locale.select}
                          size={Ui.text.sizes[14]}
                          bold
                        />
                      </Ui.Control>
                    </Ui.EditorAction.Item>
                  </Ui.EditorAction.Index>
                </Ui.Group.Item>
              </Ui.Group.Body>
            </Ui.Group.Index>
          </Ui.FlexibleBar.Cell>
        </Ui.FlexibleBar.Body>
      ),
      component: (
        <Ui.TextBox.Body offset={Ui.textBox.offsets.medium}>
          <Ui.Text
            id={locale.body}
            interpolationParams={locale.bodyPlurals}
            size={Ui.text.sizes[14]}
            block
            hasHTML
          />
        </Ui.TextBox.Body>
      ),
      props: {
        size: Ui.dialog.sizes.small,
        offset: Ui.dialog.offsets.medium,
      },
    });
  }
}

export default WarningModalProvider;
