// NOTE: код оброс эволюцией, надо отрефакторить. JSF-2666

import clamp from 'lodash/clamp';
import concat from 'lodash/concat';
import { thisDevice } from '@pdffiller/jsf-useragent';
import { elemTypes, isGraphicToolType,
  isHorizontalGraphicType } from './elemTypes';
import { colorKeys, defaultControlPoints } from './const';
import { BezierSpline } from './BezierSpline';
import { iconTypes } from '../ui/Icon';

export const directions = {
  forward: 'forward',
  backward: 'backward',
  double: 'double',
};

// TODO: to static css
export const svgStyle = {
  position: 'absolute',
  top: 0,
  left: 0,
  bottom: 0,
  right: 0,
  width: '100%',
  height: '100%',
  margin: 'auto auto 0 0',
};

// TODO: to static css
export const svgFillableStyle = {
  width: 'auto',
  height: 'auto',
  maxHeight: '100%',
  maxWidth: '100%',
};

// TODO: to static css
export const wrapperDivStyle = {
  position: 'relative',
  overflow: 'visible',
  width: '100%',
  height: '100%',
};

// on touch we need bigger endPoints
// to make it easier handle with fingers
const desktopEndPointSize = 8; // px
const touchEndPointSize = 16; // px

export const getEndPointSize = () => {
  if (thisDevice.isMobile || thisDevice.isSurface) {
    return touchEndPointSize;
  }
  return desktopEndPointSize;
};

/**
 * Rotate given points by given angle
 *
 * @param {array} points - array [x0, y0, x1, y1 ...]
 * @param {number} angle - rotation angle in radians
 * @returns {array} rotated points [x0, y0, x1, y1 ...]
 */
const rotate = (points, angle) => {
  const sinAngle = Math.sin(angle);
  const cosAngle = Math.cos(angle);

  const rotated = points.map((value, index) => {
    const currentIsX = !(index % 2);
    const Xcoord = currentIsX
      ? value
      : points[index - 1];

    const Ycoord = currentIsX
      ? points[index + 1]
      : value;

    const res = currentIsX
      ? (Xcoord * cosAngle) + (Ycoord * sinAngle)
      : (-Xcoord * sinAngle) + (Ycoord * cosAngle);
    return res;
  });

  return rotated;
};

/**
 * Get viewbox for given points: min 'x' and 'y', width and height: pure, without lineWidth
 *
 * @param {array} controlPoints - array [x0, y0, x1, y1 ...]
 * @returns {object} - { x, y, width, height }
 */
export const getPureViewbox = (controlPoints, lineWidth = 0) => {
  const Xs = controlPoints.filter((value, index) => {
    return !(index % 2);
  }); // odd indexes are 'x' values
  const Ys = controlPoints.filter((value, index) => {
    return !!(index % 2);
  }); // even indexes are 'y' values
  const minX = Math.min(...Xs);
  const width = Math.max(...Xs) - minX;
  const minY = Math.min(...Ys);
  const height = Math.max(...Ys) - minY;
  return {
    x: minX - (lineWidth / 2),
    y: minY - (lineWidth / 2),
    width: width + lineWidth,
    height: height + lineWidth,
  };
};

/**
 * Get SVG-viewbox for given points: min 'x' and 'y', width and height
 *
 * @param {array} controlPoints - array [x0, y0, x1, y1 ...]
 * @param {number} lineWidth - optional parameter, lineWidth for square-brushed curves
 * @returns {string} - SVG-viewBox: 'x y width height'
 */
export const getViewbox = (controlPoints, lineWidth = 0) => {
  const { x, y, width, height } = getPureViewbox(controlPoints, lineWidth);
  return `${x} ${y} ${width} ${height}`;
};

/**
 * Get SVG-viewbox for given curves
 *
 * @param {array} curves - curves as given in state for sign.curve
 * @returns {string} - SVG-viewbox
 */
export const getSignCurveViewbox = (curves) => {
  const wholeSignPoints = concat([], ...(curves.map((curve) => {
    return curve.controlPoints;
  })));
  return getPureViewbox(wholeSignPoints, curves[0].lineWidth);
};

/**
 * Get size for given curves
 *
 * @param {array} curves - curves as given in state for sign.curve
 * @returns {object} - { width, height }
 */
export const getSignCurveSize = (curves) => {
  const viewbox = getSignCurveViewbox(curves);
  return {
    width: viewbox.width,
    height: viewbox.height,
  };
};

/**
 * Make SVG path from given 2d array
 *
 * @param {array} points - points for path (i.e. [x0, y0, x1, y1, ...])
 * @param {bool} closePath - do we connect end point with begin or not
 * @returns {string} svg path
 */
export const pathFromPoints = (pointsArg, closePath = false) => {
  const points = [];
  for (let index = 0; index < pointsArg.length; index += 2) {
    points.push(`${pointsArg[index]},${pointsArg[index + 1]}`);
  }

  const closeText = closePath
    ? 'Z'
    : '';

  return `M${points.join('L')}${closeText}`;
};

/**
 * Make SVG bezier path from given 2d array
 *
 * @param {array} points - points for path (i.e. [x0, y0, x1, y1, ...])
 * @param {bool} smoothing - do we use curve or line
 * @returns {string} svg path
 */
export const bezierPathFromPoints = (pointsArg, smoothing) => {
  if (pointsArg.length < 2) {
    return '';
  }

  const points = [];
  let lastPoint = null;
  let currentPoint = null;
  for (let index = 0; index < pointsArg.length - 2; index += 2) {
    currentPoint = [pointsArg[index], pointsArg[index + 1]];
    if (
      !(smoothing && lastPoint &&
      Math.abs(currentPoint[0] - lastPoint[0]) < 6 &&
      Math.abs(currentPoint[1] - lastPoint[1]) < 6)
    ) {
      lastPoint = currentPoint;
      points.push(currentPoint);
    }
  }

  currentPoint = [pointsArg[pointsArg.length - 2], pointsArg[pointsArg.length - 1]];
  if (smoothing) {
    if (
      points.length > 1 &&
      Math.abs(currentPoint[0] - lastPoint[0]) < 6 &&
      Math.abs(currentPoint[1] - lastPoint[1]) < 6
    ) {
      points.pop();
    }

    if (points.length === 1) {
      const idx = Math.floor(pointsArg.length / 4) * 2;
      currentPoint = [pointsArg[idx], pointsArg[idx + 1]];
      points.push([currentPoint[0] + 0.001, currentPoint[1] + 0.001]);
    }
  }

  points.push(currentPoint);

  const spline = new BezierSpline(points);
  spline.construct(0.3);

  let res = `M${points[0][0]},${points[0][1]}`;
  for (let idx = 0; idx < spline.crp.length; idx++) {
    const point = spline.crp[idx];
    if (point) {
      if (smoothing) {
        res += `C${point[1][0]},${point[1][1]} ${point[2][0]},${point[2][1]} `;
        res += `${point[3][0]},${point[3][1]}`;
      } else {
        res += `L${point[3][0]},${point[3][1]}`;
      }
    }
  }

  return res;
};

/**
 * Returns an svg path for arrow, as given at
 * https://docs.google.com/document/d/19PgQ5C5Qp5ro3NMSUV9pwtYaYJfBsuln0Qmw-ldBdPk
 *
 * @param {array} controlPoints - [x0, y0, x1, y1] - begin and end points for arrow
 * @param {number} thickness - thickness parameter
 * @param {string} direction - one of 'directions' instance
 * @returns {object} {points, endPoints} - each is [x0, y0, x1, y1...] array
 */
export const drawArrow = (controlPoints, thickness, direction) => {
  // Step 1: preparing necessary values

  const headW = thickness < 1.5
    ? 3.75
    : thickness * 4;

  const radius = thickness / 2; // arrow tail radius

  const headL = thickness < 1.5
    ? 7.5
    : thickness * 4;

  const shaftPos = headL * 0.3;
  const con = thickness * 0.5;

  const doubleLengthMultiplier = direction === directions.double
    ? 2
    : 1;

  const arrowLength = Math.max(
    Math.sqrt(
      ((controlPoints[2] - controlPoints[0]) ** 2) +
      ((controlPoints[3] - controlPoints[1]) ** 2),
    ),
    headL * doubleLengthMultiplier,
  );

  const ptA = { x: 0, y: 0 };
  const ptB = { x: arrowLength, y: 0 };

  // Step 2: get points for horizontal arrow
  const points = [];
  if (direction === directions.forward) {
    points.push(ptA.x, ptA.y - (thickness / 2));
    points.push((ptB.x - headL) + shaftPos, ptB.y - (con / 2));
    points.push(ptB.x - headL, ptB.y - (headW / 2));
    points.push(ptB.x, ptB.y);
    points.push(ptB.x - headL, ptB.y + (headW / 2));
    points.push((ptB.x - headL) + shaftPos, ptB.y + (con / 2));
    points.push(ptA.x, ptA.y + (thickness / 2));

    const { PI, sin, cos } = Math;
    for (let index = PI * 2; index >= PI; index -= PI / 20) {
      points.push(ptA.x + (radius * sin(index)), ptA.y + (radius * cos(index)));
    }
  }

  if (direction === directions.backward) {
    points.push(ptB.x, ptB.y - (thickness / 2));
    points.push((ptA.x + headL) - shaftPos, ptA.y - (con / 2));
    points.push(ptA.x + headL, ptA.y - (headW / 2));
    points.push(ptA.x, ptA.y);
    points.push(ptA.x + headL, ptA.y + (headW / 2));
    points.push((ptA.x + headL) - shaftPos, ptA.y + (con / 2));
    points.push(ptB.x, ptB.y + (thickness / 2));

    const { PI, sin, cos } = Math;
    for (let index = PI * 2; index >= PI; index -= PI / 20) {
      points.push(ptB.x + (radius * -sin(index)), ptB.y + (radius * cos(index)));
    }
  }

  if (direction === directions.double) {
    points.push((ptA.x + headL) - shaftPos, ptA.y - (con / 2));
    points.push((ptB.x - headL) + shaftPos, ptB.y - (con / 2));
    points.push(ptB.x - headL, ptB.y - (headW / 2));
    points.push(ptB.x, ptB.y);
    points.push(ptB.x - headL, ptB.y + (headW / 2));
    points.push((ptB.x - headL) + shaftPos, ptB.y + (con / 2));
    points.push((ptA.x + headL) - shaftPos, ptA.y + (con / 2));
    points.push(ptA.x + headL, ptA.y + (headW / 2));
    points.push(ptA.x, ptA.y);
    points.push(ptA.x + headL, ptA.y - (headW / 2));
  }

  // Step 3: rotate arrow and return result
  const width = controlPoints[2] - controlPoints[0];
  const height = controlPoints[3] - controlPoints[1];
  const angle = -Math.atan2(height, width);

  // We need also coordinates for endPoints
  const endPoints = [ptA.x, ptA.y, ptB.x, ptB.y];
  return {
    points: rotate(points, angle),
    endPoints: rotate(endPoints, angle),
  };
};

/**
 * Make a line between two points emulating square brush
 *
 * @param {array} controlPoints - array of two points [x0, y0, x1, y1]
 * @returns {array} - array of objects {x,y} - points in clockwise direction
 */
export const getAnchorPoints = (controlPoints, thickness) => {
  const th2 = thickness / 2;
  const [bx, by, ex, ey] = controlPoints; // begin x, y; end x, y

  const btl = { x: bx - th2, y: by - th2 }; // begin top left
  const btr = { x: bx + th2, y: by - th2 }; // begin top right
  const bbl = { x: bx - th2, y: by + th2 }; // begin bottom left
  const bbr = { x: bx + th2, y: by + th2 }; // begin bottom right
  const etl = { x: ex - th2, y: ey - th2 }; // end top left
  const etr = { x: ex + th2, y: ey - th2 }; // end top right
  const ebl = { x: ex - th2, y: ey + th2 }; // end bottom left
  const ebr = { x: ex + th2, y: ey + th2 }; // end bottom right

  // return different arrays for different directions to save clockwise direction

  // right-down, straight right, straight down, or when points are equal
  if (bx <= ex && by <= ey) {
    return [bbl, btl, btr, etr, ebr, ebl];
  }

  // right-up, straight up
  if (bx < ex && by >= ey) {
    return [bbr, bbl, btl, etl, etr, ebr];
  }

  // left-down, straight left
  if (bx >= ex && by < ey) {
    return [btl, btr, bbr, ebr, ebl, etl];
  }

  // left-up
  return [btr, bbr, bbl, ebl, etl, etr];
};

/**
 * When we have big 'controlPoints' array and we know that
 * it's horizontal line (erase or blackout tool) - draw only edge points
 *
 * @param {array} controlPoints - array [x0, y0, x1, y1 ...]
 * @returns {array} - [x0, y0, x1, y1] - x0 and x1 - leftest and rightest points
 */
export const getEdgePoints = (controlPoints) => {
  if (!controlPoints.length || controlPoints.length < 2) {
    return defaultControlPoints;
  }

  const Xs = controlPoints.filter((value, index) => {
    return !(index % 2);
  }); // odd indexes are 'x' values
  const Ycoord = controlPoints[1]; // assume that 'y' values are equal in horizontal curve
  return [
    Math.min(...Xs), Ycoord,
    Math.max(...Xs), Ycoord,
  ];
};

/**
 * Map color from state to program-readable css-compatible format
 *
 * @param {string} color - string of 6 hex digits
 * @returns {string} - valid css-compatible color
 */
export const getValidColor = (color) => {
  if (
    typeof color === 'string' &&
    /^[a-fA-F0-9]{6}$/.test(color)
  ) {
    return `#${color}`;
  }
  return '#000000';
};

// TODO: very dirty code, refactor it later
// TODO: test
/**
 * When graphictool's lineWidth is changed, it causes change of x, y, width and height.
 *
 * @returns {object} new props to add
 */
export const prepareNewPropsOnLineWidthChange = (
  type, { x = 0, y = 0, width, height, lineWidth, controlPoints, direction },
  newLineWidth, originalSize, isGhost,
) => {
  // find valid lineWidth
  for (let tmpLineWidth = newLineWidth; tmpLineWidth >= 1; --tmpLineWidth) {
    if (type === elemTypes.arrow) {
      // For arrow: headW (see drawArrow() method) can't be thicker than document
      const maxLineWidth = Math.min(originalSize.width, originalSize.height) / 4;

      // for arrow ghost
      if (!controlPoints) {
        const computedLineWidth = Math.min(tmpLineWidth, maxLineWidth);
        if (computedLineWidth !== lineWidth) {
          return { lineWidth: computedLineWidth };
        }
      } else {
        const oldArrow = drawArrow(controlPoints, lineWidth, direction);
        const oldViewbox = getPureViewbox(oldArrow.points);
        const newArrow = drawArrow(controlPoints, tmpLineWidth, direction);
        const newViewbox = getPureViewbox(newArrow.points);

        if (
          newViewbox.width <= originalSize.width &&
          newViewbox.height <= originalSize.height
        ) {
          if (tmpLineWidth === lineWidth) {
            return false;
          }

          // calc diff between old elem position and new
          const dx = (oldArrow.endPoints[0] - oldViewbox.x) -
            (newArrow.endPoints[0] - newViewbox.x);
          const dy = (oldArrow.endPoints[1] - oldViewbox.y) -
            (newArrow.endPoints[1] - newViewbox.y);

          return {
            x: clamp(x + dx, 0, originalSize.width - newViewbox.width),
            y: clamp(y + dy, 0, originalSize.height - newViewbox.height),
            width: newViewbox.width,
            height: newViewbox.height,
            lineWidth: tmpLineWidth,
          };
        }
      }
    }

    const isHorizontal = isHorizontalGraphicType(type);
    const dLineWidth = tmpLineWidth - lineWidth;

    let isViewBoxSizesHigherOriginalSizes = false;

    // for not-ghost pen
    if (type === elemTypes.pen && controlPoints) {
      const viewbox = getPureViewbox(controlPoints, tmpLineWidth);
      if (
        viewbox.width > originalSize.width ||
        viewbox.height > originalSize.height
      ) {
        isViewBoxSizesHigherOriginalSizes = true;
      }
    }

    if (isViewBoxSizesHigherOriginalSizes) {
      return false;
    }
    if (tmpLineWidth === lineWidth) {
      return false;
    }

    // x and width must be not changed in horizontal types
    const newWidth = (isHorizontal || isGhost)
      ? width
      : width + dLineWidth;

    return {
      x: isHorizontal
        ? x
        : Math.max(x - (dLineWidth / 2), 0),

      width: newWidth,
      ...(isHorizontal && !isGhost && {
        controlPoints: [0, 0, newWidth - tmpLineWidth, 0],
      }),

      y: Math.max(y - (dLineWidth / 2), 0),
      height: isGhost
        ? height
        : height + dLineWidth,

      lineWidth: tmpLineWidth,
    };
  }

  return false;
};

/**
 * get valid key for updateElement:
 * text tools have 'color' prop, graphic tools have 'fillColor' prop
 * background and border color keys remain unchanged
 *
 * @param {string} colorKey - given key
 * @param {string} type - element type
 * @returns {string} valid key for given type
 */
export const getValidColorKey = (colorKey, type) => {
  return (isGraphicToolType(type) && colorKey === colorKeys.fontColor)
    ? colorKeys.fillColor
    : colorKey;
};

/**
 * Get approptiate icon for colorpicker
 *
 * @returns {string or null}
 */
export const getColorIcon = (colorKey) => {
  switch (colorKey) {
    case colorKeys.fontColor: return iconTypes.text;
    case colorKeys.bgColor: return iconTypes.paint;
    case colorKeys.borderColor: return iconTypes.square;
    default: return null;
  }
};

/**
 * get active color for given role (foreground, background, border) for given element
 *
 * @param {string} colorKey - one of keys of 'content' map:
 * 'color', 'fillColor', 'bgColor', 'borderColor'
 * @param {object} element - element :)
 * @returns {string} active color
 */
export const getActiveColor = (colorKey, element) => {
  const { type, content } = element;
  const validColorKey = getValidColorKey(colorKey, type);
  return content[validColorKey];
};

/**
 * Rearrange smallColors array to render it on touch
 *
 * @param {array} colors - smallColors
 * @returns {array} rearranged smallColors
 */
export const rearrangeSmallColors = (colors) => {
  const cl = colors.length;

  const dirtyRes = [...Array(cl)]
    .map((__, index) => {
      return colors[((index % 3) * (cl / 3)) + Math.floor(index / 3)];
    });

  const mapDirtyRes = (__, index, array) => {
    return array[((index % 6) * 3) + Math.floor(index / 6)];
  };
  return [
    ...dirtyRes.slice(0, cl / 2).map(mapDirtyRes),
    ...dirtyRes.slice(cl / 2).map(mapDirtyRes),
  ];
};

/**
 * Для отправки Киеву на сервер мы преобразуем имеющиеся данные (x, y, width, height)
 * в массив из двух controlPoint и lineWidth.
 * Как у них это хранится: lineWidth - половина меньшей стороны прямоугольника,
 * controlPoints - соответственно, "фокусы"
 *
 * @param {number} x - x pos of element
 * @param {number} y - y pos of element
 * @param {number} width - width of element
 * @param {number} height - height of element
 * @returns {shape({ controlPoints, lineWidth })} data for servwr
 */
export const getRectProps = ({ x, y, width, height }) => {
  const lineWidth = Math.min(width, height) / 2;
  const controlPoints = [
    x + lineWidth,
    y + lineWidth,
    (x + width) - lineWidth,
    (y + height) - lineWidth,
  ];
  return { lineWidth, controlPoints };
};

/**
 * Для корректной отрисовки arrow ghost'a нужно считать размеры и позицию относительно курсора
 *
 * @param {object} ghostContent - ghost.content params
 * @param {number} scale - page scale
 * @returns {object} { width, height, x, y } - size and position of an arrow ghost
 */
export const getArrowPropsForGhost = (ghostContent, scale = 1) => {
  const { points } = drawArrow(
    defaultControlPoints,
    ghostContent.lineWidth * scale,
    ghostContent.direction,
  );
  const { width, height, x, y } = getPureViewbox(points, 0);

  return { width, height, x, y };
};
