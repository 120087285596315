import React from 'react';
import PropTypes from 'prop-types';

const FillableWizardMain = ({ children }) => {
  return (
    <div className="fillable-wizard__main">
      {children}
    </div>
  );
};

FillableWizardMain.propTypes = {
  children: PropTypes.element.isRequired,
};

export default FillableWizardMain;
