import { utils } from 'ws-editor-lib';

import { cancellableOpts } from '../modules/undoRedo';
import updateFillableElementWithPreprocessing from './updateFillableElementWithPreprocessing';
import { limitByFrameSize } from '../../helpers/utils';
import selectors from '../selectors/elementsSelectors';
import * as baseSelectors from '../selectors/baseSelectors';
import { isRadioElement } from '../helpers/functions';

const propagateTemplateToOtherElementsOfRadioGroup = (
  activeElement,
  dispatch,
  getState,
  templateToUpdate,
  subType = undefined,
) => {
  const state = getState();
  if (isRadioElement(activeElement)) {
    const isResize = templateToUpdate.hasOwnProperty('width') ||
      templateToUpdate.hasOwnProperty('height');
    const originalSizes = baseSelectors.getOriginalSizes(state);
    const elementPageOriginalSize = originalSizes[activeElement.pageId];

    const elementRadioGroupName = utils.getElementGroupNames(activeElement).radio;
    const elementsIdByRadioGroup = selectors.getElementsIdByRadioGroup(
      state, elementRadioGroupName,
    ) || [];

    elementsIdByRadioGroup.forEach((id) => {
      if (id === activeElement.id) {
        return;
      }

      let newTemplate = templateToUpdate;
      const elementFromMapFactory = selectors.getElementFromMapFactory(id)(state);
      // don't allow to shift other elements off-page
      if (isResize) {
        if (elementFromMapFactory) {
          const { template: { x, y } } = elementFromMapFactory;

          newTemplate = {
            ...newTemplate,
            ...limitByFrameSize({ x, y }, elementPageOriginalSize, templateToUpdate),
          };
        } else {
          // eslint-disable-next-line no-console
          console.warn("element wasn't removed from map");
        }
      }

      dispatch(
        updateFillableElementWithPreprocessing({
          id,
          template: newTemplate,
          subType,
        }, cancellableOpts),
      );
    });
  }
};

export default propagateTemplateToOtherElementsOfRadioGroup;
