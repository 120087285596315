import { selectors } from 'jsfcore';
import { select, put } from 'redux-saga/effects';
import { openModal } from 'combine-modals/lib/actions/actionCreator';
import constants from 'combine-modals/lib/constants/dialog.modal';

function* openProcessingModal(options) {
  const { processingModal } = yield select(selectors.locale.getEditorLocale);

  yield put(openModal('DialogModal', {
    size: constants.SIZE_MD,
    modalType: constants.PROCESSING,
    title: processingModal.title,
    text: processingModal.text,
    ...options,
  }));
}

export default openProcessingModal;
