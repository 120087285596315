export const iosDateKeyboards = {
  '320x480': { portrait: 216, landscape: 0 }, // iphone 4
  '320x568': { portrait: 216, landscape: 0 },
  '375x667': { portrait: 216, landscape: 0 },
  '375x812': { portrait: 195, landscape: 0 }, // iphone X, XS
  '414x736': { portrait: 226, landscape: 0 },
  '414x896': { portrait: 205, landscape: 0 }, // iphone XS MAX, XR
  '768x1024': { portrait: 313, landscape: 398 },
  '834x1112': { portrait: 313, landscape: 398 },
  '1024x1366': { portrait: 371, landscape: 459 },
  '1024x768': { portrait: 398, landscape: 313 },
  '1366x1024': { portrait: 459, landscape: 371 },
};

export const iosKeyboards = {
  '320x480': { portrait: 216, landscape: 0 }, // iphone 4
  '320x568': { portrait: 216, landscape: 0 },
  '375x667': { portrait: 216, landscape: 0 },
  '375x812': { portrait: 252, landscape: 0 }, // iphone X, XS
  '414x736': { portrait: 226, landscape: 0 },
  '414x896': { portrait: 262, landscape: 0 }, // iphone XS MAX, XR
  '768x1024': { portrait: 313, landscape: 398 },
  '834x1112': { portrait: 313, landscape: 398 },
  '1024x1366': { portrait: 371, landscape: 459 },
  '1024x768': { portrait: 398, landscape: 313 },
  '1366x1024': { portrait: 459, landscape: 371 },
};

export const iosKeyboardsWithPanel = {
  '320x480': { portrait: 216, landscape: 0 }, // iphone 4
  '320x568': { portrait: 253, landscape: 0 },
  '375x667': { portrait: 258, landscape: 0 },
  '375x812': { portrait: 252, landscape: 0 }, // iphone X, XS
  '414x736': { portrait: 226, landscape: 0 },
  '414x896': { portrait: 262, landscape: 0 }, // iphone XS MAX, XR
  '768x1024': { portrait: 313, landscape: 398 }, // fix
  '834x1112': { portrait: 313, landscape: 398 },
  '1024x1366': { portrait: 371, landscape: 459 }, // fix
  '1024x768': { portrait: 398, landscape: 313 },
  '1366x1024': { portrait: 459, landscape: 371 },
};

export const zeroSpace = { portrait: 0, landscape: 0 };
