import { eventChannel } from 'redux-saga';
import { call, take, put, all } from 'redux-saga/effects';
import { trackPoint } from 'ws-editor-lib/actions';
import { SET_APP_STARTED } from 'jsfcore/store/modules/events';
import { getEventPath } from 'jsfcore/helpers/utils';
import { elemPrevUserActedTrackPoint } from 'jsfcore/helpers/const';

export const USER_ACTED = 'USER_ACTED';

export const documentListener = () => {
  return eventChannel((emitter) => {
    let onKeyDownListener = null;
    let onClickListener = null;

    const clearListeners = () => {
      document.body.removeEventListener('keydown', onKeyDownListener);
      document.body.removeEventListener('click', onClickListener);
    };

    const addListeners = () => {
      document.body.addEventListener('keydown', onKeyDownListener);
      document.body.addEventListener('click', onClickListener);
    };

    onKeyDownListener = () => {
      clearListeners();
      emitter(true);
    };

    onClickListener = (event) => {
      if (
        getEventPath(event).some(({ id }) => {
          return Object.values(elemPrevUserActedTrackPoint).some((val) => {
            return val === id;
          });
        })
      ) {
        return;
      }

      clearListeners();
      emitter(true);
    };

    addListeners();

    return () => {};
  });
};

export function* watchOnFirstUserAction() {
  yield take(SET_APP_STARTED);
  const userActionChannel = yield call(documentListener);
  if (yield take(userActionChannel)) {
    yield put(trackPoint(USER_ACTED, {}));
  }
}

export default function* userActedRootSaga() {
  yield all([
    watchOnFirstUserAction(),
  ]);
}
