import { mocks, allModules } from './const';
import getLazy from './getLazy';
import loadModule from './loadModule';
import { LazyComponent, LazyComponentSubscribed } from './LazyComponent';

// export fot tests
export function createImportObject(prefix, object) {
  return Object.keys(object).reduce((acc, keyName) => {
    const value = object[keyName];
    if (typeof value === 'string') {
      acc[keyName] = `${prefix}.${value}`;
    } else {
      acc[keyName] = createImportObject(`${prefix}.${keyName}`, object[keyName]);
    }

    return acc;
  }, {});
}

const comments = createImportObject(allModules.comments, {
  components: {
    comments: 'Comments',
    comment: 'CommentTool',
    contentLayer: 'ContentLayer',
  },
  functions: {
    getActiveCommentId: 'getActiveCommentId',
  },
  actions: {
    setActiveComment: 'setActiveComment',
    setEditComment: 'setEditComment',
    setDeleteComment: 'setDeleteComment',
    setAddCommentMessage: 'setAddCommentMessage',
  },
});

const fakeEdit = createImportObject(allModules.fakeEdit, {
  components: {
    FakeEditLayer: 'FakeEditLayer',
    FakeEditTextToolWrapper: 'FakeEditTextToolWrapper',
  },
});

const fConstructor = createImportObject(allModules.fConstructor, {
  components: {
    headerBreadcrumbs: 'ConstructorHeaderBreadcrumbs',
    headerButtons: 'ConstructorHeaderButtons',

    fConstructor: 'FConstructor',
    previewButtons: 'FConstructorPreviewButton',
    fcToolbarAdvice: 'FConstructorToolbarAdvice',

    lockPAConstructor: 'LockPAConstructor',
    paConstructor: 'PAConstructor',

    headerProvider: 'HeaderProvider',
  },
  actions: {
    activatePreviewMode: 'activatePreviewMode',
    deactivatePreviewMode: 'deactivatePreviewMode',
    hideConstructor: 'hideConstructor',
  },
});

const formula = createImportObject(allModules.formula, {
  components: {
    FormulaRtaProvider: 'FormulaRtaProvider',
  },
  functions: {
    parser: 'parser',
    updateFormulasThunk: 'updateFormulasThunk',
    getElementsToUpdateSNF: 'getElementsToUpdateSNF',
    getElementsToUpdateJSF: 'getElementsToUpdateJSF',

    checkIsElementInFormulas: 'checkIsElementInFormulas',
    createFormulaVariableInText: 'createFormulaVariableInText',
  },
  objects: {
    locale: 'locale',
    consts: 'consts',
    selectors: 'selectors',
  },
});

const managers = createImportObject(allModules.managers, {
  components: {
    addressBookWrapper: 'AddressBookWrapper',
    imageManagerWrapper: 'ImageManagerWrapper',
    signatureManagerWrapper: 'SignatureManagerWrapper',
  },
  actions: {
    openImageManager: 'openImageManager',
    openSignatureManager: 'openSignatureManager',
    initImageSignatureManager: 'initImageSignatureManager',
  },
  functions: {},
});

const rearrange = createImportObject(allModules.rearrange, {
  components: {
    pageRearrangeWrapper: 'PageRearrangeWrapper',
  },
  actions: {
    openPageRearrange: 'openPageRearrange',
  },
  functions: {},
});

const wizard = createImportObject(allModules.wizard, {
  components: {
    wizardThumbnails: 'WizardThumbnails',
    wizardPointer: 'WizardPointer',
    wizardController: 'WizardController',
    wizardFooter: 'WizardFooter',
    wizardContent: 'WizardContent',
    wizardTodoListMaximized: 'WizardTodoListMaximized',
    wizardStartButton: 'WizardStartButton',
    wizardHeaderNavigation: 'WizardHeaderNavigation',
    wizardTodoListMobile: 'WizardTodoListMobile',
    wizardPhoneBottomSheetMenu: 'WizardPhoneBottomSheetMenu',
  },
  actions: {
    toggleWizardNext: 'toggleWizardNext',
    toggleWizardPrev: 'toggleWizardPrev',
    toggleWizardByElement: 'toggleWizardByElement',
    toggleWizardLastActive: 'toggleWizardLastActive',
    setWizardActive: 'setWizardActive',
    triggerSelectSignatureClick: 'triggerSelectSignatureClick',
    setWizardTraverseMode: 'setWizardTraverseMode',
    setMobileTodoIsActive: 'setMobileTodoIsActive',
  },
  functions: {
    getListItems: 'getListItems',
    getSignToReuse: 'getSignToReuse',
    isElementDone: 'isElementDone',
    setWizardActive: 'setWizardActive',
    toggleWizardNext: 'toggleWizardNext',
    getDateToSet: 'getDateToSet',
  },
  objects: {
    trackPoints: 'trackPoints',
  },
});

const versions = createImportObject(allModules.versions, {
  components: {
    versionsPanel: 'VersionsPanel',
    headerBreadcrumbs: 'VersionsHeaderBreadCrumbs',
  },
});

const feedback = createImportObject(allModules.feedback, {
  components: {
    feedbackModal: 'FeedbackModal',
  },
  actions: {
    openFeedbackModal: 'openFeedbackModal',
  },
});

function dispatchActionController() {
  let innerDispatch = null;

  const getDispatch = (dispatch) => {
    innerDispatch = dispatch;
  };

  const dispatchAction = (actionName, ...params) => {
    const action = getLazy(actionName);
    if (action && innerDispatch) {
      innerDispatch(action(...params));
    }
  };

  return { getDispatch, dispatchAction };
}

const { getDispatch, dispatchAction } = dispatchActionController();

export {
  comments,
  fakeEdit,
  fConstructor,
  formula,
  managers,
  rearrange,
  wizard,
  versions,
  feedback,

  mocks,
  getLazy,
  getDispatch,
  dispatchAction,
  allModules as modulesLiterals,

  LazyComponent,
  loadModule as getModulePromise,
  LazyComponentSubscribed,
};
