const editorModes = {
  constructor: 'constructor',
  init: 'init',
  main: 'main',
  pages: 'pages',
  pending: 'pending',
  versions: 'versions',
};

export default editorModes;
