import { createSelector } from 'reselect';
import { getScales } from './baseSelectors';

const emptyArray = [];
const emptyObject = {};

/**
 * Comments
 */
export const getCommentsWS = (state) => {
  return state.ws.comments || emptyArray;
};
export const getComments = createSelector(
  [getCommentsWS],
  (comments) => {
    return comments.filter((comment) => {
      return !comment.content.resolved;
    });
  },
);
export const getCommentsMap = (state) => {
  return state.ws.commentsMap;
};
export const getComment = (state, id) => {
  const commentHeadInMap = getCommentsMap(state)[id];
  if (commentHeadInMap) {
    return commentHeadInMap;
  }
  return null;
};
export const getCommentReply = (state, id) => {
  const comments = getComments(state);
  for (let i = 0; i < comments.length; i++) {
    const comment = comments[i];
    if (comment.content !== undefined && comment.content.replies !== undefined) {
      for (let j = 0; j < comment.content.replies.length; j++) {
        const reply = comment.content.replies[j];
        if (reply.id === id) {
          return reply;
        }
      }
    }
  }
  return null;
};
export const makeGetPageComments = (pageId) => {
  return createSelector(
    [getComments],
    (comments) => {
      return comments.filter((comment) => {
        return comment.pageId === pageId;
      });
    },
  );
};

export const getScaledComments = createSelector(
  [getComments, getScales],
  (comments, scales) => {
    return comments.map(
      (comment) => {
        return {
          ...comment,
          content: {
            ...comment.content,
            x: comment.content.x * scales[comment.pageId],
            y: comment.content.y * scales[comment.pageId],
          },
        };
      },
    );
  },
);
export const makeGetScaledPageComments = (pageId) => {
  return createSelector(
    [getScaledComments],
    (scaledComments) => {
      return scaledComments.filter((comment) => {
        return comment.pageId === pageId;
      });
    },
  );
};

export const getState = (state) => {
  return state.comments || emptyObject;
};
export const getEditCommentId = (state) => {
  return getState(state).editCommentId;
};
export const getEditCommentMessage = (state) => {
  return getState(state).editCommentMessage;
};
export const getDeleteCommentId = (state) => {
  return getState(state).deleteCommentId;
};
export const getActiveCommentId = (state) => {
  return getState(state).activeCommentId;
};
export const getAddCommentMessage = (state) => {
  return getState(state).addCommentMessage;
};
export const getActiveComment = (state) => {
  const activeCommentId = getActiveCommentId(state);
  const commentsMap = getCommentsMap(state);
  if (activeCommentId === false) {
    return false;
  }
  return commentsMap[activeCommentId];
};
