import React from 'react';
import PropTypes from 'prop-types';
import useTemporaryDisableBlur from './useTemporaryDisableBlur';

function focusControllerDraggableDecoratorDesktop(DraggableCore) {
  function CancelBlurForDraggableCore(props) {
    const { onStart, onStop } = props;
    const [
      onStartWithDisableBlur,
      onStopWithDisableBlur,
    ] = useTemporaryDisableBlur(onStart, onStop);

    return (
      <DraggableCore
        {...props}
        onStart={onStartWithDisableBlur}
        onStop={onStopWithDisableBlur}
      />
    );
  }

  CancelBlurForDraggableCore.propTypes = {
    onStart: PropTypes.func.isRequired,
    onStop: PropTypes.func.isRequired,
  };

  return CancelBlurForDraggableCore;
}

export default focusControllerDraggableDecoratorDesktop;
