import React from 'react';
import PropTypes from 'prop-types';

const ContentConfiguratorMainContent = ({ children, rowAttributes, cellAttributes }) => {
  return (
    <div className="content-configurator__main-content">
      <div className="content-configurator__row" {...rowAttributes}>
        <div className="content-configurator__cell" {...cellAttributes}>
          {children}
        </div>
      </div>
    </div>
  );
};


ContentConfiguratorMainContent.propTypes = {
  children: PropTypes.node,
  rowAttributes: PropTypes.shape({}),
  cellAttributes: PropTypes.shape({}),
};

ContentConfiguratorMainContent.defaultProps = {
  children: null,
  rowAttributes: {},
  cellAttributes: {},
};

export default ContentConfiguratorMainContent;
