import PropTypes from 'prop-types';
import React from 'react';
import { createPortal } from 'react-dom';
import * as Layer from './Layer';

const Portal = ({ children }) => {
  return (
    <Layer.Context.Consumer>
      {(portalLayerRef) => {
        if (__CLIENT__ && portalLayerRef instanceof Element) {
          return createPortal(children, portalLayerRef);
        }

        return null;
      }}
    </Layer.Context.Consumer>
  );
};

Portal.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Portal;
