import React from 'react';
import PropTypes from 'prop-types';

const PanelHeaderBody = ({ children }) => {
  return (
    <div className="panel-header__body">
      {children}
    </div>
  );
};

PanelHeaderBody.propTypes = {
  children: PropTypes.node.isRequired,
};

export default PanelHeaderBody;
