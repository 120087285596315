const ACTION_TAG = '@@ws-editor-lib/';

const actionTypes = {

  RESET_ALL_REDUCERS: 'all/RESET_ALL_REDUCERS',

  AUTH_INIT: `${ACTION_TAG}AUTH_INIT`,
  AUTH_RESTORE: `${ACTION_TAG}AUTH_RESTORE`,
  AUTH_FAKE: `${ACTION_TAG}AUTH_FAKE`,
  // AUTH_RECONNECT: ACTION_TAG + 'AUTH_RECONNECT',
  DESTROY: `${ACTION_TAG}DESTROY`,

  SERVER_CONNECT: `${ACTION_TAG}SERVER_CONNECT`,
  SERVER_ERROR: `${ACTION_TAG}SERVER_ERROR`,
  SERVER_DISCONNECT: `${ACTION_TAG}SERVER_DISCONNECT`,
  DESTROY_RECEIVE: `${ACTION_TAG}DESTROY_RECEIVE`,
  AUTH_RECEIVE: `${ACTION_TAG}AUTH_RECEIVE`,
  SET_EXTERNAL_USER: `${ACTION_TAG}SET_EXTERNAL_USER`,
  OPERATIONS_RECEIVE: `${ACTION_TAG}OPERATIONS_RECEIVE`,
  OPERATION_PUSH: `${ACTION_TAG}OPERATION_PUSH`,
  API_HASH_SET: `${ACTION_TAG}API_HASH_SET`,
  SET_WS_CONNECTION_FAILED: `${ACTION_TAG}SET_WS_CONNECTION_FAILED`,

  GET_ACCESS: `${ACTION_TAG}GET_ACCESS`,
  ACTIVATE_TOOL: `${ACTION_TAG}ACTIVATE_TOOL`,
  ADD_ELEMENT: `${ACTION_TAG}ADD_ELEMENT`,
  ADD_ELEMENTS: `${ACTION_TAG}ADD_ELEMENTS`,
  ADD_FILLABLE_ELEMENTS: `${ACTION_TAG}ADD_FILLABLE_ELEMENTS`,
  UPDATE_ELEMENT: `${ACTION_TAG}UPDATE_ELEMENT`,
  UPDATE_ELEMENTS: `${ACTION_TAG}UPDATE_ELEMENTS`,
  UPDATE_FILLABLE_GROUPS: `${ACTION_TAG}UPDATE_FILLABLE_GROUPS`,

  ADD_VALIDATORS_COLLECTION: `${ACTION_TAG}ADD_VALIDATORS_COLLECTION`,
  UPDATE_TODO_LIST: `${ACTION_TAG}UPDATE_TODO_LIST`,
  SET_ACTIVE_ELEMENT: `${ACTION_TAG}SET_ACTIVE_ELEMENT`,
  REMOVE_ELEMENT: `${ACTION_TAG}REMOVE_ELEMENT`,
  REMOVE_ELEMENTS: `${ACTION_TAG}REMOVE_ELEMENTS`,
  CLIENT_OPERATION: `${ACTION_TAG}CLIENT_OPERATION`,
  USER_OPERATION: `${ACTION_TAG}CLIENT_OPERATION`,
  PROJECT_OPERATION: `${ACTION_TAG}PROJECT_OPERATION`,
  CLEAR_ELEMENT: `${ACTION_TAG}CLEAR_ELEMENT`,
  FULL_RELOAD: `${ACTION_TAG}FULL_RELOAD`,
  SET_CONFIRMED_OPS: `${ACTION_TAG}SET_CONFIRMED_OPS`,
  APPLY_CONFIG_PAGES: `${ACTION_TAG}APPLY_CONFIG_PAGES`,

  TIMER_TICK: `${ACTION_TAG}TIMER_TICK`,
  LOADING_PAGE: `${ACTION_TAG}LOADING_PAGE`,
  CHANGE_MESSAGE: `${ACTION_TAG}CHANGE_MESSAGE`,
  MESSAGE_SET: `${ACTION_TAG}MESSAGE_SET`,

  GALLERY_IMAGES_LIST: `${ACTION_TAG}GALLERY_IMAGES_LIST`,
  GALLERY_SIGNATURES_LIST: `${ACTION_TAG}GALLERY_SIGNATURES_LIST`,
  ADD_GALLERY_IMAGE: `${ACTION_TAG}ADD_GALLERY_IMAGE`,
  DELETE_GALLERY_IMAGE: `${ACTION_TAG}DELETE_GALLERY_IMAGE`,
  UPDATE_GALLERY_IMAGE: `${ACTION_TAG}UPDATE_GALLERY_IMAGE`,
  ADD_GALLERY_SIGNATURE: `${ACTION_TAG}ADD_GALLERY_SIGNATURE`,
  UPDATE_GALLERY_SIGNATURE: `${ACTION_TAG}UPDATE_GALLERY_SIGNATURE`,
  DELETE_GALLERY_SIGNATURE: `${ACTION_TAG}DELETE_GALLERY_SIGNATURE`,
  SET_SIGNATURE_DEFAULTS: `${ACTION_TAG}SET_SIGNATURE_DEFAULTS`,
  UPDATE_DEFAULT_SETTINGS: `${ACTION_TAG}UPDATE_DEFAULT_SETTINGS`,
  ADD_ERROR: `${ACTION_TAG}ADD_ERROR`,

  SET_EXPERIMENTAL_FEATURES: `${ACTION_TAG}SET_EXPERIMENTAL_FEATURES`,

  ON_OPERATIONS_PREFIX: `${ACTION_TAG}ON_OPERATIONS_`,
  ON_OPERATIONS_EDITOR_SCENARIOS: `${ACTION_TAG}ON_OPERATIONS_EDITOR_SCENARIOS`,
  ON_OPERATIONS_EDITOR_FEATURES: `${ACTION_TAG}ON_OPERATIONS_EDITOR_FEATURES`,
  ON_OPERATIONS_EDITOR_DEFAULTS: `${ACTION_TAG}ON_OPERATIONS_EDITOR_DEFAULTS`,
  ON_OPERATIONS_EDITOR_GUI: `${ACTION_TAG}ON_OPERATIONS_EDITOR_GUI`,
  ON_OPERATIONS_EDITOR_EXPERIMENTS: `${ACTION_TAG}ON_OPERATIONS_EDITOR_EXPERIMENTS`,
  ON_OPERATIONS_EDITOR_STATISTICS: `${ACTION_TAG}ON_OPERATIONS_EDITOR_STATISTICS`,
  ON_OPERATIONS_EDITOR_LOCALE: `${ACTION_TAG}ON_OPERATIONS_EDITOR_LOCALE`,
  ON_OPERATIONS_EDITOR_MODE: `${ACTION_TAG}ON_OPERATIONS_EDITOR_MODE`,
  ON_OPERATIONS_EDITOR_ALERT: `${ACTION_TAG}ON_OPERATIONS_EDITOR_ALERT`,
  ON_OPERATIONS_DOCUMENT_SOURCE: `${ACTION_TAG}ON_OPERATIONS_DOCUMENT_SOURCE`,
  ON_OPERATIONS_DOCUMENT_ACCESS: `${ACTION_TAG}ON_OPERATIONS_DOCUMENT_ACCESS`,
  ON_OPERATIONS_TOOLS_ELEMENTS: `${ACTION_TAG}ON_OPERATIONS_TOOLS_ELEMENTS`,
  ON_OPERATIONS_TOOLS_RADIO: `${ACTION_TAG}ON_OPERATIONS_TOOLS_RADIO`,
  ON_OPERATIONS_TOOLS_PAGES: `${ACTION_TAG}ON_OPERATIONS_TOOLS_PAGES`,
  ON_OPERATIONS_TOOLS_ATTRIBUTES: `${ACTION_TAG}ON_OPERATIONS_TOOLS_ATTRIBUTES`,
  ON_OPERATIONS_IMAGES_LIST: `${ACTION_TAG}ON_OPERATIONS_IMAGES_LIST`,
  ON_OPERATIONS_SIGNATURES_LIST: `${ACTION_TAG}ON_OPERATIONS_SIGNATURES_LIST`,
  ON_OPERATIONS_IMAGES_ADD: `${ACTION_TAG}ON_OPERATIONS_IMAGES_ADD`,
  ON_OPERATIONS_IMAGES_UPDATE: `${ACTION_TAG}ON_OPERATIONS_IMAGES_UPDATE`,
  ON_OPERATIONS_IMAGES_DELETE: `${ACTION_TAG}ON_OPERATIONS_IMAGES_DELETE`,
  ON_OPERATIONS_SIGNATURES_ADD: `${ACTION_TAG}ON_OPERATIONS_SIGNATURES_ADD`,
  ON_OPERATIONS_SIGNATURES_UPDATE: `${ACTION_TAG}ON_OPERATIONS_SIGNATURES_UPDATE`,
  ON_OPERATIONS_SIGNATURES_DELETE: `${ACTION_TAG}ON_OPERATIONS_SIGNATURES_DELETE`,
  ON_OPERATIONS_SIGNATURES_PENDING: `${ACTION_TAG}ON_OPERATIONS_SIGNATURES_PENDING`,
  ON_OPERATIONS_ATTACHMENTS_LIST: `${ACTION_TAG}ON_OPERATIONS_ATTACHMENTS_LIST`,
  ON_OPERATIONS_ATTACHMENTS_ADD: `${ACTION_TAG}ON_OPERATIONS_ATTACHMENTS_ADD`,
  ON_OPERATIONS_ATTACHMENTS_DELETE: `${ACTION_TAG}ON_OPERATIONS_ATTACHMENTS_DELETE`,
  ON_OPERATIONS_DOCUMENTSOURCE_TOOLSPAGES: `${ACTION_TAG}ON_OPERATIONS_DOCUMENTSOURCE_TOOLSPAGES`,
  ON_OPERATIONS_FONT_RECOGNIZE: `${ACTION_TAG}ON_OPERATIONS_FONT_RECOGNIZE`,
  ON_OPERATIONS_ROLES_LIST: `${ACTION_TAG}ON_OPERATIONS_ROLES_LIST`,
  ON_OPERATIONS_ROUTING_STEPS: `${ACTION_TAG}ON_OPERATIONS_ROUTING_STEPS`,
  ON_OPERATIONS_VALIDATORS_ELEMENTS: `${ACTION_TAG}ON_OPERATIONS_VALIDATORS_ELEMENTS`,

  FLUSH_OPERATIONS_QUEUE: `${ACTION_TAG}FLUSH_OPERATIONS_QUEUE`,

  TRACK_POINT: `${ACTION_TAG}TRACK_POINT`,
  ERROR_POINT: `${ACTION_TAG}ERROR_POINT`,
  RECONNECT: `${ACTION_TAG}RECONNECT`,
  EDITOR_FEEDBACK: `${ACTION_TAG}EDITOR_FEEDBACK`,
  AUTH_ERROR: `${ACTION_TAG}AUTH_ERROR`,
  PROBLEM_REPORT: `${ACTION_TAG}PROBLEM_REPORT`,
  SAVE_SIGNATURE: `${ACTION_TAG}SAVE_SIGNATURE`,

  // fConstructor actions
  ACTIVATE_FILLABLE_TOOL: `${ACTION_TAG}ACTIVATE_FILLABLE_TOOL`,
  ADD_FILLABLE_ELEMENT: `${ACTION_TAG}ADD_FILLABLE_ELEMENT`,
  ADD_FILLABLE_ELEMENT_AS_IS: `${ACTION_TAG}ADD_FILLABLE_ELEMENT_AS_IS`,
  UPDATE_FILLABLE_ELEMENT: `${ACTION_TAG}UPDATE_FILLABLE_ELEMENT`,
  UPDATE_FILLABLE_ELEMENTS: `${ACTION_TAG}UPDATE_FILLABLE_ELEMENTS`,
  REMOVE_FILLABLE_ELEMENT: `${ACTION_TAG}REMOVE_FILLABLE_ELEMENT`,
  SHOW_CONSTRUCTOR_PREVIEW: `${ACTION_TAG}SHOW_CONSTRUCTOR_PREVIEW`,
  HIDE_CONSTRUCTOR_PREVIEW: `${ACTION_TAG}HIDE_CONSTRUCTOR_PREVIEW`,
  SET_DEFAULT_FILLABLE_CHECKMARK_SUBTYPE: `${ACTION_TAG}SET_DEFAULT_FILLABLE_CHECKMARK_SUBTYPE`,
  UPDATE_ORDER_FILLABLE_ELEMENTS: `${ACTION_TAG}UPDATE_ORDER_FILLABLE_ELEMENTS`,


  // PA actions
  SET_PA_SETTINGS: `${ACTION_TAG}SET_PA_SETTINGS`,
  UPDATE_ATTR_DEFAULTS: `${ACTION_TAG}UPDATE_ATTR_DEFAULTS`,

  // Rearrange apply success
  APPLY_SUCCESS: 'APPLY_SUCCESS',

  // FCMode
  ACTIVATE_MODE: `${ACTION_TAG}ACTIVATE_MODE`,
  ACTIVATE_FCMODE: `${ACTION_TAG}ACTIVATE_FCMODE`,
  CANCEL_FCMODE: `${ACTION_TAG}CANCEL_FCMODE`,
  ACTIVATE_DEFAULT_MODE: `${ACTION_TAG}ACTIVATE_DEFAULT_MODE`,
  RESET_ELEMENTS: `${ACTION_TAG}RESET_ELEMENTS`,

  // Modal actions
  OPEN_MODAL: 'OPEN_MODAL',
  CLOSE_MODAL: 'CLOSE_MODAL',

  // native edit mode (trueedit)
  ON_OPERATIONS_TOOLS_NATIVE: `${ACTION_TAG}ON_OPERATIONS_TOOLS_NATIVE`,

  CHANGE_ELEMENT_PAGE_ID: `${ACTION_TAG}CHANGE_ELEMENT_PAGE_ID`,

  // routing
  UPDATE_ROLES: `${ACTION_TAG}UPDATE_ROLES`,
  UPDATE_ROUTING: `${ACTION_TAG}UPDATE_ROUTING`,
  SAVE_ROUTING_SIGNERS_TEMPLATE: `${ACTION_TAG}SAVE_ROUTING_SIGNERS_TEMPLATE`,
  REMOVE_ROUTING_SIGNERS_TEMPLATE: `${ACTION_TAG}REMOVE_ROUTING_SIGNERS_TEMPLATE`,
  SET_ACTIVE_SIGNER: `${ACTION_TAG}SET_ACTIVE_SIGNER`,

  // TEMP
  SET_ELEMENTS: `${ACTION_TAG}SET_ELEMENTS`,
  // Comments
  ADD_COMMENT: `${ACTION_TAG}ADD_COMMENT`,
  UPDATE_COMMENT: `${ACTION_TAG}UPDATE_COMMENT`,
  DELETE_COMMENT: `${ACTION_TAG}DELETE_COMMENT`,
  RESOLVE_COMMENT: `${ACTION_TAG}RESOLVE_COMMENT`,
  ON_OPERATIONS_USERS_LIST: `${ACTION_TAG}ON_OPERATIONS_USERS_LIST`,
  ON_OPERATIONS_TOOLS_COMMENT: `${ACTION_TAG}ON_OPERATIONS_TOOLS_COMMENT`,
  // font recognizing by image
  SEND_FONT_RECOGNITION_IMAGE: `${ACTION_TAG}SEND_FONT_RECOGNITION_IMAGE`,

  CHANGE_VIEW_MODE: `${ACTION_TAG}CHANGE_VIEW_MODE`,
  CHANGE_ALLOW_EDITING: `${ACTION_TAG}CHANGE_ALLOW_EDITING`,
  DELETE_CONTENT_FOR_PREVIEW_MODE: `${ACTION_TAG}DELETE_CONTENT_FOR_PREVIEW_MODE`,

  // pdf
  SET_PDF_DOCUMENT_SCALE: `${ACTION_TAG}SET_PDF_DOCUMENT_SCALE`,

  AUTOFILL: `${ACTION_TAG}AUTOFILL`,
  ON_OPERATIONS_OPERATIONS_AUTOFILL: `${ACTION_TAG}ON_OPERATIONS_OPERATIONS_AUTOFILL`,
  DEBOUNCED_DUMMY_ACTION: `${ACTION_TAG}DEBOUNCED_DUMMY_ACTION`,
  SET_ORGANIZATION: `${ACTION_TAG}SET_ORGANIZATION`,
  ACTIVATE_PENDING_MODE_FOR_ADDONS: `${ACTION_TAG}ACTIVATE_PENDING_MODE_FOR_ADDONS`,
  SEND_TRIGGER_OPERATION: `${ACTION_TAG}SEND_TRIGGER_OPERATION`,

  // wizard
  TURN_WIZARD_FULL: `${ACTION_TAG}TURN_WIZARD_FULL`,
  TURN_WIZARD_LITE: `${ACTION_TAG}TURN_WIZARD_LITE`,
  SET_LAST_ACTIVE_FILLABLE_ELEMENT_ID: `${ACTION_TAG}SET_LAST_ACTIVE_FILLABLE_ELEMENT_ID`,

  // versions
  ACTIVATE_VERSIONS_MODE: `${ACTION_TAG}ACTIVATE_VERSIONS_MODE`,
  GET_VERSIONS_LIST: `${ACTION_TAG}GET_VERSIONS_LIST`,
  ON_OPERATIONS_VERSIONS_LIST: `${ACTION_TAG}ON_OPERATIONS_VERSIONS_LIST`,
  CANCEL_VERSIONS_MODE: `${ACTION_TAG}CANCEL_VERSIONS_MODE`,
  PREVIEW_VERSION: `${ACTION_TAG}PREVIEW_VERSION`,
  RESTORE_VERSION: `${ACTION_TAG}RESTORE_VERSION`,
  SAVE_VERSION: `${ACTION_TAG}SAVE_VERSION`,
  ON_OPERATIONS_VERSIONS_SAVE: `${ACTION_TAG}ON_OPERATIONS_VERSIONS_SAVE`,
  RESET_WS_PAGES: `${ACTION_TAG}RESET_WS_PAGES`,
  RESET_COMMENTS: `${ACTION_TAG}RESET_COMMENTS`,

  // externalValidation
  CHECK_EXTERNAL_VALIDATION: `${ACTION_TAG}CHECK_EXTERNAL_VALIDATION`,
  ON_OPERATIONS_VALIDATORS_EXTERNAL: `${ACTION_TAG}ON_OPERATIONS_VALIDATORS_EXTERNAL`,
};

export default actionTypes;
