import mapFromPatches from './fromBackend';
import mapToPatches from './toBackend';

const reduceFn = (finalOperations, mapFn) => {
  return mapFn(finalOperations);
};

const mapToBackend = (operations) => {
  return mapToPatches.reduce(reduceFn, operations);
};

const mapFromBackend = (operations) => {
  return mapFromPatches.reduce(reduceFn, operations);
};

export {
  mapFromBackend,
  mapToBackend,
};
