// eslint-disable-next-line
export function appendScriptTag(src, asyncLoad, onLoad) {
  const script = document.createElement('script');
  script.src = src;
  script.async = asyncLoad;
  script.charset = 'UTF-8';
  script.crossorigin = 'anonymous';
  script.onload = onLoad;
  document.body.appendChild(script);
}
