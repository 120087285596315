import { openModal } from 'combine-modals/lib/actions/actionCreator';
import { FEEDBACK_MODAL } from 'combine-modals/lib/constants/modal.classnames';
import { feedback, dispatchAction } from '@pdffiller/jsf-lazyload';
import deactivateElement from './deactivateElement';
import { getViewerId, getProjectId, getEndPoints } from '../selectors/baseSelectors';
import { getUseNewFeedbackModal } from '../selectors/featureSelectors';
import { getViewerEmail } from '../selectors/selectors';
import { getDataForFeedBackModal } from '../../helpers/utils';

export default (options) => {
  return (dispatch, getState) => {
    const state = getState();
    const viewerId = getViewerId(state);
    const projectId = getProjectId(state);
    const endPoints = getEndPoints(state);
    const viewerEmail = getViewerEmail(state);
    const useNewFeedbackModal = getUseNewFeedbackModal(getState());

    dispatch(deactivateElement());

    if (useNewFeedbackModal) {
      return dispatchAction(feedback.actions.openFeedbackModal, {
        ...getDataForFeedBackModal(viewerId, viewerEmail, projectId, endPoints),
        ...options,
      });
    }

    return dispatch(openModal(FEEDBACK_MODAL, {
      ...getDataForFeedBackModal(viewerId, viewerEmail, projectId, endPoints),
      ...options,
    }));
  };
};
