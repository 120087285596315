import get from 'lodash/get';

export const getValFromPath = (store, path) => {
  const state = store.getState();
  return get(state, path);
};

export const watchFactory = (store) => {
  let acc = [];

  const listener = () => {
    for (let i = 0; i < acc.length; i++) {
      const watcher = acc[i];
      const newValue = getValFromPath(store, watcher.path);
      if (watcher.value !== newValue) {
        // eslint-disable-next-line
        console.warn(`Watcher: ${watcher.path}`, newValue);
        watcher.value = newValue;
      }
    }
  };

  store.subscribe(listener);

  const clear = () => {
    acc = [];
  };

  window.clearWatch = clear;

  return (path) => {
    const value = getValFromPath(store, path);
    acc.push({ path, value });

    // eslint-disable-next-line
    console.warn(`Watcher: ${path}`, value);
  };
};
