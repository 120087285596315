import { takeEvery, put } from 'redux-saga/effects';
import * as globalLocaleActions from 'global-locale/lib/actions/actionCreators';

import { UPDATE_LOCALE } from 'jsfcore/store/modules/locale';

export function* mergeLocale({ name, locale }) {
  yield put(globalLocaleActions.mergeLocale(name, locale));
}

export default function* localeSaga() {
  yield takeEvery(UPDATE_LOCALE, mergeLocale);
}
