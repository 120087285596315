import { put, select, take, all } from 'redux-saga/effects';
import { actionTypes } from 'ws-editor-lib/actions';
import { thisDevice } from '@pdffiller/jsf-useragent';
import { selectors, thunks } from '../..';

// For delete the content of the date element, you need update activeElement,
// for the correct sending operations to the server

export function* watchOnResetElements() {
  // eslint-disable-next-line no-constant-condition
  while (true) {
    yield take(actionTypes.RESET_ELEMENTS);
    yield take(actionTypes.ON_OPERATIONS_TOOLS_ELEMENTS);
    const firstFillableEnabledElemOnPage = yield select(
      selectors.elements.getFirstFillableEnabledElementOnActivePage,
    );
    const isVersionsMode = yield select(selectors.mode.isVersions);

    if (firstFillableEnabledElemOnPage && !isVersionsMode) {
      yield put(thunks.setActiveElement(firstFillableEnabledElemOnPage.id, true));
    }
  }
}

export default function* rootSaga() {
  yield all([
    // on IOS can't restore focus and show keyboard without user action
    // for desktop will be restored focus by setActiveElement
    // for mobile will be shown WizardPointer
    // https://pdffiller.atlassian.net/browse/JSF-4592
    ...(
      thisDevice.isDesktop
        ? [watchOnResetElements()]
        : []
    ),
  ]);
}
