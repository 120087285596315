import { select, put, takeEvery } from 'redux-saga/effects';
import { actionTypes } from 'ws-editor-lib/actions';
import { thunks, selectors } from 'jsfcore';

export function* onServerError() {
  const activeElementId = yield select(selectors.base.getActiveElementId);
  if (activeElementId) {
    yield put(thunks.setActiveElement(activeElementId, false));
  }
}

export function* watchServerError() {
  yield takeEvery(actionTypes.SERVER_ERROR, onServerError);
}
