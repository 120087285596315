const tooltipAnchorOutlineSize = 1;
const tooltipShadow = {
  top: 0,
  left: 1,
  right: 1,
  bottom: 4,
};

export default {
  postMeasure(args) {
    const { anchorRect, elementRect } = args;
    return {
      ...args,
      anchorRect: {
        ...anchorRect,
        top: anchorRect.top - tooltipAnchorOutlineSize,
        right: anchorRect.right + tooltipAnchorOutlineSize,
        bottom: anchorRect.bottom + tooltipAnchorOutlineSize,
        left: anchorRect.left - tooltipAnchorOutlineSize,

        width: anchorRect.width + tooltipAnchorOutlineSize * 2,
        height: anchorRect.height + tooltipAnchorOutlineSize * 2,
      },

      elementRect: {
        ...elementRect,
        top: elementRect.top + tooltipShadow.top,
        right: elementRect.right + tooltipShadow.right,
        bottom: elementRect.bottom + tooltipShadow.bottom,
        left: elementRect.left - tooltipShadow.left,

        width: elementRect.width + tooltipShadow.left + tooltipShadow.left,
        height: elementRect.height + tooltipShadow.top + tooltipShadow.bottom,
      },
    };
  },
};
