import get from 'lodash/get';

import { mocks } from './const';
import { modulesStore } from './loadModule';

const lazyGetMocks = {
  actions: mocks.null,
  components: mocks.component,
  functions: mocks.fns.null,
  objects: mocks.object,
};

const getDefaultValue = (fallback, type) => {
  if (fallback !== undefined) {
    return fallback;
  }

  return lazyGetMocks[type] || mocks.null;
};

function getLazy(importName, fallback) {
  if (importName === undefined) {
    // eslint-disable-next-line no-console
    console.error('an attempt to import lazy module that do not exist');
    return fallback || mocks.null;
  }

  const [, type] = importName.split('.');
  const defaultValue = getDefaultValue(fallback, type);

  return get(modulesStore, importName, defaultValue);
}

export default getLazy;
