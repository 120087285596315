import filter from 'lodash/filter';
import get from 'lodash/get';
import merge from 'lodash/merge';
import reduce from 'lodash/reduce';
import omit from 'lodash/omit';
import { actionTypes } from 'ws-editor-lib/actions';
import { SET_PA_ACTIVE_SECTION } from './viewport';
import { RESET_ALL_REDUCERS } from './events';

const {
  ADD_ELEMENT,
  ADD_ELEMENTS,
  ADD_FILLABLE_ELEMENT,
  ADD_FILLABLE_ELEMENTS,
  UPDATE_FILLABLE_ELEMENT,
  UPDATE_ELEMENT,
  UPDATE_ORDER_FILLABLE_ELEMENTS,
  UPDATE_ROUTING,
  UPDATE_FILLABLE_GROUPS,
  REMOVE_ELEMENT,
  REMOVE_FILLABLE_ELEMENT,
  REMOVE_ELEMENTS,
  SET_ACTIVE_SIGNER,
  SET_PA_SETTINGS,
  CHANGE_ELEMENT_PAGE_ID,
} = actionTypes;

const maxHistoryItems = 40;
const mergeableActionExpiredTime = 300; // in milliseconds

export const UNDO = 'undoRedo/UNDO';
export const REDO = 'undoRedo/REDO';
export const DIVIDE_UNDO_REDO = 'undoRedo/DIVIDE_UNDO_REDO';
export const MERGE_UNDO_REDO_FROM_WATERMARK = 'undoRedo/MERGE_UNDO_REDO_FROM_WATERMARK';
export const CLEAR_UNDO_REDO_HISTORY = 'undoRedo/CLEAR_UNDO_REDO_HISTORY';

export const cancellableKey = 'cancellable';

export const cancellableOpts = { [cancellableKey]: true };

export const isCancellableAction = (action) => {
  return action[cancellableKey] === true || (
    action.opts && action.opts[cancellableKey] === true
  );
};

const isMergeableExpired = (previousHistory) => {
  return (
    typeof previousHistory.actionDateTime !== 'undefined' &&
    Date.now() - previousHistory.actionDateTime > mergeableActionExpiredTime
  );
};

export const getOptsForUndoRedo = (opts = {}) => {
  return {
    fromUndoRedo: true,
    ...omit(opts, cancellableKey),
  };
};

export const addHistoryInfo = (action, undo, redo, elementId, additionalFields = {}) => {
  if (!undo || !redo) {
    return action;
  }

  const undoId = undo.id || elementId;
  const redoId = redo.id || elementId;

  return {
    ...action,
    undoRedo: {
      undo: typeof undo === 'function'
        ? undo
        : { ...undo, id: undoId, opts: getOptsForUndoRedo(undo.opts) },
      redo: typeof redo === 'function'
        ? redo
        : { ...redo, id: redoId, opts: getOptsForUndoRedo(redo.opts) },
      ...additionalFields,
    },
  };
};

export const initialState = {
  history: [],
  cursor: 0,
  isDivider: false,
  divideIndex: 0,
};

export const isUndoAvailable = (state) => {
  return state.history.length - state.cursor > 0;
};

export const isRedoAvailable = (state) => {
  return state.cursor !== 0;
};

// создает историю, по которой через undo возвращаешь значения до открытия
// watermark, а через redo - значения перед закрытием watermark
const getSoleUndoAndRedoHistory = (history) => {
  return reduce(history, (acc, historyStep) => {
    // отфильтровываем экшены, которые нужны только для конструктора watermark
    const undoActionsArray = filter(historyStep.undo, 'newAttrContent');
    const redoActionsArray = filter(historyStep.redo, 'newAttrContent');

    // массив undo/redo экшенов, сгруппированных по времени, объединяем в 1 экшен
    const soleUndoAction = merge({}, ...undoActionsArray);
    const soleRedoAction = merge({}, ...redoActionsArray);

    const prevAccUndoAction = get(acc, 'undo[0]', {});
    const prevAccRedoAction = get(acc, 'redo[0]', {});

    const newAcc = {
      undo: [merge(prevAccUndoAction, soleUndoAction)],
      redo: [merge(soleRedoAction, prevAccRedoAction)],
    };

    return newAcc;
  }, {});
};

export const generateHistoryFromWatermark = (history) => {
  if (Array.isArray(history) && history.length === 0) {
    return [];
  }

  const soleUndoAndRedoHistory = getSoleUndoAndRedoHistory(history);
  soleUndoAndRedoHistory.actionDateTime = Date.now();

  return [soleUndoAndRedoHistory];
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case RESET_ALL_REDUCERS:
    case CLEAR_UNDO_REDO_HISTORY:
      return initialState;

    case UNDO: {
      if (!isUndoAvailable(state)) {
        // eslint-disable-next-line no-console
        console.warn('undoRedo reducer warning there is no undo action');
        return state;
      }

      return {
        ...state,
        ...action.payload.updatedHistory && {
          history: action.payload.updatedHistory,
        },
        cursor: action.payload.cursor,
      };
    }

    case REDO: {
      if (!isRedoAvailable(state)) {
        // eslint-disable-next-line no-console
        console.warn('undoRedo reducer warning there is no redo action');
        return state;
      }

      return {
        ...state,
        ...action.payload.updatedHistory && {
          history: action.payload.updatedHistory,
        },
        cursor: action.payload.cursor,
      };
    }

    case DIVIDE_UNDO_REDO: {
      return {
        ...state,
        isDivider: true,
        divideIndex: action.payload.divideIndex,
      };
    }

    case ADD_FILLABLE_ELEMENT:
    case ADD_FILLABLE_ELEMENTS:
    case ADD_ELEMENT:
    case ADD_ELEMENTS:
    case UPDATE_ELEMENT:
    case UPDATE_ORDER_FILLABLE_ELEMENTS:
    case UPDATE_FILLABLE_GROUPS:
    case UPDATE_ROUTING:
    case SET_ACTIVE_SIGNER:
    case UPDATE_FILLABLE_ELEMENT:
    case REMOVE_FILLABLE_ELEMENT:
    case REMOVE_ELEMENT:
    case REMOVE_ELEMENTS:
    case SET_PA_SETTINGS:
    case SET_PA_ACTIVE_SECTION:
    case CHANGE_ELEMENT_PAGE_ID: {
      const { undoRedo } = action;
      if (undoRedo) {
        const newHistory = state.history.slice(state.cursor, maxHistoryItems + state.cursor);
        const previousUndoRedo = state.history[state.cursor];
        // если нет истории перед курсором или предыдуший экшн был разделителем
        // или таймстемп предыдущего экшена успел протухнуть,
        // то добавляем в историю новый экшн с массивами undo/redo
        if (!previousUndoRedo || isMergeableExpired(previousUndoRedo) || state.isDivider) {
          // если в разделителе был передан divideIndex,
          // то мы включаем в новый массив экшенов экшены из предыдущего массива
          // в количестве = divideIndex
          const addToNewUndoRedo = state.divideIndex && previousUndoRedo && {
            undo: previousUndoRedo.undo.slice(0, state.divideIndex),
            redo: previousUndoRedo.redo.slice(-state.divideIndex, previousUndoRedo.redo.length),
          };
          const newHistoryWithCuttedActions = (
            state.divideIndex &&
            previousUndoRedo &&
            previousUndoRedo.undo.length > state.divideIndex
          )
            ? [
              {
                ...previousUndoRedo,
                undo: previousUndoRedo.undo.slice(state.divideIndex),
                redo: previousUndoRedo.redo.slice(0, -state.divideIndex),
              },
              ...newHistory.slice(1),
            ]
            : newHistory.slice(1);

          return {
            ...state,
            cursor: 0,
            history: [
              {
                ...undoRedo,
                undo: addToNewUndoRedo
                  ? [undoRedo.undo, ...addToNewUndoRedo.undo]
                  : [undoRedo.undo],
                redo: addToNewUndoRedo
                  ? [...addToNewUndoRedo.redo, undoRedo.redo]
                  : [undoRedo.redo],
                actionDateTime: Date.now(),
              },
              ...(addToNewUndoRedo
                ? newHistoryWithCuttedActions
                : newHistory
              ),
            ],
            isDivider: false,
            divideIndex: 0,
          };
        }

        // в остальных случаях - добавляем в него новый экшн
        // из полученного undoRedo, и возвращаем стейт без добавления нового айтема в историю
        const updatedUndoRedo = {
          ...previousUndoRedo,
          undo: [
            undoRedo.undo,
            ...previousUndoRedo.undo,
          ],
          redo: [
            ...previousUndoRedo.redo,
            undoRedo.redo,
          ],
          actionDateTime: Date.now(),
        };

        return {
          ...state,
          history: [
            updatedUndoRedo,
            ...state.history.slice(1),
          ],
          isDivider: false,
          divideIndex: 0,
        };
      }

      return state;
    }

    case MERGE_UNDO_REDO_FROM_WATERMARK: {
      const history = state.history.slice();
      return {
        ...initialState,
        history: generateHistoryFromWatermark(history),
      };
    }

    default:
      return state;
  }
}
