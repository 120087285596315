import React from 'react';
import PropTypes from 'prop-types';
import cls from 'classnames';

export const thumbnailContentTypes = {
  canvas: 'canvas',
  null: null,
};

const PageThumbnailContent = ({ width, height, type, storeRef }) => {
  return (
    <canvas
      className={cls('media', {
        [`media--type--${type}`]: type,
      })}
      width={width}
      height={height}
      ref={storeRef}
    />
  );
};

PageThumbnailContent.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  type: PropTypes.oneOf(Object.values(thumbnailContentTypes)),
  storeRef: PropTypes.func,
};

PageThumbnailContent.defaultProps = {
  width: null,
  height: null,
  type: thumbnailContentTypes.null,
  storeRef: null,
};

export default PageThumbnailContent;
