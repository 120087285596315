import memoize from 'lodash/memoize';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import classnames from 'classnames';
import { DraggableCore } from 'react-draggable';
import { focusControllerDraggableDecorator } from '@pdffiller/jsf-focuscontroller';
import { thisDevice } from '@pdffiller/jsf-useragent';

const getDecoratedDraggableCore = memoize(() => {
  return focusControllerDraggableDecorator(thisDevice)(DraggableCore);
});
// TODO: correctly render on scale changes
/**
 * Make an EndPoint to drag line.
 * we have array of begin and end, draw a rect only at needed point
 */
export default class EndPoint extends Component {
  static propTypes = {
    onDragStart: PropTypes.func.isRequired,
    onDragMove: PropTypes.func.isRequired,
    onDragStop: PropTypes.func.isRequired,
    zIndex: PropTypes.number,
    disabled: PropTypes.bool,
    cursor: PropTypes.string,
    resizeIndex: PropTypes.number.isRequired,
    // for constructor style
    classMod: PropTypes.string.isRequired,
  };

  static defaultProps = {
    disabled: false,
    zIndex: 'auto',
    cursor: 'move',
  };

  onDragStart = (event) => {
    this.props.onDragStart(this.props.resizeIndex)(event);
  };

  onDragMove = (event) => {
    this.props.onDragMove(this.props.resizeIndex)(event);
  };

  onDragStop = (event) => {
    this.props.onDragStop(this.props.resizeIndex)(event);
  };

  render() {
    const { cursor, zIndex, classMod } = this.props;

    const DecoratedDraggableCore = getDecoratedDraggableCore();
    const endPointStyles = { cursor };
    const endpointClasses = classnames(
      'resize-handle',
      'resize-handle--theme--primary',
      'resize-handle--size--parent',
      'resize-handle--type--circle',
    );

    return (
      <DecoratedDraggableCore
        onStart={this.onDragStart}
        onDrag={this.onDragMove}
        onStop={this.onDragStop}
        disabled={this.props.disabled}
        enableUserSelectHack={false}
      >
        <div
          className={`endPointWrapper-EndPoint box__resize-handler box__resize-handler--${classMod}`}
          style={{ zIndex }}
        >
          <div
            className={endpointClasses}
            style={endPointStyles}
          />
        </div>
      </DecoratedDraggableCore>
    );
  }
}
