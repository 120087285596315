import uniqBy from 'lodash/uniqBy';
import get from 'lodash/get';
import filter from 'lodash/filter';
import flattenDeep from 'lodash/flattenDeep';
import flatten from 'lodash/flatten';

import { getNullDeps } from '../helpers/conditionsUtils';
import { getDependency, dependenciesMap } from '../di';

export const getChoiseByClickingModeIsActive = (state) => {
  return state.conditions.isActiveChoiseByClickingMode;
};

export const getActiveElementConditions = (state) => {
  const elementsSelectors = getDependency(dependenciesMap.elementsSelectors);

  const activeElement = elementsSelectors.getActiveElement(state);
  if (get(activeElement, 'template', false)) {
    return {
      id: activeElement.id,
      name: activeElement.template.name,
      conditional: activeElement.template.conditional || false,
      dependency: activeElement.template.dependency || getNullDeps(),
      roleId: activeElement.template.roleId,
      type: activeElement.type,
      subType: activeElement.subType,
    };
  }

  return {
    id: null,
    name: null,
    conditional: false,
    dependency: getNullDeps(),
    roleId: null,
    type: null,
    subType: null,
  };
};

const getStepIndexByRoleId = (steps, roleId) => {
  let index = 0;
  for (index; index < steps.length; index++) {
    const hasSigner = steps[index].signers.some(
      (signer) => {
        return signer.roleId === roleId;
      },
    );

    if (hasSigner) {
      break;
    }
  }

  return index;
};

export const isElementOnCurrentRoleOrOnNextStep = (steps, roleId, elemRoleId) => {
  const activeElementStepIndex = getStepIndexByRoleId(steps, roleId);
  const elementStepIndex = getStepIndexByRoleId(steps, elemRoleId);

  return (
    (activeElementStepIndex === elementStepIndex && elemRoleId !== roleId) ||
    (activeElementStepIndex < elementStepIndex)
  );
};

export const getSelectFields = (state) => {
  const elementsSelectors = getDependency(dependenciesMap.elementsSelectors);
  const getDeletedPages = getDependency(dependenciesMap.getDeletedPages);
  const isElementFromDeletedPage = getDependency(dependenciesMap.isElementFromDeletedPage);
  const isSignNow = getDependency(dependenciesMap.isSignNow);

  const elements = elementsSelectors.getFillableEnabledElements(state);
  const {
    name: activeElementName,
    roleId: activeElementRoleId,
  } = getActiveElementConditions(state);
  const deletedPages = getDeletedPages(state);

  return uniqBy(
    elements
      .filter(({ template: { roleId, name }, pageId }) => {
        const isActiveElementName = name === activeElementName;
        if (isElementFromDeletedPage(pageId, deletedPages) || isActiveElementName) {
          return false;
        }
        // in jsfiller we currently haven't roleId at template
        if (isSignNow()) {
          const snRoleSelectors = getDependency(dependenciesMap.snRoleSelectors);
          const steps = snRoleSelectors.getSteps(state);
          return !isElementOnCurrentRoleOrOnNextStep(steps, activeElementRoleId, roleId);
        }
        return true;
      })
      .map(({ template: { name } }) => {
        return {
          value: name,
          label: name,
        };
      }),
    'value',
  );
};

export const checkDependencyClauses = (clauses, name) => {
  return clauses.some((depElem) => {
    if (depElem.clauses) {
      return checkDependencyClauses(depElem.clauses, name);
    }
    return name === depElem.name;
  });
};

export const getDependenciesValuesDeep = (clauses) => {
  return flattenDeep(clauses.map((depElem) => {
    if (depElem.clauses) {
      return getDependenciesValuesDeep(depElem.clauses);
    }
    return depElem.value;
  }));
};

export const getDependenciesValuesByNameDeep = (dependency, name) => {
  if (dependency.clauses) {
    return flatten(
      dependency.clauses
        .map((clause) => {
          return getDependenciesValuesByNameDeep(clause, name);
        }),
    );
  }
  if (name !== dependency.name) {
    return [];
  }
  return [dependency.value];
};

export const getAllDependedElementsFromElement = (state, elementId) => {
  const jsfcoreSelectors = getDependency(dependenciesMap.jsfcoreSelectors);
  const elementsSelectors = getDependency(dependenciesMap.elementsSelectors);

  const element = jsfcoreSelectors.elements.getElementByIdFactory(elementId)(state);
  const elementTemplate = get(element, 'template', null);

  const elements = elementsSelectors.getFillableEnabledElements(state);

  if (!elementTemplate) {
    return [];
  }

  return filter(elements, (el) => {
    const elementDependency = get(el, 'template.dependency', {});

    return elementDependency.name === elementTemplate.name || (
      elementDependency.clauses &&
      checkDependencyClauses(elementDependency.clauses, elementTemplate.name)
    );
  });
};

export const temp = null;
