import React from 'react';
import PropTypes from 'prop-types';

const ImagePlaceholder = ({ innerText, size, fontSize, paddingLeft }) => {
  if (!innerText) {
    return null;
  }

  const style = {
    fontSize,
  };

  return (
    <div className="fillable-field__text" style={{ paddingLeft }}>
      <span
        className={`text text--size--${size}`}
        style={style}
      >
        {innerText}
      </span>
    </div>
  );
};

ImagePlaceholder.propTypes = {
  innerText: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.string,
  ]),
  size: PropTypes.number,
  fontSize: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  paddingLeft: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
};

ImagePlaceholder.defaultProps = {
  innerText: null,
  size: 14,
  fontSize: 14,
  paddingLeft: 7,
};

export default ImagePlaceholder;
