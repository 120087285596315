export default () => {
  return (next) => {
    return (action) => {
      if (!window.lazyMiddlewaresChain) {
        return next(action);
      }
      return window.lazyMiddlewaresChain(next)(action);
    };
  };
};
