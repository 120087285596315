import PropTypes from 'prop-types';
import React, { Component } from 'react';
import cls from 'classnames';

export const colorPickerOffsets = {
  extraSmall: 'extra-small',
  medium: 'medium',
  null: null,
};

export const colorPickerTypes = {
  flexible: 'flexible',
  null: null,
};

export default class ColorPicker extends Component {
  static propTypes = {
    children: PropTypes.node,
    offset: PropTypes.oneOf(
      Object.values(colorPickerOffsets),
    ),
    type: PropTypes.oneOf(
      Object.values(colorPickerTypes),
    ),
  };

  static defaultProps = {
    children: null,
    offset: colorPickerOffsets.null,
    type: colorPickerTypes.null,
  };

  render() {
    const { offset, type } = this.props;

    const className = cls('color-picker', {
      [`color-picker--offset--${offset}`]: offset,
      [`color-picker--type--${type}`]: type,
    });

    return (
      <div className={className}>
        {this.props.children}
      </div>
    );
  }
}
