const anchorMargin = 4;
const elementMargin = 1;

export default {
  postMeasure(args) {
    const { anchorRect, elementRect } = args;
    return {
      ...args,
      anchorRect: {
        ...anchorRect,
        top: anchorRect.top - anchorMargin,
        bottom: anchorRect.bottom + anchorMargin,
        height: anchorRect.height + anchorMargin * 2,
      },

      elementRect: {
        ...elementRect,
        right: elementRect.right + elementMargin,
        left: elementRect.left - elementMargin,
        width: elementRect.width + elementMargin * 2,
      },
    };
  },
};
