import React from 'react';
import PropTypes from 'prop-types';

const SettingsBoxGroup = ({ children }) => {
  return (
    <div className="settings-box__group">
      {children}
    </div>
  );
};

SettingsBoxGroup.propTypes = {
  children: PropTypes.node.isRequired,
};

export default SettingsBoxGroup;
