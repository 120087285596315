import get from 'lodash/get';
import { elemTypes } from './elemTypes';

export const getCroppedSize = (contentSize, { width, height }) => {
  const wFactor = width / contentSize.width;
  const hFactor = height / contentSize.height;
  const minFactor = Math.min(wFactor, hFactor);
  return {
    width: contentSize.width * minFactor,
    height: contentSize.height * minFactor,
  };
};

export const getContentXYSignature = (contentSize, { width, height, x, y }) => {
  const croppedSize = getCroppedSize(contentSize, { width, height });

  return {
    contentX: x,
    contentY: (y + height) - croppedSize.height,
    ...croppedSize,
  };
};

export const getContentXYPicture = (contentSize, { width, height, x, y }) => {
  const croppedSize = getCroppedSize(contentSize, { width, height });

  return {
    contentX: x + ((width - croppedSize.width) / 2),
    contentY: y + ((height - croppedSize.height) / 2),
    ...croppedSize,
  };
};

export const getContentXY = (element) => {
  const contentSize = {
    width: get(element, 'content.width', element.template.width),
    height: get(element, 'content.height', element.template.height),
  };

  if (element.type === elemTypes.signature) {
    return getContentXYSignature(contentSize, element.template);
  }

  if (element.type === elemTypes.image) {
    return getContentXYPicture(contentSize, element.template);
  }

  return {};
};
