import React from 'react';
import PropTypes from 'prop-types';

const FillableFieldLabelCell = ({ html, style }) => {
  return (
    <span
      className="fillable-field__label__cell"
      style={style}
      // eslint-disable-next-line react/no-danger
      dangerouslySetInnerHTML={{ __html: html }}
    />
  );
};

FillableFieldLabelCell.propTypes = {
  html: PropTypes.string.isRequired,
  style: PropTypes.shape({}),
};

FillableFieldLabelCell.defaultProps = {
  style: null,
};

export default FillableFieldLabelCell;
