import PropTypes from 'prop-types';
import React from 'react';
import { portalLayerClassName } from '../helpers/const';

const PortalLayer = ({ storeRef }) => {
  return (
    <div ref={storeRef} className={portalLayerClassName} />
  );
};

PortalLayer.propTypes = {
  storeRef: PropTypes.func.isRequired,
};
export default PortalLayer;
