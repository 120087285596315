// import 'babel-polyfill';
import React from 'react';
import { render, hydrate } from 'react-dom';
import { Provider } from 'react-redux';
import deepForceUpdate from 'react-deep-force-update';
import { loadableReady } from '@loadable/component';

import logger from '@pdffiller/jsf-logger/clientLogger';
import LegacyContextProvider from 'jsfcore/components/LegacyContextProvider';
import { thisDevice } from '@pdffiller/jsf-useragent';

import { initializeSksTracker } from './helpers/external';

import Root from './Root';
import createStore from './store/create';

const dest = document.getElementById('content');

// eslint-disable-next-line
const store = createStore(window.__data);

logger.addStore(store);

loadableReady(() => {
  /**
   * on iOS13 and maybe in other cases in future we has
   * to discard ServerSideRendering and rerender app completely.
   *
   * Do we need to discardSSR or not, we resolve in jsf-useragent/index.js
   */
  const rootRenderFunction = thisDevice.discardSSR
    ? render
    : hydrate;

  window.reactInstance = rootRenderFunction(
    <Provider store={store} key="provider">
      <LegacyContextProvider store={store}>
        <Root />
      </LegacyContextProvider>
    </Provider>,
    dest,
  );
});

if (__DEVELOPMENT__) {
  window.React = React;
}

// Enable Hot Module Replacement (HMR)
if (module.hot) {
  module.hot.accept('./Root', () => {
    if (
      window.reactInstance &&
      window.reactInstance.updater.isMounted(window.reactInstance)
    ) {
      // Force-update the whole tree, including components that refuse to update
      deepForceUpdate(window.reactInstance);
    }

    window.reactInstance = render(
      <Provider store={store} key="provider">
        <LegacyContextProvider store={store}>
          <Root />
        </LegacyContextProvider>
      </Provider>,
      dest,
    );
  });
}

initializeSksTracker();
