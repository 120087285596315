import { createSelector } from 'reselect';
import find from 'lodash/find';
import uniq from 'lodash/uniq';
import findIndex from 'lodash/findIndex';
import get from 'lodash/get';
import set from 'lodash/set';
import { utils } from 'ws-editor-lib';

import isSignNow from '../../helpers/const/isSignNow';
import { numberRegex } from '../../helpers/const';
import { elemTypes, elemSubTypes } from '../../helpers/elemTypes';
import {
  isFormulaElement,
  isDropdownElement,
  isDisabledElement,
  isFillable,
  isElementFromDeletedPage,
  isRadioElement,
  isCheckmarkElement,
  isRequiredElement,
  isFilledElement,
  isOptionalElement,
} from '../helpers/functions';

import * as jsfValidationsSelectors from '../../jsf-validations/selectors/validatorsSelectors';

import * as baseSelectors from './baseSelectors';
import * as helpers from './helpers';
import { getDeletedPages, getPages } from './wsPagesSelectors';

/**
 * Selectors for elements
 */
class ElementsSelectors {
  /**
   * getSplittedElementsIdsByPageIdCache is cache for selectors
   * which are created by getSplittedElementsIdsByPageIdFactory
   * @type {Object}
   */
  getSplittedElementsIdsByPageIdFactoryCache = {};

  /**
   * Return function (with memoization) which
   * return ids of elements from pageId
   * @param {number} pageId
   * @returns {function}
   */
  getSplittedElementsIdsByPageIdFactory = (pageId) => {
    if (!this.getSplittedElementsIdsByPageIdFactoryCache[pageId]) {
      this.getSplittedElementsIdsByPageIdFactoryCache[pageId] = createSelector(
        [baseSelectors.getElements],

        /**
         * @typedef {Object} ElementsIds
         * @property {array} allElementsIds
         * @property {array} eraseElementsIds
         * @property {array} otherElementsIds
         *
         * @param {array} elements
         * @returns {ElementsIds}
         */
        (elements) => {
          const allElementsIds = [];
          const eraseElementsIds = [];
          const otherElementsIds = [];

          for (let i = 0; i < elements.length; i++) {
            const element = elements[i];
            if (element.pageId === pageId) {
              if (element.type === elemTypes.erase) {
                eraseElementsIds.push(element.id);
              } else {
                otherElementsIds.push(element.id);
              }
              allElementsIds.push(element.id);
            }
          }

          return {
            allElementsIds,
            eraseElementsIds,
            otherElementsIds,
          };
        },
      );
    }

    return this.getSplittedElementsIdsByPageIdFactoryCache[pageId];
  };

  getElementsIds = createSelector(
    [baseSelectors.getElements],
    (elements) => {
      const elementsIds = [];

      for (let i = 0; i < elements.length; i++) {
        elementsIds.push(elements[i].id);
      }

      return elementsIds;
    },
  );

  /**
   * elementsIdsCache - memoized elementsIds list
   * @type {Null|Array}
   */
  elementsIdsCache = null;

  /**
   * sortedElementsIdsCache - memoized sortedElementsIds list
   * @type {Null|Array}
   */
  sortedElementsIdsCache = null;

  /**
   * pagesCache - memoized pages list
   * @type {Null|Array}
   */
  pagesCache = null;

  getSortedElementsIdsByPage = (state) => {
    const pages = getPages(state);
    if (!pages) {
      return [];
    }

    if (this.sortedElementsIdsCache) {
      /*
        когда у нас есть закешированный список отсортированных по страницам id
        нам нужно проверить, не изменился ли порядок или количество элементов
        если были изменения обновляем кэш
      */
      const elementsIds = this.getElementsIds(state);
      if (elementsIds !== this.elementsIdsCache) {
        this.elementsIdsCache = elementsIds;
        this.sortedElementsIdsCache = null;
      }
    }

    if (this.pagesCache !== pages || !this.sortedElementsIdsCache) {
      /*
        если изменился порядок страниц или у нас нет отсортированных по страница id
        сортируем id и обновляем кэш
      */
      const sortedElementsIds = [];

      for (let pageIndex = 0; pageIndex < pages.length; pageIndex++) {
        const pageSource = pages[pageIndex].source;
        const { allElementsIds } = this.getSplittedElementsIdsByPageIdFactory(pageSource)(state);
        sortedElementsIds.push(...allElementsIds);
      }

      this.pagesCache = pages;
      this.sortedElementsIdsCache = sortedElementsIds;
    }

    return this.sortedElementsIdsCache;
  }

  getElementFromMapFactory = (elementId) => {
    return createSelector(
      [baseSelectors.getElementsMap],
      (elementsMap) => {
        return elementsMap[elementId];
      },
    );
  };

  // Используется только для getActiveElement
  getIsElementCheckedFactory = (elementId) => {
    return (state) => {
      const elementsMap = baseSelectors.getElementsMap(state);

      const element = elementsMap[elementId];

      if (!element) {
        return undefined;
      }

      if (isRadioElement(element)) {
        const elementRadioGroupName = utils.getElementGroupNames(element).radio;
        const checkedElement = this.getCheckedElementInsideRadioGroup(state, elementRadioGroupName);
        return checkedElement !== undefined;
      }

      if (isCheckmarkElement(element)) {
        return element && element.content && element.content.checked;
      }

      return undefined;
    };
  };

  /**
   * getElementByIdFactoryCache is cache for selectors
   * which are created by getElementByIdFactory
   * @type {Object}
   */
  getElementByIdFactoryCache = {};

  getElementByIdFactory = (elementId) => {
    if (!this.getElementByIdFactoryCache[elementId]) {
      const getElementFromMap =
        this.getElementFromMapFactory(elementId);
      const getIsEnabledByDependencies =
        helpers.getIsEnabledByDependenciesSelector(elementId);
      const getIsElementChecked =
        this.getIsElementCheckedFactory(elementId);

      this.getElementByIdFactoryCache[elementId] =
        createSelector(
          [
            getElementFromMap,
            baseSelectors.getRoleId,
            getIsEnabledByDependencies,
            jsfValidationsSelectors.getMergedValidators,
            baseSelectors.getFlatGroups,
            getIsElementChecked,
          ],
          (
            element,
            roleId,
            isEnabledByDependencies,
            validators,
            flatGroups,
            isChecked,
          ) => {
            if (!element) {
              return false;
            }

            const elementArg = element;
            const elementWithConditional = helpers.mixConditionalProperty(elementArg);
            const isDisabled = isDisabledElement(elementWithConditional, roleId);
            const isMemberOfFlatGroup = elementArg.template
              ? flatGroups.hasOwnProperty(utils.createFlatName(elementArg))
              : false;
            const isElementCanBeChecked = isCheckmarkElement(element) || isRadioElement(element);

            const el = {
              ...elementWithConditional,
              enabled: !isDisabled && isEnabledByDependencies,
              isEnabledByDependencies,
              isMemberOfFlatGroup,
              ...isElementCanBeChecked && { isChecked },
            };

            return helpers.mixValidator(el, validators);
          },
        );
    }

    return this.getElementByIdFactoryCache[elementId];
  };

  getActiveElement = (state) => {
    const activeElementId = baseSelectors.getActiveElementId(state);

    return (
      activeElementId &&
      this.getElementByIdFactory(activeElementId)(state)
    );
  };

  // elementsCache(used in getElements) stores all elements, but if some element changed or added
  // cache will be updated(new link to cache, !shallow equal)
  // not updated elements will be same(shallow equal)
  elementsCache = null;

  // elementsCacheByType(used in getElementsByType) stores all elements by type
  // elementsCacheByType.erase = [...], elementsCacheByType.checkmark = [...], etc
  // if some element changed or added
  // cache by type will be updated(new link to cache, !shallow equal)
  // not updated elements will be same(shallow equal)
  // together with getElementsByType resolves cache updating
  // only for changes in elements by same type
  // if FakeEditLayer(getTextContent -> getScaledEraseElementShapes),
  // uses elementsCache and getElements, it rerenders for any changes in any elements
  // but it use only erase elements
  // elementsCacheByType and getElementsByType resolves this issue
  elementsCacheByType = {};

  elementsCachePath = 'elementsCache';

  elementsCacheByTypeRoot = 'elementsCacheByType';

  getElementsCacheByTypePath = (type) => {
    return `${this.elementsCacheByTypeRoot}.${type}`;
  };

  updateElementsCacheAndReturnIt = (elements, cachePath = this.elementsCachePath) => {
    set(this, cachePath, elements);
    return elements;
  };

  getElementsWithoutCache = (state) => {
    const elementsIds = this.getSortedElementsIdsByPage(state);
    const elements = [];

    for (let i = 0; i < elementsIds.length; i++) {
      const getElement =
        this.getElementByIdFactory(elementsIds[i]);
      elements.push(getElement(state));
    }

    return elements;
  };

  getElementsCache = (elements, cachePath = this.elementsCachePath) => {
    const cache = get(this, cachePath, null);

    if (!cache) {
      return this.updateElementsCacheAndReturnIt(elements, cachePath);
    }

    if (cache.length !== elements.length) {
      return this.updateElementsCacheAndReturnIt(elements, cachePath);
    }

    for (let i = 0; i < elements.length; i++) {
      if (elements[i] !== cache[i]) {
        return this.updateElementsCacheAndReturnIt(elements, cachePath);
      }
    }

    return cache;
  };

  getElements = (state) => {
    const elements = this.getElementsWithoutCache(state);

    return this.getElementsCache(elements);
  };

  getElementsByType = (type) => {
    const cachePath = this.getElementsCacheByTypePath(type);

    return (state) => {
      const elements = baseSelectors.getElements(state).filter((element) => {
        return element.type === type;
      });

      return this.getElementsCache(elements, cachePath);
    };
  };

  getElementsIdByRadioGroup = (state, groupName) => {
    return baseSelectors.getRadioGroups(state)[groupName];
  };

  getElementsByRadioGroup = (radioGroupId) => {
    return (state) => {
      const ids = this.getElementsIdByRadioGroup(state, radioGroupId) || [];
      const elementsMap = baseSelectors.getElementsMap(state);

      return ids.map((id) => {
        return elementsMap[id];
      });
    };
  };

  getCheckedElementInsideRadioGroup = (state, groupName) => {
    const elementIds = this.getElementsIdByRadioGroup(state, groupName);
    if (elementIds === undefined) {
      return false;
    }

    const elementsMap = baseSelectors.getElementsMap(state);
    const checkedElement = elementIds
      .map((elementId) => {
        return elementsMap[elementId];
      })
      .find((element) => {
        return element.content && element.content.checked;
      });

    return checkedElement;
  };

  getElementToRemove = (state, element) => {
    if (isRadioElement(element)) {
      const elementRadioGroupName = utils.getElementGroupNames(element).radio;
      // detect checked radio button and use it in remove action
      // it will help us to roll back checked value in UNDO use case
      // other radio buttons within group will be cleared by ws-editor-lib
      const checkedElement = this.getCheckedElementInsideRadioGroup(
        state, elementRadioGroupName,
      );
      if (checkedElement) {
        return checkedElement;
      }
    }
    return element;
  };

  getEnabledElements = createSelector(
    [this.getElements],
    (elements) => {
      return elements.filter((el) => {
        return el.enabled;
      });
    },
  );

  fillableElementsFilter = (elements) => {
    return elements.filter((el) => {
      return Boolean(el.template);
    });
  };

  fillableNotFormulasElementsFilter = (elements) => {
    return elements.filter((el) => {
      return isFillable(el) && !isFormulaElement(el);
    });
  };

  getFillableElements = createSelector(
    this.getElements,
    this.fillableElementsFilter,
  );

  getFillableEnabledElements = createSelector(
    [this.getEnabledElements],
    this.fillableElementsFilter,
  );

  getFillableNotFormulasEnabledElements = createSelector(
    [this.getEnabledElements],
    this.fillableNotFormulasElementsFilter,
  );

  getFirstFillableEnabledElementOnActivePage = createSelector(
    [this.getFillableEnabledElements, baseSelectors.getActivePageId],
    (elements, activePageId) => {
      const firstFillableEnabledElemOnPage = find(elements, (elem) => {
        return elem.pageId === activePageId && !isFormulaElement(elem);
      });

      return firstFillableEnabledElemOnPage || false;
    },
  );

  getElementsNamesWithoutValidation = createSelector(
    [
      this.getFillableEnabledElements,
      jsfValidationsSelectors.getNumberValidatorsIds,
    ],
    (elements, numberValidatorsIds) => {
      return elements
        .filter(
          (element) => {
            if (element.subType === elemSubTypes.text.dropdown) {
              const hasAllowCustomText = get(element, 'template.allowCustomText', false);

              // elements with this flag don't participated in validation
              if (hasAllowCustomText) {
                return true;
              }

              const dropdownOptions = get(element, 'content.list', []);

              if (dropdownOptions.length) {
                const hasAtLeastOneNonNumericOption = dropdownOptions.some((dropdownOption) => {
                  return !numberRegex.test(dropdownOption);
                });

                return hasAtLeastOneNonNumericOption;
              }
            }

            return (
              element.subType !== elemSubTypes.text.formula &&
              element.subType !== elemSubTypes.text.dropdown &&
              (
                !element.template.validatorId ||
                !numberValidatorsIds.includes(element.template.validatorId)
              )
            );
          },
        ).map(
          (element) => {
            return element.template.name;
          },
        );
    });

  getValidElementsForFormula = createSelector(
    [
      this.getFillableElements,
      baseSelectors.getActiveElementId,
      jsfValidationsSelectors.getFormulaValidators,
      getDeletedPages,
    ],
    (elements, activeElementId, formulaValidators, deletedPages) => {
      const isSignNowEditor = isSignNow();

      return uniq(
        elements.filter((element) => {
          if (
            isElementFromDeletedPage(element.pageId, deletedPages) ||
            element.type !== elemTypes.text ||
            (!isSignNowEditor && element.id === activeElementId)
          ) {
            return false;
          }

          const isDropdown = isDropdownElement(element);
          const hasFormulaValidator = find(formulaValidators, ({ id }) => {
            return element.template.validatorId === id;
          });
          const isFormula = isFormulaElement(element);

          return hasFormulaValidator || isFormula || isDropdown;
        }).map((element) => {
          return element.template.name;
        }),
      );
    },
  );

  getHasFillableFields = createSelector(
    [this.getElements],
    (elements) => {
      return findIndex(elements, isFillable) > -1;
    },
  );

  getElementsNames = createSelector(
    [this.getElements],
    (elements) => {
      return uniq(elements.map((element) => {
        return get(element, 'template.name', null);
      }));
    },
  );

  getFillableElementsNames = createSelector(
    [this.getFillableElements],
    (elements) => {
      return uniq(elements.map((element) => {
        return get(element, 'template.name', null);
      }));
    },
  );

  getOnlyFormulaElements = createSelector(
    [this.getElements],
    (elements) => {
      const formulaElements = elements.filter(isFormulaElement);
      return formulaElements;
    },
  );

  getHasOnlyFormulaElements = createSelector(
    [this.getFillableElements, this.getOnlyFormulaElements],
    (fillableElements, formulaElements) => {
      if (fillableElements.length === formulaElements.length) {
        return true;
      }

      return false;
    },
  );

  getFillableRequiredEnabledElements = createSelector(
    [this.getFillableEnabledElements],
    (fillableElements) => {
      return fillableElements.filter(isRequiredElement);
    },
  );

  getFilledFillableRequiredElements = createSelector(
    [this.getFillableRequiredEnabledElements],
    (fillableElements) => {
      return fillableElements.filter(isFilledElement);
    },
  );

  getFillableOptionalEnabledElements = createSelector(
    [this.getFillableEnabledElements],
    (fillableElements) => {
      return fillableElements.filter(isOptionalElement);
    },
  );

  getFilledFillableOptionalElements = createSelector(
    [this.getFillableOptionalEnabledElements],
    (fillableElements) => {
      return fillableElements.filter(isFilledElement);
    },
  );

  getLastActiveFillableElement = (state) => {
    const lastActiveFillableElementId = baseSelectors.getLastActiveFillableElementId(state);

    return this.getElementByIdFactory(lastActiveFillableElementId)(state);
  };
}

const elementsSelectorsInstance = new ElementsSelectors();

export default elementsSelectorsInstance;
