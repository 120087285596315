import { useEffect } from 'react';
import useDisableBlur from './useDisableBlur';

const FocusControllerDisableBlur = ({ disabled }) => {
  const [disable, enable] = useDisableBlur();

  useEffect(() => {
    if (disabled) {
      disable();
    } else {
      enable();
    }
  }, [disabled]);

  return null;
};

export default FocusControllerDisableBlur;
