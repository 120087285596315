import PropTypes from 'prop-types';
import React, { Component } from 'react';
import cls from 'classnames';

export const editorActionSizes = {
  parent: 'parent',
  medium: 'medium',
  small: 'small',
  extraSmall: 'extra-small',
  extraSmallFlexible: 'extra-small-flexible',
  xxsmall: 'xxsmall',
  large: 'large',
  parentHeight: 'parent-height',
  null: null,
};

export const editorActionTypes = {
  compact: 'compact',
  null: null,
};

export const editorActionOffsets = {
  medium: 'medium',
  null: null,
};

export default class EditorAction extends Component {
  static propTypes = {
    children: PropTypes.node,
    size: PropTypes.oneOf(
      Object.values(editorActionSizes),
    ),
    type: PropTypes.oneOf(
      Object.values(editorActionTypes),
    ),
    offset: PropTypes.oneOf(
      Object.values(editorActionOffsets),
    ),
    storeRef: PropTypes.func,
  };

  static defaultProps = {
    children: null,
    size: editorActionSizes.medium,
    type: editorActionTypes.null,
    offset: editorActionOffsets.null,
    storeRef: null,
  };

  render() {
    const { size, type, offset } = this.props;

    return (
      <div
        ref={this.props.storeRef}
        className={
          cls(
            'editor-action', {
              [`editor-action--size--${size}`]: size,
              [`editor-action--type--${type}`]: type,
              [`editor-action--offset--${offset}`]: offset,
            },
          )
        }
      >
        {this.props.children}
      </div>
    );
  }
}
