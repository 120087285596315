import moment from 'moment';
import findLastIndex from 'lodash/findLastIndex';
import find from 'lodash/find';
import findIndex from 'lodash/findIndex';
import pick from 'lodash/pick';
import pickBy from 'lodash/pickBy';
import get from 'lodash/get';
import omit from 'lodash/omit';
import uniqBy from 'lodash/uniqBy';
import startsWith from 'lodash/startsWith';
import difference from 'lodash/difference';
import isEmpty from 'lodash/isEmpty';
import isArray from 'lodash/isArray';
import { defaultMemoize } from 'reselect';
import cloneDeep from 'lodash/cloneDeep';
import flow from 'lodash/flow';
import isEqual from 'lodash/isEqual';

import {
  getIndexByPageId,
  getAdjacentPageId,
  propsInheritanceByGroupMap,
  getNeedUpdateGhost,
  getFromDefaultsTemplate,
} from '../../../helpers';
import {
  GROUPS,
  DEFAULT_DROPDOWN_LIST,
  TYPES,
  TOOL_TYPES,
  TOOL_SUB_TYPES,
} from '../../../../constants';
import isSignNow from '../../../../isSignNow';

const ID = 'id';

const VISIBLE_CONTENT_PROPERTY = 'visible';
const PLACEHOLDER_TEMPLATE_PROPERTY = 'placeholder';
export const DEFAULT_SUBTYPE = 'subType';

const DEFAULT_LETTER_WIDTH = 814;
/**
 * Previously here was IMAGE_MAX_WIDTH
 * without any round, but in every part
 * of code where they used that variable,
 * they were rounding, so I got rid of it
 */
const IMAGE_MAX_WIDTH = Math.floor(DEFAULT_LETTER_WIDTH / 4);
const SIGNATURE_SCALE_HEIGHT = 35;
const SIGNATURE_WIDTH_HEIGHT_RATIO = 2.5;


const CONFIRMED_PROPERTY_NAME = 'confirmed';

const TO_FIXED_FOR_CONTROL_POINTS = 2;

const DEFAULT_CONTENT_TEXT = '';

const DEFAULT_TEXT_FIELD_VIEW_MODE = 'classic';
// Tool for constructor

export const DEFAULT_DATE_FORMAT = 'MM/DD/YYYY';

export const fillableActionsTypes = {
  add: TYPES.add,
  remove: 'remove',
  edit: 'edit',
};

// TODO: write tests for all functions

export const createMapFromElements = defaultMemoize((elements) => {
  return elements.reduce(
    (acc, element) => {
      acc[element.id] = element;
      return acc;
    }, {});
});

export const createFlatId = ({ clientId, localId }) => {
  return `${clientId}-${localId}`;
};

export const isConfirmedElementOperation = (operation) => {
  return operation.hasOwnProperty(CONFIRMED_PROPERTY_NAME);
};

export const isVisible = (properties) => {
  return get(properties, `content.${VISIBLE_CONTENT_PROPERTY}`, true);
};

export const isVisibleTemplate = (properties) => {
  return get(properties, `template.${VISIBLE_CONTENT_PROPERTY}`, true);
};

const isClearOperation = (operation) => {
  const hasTemplate = get(operation, 'properties.template');
  const isVisibleContent = isVisible(operation.properties);

  return (
    (hasTemplate && isVisibleTemplate(operation.properties)) &&
    !isVisibleContent
  );
};

export const isDeleteOperation = (operation) => {
  const hasTemplate = get(operation, 'properties.template');
  const isVisibleContent = isVisible(operation.properties);

  return (
    (hasTemplate && !isVisibleTemplate(operation.properties)) ||
    (!hasTemplate && !isVisibleContent)
  );
};

export const isOperationElementRemoved = (operation, elementsMap) => {
  const id = createFlatId(operation.properties.element);
  const hasTemplate = get(operation, 'properties.template');

  if (!elementsMap[id]) {
    return true;
  }

  // https://pdffiller.atlassian.net/browse/JSF-6671
  // for case when used undo/redo and element was restored before received response from socket
  return hasTemplate
    ? !isVisibleTemplate(elementsMap[id])
    : !isVisible(elementsMap[id]);
};

export const isElementOperation = (operation) => {
  return operation.id && operation.properties.element;
};

export const needHandleOperationFromServer = ({
  operation,
  operations,
  operationIndex,
  elementsMap,
}) => {
  if (!isElementOperation(operation)) {
    return false;
  }

  if (isClearOperation(operation)) {
    /**
     * В обычной ситуации Clear операции мы не обрабатываем т.к.
     * пользователь может успеть ввести что-то в поле после очистки
     * и на момент, когда придет isClearOperation очистки пользователь уже успеет
     * ввести какой-то контент в поле;
     *
     * Но иногда бывает другая ситуация. Если открыть проект в соседней вкладке -
     * ws отправляет закешированные операции списком. И в этих операция может быть
     * операция очистки. Игнорировать ее нельзя.
     *
     * Не совсем ясно как детектить это, поэтому пробую так –
     * если приходит isClearOperation а в массиве операций уже есть операция для
     * этого элемента до текущей – то применяем ее.
     */
    for (let i = 0; i < operationIndex; i++) {
      if (operation.properties.id === operations[i].properties.id) {
        return true;
      }
    }

    return false;
  }

  if (isDeleteOperation(operation)) {
    return isOperationElementRemoved(operation, elementsMap);
  }

  if (isConfirmedElementOperation(operation)) {
    return false;
  }

  return true;
};

export const isElementText = (element) => {
  return element.type === TOOL_TYPES.text;
};
export const isElementCheckmark = (element) => {
  return element.type === TOOL_TYPES.checkmark;
};
export const isRadioButton = (element) => {
  return element.type === TOOL_TYPES.radio;
};
export const isElementDate = (element) => {
  return isElementText(element) && element[DEFAULT_SUBTYPE] === TOOL_TYPES.date;
};
export const isElementDropdown = (element) => {
  return isElementText(element) && element[DEFAULT_SUBTYPE] === TOOL_TYPES.dropdown;
};
export const isElementSignature = (element) => {
  return element.type === TOOL_TYPES.signature;
};
export const isElementImage = (element) => {
  return element.type === TOOL_TYPES.image;
};
export const isElementErase = (element) => {
  return element.type === TOOL_TYPES.erase;
};
export const isElementOverlyingText = (element) => {
  return isElementText(element) && element.subType === TOOL_TYPES.overlying;
};
export const isElementComment = (element) => {
  return element.type === TOOL_TYPES.comment;
};
export const isElementFormula = (element) => {
  return isElementText(element) && element[DEFAULT_SUBTYPE] === TOOL_TYPES.formula;
};
export const isElementSmart = (element) => {
  return element.type === TOOL_TYPES.smartfield;
};
export const isElementSalesforce = (element) => {
  return element.type === TOOL_TYPES.salesforce;
};
export const isFillableElement = (element) => {
  return Boolean(element.template);
};
export const omitPlaceholder = (template) => {
  return omit(template, PLACEHOLDER_TEMPLATE_PROPERTY);
};
export const getIsGalleryOrSignature = (element) => {
  return isElementImage(element) || isElementSignature(element);
};
export const getIsGalleryOrSignatureOrCheckmarkOrRadio = (element) => {
  return (
    getIsGalleryOrSignature(element) ||
    isElementCheckmark(element) ||
    isRadioButton(element)
  );
};
// true in all cases, except element.content object isn't empty
export const isEmptyFillableElement = (element) => {
  if (!element || !isFillableElement(element)) {
    return false;
  }

  if (isSignNow()) {
    const contentKeys = Object.keys(get(element, 'content', {}));
    const template = omitPlaceholder(get(element, 'template', {}));
    const templateKeys = Object.keys(template);

    return difference(templateKeys, contentKeys).length > 0;
  }

  // https://pdffiller.atlassian.net/browse/JSF-6470
  // Убрал return true, если у контента меньше ключей, чем у темплейта
  // Так как это приводило к ненужным апдейтам на focus out элемента
  return element.content === undefined;
};

export const isFilledFillableElement = (element) => {
  return (element && isFillableElement(element) && element.content);
};

export const getNextLocalId = (sendOperationsCount) => {
  return ({
    ...sendOperationsCount,
    local: sendOperationsCount.local + 1,
  });
};

export const createId = ({ local }) => {
  return ({
    clientId: 0,
    localId: local,
  });
};

export const createIdAndNext = (lastOperationsCount) => {
  const sendOperationsCount = getNextLocalId(lastOperationsCount);
  const id = createId(sendOperationsCount);
  return { id, sendOperationsCount };
};

export const flatIdToId = (id) => {
  const [clientId, localId] = id.split('-').map((el) => {
    return Number(el);
  });
  return { clientId, localId };
};

export const doesElementHasContentPosition = (element) => {
  return (
    element.content && element.content.contentX && element.content.contentY
  );
};

export const doesElementHasPosition = (element) => {
  return (
    element.content && element.content.x && element.content.y
  );
};

export const alignContentPosToElementPos = (element) => {
  return ({
    ...element,
    ...(element.content === undefined)
      ? {}
      : {
        content: {
          ...element.content,
          contentX: element.content.x,
          contentY: element.content.y,
        },
      },
  });
};

export const alignElementPosToContentPos = (element) => {
  return ({
    ...element,
    ...(element.content === undefined)
      ? {}
      : {
        content: {
          ...element.content,
          x: element.content.contentX,
          y: element.content.contentY,
        },
      },
  });
};

export const alignIfRequiresContentPos = (el) => {
  return (
    isFillableElement(el) &&
    doesElementHasPosition(el) &&
    (isElementSignature(el) || isElementImage(el))
      ? alignContentPosToElementPos(el)
      : el
  );
};

export const alignToSendIfRequires = (el) => {
  return (
    isFillableElement(el) && doesElementHasContentPosition(el)
      ? alignElementPosToContentPos(el)
      : el
  );
};

const doesElementHasContentText = (element) => {
  return element.content.text !== undefined;
};

const addDefaultContentText = (element) => {
  return {
    ...element,
    content: {
      ...element.content,
      text: DEFAULT_CONTENT_TEXT,
    },
  };
};

export const addDefaultTextToContentIfRequires = (element) => {
  if (
    !isSignNow() &&
    isFillableElement(element) &&
    isElementText(element) &&
    !doesElementHasContentText(element)
  ) {
    return addDefaultContentText(element);
  }

  return element;
};

export const fillFillable = (element, subType) => {
  const filledElement = {
    ...omit(element, 'isNeedToBeFilledWithTemplate'),
    ...(subType && { subType }),
    content: {
      ...omitPlaceholder(element.template),
      ...element.content,
      visible: true,
    },
  };
  return flow([
    alignIfRequiresContentPos,
    addDefaultTextToContentIfRequires,
  ])(filledElement);
};

export const clearFillable = (element, subType) => {
  return alignIfRequiresContentPos({
    ...element,
    ...(subType && { subType }),
    content: undefined,
  });
};

export const fillFillableIfEmpty = (element, subType) => {
  return (
    isEmptyFillableElement(element)
      ? fillFillable(element, subType)
      : element
  );
};

export const createFlatName = ({ type, subType, template }) => {
  if (type === TOOL_TYPES.checkmark) {
    return `${type}_${template.name}`;
  }

  if (type === TOOL_TYPES.radio) {
    if (isSignNow() && get(template, 'original.radio_id', false)) {
      // in SN radio values can be duplicated within one radio element
      return `${type}_${template.name}_${template.original.radio_id}`;
    }
    return `${type}_${template.name}_${template.radioValue}`;
  }

  return `${type}_${subType}_${template.name}`;
};

export const createRadioName = ({ type, pageId, template }) => {
  return `${type}_${pageId}_${template.name}_${template.radioGroupId}`;
};

// deprecated
export function getElementById(elementsMap, id) {
  return elementsMap[id];
}

export const doesElementSupportFlatGroupJSF = (element) => {
  return (
    (
      (isElementText(element) && !isElementFormula(element)) ||
      isElementCheckmark(element) ||
      isRadioButton(element)
    ) &&
    isFillableElement(element) &&
    get(element, 'template.name.length', 0) &&
    !!get(element, 'subType.length', 0)
  );
};

export const doesElementSupportFlatGroupSNF = () => {
  return false;
};

export const doesElementSupportFlatGroup = isSignNow()
  ? doesElementSupportFlatGroupSNF
  : doesElementSupportFlatGroupJSF;

export const doesElementSupportRadioGroup = (element) => {
  return (
    isRadioButton(element) &&
    get(element, 'pageId', -1) !== -1
  );
};

export const doesElementSupportGroups = (element) => {
  return ({
    flat: doesElementSupportFlatGroup(element),
    radio: doesElementSupportRadioGroup(element),
  });
};

export const hasElementGroupChanged = (element, oldElement) => {
  return (
    element.template.name !== oldElement.template.name
  );
};

export const getElementGroupNames = (element) => {
  const {
    flat: doesSupportFlat,
    radio: doesSupportRadio,
  } = doesElementSupportGroups(element);

  const flat = doesSupportFlat
    ? createFlatName(element)
    : false;

  const radio = doesSupportRadio
    ? createRadioName(element)
    : false;

  return { flat, radio };
};

export function send(transport, id, properties) {
  if (transport && transport.sendOperation) {
    transport.sendOperation({ id, properties });
  }
}

export const doesElementHaveCurves = (element) => {
  return Boolean(get(element, 'content.curves', false));
};

export const doesElementHaveControlpoints = (element) => {
  return Boolean(get(element, 'content.controlPoints', false));
};

export const controlPointsToFixed = (controlPoints, toFixed) => {
  return controlPoints.map(
    (point) => {
      return Number(Number(point).toFixed(toFixed));
    },
  );
};

export const curvesToFixed = (curves, toFixed) => {
  return curves.map((curve) => {
    return ({
      ...curve,
      controlPoints: controlPointsToFixed(curve.controlPoints, toFixed),
    });
  });
};

export function findDuplicatesInControlPoints(controlPoints) {
  let duplicatesCount = 0;
  let previousPointX = null;
  let previousPointY = null;

  for (let i = 0; i < controlPoints.length / 2; i++) {
    if (controlPoints[i * 2] === previousPointX && controlPoints[(i * 2) + 1] === previousPointY) {
      duplicatesCount += 1;
    } else {
      previousPointX = controlPoints[i * 2];
      previousPointY = controlPoints[(i * 2) + 1];
    }
  }

  return duplicatesCount;
}

export function sendToolElement(transport, id, properties) {
  const sendedToolElement = cloneDeep(properties);

  if (doesElementHaveControlpoints(sendedToolElement)) {
    sendedToolElement.content.controlPoints = controlPointsToFixed(
      sendedToolElement.content.controlPoints,
      TO_FIXED_FOR_CONTROL_POINTS,
    );
  } else if (doesElementHaveCurves(sendedToolElement)) {
    sendedToolElement.content.curves = curvesToFixed(
      sendedToolElement.content.curves,
      TO_FIXED_FOR_CONTROL_POINTS,
    );
  }

  send(transport, id, {
    ...sendedToolElement,
    group: GROUPS.tools,
  });
}

export function sendFillableElement(transport, { id, element: el, type }) {
  const element = cloneDeep(el);

  if (type && id && element) {
    if (type === fillableActionsTypes.remove) {
      send(transport, id, {
        ...omit(element, ['template']),
        template: {
          visible: false,
          ...pick(element.template, ['radioGroup', 'name']),
        },
        group: GROUPS.tools,
      });
      return;
    }

    send(transport, id, { ...element, group: GROUPS.tools });
  }
}

export function sendToolOperation(transport, id, properties) {
  send(transport, id, {
    ...properties,
    group: GROUPS.tools,
  });
}

export function sendEditorOperation(transport, id, properties) {
  send(transport, id, {
    ...properties,
    group: GROUPS.editor,
  });
}

export function sendDefaultsOperation(transport, id, properties) {
  sendEditorOperation(transport, id, {
    ...properties,
    type: TYPES.defaults,
  });
}

export function sendToolElementList(transport, sendOperationsCount, updatedElements) {
  let uSendOperationsCount = sendOperationsCount;
  const operations = updatedElements.map((el) => {
    uSendOperationsCount = getNextLocalId(uSendOperationsCount);
    return {
      properties: {
        ...el,
        group: GROUPS.tools,
      },
      id: createId(uSendOperationsCount),
    };
  });

  if (transport && transport.sendOperations) {
    transport.sendOperations(operations);
  }

  return uSendOperationsCount;
}

export function sendToolFillableOrder(transport, { element, pageId, order }) {
  const { type, subType, id } = element;
  send(transport, id, {
    group: GROUPS.tools,
    isFCMode: true,
    element: element.element,
    type,
    subType,

    template: { order },
    ...pageId !== undefined
      ? { pageId }
      : {},
  });
}

export const sendOrderFillableElements = (transport, state, order) => {
  const { elementsMap } = state;

  const orderPageIds = Object.keys(order);
  for (let index = 0; index < orderPageIds.length; ++index) {
    const pageId = Number(orderPageIds[index]);
    const pageOrder = order[pageId];

    pageOrder.forEach((elementId, orderIndex) => {
      const element = elementsMap[elementId];
      if (element.template.order !== orderIndex || element.pageId !== pageId) {
        sendToolFillableOrder(transport, {
          element,
          order: orderIndex,
          pageId: element.pageId !== pageId
            ? pageId
            : undefined,
        });
      }
    });
  }
};

export function sendImageList(transport) {
  send(transport, {}, {
    group: GROUPS.images,
    type: TYPES.list,
  });
}

export function sendSignaturesList(transport) {
  send(transport, {}, {
    group: GROUPS.signatures,
    type: TYPES.list,
  });
}

// helper functions to add several elements to groups
// using old addToGroup loop, 10000 elements took about 60000ms
// that algorithm took about 700ms
const getElementsThatRequiresGroupsReduceNumber = (res, el) => {
  const { flat: flatName, radio: radioName } = getElementGroupNames(el);
  if (radioName) {
    if (res.radio[radioName] === undefined) {
      res.radio[radioName] = 1;
    } else {
      res.radio[radioName] += 1;
    }
  }
  if (flatName) {
    if (res.flat[flatName] === undefined) {
      res.flat[flatName] = 1;
    } else {
      res.flat[flatName] += 1;
    }
  }

  return res;
};

export const getElementsThatRequiresGroupsReduceRef = (grouped) => {
  return (res, el) => {
    const { flat: flatName, radio: radioName } = getElementGroupNames(el);

    if (radioName) {
      if (res.radio[radioName] === undefined) {
        res.radio[radioName] = [el.id];
      } else {
        res.radio[radioName].push(el.id);
      }
    }

    if (flatName && grouped.flat[flatName] !== 1) {
      if (res.flat[flatName] === undefined) {
        res.flat[flatName] = [el.id];
      } else {
        res.flat[flatName].push(el.id);
      }
    }

    return res;
  };
};

// update all groups using elements
export const createGroupsUsingElements = (elements, state = {}) => {
  const groupedEls = elements.reduce(
    getElementsThatRequiresGroupsReduceNumber,
    { radio: {}, flat: {} },
  );

  const { radio, flat } = elements.reduce(
    getElementsThatRequiresGroupsReduceRef(groupedEls),
    { radio: {}, flat: {} },
  );

  if (isEqual({ radio, flat }, state.fillableGroups)) {
    return {
      ...state,
      elements,
    };
  }

  return {
    ...state,
    elements,
    fillableGroups: {
      radio,
      flat,
    },
  };
};

export const getElementsToUpdateWithinRadioGroup = (element, state) => {
  const { template: { radioValue }, content: { checked } = {} } = element;
  const { elementsMap, fillableGroups: { radio } } = state;

  const group = radio[createRadioName(element)];

  // we ought to update all elements only in case of checked: true
  if (group === undefined || group.length === 1 || !checked) {
    return [];
  }

  return group.reduce((acc, id) => {
    if (id === element.id) {
      return acc;
    }

    const el = elementsMap[id];
    // use template.name comparsion to ensure that we won't change
    // elements with the same name to different values
    if (el.template.radioValue !== radioValue && el.content && el.content.checked) {
      return [...acc, {
        ...el,
        content: {
          ...el.content,
          checked: false,
        },
      }];
    }

    return acc;
  }, []);
};

const getInheritanceProps = ({ type, subType }) => {
  return propsInheritanceByGroupMap[type][subType];
};

const groupsPropsHasBeenChanged = ({ element, prevElement }) => {
  return getInheritanceProps(element).some(({ name }) => {
    // if prop=subType need check checkmark's subType has been changed,
    // comparing subtypes new & old element
    if (name === DEFAULT_SUBTYPE) {
      return get(element, `${name}`, false) !== get(prevElement, `${name}`, false);
    }
    return get(element, `template[${name}]`, false) !== get(prevElement, `template[${name}]`, false);
  });
};

const getDefaultsOrExistsTemplateProp = (element, name, value) => {
  // for validatorId we don't need to set default '' because we have logic that checks ''
  if (name === 'validatorId') {
    return get(element, `template[${name}]`, value);
  }
  // if default value is present and element doesn't
  // have value for current property
  if (value === undefined) {
    return get(element, `template[${name}]`, '');
  }
  return get(element, `template[${name}]`, value);
};

export const getPropsForUpdateElementInGroup = (element) => {
  if (!element) {
    return [];
  }

  const propsForUpdate = { content: {}, template: {} };

  getInheritanceProps(element).forEach(({ name, value }) => {
    if (isElementCheckmark(element) || isRadioButton(element)) {
      if (name === DEFAULT_SUBTYPE) {
        const defaultSybType = get(element, `${name}`, 'x');
        propsForUpdate[name] = defaultSybType;
        propsForUpdate.template[name] = defaultSybType;
      } else if (name === 'initial') {
        propsForUpdate.content.checked = Boolean(Number(get(element, `template[${name}]`, 0)));
      } else if (name !== DEFAULT_SUBTYPE && name !== 'checked') {
        propsForUpdate.template[name] = get(element, `template[${name}]`, '');
      } else if (name === 'allowEditing' || name === 'readonly') {
        propsForUpdate.template[name] = getDefaultsOrExistsTemplateProp(element, name, value);
      }

      if (name !== DEFAULT_SUBTYPE && name !== 'checked' && name !== 'label') {
        propsForUpdate.template[name] = get(element, `template[${name}]`, '');
      }
      return;
    }
    if (isElementDate(element)) {
      if (name === TOOL_TYPES.text) {
        propsForUpdate.template[name] = get(element, `content[${name}]`, '');
      } else if (name === 'allowEditing' || name === 'readonly') {
        propsForUpdate.template[name] = getDefaultsOrExistsTemplateProp(element, name, value);
      } else {
        propsForUpdate.template[name] = get(element, `template[${name}]`, '');
      }
      return;
    }

    propsForUpdate.template[name] = getDefaultsOrExistsTemplateProp(element, name, value);
  });

  return propsForUpdate;
};

const getUpdatedGroupElementWithInheritedProps =
  ({ element, elementForUpdate }) => {
    const propsForUpdate = getPropsForUpdateElementInGroup(element);

    const content = {
      ...elementForUpdate.content,
      ...propsForUpdate.content,
    };

    const template = {
      ...elementForUpdate.template,
      ...propsForUpdate.template,
    };

    return {
      ...elementForUpdate,
      ...(propsForUpdate.subType && { subType: propsForUpdate.subType }),
      ...(!isEmpty(template) && { template }),
      ...(!isEmpty(content) && { content }),
    };
  };

const getUpdatedElementAndSendOrReturnOld =
  ({ needUpdateGroup, element, elementForUpdate, sendUpdatedElementGroup = false }) => {
    if (needUpdateGroup) {
      const updatedElement = getUpdatedGroupElementWithInheritedProps({
        element,
        elementForUpdate,
      });

      // call send described in FillableElements
      if (sendUpdatedElementGroup) {
        sendUpdatedElementGroup({ updatedElement });
      }

      return updatedElement;
    }

    return elementForUpdate;
  };

// set all elements with the same dbname to the same value
export const getElementsToUpdateWithinFlatGroup = (element, state, opts) => {
  const { elementsMap, fillableGroups: { flat } } = state;

  const group = flat[createFlatName(element)];

  if (group === undefined || group.length === 1) {
    return [];
  }

  const needUpdateGroup =
    group.length > 0 &&
    groupsPropsHasBeenChanged({ element, prevElement: elementsMap[element.id] });

  return group.reduce((acc, id) => {
    // do not update current element
    if (id === element.id) {
      return acc;
    }

    const el = elementsMap[id];

    const updatedElement = getUpdatedElementAndSendOrReturnOld({
      needUpdateGroup,
      element,
      elementForUpdate: el,
      sendUpdatedElementGroup: opts.sendUpdatedElementGroup,
    });

    const content = {
      ...updatedElement.content,

      text: isElementText(element)
        ? get(element, 'content.text')
        : undefined,
      checked: (isElementCheckmark(element) || isRadioButton(element))
        ? get(element, 'content.checked')
        : undefined,
      width: get(element, 'content.width'),
      height: get(element, 'content.height'),
    };

    /**
     * Высота и ширина пропагейтится между элементами flat группы
     * только если из размеры в template одинаковые
     *
     * Поэтому если размеры template отличаются то удаляем из
     * объекта width&height
     */
    const contentAfterFilter = ((newContent) => {
      if (
        updatedElement.template.width !== element.template.width ||
        updatedElement.template.height !== element.template.height
      ) {
        return omit(newContent, ['width', 'height']);
      }

      return newContent;
    })(content);

    return [
      ...acc, {
        ...updatedElement,
        ...(get(updatedElement, 'content', false) && {
          content: contentAfterFilter,
        }),
      },
    ];
  }, []);
};

export const getElementsToUpdateAccordingToGroup = (element, state, opts = {}) => {
  const {
    flat: doesSupportFlat,
    radio: doesSupportRadio,
  } = doesElementSupportGroups(element);

  let elementsToUpdate = [];
  if (doesSupportFlat) {
    elementsToUpdate = getElementsToUpdateWithinFlatGroup(element, state, opts);
  }
  if (doesSupportRadio) {
    // для каждого элемента из elementsToUpdate проверяем имеет ли он radio группу,
    // если да - добавляем для update'а
    elementsToUpdate.forEach((elemWithinFlatGroup) => {
      elementsToUpdate.push(...getElementsToUpdateWithinRadioGroup(elemWithinFlatGroup, state));
    });
    // проверяем и добаляем элементы, которые в radio группе вместе с изначальным элементом
    elementsToUpdate.push(...getElementsToUpdateWithinRadioGroup(element, state));
  }

  return uniqBy(elementsToUpdate, 'id');
};

const leaveUnchangedIfDontExistOrRemoveElement = (obj, key, id) => {
  if (obj[key] === undefined) {
    return obj;
  }

  return {
    ...obj,
    [key]: obj[key].filter((el) => {
      return el !== id;
    }),
  };
};

export const deleteElementFromGroups = (element, state) => {
  const { flat: flatName, radio: radioName } = getElementGroupNames(element);


  if (!flatName && !radioName) {
    return state;
  }

  const { flat, radio } = state.fillableGroups;
  return {
    ...state,
    fillableGroups: {
      flat: flatName
        ? leaveUnchangedIfDontExistOrRemoveElement(flat, flatName, element.id)
        : flat,
      radio: radioName
        ? leaveUnchangedIfDontExistOrRemoveElement(radio, radioName, element.id)
        : radio,
    },
  };
};

// unused
// remove group if it contains only 1 element
const lengthAbove1 = (el) => {
  return el.length > 1;
};
export const optimizeGroups = (state) => {
  const { fillableGroups: groups } = state;
  return {
    ...state,
    fillableGroups: {
      flat: pickBy(groups.flat, lengthAbove1),
      radio: pickBy(groups.radio, lengthAbove1),
    },
  };
};

export const placeElementAtTheTop = (element, state) => {
  const { elements, elementsMap } = state;
  const { id } = element;

  const elementsLastIndex = elements.length - 1;
  if (elementsMap[id] === undefined || elements[elementsLastIndex] === element) {
    return state;
  }

  return {
    ...state,
    elements: [...elements.filter((el) => {
      return el.id !== id;
    }), element],
  };
};

const getNewElementsArrayOnAddElement = (element, elements) => {
  if (isElementErase(element)) {
    return [element, ...elements];
  }

  // fakeEdit text element should be placed immediately after its erase element in elements array
  if (isElementOverlyingText(element)) {
    const { linkedEraseId } = element.content;
    const linkedEraseIndex = findIndex(elements, ({ id }) => {
      return id === linkedEraseId;
    });
    return [
      ...elements.slice(0, linkedEraseIndex + 1),
      element,
      ...elements.slice(linkedEraseIndex + 1),
    ];
  }

  return [...elements, element];
};

export const addElement = (element, state) => {
  const { elements, elementsMap } = state;

  // element does already exist
  if (elementsMap[element.id] !== undefined) {
    return state;
  }

  return {
    ...state,
    elements: getNewElementsArrayOnAddElement(element, elements),
    elementsMap: {
      ...elementsMap,
      [element.id]: element,
    },
  };
};

export const updateElement = (element, state) => {
  const { elements, elementsMap } = state;
  const { id } = element;

  // element does not exist
  if (elementsMap[id] === undefined) {
    return state;
  }

  return ({
    ...state,
    elements: elements.map((el) => {
      return id === el.id
        ? element
        : el;
    }),
    elementsMap: {
      ...elementsMap,
      [id]: element,
    },
  });
};

export const updateElements = (elementsToUpdate, state) => {
  if (elementsToUpdate.length === 0) {
    return state;
  }

  return elementsToUpdate.reduce(
    (acc, item) => {
      return updateElement(item, acc);
    },
    state,
  );
};

export const deleteElement = (element, state) => {
  const { elements, elementsMap } = state;
  const { id } = element;

  // element does not exist
  if (elementsMap[id] === undefined) {
    return state;
  }

  return ({
    elements: elements.filter((el) => {
      return el.id !== id;
    }),
    elementsMap: omit(elementsMap, id),
  });
};

// todo deprecated method
export function findElementById(elements, id) {
  for (let i = 0; i < elements.length; i++) {
    if (createId(elements[i].element) === id) {
      return elements[i];
    }
  }

  return false;
}

export function getElementDefaults(defaults, type, subType) {
  if (type === TOOL_TYPES.image) {
    return {};
  }

  if (type === TOOL_TYPES.comment) {
    return {
      width: 20,
      height: 20,
    };
  }

  if (!defaults) {
    // eslint-disable-next-line no-console
    console.warn(`defaults is not defined. type is ${type}. subType is ${subType}`);
    return false;
  }

  const searchId = subType
    ? `${GROUPS.tools}.${type}.${subType}`
    : `${GROUPS.tools}.${type}`;

  return defaults.find(
    (el) => {
      return el.id === searchId;
    },
  ) ||
    defaults.find(
      (el) => {
        return el.id === `${GROUPS.tools}.${type}.*`;
      },
    ) ||
      false;
}

export function getDefaultsDiffWithElement(element, { defaults: defs }) {
  // we are searching diff within defaults.content and element.content
  const defaults = getElementDefaults(defs.content, element.type, element.subType);

  const diffObj = {};

  // iterate over defaults keys
  Object.keys(defaults).forEach((key) => {
    if (!element.content.hasOwnProperty(key)) {
      return;
    }

    if (element.content[key] instanceof Object && defaults[key] instanceof Object) {
      if (diffObj[defaults.id] === undefined) {
        diffObj[defaults.id] = {};
      }

      diffObj[defaults.id][key] = {
        ...defaults[key],
        ...element.content[key],
      };
    } else if (
      element.content[key] !== defaults[key] &&
      defaults[key] !== 0 && key !== ID
    ) {
      defaults[key] = element.content[key];

      if (diffObj[defaults.id] === undefined) {
        diffObj[defaults.id] = {};
      }

      diffObj[defaults.id][key] = element.content[key];
    }
  });

  // return diffObj if it has keys
  if (Object.keys(diffObj).length) {
    return diffObj;
  }

  return false;
}

export function getDefaultsDiffWithSingleElementTemplate(defaults, element) {
  const defaultPlate =
    getFromDefaultsTemplate(
      defaults.template,
      element.type,
      element.subType,
      { isImmutable: !isSignNow() },
    );

  const defaultObj = {};

  Object.keys(defaultPlate).forEach((key) => {
    if (element.template.hasOwnProperty(key)) {
      if (isArray(element.template[key]) && isArray(defaultPlate[key])) {
        if (!defaultObj[defaultPlate.id]) {
          defaultObj[defaultPlate.id] = [];
        }

        defaultObj[defaultPlate.id][key] = [
          ...defaultPlate[key],
          ...element.template[key],
        ];
      } else if (typeof element.template[key] === 'object' && typeof defaultPlate[key] === 'object') {
        if (!defaultObj[defaultPlate.id]) {
          defaultObj[defaultPlate.id] = {};
        }

        defaultObj[defaultPlate.id][key] = {
          ...defaultPlate[key],
          ...element.template[key],
        };
      } else if (
        element.template[key] !== defaultPlate[key] &&
        defaultPlate[key] !== 0 && key !== ID
      ) {
        defaultPlate[key] = element.template[key];

        if (!defaultObj[defaultPlate.id]) {
          defaultObj[defaultPlate.id] = {};
        }

        defaultObj[defaultPlate.id][key] = element.template[key];
      }
    }
  });

  if (Object.keys(defaultObj).length) {
    return defaultObj;
  }

  return false;
}

export function getDefaultsDiffWithElementTemplate(defaults, element) {
  if (element.type === TOOL_TYPES.checkmark) {
    // For checkmark we need update defaults for all 3 subTypes
    return Object.values(TOOL_SUB_TYPES[TOOL_TYPES.checkmark]).reduce((result, subType) => {
      return {
        ...result,
        ...getDefaultsDiffWithSingleElementTemplate(defaults, { ...element, subType }),
      };
    }, {});
  }

  return getDefaultsDiffWithSingleElementTemplate(defaults, element);
}

export function modifiedOperationDefaults(defaultPlate, operation) {
  const defaultObj = {};

  Object.keys(defaultPlate).forEach((key) => {
    if (
      operation.hasOwnProperty(key) &&
      operation[key] !== defaultPlate[key] &&
      defaultPlate[key] !== 0
    ) {
      const newDefaultPlate = { ...defaultPlate };
      newDefaultPlate[key] = operation[key];

      if (!defaultObj[newDefaultPlate.id]) {
        defaultObj[newDefaultPlate.id] = {};
      }

      defaultObj[newDefaultPlate.id][key] = operation[key];
    }
  });

  if (Object.keys(defaultObj).length) {
    return defaultObj;
  }

  return false;
}

export const applyDefaultsContent = (defaults, properties) => {
  return ({
    ...defaults,
    content: defaults.content.reduce((acc, el, i) => {
      const { id: contentID } = el;

      if (properties[contentID]) {
        acc[i] = {
          ...acc[i],
          ...properties[contentID],
        };
      }

      return acc;
    }, [...defaults.content]),
  });
};

export function applyDefaultsTemplate(defaults, properties) {
  const template = [
    ...defaults.template,
  ];

  for (let i = 0; i < defaults.template.length; i++) {
    const templateID = defaults.template[i].id;

    if (properties[templateID]) {
      template[i] = {
        ...template[i],
        ...properties[templateID],
      };
    }
  }

  return {
    ...defaults,
    template,
  };
}

export function createGhostElement(defaultsContent, id, toolType, toolSubType, item = {}) {
  const { subType = toolSubType } = item;

  const ghostContentTemplate = {
    ...getElementDefaults(defaultsContent, toolType, subType),
    ...item,
  };

  if (typeof ghostContentTemplate.id === 'string') {
    delete ghostContentTemplate.id;
  }

  return {
    id,
    subType: toolSubType,
    type: toolType,
    ...(!isEmpty(ghostContentTemplate) && {
      content: ghostContentTemplate,
    }),
  };
}

export function scalingImageSignatureByDefaults(element) {
  const { content } = element;

  if (!content) {
    return element;
  }

  let { width, height } = content;
  if (isElementImage(element) && content.width > IMAGE_MAX_WIDTH) {
    const ratio = content.height / content.width;
    width = IMAGE_MAX_WIDTH;
    height = Math.floor(IMAGE_MAX_WIDTH * ratio);
  } else if (isElementSignature(element) && content.width && content.height) {
    const scale = content.width / content.height > SIGNATURE_WIDTH_HEIGHT_RATIO
      ? (SIGNATURE_SCALE_HEIGHT * SIGNATURE_WIDTH_HEIGHT_RATIO) / content.width
      : SIGNATURE_SCALE_HEIGHT / content.height;
    width = content.width * scale >= 1
      ? Math.floor(content.width * scale)
      : 1;
    height = content.height * scale >= 1
      ? Math.floor(content.height * scale)
      : 1;
  }

  const updatedContent = width || height
    ? {
      content: {
        ...element.content,
        width,
        height,
      },
    }
    : {};

  return {
    ...element,
    ...updatedContent,
  };
}

export const reCreateGhostElement = (
  defaultsContent,
  toolType,
  toolSubType,
  sendOperationsCount,
  item,
) => {
  const sendOperationsCountUpdated = getNextLocalId(sendOperationsCount);
  const id = createFlatId(createId(sendOperationsCountUpdated));
  return {
    ghostElement: createGhostElement(defaultsContent, id, toolType, toolSubType, item),
    sendOperationsCount: sendOperationsCountUpdated,
  };
};

export const getNextElementId = (flatId) => {
  const oldId = flatIdToId(flatId);
  return createFlatId({
    ...oldId,
    localId: oldId.localId + 1,
  });
};

export function checkDiffAndApplyDefaultsAndSend(transport, element, state, isFillable = false) {
  const defaultsDiff = isFillable
    ? getDefaultsDiffWithElementTemplate(state.defaults, element)
    : getDefaultsDiffWithElement(element, state, isFillable);

  if (!defaultsDiff) {
    return state;
  }

  const sendOperationsCount = getNextLocalId(state.sendOperationsCount);

  if (!isFillable) {
    sendDefaultsOperation(transport, createId(sendOperationsCount), { content: defaultsDiff });
  }

  return {
    defaults: {
      ...state.defaults,
      ...isFillable
        ? applyDefaultsTemplate(state.defaults, defaultsDiff)
        : applyDefaultsContent(state.defaults, defaultsDiff),
    },
    sendOperationsCount,
  };
}

export function generateIdsBeforeAddFillableElements(state, elements) {
  return elements.map((el, i) => {
    const sendOperationsCount = getNextLocalId({
      ...state.sendOperationsCount,
      local: state.sendOperationsCount.local + i,
    });
    const element = createId(sendOperationsCount);
    const id = createFlatId(element);
    return { ...el, id, element };
  });
}

// JSF-1964: don't save "initial" default prop for Checkboxes and "arrangement" for Text
// JSF-1962: don't save "initial" default prop for Date element
const getCorrectElementTemplate = (element) => {
  const elementTemplate = { ...element.template };

  if (isElementCheckmark(element)) {
    elementTemplate.initial = false;
  }

  if (isElementText(element)) {
    elementTemplate.arrangement = 'none';
    elementTemplate.initial = '';
  }

  if (isElementDate(element)) {
    elementTemplate.initial = '';
  }

  if (isElementDropdown(element)) {
    elementTemplate.list = [];
  }

  delete elementTemplate.validatorId;

  return elementTemplate;
};

export const getNewDefaults = (defaults, element) => {
  const correctElement = {
    ...element,
    template: getCorrectElementTemplate(element),
  };

  const elementDiff = getDefaultsDiffWithElementTemplate(defaults, correctElement);
  return {
    defaults: {
      ...defaults,
      ...applyDefaultsTemplate(
        defaults,
        elementDiff,
      ),
    },
    elementDiff,
  };
};

export function getSendableData(sourceElement, updatedElement) {
  const getContent = (source, updated) => {
    const keysToKeep = {
      text: true,
    };

    if (!updated.content) {
      return {};
    }

    if (source.content) {
      return pickBy(updated.content, (val, key) => {
        return val !== source.content[key] || keysToKeep[key];
      },
      );
    }

    return { ...updatedElement.content };
  };

  const owner = get(updatedElement, 'content.owner', get(sourceElement, 'content.owner'));

  return omit({
    ...updatedElement,
    content: Object.assign(
      getContent(sourceElement, alignToSendIfRequires(updatedElement)),
      owner
        ? { owner }
        : {},
    ),
  }, 'template');
}

// has tests
export const getIndexLastElemOnPage = ({ elements, pageId }) => {
  return findLastIndex(elements, (el) => {
    return el.pageId === pageId;
  });
};

const getIndexLastElemOnPageRecurs = ({ elements, pageId, pageSettings }) => {
  const prevPageId = getAdjacentPageId(pageId, pageSettings, -1);
  const lastIndex = findLastIndex(elements, ['pageId', prevPageId]);
  if (lastIndex === -1 && getIndexByPageId(prevPageId, pageSettings) > 0) {
    return getIndexLastElemOnPageRecurs({ elements, pageId: prevPageId, pageSettings });
  }
  return lastIndex;
};

export const addFillableElementAndSortElements = (element, elements, pageSettings) => {
  const { pageId } = element;
  const endIndex = getIndexLastElemOnPage({ elements, pageId });
  // case we added fillable element to a blank page
  if (endIndex === -1) {
    const endIndexOnPrevPage = getIndexLastElemOnPageRecurs({ elements, pageId, pageSettings });

    const newOrderedElements = [
      ...elements.slice(0, endIndexOnPrevPage + 1),
      element,
      ...elements.slice(endIndexOnPrevPage + 1),
    ];
    return {
      elements: newOrderedElements,
      elementsMap: createMapFromElements(newOrderedElements),
    };
  }
  // case we added fillable element on the page which already has items
  const newOrderedElements = [
    ...elements.slice(0, endIndex + 1),
    element,
    ...elements.slice(endIndex + 1),
  ];
  return {
    elements: newOrderedElements,
    elementsMap: createMapFromElements(newOrderedElements),
  };
};

const getFillableSearchIdByTool = (tool) => {
  const { type, subType } = tool;

  switch (type) {
    case TOOL_TYPES.text:
      /* eslint-disable curly */
      if (subType === TOOL_TYPES.number) return 'tools.text.number';
      if (subType === TOOL_TYPES.formula) return 'tools.text.formula';
      if (subType === TOOL_TYPES.dropdown) return 'tools.text.dropdown';
      if (subType === TOOL_TYPES.date) return 'tools.text.date';
      return 'tools.text.*';

    case TOOL_TYPES.signature:
      if (subType === TOOL_TYPES.initials) return 'tools.signature.initials';
      return 'tools.signature.*';

    case TOOL_TYPES.checkmark:
      return 'tools.checkmark.x';

    case TOOL_TYPES.radio:
      return 'tools.radio.*';

    case TOOL_TYPES.image:
      return 'tools.image';

    case TOOL_TYPES.attachment:
      return 'tools.attachment.none';

    case TOOL_TYPES.salesforce:
      return 'tools.salesforce.none';

    case TOOL_TYPES.smartfield:
      return 'tools.smartfield.none';

    default:
      return 'tools.text.*';
  }
};

const getFillableDefaultTemplateByTool = (tool, defaults) => {
  const searchId = getFillableSearchIdByTool(tool);
  const defaultTemplate = find(defaults.template, (el) => {
    return el.id === searchId;
  });

  return defaultTemplate === undefined
    ? {}
    : defaultTemplate;
};

export const reCreateFillableGhostElement = (tool, state) => {
  const { sendOperationsCount, defaults } = state;
  const uSendOperationsCount = getNextLocalId(sendOperationsCount);
  const flatId = createFlatId(createId(uSendOperationsCount));
  const { id, ...defaultTemplateByTool } = getFillableDefaultTemplateByTool(tool, state.defaults);

  return {
    ghostElement: {
      id: flatId,
      element: flatIdToId(flatId),
      subType: tool.subType,
      type: tool.type,
      template: {
        ...defaultTemplateByTool,
        toolId: tool.id,
        ...tool.subType === TOOL_TYPES.initials
          ? { restrictSubTypes: [TOOL_TYPES.text] }
          : {},
        viewMode: defaults.textFieldViewMode || DEFAULT_TEXT_FIELD_VIEW_MODE,
        ...tool.subType === TOOL_TYPES.dropdown
          ? { list: DEFAULT_DROPDOWN_LIST }
          : {},
        ...tool.properties,
      },
    },
    sendOperationsCount: uSendOperationsCount,
  };
};

// awesome
export const getNamePattern = ({ type, subType }) => {
  if (type === TOOL_TYPES.text && subType === TOOL_TYPES.none) {
    return 'Text_';
  }
  if (type === TOOL_TYPES.text && subType === TOOL_TYPES.number) {
    return 'Number_';
  }
  if (type === TOOL_TYPES.text && subType === TOOL_TYPES.date) {
    return 'Date_';
  }
  if (type === TOOL_TYPES.text && subType === TOOL_TYPES.dropdown) {
    return 'Dropdown_';
  }
  if (type === TOOL_TYPES.text && subType === TOOL_TYPES.formula) {
    return 'Formula_';
  }
  if (type === TOOL_TYPES.checkmark) {
    return 'Checkmark_';
  }
  if (type === TOOL_TYPES.image) {
    return 'Image_';
  }
  if (type === TOOL_TYPES.signature && subType === TOOL_TYPES.none) {
    return 'Signature_';
  }
  if (type === TOOL_TYPES.signature && subType === TOOL_TYPES.image) {
    return 'Signature_';
  }
  if (type === TOOL_TYPES.signature && subType === TOOL_TYPES.initials) {
    return 'Initials_';
  }
  if (type === TOOL_TYPES.radio && subType === TOOL_TYPES.none) {
    return 'Radio_';
  }
  if (type === TOOL_TYPES.attachment && subType === TOOL_TYPES.none) {
    return 'Attachment_';
  }
  return undefined;
};

export const getMaxIndex = (names, namePattern) => {
  const figures = names
    .map((el) => {
      return el.substring(namePattern.length);
    })
    .filter((el) => {
      const num = Number(el);
      return num !== 0 && !Number.isNaN(num);
    });

  return figures.length === 0
    ? 0
    : Math.max(...figures);
};

export const getNextNameByTypeIterator = (element, state, nameField = 'name') => {
  const { elements } = state;
  const { type, subType } = element;

  const namePattern = getNamePattern({ type, subType });
  if (namePattern === undefined) {
    return {
      next: () => {
        return { value: null, done: true };
      },
    };
  }

  const names = elements
    .filter((current) => {
      return (
        current.template && current.type === type &&
        (
          // we dont compare checkmarks by substype
          // as the each new checkmark got to have a new dbname
          type === TOOL_TYPES.checkmark ||

          // we dont compare signature by substype
          // as the each new signature got to have a new dbname
          type === TOOL_TYPES.signature ||

          current.subType === subType
        ) &&
        current.template[nameField] && startsWith(current.template[nameField], namePattern)
      );
    }).map((el) => {
      return el.template[nameField];
    });

  let index = getMaxIndex(names, namePattern);

  // returns object with next method: each call of this method will return next name
  return {
    next: () => {
      index += 1;

      return { value: `${namePattern}${index}`, done: false };
    },
  };
};

export const getNextNameByType = (element, state, nameField = 'name') => {
  return getNextNameByTypeIterator(element, state, nameField).next().value;
};

export const applyNextDbNameByType = (element, state) => {
  return ({
    ...element,
    template: {
      ...element.template,
      name: element.template.name || getNextNameByType(element, state),
    },
  });
};

export const applyToDefaultsAndUpdateGhost = (
  transport, element, state, dontUpdateDefaults, isFillable,
) => {
  const { defaults, sendOperationsCount } = dontUpdateDefaults
    ? state
    : checkDiffAndApplyDefaultsAndSend(transport, element, state, isFillable);

  const needUpdateGhost = state.ghostElement && getNeedUpdateGhost(element);

  return {
    defaults,
    sendOperationsCount,
    ...(needUpdateGhost && (
      state.ghostElement.template
        ? reCreateFillableGhostElement(
          state.activeTool,
          state,
        )
        : reCreateGhostElement(
          defaults.content,
          state.ghostElement.type,
          state.ghostElement.subType,
          sendOperationsCount,
          state.ghostElement.content,
        )
    )),
  };
};

export const getTimeStampNow = () => {
  return moment().utc().valueOf();
};

export function fillOperations(operations, elementsMap) {
  return operations.map((operation) => {
    const newOperation = {
      ...operation,
      properties: { ...operation.properties },
    };
    const { group, content, template, element } = newOperation.properties;
    if (group === GROUPS.tools && element) {
      const { clientId, localId } = element;
      const elementId = `${clientId}-${localId}`;

      const elementFromMap = getElementById(elementsMap, elementId);

      if (elementFromMap) {
        if (elementFromMap.content) {
          newOperation.properties.content = {
            ...elementFromMap.content,
            ...(content || {}),
          };
        }

        if (elementFromMap.template) {
          newOperation.properties.template = {
            ...elementFromMap.template,
            ...(template || {}),
          };
        }
      } else if (
        // данный блок позволяет добавить template для операции с элементом,
        // когда элемент еще не существует в elementsMap (такое возможно
        // при дублировании вкладки (см. JSF-2874)), иначе операция удалит его
        (content && content.visible === false) && !template
      ) {
        const elementFromOperations = find(
          operations,
          { id: { localId, clientId } },
        );

        if (elementFromOperations && elementFromOperations.properties.template) {
          newOperation.properties.template = {
            ...elementFromOperations.properties.template,
          };
        }
      }
    }

    return newOperation;
  });
}

export function transformCommentToClientScheme(comment) {
  const { content } = comment;
  if (content === undefined || content.replies === undefined) {
    return comment;
  }

  return {
    ...comment,
    content: {
      ...content,
      replies: content.replies.map((reply) => {
        return {
          content: {
            ...reply,
          },
          parent: comment.id || createFlatId(comment.element),
          id: String(reply.date),
        };
      }),
    },
  };
}

export function transformCommentToServerScheme(comment) {
  const { content } = comment;
  if (content === undefined || content.replies === undefined) {
    return comment;
  }

  return {
    ...comment,
    content: {
      ...content,
      width: 20,
      height: 20,
      replies: content.replies.map((reply) => {
        return reply.content;
      }),
    },
  };
}

export function createCommentFromOperation(operation, state) {
  const { sendOperationsCount } = state;
  const { id, properties } = operation;
  const { content, element, pageId } = properties;
  return {
    element: transformCommentToClientScheme({
      content: {
        ...content,
        width: 1,
        height: 1,
      },
      element,
      pageId,
      type: TOOL_TYPES.comment,
      subType: TOOL_SUB_TYPES.none,
      id: createFlatId(element),
    }),
    sendOperationsCount: {
      ...sendOperationsCount,
      local: id.clientId === 0 && element.localId > sendOperationsCount.local
        ? element.localId
        : sendOperationsCount.local,
    },
  };
}

export function sendComment(transport, comment) {
  send(transport, comment.element, {
    group: GROUPS.tools,
    ...transformCommentToServerScheme(comment),
  });
}

export function getCommentUsersToFetch(comment) {
  const { content } = comment;
  const users = { [content.author]: true };
  if (content.replies) {
    content.replies.forEach((reply) => {
      if (users[reply.author] === undefined) {
        users[reply.author] = true;
      }
    });
  }

  return Object.keys(users);
}

export function queryUsers(transport, users, state) {
  if (users.length === 0) {
    return state;
  }

  const { sendOperationsCount, id } = createIdAndNext(state.sendOperationsCount);
  send(transport, id, {
    group: GROUPS.users,
    type: TYPES.list,
    users,
  });

  return {
    ...state,
    sendOperationsCount,
  };
}

export function createCommentsMapFromArray(comments) {
  return comments.reduce((acc, comment) => {
    acc[comment.id] = comment;
    return acc;
  }, {});
}

export const getTemplateId = (elemType, elemSubType) => {
  return `tools.${elemType}.${TOOL_SUB_TYPES[elemType][elemSubType]}`;
};

export const getIsNumberTemplateId = (templateId) => {
  return getTemplateId(TOOL_TYPES.text, TOOL_SUB_TYPES.text.number) === templateId;
};

export const getIsFormulaTemplateId = (templateId) => {
  return getTemplateId(TOOL_TYPES.text, TOOL_SUB_TYPES.text.formula) === templateId;
};

export const getIsDateTemplateId = (templateId) => {
  return getTemplateId(TOOL_TYPES.text, TOOL_SUB_TYPES.text.date) === templateId;
};

export const createGenerateDevURLfunc = (websocketClient) => {
  return (params = {}) => {
    const localhost = params.localhost || 'localhost';
    const port = params.port || '3000';
    const host = params.host || params.addHost
      ? `&ws.config.host=${params.host || websocketClient.config.host || websocketClient.ws.url}`
      : '';
    // eslint-disable-next-line no-underscore-dangle
    const _host = params._host || websocketClient._host || websocketClient.urlParams._host || `${websocketClient.location.origin}/`;
    const token = params.token || websocketClient.apiHash;
    const projectId = params.projectId || websocketClient.projectId;
    const viewerId = params.viewerId || websocketClient.viewerId;

    return `${localhost}:${port}/?token=${token}&projectId=${projectId}&viewerId=${viewerId}&_host=${_host}${host}`;
  };
};
