export const modifiers = {
  edge: 'edge',
  inner: 'inner',

  // default one
  outer: 'outer',
};

export const possiblePositions = {
  top: 'top-center-outer',
  topOuter: 'top-center-outer',
  topCenter: 'top-center-outer',
  topCenterOuter: 'top-center-outer',
  topCenterInner: 'top-center-inner',

  topLeft: 'top-left-outer',
  topLeftOuter: 'top-left-outer',
  topLeftEdge: 'top-left-edge',
  topLeftInner: 'top-left-inner',
  topRight: 'top-right-outer',
  topRightOuter: 'top-right-outer',
  topRightEdge: 'top-right-edge',
  topRightInner: 'top-right-inner',


  right: 'right-center-outer',
  rightOuter: 'right-center-outer',
  rightCenter: 'right-center-outer',
  rightCenterOuter: 'right-center-outer',
  rightCenterInner: 'right-center-inner',

  rightTop: 'right-top-outer',
  rightTopOuter: 'right-top-outer',
  rightTopEdge: 'right-top-edge',
  rightTopInner: 'right-top-inner',
  rightBottom: 'right-bottom-outer',
  rightBottomOuter: 'right-bottom-outer',
  rightBottomEdge: 'right-bottom-edge',
  rightBottomInner: 'right-bottom-inner',


  bottom: 'bottom-center-outer',
  bottomOuter: 'bottom-center-outer',
  bottomCenter: 'bottom-center-outer',
  bottomCenterOuter: 'bottom-center-outer',
  bottomCenterInner: 'bottom-center-inner',

  bottomRight: 'bottom-right-outer',
  bottomRightOuter: 'bottom-right-outer',
  bottomRightEdge: 'bottom-right-edge',
  bottomRightInner: 'bottom-right-inner',
  bottomLeft: 'bottom-left-outer',
  bottomLeftOuter: 'bottom-left-outer',
  bottomLeftEdge: 'bottom-left-edge',
  bottomLeftInner: 'bottom-left-inner',


  left: 'left-center-outer',
  leftOuter: 'left-center-outer',
  leftCenter: 'left-center-outer',
  leftCenterOuter: 'left-center-outer',
  leftCenterInner: 'left-center-inner',

  leftTop: 'left-top-outer',
  leftTopOuter: 'left-top-outer',
  leftTopEdge: 'left-top-edge',
  leftTopInner: 'left-top-inner',
  leftBottom: 'left-bottom-outer',
  leftBottomOuter: 'left-bottom-outer',
  leftBottomEdge: 'left-bottom-edge',
  leftBottomInner: 'left-bottom-inner',

  // use center as default for bottom-center-outer
  center: 'bottom-center-outer',
  centerOuter: 'bottom-center-outer',
  centerInner: 'center-inner',
};

export const portalLayerClassName = 'react-portal';
