import { takeEvery, put, select } from 'redux-saga/effects';
import has from 'lodash/has';
import { openModal, closeModal } from 'combine-modals/lib/actions/actionCreator';
import { DIALOG_MODAL, ERROR_MODAL } from 'combine-modals/lib/constants/modal.classnames';
import { selectors } from 'jsfcore';
import { actionTypes as AT, setActiveElement } from 'ws-editor-lib/actions';


function* onAutofill({ operations }) {
  yield put(closeModal(DIALOG_MODAL));

  const hasError = has(operations, '[0].properties.error');

  if (hasError) {
    const autofillErrorModalLocale = yield select(selectors.locale.getAutofillErrorModalLocale);
    const { title, text, okButtonText } = autofillErrorModalLocale;
    const options = {
      title,
      content: text,
      btnConfirmText: okButtonText,
    };

    yield put(openModal(ERROR_MODAL, options));
  } else {
    const firstFillableElement =
      yield select(selectors.elements.getFirstFillableEnabledElementOnActivePage);

    if (firstFillableElement) {
      yield put(setActiveElement(firstFillableElement.id, true));
    }
  }
}

export default function* watchOnAutofill() {
  yield takeEvery(AT.ON_OPERATIONS_OPERATIONS_AUTOFILL, onAutofill);
}
