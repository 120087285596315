import elementSelectors from '../elementsSelectors';
import { getGhostElement } from '../baseSelectors';
import getElementPropsForToolbar from './getElementPropsForToolbar';
import { getValidColorKey } from '../../../helpers/graphicUtils';

const getValidColorPropsFactory = (colorKey) => {
  return (state) => {
    const activeElement = elementSelectors.getActiveElement(state);
    const elementProps = getElementPropsForToolbar(state);
    const ghost = getGhostElement(state);

    const element = activeElement || ghost;
    const validColorKey = getValidColorKey(colorKey, element.type);
    const { [validColorKey]: color } = elementProps;
    return {
      color,
      colorKey: validColorKey,
    };
  };
};

export default getValidColorPropsFactory;
