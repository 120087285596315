import { createSelector } from 'reselect';
import getElementPropsForToolbar from './getElementPropsForToolbar';
import getToolbarAttributesVisilibity from './getToolbarAttributesVisilibity';

const getToolbarAttributesProps = createSelector(
  [getElementPropsForToolbar, getToolbarAttributesVisilibity],
  (elementProps, visibilityProps) => {
    const {
      hasPopup,
      hasSize,
      hasStamp,
      hasFont,
      hasColor,
      hasFontColor,
      hasFillColor,
      hasBackgroundColor,
      hasBorderColor,
      hasLineWidth,
      hasArrowStyle,
      hasRectDraw,
      hasHref,
      hasAlign,
      hasVAlign,
    } = visibilityProps;

    const {
      id,
      isFillable,
      isTextToolBasedElement,
      isFConstructorElementHaveToolbar,
      isElementHaveToolbar,
      isSignature,
      lineWidth,
    } = elementProps;

    return {
      hasPopup,
      hasSize,
      hasStamp,
      hasFont,
      hasColor,
      hasFontColor,
      hasFillColor,
      hasBackgroundColor,
      hasBorderColor,
      hasLineWidth,
      hasArrowStyle,
      hasRectDraw,
      hasHref,
      hasAlign,
      hasVAlign,
      id,
      isFillable,
      isTextToolBasedElement,
      isFConstructorElementHaveToolbar,
      isElementHaveToolbar,
      isSignature,
      lineWidth,
    };
  });

export default getToolbarAttributesProps;
