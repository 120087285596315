import isSignNow from './isSignNow';

const redirectToHrefSignNow = (hrefLink) => {
  // snfiller renders inside iframe
  window.top.location.href = hrefLink;
};

const redirectToHrefJSFiller = (hrefLink) => {
  window.location.href = hrefLink;
};

// eslint-disable-next-line import/prefer-default-export
export const redirectToHref = isSignNow()
  ? redirectToHrefSignNow
  : redirectToHrefJSFiller;
