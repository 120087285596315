const store = {
  cache: {},
  events: [],
};

if (__CLIENT__) {
  window.JSFillerStores = { ...window.JSFillerStores, profillerStore: store };
}

export const popEvents = () => {
  if (store.frozen) {
    return [];
  }
  const events = [
    ...store.events,
  ];
  store.events.length = 0;
  return events;
};

// AB: останавливает сбор и отправку метрик на сервер
// в конслоль будет продолжать писаться
export const freezeEvents = () => {
  store.frozen = true;
};

export const clear = () => {
  store.cache = {};
  store.events.length = 0;
  if (__CLIENT__) {
    performance.clearResourceTimings();
  }
};

if (__CLIENT__) {
  window.getStartupData = () => {
    return store.cache;
  };
}

const profiler = (name, data) => {
  if (__CLIENT__) {
    const now = performance.now();
    if (!__TEST__) {
      // eslint-disable-next-line no-console
      console.log(`Profiler: ${name}`, now, { ...data });
    }
    if (store.frozen !== true && !store.cache[name]) {
      if (!__TEST__ && !__INTEGRATION_TEST__) {
        // check for performance.mark for safari <= 10.1.2
        performance.mark && performance.mark(name);
        // check for console.timeStamp -> https://pdffiller.atlassian.net/browse/JSF-7151
        // eslint-disable-next-line no-console
        console.timeStamp && console.timeStamp(name);
      }

      const event = {
        timestamp: now,
        name,
        ...data,
      };
      store.cache[name] = event;
      store.events.push(event);
    }
  }
};

export default profiler;
