import PropTypes from 'prop-types';
import { Component, Children } from 'react';

export default class PortalVisibilityProvider extends Component {
  static propTypes = {
    children: PropTypes.func.isRequired,

    initialVisibility: PropTypes.bool,
    timeout: PropTypes.number,
  };

  static defaultProps = {
    initialVisibility: false,
    timeout: 0,
  };

  constructor(props) {
    super(props);
    this.state = {
      isVisible: props.initialVisibility,
    };
    this.timeoutId = null;
  }

  openPortal = () => {
    if (this.state.isVisible) {
      return;
    }

    if (this.props.timeout) {
      this.timeoutId = setTimeout(this.togglePortal, this.props.timeout);
    } else {
      this.togglePortal();
    }
  };

  closePortal = () => {
    if (this.timeoutId) {
      clearTimeout(this.timeoutId);
    }
    if (!this.state.isVisible) {
      return;
    }

    this.togglePortal();
  };

  togglePortal = () => {
    this.setState((prevState) => {
      return {
        isVisible: !prevState.isVisible,
      };
    });
  };

  render() {
    return Children.only(
      this.props.children({
        openPortal: this.openPortal,
        closePortal: this.closePortal,
        togglePortal: this.togglePortal,
        isVisible: this.state.isVisible,
      }),
    );
  }
}
