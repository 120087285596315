import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import * as Ui from '../../ui';
import * as modalsSelectors from '../selectors';
import * as types from '../types';
import { closeModal } from '../store/modalsActions';

// create function for get DI modules in runtime
const getLocales = () => {
  const locales = {
    [types.confirmationTypes.removeRequestPayment]: {
      header: 'Are you sure?',
      body: 'This will remove the payment request from this document.',
      select: 'OK',
      cancel: 'Cancel',
      areaLabelSelect: 'Erase all conditions',
    },
    [types.confirmationTypes.removeConditions]: {
      header: 'CONDITIONAL_FIELDS.REMOVE_CONDITIONS_MODAL_TITLE',
      body: 'CONDITIONAL_FIELDS.REMOVE_CONDITIONS_MODAL_DESCRIPTION',
      select: 'FORMS.OK',
      cancel: 'FORMS.CANCEL',
      areaLabelSelect: 'Erase all conditions',
    },
    [types.confirmationTypes.updateConditions]: {
      header: 'CONDITIONAL_FIELDS.UPDATE_VALUE_RELATED_DEPENDENCIES_MODAL_TITLE',
      body: 'CONDITIONAL_FIELDS.FIELD_NAME_IS_REFERENCED_NOTICE',
      bodyPluralParams: [{
        name: 'one',
      }],
      select: 'FORMS.OK',
      cancel: 'FORMS.CANCEL',
      areaLabelSelect: 'Update related dependencies',
    },
    [types.confirmationTypes.updateRadio]: {
      header: 'CONDITIONAL_FIELDS.UPDATE_RADIO_VALUE_RELATED_DEPENDENCIES_MODAL_TITLE',
      body: 'CONDITIONAL_FIELDS.UPDATE_RADIO_VALUE_RELATED_DEPENDENCIES_MODAL_BODY',
      select: 'CONDITIONAL_FIELDS.UPDATE_CONDITION',
      cancel: 'CONDITIONAL_FIELDS.CONTINUE_WITHOUT_UPDATE',
      areaLabelSelect: 'Update related dependencies',
    },
    [types.modalTypes.radioRemove]: {
      header: 'REMOVE_RADIO_GROUP.TITLE',
      body: 'REMOVE_RADIO_GROUP.BODY',
      select: 'FORMS.OK',
      cancel: 'FORMS.CANCEL',
      areaLabelSelect: 'Remove radio group',
    },
  };

  return locales;
};

@connect(
  (state) => {
    return {
      type: modalsSelectors.getConfirmationType(state),
    };
  }, {
    closeModal,
  },
)
class ConfirmationModalProvider extends Component {
  static propTypes = {
    children: PropTypes.func.isRequired,
    closeModal: PropTypes.func.isRequired,
    type: PropTypes.oneOf(
      Object.keys(getLocales()),
    ).isRequired,
  };

  onClose = () => {
    this.props.closeModal(
      types.modalsMap[types.modalTypes.confirmation],
    );
  };

  onSelect = () => {
    this.props.closeModal(
      types.modalsMap[types.modalTypes.confirmation],
      { isConfirm: true },
    );
  };

  render() {
    const locale = getLocales()[this.props.type];

    return this.props.children({
      onClose: this.onClose,
      header: (
        <Ui.FlexibleBar.Body
          justify={Ui.flexibleBar.justifies.flexEnd}
          offset={Ui.flexibleBar.offsets.medium}
        >
          <Ui.FlexibleBar.Cell attributes={Ui.attributes.grow}>
            <Ui.Group.Index offset={Ui.group.offsets.smallHorizontal}>
              <Ui.Group.Body>
                <Ui.Group.Item>
                  <Ui.Text id={locale.header} size={Ui.text.sizes[18]} />
                </Ui.Group.Item>
              </Ui.Group.Body>
            </Ui.Group.Index>
          </Ui.FlexibleBar.Cell>

          <Ui.FlexibleBar.Cell>
            <Ui.Group.Index offset={Ui.group.offsets.smallHorizontal}>
              <Ui.Group.Body>
                <Ui.Group.Item>
                  <Ui.Control
                    onClick={this.onClose}
                    size={Ui.control.sizes.touch}
                    theme={Ui.control.themes.transparentGray}
                    justify={Ui.control.justifies.null}
                    offset={Ui.control.offsets.null}
                    behavior={Ui.control.behaviors.null}
                    role={Ui.control.roles.null}
                    ariaLabel="Close a window"
                    isBusy={false}
                    isPressed={false}
                    inheritRadius
                    icon={
                      <Ui.Icon
                        size={Ui.icon.sizes.extraSmall}
                        type={Ui.icon.types.close}
                      />
                    }
                  />
                </Ui.Group.Item>
              </Ui.Group.Body>
            </Ui.Group.Index>
          </Ui.FlexibleBar.Cell>
        </Ui.FlexibleBar.Body>
      ),
      footer: (
        <Ui.FlexibleBar.Body
          justify={Ui.flexibleBar.justifies.flexEnd}
          offset={Ui.flexibleBar.offsets.medium}
        >
          <Ui.FlexibleBar.Cell>
            <Ui.Group.Index
              offset={Ui.group.offsets.small}
              justify={Ui.group.justifies.spaceBetween}
            >
              <Ui.Group.Body>
                <Ui.Group.Item>
                  <Ui.Control
                    onClick={this.onClose}
                    size={Ui.control.sizes.small}
                    offset={Ui.control.offsets.medium}
                    theme={Ui.control.themes.lackluster}
                    behavior={Ui.control.behaviors.null}
                    justify={Ui.control.justifies.null}
                    role={Ui.control.roles.null}
                    ariaLabel="Cancel"
                    isBusy={false}
                    isPressed={false}
                  >
                    <Ui.Text
                      id={locale.cancel}
                      size={Ui.text.sizes[14]}
                      bold
                    />
                  </Ui.Control>
                </Ui.Group.Item>

                <Ui.Group.Item>
                  <Ui.Control
                    onClick={this.onSelect}
                    size={Ui.control.sizes.small}
                    offset={Ui.control.offsets.medium}
                    theme={Ui.control.themes.primary}
                    behavior={Ui.control.behaviors.null}
                    justify={Ui.control.justifies.null}
                    role={Ui.control.roles.null}
                    ariaLabel={locale.areaLabelSelect}
                    isBusy={false}
                    isPressed={false}
                  >
                    <Ui.Text
                      id={locale.select}
                      size={Ui.text.sizes[14]}
                      bold
                    />
                  </Ui.Control>
                </Ui.Group.Item>
              </Ui.Group.Body>
            </Ui.Group.Index>
          </Ui.FlexibleBar.Cell>
        </Ui.FlexibleBar.Body>
      ),
      component: (
        <Ui.TextBox.Body offset={Ui.textBox.offsets.medium}>
          {
            (
              this.props.type === types.confirmationTypes.updateConditions ||
              this.props.type === types.confirmationTypes.updateRadio
            )
              ? (
                <Ui.Text
                  id={locale.body}
                  interpolationParams={locale.bodyPluralParams}
                  size={Ui.text.sizes[14]}
                  block
                />
              )
              : (
                <Ui.Text
                  id={locale.body}
                  size={Ui.text.sizes[14]}
                  block
                />
              )
          }
        </Ui.TextBox.Body>
      ),
      props: {
        size: Ui.dialog.sizes.small,
        offset: Ui.dialog.offsets.medium,
      },
    });
  }
}

export default ConfirmationModalProvider;
