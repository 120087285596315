import { Component } from 'react';
import PropTypes from 'prop-types';

class LegacyContextProvider extends Component {
  static propTypes = {
    store: PropTypes.shape({}).isRequired,
    children: PropTypes.element.isRequired,
  };

  static childContextTypes = {
    store: PropTypes.shape({}),
  };

  getChildContext = () => {
    return {
      store: this.props.store,
    };
  };

  render() {
    return this.props.children;
  }
}

export default LegacyContextProvider;
