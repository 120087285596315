import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import ContentEditable from 'react-contenteditable';

const fakeTextAreaThemes = {
  default: 'default',
  null: null,
};

const fakeTextAreaSizes = {
  parent: 'parent',
  null: null,
};

const fakeTextAreaOffsets = {
  medium: 'medium',
  null: null,
};

// currently unused, delete this when use
const FakeTextArea = ({
  children,
  onChange,
  onClick,
  onBlur,
  onKeyDown,
  size,
  storeRef,
  textStart,
  textEnd,
  theme,
  offset,
  isFocused,
}) => {
  return (
    <div
      className={classnames('fake-textarea', {
        [`fake-textarea--theme--${theme}`]: theme,
        [`fake-textarea--size--${size}`]: size,
        [`fake-textarea--offset--${offset}`]: offset,
        'is-focused': isFocused,
      })}
      data-text-start={textStart}
      data-text-end={textEnd}
      onClick={onClick}
      onKeyDown={onKeyDown}
      onBlur={onBlur}
    >
      <ContentEditable
        tagName="span"
        className="fake-textarea__content"
        html={children}
        innerRef={storeRef}
        onChange={onChange}
      />
    </div>
  );
};

FakeTextArea.propTypes = {
  children: PropTypes.string.isRequired,
  theme: PropTypes.oneOf(Object.values(fakeTextAreaThemes)),
  size: PropTypes.oneOf(Object.values(fakeTextAreaSizes)),
  storeRef: PropTypes.shape({}).isRequired,
  offset: PropTypes.oneOf(Object.values(fakeTextAreaOffsets)),
  onChange: PropTypes.func,
  onClick: PropTypes.func,
  onBlur: PropTypes.func,
  onKeyDown: PropTypes.func,
  textStart: PropTypes.string,
  textEnd: PropTypes.string,
  isFocused: PropTypes.bool.isRequired,
};

FakeTextArea.defaultProps = {
  theme: fakeTextAreaThemes.null,
  size: fakeTextAreaSizes.null,
  offset: fakeTextAreaOffsets.null,
  onChange: null,
  onClick: null,
  onBlur: null,
  onKeyDown: null,
  textStart: '',
  textEnd: '',
};

export default FakeTextArea;
export { fakeTextAreaThemes, fakeTextAreaSizes, fakeTextAreaOffsets };
