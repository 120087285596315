import PropTypes from 'prop-types';
import React, { Component } from 'react';
import classnames from 'classnames';

export const gridSeparatorSizes = {
  default: null,
  medium: 'medium',
};

export const gridSeparatorDirections = {
  none: null,
  left: 'left',
  right: 'right',
};

export default class GridSeparator extends Component {
  static propTypes = {
    size: PropTypes.string,
    direction: PropTypes.string,
  };

  static defaultProps = {
    size: gridSeparatorSizes.default,
    direction: gridSeparatorDirections.none,
  };

  render() {
    const { size, direction } = this.props;
    const directionOrEmpty = direction
      ? `-${direction}`
      : '';

    return (
      <div
        role="separator"
        className={classnames(
          'grid__separator', {
            [`grid__separator--offset${directionOrEmpty}--${size}`]: size !== gridSeparatorSizes.default,
          })}
      />
    );
  }
}
