import { all } from 'redux-saga/effects';

import watchPageChange from '@pdffiller/jsf-conditions/store/watchPageChangeSaga';
import conditionsRemoveContentRootSaga from '@pdffiller/jsf-conditions/store/conditionsRemoveContentSaga';

import appStartedSaga, {
  toggleProcessingModalOnAppStarted,
  watchOnOperationsEditorModePending,
} from 'jsfcore/store/sagas/appStartedSaga';
import resetElementsRootSaga from 'jsfcore/store/sagas/resetElements';
import watchOnChangePage from 'jsfcore/store/sagas/changePage/changePage';
import pdfRootSaga from 'jsfcore/store/sagas/pdf/pdf';
import localeSaga from 'jsfcore/store/sagas/locale/localeSaga';
import watchOkClicked from 'jsfcore/store/sagas//onOkClicked/onOkClicked';
import loadPdfRootSaga from 'jsfcore/store/sagas/loadPdf/loadPdf';

import validateElementWatcher from 'jsfcore/jsf-validations/sagas/validateElements';
import { getQSParams } from 'jsfcore/helpers/utils';

import authRootSaga from './auth/authRootSaga';
import { openPdfOfflineLocally } from './auth/interfaceSaga';
import { watchServerError } from './error/error';
import modalRootSaga from './modals';
import { watchRedirectToFlashEditor } from './redirects/goToFlashEditor';
import { watchZoomInByKeyboard, watchZoomOutByKeyboard } from './zoom/watchZoomInOutByKeyboard';
import fontSizeRemChanger from './zoom/fontSizeRemChanger';
import phpLogsRootSaga from './phpLogs/phpLogs';
import formPreviewRootSaga from './formPreview/formPreview';
import userActedRootSaga from './userActed/userActed';
import fakeEditRootSaga from './fakeEdit/fakeEdit';
import searchRootSaga from './search/searchSaga';
import modesRootSaga from './modes/modesSaga';
import changeFCModeSaga from './modes/changeFCModeSaga';
import watchOnVersionsMode from './modes/watchOnVersionsMode';
import openCommentsOnCommentReceive from './openCommentsOnCommentReceive';
import watchOnAutofill from './autofill/autofill';
import watchIsEnabledByDependency from './watchIsEnabledByDependency';
import toggleSigningSessionOnSetActiveElement, {
  watchOnOperationsEditorScenarios,
} from './signingSession';
import watchOnCheckExternalValidation from './externalValidation';
import activatePendingMode from './pendingMode';


export default function* rootSaga() {
  const {
    projectId,
    viewerId,
    embedded,
    isOfflineMode,
    dontWaitForPdf,
  } = getQSParams();

  yield __CLIENT__ && __ENABLE_SAGAS__
    ? all([
      authRootSaga({ projectId, viewerId, embedded, isOfflineMode }),
      watchServerError(),
      modalRootSaga(),
      watchRedirectToFlashEditor(),
      watchZoomInByKeyboard(),
      watchZoomOutByKeyboard(),
      resetElementsRootSaga(),
      watchOnChangePage(),
      pdfRootSaga(),
      loadPdfRootSaga(),
      phpLogsRootSaga({ isOfflineMode }),
      formPreviewRootSaga(),
      userActedRootSaga(),
      fakeEditRootSaga(),
      searchRootSaga(),
      watchOkClicked(),
      modesRootSaga(),
      changeFCModeSaga(),
      watchOnVersionsMode(),
      localeSaga(),
      appStartedSaga(),
      openCommentsOnCommentReceive(),
      watchOnAutofill(),
      conditionsRemoveContentRootSaga(),
      // validateElementWatcher должна отрабатывать раньше formulaSaga,
      // чтобы формула рассчитывалась только для валидных значений

      validateElementWatcher(),
      toggleProcessingModalOnAppStarted(),
      watchOnOperationsEditorModePending(),
      watchPageChange(),
      watchIsEnabledByDependency(),
      fontSizeRemChanger(),
      watchOnOperationsEditorScenarios(),
      toggleSigningSessionOnSetActiveElement(),
      watchOnCheckExternalValidation(),
      activatePendingMode(),
      ...(
        dontWaitForPdf
          ? [openPdfOfflineLocally()]
          : []
      ),
    ])
    : all([]);
}
