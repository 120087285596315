import PropTypes from 'prop-types';
import React, { Component } from 'react';

export default class ToolBarGroup extends Component {
  static propTypes = {
    children: PropTypes.node,
    attributes: PropTypes.shape({}),
    storeRef: PropTypes.func,
  };

  static defaultProps = {
    children: null,
    attributes: {},
    storeRef: null,
  };

  render() {
    return (
      <div
        className="toolbar__group"
        ref={this.props.storeRef}
        {...this.props.attributes}
      >
        {this.props.children}
      </div>
    );
  }
}
