import { createSelector } from 'reselect';
import elementsSelectors from '../elementsSelectors';
import { getGhostElement, getDefaultViewMode, getActivePageId } from '../baseSelectors';

import { isPreConfigurableType } from '../../../helpers/elemTypes';
import {
  getElementProps,
  getElementToolbarData,
  isElementHaveToolbar,
  isFConstructorElementHaveToolbar,
} from '../../helpers/functions';

const getElementPropsForToolbar = createSelector(
  [elementsSelectors.getActiveElement, getGhostElement, getDefaultViewMode, getActivePageId],
  (activeElement, ghostElement, defaultViewMode, activePageId) => {
    if (activeElement) {
      return {
        ...getElementProps(activeElement, defaultViewMode),
        ...getElementToolbarData(activeElement, defaultViewMode),
        isActiveElement: true,
        isElementHaveToolbar: isElementHaveToolbar(activeElement),
        isFConstructorElementHaveToolbar: isFConstructorElementHaveToolbar(activeElement),
        id: activeElement.id,
      };
    }

    if (ghostElement && isPreConfigurableType(ghostElement.type)) {
      return {
        ...getElementProps(ghostElement, defaultViewMode),
        ...getElementToolbarData(ghostElement, defaultViewMode),
        isElementHaveToolbar: isElementHaveToolbar(ghostElement),
        isFConstructorElementHaveToolbar: isFConstructorElementHaveToolbar(ghostElement),
        pageId: activePageId,
        isGhost: true,
        id: ghostElement.id,
      };
    }

    return {};
  },
);

export default getElementPropsForToolbar;
