import PropTypes from 'prop-types';
import React, { Component } from 'react';
import cls from 'classnames';

export const editorInfoSizes = {
  smallHorizontal: 'small-horizontal',
  mediumHorizontal: 'medium-horizontal',
  largeHorizontal: 'large-horizontal',
  null: null,
};

export default class EditorInfo extends Component {
  static propTypes = {
    children: PropTypes.node,

    size: PropTypes.oneOf(
      Object.values(editorInfoSizes),
    ),
  };

  static defaultProps = {
    children: null,
    size: editorInfoSizes.null,
  };

  render() {
    const { size } = this.props;

    return (
      <div
        className={cls(
          'editor-info', {
            [`editor-info--size--${size}`]: size,
          },
        )}
      >
        {this.props.children}
      </div>
    );
  }
}
