import * as Layer from './Layer';
import Portal from './Portal';
import Wrapper from './Wrapper/legacy';
import WrapperExperiment from './Wrapper/experiment';
import VisibilityProvider from './PortalVisibilityProvider';
import DetectWindowClickProvider from './DetectWindowClickProvider';
import DetectScrollProvider from './LegacyDetectScrollProvider';
import ExperimentDetectScrollProvider from './ExperimentDetectScrollProvider';
import ClosePortalFunctionsComposer from './ClosePortalFunctionsComposer';
import DetectWindowResizeProvider from './DetectWindowResizeProvider';
import { possiblePositions } from './helpers/const';
import { presetsNames } from './helpers/presets';

export default Portal;

export {
  Layer,
  Wrapper,
  WrapperExperiment,
  possiblePositions as wrapperPositions,
  presetsNames as wrapperPresets,
  VisibilityProvider,
  DetectWindowClickProvider,
  DetectScrollProvider,
  ExperimentDetectScrollProvider,
  ClosePortalFunctionsComposer,
  DetectWindowResizeProvider,
  Portal,
};
