import { actions, types } from '../../jsf-modals';

function getAdditionalDataForRadioBeforeCreate() {
  return async (dispatch) => {
    const radio = await dispatch(actions.openModal(
      types.modalsMap[types.modalTypes.radio],
    ));
    return radio || false;
  };
}

export default getAdditionalDataForRadioBeforeCreate;
