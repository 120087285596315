import {
  select,
  takeEvery,
  fork,
} from 'redux-saga/effects';

import { selectors } from 'jsfcore';
import { systemStatuses } from 'jsfcore/helpers/const';

let systemCache;

const onDestroyCbs = [];
const onDestroyedCbs = [];

/**
 * Это система мониторинга state.ws.system.status
 * Когда state.ws.system.status изменяется с какого-то на
 * статус перечисленный объекте в "events"
 * мы запускаем соответствующую функцию
 *
 * Сейчас пришлось написать код который слушает диспатч всех
 * экшенов (*) это не оптимально, но любые другие варианты требуют
 * постоянной поддежки. Например, если какой-то новый экшн начнет влиять
 * на с state.ws.system.status.
 *
 * Q: Что будет выполнено при изменении статуса на destroy?
 * A: Массив функций onDestroyCbs
 *
 * Q: Что будет выполнено при изменении статуса на destroyed?
 * A: Массив функций onDestroyedCbs
 *
 * Чтобы добавить действия в эти массивы, используем функции:
 * - appendDestroyCallback
 * - appendDestroyedCallback
 */

export const appendDestroyCallback = (callback) => {
  onDestroyCbs.push(callback);
};

export const appendDestroyedCallback = (callback) => {
  onDestroyedCbs.push(callback);
};

export function* onDestroyReceived(system) {
  for (let i = 0; i < onDestroyCbs.length; i++) {
    const callback = onDestroyCbs[i];
    yield callback(system);
  }
}

export function* onDestroyedReceived(system) {
  for (let i = 0; i < onDestroyedCbs.length; i++) {
    const callback = onDestroyedCbs[i];
    yield callback(system);
  }
}

const events = {
  [systemStatuses.destroy]: onDestroyReceived,
  [systemStatuses.destroyed]: onDestroyedReceived,
};
const eventsNames = Object.keys(events);

export function* onAnyAction() {
  const newSystem = yield select(selectors.base.getSystem);

  for (let i = 0; i < eventsNames.length; i++) {
    const eventName = eventsNames[i];

    const currentStatement =
      !systemCache ||
      !systemCache.status ||
      systemCache.status !== eventName;

    const nextStatement =
      newSystem &&
      newSystem.status &&
      newSystem.status === eventName;

    if (currentStatement && nextStatement) {
      yield fork(events[eventName], newSystem);
    }
  }

  systemCache = newSystem;
}

export default function* systemStatusWatcher() {
  yield takeEvery('*', onAnyAction);
}
