import hintPreset from './hintPreset';
import rtaPreset from './rtaPreset';
import tooltipPreset from './tooltipPreset';
import hrefPreset from './hrefPreset';
import datePickerPreset from './datePickerPreset';
import wizardTooltipPreset from './wizardTooltipPreset';
import signStampPreset from './signStampPreset';

const presets = {
  hint: hintPreset,
  rta: rtaPreset,
  tooltip: tooltipPreset,
  href: hrefPreset,
  datePicker: datePickerPreset,
  wizardTooltip: wizardTooltipPreset,
  signStamp: signStampPreset,
};

const getArgs = (args) => {
  return args;
};

const defaultPreset = {
  postMeasure: getArgs,
  postCalculation: getArgs,
  postValidation: getArgs,
  postClamping: getArgs,
};


export const presetsNames = Object.keys(presets).reduce((acc, name) => {
  acc[name] = name;
  return acc;
}, {});

export default function getPresetObject(preset) {
  if (preset === undefined || preset === null || preset === false) {
    return defaultPreset;
  }

  if (typeof preset === 'string') {
    if (presets.hasOwnProperty(preset)) {
      return {
        ...defaultPreset,
        ...presets[preset],
      };
    }
    throw new Error(`preset with provided name '${preset}' is not defined`);
  }
  if (typeof preset === 'object') {
    return {
      ...defaultPreset,
      ...preset,
    };
  }
  throw new Error('preset should be either presetName or preset object');
}
