import PropTypes from 'prop-types';
import React from 'react';
import cls from 'classnames';

export const todoListSizes = {
  large: 'large',
  default: null,
};

const TodoListIndex = ({ children, size }) => {
  return (
    <div
      className={cls('todo', {
        [`todo--size--${size}`]: size,
      })}
    >
      <div className="todo__body">
        {children}
      </div>
    </div>
  );
};

TodoListIndex.propTypes = {
  children: PropTypes.node,
  size: PropTypes.oneOf(
    Object.values(todoListSizes),
  ),
};

TodoListIndex.defaultProps = {
  children: null,
  size: todoListSizes.default,
};

export default TodoListIndex;
