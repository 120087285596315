import { createSelector } from 'reselect';

import { selectors } from 'jsfcore';
import { elemTypes } from 'jsfcore/helpers/elemTypes';
import pdfTextStore from 'jsfcore/store/helpers/pdfTextStore';

// основное в этом файле - eraseElementShapes. Он нужен:
// 1. чтобы отфильтровывать результаты поиска и не отображать найденные значения
// которые скрыты и находятся под erase-элементом (функционал fakeEdit)
// 2. чтобы по нему отфильтровать textContent для FakeEditLayer.js

// NOTE: almost every selector here is cached by pageId,
// so even if FakeEditLayer on any page is unmounted and than created again
// we'll not re-compute data for it

// need for easier filtering 'erased' textContent items
export const getIdFromGeometry = ({ pageId, x, y, width, height }) => {
  return `${pageId}|${Math.round(x)}|${Math.round(y)}|${Math.round(width)}|${Math.round(height)}`;
};

const getScaledEraseElementShapesCache = {};
export const getScaledEraseElementShapes = (state, pageId) => {
  if (!getScaledEraseElementShapesCache[pageId]) {
    const getEraseElements = selectors.elements.getElementsByType(elemTypes.erase);
    getScaledEraseElementShapesCache[pageId] = createSelector(
      [getEraseElements, selectors.base.getPdfScale],
      (elements, pdfScale) => {
        return elements
          .filter((element) => {
            return element.pageId === pageId;
          })
          .reduce((acc, element) => {
            const geometry = {
              x: element.content.x / pdfScale,
              y: element.content.y / pdfScale,
              width: element.content.width / pdfScale,
              height: element.content.height / pdfScale,
            };
            return {
              ...acc,
              [getIdFromGeometry(geometry)]: geometry,
            };
          }, {});
      },
    );
  }

  return getScaledEraseElementShapesCache[pageId](state);
};

const textItemsCache = {};
const getNonRotatedTextItems = (pageId) => {
  const pageTextItems = pdfTextStore.getPdfText(pageId);
  if (!textItemsCache[pageId] || textItemsCache[pageId].textItems !== pageTextItems) {
    textItemsCache[pageId] = {
      textItems: pageTextItems,
      notRotatedTextItems: pageTextItems.filter((item) => {
        return item.rotate === 0;
      }),
    };
  }
  return textItemsCache[pageId].notRotatedTextItems;
};

const textContentSelectorCache = {};
export const getTextContent = (state, pageId) => {
  if (!textContentSelectorCache[pageId]) {
    textContentSelectorCache[pageId] = createSelector(
      [selectors.base.getTextContentPages, getScaledEraseElementShapes],
      (textContentPages, eraseElementShapes) => {
        if (textContentPages[pageId] === null) {
          return false;
        }
        const nonRotatedItems = getNonRotatedTextItems(pageId);

        return nonRotatedItems.filter((item) => {
          if (eraseElementShapes[getIdFromGeometry(item)]) {
            return false;
          }
          return true;
        });
      },
    );
  }

  return textContentSelectorCache[pageId](state, pageId);
};
