import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

export const switcherThemes = {
  primary: 'primary',
  default: 'default',
  null: null,
};

export const switcherSizes = {
  extraSmall: 'extra-small',
  default: 'default',
  null: null,
};

const Switcher = ({
  theme,
  size,
  isActive,
  onClick,
}) => {
  return (
    <div
      className={classnames(
        'switcher', {
          [`switcher--size--${size}`]: size,
          [`switcher--theme--${theme}`]: theme,
          'is-active': isActive,
        },
      )}
      onClick={onClick}
    />
  );
};

Switcher.propTypes = {
  size: PropTypes.oneOf(Object.values(switcherSizes)),
  theme: PropTypes.oneOf(Object.values(switcherThemes)),
  isActive: PropTypes.bool,
  onClick: PropTypes.func,
};

Switcher.defaultProps = {
  size: null,
  theme: null,
  isActive: false,
  onClick: null,
};

export default Switcher;
