import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

export const textBoxOffsets = {
  medium: 'medium',
  small: 'small',
  null: null,
};

export const textBoxHorizontalOffsets = {
  medium: 'medium',
  null: null,
};

export const textBoxAligns = {
  center: 'center',
  null: null,
};

export const textBoxJustifies = {
  center: 'center',
  null: null,
};

const TextBoxBody = ({ offset, offsetHorizontal, align, justify, children }) => {
  return (
    <div
      className={classnames(
        'text-box', {
          [`text-box--offset--${offset}`]: offset,
          [`text-box--align--${align}`]: align,
          [`text-box--justify--${justify}`]: justify,
          [`text-box--offset-horizontal--${offsetHorizontal}`]: offsetHorizontal,
        },
      )}
    >
      <div className="text-box__body">
        {children}
      </div>
    </div>
  );
};

TextBoxBody.propTypes = {
  children: PropTypes.node,
  offset: PropTypes.oneOf(
    Object.values(textBoxOffsets),
  ),
  offsetHorizontal: PropTypes.oneOf(Object.values(textBoxHorizontalOffsets)),
  align: PropTypes.oneOf(
    Object.values(textBoxAligns),
  ),
  justify: PropTypes.oneOf(
    Object.values(textBoxJustifies),
  ),
};

TextBoxBody.defaultProps = {
  offset: textBoxOffsets.null,
  offsetHorizontal: textBoxHorizontalOffsets.null,
  align: textBoxAligns.null,
  justify: textBoxJustifies.null,
  children: null,
};

export default TextBoxBody;
