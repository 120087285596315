import React from 'react';
import PropTypes from 'prop-types';

const EditorInfoCell = ({ children }) => {
  return (
    <div className="editor-info__cell">
      {children}
    </div>
  );
};

EditorInfoCell.propTypes = {
  children: PropTypes.node,
};

EditorInfoCell.defaultProps = {
  children: null,
};

export default EditorInfoCell;
