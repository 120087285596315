import PropTypes from 'prop-types';
import React, { Component } from 'react';

export default class FlexCell extends Component {
  static propTypes = {
    children: PropTypes.node,

    attributes: PropTypes.shape({}),
  };

  static defaultProps = {
    children: null,
    attributes: {},
  };

  render() {
    return (
      <div
        className="flex-grid__cell"
        {...this.props.attributes}
      >
        <div className="flex-grid__cell-wrapper">
          {this.props.children}
        </div>
      </div>
    );
  }
}
