import * as lazySelectors from '@pdffiller/jsf-lazyload/store/selectors';
import * as base from './baseSelectors';

/**
 * Селектор отвечает на вопрос находимся ли мы в режиме
 * PageAttributes. Проверка на getIsConstructorLoaded нужна
 * потому, что модуль находится в модуле конструктора
 */
export function isPageAttributes(state) {
  return (
    lazySelectors.common.getIsConstructorLoaded(state) &&
    base.getIsPAConstructorShown(state)
  );
}

/**
 * Находимся ли мы в режиме конструктора?
 * isConstructor
 * isConstructor.includingPreview
 */
export function isConstructor(state) {
  return (
    lazySelectors.common.getIsConstructorLoaded(state) &&
    base.getIsFConstructorShown(state) &&
    !base.getIsFConstructorPreviewShown(state)
  );
}

/**
 * Находимся ли мы в режиме конструктора или аттрибутов страницы
 */
export function getIsAnyConstructorShown(state) {
  return (
    isPageAttributes(state) ||
    isConstructor(state)
  );
}

// Иногда нам не нужно расделять конструктор и превью
function isConstructorIncludingPreview(state) {
  return (
    lazySelectors.common.getIsConstructorLoaded(state) &&
    base.getIsFConstructorShown(state)
  );
}
isConstructor.includingPreview = isConstructorIncludingPreview;

/**
 * Открыты ли версии?
 */
export function isVersions(state) {
  return (
    lazySelectors.common.getIsVersionsLoaded(state) &&
    base.getIsVersionsShown(state)
  );
}

/**
 * Находимся ли мы в режиме заполнения?
 * (wizard, preview, simple)
 */
export function isFilling(state) {
  return (
    !isPageAttributes(state) &&
    !isConstructor(state) &&
    !isVersions(state)
  );
}

/**
 * Находимся ли мы в режиме превью (конструктор -> превью)?
 */
export function isPreview(state) {
  return (
    lazySelectors.common.getIsConstructorLoaded(state) &&
    base.getIsFConstructorShown(state) &&
    base.getIsFConstructorPreviewShown(state)
  );
}

/**
 * Находимся ли мы в режиме сортировки fillable элементов
 */
export function isFieldsOrder(state) {
  return (
    lazySelectors.common.getIsConstructorLoaded(state) &&
    base.getIsFConstructorShown(state) &&
    base.getIsFConstructorOrderShown(state)
  );
}

/**
 * Находимся ли мы в режиме поиска?
 */
export function isSearch(state) {
  return base.getIsSearchModeActive(state);
}

/**
 * Открыты ли комментарии?
 */
export function isComments(state) {
  return (
    lazySelectors.jsf.getIsCommentsLoaded(state) &&
    base.getIsCommentsShown(state)
  );
}

/**
 * Отрисован ли визард поинтер?
 */
export function isPointer(state) {
  return (
    lazySelectors.common.getIsWizardLoaded(state) &&
    base.getIsPointerShown(state)
  );
}
