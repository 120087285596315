import { Component } from 'react';
import PropTypes from 'prop-types';
import { thisDevice } from '@pdffiller/jsf-useragent';

export default class DetectWindowResizeProvider extends Component {
  static propTypes = {
    onResize: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);

    // DetectWindowResizeProvider was added for hiding popup during window resize because
    // otherwise it shown in incorrect place
    // Android triggers resize event during showing/hiding keyboard
    // this behaviour occurs an issue
    // hiding settings popup(DD List) for text tool https://pdffiller.atlassian.net/browse/JSF-4371
    // On the mobile, this problem will now be solved through the orientationchange event.
    // On the mobile, there seems to be no cases when, due to the opening / closing of the keyboard,
    // the opened popup will be displayed incorrectly.
    this.eventName = thisDevice.isMobile
      ? 'orientationchange'
      : 'resize';
  }

  componentDidMount() {
    window.addEventListener(this.eventName, this.onResize);
  }

  componentWillUnmount() {
    window.removeEventListener(this.eventName, this.onResize);
  }

  onResize = (event) => {
    this.props.onResize(event);
  };

  render() {
    return null;
  }
}
