import findIndex from 'lodash/findIndex';
import moment from 'moment';
import get from 'lodash/get';

import {
  isDate,
  isCheckmarkElement,
  isSignatureCurve,
  isSignatureText,
} from '../../store/helpers/functions';
import {
  mouseFocusClassName,
  longTapClassName,
  defaultDateFormat,
  ghostVisibleOverClassName,
  commentsHideGhostClassname,
  fakeEditHighlighterClassname,
  pageClickBlockerClassname,
} from '../../helpers/const';
import { isTextToolBasedElement, elemTypes } from '../../helpers/elemTypes';
import { getEventPath, hasClass } from '../../helpers/utils';

export const nodeIsElement = (node, { isLongTap = false } = {}) => {
  return findIndex(getEventPath(node), (pathNode) => {
    return (
      hasClass(pathNode, mouseFocusClassName) ||
      (isLongTap && hasClass(pathNode, longTapClassName))
    );
  }) > -1;
};

/**
 * Все что ниже - новое, все что выше - удалить
 */
export const getObjectForAppendToNewElement = (ghost) => {
  return {
    ...(
      isTextToolBasedElement(ghost) && (!ghost.content || !ghost.content.text)
        ? { text: '' }
        : {}
    ),
    ...(
      isDate(ghost)
        ? {
          ...(
            get(ghost, 'content.width', false)
              ? {}
              : { width: 72 }
          ),
          ...(
            get(ghost, 'content.height', false)
              ? {}
              : { height: 17 }
          ),
          text: moment(new Date()).format(defaultDateFormat),
        }
        : {}
    ),

    ...(
      isCheckmarkElement(ghost)
        ? { checked: true }
        : {}
    ),

    ...(isSignatureCurve(ghost)
      ? {
        width: ghost.content.width,
        height: ghost.content.height,
      }
      : {}),

    ...(isSignatureText(ghost)
      ? {
        bold: false,
        italic: false,
        underline: false,
      }
      : {}),
  };
};

export const getIsMouseOverElement = (targetNodePath) => {
  return findIndex(targetNodePath, (pathNode) => {
    return hasClass(pathNode, mouseFocusClassName);
  }) > -1;
};

export const getIsMouseOverVisibleBox = (targetNodePath) => {
  return findIndex(targetNodePath, (pathNode) => {
    return hasClass(pathNode, ghostVisibleOverClassName);
  }) > -1;
};

export const getIsMouseOverClickBlocker = (node) => {
  return hasClass(node, pageClickBlockerClassname);
};

export const getIsMouseOverComment = (targetNodePath) => {
  return findIndex(targetNodePath, (pathNode) => {
    return hasClass(pathNode, commentsHideGhostClassname);
  }) > -1;
};

export const getIsMouseOverFakeEdit = (targetNodePath) => {
  return findIndex(targetNodePath, (pathNode) => {
    return hasClass(pathNode, fakeEditHighlighterClassname);
  }) > -1;
};

export const getPageIdAboveWhichMouse = (targetNodePath) => {
  for (let i = 0; i < targetNodePath.length; i++) {
    const node = targetNodePath[i];

    if (node && node.getAttribute) {
      // https://bugs.webkit.org/show_bug.cgi?id=161454
      const pageId = node.getAttribute('data-page-id');
      if (pageId !== null) {
        return parseInt(pageId, 0);
      }
    }
  }

  return -1;
};

/**
 * Функция должна проанализировать target и ответить на следующие вопросы: {
 *   isMouseOverElement - bool мыш над элементом?
 *   isMouseOverClickBlocker - bool мыш над EndPoint'ом или кнопкой в углу?
 *   pageIdAboveWhichMouse - number номер страницы над которым мышка
 * }
 */
export const analyzeTarget = (target) => {
  if (!target) {
    return {
      isMouseOverVisibleBox: false,
      isMouseOverElement: false,
      isMouseOverClickBlocker: false,
      isMouseOverComment: false,
      isMouseOverFakeEdit: false,
      pageIdAboveWhichMouse: -1,
    };
  }
  const targetNodePath = getEventPath(target);
  return {
    isMouseOverVisibleBox: getIsMouseOverVisibleBox(targetNodePath),
    isMouseOverElement: getIsMouseOverElement(targetNodePath),
    isMouseOverClickBlocker: getIsMouseOverClickBlocker(target),
    isMouseOverComment: getIsMouseOverComment(targetNodePath),
    isMouseOverFakeEdit: getIsMouseOverFakeEdit(targetNodePath),
    pageIdAboveWhichMouse: getPageIdAboveWhichMouse(targetNodePath),
  };
};

export const getDefaultDateStamp = () => {
  return moment(new Date()).format(defaultDateFormat);
};

export const applyDateStampIfNeed = ({ ghost, useSigDateStamp }) => {
  return (
    useSigDateStamp && ghost.type === elemTypes.signature
      ? { dateStamp: getDefaultDateStamp() }
      : {}
  );
};

export const haveDataForRenderGhost =
  ({ ghostElementForRender, pdfPages, pageId }) => {
    if (!ghostElementForRender) {
      return false;
    }
    if (!pdfPages[pageId]) {
      return false;
    }
    if (
      ghostElementForRender.type === 'fctool' &&
      ghostElementForRender.subType === 'none'
    ) {
      return false;
    }
    if (ghostElementForRender.type === 'selection') {
      return false;
    }

    return true;
  };
