import merge from 'lodash/merge';
import get from 'lodash/get';
import groupBy from 'lodash/groupBy';
import values from 'lodash/values';
import uuidv1 from 'uuid/v1';
import { TYPES } from '../../../constants';

import removeInitialFromGroupedOpsIfNeed from './removeInitialFromGroupedOpsIfNeed';

export default function optimizeOperations(operations) {
  if (operations.length === 1) {
    return operations;
  }

  /**
    * At this point we are groupping operations by element object.
    * If object element doesn't exist we are using Math.random to set operation
    * in a grouped list as unique;
    * In result will be an array of grouped arrays which contain operations as is
    */
  const grouppedOps = values(
    groupBy(
      operations,
      (op) => {
        return get(op, 'properties.type') === TYPES.trigger
          // we don't want to merge trigger operation with others
          ? `${TYPES.trigger}${JSON.stringify(get(op, 'properties.element', uuidv1()))}`
          : JSON.stringify(get(op, 'properties.element', uuidv1()));
      },
    ),
  );

  /**
    * remove all initial properties if some
    * operations in a group have no initial prop
    * for example, check on checkmark by clicking on it
    */
  const ops = removeInitialFromGroupedOpsIfNeed(grouppedOps);

  const mergedOps = ops.map(
    (opsGroup) => {
      return opsGroup.reduce((accum, op) => {
        return merge(accum, op);
      }, {});
    },
  );

  return mergedOps;
}
