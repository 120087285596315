import { takeEvery, select } from 'redux-saga/effects';
import { CHANGE_SCALE, SET_ACTIVE_PAGE } from 'jsfcore/store/modules/navigationActionTypes';
import { UPDATE_WORKSPACE } from 'jsfcore/store/modules/viewport';
import { SET_APP_STARTED } from 'jsfcore/store/modules/events';
import { selectors } from 'jsfcore';

const baseFontSize = 20.0;
const html = __CLIENT__ && document.documentElement;

function getFontSizeByScale(scale) {
  return `${baseFontSize / scale}px`;
}

function* updateFontSize() {
  const scale = yield select(selectors.getActivePageScale);
  html.style.fontSize = getFontSizeByScale(scale);
}

export default function* watchScaleOrPageChange() {
  yield takeEvery(
    [CHANGE_SCALE, SET_ACTIVE_PAGE, SET_APP_STARTED, UPDATE_WORKSPACE],
    updateFontSize,
  );
}
