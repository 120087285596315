import React from 'react';
import PropTypes from 'prop-types';

const FillableWizardText = ({ children }) => {
  return (
    <div className="fillable-wizard__text">
      {children}
    </div>
  );
};

FillableWizardText.propTypes = {
  children: PropTypes.element.isRequired,
};

export default FillableWizardText;
