import React from 'react';
import PropTypes from 'prop-types';
import cls from 'classnames';

export const inputThemes = {
  default: 'default',
  null: null,
};

export const inputSizes = {
  parent: 'parent',
  null: null,
};

export const inputOffsets = {
  mediumHorizontal: 'medium-horizontal',
  null: null,
};

export const inputTypes = {
  text: 'text',
  number: 'number',
};

const Input = ({
  theme, size, offset, type, placeholder,
  storeRef, onChange, onKeyPress, onKeyDown,
}) => {
  return (
    <input
      className={cls('input-field', {
        [`input-field--theme--${theme}`]: theme,
        [`input-field--size--${size}`]: size,
        [`input-field--offset--${offset}`]: offset,
      })}
      placeholder={placeholder}
      type={type}
      ref={storeRef}
      onChange={onChange}
      onKeyPress={onKeyPress}
      onKeyDown={onKeyDown}
    />
  );
};

Input.propTypes = {
  theme: PropTypes.oneOf(Object.values(inputThemes)),
  size: PropTypes.oneOf(Object.values(inputSizes)),
  offset: PropTypes.oneOf(Object.values(inputOffsets)),
  type: PropTypes.oneOf(Object.values(inputTypes)),
  placeholder: PropTypes.string,
  storeRef: PropTypes.func,
  onChange: PropTypes.func,
  onKeyPress: PropTypes.func,
  onKeyDown: PropTypes.func,
};

Input.defaultProps = {
  theme: inputThemes.null,
  size: inputSizes.null,
  offset: inputOffsets.null,
  type: inputTypes.text,
  placeholder: '',
  storeRef: null,
  onChange: null,
  onKeyPress: null,
  onKeyDown: null,
};

export default Input;
