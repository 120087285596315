import React from 'react';
import PropTypes from 'prop-types';

const SortableElementRemove = ({ children }) => {
  return (
    <div className="sortable-element__remove">
      {children}
    </div>
  );
};

SortableElementRemove.propTypes = {
  children: PropTypes.node.isRequired,
};

export default SortableElementRemove;
