// eslint-disable-next-line no-undef
let flagIsSignNow = __SNF__;

const isSignNow = () => {
  return flagIsSignNow;
};

export const setIsSignNow = (flag) => {
  flagIsSignNow = flag;
};

export default isSignNow;
