import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

const labelThemes = {
  null: null,
  blue: 'blue',
  lightBlue: 'lightblue',
};

const labelSizes = {
  null: null,
  parent: 'parent',
};

const labelTypes = {
  null: null,
  triangle: 'triangle',
  rounded: 'rounded',
};

const Label = ({ children, icon, text, theme, size, type }) => {
  return (
    <div
      className={classnames('label', {
        [`label--theme--${theme}`]: theme,
        [`label--size--${size}`]: size,
        [`label--type--${type}`]: type,
      })}
    >
      <div className="label__body">
        {icon && (
          <div className="label__icon">
            {icon}
          </div>
        )}

        {text && (
          <div className="label__text">
            {text}
          </div>
        )}

        {children}
      </div>
    </div>
  );
};

Label.propTypes = {
  children: PropTypes.node,
  icon: PropTypes.element,
  text: PropTypes.element,
  theme: PropTypes.string,
  size: PropTypes.string,
  type: PropTypes.string,
};

Label.defaultProps = {
  children: null,
  icon: null,
  text: null,
  theme: labelThemes.null,
  size: labelSizes.null,
  type: labelTypes.null,
};

export default Label;
export { labelTypes, labelSizes, labelThemes };
