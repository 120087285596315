/**
  * когда мы меняем режимы редактора, нам нужно сразу же сообщить
  * об этом бэкэнду, чтобы избежать мержа операций и все последующие
  * операции корректно обрабатывались.
  */
export default function hasToFlushOperationsNow(operations) {
  return operations.some((op) => {
    const { group, type } = op.properties || {};
    return group === 'editor' && type === 'mode';
  });
}
