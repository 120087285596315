import PropTypes from 'prop-types';
import React, { Component } from 'react';
import classnames from 'classnames';

const gridCellGroupTypes = {
  topLayer: 'top-layer',
  null: null,
};

const gridCellGroupSizes = {
  medium: 'medium',
  null: null,
};

export default class GridCellGroup extends Component {
  static propTypes = {
    children: PropTypes.node,
    style: PropTypes.shape({}),
    id: PropTypes.string,
    attributes: PropTypes.shape({}),
    size: PropTypes.oneOf(Object.values(gridCellGroupSizes)),
    type: PropTypes.oneOf(Object.values(gridCellGroupTypes)),

    onMouseDown: PropTypes.func,
    storeRef: PropTypes.func,
  };

  static defaultProps = {
    children: null,
    onMouseDown: null,
    style: null,
    id: null,
    attributes: {},
    size: gridCellGroupSizes.null,
    storeRef: null,
    type: gridCellGroupTypes.null,
  };

  render() {
    const {
      id,
      style,
      size,
      type,
    } = this.props;

    return (
      <div
        className={classnames('grid__cellgroup', {
          [`grid__cellgroup--size--${size}`]: size,
          [`grid__cellgroup--type--${type}`]: type,
        })}
        onMouseDown={this.props.onMouseDown}
        id={id}
        style={style}
        ref={this.props.storeRef}
        {...this.props.attributes}
      >
        {this.props.children}
      </div>
    );
  }
}

export {
  gridCellGroupSizes,
  gridCellGroupTypes,
};
