import { sessionsManager } from '@pdffiller/jsf-loader/JSFillerStores';

const store = {};

const initialPdfTextStore = {
  textContent: [],
  textSamples: {},
};

if (__CLIENT__) {
  window.JSFillerStores = { ...window.JSFillerStores, pdfTextStore: store };
}

const getPdfTextStore = () => {
  const sessionId = sessionsManager.getCurrentSessionId();

  if (!store[sessionId]) {
    store[sessionId] = initialPdfTextStore;
  }

  return store[sessionId];
};

const deletePdfTextBySessionId = (id) => {
  delete store[id];
};

const updateTextSamples = ({ textContent, pageId }) => {
  const pdfTextStore = getPdfTextStore();

  textContent.forEach((textItem) => {
    const { pdfFontName, text } = textItem;
    if (
      !pdfTextStore.textSamples[pdfFontName] ||
      pdfTextStore.textSamples[pdfFontName].length < text.length ||
      pdfTextStore.textSamples[pdfFontName].pageId !== pageId
    ) {
      pdfTextStore.textSamples[pdfFontName] = { ...textItem, pageId };
    }
  });
};

sessionsManager.onSessionRemoved(deletePdfTextBySessionId);

export default {
  getPdfText: (pageId) => {
    return getPdfTextStore().textContent[pageId];
  },

  getPdfTextSample: (pdfFontName) => {
    return getPdfTextStore().textSamples[pdfFontName];
  },

  putPdfText: (pageId, textContent) => {
    getPdfTextStore().textContent[pageId] = textContent;
    updateTextSamples({ textContent, pageId });
  },

  clear: () => {
    getPdfTextStore().textContent = [];
    getPdfTextStore().textSamples = {};
  },
};
