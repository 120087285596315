import Index from './Dropdown/Dropdown';
import Body from './Dropdown/DropdownBody';

const Dropdown = {
  Index,
  Body,
};

export {
  // eslint-disable-next-line import/prefer-default-export
  Dropdown,
};
