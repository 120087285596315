import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

export const togglerThemes = {
  primary: 'primary',
  lightRounded: 'light-rounded',
  null: null,
};

export const togglerSizes = {
  parentWidth: 'parent-width',
  null: null,
};

export const togglerOffsets = {
  extraSmall: 'extra-small',
  null: null,
};

const Toggler = ({
  children,
  theme,
  offset,
  size,
}) => {
  return (
    <div
      className={classnames('toggler', {
        [`toggler--theme--${theme}`]: theme,
        [`toggler--offset--${offset}`]: offset,
        [`toggler--size--${size}`]: size,
      })}
    >
      <div className="toggler__body">
        {children}
      </div>
    </div>
  );
};

Toggler.propTypes = {
  children: PropTypes.element.isRequired,
  theme: PropTypes.string,
  offset: PropTypes.string,
  size: PropTypes.string,
};

Toggler.defaultProps = {
  theme: togglerThemes.null,
  offset: togglerOffsets.null,
  size: togglerSizes.null,
};

export default Toggler;
