import {
  getNextLocalId,
  getElementDefaults,
  applyDefaultsContent,
} from '../Operations/Utils';

import { ATTRIBUTE_NAMES } from '../../../constants';

function getDefaultsDiffWithAttribute(attrName, attribute, { defaults: defs }) {
  if (!attribute || !attribute.content) {
    return false;
  }

  // we are searching diff within defaults.content and attrContent
  const defaults = getElementDefaults(defs.content, ATTRIBUTE_NAMES.attributes, attrName);

  const diffObj = {};

  // iterate over defaults keys
  Object.keys(defaults).forEach((key) => {
    if (!attribute.content.hasOwnProperty(key)) {
      return;
    }

    if (attribute.content[key] !== defaults[key]) {
      defaults[key] = attribute.content[key];

      if (diffObj[defaults.id] === undefined) {
        diffObj[defaults.id] = {};
      }

      diffObj[defaults.id][key] = attribute.content[key];
    }
  });

  // return diffObj if it has keys
  if (Object.keys(diffObj).length) {
    return diffObj;
  }

  return false;
}

function doUpdateAttrDefaults(transport, state) {
  const { defaults, attributes } = state;
  if (attributes === null) {
    return {};
  }

  const updatedAttrs = Object.values(ATTRIBUTE_NAMES)
    .filter((attrName) => {
      return attributes.hasOwnProperty(attrName);
    })
    .reduce((accum, attrName) => {
      const diff = getDefaultsDiffWithAttribute(attrName, attributes[attrName], state);
      return {
        ...accum,
        ...diff,
      };
    }, {});

  const sendOperationsCount = getNextLocalId(state.sendOperationsCount);

  return {
    defaults: applyDefaultsContent(defaults, updatedAttrs),
    sendOperationsCount,
  };
}

export default doUpdateAttrDefaults;
