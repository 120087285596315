import find from 'lodash/find';

import {
  send,
  createId,
  getNextLocalId,
} from '../Operations/Utils';

const GROUP_FONT = 'font';
const TYPE_RECOGNIZE = 'recognize';

export const doSendFontRecognitionImage = (transport, state, action) => {
  const sendOperationsCount = getNextLocalId(state.sendOperationsCount);
  const id = createId(sendOperationsCount);

  const properties = {
    group: GROUP_FONT,
    type: TYPE_RECOGNIZE,
    image: action.base64,
    imageFormat: action.format,
    text: action.text,
  };

  send(transport, id, properties);

  return {
    sendOperationsCount,
  };
};

export const doReceiveRecognizedFont = (state, action) => {
  const operation = find(action.operations, (op) => {
    const { group, type } = op.properties;
    return group === GROUP_FONT && type === TYPE_RECOGNIZE;
  });

  if (operation === undefined) {
    return {};
  }

  const {
    ascenderHeight,
    baseHeight,
    baseLine,
    bgColor,
    descenderHeight,
    font: fontName,
    lineHeight,
    probability,
    textColor,
  } = operation.properties;

  return {
    recognizedFont: {
      ascenderHeight,
      baseHeight,
      baseLine,
      bgColor,
      descenderHeight,
      fontName,
      lineHeight,
      probability,
      textColor,
    },
  };
};
