import { sessionsManager } from '@pdffiller/jsf-loader/JSFillerStores';
import { getQSParams } from '@pdffiller/jsf-loader/auth';

const store = {};

if (__CLIENT__) {
  window.JSFillerStores = { ...window.JSFillerStores, pdfDocumentStore: store };
}

const getStore = () => {
  const sessionId = sessionsManager.getCurrentSessionId();

  if (!store[sessionId]) {
    store[sessionId] = {};
  }

  return store[sessionId];
};

const deletePdfDocumentBySessionId = (id) => {
  const { embedded } = getQSParams();

  // TODO: избавиться от этой логике в JSF-8041
  // после нажатия на Search в FindAnotherForm
  // посылается destroy, на который уничтожается сессия
  // но приложение работает еще какое-то время, и ему нужны pdfPages и pdfDocuments
  if (embedded) {
    delete store[id];
  }
};

export default {

  getPdfDocument: () => {
    return getStore().pdf || false;
  },

  getLocalPdf: () => {
    return getStore().localPdf || false;
  },

  setLocalPdf: (arrayBuffer) => {
    getStore().localPdf = arrayBuffer;
  },

  setPdfDocument: (pdfDocument) => {
    getStore().pdf = pdfDocument;
  },

  hasPdfDocument: () => {
    return !!getStore().pdf;
  },

  setImagePageLoader: (loader) => {
    getStore().imageLoader = loader;
  },

  getImagePageLoader: () => {
    return getStore().imageLoader || false;
  },

  clear: () => {
    delete getStore().pdf;
    delete getStore().imageLoader;
  },
};

sessionsManager.onSessionRemoved(deletePdfDocumentBySessionId);
