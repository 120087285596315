import PropTypes from 'prop-types';
import React, { Component } from 'react';

import * as ToolBar from './ToolBar';
import Icon, { iconSizes } from './Icon';
import Control, {
  controlBehaviors,
  dataPointerTypes,
  controlOffsets,
  getBoxIcon,
  controlThemes,
  boxIconTypes,
} from './Control';
import Text, { textOffsets, textWeights } from './Text';
import { toolbarItemTypes } from './ToolBar/ToolBarItem';

const getBoxIconByColor = ({ boxColor, icon }) => {
  const type = icon
    ? boxIconTypes.thin
    : boxIconTypes.null;

  return (boxColor
    ? getBoxIcon({ boxColor, type })
    : null
  );
};

export default class SettingButton extends Component {
  static propTypes = {
    id: PropTypes.string,
    title: PropTypes.string,
    icon: PropTypes.string,
    input: PropTypes.oneOfType([
      PropTypes.element,
      PropTypes.arrayOf(PropTypes.oneOfType([
        PropTypes.element,
        PropTypes.bool,
      ])),
    ]),
    behavior: PropTypes.oneOf(
      Object.values(controlBehaviors),
    ),
    itemType: PropTypes.oneOf(
      Object.values(toolbarItemTypes),
    ),
    type: PropTypes.string,
    isPressed: PropTypes.bool,
    isDisabled: PropTypes.bool,
    boxColor: PropTypes.string,
    size: PropTypes.oneOf(
      Object.values(iconSizes),
    ),
    dataPointer: PropTypes.oneOf(
      Object.values(dataPointerTypes),
    ),
    label: PropTypes.string,
    textSize: PropTypes.number,
    textWeight: PropTypes.oneOf(
      Object.values(textWeights),
    ),
    theme: PropTypes.oneOf(
      Object.values(controlThemes),
    ),
    textOffset: PropTypes.oneOf(
      Object.values(textOffsets),
    ),
    offset: PropTypes.oneOf(
      Object.values(controlOffsets),
    ),
    attributes: PropTypes.shape({}),
    inheritRadius: PropTypes.bool,

    onMouseDown: PropTypes.func,
    onClick: PropTypes.func,
    onMouseEnter: PropTypes.func,
    onMouseLeave: PropTypes.func,
    onTouchStart: PropTypes.func,
    onFocus: PropTypes.func,
    storeRef: PropTypes.func,
    children: PropTypes.node,
  };

  static defaultProps = {
    id: null,
    title: null,
    icon: null,
    input: null,
    size: iconSizes.small,
    behavior: controlBehaviors.column,
    itemType: toolbarItemTypes.control,
    dataPointer: dataPointerTypes.null,
    offset: controlOffsets.null,
    textWeight: textWeights.bold,
    theme: controlThemes.transparentGray,
    textSize: 11,
    textOffset: null,
    label: null,
    type: null,
    boxColor: null,
    isPressed: false,
    isDisabled: false,
    inheritRadius: false,
    onClick: null,
    onMouseDown: null,
    onMouseEnter: null,
    onMouseLeave: null,
    onTouchStart: null,
    onFocus: null,
    storeRef: null,
    children: null,
    attributes: null,
  };

  render() {
    const {
      id,
      icon,
      input,
      title,
      type,
      textSize,
      textWeight,
      itemType,
      size,
      boxColor,
      isPressed,
      behavior,
      label,
      isDisabled,
      dataPointer,
      theme,
      textOffset,
      offset,
      inheritRadius,
      storeRef,
      children,
      attributes,
    } = this.props;

    return (
      <ToolBar.Item
        id={id}
        type={itemType}
        storeRef={storeRef}
        {...attributes}
      >
        {input}
        {children}
        <Control
          type={type}
          behavior={behavior}
          title={title}
          theme={theme}
          isPressed={isPressed}
          disabled={isDisabled}
          dataPointer={dataPointer}
          offset={offset}
          icon={
            icon
              ? <Icon size={size} type={icon} />
              : null
          }
          boxIcon={getBoxIconByColor({ boxColor, icon })}
          inheritRadius={inheritRadius}
          onMouseDown={this.props.onMouseDown}
          onClick={this.props.onClick}
          onMouseEnter={this.props.onMouseEnter}
          onMouseLeave={this.props.onMouseLeave}
          onTouchStart={this.props.onTouchStart}
          onFocus={this.props.onFocus}
        >
          {
            label
              ? (
                <Text
                  weight={textWeight}
                  size={textSize}
                  offset={textOffset}
                >
                  {label}
                </Text>
              )
              : null
          }
        </Control>
      </ToolBar.Item>
    );
  }
}
