import letterCases from '../../helpers/const/letterCases';
import viewModes from '../../helpers/const/viewModes';

export const UPDATE_LOCALE = 'locale/UPDATE_LOCALE';
export const DEFAULT_MESSAGES = {
  loadingPages: 'Loading page %current% of %total%.',
  intro: {
    A1: "To enter data on this form, click anywhere and begin typing. Click 'DONE' to proceed.",
    D1: "To enter data on this form, use fillable fields or click anywhere and begin typing. Click 'DONE' to proceed.",
    R1: 'Click DONE to exit.',
  },
  saving: 'Saving...',
  saved: 'All changes have been saved.',
  exiting: 'Closing your form...',
};

export const initialStateLocale = {
  editor: {
    assistant: {
      sticker: {
        messages: {
          moreThan10: 'List of steps to complete this document.',
          lessThan11: 'Only %N% more steps to complete the document.',
          allCompleted: 'Well done!\nAll steps completed!',
        },
        footer: '<font color="#E74C3C">*</font> - required fields',
      },
      tools: {
        basic_field_text: {
          uiName: 'Text',
          dbName: 'Text_%N%',
          defaultLabel: 'text',
          stickerText: 'Enter %LABEL%',
          iconType: 'text-new',
          panelData: {
            fieldEmpty: { text: 'Enter %LABEL%', buttons: [{ id: 'next', label: 'Next' }] },
            fieldFilled: { text: 'Enter %LABEL%', buttons: [{ id: 'next', label: 'Next' }] },
          },
        },
        basic_field_number: {
          uiName: 'Number',
          dbName: 'Number_%N%',
          defaultLabel: 'a number',
          stickerText: 'Enter %LABEL%',
          iconType: 'number-new',
          panelData: {
            fieldEmpty: { text: 'Enter %LABEL%', buttons: [{ id: 'next', label: 'Next' }] },
            fieldFilled: { text: 'Enter %LABEL%', buttons: [{ id: 'next', label: 'Next' }] },
          },
        },
        basic_field_date: {
          uiName: 'Date',
          dbName: 'Date_%N%',
          defaultLabel: 'a date',
          stickerText: 'Select %LABEL%',
          iconType: 'date-new',
          panelData: {
            fieldEmpty: { text: 'Select %LABEL%', buttons: [{ id: 'next', label: 'Confirm' }] },
            fieldFilled: { text: 'Select %LABEL%', buttons: [{ id: 'next', label: 'Next' }] },
          },
        },
        basic_field_dropdown: {
          uiName: 'Dropdown',
          dbName: 'Dropdown_%N%',
          custom: 'or enter your own',
          defaultLabel: 'the list',
          stickerText: 'Select from %LABEL%',
          iconType: 'dropdown-new',
          panelData: {
            fieldEmpty: {
              text: 'Select an option from %LABEL%',
              liteText: 'Select an item',
              buttons: [{ id: 'next', label: 'Confirm' }],
            },
            fieldFilled: {
              text: 'Select an option from %LABEL%',
              liteText: 'Select an item',
              buttons: [{ id: 'next', label: 'Confirm' }],
            },
          },
        },
        basic_field_checkmark: {
          uiName: 'Checkbox',
          dbName: 'Checkbox_%N%',
          defaultLabel: 'a checkbox',
          stickerText: 'Select %LABEL%',
          iconType: 'checkbox-new',
          panelData: {
            fieldEmpty: {
              text: 'Select %LABEL%',
              buttons: [{ id: 'check', label: 'Check' }, { id: 'next', label: 'Next' }],
            },
            fieldFilled: {
              text: 'Unselect %LABEL%',
              buttons: [{ id: 'uncheck', label: 'Uncheck' }, { id: 'next', label: 'Next' }],
            },
          },
        },
        basic_field_signature: {
          uiName: 'Signature',
          dbName: 'Signature_%N%',
          defaultLabel: 'a signature',
          stickerText: 'Add %LABEL%',
          iconType: 'sign-new',
          panelData: {
            fieldEmpty1: {
              text: 'Click Sign to add %LABEL%',
              liteText: 'Add %LABEL%',
              buttons: [{ id: 'add', label: 'Sign' }, { id: 'next', label: 'Next' }],
            },
            fieldEmpty2: {
              text: 'Click Sign to use your previous signature, or New to add a new signature',
              liteText: 'Add %LABEL%',
              buttons: [{ id: 'reuse', label: 'Sign' }, { id: 'add', label: 'New' }],
            },
            fieldFilled: {
              text: 'Please click Replace to change %LABEL%.',
              liteText: 'Add %LABEL%',
              buttons: [{ id: 'replace', label: 'Replace' }, { id: 'next', label: 'Next' }],
            },
          },
        },
        basic_field_initials: {
          uiName: 'Initials',
          dbName: 'Initials_%N%',
          defaultLabel: 'initials',
          stickerText: 'Add %LABEL%',
          iconType: 'initials-bordered',
          panelData: {
            fieldEmpty1: {
              text: 'Click Add to put your %LABEL%',
              liteText: 'Add %LABEL%',
              buttons: [{ id: 'add', label: 'Add' }, { id: 'next', label: 'Next' }],
            },
            fieldEmpty2: {
              text: 'Click Add to use your previous %LABEL%, or New to add a new %LABEL%',
              liteText: 'Add %LABEL%',
              buttons: [{ id: 'reuse', label: 'Add' }, { id: 'add', label: 'New' }],
            },
            fieldFilled: {
              text: 'Please click Replace to change %LABEL%',
              liteText: 'Add %LABEL%',
              buttons: [{ id: 'replace', label: 'Replace' }, { id: 'next', label: 'Next' }],
            },
          },
        },
        basic_field_image: {
          uiName: 'Image',
          dbName: 'Image_%N%',
          defaultLabel: 'an image',
          stickerText: 'Add %LABEL%',
          iconType: 'picture',
          panelData: {
            fieldEmpty: {
              text: 'Click Add to select, upload or capture %LABEL%',
              liteText: 'Add %LABEL%',
              buttons: [{ id: 'add', label: 'Add' }, { id: 'next', label: 'Next' }],
            },
            fieldFilled: {
              text: 'Click Replace to replace the existing image with a new one',
              liteText: 'Add %LABEL%',
              buttons: [{ id: 'replace', label: 'Replace' }, { id: 'next', label: 'Next' }],
            },
          },
        },
        basic_field_formula: {
          uiName: 'Formula',
          dbName: 'Formula_%N%',
          defaultLabel: '',
          iconType: 'formula-bordered',
          panelData: { fieldEmpty: { text: '', buttons: [] }, fieldFilled: { text: '', buttons: [] } },
        },
        basic_field_radio: {
          uiName: 'Radio Button',
          dbName: 'Radio_Button_%N%',
          defaultLabel: 'options',
          stickerText: 'Choose %LABEL%',
          iconType: 'redesign--radio-button',
          panelData: {
            groupEmpty: {
              text: 'Click on one of the %LABEL% to select',
              buttons: [{ id: 'check', label: 'Check' }, { id: 'next', label: 'Next' }],
            },
            groupFilled: {
              text: 'Click on one of the %LABEL% to select',
              buttons: [{ id: 'uncheck', label: 'Uncheck' }, { id: 'next', label: 'Next' }],
            },
          },
        },
        additional_field_name: {
          uiName: 'Name',
          dbName: 'Name_%N%',
          defaultLabel: 'name',
          stickerText: 'Enter %LABEL%',
          iconType: 'redesign--squared-user',
          panelData: {
            fieldEmpty: { text: 'Enter your %LABEL%', buttons: [{ id: 'next', label: 'Next' }] },
            fieldFilled: { text: 'Enter your %LABEL%', buttons: [{ id: 'next', label: 'Next' }] },
          },
        },
        additional_field_email: {
          uiName: 'Email',
          dbName: 'Email_%N%',
          defaultLabel: 'email',
          stickerText: 'Enter %LABEL%',
          iconType: 'redesign--email-24',
          panelData: {
            fieldEmpty: { text: 'Enter your %LABEL%', buttons: [{ id: 'next', label: 'Next' }] },
            fieldFilled: { text: 'Enter your %LABEL%', buttons: [{ id: 'next', label: 'Next' }] },
          },
        },
        additional_field_company: {
          uiName: 'Company',
          dbName: 'Company_%N%',
          defaultLabel: 'company',
          stickerText: 'Enter %LABEL%',
          iconType: 'redesign--company',
          panelData: {
            fieldEmpty: { text: 'Enter your %LABEL%', buttons: [{ id: 'next', label: 'Next' }] },
            fieldFilled: { text: 'Enter your %LABEL%', buttons: [{ id: 'next', label: 'Next' }] },
          },
        },
        additional_field_title: {
          uiName: 'Title',
          dbName: 'Title_%N%',
          defaultLabel: 'title',
          stickerText: 'Enter the %LABEL%',
          iconType: 'redesign--user-info',
          panelData: {
            fieldEmpty: { text: 'Enter the %LABEL%', buttons: [{ id: 'next', label: 'Next' }] },
            fieldFilled: { text: 'Enter the %LABEL%', buttons: [{ id: 'next', label: 'Next' }] },
          },
        },
        additional_field_phone: {
          uiName: 'US Phone Number',
          dbName: 'US_Phone_Number_%N%',
          defaultLabel: '',
          stickerText: 'Enter a US phone number',
          iconType: 'redesign--phone',
          panelData: {
            fieldEmpty: { text: '<from validator>', buttons: [{ id: 'next', label: 'Next' }] },
            fieldFilled: { text: '<from validator>', buttons: [{ id: 'next', label: 'Next' }] },
          },
        },
        additional_field_us_zip: {
          uiName: 'Zip Code',
          dbName: 'Zip_Code_%N%',
          defaultLabel: '',
          stickerText: 'Enter a US zip code',
          iconType: 'redesign--post',
          panelData: {
            fieldEmpty: { text: '<from validator>', buttons: [{ id: 'next', label: 'Next' }] },
            fieldFilled: { text: '<from validator>', buttons: [{ id: 'next', label: 'Next' }] },
          },
        },
        additional_field_us_currency: {
          uiName: 'US Currency',
          dbName: 'US_Currency_%N%',
          defaultLabel: '',
          stickerText: 'Enter an amount',
          iconType: 'redesign--usd',
          panelData: {
            fieldEmpty: { text: '<from validator>', buttons: [{ id: 'next', label: 'Next' }] },
            fieldFilled: { text: '<from validator>', buttons: [{ id: 'next', label: 'Next' }] },
          },
        },
        additional_field_eu_currency: {
          uiName: 'EU Currency',
          dbName: 'EU_Currency_%N%',
          defaultLabel: '',
          stickerText: 'Enter an amount',
          iconType: 'redesign--eur',
          panelData: {
            fieldEmpty: { text: '<from validator>', buttons: [{ id: 'next', label: 'Next' }] },
            fieldFilled: { text: '<from validator>', buttons: [{ id: 'next', label: 'Next' }] },
          },
        },
        additional_field_age: {
          uiName: 'Age',
          dbName: 'Age_%N%',
          defaultLabel: '',
          stickerText: 'Enter an Age',
          iconType: 'redesign--cake',
          panelData: {
            fieldEmpty: { text: '<from validator>', buttons: [{ id: 'next', label: 'Next' }] },
            fieldFilled: { text: '<from validator>', buttons: [{ id: 'next', label: 'Next' }] },
          },
        },
        additional_field_ssn: {
          uiName: 'SSN',
          dbName: 'SSN_%N%',
          defaultLabel: '',
          stickerText: 'Enter SSN',
          iconType: 'redesign--build',
          panelData: {
            fieldEmpty: { text: '<from validator>', buttons: [{ id: 'next', label: 'Next' }] },
            fieldFilled: { text: '<from validator>', buttons: [{ id: 'next', label: 'Next' }] },
          },
        },
        additional_field_ein: {
          uiName: 'EIN',
          dbName: 'EIN_%N%',
          stickerText: 'Enter EIN',
          iconType: 'redesign--ein',
          panelData: {
            fieldEmpty: { text: 'Enter EIN', buttons: [{ id: 'next', label: 'Next' }] },
            fieldFilled: { text: 'Enter EIN', buttons: [{ id: 'next', label: 'Next' }] },
          },
        },
        additional_field_ccn: {
          uiName: 'Credit Card Number',
          dbName: 'Credit_Card_Number_%N%',
          defaultLabel: '',
          stickerText: 'Enter a CCN',
          iconType: 'redesign--card',
          panelData: {
            fieldEmpty: { text: '<from validator>', buttons: [{ id: 'next', label: 'Next' }] },
            fieldFilled: { text: '<from validator>', buttons: [{ id: 'next', label: 'Next' }] },
          },
        },
        additional_field_states: {
          uiName: 'US States Collection',
          dbName: 'US_States_Collection_%N%',
          defaultLabel: '',
          stickerText: 'Enter a US state',
          iconType: 'redesign--flag',
          panelData: {
            fieldEmpty: { text: '<from validator>', buttons: [{ id: 'next', label: 'Next' }] },
            fieldFilled: { text: '<from validator>', buttons: [{ id: 'next', label: 'Next' }] },
          },
        },
        additional_field_gender: {
          uiName: 'Gender',
          dbName: 'Gender_%N%',
          stickerText: 'Select the gender',
          iconType: 'redesign--gender',
          panelData: {
            fieldEmpty: { text: 'Select the gender', buttons: [{ id: 'next', label: 'Next' }] },
            fieldFilled: { text: 'Select the gender', buttons: [{ id: 'next', label: 'Next' }] },
          },
        },
      },
    },
    connectionLost: {
      title: 'The secure connection to server has timed out',
      description: '<p>It looks like your device has been idle for a while.</p>' +
        '<p>Please click Connect to re-establish the connection.</p>',
      retry: 'Connect',
    },
    reconnect: {
      title: 'Connection lost',
      description: '<p>Trying to reconnect...</p>',
      retry: '',
    },
    reportProblem: {
      buttonText: 'Report a problem',
      modalTitle: 'We’re Sorry',
      modalContent: 'Please provide us with as much detail as possible so that we may better assist you.',
      modalOkButton: 'Report a problem',
    },
    tryNow: {
      title: 'The Document is Currently in Use',
      editedBy: 'This document is being edited by:',
      beforeTimer: 'We will check again in',
      afterTimer: 'seconds to see if the document becomes available.',
      cancel: 'Cancel',
      tryNow: 'Try Now',
    },
    doneButton: {
      main: {
        label: 'Done',
      },
    },
    headerDeclineButton: {
      label: 'Decline',
    },
    headerDraftButton: {
      label: 'Draft',
    },
    help: {
      title: 'Help',
    },
    headerTools: {
      more: 'More',
      select: 'Select...',
    },
    headerZoom: {
      fitPage: 'Fit Page',
      fitWidth: 'Fit Width',
      zoom: 'Zoom',
    },
    wizardTodoPopupView: {
      title: 'All Set!',
      description: 'You’ve filled out all of the required fields in this document. If you’re ready to submit it, click Done to do so now.',
    },
    wizardAllFieldsFilledView: {
      title: 'All Set!',
      description:
        `You’ve filled out all fields in this document.
        If you’re ready to submit it, click Done to do so now.`,
    },
    wizardAssistantDateView: {
      months: [
        'January', 'February', 'March', 'April', 'May',
        'June', 'July', 'August', 'September', 'October',
        'November', 'December',
      ],
    },
    wizzardTodoPopupView: {
      title: 'All Set!',
      description: 'You’ve filled out all of the required fields in this document. If you’re ready to submit it, click Done to do so now.',
    },
    wizzardAssistantDateView: {
      months: [
        'January',
        'February',
        'March',
        'April',
        'May',
        'June',
        'July',
        'August',
        'September',
        'October',
        'November',
        'December',
      ],
    },
    wizardTodo: {
      needToFillTitle: 'Fields to fill in',
      needToFillDescription: 'Use the list below to keep track of the fields you have to fill in.',
      requiredFields: 'Show only required fields',
      todoList: 'Todo list',
    },
    wizardFullLiteButton: {
      label: 'Wizard',
      title: 'Toggle Wizard',
    },
    pagePrint: {
      text: 'To print the document, please select Print from DONE menu.',
    },
    paginationLabel: {
      template: 'Page %current% of %total%',
    },
    toolbarAttributes: {
      settings: 'Settings',
      OK: 'OK',
      done: 'Done',
      back: 'Back',
      color: 'Color',
      background: 'Background',
      border: 'Border',
      font: 'Font',
      fontSize: 'Font Size',
      fontStyle: 'Style',
      arrowStyle: 'Arrow type',
      basicColors: 'Basic Colors',
      moreColors: 'More Colors',
      stamp: 'Stamp',
      align: 'Align',
      valign: 'Vertical align',
      href: 'Hyperlink',
      size: 'Size',
    },
    toolbarHelpView: {
      findAnotherForm: 'Find another form',
      message: 'Message',
      formLookingFor: 'Not the form you were looking for?',
      text: 'Find another form',
    },
    thumbnails: {
      label: '<div class="counter__label">Page</div> %current% <div class="counter__separator">of</div> %total%',
      labelInit: 'Pages',
    },
    wizardPointer: {
      next: 'Next',
      start: 'Start',
      continue: 'Continue',
      fill: 'Fill',
    },
    wizardStartButton: {
      start: 'Start',
    },
    wizardPhoneBottomSheetMenu: {
      saveAndExit: 'Save and Exit',
      annotate: 'Annotate',
      backToFill: 'Back to Fill',
    },
    toolbarNavigation: {
      showPages: 'Show pages',
      hidePages: 'Hide pages',
      editPages: 'Edit pages',
      tooltips: {
        showPages: 'Hide or display the pages panel.',
        rearrange: 'Rearrange the page order of your document, add extra pages, duplicate or delete pages.',
      },
      undo: 'Undo last actions',
      redo: 'Redo to set changes back',
    },
    errorModal: {
      title: 'Oops, Something Went Wrong!',
      content: 'We’re sorry you’re having trouble. Please click the button below to try the alternative PDFfiller Editor.',
      confirm: 'Try Alternative Editor',
      reload: 'Reload',
      confirmMobile: 'Try Again',
    },
    smallScreenMessage: {
      title: 'Sorry, your screen size is not supported',
      landscapeTitle: 'Landscape mode is not supported',
      description: 'Please resize your browser window and make it bigger',
    },
    processingModal: {
      title: 'Processing',
      text: 'Please wait',
    },
    successModal: {
      title: 'Changes Saved!',
      text: 'Version %version%',
    },
    helpModal: {
      title: 'Welcome',
      text: 'Version %version%',
    },
    placeholders: {
      initials: 'Initials',
      signature: 'Signature',
      image: 'Image',
    },
    messages: DEFAULT_MESSAGES,
    labels: {
      toolbar: {
        'tools.text.none': 'Text',
        'tools.checkmark.x': 'Cross',
        'tools.checkmark.v': 'Check',
        'tools.checkmark.o': 'Circle',
        'tools.signature.*': 'Sign',
        'tools.text.date': 'Date',
        'tools.image.none': 'Picture',
        'tools.erase.none': 'Erase',
        'tools.highlight.none': 'Highlight',
        'tools.blackout.none': 'Blackout',
        'tools.textbox.none': 'Text Box',
        'tools.arrow.none': 'Arrow',
        'tools.line.none': 'Line',
        'tools.pen.none': 'Draw',
        'tools.sticky.none': 'Sticky',
      },
      extrasbar: {
        'tools.template': 'Constructor',
        versions: 'Versions',
        'tools.attributes': 'Watermark',
      },
      misc: {
        'tools.pages': 'Rearrange',
        'tools.comments': 'Comments',
        'common.thumbnails': 'Thumbnails',
        'common.help': 'Help',
        'common.feedback': 'Feedback',
        'common.search': 'Search',
      },
      modebar: {
        'mode.main': 'Edit',
        'mode.constructor': 'Add Fields',
        'mode.preview': 'Preview',
      },
      constructor: {
        'button.preview': 'Preview',
        'button.cancel': 'Cancel',
        'button.save': 'SAVE',
      },
      preview: {
        'button.exit_preview': 'Exit',
      },
    },
    modals: {
      text_dropdown: {
        text: 'Dropdown is empty, please define items.',
        okButtonText: 'OK',
      },
      invalid_initial_value: {
        text: 'Please enter correct values',
        okButtonText: 'OK',
      },
      autofill: {
        title: 'Welcome back!',
        text: 'We noticed you filled out this form earlier. <br/> Would you like to pre-fill a form with the previous data?',
        okButtonText: 'Sure, let’s do it!',
        cancelButtonText: 'No thanks',
      },
      autofillError: {
        title: 'Sorry, no data found',
        text: 'Your document couldn’t be automatically pre-filled. Please fill out this form manually.',
        okButtonText: 'Got it',
      },
      loading: {
        title: 'Loading...',
      },
      versionsModeError: {
        title: 'Title',
        text: 'Text',
        okButtonText: 'okButtonText',
      },
      dropdown_duplicate_value: {
        text: 'The dropdown item has already existed, please replace it.',
        okButtonText: 'OK',
      },
    },
    search: {
      label: 'Search',
      searchOf: 'of',
    },
    feedback: {
      label: 'Feedback',
    },
    fakeEdit: {
      label: 'Alter original',
      hints: {
        initial: {
          active: 'Disable original PDF altering',
          inActive: 'Enable original PDF altering',
        },
        rollover: {
          active: 'Disable original PDF altering',
          inActive: 'Enable original PDF altering',
        },
      },
    },
    toolsLabels: {
      stamp: 'Stamp',
      remove: 'Remove',
      done: 'Done',
      skip: 'Skip',
      save: 'Save',
      confirm: 'Confirm',
    },
    editorInfo: {
      readOnlyAccess: 'Read Only Access',
      fillingWizard: `Step-By-Step Filling Wizard. Use the orange button on the
                      tooltip to go to the next field. Click on Done when finished.`,
      fillingWizardMobile: 'Filling Wizard',
    },
    apply: {
      label: 'Apply',
    },
    fakeEditHint: {
      text: 'Double click to edit this text',
    },
    searchModal: {
      title: 'Search for Fillable Documents',
      description: "Search PDFfiller's library of over 10 Million forms by name or description",
      placeholder: 'Describe the Form',
      primaryButton: 'Search',
    },
  },
  fConstructor: {
    headers: {
      breadcrumbs: {
        editor: 'Editor',
        addFillableDefault: 'Add Fillable Fields',
        addWatermark: 'Add Watermark',
        preview: 'Preview document',
      },
      buttons: {
        cancel: 'Cancel',
        save: 'SAVE',
        close: 'Close',
        preview: 'Preview',
        exitPreview: 'Exit Preview',
      },
      previewToolbarAdvice: `&nbsp;&nbsp;You’re in preview mode.
      Changes won’t be saved, so feel free to test the fields and workflow!`,
      modesBar: {
        main: 'Edit',
        constructor: 'Add Fields',
        preview: 'Preview',
      },
    },
    tabs: {
      fields: {
        tabHeading: 'Select field type to add',
        tabDescription: 'PDFfiller has a wide range of specific fillable filelds. Please choose the element that match users’ data.',
        standardFieldsHeading: 'Standard fields',
        templateFieldsHeading: 'Template fields',
      },
      options: {
        general: {
          textToHelp: {
            header: 'Add some Text to Help',
            hint: `Create a label that explains what to type in the fields
              (ex. "First name" <br /> "Last name" "Birthdate"). This will appear as
              follows: <i>Please enter First Name</i>. <br />`,
          },
          dropdownBox: {
            label: 'Dropdown items',
            placeholder: 'Item name',
            add: 'Add',
            own: 'or enter your own',
            alternativeChoice: {
              label: 'Allow an alternative choice',
            },
            emptyChoice: {
              label: 'Allow an empty choice',
            },
          },
          requiredCheckbox: {
            hint: 'Require users to enter data into this field before submitting.',
            label: 'Required field',
          },
        },
        advanced: {
          header: 'Advanced',
          hints: {
            default: `<b>Default</b> - Type the default text that you would like to appear in this text field. Click the <br />
            lock icon to lock or unlock the field. A locked field cannot be edited by other users.`,
            defaultCheckmark:
              '<b>Default</b> - Select whether the default setting for a checkbox is checked or unchecked.',
            note:
              `<b>Note</b> - Enter any special instructions or tips for filling this field. Users will see the note <br />
              when they select the field.`,
            maxChars:
              `<b>Max Characters</b> - Set the maximum number of characters allowed in this field. <br />
              The "auto" setting will limit to what can physically fit into the field that you create.`,
            maxLines:
              `<b>Max Lines</b> - Set the maximum number of text lines allowed in this field that you create. <br />
              The "auto" setting will limit to what can physically fit into the field that you create.`,
            dbName:
              `<b>Database Name</b> - Create a name for this field that will appear when you export filled <br />
              data to a spreadsheet or when you use a PDFfiller API.`,
            format:
              '<b>Format</b> - Choose how you would like the date to appear in this field.',
            initial:
              `<b>Initial Value</b> - Choose the value that this field will display before the user makes any <br />
              changes to it.`,
            typeOfCheck:
              `<b>Type of Checkbox</b> - Choose whether you want the checkbox to be formatted as a <br />
              checkmark, X, or circle.`,
            groupName:
              `<b>Group Name</b> - Group checkboxes together by entering the same group name for each <br />
              box. When filling the document, only one text box within a group can be checked <br />
              at any time.`,
          },
        },
        fields: {
          charInCells: {
            label: 'Characters in cells',
          },
          checkboxAdvanced: {
            typeOfCB: 'Type of Checkbox',
            default: 'Default',
            checked: 'Checked',
            notChecked: 'Not Checked',
          },
          dateAdvanced: {
            default: 'Default',
            userSysDate: 'User’s System Date',
            noDate: 'No Date',
            customDate: 'Custom Date',
          },
          dateFormatSelect: [
            { value: 'MM/dd/yyyy', label: 'MM/DD/YYYY' },
            { value: 'MM/dd/yy', label: 'MM/DD/YY' },
            { value: 'yyyy-MM-dd', label: 'YYYY-MM-DD' },
            { value: 'yyyy/MM/dd', label: 'YYYY/MM/DD' },
            { value: 'yyyy-M-d', label: 'YYYY-M-D' },
            { value: 'yyyy/M/d', label: 'YYYY/M/D' },
            { value: 'd.M.yyyy', label: 'D.M.YYYY' },
            { value: 'd-M-yyyy', label: 'D-M-YYYY' },
            { value: 'd/M/yyyy', label: 'D/M/YYYY' },
            { value: 'dd.MM.yyyy', label: 'DD.MM.YYYY' },
            { value: 'dd-MM-yyyy', label: 'DD-MM-YYYY' },
            { value: 'dd/MM/yyyy', label: 'DD/MM/YYYY' },
          ],
          dbFieldName: {
            label: 'Database field name',
            default: '',
          },
          defaultNumber: {
            label: 'Default number (optional)',
            placeholder: 'e.g. 12345',
          },
          dropdown: {
            label: 'Default',
          },
          fieldNameOfTextField: {
            label: 'Field name',
            labelRequired: ' (required)',
          },
          format: {
            label: 'Format',
          },
          letterCase: {
            label: 'Letter Case',
          },
          letterCaseSelect: [
            { value: letterCases.none, label: 'None' },
            { value: letterCases.sentence, label: 'Sentence' },
            { value: letterCases.upper, label: 'UPPER' },
            { value: letterCases.lower, label: 'lower' },
          ],
          lock: {
            labelLock: 'Lock the field',
            labelUnlock: 'Unlock the field',
          },
          maxCharMaxLine: {
            maxChar: {
              label: 'Max characters',
              default: 'Auto',
            },
            maxLine: {
              label: 'Max lines',
              default: 'Auto',
            },
          },
          lineSpacing: {
            label: 'Line spacing',
            placeholder: '1.21',
          },
          note: {
            label: 'Note (Optional)',
            placeholder: 'i.g. Please check…',
          },
          placeholder: {
            label: 'Placeholder',
            placeholder: 'i.e. My placeholder',
          },
          radioAdvanced: {
            typeOfCB: 'Type of Radio',
            default: 'Default checked radio',
            checked: 'Checked',
            notChecked: 'Not Checked',
            addButtonLabel: 'Add radio buttons',
            applyButtonText: 'apply',
            placeholder: 'element 1\nelement 2',
            changeRadioValue: {
              label: 'Change value',
              placeholder: 'Please enter a value',
            },
          },
          textWithLock: {
            label: 'Default text (optional)',
            placeholder: 'e.g. John Doe',
          },
          validation: {
            label: 'Validation',
          },
          viewMode: {
            label: 'View mode',
          },
          viewModeSelect: [
            { value: viewModes.classic, label: 'Classic' },
            { value: viewModes.warning, label: 'Warning' },
            { value: viewModes.stretch, label: 'Stretch' },
            { value: viewModes.combo, label: 'Combo' },
          ],
        },
        hints: {
          textToHelp: `Create a label that explains what to type in the fields
            (ex. "First name" <br /> "Last name" "Birthdate"). This will appear as
            follows: <i>Please enter First Name</i>. <br />`,
        },
      },
      order: {
        header: 'Rearrange the order',
        orderListEmpty: {
          label:
          `<p>Whoops! There aren't any fields in the document to reorganize.</p>
          <p>Click the Fields tab to go back and add some custom fillable fields to
          your document.</p>`,
        },
        text: [
          'Set a filling order for the fillable fields in your document. Recipients will complete the fields according to the order you’ve set.',
          'To set the order, simply move fields around.',
        ],
      },
    },
    tabsNames: {
      fields: {
        title: 'Fields',
        hint: 'Add and modify fillable fields in your document.',
      },
      options: {
        title: 'Options',
        hints: {
          enabled: 'Modify fillable fields options.',
          disabled: 'Choose a fillable field type to add to your document.',
        },
      },
      order: {
        title: 'Order',
        hint: 'Rearrange the order in which the <br /> document will be filled.',
      },
    },
    panel: {
      addFillableFields: 'ADD FILLABLE FIELDS',
      addWatermark: 'ADD WATERMARK',
      versions: 'VERSIONS',
    },
    paConstructor: {
      bates: {
        header: 'Bates Numbering',
        startNumber: 'Start Number',
        prefix: 'Prefix',
        suffix: 'Suffix',
        size: 'Size',
        position: 'Position on Page',
        tooltip: `Add bate numbers to your document with options to customize start number,
          prefix, suffix, size and positioning`,
        hint: 'Learn more',
      },
      date: {
        header: 'Date',
        format: 'Format',
        size: 'Size',
        position: 'Position on Page',
        tooltip: `Add a date to each page of your document with options to customize size,
          formatting and positioning`,
        hint: 'Learn more',
      },
      numbering: {
        header: 'Page Numbering',
        size: 'Size',
        position: 'Position on Page',
        displayFormat: 'Format',
        tooltip: `Add page numbers to your document with options to customize size,
          formatting and positioning`,
        hint: 'Learn more',
      },
      watermark: {
        header: 'Watermark',
        text: 'Text',
        size: 'Size',
        opacity: 'Opacity',
        orientation: 'Orientation',
        pageRangeControl: {
          applyTo: 'Apply To',
          all: 'All',
          pageRange: 'Page Range',
          hint: 'Enter page numbers and/of page ranges separated by commas (e.g. 2, 5-8)',
        },
        tooltip: `Add a watermark to any page(s) in your document with options to customize
          text, size, opacity and orientation`,
        hint: 'Learn more',
      },
    },
  },
  comments: {
    headerButton: 'Comments',
    toolbarAddComment: 'Add comment',
    delete: 'Delete',
    threadDeleteConfirm: 'Delete this comment thread?',
    deleteConfirm: 'Delete this comment?',
    cancel: 'Cancel',
    resolve: 'Resolve',
    edit: 'Edit',
    reply: 'Reply',
    save: 'Save',
    comment: 'Comment',
  },
  snfiller: {
    MAKE_PAYMENT: {
      MAKE_PAYMENT: 'Payment',
    },
    COMPACT_HEADER: {
      OPTIONS: 'Options',
      DONE_BUTTON: 'Done',
      CLOSE_BUTTON: 'Close',
      SAVE_AND_INVITE_BUTTON: 'Save and Invite',
      SAVE_DOCUMENT: 'Save Document',
    },
    GUIDED_HEADER: {
      SIGNING: 'Signing',
      CLOSE_BUTTON: 'Close',
      DECLINE_TO_SIGN: 'Decline To Sign',
      DONE_BUTTON: 'Done',
      FORWARD_INVITE: 'Forward Invite',
      GUIDE_ME_BUTTON: 'Guide Me',
      SAVE_AND_PAY: 'Save and Pay',
      SAVE_DOCUMENT: 'Save Document',
    },
  },
  versions: {
    header: {
      hint: 'View and restore previous<br/> versions of your document<br/> without losing any changes.',
      text: 'Versions',
      buttons: {
        close: 'Close',
      },
    },
    breadcrumbs: {
      editor: 'Editor',
      versions: 'Versions',
    },
    list: {
      currentVersion: 'Current Version',
      previousVersion: 'Previous Version',
      buttons: {
        restore: 'Restore',
      },
    },
  },
  supportChat: {
    hintMessage: 'Hi <span aria-label="handshake" role="img">👋</span>, how can we help?',
    supportChatButtonAriaLabel: 'Chat button',
    closeHintButtonTitle: 'Close hint',
  },
};
