import { take, takeEvery, put } from 'redux-saga/effects';

import { CHANGE_PAGE, SET_ACTIVE_PAGE } from 'jsfcore/store/modules/navigationActionTypes';
import { setActiveElement } from '../../thunks';

function getIsNeedRenderNextPage(prevPage, nextPage) {
  return Math.abs(prevPage - nextPage) > 1;
}

// JSF-7770
// При переходе на следующую страницу и активации элемента есть два сценария:
// 1. следующий элемент не на предыдущей/следующей странице
// ждем пока страница будет отренедерна (завязался на SET_ACTIVE_PAGE)
// и устанавлиаем элемент активным
// 2. следующий элемент находится на предыдущей/следующей странице
// устанавливаем активный элемент, т.к. страница уже отрендерена
function* onPageChangeAndSetActiveElementId({ prevPage, nextPage, activeElementId }) {
  if (activeElementId) {
    const isNeedRenderNextPage = getIsNeedRenderNextPage(prevPage, nextPage);
    if (isNeedRenderNextPage) {
      let activePage;

      while (activePage !== nextPage) {
        const action = yield take(SET_ACTIVE_PAGE);
        activePage = action.nextPage;
      }

      yield put(setActiveElement(activeElementId, true));
    } else {
      yield put(setActiveElement(activeElementId, true));
    }
  }
}

export default function* watchOnChangePage() {
  yield takeEvery(
    CHANGE_PAGE,
    onPageChangeAndSetActiveElementId,
  );
}
