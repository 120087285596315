import React from 'react';
import PropTypes from 'prop-types';

const FillableFieldContainer = ({ children }) => {
  return (
    <div className="fillable-field__container">
      {children}
    </div>
  );
};

FillableFieldContainer.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.element.isRequired,
    PropTypes.arrayOf(
      PropTypes.oneOfType([
        PropTypes.element.isRequired,
        PropTypes.oneOf([false]),
      ]),
    ),
  ]).isRequired,
};

export default FillableFieldContainer;
