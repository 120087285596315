import React from 'react';
import PropTypes from 'prop-types';

const ListItem = ({ children, storeRef, ...props }) => {
  return (
    <li
      {...props}
      ref={storeRef}
      className="list__item"
    >
      {children}
    </li>
  );
};

ListItem.propTypes = {
  children: PropTypes.node.isRequired,
  storeRef: PropTypes.func,
};

ListItem.defaultProps = {
  storeRef: null,
};

export default ListItem;
