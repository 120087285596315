// jsf-formula dynamic dependency injection
import * as jsfConditionsDI from '@pdffiller/jsf-conditions/di';
import isSignNow from './isSignNow';
import { createMapFromElements } from './store/models/Operations/Utils';

// jsf-conditions dynamic dependency injection
import { actionTypes, updateElements, removeElements } from './actions';

jsfConditionsDI.injectDependencies({
  actionTypes,
  updateElements,
  removeElements,
  createMapFromElements,
  isSignNow,
});
