import React from 'react';
import PropTypes from 'prop-types';

const EntitledBoxHeader = ({ children }) => {
  return (
    <div className="entitled-box__header">
      <div className="entitled-box__label">
        {children}
      </div>
    </div>
  );
};

EntitledBoxHeader.propTypes = {
  children: PropTypes.node,
};

EntitledBoxHeader.defaultProps = {
  children: null,
};

export default EntitledBoxHeader;
