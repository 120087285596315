import { takeEvery, put, select } from 'redux-saga/effects';
import { thisDevice } from '@pdffiller/jsf-useragent';
import { activateTool } from 'ws-editor-lib/actions';
import { selectors, thunks } from '../../..';
import { isActivateTextOnOkType } from '../../../helpers/elemTypes';
import { zeroSpace } from '../../../helpers/const';
import { ON_OK_CLICKED } from '../../modules/events';
import { resetPopup, setKeyboardShown, setKeyboardSize } from '../../modules/viewport';
import { isFillable } from '../../helpers/functions';

function* onOkClicked() {
  if (thisDevice.isMobile) {
    yield put(resetPopup());
    yield put(setKeyboardShown(false));
    if (thisDevice.isIOS) {
      yield put(setKeyboardSize(zeroSpace));
    }
  }

  const activeElement = yield select(selectors.elements.getActiveElement);

  yield put(thunks.setActiveElement(activeElement.id, false));

  if (isActivateTextOnOkType(activeElement.type) && !isFillable(activeElement)) {
    const { type, subType } = yield select(selectors.getDefaultTool);
    yield put(activateTool(type, subType));
  }
}

export default function* watchOkClicked() {
  yield takeEvery(ON_OK_CLICKED, onOkClicked);
}
