import saveCaretPosition from './saveCaretPosition';
import { sizeUp, sizeDown } from './sizeUpDown';
import toggleStamp from './toggleStamp';
import removeImport, { removeFillableElements } from './remove';
import { addElement, addFillableElement } from './addElement';
import setActiveElementImport from './setActiveElement';
import deactivateElement from './deactivateElement';
import { changePage } from './changePage';
import toggleFConstructor from './toggleFConstructor';
import updateFillableElementWithPreprocessing, {
  updateFillableElementWithConditionsCheck,
} from './updateFillableElementWithPreprocessing';
import updateFillableElementThunk from './updateFillableElement';
import forceFocusTextElement from './forceFocusTextElement';
import propagateTemplateToOtherElementsOfRadioGroup
  from './propagateTemplateToOtherElementsOfRadioGroup';
import getAdditionalDataForRadioBeforeCreate
  from './getAdditionalDataForRadioBeforeCreate';
import closeSignatureManagerImport from './closeSignatureManager';
import onDoneButtonOptionClickFactory from './onDoneButtonOptionClickFactory';
import showDropdownIsEmptyModal from './showDropdownIsEmptyModal';
import openFeedbackModal from './openFeedbackModal';

export const setActiveElement = setActiveElementImport;

// IF put closeSignatureManager in export {}, then occurs error
// TypeError during Jest's spyOn:
// Cannot set property <<YOUR_FUNCTION_NAME>> of #<Object> which has only a getter
export const closeSignatureManager = closeSignatureManagerImport;

export {
  saveCaretPosition,
  sizeUp,
  sizeDown,
  toggleStamp,
  addElement,
  addFillableElement,
  changePage,
  toggleFConstructor,
  updateFillableElementWithPreprocessing,
  updateFillableElementWithConditionsCheck,
  updateFillableElementThunk,
  forceFocusTextElement,
  propagateTemplateToOtherElementsOfRadioGroup,
  getAdditionalDataForRadioBeforeCreate,
  removeFillableElements,
  onDoneButtonOptionClickFactory,
  showDropdownIsEmptyModal,
  openFeedbackModal,

  removeImport as remove,
  deactivateElement,
};
