import React from 'react';
import PropTypes from 'prop-types';

const ContentConfiguratorMain = ({ children }) => {
  return (
    <div className="content-configurator__main">
      {children}
    </div>
  );
};

ContentConfiguratorMain.propTypes = {
  children: PropTypes.node,
};

ContentConfiguratorMain.defaultProps = {
  children: null,
};

export default ContentConfiguratorMain;
