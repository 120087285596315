import { select, takeEvery, put } from 'redux-saga/effects';

import { CHANGE_PAGE } from 'jsfcore/store/modules/navigationActionTypes';

import { disable } from './actions/choiseByClicking';
import { getChoiseByClickingModeIsActive } from '../selectors';

function* onPageChange() {
  const isChoiseByClicking = yield select(getChoiseByClickingModeIsActive);

  // Выключить choiseByClicking при смене страницы
  if (isChoiseByClicking) {
    yield put(disable());
  }
}

export default function* watchPageChange() {
  yield takeEvery(CHANGE_PAGE, onPageChange);
}
